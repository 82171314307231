module.exports = {
    moduleType: 'locale',
    name: 'ru',
    dictionary: {
        Autoscale: 'Автоматическое масштабирование',
        'Box Select': 'Выделение прямоугольной области',
        'Click to enter Colorscale title': 'Нажмите для ввода названия цветовой шкалы',
        'Click to enter Component A title': 'Нажмите для ввода названия компонента A',
        'Click to enter Component B title': 'Нажмите для ввода названия компонента B',
        'Click to enter Component C title': 'Нажмите для ввода названия компонента C',
        'Click to enter Plot title': 'Нажмите для ввода названия графика',
        'Click to enter X axis title': 'Нажмите для ввода названия оси X',
        'Click to enter Y axis title': 'Нажмите для ввода названия оси Y',
        'Click to enter radial axis title': 'Нажмите для ввода названия полярной оси',
        'Compare data on hover': 'При наведении показывать все данные',
        'Double-click on legend to isolate one trace': 'Дважды щёлкните по легенде для выделения отдельных данных',
        'Double-click to zoom back out': 'Для сброса масштаба к значению по умолчанию дважды щёлкните мышью',
        'Download plot': 'Сохранить график в формате SVG',
        'Download plot as a png': 'Сохранить в формате PNG',
        'Edit in Chart Studio': 'Редактировать в Chart Studio',
        'IE only supports svg.  Changing format to svg.': 'IE поддерживает только svg. Формат сменяется на svg.',
        'Lasso Select': 'Лассо',
        'Orbital rotation': 'Орбитальное движение',
        Pan: 'Режим перемещения по графику',
        'Produced with Plotly.js': 'Создано с помощью Plotly.js',
        Reset: 'Сбросить к значениям по умолчанию',
        'Reset axes': 'Сбросить отображение осей к значениям по умолчанию',
        'Reset camera to default': 'Сбросить параметры камеры к значениям по умолчанию',
        'Reset camera to last save': 'Сбросить камеру к последнему сохранённому состоянию',
        'Reset view': 'Сбросить отображение к значениям по умолчанию',
        'Reset views': 'Сбросить отображения к значениям по умолчанию',
        'Show closest data on hover': 'При наведении показывать ближайшие данные',
        'Snapshot succeeded': 'Снимок успешно создан',
        'Sorry, there was a problem downloading your snapshot!': 'К сожалению, возникла проблема при сохранении снимка',
        'Taking snapshot - this may take a few seconds': 'Делается снимок - это может занять несколько секунд',
        'Toggle Spike Lines': 'Включить/выключить отображение линий проекций точек',
        'Toggle show closest data on hover': 'Включить/выключить показ ближайших данных при наведении',
        'Turntable rotation': 'Вращение на поворотном столе',
        Zoom: 'Режим увеличения масштаба по выделенной области',
        'Zoom in': 'Увеличить',
        'Zoom out': 'Уменьшить',
        'close:': 'Закрытие:',
        'concentration:': 'Концентрация:',
        'high:': 'Максимум:',
        'incoming flow count:': 'Количество входящих связей:',
        'kde:': 'Ядерная оценка плотности:',
        'lat:': 'Широта:',
        'lon:': 'Долгота:',
        'low:': 'Минимум:',
        'lower fence:': 'Нижняя граница:',
        'max:': 'Макс.:',
        'mean ± σ:': 'Среднее ± σ:',
        'mean:': 'Среднее:',
        'median:': 'Медиана:',
        'min:': 'Мин.:',
        'new text': 'Новый текст',
        'open:': 'Открытие:',
        'outgoing flow count:': 'Количество исходящих связей:',
        'q1:': 'q1:',
        'q3:': 'q3:',
        'source:': 'Источник:',
        'target:': 'Цель:',
        trace: 'Ряд',
        'upper fence:': 'Верхняя граница:',
    },
    format: {
        days: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
        shortDays: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
        months: [
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Май',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
            'Октябрь',
            'Ноябрь',
            'Декабрь',
        ],
        shortMonths: ['Янв.', 'Февр.', 'Март', 'Апр.', 'Май', 'Июнь', 'Июль', 'Авг.', 'Сент.', 'Окт.', 'Ноя', 'Дек.'],
        date: '%d.%m.%Y',
        decimal: ',',
        thousands: ' ',
    },
};
