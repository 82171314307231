import { FC, useState } from 'react';
import { useIntl } from 'react-intl';

import Button from '@mui/material/Button';

import { SchemaSelectionModes } from '@repeat/models';
import { TranslationKey } from '@repeat/translations';
import { pxToRem } from '@repeat/ui-kit';

import { messages } from '../translation';
import { ElementProperties } from './ElementProperties';
import { PropertiesSettings } from './PropertiesSettings';

export const ElementPropertiesCustom: FC<{ mode: SchemaSelectionModes }> = ({ mode }) => {
    const intl = useIntl();
    const [isPropertiesSettingsBlockVisible, setIsPropertiesSettingsBlockVisible] = useState(false);

    return (
        <>
            <ElementProperties mode={mode} />
            {mode === SchemaSelectionModes.PROJECT &&
                (!isPropertiesSettingsBlockVisible ? (
                    <Button
                        style={{
                            marginLeft: pxToRem(16),
                            marginTop: `-${pxToRem(16)}`,
                            padding: 0,
                        }}
                        onClick={() => setIsPropertiesSettingsBlockVisible(true)}
                    >
                        {intl.formatMessage(messages[TranslationKey.WORKSPACE_ADD_PROPERTY])}
                    </Button>
                ) : (
                    <Button
                        style={{
                            marginLeft: pxToRem(16),
                            marginTop: `-${pxToRem(16)}`,
                            padding: 0,
                        }}
                        onClick={() => setIsPropertiesSettingsBlockVisible(false)}
                    >
                        {intl.formatMessage(messages[TranslationKey.HIDE])}
                    </Button>
                ))}
            {isPropertiesSettingsBlockVisible && (
                <PropertiesSettings setVisibility={setIsPropertiesSettingsBlockVisible} />
            )}
        </>
    );
};
