import { Action } from 'redux';

export enum LogTypes {
    ADD_LOG = 'ADD_LOG',
    CLEAR_LOG = 'CLEAR_LOG',
    THROW_LOG_ERROR = 'THROW_LOG_ERROR',
}

export interface LogAction extends Action<LogTypes> {
    // TODO: check Type property !!!
    type: LogTypes;
    payload?: string;
}
