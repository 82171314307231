/* eslint-disable @typescript-eslint/no-explicit-any */
import { appInitialState, appUserInitialState } from '@repeat/common-slices';
import { LOCAL_STORAGE_APP_REDUX_STATE_KEY, exceptObjectByKeys } from '@repeat/constants';
import { IElement, TState } from '@repeat/models';

import { initialState as fileManagerInitialState } from './slices/fileManagerSlice';
import { initialState as projectsInitialState } from './slices/projectsSlice';
import { initialState as schemaInitialState } from './slices/schema';
import { initialState as workspaceInitialState } from './slices/workspace';

const initState: TState = {
    app: {
        ...appInitialState,
    },
    appUser: {
        ...appUserInitialState,
    },
    valid: 0,
    counter: 0,
    elemTool: {
        x: 0,
        y: 0,
        size: 1,
        height: 1,
        width: 1,
        visible: false,
        picId: 0,
        name: '',
        startSize: 1,
        angle: 0,
        warning: false,
        state: '0',
    },
    workspace: {
        ...workspaceInitialState,
    },
    schema: {
        ...schemaInitialState,
    },
    isLoading: false,
    task: {
        systemType: 'ee',
        taskNumber: 1,
    },
    deletedElements: [] as IElement[],
    projects: {
        ...projectsInitialState,
    },
    fileManager: {
        ...fileManagerInitialState,
    },
};

export const reduceStoreByKeys = (stateKeys: (keyof TState)[], state: TState): TState => {
    return exceptObjectByKeys(stateKeys, state);
};

export const getInitState = (): TState => {
    const stateFromLS = window.localStorage.getItem(LOCAL_STORAGE_APP_REDUX_STATE_KEY);
    const state = stateFromLS
        ? reduceStoreByKeys(
              ['app', 'appUser', 'projects', 'workspace', 'task', 'fileManager'],
              JSON.parse(stateFromLS) as TState
          )
        : initState;
    return state;
};
