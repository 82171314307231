export type TCoordinates = {
    x: number;
    y: number;
};

export enum PortPositionTypes {
    LEFT = 'left',
    TOP = 'top',
    RIGHT = 'right',
    BOTTOM = 'bottom',
}
export type TPortPositionType = PortPositionTypes.LEFT | PortPositionTypes.TOP | PortPositionTypes.RIGHT | PortPositionTypes.BOTTOM;
