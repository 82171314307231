export enum ModelStatuses {
    RUN = 'RUN',
    STOP = 'STOP',
    DATA = 'DATA',
    FREEZE = 'FREEZE',
    INIT = 'INIT',
    CODE_GENERATION = 'CODE',
}
export type ModelStatus =
    | ModelStatuses.RUN
    | ModelStatuses.STOP
    | ModelStatuses.DATA
    | ModelStatuses.FREEZE
    | ModelStatuses.INIT
    | ModelStatuses.CODE_GENERATION;
