import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { TNewBlockInfoModal } from 'libs/models/src/lib/modal';

import { ApplicationActions } from '@repeat/common-slices';
import { validateEmptySpaces } from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { ILibraryItem, ModalTypes } from '@repeat/models';
import { Button, InputField, Modal, StyledForm, uiColors } from '@repeat/ui-kit';

import { EditBlockEn } from '../EngineerMode/EditBlockForm/EditBlockEn';
import { EditBlockUI } from '../EngineerMode/EditBlockForm/EditBlockUI';
import { EngineerService } from '../EngineerMode/EngineerService';
import { SPropertyValuesForm, StyledButtons } from '../EngineerMode/styled/SEditBlock';

const engineerService = new EngineerService('engineerBlocks');
const engineerServiceEN = new EngineerService('engineerBlocksEN');

export const NewBlockInfoModal = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm<Pick<ILibraryItem, 'name' | 'type' | 'library' | 'subLibrary' | 'elemProps' | 'elemParams'>>({
        mode: 'onChange',
    });

    const [isValidForm, setIsValidForm] = useState(false);
    const modals = useAppSelector((state) => state.app.modals);
    const modal = modals.find((m) => m.type === ModalTypes.NEWBLOCK_INFO) as TNewBlockInfoModal;
    const [block] = useState(modal.data.block);
    const [selectedTabs, setSelectedTabs] = useState({ settings: true, block: false });

    const handleClose = () => {
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.NEWBLOCK_INFO }));
    };

    const handleFormValidate = (isValid: boolean) => {
        setIsValidForm(isValid);
    };

    const handleSaveClick = useCallback(
        async (data: Pick<ILibraryItem, 'name' | 'elemProps' | 'elemParams'>) => {
            const engineerBlock = structuredClone(block);
            if (engineerBlock) {
                engineerBlock.name = data.name;
                engineerBlock.shortName = data.name;
                engineerBlock.elemProps.forEach((prop, i) => {
                    prop.description = data.elemProps[i].description;
                    prop.availableValues?.map((avaliableValue, index) => {
                        avaliableValue.title = data.elemProps[i]?.availableValues?.[index].title as string;
                    });
                });
                engineerBlock.elemParams.forEach((param, i) => (param.description = data.elemParams[i].description));
                return await engineerService
                    .updateBlockByCursor(engineerBlock.type, engineerBlock)
                    .then(() => navigate(0))
                    .catch((err) => console.error(err));
            }
        },
        [engineerService, block, navigate]
    );

    return (
        <Modal
            id={ModalTypes.NEWBLOCK_INFO}
            title={block.name}
            isOpen={true}
            hasOverlay={true}
            onClose={handleClose}
            type='default'
            size='large'
            footer={null}
        >
            <Tabs>
                <TabList style={{ display: 'flex' }}>
                    <Tab
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '14px',
                            color: `${selectedTabs.settings ? uiColors.mainBlue : uiColors.darkGrey}`,
                        }}
                        onClick={() => setSelectedTabs({ settings: true, block: false })}
                    >
                        RU
                    </Tab>
                    <Tab
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '14px',
                            color: `${selectedTabs.settings ? uiColors.mainBlue : uiColors.darkGrey}`,
                        }}
                        onClick={() => setSelectedTabs({ settings: true, block: false })}
                    >
                        UI
                    </Tab>
                    <Tab
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '14px',
                            color: `${selectedTabs.settings ? uiColors.mainBlue : uiColors.darkGrey}`,
                        }}
                        onClick={() => setSelectedTabs({ settings: true, block: false })}
                    >
                        EN
                    </Tab>
                </TabList>
                <TabPanel>
                    <StyledForm>
                        <InputField
                            label='Название'
                            type='text'
                            defaultValue={block?.name ?? ''}
                            id='standard-required'
                            {...register('name', {
                                validate: {
                                    noWhitespace: validateEmptySpaces,
                                },
                            })}
                        />
                        <h5>Свойства блока</h5>
                        {block?.elemProps.map((elemProp, index) => (
                            <>
                                <InputField
                                    key={elemProp.description}
                                    label={elemProp.name}
                                    type='text'
                                    defaultValue={elemProp.description ?? ''}
                                    id='standard-required'
                                    {...register(`elemProps.${index}.description`, {
                                        validate: {
                                            noWhitespace: validateEmptySpaces,
                                        },
                                    })}
                                />
                                {elemProp.availableValues?.map((avaliableValue, index2) => (
                                    <SPropertyValuesForm>
                                        <h6>Значение свойства "{avaliableValue.title}"</h6>

                                        <InputField
                                            key={avaliableValue.title}
                                            type='text'
                                            size='medium'
                                            defaultValue={block?.elemProps[index].availableValues?.[index2].title ?? ''}
                                            id='standard-required'
                                            {...register(`elemProps.${index}.availableValues.${index2}.title`)}
                                        />
                                    </SPropertyValuesForm>
                                ))}
                            </>
                        ))}

                        <h5>Параметры блока</h5>
                        {block?.elemParams.map((elemParam, index) => (
                            <InputField
                                key={elemParam.description}
                                label={elemParam.name}
                                type='text'
                                defaultValue={elemParam.description ?? ''}
                                {...register(`elemParams.${index}.description`)}
                            />
                        ))}
                    </StyledForm>

                    <StyledButtons>
                        <Button variant='secondary' onClick={handleClose}>
                            Отмена
                        </Button>
                        <Button onClick={handleSubmit(handleSaveClick)}>Сохранить</Button>
                    </StyledButtons>
                </TabPanel>
                <TabPanel>
                    <EditBlockUI currentBlockProp={block!} engineerService={engineerService} />
                </TabPanel>
                <TabPanel>
                    <EditBlockEn currentBlockProp={block!} engineerService={engineerServiceEN} />
                </TabPanel>
            </Tabs>
        </Modal>
    );
};
