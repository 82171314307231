import { useState } from 'react';

import styled from 'styled-components';

import {
    uiRGBColors,
    Icon,
    pxToRem,
    StyledAccordionItem,
    StyledAccordionItemBody,
    StyledAccordionItemTitle,
    StyledTitleWrapper,
} from '@repeat/ui-kit';

import { SButton } from './ElementConfigurationTitle';

interface IElementConfigurationAccordionItem {
    title: string | React.ReactNode;
    iconName: string | boolean;
    iconFill?: string;
    children: React.ReactNode;
}
const SElementConfigurationAccordionItem = styled(StyledAccordionItem)`
    padding: ${pxToRem(8)} ${pxToRem(16)};
    margin-bottom: 0;
    &:hover {
        ${SButton} {
            display: block;
        }
    }
`;
const SElementConfigurationAccordionItemTitle = styled(StyledAccordionItemTitle)`
    padding-left: ${pxToRem(8)};
    > i:last-child {
        transform: ${({ open }) => (!open ? 'translateY(-50%)' : 'rotate(180deg) translateY(50%)')};
        left: -${pxToRem(16)};
        top: 50%;
    }
`;

const SModifiedTitleWrapper = styled(StyledTitleWrapper)`
    padding-right: 0;
`;
export const ElementConfigurationAccordionItem: React.FC<IElementConfigurationAccordionItem> = ({
    title,
    children,
    iconName,
    iconFill,
}) => {
    const [open, setOpen] = useState(false);
    const handleClick = () => setOpen(!open);
    const AccordionIcon = () =>
        iconName && typeof iconName === 'string' ? <Icon fill={iconFill} name={iconName} /> : null;

    return (
        <SElementConfigurationAccordionItem>
            <SElementConfigurationAccordionItemTitle open={open} onClick={handleClick}>
                <AccordionIcon />
                <SModifiedTitleWrapper>{title}</SModifiedTitleWrapper>
                <Icon name='chevron' fill={`rgba(${uiRGBColors.blackSecond}, 0.5)`} />
            </SElementConfigurationAccordionItemTitle>
            <StyledAccordionItemBody open={open}>{children}</StyledAccordionItemBody>
        </SElementConfigurationAccordionItem>
    );
};
