import { IUndoRedoSetStack, TUndoRedoContainer } from '@repeat/models';

interface IStorage {
    limit: number;
    getStack(store: TUndoRedoContainer): void;
    setStack(store: TUndoRedoContainer, newItem: IUndoRedoSetStack): void;
    undo(store: TUndoRedoContainer): void;
    redo(store: TUndoRedoContainer): void;
}

abstract class AUndoRedoService implements IStorage {
    limit = 100;
    public abstract getStack(store: TUndoRedoContainer): void;
    public abstract setStack(store: TUndoRedoContainer, newItem: IUndoRedoSetStack): void;
    public abstract undo(store: TUndoRedoContainer): void;
    public abstract redo(store: TUndoRedoContainer): void;
}

export class UndoRedoService extends AUndoRedoService {
    override limit = 100;

    getStack(store: TUndoRedoContainer) {
        return store;
    }
    setStack(store: TUndoRedoContainer, stackItem: IUndoRedoSetStack) {
        const { stack }: TUndoRedoContainer = store;
        const isUpperQuote = stack.length === this.limit;
        const newStack = isUpperQuote ? [stackItem, ...stack.slice(0, stack.length - 1)] : [stackItem, ...stack];

        return {
            next: [],
            current: stackItem,
            stack: [...newStack],
        };
    }
    redo(store: TUndoRedoContainer) {
        const { stack, next } = store;
        const newNext = [...next.slice(1)];
        return {
            current: next[0],
            next: newNext,
            stack: [next[0], ...stack],
        };
    }
    undo(store: TUndoRedoContainer) {
        const { stack, next, current } = store;
        stack.shift();
        return {
            next: [current, ...next],
            current: stack[0],
            stack: [...stack],
        };
    }
}
