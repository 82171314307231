import styled, { css } from 'styled-components';

import { AlertType, IAlert } from './Alert';

import { pxToRem, radius, uiColors } from '../../config/config';

export const SAlert = styled.div<IAlert>`
    align-items: center;
    border-radius: ${radius.default};
    display: flex;
    padding: ${pxToRem(8)} ${pxToRem(16)};
    width: 100%;
    margin: ${pxToRem(16)} 0;
    font-size: ${pxToRem(14)};
    color: ${uiColors.dark};

    ${({ type }) =>
        type === AlertType.SUCCESS &&
        css`
            background: ${uiColors.lightGreen};
            border: 1px solid ${uiColors.green};
        `}
    ${({ type }) =>
        type === AlertType.WARNING &&
        css`
            background: ${uiColors.lightYellow};
            border: 1px solid ${uiColors.yellow};
        `}
    ${({ type }) =>
        type === AlertType.ERROR &&
        css`
            background: ${uiColors.lightRed};
            border: 1px solid ${uiColors.red};
        `}
`;
