import styled from 'styled-components';

import { ElementNotificationTypes, TElementNotificationType } from '@repeat/models';
import { border, pxToRem, radius, uiColors, zIndex } from '@repeat/ui-kit';

interface ISImageWrapperProps {
    width: number;
    height?: number;
    rotation?: number;
    background?: string;
}

interface ISNodeWrapperProps {
    width: number;
    height: number;
    background?: string;
    isResizable?: boolean;
    isOutlined?: boolean;
    rotation?: number;
    notificationType?: TElementNotificationType | null;
}

const chevronRight =
    "background-image: url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 24 24'%3E%3Ctitle%3Echevron-right%3C/title%3E%3Cpath d='M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z' /%3E%3C/svg%3E\");";

const chevronLeft =
    "background-image: url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 24 24'%3E%3Ctitle%3Echevron-left%3C/title%3E%3Cpath d='M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z' /%3E%3C/svg%3E\");";

const chevronTop =
    "background-image: url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 24 24'%3E%3Ctitle%3Echevron-up%3C/title%3E%3Cpath d='M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z' /%3E%3C/svg%3E\");";

const chevronBottom =
    "background-image: url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 24 24'%3E%3Ctitle%3Echevron-down%3C/title%3E%3Cpath d='M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z' /%3E%3C/svg%3E\");";

export const SElement = styled.div`
    position: relative;

    .moveable-control-box {
        z-index: ${zIndex.moveable};
    }
    .moveable-control.moveable-origin {
        display: none;
    }
`;

export const SNodeNotification = styled.div``;

export const SNodeWrapper = styled.figure<ISNodeWrapperProps>`
    background: ${({ background }) => (background ? background : 'var(--ui-background)')};

    border: ${({ isOutlined, notificationType }) => {
        switch (true) {
            case isOutlined:
                return `1px solid ${uiColors.brightBlue}`;
            case notificationType === ElementNotificationTypes.NEED_TO_UPDATE:
            case notificationType === ElementNotificationTypes.IS_DEPRECATED:
            case notificationType === ElementNotificationTypes.GOTO_WITHOUT_PAIR:
                return `1px solid ${uiColors.red}`;
            case notificationType === ElementNotificationTypes.PROJECT_IS_CHANGED:
            case notificationType === ElementNotificationTypes.USER_BLOCK_IS_CHANGED:
                return `1px solid ${uiColors.orange}`;
            case notificationType === ElementNotificationTypes.IS_ERROR_GETTING_PROJECT:
            case notificationType === ElementNotificationTypes.USER_BLOCK_IS_DELETED:
            case notificationType === ElementNotificationTypes.USER_BLOCK_IS_ERROR:
                return `1px solid ${uiColors.red}`;
            default:
                return `1px solid ${uiColors.darkGrey}`;
        }
    }};
    border-radius: ${pxToRem(2)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform-origin: center;
    height: ${({ height }) => `${height}px`};
    width: ${({ width }) => `${width}px`};
    position: relative;

    ${({ isOutlined }) =>
        isOutlined &&
        `
        box-shadow: 0 0 1px 1px ${uiColors.brightBlue};
    `}
`;

export const STextNodeWrapper = styled.div<ISNodeWrapperProps>`
    background: var(--ui-sidebar);
    border: ${({ isOutlined }) => (isOutlined ? `1px solid ${uiColors.mainBlue}` : border.sidebar)};
    box-shadow: 0 ${pxToRem(1)} ${pxToRem(8)} rgba(0, 0, 0, 0.2);
    border-radius: ${pxToRem(2)};
    display: flex;
    flex-direction: column;
    height: ${({ height }) => `${height}px`};
    left: 1px;
    padding: 0;
    position: relative;
    width: ${({ width }) => `${width}px`};

    .react-flow__resize-control.handle {
        height: ${pxToRem(8)};
        width: ${pxToRem(8)};
    }
`;

export const SImageNodeButton = styled.div`
    border-radius: 4px;
    background: var(--ui-sidebar);
    margin-top: 0;
    right: 8px;
    opacity: 0.6;
    transition: all 0.3s ease-in-out;
    pointer-events: all;
    padding: 2px;

    button {
        svg {
            max-height: ${pxToRem(20)};
            max-width: ${pxToRem(20)};
        }
    }

    input {
        width: 100%;
    }
`;

export const SImageNodeWrapper = styled.div<ISNodeWrapperProps>`
    background: ${({ background }) => (background ? background : 'var(--ui-icon-button)')};
    border: ${({ isOutlined }) => (isOutlined ? `1px solid ${uiColors.mainBlue}` : border.sidebar)};
    box-shadow: 0 ${pxToRem(1)} ${pxToRem(8)} rgba(0, 0, 0, 0.2);
    border-radius: ${pxToRem(2)};
    display: flex;
    flex-direction: column;
    height: ${({ height }) => `${height}px`};
    left: 1px;
    padding: 0;
    position: relative;
    width: ${({ width }) => `${width}px`};

    .react-flow__resize-control.handle {
        height: ${pxToRem(8)};
        width: ${pxToRem(8)};
    }
`;

export const SImageWrapper = styled.div<ISImageWrapperProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
        display: block;
        margin: 0;
        padding: 0;
        height: ${({ height }) => `${height}px`};
        width: 95%;
        object-fit: contain;
        object-position: center;

        ${({ rotation }) => {
            return `transform: rotate(${rotation}deg)`;
        }}
    }
`;

interface ISBlockWrapperProps {
    isEditMode: boolean;
}

export const SBlockWrapper = styled.div<ISBlockWrapperProps>`
    display: flex;
    border-radius: ${radius.default};
    flex-direction: column;
    font-size: ${pxToRem(14)};
    height: 100%;

    textarea {
        border: none;
        border-radius: ${radius.default};
        height: 100%;
        padding: ${pxToRem(4)} ${pxToRem(8)};
        resize: none;
        outline: none;
        ${({ isEditMode }) => {
            return !isEditMode ? 'pointer-events: none;' : '';
        }}

        &:focus {
            border: none;
        }
    }
`;

export const SNodeIndex = styled.div<{ rotation?: number }>`
    position: absolute;
    top: -${pxToRem(24)};
    left: -${pxToRem(16)};
    ${({ rotation }) =>
        rotation &&
        `
        transform: rotate(${rotation}deg);
    `}
`;

export const SHandleFakeTooltip = styled.span`
    visibility: hidden;
    position: fixed;
    z-index: -100;
`;

export const SHandleTooltip = styled.div`
    &,
    ${SHandleFakeTooltip} {
        background-color: var(--ui-sidebar);
        border-radius: ${radius.default};
        font-size: 10px;
        display: flex;
        height: ${pxToRem(16)};
        padding: 0 4px;
        position: fixed;
        white-space: nowrap;
        width: auto;
    }
`;

export const SHandleWrapper = styled.div<{ rotation: number; show?: boolean; devMode: boolean }>`
    .react-flow__handle {
        background-color: var(--ui-icons);
        height: ${pxToRem(12)} !important;
        width: ${pxToRem(12)} !important;
        margin: -1px;
        pointer-events: auto !important;
        z-index: ${zIndex.super};

        &[data-type='input']::before,
        &[data-type='output']::before {
            background-size: cover;
            content: '';
            color: white;
            display: inline-block;
            font-size: ${pxToRem(12)};
            line-height: ${pxToRem(7)};
            position: fixed;
            bottom: 0;
            text-align: center;
            height: 100%;
            width: 100%;
            transform: ${({ rotation }) => rotation && `rotate(${rotation}deg)`};
        }

        ${({ devMode }) =>
            devMode &&
            `
            &[data-type='input']::before {
                border: 1px solid red;
            }
            &[data-type='output']::before {
                border: 1px solid green;
            }
        `}

        // TOP
        &[data-type='input'][data-position='top'][data-rotation='0'] {
            ${chevronBottom};
        }
        &[data-type='input'][data-position='top'][data-rotation='90'] {
            ${chevronRight};
        }
        &[data-type='input'][data-position='top'][data-rotation='-90'] {
            ${chevronLeft};
        }
        &[data-type='input'][data-position='top'][data-rotation='180'] {
            ${chevronTop};
        }
        &[data-type='input'][data-position='top'][data-rotation='-180'] {
            ${chevronTop};
        }
        &[data-type='input'][data-position='top'][data-rotation='270'] {
            ${chevronLeft};
        }
        &[data-type='input'][data-position='top'][data-rotation='-270'] {
            ${chevronRight};
        }

        // LEFT
        &[data-type='input'][data-position='left'][data-rotation='0'] {
            ${chevronRight};
        }
        &[data-type='input'][data-position='left'][data-rotation='90'] {
            ${chevronTop};
        }
        &[data-type='input'][data-position='left'][data-rotation='-90'] {
            ${chevronBottom};
        }
        &[data-type='input'][data-position='left'][data-rotation='180'] {
            ${chevronLeft};
        }
        &[data-type='input'][data-position='left'][data-rotation='-180'] {
            ${chevronLeft};
        }
        &[data-type='input'][data-position='left'][data-rotation='270'] {
            ${chevronBottom};
        }
        &[data-type='input'][data-position='left'][data-rotation='-270'] {
            ${chevronTop};
        }

        // RIGHT
        &[data-type='input'][data-position='right'][data-rotation='0'] {
            ${chevronLeft};
        }
        &[data-type='input'][data-position='right'][data-rotation='90'] {
            ${chevronBottom};
        }
        &[data-type='input'][data-position='right'][data-rotation='-90'] {
            ${chevronTop};
        }
        &[data-type='input'][data-position='right'][data-rotation='180'] {
            ${chevronRight};
        }
        &[data-type='input'][data-position='right'][data-rotation='-180'] {
            ${chevronRight};
        }
        &[data-type='input'][data-position='right'][data-rotation='270'] {
            ${chevronTop};
        }
        &[data-type='input'][data-position='right'][data-rotation='-270'] {
            ${chevronBottom};
        }

        // BOTTOM
        &[data-type='input'][data-position='bottom'][data-rotation='0'] {
            ${chevronTop};
        }
        &[data-type='input'][data-position='bottom'][data-rotation='90'] {
            ${chevronLeft};
        }
        &[data-type='input'][data-position='bottom'][data-rotation='-90'] {
            ${chevronRight};
        }
        &[data-type='input'][data-position='bottom'][data-rotation='180'] {
            ${chevronBottom};
        }
        &[data-type='input'][data-position='bottom'][data-rotation='-180'] {
            ${chevronBottom};
        }
        &[data-type='input'][data-position='bottom'][data-rotation='270'] {
            ${chevronRight};
        }
        &[data-type='input'][data-position='bottom'][data-rotation='-270'] {
            ${chevronLeft};
        }

        // TOP output
        &[data-type='output'][data-position='top'][data-rotation='0'] {
            ${chevronTop};
        }
        &[data-type='output'][data-position='top'][data-rotation='90'] {
            ${chevronLeft};
        }
        &[data-type='output'][data-position='top'][data-rotation='-90'] {
            ${chevronRight};
        }
        &[data-type='output'][data-position='top'][data-rotation='180'] {
            ${chevronBottom};
        }
        &[data-type='output'][data-position='top'][data-rotation='-180'] {
            ${chevronBottom};
        }
        &[data-type='output'][data-position='top'][data-rotation='270'] {
            ${chevronRight};
        }
        &[data-type='output'][data-position='top'][data-rotation='-270'] {
            /* Стили для порта выхода с параметрами: top, rotation: -270 */
        }

        // LEFT output
        &[data-type='output'][data-position='left'][data-rotation='0'] {
            ${chevronLeft};
        }
        &[data-type='output'][data-position='left'][data-rotation='90'] {
            ${chevronBottom};
        }
        &[data-type='output'][data-position='left'][data-rotation='-90'] {
            ${chevronTop};
        }
        &[data-type='output'][data-position='left'][data-rotation='180'] {
            ${chevronRight};
        }
        &[data-type='output'][data-position='left'][data-rotation='-180'] {
            ${chevronRight};
        }
        &[data-type='output'][data-position='left'][data-rotation='270'] {
            ${chevronTop};
        }
        &[data-type='output'][data-position='left'][data-rotation='-270'] {
            ${chevronBottom};
        }

        // RIGHT output
        &[data-type='output'][data-position='right'][data-rotation='0'] {
            ${chevronRight};
        }
        &[data-type='output'][data-position='right'][data-rotation='90'] {
            ${chevronTop};
        }
        &[data-type='output'][data-position='right'][data-rotation='-90'] {
            ${chevronBottom};
        }
        &[data-type='output'][data-position='right'][data-rotation='180'] {
            ${chevronLeft};
        }
        &[data-type='output'][data-position='right'][data-rotation='-180'] {
            /* Стили для порта выхода с параметрами: right, rotation: -180 */
        }
        &[data-type='output'][data-position='right'][data-rotation='270'] {
            ${chevronBottom};
        }
        &[data-type='output'][data-position='right'][data-rotation='-270'] {
            ${chevronTop};
        }

        // BOTTOM output
        &[data-type='output'][data-position='bottom'][data-rotation='0'] {
            ${chevronBottom};
        }
        &[data-type='output'][data-position='bottom'][data-rotation='90'] {
            ${chevronRight};
        }
        &[data-type='output'][data-position='bottom'][data-rotation='-90'] {
            /* Стили для порта выхода с параметрами: bottom, rotation: -90 */
        }
        &[data-type='output'][data-position='bottom'][data-rotation='180'] {
            ${chevronTop};
        }
        &[data-type='output'][data-position='bottom'][data-rotation='-180'] {
            ${chevronTop};
        }
        &[data-type='output'][data-position='bottom'][data-rotation='270'] {
            ${chevronLeft};
        }
        &[data-type='output'][data-position='bottom'][data-rotation='-270'] {
            ${chevronRight};
        }
    }
`;

export const SNodeTrendsTriggerItem = styled.li<{ header?: boolean }>`
    align-items: center;
    display: flex;
    font-size: 12px;
    background: ${({ header }) => (header ? 'var(--ui-sidebar)' : 'transparent')};
    font-weight: ${({ header }) => (header ? 'bold' : 'normal')};
    position: ${({ header }) => (header ? 'sticky' : 'relative')};
    border-bottom: ${({ header }) => (header ? `${border.default}` : 'none')};
    position: ${({ header }) => (header ? `sticky` : 'relative')};
    z-index: ${({ header }) => (header ? `2` : '1')};
    padding: 8px 16px;
    justify-content: space-between;
    width: 100%;
    top: 0;

    label {
        width: 16px;
        margin: 0 0 0 ${pxToRem(8)};

        span {
            height: 16px;
            width: 20px;
        }
    }
`;

export const SNodeTrendsTriggerList = styled.ul<{ ref: any }>`
    border: ${border.default};
    background: var(--ui-sidebar);
    border-radius: ${radius.default};
    display: none;
    padding: 0 0 ${pxToRem(8)};
    min-width: ${pxToRem(200)};
    max-width: ${pxToRem(250)};
    max-height: ${pxToRem(200)};
    height: auto;
    margin-top: 22px;
    margin-left: -24px;
    overflow-y: auto;
    z-index: 10;
    will-change: display;

    &:hover {
        display: block;
    }
`;

const triggerSize = 24;

export const SNodeTrendsTrigger = styled.div`
    border-radius: 4px;
    background: var(--ui-sidebar);
    height: ${triggerSize}px;
    width: ${triggerSize}px;
    padding: 4px;
    position: absolute;
    transition: all 0.3s ease-in-out;
    left: -${triggerSize}px;
    top: -2px;
    opacity: 0.6;
    z-index: 10;

    &:hover {
        opacity: 1;
    }

    &:hover + ${SNodeTrendsTriggerList} {
        display: block;
    }
`;
export const SNodeTrendsTriggerWrapper = styled.div<{ rotation: number; selected: boolean }>`
    display: ${({ selected }) => (selected ? 'flex' : 'none')};
    position: absolute;
    bottom: 100%;
    left: 100%;
    transform: ${({ rotation }) => (rotation ? ` rotate(${rotation}deg)` : 'none')};
    min-width: 24px;
    pointer-events: all;

    &:hover {
        opacity: 1;

        ${SNodeTrendsTrigger} {
            opacity: 1;
        }

        ${SNodeTrendsTriggerList} {
            display: block;
        }
    }
`;

export const SElementEditCustomNameTextarea = styled.textarea`
    border: 0;
    border-radius: ${radius.default};
    overflow: hidden;
    resize: none;
    padding: ${pxToRem(4)};
`;

export const SElementEditCustomNameText = styled.span`
    font-size: ${pxToRem(12)};
    text-align: center;
    word-break: break-all;
    display: block;
    min-width: ${pxToRem(128)};
    max-width: ${pxToRem(148)};
    width: 100%;
`;

export const SElementCustomNameWrapper = styled.div<{ rotation: number }>`
    position: absolute;
    top: calc(100% + ${pxToRem(8)});
    left: -25%;
    right: 25%;

    ${({ rotation }) =>
        rotation &&
        `
        transform: rotate(${rotation}deg);
    `}
`;

export const SElementControlPanel = styled.div<{ selected: boolean }>`
    border-radius: 4px;
    background: var(--ui-sidebar);
    margin-top: 24px;
    right: 8px;
    opacity: 0.6;
    transition: all 0.3s ease-in-out;
    pointer-events: all;
    padding: 2px;
    z-index: 10;

    button {
        svg {
            max-height: ${pxToRem(20)};
            max-width: ${pxToRem(20)};
        }
    }

    &:hover {
        opacity: 1;
    }
`;

export const SNodeTrendsTitle = styled.div`
    background-color: var(--ui-sidebar);
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;

    span {
        color: var(--ui-text);
        display: block;
    }

    div {
        color: var(--ui-text);
    }
`;
