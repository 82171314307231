import { useIntl } from 'react-intl';

import { ApplicationActions } from '@repeat/common-slices';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { ModalTypes, TErrorModal } from '@repeat/models';
import { TranslationKey } from '@repeat/translations';
import { Button, Modal } from '@repeat/ui-kit';

import { blockOptionsMessages as messages } from './translation';

export const ErrorModal = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const modals = useAppSelector((state) => state.app.modals);
    const modal = modals.find((modal) => modal.type === ModalTypes.ERROR) as TErrorModal;

    const handleClose = () => {
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.ERROR }));
    };

    const FooterButtons = () => (
        <Button onClick={handleClose}>{intl.formatMessage(messages[TranslationKey.OK_THANKS])}</Button>
    );

    return (
        <Modal
            id={ModalTypes.ELEMENT_CONFIGURATION}
            title={intl.formatMessage(messages[TranslationKey.ERROR])}
            isOpen={true}
            hasOverlay={true}
            onClose={handleClose}
            type='default'
            size='medium'
            footer={<FooterButtons />}
        >
            {intl.formatMessage({ id: modal.data.errorKey })}
        </Modal>
    );
};
