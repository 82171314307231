import { useState } from 'react';

import styled from 'styled-components';

import { uiRGBColors, pxToRem } from '../../config/config';
import { IDefaultState } from '../../config/types';
import { Icon } from '../Icon/Icon';

export const StyledAccordionItem = styled.li`
    border-bottom: 1px solid rgba(${uiRGBColors.blackSecond}, 0.15);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-bottom: ${pxToRem(8)};
    padding: 0 ${pxToRem(8)} ${pxToRem(8)} ${pxToRem(16)};
    &:last-child {
        border-bottom: 0;
    }
`;

export const StyledAccordionItemTitle = styled.div<IDefaultState>`
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;

    i {
        align-self: flex-start;
    }

    > i:first-child {
        flex: 0 0 auto;
        margin-right: ${pxToRem(8)};
    }

    > i:last-child {
        transform: ${({ open }) => (!open ? 'rotate(-90deg)' : 'rotate(180deg)')};
        position: absolute;
        right: 0;
    }
`;

export const StyledTitleWrapper = styled.span`
    font-size: ${pxToRem(14)};
    font-weight: 500;
    display: block;
    flex: 1 0 auto;
    line-height: 20px;
    margin: 0;
    padding-right: ${pxToRem(64)};
    width: 100%;
`;

export const StyledAccordionItemBody = styled.div<IDefaultState>`
    display: block;
    height: ${({ open }) => (!open ? 0 : 'auto')};
    overflow: hidden;
    padding: ${({ open }) => (!open ? '0' : `${pxToRem(8)} 0`)};
    transition: height 0.3s ease-in-out;
`;

interface IAccordionItem {
    title: string | React.ReactNode;
    iconName: string | boolean;
    iconFill?: string;
    children: React.ReactNode;
}

export const AccordionItem: React.FC<IAccordionItem> = ({ title, children, iconName, iconFill }) => {
    const [open, setOpen] = useState(false);
    const handleClick = () => setOpen(!open);
    const AccordionIcon = () =>
        iconName && typeof iconName === 'string' ? <Icon fill={iconFill} name={iconName} /> : null;

    return (
        <StyledAccordionItem>
            <StyledAccordionItemTitle open={open} onClick={handleClick}>
                <AccordionIcon />
                <StyledTitleWrapper>{title}</StyledTitleWrapper>
                <Icon name='chevron' fill={`rgba(${uiRGBColors.blackSecond}, 0.5)`} />
            </StyledAccordionItemTitle>
            <StyledAccordionItemBody open={open}>{children}</StyledAccordionItemBody>
        </StyledAccordionItem>
    );
};
