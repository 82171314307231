import { FC } from 'react';

import styled from 'styled-components';

import { pxToRem, radius, uiColors } from '../../config/config';

type TSizes = 'big' | 'medium' | 'small';

interface BadgeProps {
    text: string;
    color?: string;
    size?: TSizes;
}

const BadgeWrapper = styled.div<{ size: number; color: string }>`
    display: inline-block;
    background-color: ${({ color }) => color};
    color: white;
    font-size: ${({ size }) => pxToRem(8 + size * 4)};
    padding: ${({ size }) => `${pxToRem(1 + size * 2)} ${pxToRem(2 + size * 2)}`};
    border-radius: ${radius.default};
    text-align: center;
    width: auto;
    max-width: 100%;
`;
const sizesValues = {
    small: 1,
    medium: 2,
    big: 3,
} as const;

export const Badge: FC<BadgeProps> = ({ size = 'medium', color = uiColors.orange, text = '' }) => {
    return (
        <BadgeWrapper size={sizesValues[size]} color={color}>
            {text}
        </BadgeWrapper>
    );
};
