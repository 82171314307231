import type { FC, PropsWithChildren } from 'react';

import styled from 'styled-components';

import cc from 'classcat';

import { uiColors } from '@repeat/ui-kit';

import type { ControlButtonProps } from './types';

export const SControlButton = styled.button`
    background: transparent;

    svg {
        fill: ${uiColors.semiDark};
    }

    &:hover {
        background: transparent;
    }

    &:hover svg {
        fill: ${uiColors.dark};
    }
`;

const ControlButton: FC<PropsWithChildren<ControlButtonProps>> = ({ children, className, ...rest }) => (
    <SControlButton type='button' className={cc(['react-flow__controls-button', className])} {...rest}>
        {children}
    </SControlButton>
);

ControlButton.displayName = 'ControlButton';

export default ControlButton;
