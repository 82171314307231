export const pxToRem = (px: number) => `${px / parseInt(fontSize.default, 10)}rem`;

export const fontSize = {
    default: '16px',
    modalTitle: '24px',
};

// do not use externally
export const uiColors = {
    white: '#fff',
    smokeWhite: '#fbfbfb',
    greyLight: '#E5E5E5',
    lightGrey: '#EFEFEF',
    borderGrey: '#ededed',
    borderGreyDark: '#BFBFBF',
    grey: '#BFBFBF',
    darkGrey: '#7F7F7F',
    semiDark: '#5F6062',
    greyPrimary: '#393939',
    dark: '#333333',
    black: '#000',
    darkBlue: '#003274',
    mainBlue: '#025EA1',
    brightBlue: '#4af',
    lightBlue: '#6CACE4',
    blueLight: '#6CACE4',
    seaWave: '#259789',
    yellow: '#ffea00',
    lightYellow: '#FFF8BC',
    red: '#D32F2F',
    orange: '#ff5722',
    lightRed: '#FCC1C1',
    green: '#35BB1F',
    darkGreen: '#1f970b',
    lightGreen: '#b3f1a9',
    accentGreen: '#b2c541',
};

// do not use externally
export const uiColorsDark = {
    darkBlue: '#111',
    mainBlue: '#222',
    brightBlue: '#444',
    white: '#1f1f1f',
    lightGrey: '#777',
};

export const uiRGBColors = {
    white: '255,255,255',
    whiteSecond: '238,238,238',
    black: '0,0,0',
    blackSecond: '49,49,49',
    red: '245,0,87',
    grey: '127,127,127',
    greySecond: '95,96,98',
    green: '37,151,137',
    darkBlue: '0,50,116',
    blue: '2,94,161',
};

export const shadow = {
    none: 'none',
    default: '1px 2px 4px 0 rgba(0, 0, 0, 15%)',
    main: '1px 3px 4px 0 rgb(0, 0, 0, 25%)',
    controls: '0 0 1px 1px var(--ui-shadow)',
};

export const radius = {
    default: pxToRem(4),
    rounded: '25%',
    circle: '50%',
    tariffs: pxToRem(24),
    projectContent: pxToRem(10),
};

export const zIndex = {
    below: -1,
    default: 0,
    base: 1,
    above: 5,
    top: 10,
    header: 15,
    sidebar: 14,
    footer: 15,
    moveable: 15,
    super: 20,
};

export const layoutSize = {
    header: '48px',
    footer: '40px',
    search: '66px',
    sidebar: '256px',
    sidebarTitle: '38px',
    elementsPanelDefault: '384px',
    elementsPanelWide: '980px',
    icons: '20px',
    authFormWide: pxToRem(352),
    authLeftWideHdReady: pxToRem(400),
    authLeftWideFullHd: pxToRem(1920 - 1160),
};

export const border = {
    default: `1px solid var(--ui-border)`,
    defaultHover: `1px solid var(--ui-border-hover)`,
    sidebar: `1px solid ${uiColors.grey}`,
};

export const breakPointsValues = {
    small: 576,
    medium: 768,
    large: 1024,
    extraLarge: 1200,
};
export const breakPoints = {
    small: `max-width: ${pxToRem(breakPointsValues.small)}`,
    medium: `max-width: ${pxToRem(breakPointsValues.medium)}`,
    large: `max-width: ${pxToRem(breakPointsValues.large)}`,
    extraLarge: `max-width: ${pxToRem(breakPointsValues.extraLarge)}`,
};

export const nodeNotificationWidth = {
    small: `${pxToRem(100)}`,
    medium: `${pxToRem(200)}`,
    large: `${pxToRem(300)}`,
    extraLarge: `${pxToRem(500)}`,
};
