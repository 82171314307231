import { Action } from 'redux';

/* eslint-disable @typescript-eslint/no-explicit-any */

export enum LibraryTypeTypes {
    ADD_LIBRARY_TYPE = 'ADD_LIBRARY_TYPE',
}

export interface LibraryTypeAction extends Action<LibraryTypeTypes> {
    // TODO: check Type property !!!
    type: LibraryTypeTypes;
    payload?: string | any;
}
