import styled from 'styled-components';

import { breakPoints, DefaultTableStyles, pxToRem, shadow, uiColors, zIndex } from '@repeat/ui-kit';

export const SVariableAddRowButton = styled.div`
    align-items: center;
    display: flex;
    padding: ${pxToRem(16)};

    a {
        color: var(--ui-link-secondary);
    }
`;

export const SVariableAddRow = styled.tr`
    > td {
        border: none !important;
        padding: 0 !important;

        &:hover {
            background: var(ui-backround);
        }
    }

    a {
        text-decoration: none;
    }

    &:hover {
        background: var(ui-backround);
    }
`;

export const SVariables = styled.div`
    border: 1px solid ${uiColors.borderGreyDark};
    margin: ${pxToRem(16)};
    max-width: 50%;
    position: relative;

    @media screen and (${breakPoints.large}) {
        max-width: 80%;
    }

    div[data-type='text'] {
        margin-bottom: 0;
    }
`;

export const SVariableTable = styled.table`
    ${DefaultTableStyles};

    th,
    td {
        padding: ${pxToRem(8)};
        vertical-align: middle;
    }

    tr > td:first-child {
        width: 50%;
    }

    td,
    tr {
        vertical-align: top;
    }

    thead tr:first-child {
        border-bottom: 1px solid ${uiColors.borderGreyDark};
    }

    tr > td:first-child,
    tr > th:first-child {
        border-right: 1px solid ${uiColors.borderGreyDark};
        width: ${pxToRem(248)};
    }

    tr > td:nth-child(2) {
        > div {
            align-items: center;
            display: flex;
            max-width: ${pxToRem(280 * 1.5)};
            padding: 0;
        }
    }
`;

export const SVariableAddItem = styled.button`
    background-color: var(--ui-main-blue);
    align-items: center;
    border: 0;
    box-shadow: ${shadow.default};
    border-radius: ${pxToRem(24)};
    color: white;
    cursor: pointer;
    display: flex;
    font-size: ${pxToRem(14)};
    justify-content: center;
    position: absolute;
    padding: 0;
    bottom: -${pxToRem(16)};
    right: -${pxToRem(16)};
    height: ${pxToRem(32)};
    width: ${pxToRem(32)};
    z-index: ${zIndex.top};
    will-change: left, bottom;

    span:empty {
        display: none;
    }

    span {
        margin-left: ${pxToRem(8)};
    }
`;

export const SVariableRow = styled.tr`
    &[data-error='true'] td {
        padding-bottom: ${pxToRem(20)};
    }

    i {
        visibility: hidden;

        &:hover {
            svg {
                fill: ${uiColors.black};
            }
        }
    }

    &:hover {
        i {
            visibility: visible;
        }
    }
`;

export const SVariableItemWrapper = styled.div`
    display: flex;
    padding-top: ${pxToRem(8)};

    > * {
        margin-right: ${pxToRem(8)};
    }
`;

export const SVariablesWrapper = styled.div`
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    padding-bottom: ${pxToRem(64)};
    top: 0;
    left: 0;
    width: 100%;
`;
