import { useIntl } from 'react-intl';

import { formatModelTime } from '@repeat/constants';
import { useAppSelector } from '@repeat/hooks';
import { workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';

import { SModelControlItem, SModelControlTime } from './StyledModelControls';
import { messages } from './translation';

export const ModelControlTime = () => {
    const { formatMessage } = useIntl();

    const modelTimeMs = useAppSelector(workspaceSelectors.modelControlTime);

    return (
        <SModelControlItem>
            <SModelControlTime data-test-id='model-control-timer'>
                {formatModelTime(modelTimeMs, formatMessage(messages[TranslationKey.MODEL_TIME_UNIT]))}
            </SModelControlTime>
        </SModelControlItem>
    );
};
