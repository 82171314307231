import moment from 'moment-timezone';

import { environment } from '@repeat/environment';

import { LICENSE_DISTRIBS, LICENSE_TIMEZONE } from '../app';

const { DISTRIB } = environment;

export const shouldHasLicense = (): boolean => {
    return LICENSE_DISTRIBS.includes(DISTRIB);
};

export interface ILicenseExpirationDuration {
    days: number;
    hours: number;
    minutes: number;
}

export const getExpirationDuration = (expireDate?: string | null): ILicenseExpirationDuration | null => {
    if (!expireDate) {
        return null;
    }

    const momentExpireAt = moment.tz(expireDate, LICENSE_TIMEZONE);

    const userTimezone = moment.tz.guess();
    const momentNow = moment.tz(new Date(), userTimezone);

    const duration = moment.duration(momentExpireAt.diff(momentNow));

    const durationDays = duration.days();

    duration.subtract(moment.duration(durationDays, 'days'));
    const durationHours = duration.hours();

    duration.subtract(moment.duration(durationHours, 'hours'));
    const durationMinutes = duration.minutes();

    return {
        days: durationDays,
        hours: durationHours,
        minutes: durationMinutes,
    };
};
