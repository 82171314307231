import React, { FC, Fragment } from 'react';
import { useIntl } from 'react-intl';

import styled from 'styled-components';

import MuiAlert, { AlertProps } from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Snackbar from '@mui/material/Snackbar';

import { ApplicationActions } from '@repeat/common-slices';
import { NOTIFICATION_AUTO_HIDE_DURATION } from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { NotificationTypes, TNotificationDetailMessage } from '@repeat/models';
import { CODE_LIST } from '@repeat/translations';
import { layoutSize, pxToRem, zIndex } from '@repeat/ui-kit';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const SSnackBarWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    position: fixed;
    margin: 0 auto;
    padding-top: ${pxToRem(8)};
    left: 0;
    right: 0;
    z-index: ${zIndex.super};
    top: ${layoutSize.header};
    max-width: 500px;

    > div {
        left: 0;
        margin-bottom: ${pxToRem(8)};
        position: relative;
        top: auto;
        right: 0;
        transform: none;
        width: 100%;
    }
`;

export const Notifications: FC = () => {
    const { formatMessage } = useIntl();
    const notifications = useAppSelector((state) => state.app.notifications);
    const dispatch = useAppDispatch();

    const handleClose = (index: number) => {
        dispatch(ApplicationActions.hideNotification({ index }));
    };

    return (
        <SSnackBarWrapper>
            {notifications.map((notification, index) => (
                <Snackbar
                    key={index}
                    open={true}
                    autoHideDuration={
                        [
                            NotificationTypes.ERROR,
                            NotificationTypes.SUCCESS,
                            NotificationTypes.INFO,
                            NotificationTypes.WARNING,
                        ].includes(notification.type)
                            ? null
                            : NOTIFICATION_AUTO_HIDE_DURATION
                    }
                    onClose={() => handleClose(index)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Alert onClose={() => handleClose(index)} severity={notification.type}>
                        {notification?.details && notification?.details?.length > 0 && (
                            <>
                                <AlertTitle>{formatMessage({ id: notification.message })}</AlertTitle>
                                {notification.details.map((detailMessage: TNotificationDetailMessage, index) => {
                                    const code = detailMessage?.code || null;
                                    if (code !== null && CODE_LIST[code]) {
                                        return (
                                            <Fragment key={`notification-${index}`}>
                                                {formatMessage({ id: CODE_LIST[code] })}
                                                <br />
                                            </Fragment>
                                        );
                                    }

                                    return (
                                        <Fragment key={`notification-${index}`}>
                                            {formatMessage({ id: detailMessage.message }, detailMessage.data || {})}
                                            <br />
                                        </Fragment>
                                    );
                                })}
                            </>
                        )}
                        {(!notification.details || notification?.details.length === 0) &&
                            formatMessage({ id: notification.message })}
                    </Alert>
                </Snackbar>
            ))}
        </SSnackBarWrapper>
    );
};
