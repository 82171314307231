import React, { FC } from 'react';

import styled, { css } from 'styled-components';

import { layoutSize, pxToRem, radius } from '../../../config/config';

const SMain = styled.main`
    background-color: var(--ui-background);
    height: calc(100vh - ${layoutSize.header} - ${layoutSize.footer});
    overflow: auto;
    margin: 0 ${layoutSize.sidebar};
    transition: margin 0.3s ease-in-out;
    width: 100%;
`;

const SMainContent = styled.div`
    background-color: var(--ui-sidebar);
    padding: ${pxToRem(24)};
    border-radius: ${radius.default};
    max-width: ${pxToRem(1024)};
`;

const SMainContentWrapper = styled.div`
    padding: ${pxToRem(24)};
    max-width: ${pxToRem(1024)};
    height: 100%;

    form {
        max-width: 400px;
    }

    h5,
    h3 {
        color: var(--ui-heading);
        margin-bottom: ${pxToRem(24)};
    }
`;

export const MainContentWrapper: FC<{ children: React.ReactNode }> = ({ children }) => {
    return <SMainContentWrapper>{children}</SMainContentWrapper>;
};

export const MainContent: FC<{ children: React.ReactNode }> = ({ children }) => {
    return <SMainContent>{children}</SMainContent>;
};

export const Main: FC<{ children: React.ReactNode; style?: keyof typeof css }> = ({ children, style }) => {
    return (
        <SMain data-tour='main-workspace' id='mainWork' style={style}>
            {children}
        </SMain>
    );
};
