import { THotkey } from './hotkey';
import { ILicenseState } from './license';
import { TPlatformType } from './meta';
import { TModal } from './modal';
import { TNotification } from './notification';

export interface IAppState {
    isInitialized: boolean;
    isLoading: boolean;
    notifications: TNotification[];
    modals: TModal[];
    hotkeyPressed: THotkey | null;
    meta: { platform: TPlatformType | null };
    license: ILicenseState;
}

export type IAppStateAccount = Pick<IAppState, 'isInitialized' | 'isLoading' | 'notifications' | 'modals' | 'meta'>;

export interface ILogFormat {
    [key: string]: {
        id: string;
        defaultMessage: string;
    };
}

export enum DistribTypes {
    CLOUD = 'cloud',
    NETWORK = 'network',
    LOCAL = 'local',
}

export type TDistribType = DistribTypes.CLOUD | DistribTypes.NETWORK | DistribTypes.LOCAL;
