import React, { FC, useCallback, memo, MouseEvent, WheelEvent, useMemo, useContext } from 'react';

import { Icon, IconButton } from '@repeat/ui-kit';

import { ToolsEditableTabName } from './ToolsEditableTabName';
import { ToolsTabsContext, ITab } from './ToolsTabsContext';

import {
    SToolsTabs,
    SToolsTabsAdd,
    SToolsTabsContent,
    SToolsTabsItem,
    SToolsTabsList,
    SToolsTabsWrapper,
} from '../STools';

export const ToolsTabs: FC = memo(() => {
    const { toolsTabs, handleTabActive, handleTabAdd, handleTabClose } = useContext(ToolsTabsContext);

    const handleScroll = useCallback((event: WheelEvent): any => {
        const delta = Math.sign(event.deltaY);
        event.currentTarget.scrollLeft = event.currentTarget.scrollLeft + delta * 200;
    }, []);

    const handleScrollToTab = useCallback(
        (newTabs: ITab[]) => {
            const target = document.getElementById(newTabs[newTabs.length - 1].id) as HTMLElement;
            if (target) {
                target.scrollIntoView({ block: 'end', inline: 'end' });
            }
        },
        [toolsTabs]
    );

    const activeTab = useMemo(() => toolsTabs.find((tab: ITab) => tab.active), [toolsTabs]);

    return (
        <SToolsTabs>
            <SToolsTabsList>
                <SToolsTabsWrapper id={'tools-tabs'} onWheel={(e: WheelEvent) => handleScroll(e)}>
                    {toolsTabs.map((tab: ITab) => (
                        <SToolsTabsItem
                            id={tab.id}
                            active={Boolean(tab?.active)}
                            key={`tools-tab-item-${tab.id}`}
                            onClick={() => handleTabActive(tab.id)}
                        >
                            <Icon name={tab.icon} />
                            <ToolsEditableTabName name={tab.name} id={tab.id} type={tab.type} />
                            {toolsTabs.length > 1 && (
                                <IconButton
                                    data-close
                                    noHover
                                    name={'close'}
                                    onClick={(event: MouseEvent) => {
                                        event.stopPropagation();
                                        handleTabClose(tab.id);
                                    }}
                                />
                            )}
                        </SToolsTabsItem>
                    ))}
                </SToolsTabsWrapper>
                <SToolsTabsAdd>
                    <IconButton
                        name={'plus'}
                        fill={'white'}
                        noHover
                        onClick={(event) => {
                            event.stopPropagation();
                            handleTabAdd(handleScrollToTab);
                        }}
                    />
                </SToolsTabsAdd>
            </SToolsTabsList>
            <SToolsTabsContent data-tab-id={activeTab?.id}>{activeTab?.content || null}</SToolsTabsContent>
        </SToolsTabs>
    );
});
