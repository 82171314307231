import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { workspaceActions } from '@repeat/store';

import { STools } from './STools';
import { ToolsTabs } from './ToolsTabs/ToolsTabs';
import { ITab, ToolsTabsProvider } from './ToolsTabs/ToolsTabsContext';
import { toolsIcons, toolsTabContent, toolsTabNames, toolsTabType } from './helpers';
import { messages } from './translation';

export const Tools = () => {
    const { formatMessage } = useIntl();

    const dispatch = useAppDispatch();
    const tools = useAppSelector((state) => state.workspace.settings.tools) as ITab[];

    const initialTabs = useMemo<ITab[]>(
        () =>
            tools
                ? tools.map((tool) => ({
                      ...tool,
                      icon: toolsIcons[tool.type as keyof typeof toolsIcons],
                      name:
                          tool.name ||
                          formatMessage(
                              messages[toolsTabNames[tool.type as keyof typeof toolsTabNames] as keyof typeof messages]
                          ),
                      content: toolsTabContent[tool.type as keyof typeof toolsTabContent],
                  }))
                : [],
        []
    );

    const defaultTab = useMemo(
        () => ({
            type: toolsTabType.START,
            icon: toolsIcons.START,
            name: formatMessage(messages[toolsTabNames.START as keyof typeof messages]),
            content: toolsTabContent[toolsTabType.START],
            data: null,
        }),
        []
    ) as ITab;

    const handleSaveTabs = useCallback((tabs: ITab[]) => {
        const tabsToSave = tabs.map((tab) => {
            const { active, id, type, data, name } = tab;
            return {
                active,
                id,
                type,
                data,
                name,
            };
        });
        dispatch(workspaceActions.setTools(tabsToSave));
    }, []);

    return (
        <ToolsTabsProvider defaultTab={defaultTab} tabs={initialTabs} onSave={handleSaveTabs}>
            <STools>
                <ToolsTabs />
            </STools>
        </ToolsTabsProvider>
    );
};
