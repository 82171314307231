import styled from 'styled-components';

import { border, fontSize, pxToRem } from '@repeat/ui-kit';

export const STableFooter = styled.div`
    background-color: var(--ui-sidebar);
    padding: ${pxToRem(8)} 0px;
    margin: ${pxToRem(10)} 0;
    width: 100%;
    font-weight: 500;
    font-size: ${fontSize.default};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;

    ul {
        display: flex;
        flex-direction: row;
    }
    ul li {
        margin: auto 0;
    }
    li:last-child {
        margin-left: ${pxToRem(10)};
    }

    button {
        border: none;
        padding: ${pxToRem(7)} ${pxToRem(14)};
        border-radius: ${border.default};
        cursor: pointer;
        margin-right: 4px;
        margin-left: 4px;
    }
`;
