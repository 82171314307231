import { FC, memo } from 'react';
import { useIntl } from 'react-intl';

import { useAppSelector } from '@repeat/hooks';
import { IEventLogDataContext } from '@repeat/models';
import { workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';

import { SStacktrace, SStacktraceTitle } from './SEventLogItem';
import { messages } from './translation';

export interface IEventLogItemContext {
    context: IEventLogDataContext;
    currentTime: string;
}

export const EventLogItemContext: FC<IEventLogItemContext> = memo(({ context, currentTime }) => {
    const { formatMessage } = useIntl();

    const { stack, blockId: blockIds, ...contextWithoutStack } = context;

    const nodes = useAppSelector(workspaceSelectors.getSchemaNodesByIds(blockIds));

    const contextItems = Object.keys(contextWithoutStack).map((item, index) => {
        return (
            <li key={`event-log-context-item-${index}-${currentTime}`}>
                {item}:{context[item]}
            </li>
        );
    });

    return (
        <ul>
            {contextItems}
            {stack !== undefined && (
                <li key={`event-log-context-item-stacktrace-${currentTime}`}>
                    <SStacktraceTitle>
                        {formatMessage(messages[TranslationKey.JOURNAL_STACKTRACE_TITLE])}:
                    </SStacktraceTitle>
                    <SStacktrace>{stack}</SStacktrace>
                </li>
            )}
            {nodes.length > 0 && (
                <li key={`event-log-context-item-block-id-${currentTime}`}>
                    <ul>
                        {nodes.map((node) => (
                            <li key={node.id}>
                                {node.data.name} [{node.data.index}]
                            </li>
                        ))}
                    </ul>
                </li>
            )}
        </ul>
    );
});
