import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { HandleType, Position } from 'reactflow';

import { CANVAS, extractNumbers } from '@repeat/constants';
import { PortPositions, TLibraryType, TPortPosition, TPortType, TSchemaHandle, TSchemaNode } from '@repeat/models';
import { TPortTypeComplex } from 'libs/models/src/lib/libraryItem';

export interface INodeViewData {
    width: number;
    height: number;
    rotation: number;
    handleMargin: number;
}

export interface IHandleViewData {
    id: string;
    type: HandleType;
    position: Position;
    style: CSSProperties;
    meta: { libraries: TLibraryType[]; type: TPortType; name: string; isConnected: boolean } & Omit<
        TPortTypeComplex,
        'type'
    >;
}

export interface IElementViewData {
    node: INodeViewData;
    handles: IHandleViewData[];
}

interface IPortsByPositionMap {
    [key: string]: TSchemaHandle[];
}

const NODE_WIDTH_DEFAULT = CANVAS.ELEMENT_MIN_WIDTH;
const NODE_HEIGHT_DEFAULT = CANVAS.ELEMENT_MIN_HEIGHT;
const HANDLE_MARGIN = CANVAS.PORT_MARGIN;

const getPortPosition = (position: TPortPosition, rotation: number): TPortPosition => {
    if (position === PortPositions.TOP) {
        switch (rotation) {
            case 90:
                return PortPositions.RIGHT;
            case 180:
                return PortPositions.BOTTOM;
            case 270:
                return PortPositions.LEFT;
            case -90:
                return PortPositions.LEFT;
            case -180:
                return PortPositions.BOTTOM;
            case -270:
                return PortPositions.RIGHT;
            case 0:
            default:
                return position;
        }
    }
    if (position === PortPositions.RIGHT) {
        switch (rotation) {
            case 90:
                return PortPositions.BOTTOM;
            case 180:
                return PortPositions.LEFT;
            case 270:
                return PortPositions.TOP;
            case -90:
                return PortPositions.TOP;
            case -180:
                return PortPositions.LEFT;
            case -270:
                return PortPositions.BOTTOM;
            case 0:
            default:
                return position;
        }
    }
    if (position === PortPositions.BOTTOM) {
        switch (rotation) {
            case 90:
                return PortPositions.LEFT;
            case 180:
                return PortPositions.TOP;
            case 270:
                return PortPositions.RIGHT;
            case -90:
                return PortPositions.RIGHT;
            case -180:
                return PortPositions.TOP;
            case -270:
                return PortPositions.LEFT;
            case 0:
            default:
                return position;
        }
    }
    if (position === PortPositions.LEFT) {
        switch (rotation) {
            case 90:
                return PortPositions.TOP;
            case 180:
                return PortPositions.RIGHT;
            case 270:
                return PortPositions.BOTTOM;
            case -90:
                return PortPositions.BOTTOM;
            case -180:
                return PortPositions.RIGHT;
            case -270:
                return PortPositions.TOP;
            case 0:
            default:
                return position;
        }
    }

    return position;
};

const calculatePortsLength = (portsCount: number, handleMargin: number) => portsCount * handleMargin * 2; // с двух сторон от порта одинаковые отступы равные шагу сетки

const calculateNodeViewData = (portsByPositionMap: IPortsByPositionMap, nodeViewData: INodeViewData): INodeViewData => {
    let width = nodeViewData.width;
    let height = nodeViewData.height;
    Object.keys(portsByPositionMap).forEach((position: TPortPosition) => {
        const onePositionPorts = portsByPositionMap[position];
        const portsCount = onePositionPorts.length;
        const portsLength = calculatePortsLength(portsCount, nodeViewData.handleMargin);

        // если ширина / высота портов с отступами больше ширины / высоты блока, то увеличиваем ширину / высоту блока
        if ([PortPositions.TOP, PortPositions.BOTTOM].includes(position)) {
            width = width >= portsLength ? width : portsLength;
        } else {
            height = height >= portsLength ? height : portsLength;
        }
    });

    return {
        ...nodeViewData,
        width,
        height,
    };
};

const calculateHandleStyle = (
    portNumber: number,
    position: TPortPosition,
    portsCount: number,
    nodeViewData: INodeViewData,
    isConnected: boolean
) => {
    const portsLength = calculatePortsLength(portsCount, nodeViewData.handleMargin);
    const sideLength = [PortPositions.TOP, PortPositions.BOTTOM].includes(position)
        ? nodeViewData.width
        : nodeViewData.height;
    const startHandlePosition = (sideLength - portsLength) / 2 + nodeViewData.handleMargin;
    const handlePosition = startHandlePosition + nodeViewData.handleMargin * 2 * portNumber;
    const handlePositionDelta = 6;

    if ([PortPositions.TOP, PortPositions.BOTTOM].includes(position)) {
        let styles: CSSProperties = {
            left: handlePosition,
        };
        if (Math.abs(nodeViewData.rotation) > 0) {
            const leftDelta = nodeViewData.rotation % 180 === 0 ? 0 : -handlePositionDelta;
            const topDelta = nodeViewData.rotation % 180 === 0 ? -handlePositionDelta : 0;

            styles = {
                ...styles,
                left: handlePosition + leftDelta,
                top: (PortPositions.TOP === position ? 0 : nodeViewData.height) + topDelta,
            };
        }
        return styles;
    }

    let styles: CSSProperties = {
        top: handlePosition,
    };
    if (Math.abs(nodeViewData.rotation) > 0) {
        const leftDelta = nodeViewData.rotation % 180 === 0 ? -handlePositionDelta : 0;
        const topDelta = nodeViewData.rotation % 180 === 0 ? 0 : -handlePositionDelta;

        styles = {
            ...styles,
            left: (PortPositions.LEFT === position ? 0 : nodeViewData.width) + leftDelta,
            top: handlePosition + topDelta,
        };
    }

    return styles;
};

const convertPositionToHandlePosition = (position: TPortPosition): Position => {
    let portPosition;
    switch (position) {
        case PortPositions.TOP:
            portPosition = Position.Top;
            break;
        case PortPositions.RIGHT:
            portPosition = Position.Right;
            break;
        case PortPositions.BOTTOM:
            portPosition = Position.Bottom;
            break;
        case PortPositions.LEFT:
        default:
            portPosition = Position.Left;
            break;
    }
    return portPosition;
};

const calculateHandlesViewData = (
    portsByPositionMap: IPortsByPositionMap,
    nodeViewData: INodeViewData
): IHandleViewData[] => {
    let portsCounter = 0;
    let allHandles: IHandleViewData[] = [];

    Object.keys(portsByPositionMap).forEach((position: string) => {
        const onePositionPorts = portsByPositionMap[position];
        const portsCount = onePositionPorts.length;
        const handles = onePositionPorts.map((port, index) => {
            const portType = 'source';
            const portPosition = convertPositionToHandlePosition(getPortPosition(port.position, nodeViewData.rotation));
            const handleStyle = calculateHandleStyle(index, port.position, portsCount, nodeViewData, port.isConnected);

            portsCounter++;

            const { libraries, type, name, isConnected, compatibleTypes } = port;

            return {
                id: `${extractNumbers(port.name)}`,
                type: portType,
                position: portPosition,
                style: handleStyle,
                meta: { libraries, type, name, isConnected, compatibleTypes },
            } as IHandleViewData;
        });
        allHandles = [...allHandles, ...handles];
    });
    return allHandles;
};

export const useInitNodeViewData = (
    element: TSchemaNode,
    changedValues: { width: number; height: number; rotated: boolean; rotation: number },
    updateNodeInternals?: () => void
): { isInitialized: boolean; viewData: IElementViewData } => {
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    const defaultNodeViewData: IElementViewData = {
        node: {
            width: element?.data?.view?.minWidth || NODE_WIDTH_DEFAULT,
            height: element?.data?.view?.minHeight || NODE_HEIGHT_DEFAULT,
            handleMargin: element?.data?.view?.portMargin || HANDLE_MARGIN,
            rotation: element?.rotation || 0,
        },
        handles: [],
    };
    const [viewData, setViewData] = useState<IElementViewData>(defaultNodeViewData);

    const changedViewData: IElementViewData = useMemo(
        () => ({
            ...viewData,
            node: {
                ...viewData.node,
                height: changedValues.height,
                width: changedValues.width,
                rotation: changedValues.rotation,
            },
        }),
        [changedValues]
    );

    const handlesViewData = useMemo(() => {
        const ports = element?.data.availablePorts || [];
        const portsByPositionMap: IPortsByPositionMap = {};
        ports.forEach((port: TSchemaHandle) => {
            if (!portsByPositionMap[port.position]) {
                portsByPositionMap[port.position] = [];
            }
            portsByPositionMap[port.position] = [...portsByPositionMap[port.position], port];
        });
        const nodeViewData = calculateNodeViewData(portsByPositionMap, changedViewData.node);
        return calculateHandlesViewData(portsByPositionMap, nodeViewData);
    }, [element, changedViewData]);

    useEffect(() => {
        const newNodeSize = {
            width: changedValues.width,
            height: changedValues.height,
        };
        const newViewData = {
            ...viewData,
            node: {
                ...viewData.node,
                ...(changedValues && { ...newNodeSize }),
            },
            handles: handlesViewData,
        };

        setViewData(newViewData);

        updateNodeInternals && updateNodeInternals();
    }, [changedValues]);

    useEffect(() => {
        const newNodeSize = {
            width: changedValues.width,
            height: changedValues.height,
        };
        const newViewData = {
            ...viewData,
            node: {
                ...viewData.node,
                ...(changedValues && { ...newNodeSize }),
            },
            handles: handlesViewData,
        };

        setViewData(newViewData);

        updateNodeInternals && updateNodeInternals();

        setIsInitialized(true);
    }, [isInitialized]);

    useEffect(() => {
        if (isInitialized) {
            setIsInitialized(false);
        }
    }, [element?.data.availablePorts]);

    return { isInitialized, viewData };
};
