import { useState } from 'react';
import ReactAutoSuggest from 'react-autosuggest';

import { SAutoSuggestWrapper } from './SAutoSuggest';

const ReactAutoSuggestInstance = ReactAutoSuggest as { new <T>(): ReactAutoSuggest<T | null> };

interface IAutoSuggestProps<T> {
    getSuggestions: (value: string) => T[];
    onSelect: (suggestion: T | null) => void;
    getSuggestionTitle: (suggestion: T | null) => string;
    renderSuggestion: (suggestion: T | null) => string | React.ReactNode;
    placeholder: string;
    width?: number;
    height?: number;
    isUpwards?: boolean;
    isAlwaysRenderSuggestions?: boolean;
    selectedSuggestion?: T | null;
}

export const AutoSuggest = <T,>(props: IAutoSuggestProps<T>) => {
    const selectedValue = props.selectedSuggestion !== undefined ? props.getSuggestionTitle(props.selectedSuggestion) : '';
    const [value, setValue] = useState<string>(selectedValue);
    const [suggestions, setSuggestions] = useState<T[]>([]);

    return (
        <SAutoSuggestWrapper width={props.width} height={props.height} isUpwards={props.isUpwards}>
            <ReactAutoSuggestInstance
                alwaysRenderSuggestions={props.isAlwaysRenderSuggestions || false}
                suggestions={suggestions}
                onSuggestionsClearRequested={() => setSuggestions([])}
                onSuggestionsFetchRequested={({ value }) => {
                    setValue(value);
                    setSuggestions(props.getSuggestions(value));
                }}
                onSuggestionSelected={(_, { suggestion }) => {
                    props.onSelect(suggestion);
                }}
                getSuggestionValue={props.getSuggestionTitle}
                renderSuggestion={props.renderSuggestion}
                inputProps={{
                    placeholder: props.placeholder,
                    value: value,
                    onChange: (_, { newValue, method }) => {
                        setValue(newValue);
                    },
                    autoFocus: true,
                }}
                highlightFirstSuggestion={true}
            />
        </SAutoSuggestWrapper>
    );
};
