import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.WORKSPACE_WARNING_CHART_START]: {
        id: TranslationKey.WORKSPACE_WARNING_CHART_START,
        defaultMessage: ru[TranslationKey.WORKSPACE_WARNING_CHART_START],
    },
    [TranslationKey.WORKSPACE_CHART_POINT_VALUE]: {
        id: TranslationKey.WORKSPACE_CHART_POINT_VALUE,
        defaultMessage: ru[TranslationKey.WORKSPACE_CHART_POINT_VALUE],
    },
    [TranslationKey.UNIT_SECOND]: {
        id: TranslationKey.UNIT_SECOND,
        defaultMessage: ru[TranslationKey.UNIT_SECOND],
    },
    [TranslationKey.WORKSPACE_CHART_SETUP_PARAMETER]: {
        id: TranslationKey.WORKSPACE_CHART_SETUP_PARAMETER,
        defaultMessage: ru[TranslationKey.WORKSPACE_CHART_SETUP_PARAMETER],
    },
});
