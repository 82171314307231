import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { uiRGBColors, pxToRem, uiColors } from '../../config/config';
import { TInputVariant } from '../InputField/InputField';

export type TToggleSizes = 'small' | 'medium' | 'big';
export type TToggleHtmlType = 'button' | 'submit';

interface IStyledToggle {
    isChecked: boolean;
    size: TToggleSizes;
    disabled?: boolean;
    variant?: TInputVariant;
}

const boxSizesConfig = {
    small: { width: pxToRem(32), height: pxToRem(16) },
    medium: { width: pxToRem(40), height: pxToRem(24) },
    big: { width: pxToRem(48), height: pxToRem(32) },
};

const bounceSizesConfig = {
    small: { width: pxToRem(14), height: pxToRem(14), left: 'calc(100% - 14px)' },
    medium: { width: pxToRem(22), height: pxToRem(22), left: 'calc(100% - 22px)' },
    big: { width: pxToRem(30), height: pxToRem(30), left: 'calc(100% - 30px)' },
};

const StyledToggleBox = styled.button<IStyledToggle>`
    align-items: center;
    border-radius: 16px;
    border: 1px solid #aaa;
    cursor: ${({ disabled }) => !disabled && 'pointer'};
    ${({ variant }) => variant === 'success' && `border-color: ${uiColors.green} !important`};
    ${({ variant }) => variant === 'warning' && `border-color: ${uiColors.yellow} !important`};
    display: flex;
    height: ${({ size }) => boxSizesConfig[size].height};
    position: relative;
    width: ${({ size }) => boxSizesConfig[size].width};
    background: ${({ isChecked }) => (!isChecked ? `${uiColors.grey}` : `${uiColors.mainBlue}`)};
`;

const StyledToggleBounce = styled.div<IStyledToggle>`
    background: rgb(${uiRGBColors.white});
    border-radius: 16px;
    height: ${({ size }) => bounceSizesConfig[size].height};
    position: absolute;
    left: ${({ isChecked, size }) => (!isChecked ? 0 : bounceSizesConfig[size].left)};
    top: 0;
    transition: left 0.3s ease;
    width: ${({ size }) => bounceSizesConfig[size].width};

    &:hover {
        background: rgb(${uiRGBColors.whiteSecond});
    }
`;

const StyledToggleText = styled.span`
    font-size: ${pxToRem(14)};
    margin-right: ${pxToRem(8)};
`;

const StyledToggleWrapper = styled.div`
    align-items: center;
    display: flex;
    position: relative;
`;

interface IToggle {
    variant?: TInputVariant;
    label?: React.ReactNode | string;
    isChecked?: boolean;
    size?: TToggleSizes;
    htmlType?: TToggleHtmlType;
    onClick: (value: boolean) => void;
    disabled?: boolean;
}

export const Toggle: React.FC<IToggle> = ({
    isChecked = false,
    onClick,
    label,
    size = 'medium',
    htmlType = 'submit',
    variant,
    disabled,
}) => {
    const [checked, setChecked] = useState(isChecked || false);
    useEffect(() => {
        setChecked(isChecked);
    }, [isChecked]);
    const handleCheck = (value: boolean) => {
        if (!disabled) {
            setChecked(!checked);
            onClick(!checked);
        }
    };

    return (
        <StyledToggleWrapper data-type={'toggle'} onClick={() => handleCheck(checked)}>
            {label && <StyledToggleText>{label}</StyledToggleText>}
            <StyledToggleBox disabled={disabled} variant={variant} size={size} type={htmlType} isChecked={checked}>
                <StyledToggleBounce size={size} isChecked={checked} />
            </StyledToggleBox>
        </StyledToggleWrapper>
    );
};
