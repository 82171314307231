import { FormLabel } from '@mui/material';
import styled from '@mui/material/styles/styled';

import { uiRGBColors, pxToRem, uiColors } from '../../config/config';

type ValueOf<T> = T[keyof T];

export interface IStyledFormLabelProps {
    textcolor?: ValueOf<typeof uiRGBColors>;
    font?: string;
    textalign?: CSSStyleDeclaration['textAlign'];
    lineheight?: CSSStyleDeclaration['lineHeight'];
    weight?: number | 'normal' | 'bold' | 'regular';
}

const StyledFormLabel = styled(FormLabel)<IStyledFormLabelProps>`
    box-sizing: border-box;
    margin: ${pxToRem(20)} 0;
    font-weight: ${({ weight }) => (weight ? weight : 700)};
    font-size: ${({ font: fontsize }) => (fontsize ? fontsize : pxToRem(14))};
    color: ${({ textcolor }) => (textcolor ? textcolor : uiColors.greyPrimary)};
    text-align: ${({ textalign }) => (textalign ? textalign : 'initial')};
    line-height: ${({ lineheight }) => (lineheight ? lineheight : 'initial')};
`;

const StyledFormLabelLight = styled(StyledFormLabel)`
    margin: ${pxToRem(20)} 0;
    font-weight: bolder;
    color: ${uiColors.dark};
`;

export { StyledFormLabel, StyledFormLabelLight };
