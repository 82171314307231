import styled from 'styled-components';

import { pxToRem, radius, uiColors } from '../../config/config';

export const LowAccent = styled.span`
    color: var(--ui-label) !important;
`;

export const RegularAccent = styled.span`
    color: var(--ui-link-default) !important;
`;

export const HighAccent = styled.span`
    color: var(--ui-text) !important;
`;

export const ErrorAccent = styled.span`
    align-items: center;
    background: ${uiColors.red};
    border-radius: ${radius.default};
    color: ${uiColors.white};
    display: flex;
    padding: ${pxToRem(16)} ${pxToRem(24)};
    width: 100%;
`;
