import styled from 'styled-components';

import { Panel } from '@reactflow/core';

import { pxToRem, uiColors } from '@repeat/ui-kit';

export const SControlsPanel = styled(Panel)`
    background: ${uiColors.white};
    display: flex;
    flex-direction: column;
    margin: ${pxToRem(16)};
`;
