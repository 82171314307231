import { Icon, InputField, pxToRem, uiColors } from '@repeat/ui-kit';
import styled from 'styled-components';

export const SGroupNameBlock = styled.div<{ isInput: boolean }>`
    position: relative;
    /* margin-top: ${pxToRem(16)}; */
    margin-right: ${({ isInput }) => isInput && pxToRem(16)};
`;

export const SGroupName = styled.div`
    align-items: center;
    font-size: '14px';
    color: ${uiColors.mainBlue};
    border: 0;
    background-color: transparent;
`;

export const SButtons = styled.div`
    position: absolute;
    top: 0;
    right: -${pxToRem(16)};
`;

export const SInput = styled(InputField)``;

export const SElementId = styled.div`
    font-size: ${pxToRem(10)};
    color: ${uiColors.grey};
`;

export const SIconTab = styled(Icon)`
    margin-right: ${pxToRem(8)};
`;

export const SElementName = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: ${pxToRem(14)};
    color: var(--ui-link-default);
    border: 0;
    background-color: transparent;
    margin-top: ${pxToRem(16)};
`;

export const SOptionsWrapper = styled.div`
    font-size: ${pxToRem(14)};
    padding-bottom: ${pxToRem(32)};
`;

export const SToolsTabsWrapper = styled.div`
    .react-tabs {
        .react-tabs__tab-list {
            margin-bottom: ${pxToRem(24)};
        }

        .react-tabs__tab {
            background-color: transparent;
            color: var(--ui-link-active) !important;
            border-radius: 0;
            border: none !important;

            &::after {
                display: none !important;
            }

            &.react-tabs__tab--selected {
                color: var(--ui-link-default) !important;
                border-bottom: 2px solid var(--ui-link-default) !important;

                svg {
                    fill: var(--ui-link-default) !important;
                }
            }
        }
    }
`;
