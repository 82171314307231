import { FC, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import Button from '@mui/material/Button';

import { ApplicationActions } from '@repeat/common-slices';
import { getElementNotification } from '@repeat/constants';
import { useAppDispatch, useAppSelector, useDemoMode } from '@repeat/hooks';
import { NotificationTypes, TElement, WorkspaceModes } from '@repeat/models';
import {
    elementsSelectors,
    setSubmodelItems,
    showElementConfigurationsModal,
    workspaceActions,
    workspaceSelectors,
} from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { Icon, ShowMore, pxToRem, uiColors } from '@repeat/ui-kit';

import { ElementParameters } from './PropertiesAndParameters/ElementParameters';
import { ElementProperties } from './PropertiesAndParameters/ElementProperties';
import { IndicatorParameterForm } from './PropertiesAndParameters/IndicatorParameterForm';
import { ParametersSettings } from './PropertiesAndParameters/ParametersSettings';
import { SButtons, SSelectedText } from './SNodeCurrent';
import { messages } from './translation';
import { PropertiesSettings } from './PropertiesAndParameters/PropertiesSettings';

import { useElementNotificationMessage } from '../../hooks/useElementNotificationMessage';

export const NodeCurrent: FC = () => {
    const { formatMessage } = useIntl();
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const selectedItems = useAppSelector(elementsSelectors.getSelectedElements);
    const selectedNode = selectedItems.elements[0];
    const [isPropertiesSettingsBlockVisible, setIsPropertiesSettingsBlockVisible] = useState(false);

    const isJythonSelected = selectedNode?.data?.type === 'jython'; //TODO add universal flag to similar blocks
    const isProjectBlockSelected = selectedNode?.data?.type === 'project';
    const isGroupBlockSelected = selectedNode?.data?.type === 'group';

    const workspaceMode = useAppSelector(workspaceSelectors.workspaceMode);
    const workspaceMetaUserBlocokId = useAppSelector(workspaceSelectors.workspaceMetaUserBlockId)
    const { isDemo } = useDemoMode([workspaceMode, workspaceMetaUserBlocokId]);

    const hasConfigurations = selectedNode?.data?.hasConfigurations;
    const selectedConfiguration = selectedNode?.data?.selectedConfiguration;
    const selectTypeHandler = () => {
        dispatch(showElementConfigurationsModal());
    };

    const handleDelete = () => {
        dispatch(workspaceActions.deleteSchemaItems());
    };

    const getNotificationType = useCallback((element: TElement) => getElementNotification(element), []);
    const notificationType = selectedNode ? getNotificationType(selectedNode.data) : null;

    const hasCodeEditorMode = selectedNode
        ? selectedNode.data.elemProps.filter((property) => property.type === 'code').length > 0
        : false;

    const handleEditCode = useCallback(() => {
        dispatch(
            workspaceActions.changeWorkspaceMode({ mode: WorkspaceModes.CODE_EDITOR, elementId: selectedNode.id })
        );
    }, [selectedNode]);

    const handleEditProjectBlock = () => {
        if (workspaceMode === WorkspaceModes.SUBMODEL) {
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.WARNING,
                        message: TranslationKey.WORKSPACE_VIEW_MODE_ACTION_NOT_ALLOWED,
                    },
                })
            );
            return;
        }
        if (selectedNode.data.diff?.isErrorGettingProject) {
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: TranslationKey.WORKSPACE_ERROR_GETTING_PROJECT_DATA,
                    },
                })
            );
            return;
        }
        dispatch(workspaceActions.changeWorkspaceMode({ mode: WorkspaceModes.SUBMODEL, elementId: selectedNode.id }));
        const projectId = selectedNode.data.elemProps.find((prop) => prop.name === 'projectId')?.value;
        if (projectId) {
            dispatch(setSubmodelItems({ projectId: Number(projectId) }));
        }
    };

    const handleBackToSchemaClick = useCallback(() => {
        dispatch(workspaceActions.changeWorkspaceMode({ mode: WorkspaceModes.MAIN, userBlockId: null }));
    }, []);

    const { getMessage } = useElementNotificationMessage(notificationType);

    return (
        <>
            {notificationType !== null &&
                (selectedNode?.data.isDeprecated ? (
                    <ShowMore>{getMessage(notificationType)}</ShowMore>
                ) : (
                    <ShowMore title={formatMessage(messages[TranslationKey.ELEMENT_ATTENTION_LABEL])}>
                        {getMessage(notificationType)}
                    </ShowMore>
                ))}

            {selectedNode?.data.isIndicator && workspaceMode !== WorkspaceModes.SUBMODEL && !isDemo && (
                <IndicatorParameterForm indicatorId={selectedNode?.data.id} />
            )}
            {hasConfigurations && (
                <ShowMore title={formatMessage(messages[TranslationKey.ELEMENT_CONFIGURATION])}>
                    {selectedConfiguration?.id !== null ? (
                        <SSelectedText>{selectedConfiguration?.name}</SSelectedText>
                    ) : (
                        <SSelectedText>
                            {formatMessage(messages[TranslationKey.ELEMENT_CONFIGURATION_CUSTOMIZABLE])}
                        </SSelectedText>
                    )}
                    {workspaceMode !== WorkspaceModes.SUBMODEL && !isDemo && (
                        <Button type='submit' onClick={selectTypeHandler}>
                            {formatMessage(messages[TranslationKey.CHANGE])}
                        </Button>
                    )}
                </ShowMore>
            )}
            {workspaceMode !== WorkspaceModes.CODE_EDITOR && !selectedNode?.data.diff?.isErrorGettingProject && (
                <>
                    <ElementProperties />
                    {isGroupBlockSelected && (
                        <>
                            {!isPropertiesSettingsBlockVisible ? (
                                !isDemo && (
                                    <Button
                                        style={{
                                            marginLeft: pxToRem(16),
                                            marginTop: `-${pxToRem(16)}`,
                                            padding: 0,
                                        }}
                                        onClick={() => setIsPropertiesSettingsBlockVisible(true)}
                                    >
                                        {intl.formatMessage(messages[TranslationKey.WORKSPACE_ADD_PROPERTY])}
                                    </Button>
                                )
                            ) : (
                                <Button
                                    style={{
                                        marginLeft: pxToRem(16),
                                        marginTop: `-${pxToRem(16)}`,
                                        padding: 0,
                                    }}
                                    onClick={() => setIsPropertiesSettingsBlockVisible(false)}
                                >
                                    {intl.formatMessage(messages[TranslationKey.HIDE])}
                                </Button>
                            )}{' '}
                            {isPropertiesSettingsBlockVisible && (
                                <PropertiesSettings setVisibility={setIsPropertiesSettingsBlockVisible} />
                            )}
                        </>
                    )}
                </>
            )}
            {isJythonSelected ? (
                <ParametersSettings />
            ) : (
                !selectedNode?.data.diff?.isErrorGettingProject && <ElementParameters />
            )}

            {workspaceMode !== WorkspaceModes.SUBMODEL && (
                <ShowMore title={formatMessage(messages[TranslationKey.WORKSPACE_ELEMENT_ACTIONS])}>
                    {(workspaceMode === WorkspaceModes.MAIN || workspaceMode === WorkspaceModes.GROUP) &&
                        hasCodeEditorMode && (
                            <Button onClick={handleEditCode} type='button'>
                                <Icon name='edit' fill={uiColors.mainBlue} />
                                &nbsp;{formatMessage(messages[TranslationKey.SCHEMA_ELEMENT_EDIT_CODE])}
                            </Button>
                        )}
                    <SButtons>
                        {(workspaceMode === WorkspaceModes.MAIN || workspaceMode === WorkspaceModes.GROUP) &&
                            isProjectBlockSelected &&
                            !selectedNode.data.diff?.isErrorGettingProject && (
                                <Button onClick={handleEditProjectBlock} type='button'>
                                    <Icon name='edit' fill={uiColors.mainBlue} />
                                    &nbsp;{formatMessage(messages[TranslationKey.WORKSPACE_PROJECT_BLOCK_VIEW])}
                                </Button>
                            )}
                        {workspaceMode === WorkspaceModes.CODE_EDITOR && (
                            <Button onClick={handleBackToSchemaClick} type='button'>
                                <Icon name='back' fill={uiColors.mainBlue} />
                                &nbsp;{formatMessage(messages[TranslationKey.SCHEMA_ELEMENT_BACK_TO_SCHEMA])}
                            </Button>
                        )}
                        {!isDemo && (
                            <Button onClick={handleDelete} type='button'>
                                <Icon name='close' fill={uiColors.mainBlue} />
                                &nbsp;{formatMessage(messages[TranslationKey.DELETE])}
                            </Button>
                        )}
                    </SButtons>
                </ShowMore>
            )}
        </>
    );
};
