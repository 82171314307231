import { current, PayloadAction } from '@reduxjs/toolkit';
import { diff } from 'deep-object-diff';
import { Data } from 'plotly.js';

import { MAX_CHART_HEIGHT } from '@repeat/constants';
import {
    EChartItemType,
    IChartEditorParameters,
    IChartItem,
    IChartItemParameter,
    IChartItemParameters,
    IChartList,
    IChartsDataMap,
    IGraphsState,
    IWorkspaceState,
    makeDefaultChartItem,
    ModelStatuses,
    Statuses,
    TChartsData,
    TrendsStatuses,
} from '@repeat/models';

interface IReIndexedObject<T> {
    [key: string]: T;
}

const getWindowItemWidthCoefficient = (currentWidth?: number) => {
    const width = currentWidth || window.innerWidth;
    let result = 3;
    if (width <= 768) {
        result = 1;
    } else if (width <= 1024) {
        result = 2;
    } else if (width <= 1200) {
        result = 3;
    }
    return result;
};

const reindexKey = (list: IReIndexedObject<unknown>) => {
    const keys = Object.keys(list);
    const reIndexedObjectKeys: IReIndexedObject<unknown> = {};
    keys.forEach((key, index) => {
        reIndexedObjectKeys[index] = list[key];
    });
    return reIndexedObjectKeys;
};

export const getElementID = (string: string) => string.replace(/_(in|out)_\d/gi, '');

/**
 * 9 - offset margin for grid item width
 */
const setChartWidth = () => window && window.innerWidth / getWindowItemWidthCoefficient();
/**
 * 0.66 - percent of half height of screen as in a grid component
 */
const setChartHeight = () => MAX_CHART_HEIGHT;

export const chartsReducer = {
    setChartDataMap: (state: IWorkspaceState, { payload: { dataMap } }: PayloadAction<{ dataMap: IChartsDataMap }>) => {
        return {
            ...state,
            graphs: { ...state.graphs, dataMap },
        };
    },
    addEmptyChart: (state: IWorkspaceState, { payload: { uuid } }: PayloadAction<{ uuid: string }>) => {
        const modelStatus = state.modelControl.modelStatus;
        const isActiveModelling = [ModelStatuses.RUN, ModelStatuses.DATA, ModelStatuses.FREEZE].includes(modelStatus);

        const oldCharts = state.graphs.charts;
        const newChartKeys = Object.keys(oldCharts);
        const index = newChartKeys.length;
        const width = setChartWidth();
        const height = setChartHeight();
        const newChart = makeDefaultChartItem(uuid, width, height, isActiveModelling);
        const charts = { ...oldCharts, [index]: newChart };
        return {
            ...state,
            graphs: { ...state.graphs, charts },
        };
    },
    removeChart: (state: IWorkspaceState, { payload: { index } }: PayloadAction<{ index: number }>) => {
        const oldCharts = state.graphs.charts;
        const newCharts: IChartList = {};
        const chart = oldCharts[index] || null;
        Object.keys(oldCharts).forEach((chart, chartIndex) => {
            if (index !== chartIndex) {
                newCharts[chartIndex] = oldCharts[chartIndex];
            }
        });
        const reIndexedResult = reindexKey(newCharts as IReIndexedObject<IChartItem>) as IChartList;

        const dataMapBefore = state.graphs.dataMap;
        const dataMapItem = chart ? dataMapBefore[chart.uuid] || null : null;
        const dataIndex = dataMapItem ? dataMapItem.index : -1;

        const dataMap = Object.keys(state.graphs.dataMap)
            .filter((uuid) => uuid !== chart.uuid)
            .reduce(
                (current, key) =>
                    Object.assign(current, {
                        [key]: {
                            ...state.graphs.dataMap[key],
                            index:
                                dataIndex >= 0 && state.graphs.dataMap[key].index > dataIndex
                                    ? state.graphs.dataMap[key].index - 1
                                    : state.graphs.dataMap[key].index,
                        },
                    }),
                {} as IChartsDataMap
            );
        return {
            ...state,
            graphs: {
                ...state.graphs,
                charts: reIndexedResult,
                dataMap,
                data: state.graphs.data
                    ? [...state.graphs.data.slice(0, index), ...state.graphs.data.slice(index + 1)]
                    : null,
            },
        };
    },
    addParametersChart: (
        state: IWorkspaceState,
        { payload: { index, uuid, YParameters, XParameters } }: PayloadAction<IChartItemParameters>
    ) => {
        const modelStatus = state.modelControl.modelStatus;
        const newCharts = { ...current(state).graphs.charts };
        const data = current(state).graphs.data;
        const isActiveModelling = [ModelStatuses.RUN, ModelStatuses.DATA, ModelStatuses.FREEZE].includes(modelStatus);
        const dataMapIndex = data && isActiveModelling ? data.length : index;
        const { modelTime: modelTimeMs } = state.modelControl;
        const graphsLastTime = state.modelControl.modelStatus !== ModelStatuses.STOP ? state.graphs.fetchLastTime : 0;

        const isNeedAddX = XParameters.key !== 'time';
        const modelNames = [...(isNeedAddX ? [XParameters.key] : []), ...YParameters.map((item) => item && item.key)];
        const elementIDs = new Set();
        [...YParameters, XParameters].forEach((item) => {
            if (item && item.key && item.key !== 'time') {
                elementIDs.add(getElementID(item.key));
            }
        });
        newCharts[index] = {
            ...newCharts[index],
            type: isNeedAddX ? EChartItemType.YFromX : EChartItemType.YFromT,
            YParameters,
            XParameters,
            modelNames,
            timeStart: modelTimeMs,
            timeEnd: modelTimeMs * 10,
            elementID: YParameters.length === 1 ? getElementID(YParameters[0].key) : null,
            elementIDs: Array.from(elementIDs),
        } as IChartItem;

        const dataMap = {
            ...state.graphs.dataMap,
            [uuid]: {
                index: dataMapIndex,
                lateTimeStart: graphsLastTime,
                isReady: !(graphsLastTime > 0),
            },
        };

        return {
            ...state,
            graphs: { ...state.graphs, charts: newCharts, dataMap },
        };
    },
    addChartWithParameters: (
        state: IWorkspaceState,
        { payload: { index, uuid, YParameters, XParameters } }: PayloadAction<IChartItemParameters>
    ) => {
        const modelStatus = state.modelControl.modelStatus;
        const isActiveModelling = [ModelStatuses.RUN, ModelStatuses.DATA, ModelStatuses.FREEZE].includes(modelStatus);

        const newCharts = { ...current(state).graphs.charts };
        const data = current(state).graphs.data;

        const dataMapIndex = data && isActiveModelling ? data.length : index;
        const { modelTime: modelTimeMs } = state.modelControl;
        const graphsLastTime = state.modelControl.modelStatus !== ModelStatuses.STOP ? state.graphs.fetchLastTime : 0;

        const modelNames = [...YParameters.map((item) => item && item.key)];
        const elementIDs = new Set();
        [...YParameters].forEach((item) => {
            if (item && item.key && item.key !== 'time') {
                elementIDs.add(getElementID(item.key));
            }
        });
        const width = setChartWidth();
        const height = setChartHeight();
        const newChart = makeDefaultChartItem(uuid, width, height, isActiveModelling);
        newCharts[index] = {
            ...newChart,
            uuid,
            type: EChartItemType.YFromT,
            YParameters,
            XParameters,
            modelNames,
            timeStart: modelTimeMs,
            timeEnd: modelTimeMs * 10,
            elementID: YParameters.length === 1 ? getElementID(YParameters[0].key) : null,
            elementIDs: Array.from(elementIDs),
        } as IChartItem;

        const dataMap = {
            ...state.graphs.dataMap,
            [uuid]: {
                index: dataMapIndex,
                lateTimeStart: graphsLastTime,
                isReady: !(graphsLastTime > 0),
            },
        };

        return {
            ...state,
            graphs: { ...state.graphs, charts: newCharts, dataMap },
        };
    },
    addPackChart: (
        state: IWorkspaceState,
        {
            payload: { list, XParameter },
        }: PayloadAction<{ list: IChartItemParameter[]; XParameter: IChartItemParameter }>
    ) => {
        const newCharts = { ...current(state).graphs.charts };
        const { modelTime: modelTimeMs } = state.modelControl;
        let newDataMap = { ...state.graphs.dataMap };
        const graphsLastTime = state.modelControl.modelStatus !== ModelStatuses.STOP ? state.graphs.fetchLastTime : 0;

        list.forEach((listItem) => {
            const modelNames = [listItem.key];
            const newIndex = Object.keys(newCharts).length;
            newCharts[newIndex] = {
                ...newCharts[newIndex],
                isEdit: newCharts[newIndex]?.isEdit || false,
                uuid: listItem.uuid,
                type: EChartItemType.YFromT,
                YParameters: [listItem],
                XParameters: XParameter,
                modelNames,
                timeStart: modelTimeMs,
                timeEnd: modelTimeMs * 10,
                elementIDs: [getElementID(listItem.key)],
            };
            newDataMap = {
                ...newDataMap,
                [listItem.uuid]: {
                    index: newIndex,
                    lateTimeStart: graphsLastTime,
                    isReady: !(graphsLastTime > 0),
                },
            };
        });

        return {
            ...state,
            graphs: {
                ...state.graphs,
                charts: newCharts,
                dataMap: newDataMap,
            },
        };
    },
    removePackChart: (
        state: IWorkspaceState,
        { payload: { list, elementID } }: PayloadAction<{ list: IChartItemParameter[]; elementID: string }>
    ) => {
        const newCharts = { ...current(state).graphs.charts };
        const newChartKeys = Object.keys(newCharts);
        const removeList = list.map((item) => item.key);

        const filteredCharts: IChartList = {};
        newChartKeys.forEach((key) => {
            const currentChart = newCharts[parseInt(key)];
            const { modelNames } = currentChart;
            modelNames.forEach((modelName) => {
                if (!removeList.includes(modelName)) {
                    filteredCharts[parseInt(key)] = currentChart;
                }
            });
        });

        return {
            ...state,
            graphs: {
                ...state.graphs,
                charts: filteredCharts,
            },
        };
    },
    setEditChart: (state: IWorkspaceState, { payload: { index } }: PayloadAction<{ index: number }>) => {
        const charts = { ...current(state).graphs.charts };

        const chart = {
            ...charts[index],
            isEdit: !charts[index].isEdit,
        } as IChartItem;
        charts[index] = chart;

        const oldDataMap = current(state).graphs.dataMap;

        if (chart.isEdit) {
            const chartDataMapIndex = oldDataMap[chart.uuid]?.index >= 0 ? oldDataMap[chart.uuid]?.index : -1;

            const dataMap = Object.keys(oldDataMap)
                .filter((k) => state.graphs.dataMap[k].index !== chartDataMapIndex)
                .reduce(
                    (current, key) =>
                        Object.assign(current, {
                            [key]: {
                                ...state.graphs.dataMap[key],
                                index:
                                    state.graphs.dataMap[key].index > chartDataMapIndex
                                        ? state.graphs.dataMap[key].index - 1
                                        : state.graphs.dataMap[key].index,
                            },
                        }),
                    {} as IChartsDataMap
                );

            return {
                ...state,
                graphs: {
                    ...state.graphs,
                    charts,
                    dataMap,
                    data:
                        state.graphs.data && chartDataMapIndex >= 0
                            ? [
                                  ...state.graphs.data.slice(0, chartDataMapIndex),
                                  ...state.graphs.data.slice(chartDataMapIndex + 1),
                              ]
                            : state.graphs.data
                            ? [...state.graphs.data]
                            : null,
                },
            };
        }

        const data = current(state).graphs.data;
        const modelStatus = state.modelControl.modelStatus;
        const graphsLastTime = state.modelControl.modelStatus !== ModelStatuses.STOP ? state.graphs.fetchLastTime : 0;
        const isActiveModelling = [ModelStatuses.RUN, ModelStatuses.DATA, ModelStatuses.FREEZE].includes(modelStatus);
        const chartDataMapIndex = data && isActiveModelling ? data.length : index;

        const dataMap = {
            ...oldDataMap,
            [chart.uuid]: {
                index: chartDataMapIndex,
                lateTimeStart: graphsLastTime,
                isReady: !(graphsLastTime > 0),
            },
        };

        return {
            ...state,
            graphs: {
                ...state.graphs,
                charts,
                dataMap,
                data:
                    state.graphs.data && chartDataMapIndex >= 0
                        ? [
                              ...state.graphs.data.slice(0, chartDataMapIndex),
                              ...state.graphs.data.slice(chartDataMapIndex + 1),
                          ]
                        : state.graphs.data
                        ? [...state.graphs.data]
                        : null,
            },
        };
    },
    setSizeCharts: (
        state: IWorkspaceState,
        { payload: { index, width, height } }: PayloadAction<{ width: number; height: number; index: number }>
    ) => {
        const newCharts = { ...current(state).graphs.charts };
        if (newCharts[index]) {
            newCharts[index] = {
                ...newCharts[index],
                width,
                height,
            } as IChartItem;

            return {
                ...state,
                graphs: { ...state.graphs, charts: newCharts },
            };
        }
        return {
            ...state,
        };
    },
    updateChartEditorLayout: (
        state: IWorkspaceState,
        { payload: { uuid, figure } }: PayloadAction<IChartEditorParameters>
    ) => {
        const newCharts = { ...current(state).graphs.charts };
        let chartIndex: number | null = null;
        Object.keys(newCharts).forEach((value: string) => {
            const index = Number(value);
            const chart = newCharts[index];
            if (chart.uuid === uuid) {
                chartIndex = index;
            }
        });

        if (chartIndex === null) {
            return { ...state };
        }

        const newChart = newCharts[chartIndex];

        const legends = figure.data.map((value: Data) => {
            return value.name || '';
        });
        let shouldLegendsUpdate = false;
        if (legends.length > 0) {
            const currentLegends = newChart.YParameters.map((parameter: IChartItemParameter) => parameter.title);
            const legendsDiff = diff(legends, currentLegends);

            shouldLegendsUpdate = Object.keys(legendsDiff).length > 0;
        }

        const layout = structuredClone(figure.layout);

        let shouldLayoutUpdate = false;
        if (layout && newChart.layout) {
            const layoutDiff = diff(layout, newChart.layout);

            if (Object.keys(layoutDiff).length > 0) {
                shouldLayoutUpdate = true;
            }
        } else if (layout && !newChart?.layout) {
            shouldLayoutUpdate = true;
        }
        if (!shouldLayoutUpdate && !shouldLegendsUpdate) {
            return state;
        }

        const newYParameters = shouldLegendsUpdate
            ? newChart.YParameters.map((parameter: IChartItemParameter, index: number) => {
                  if (!legends[index]) {
                      return { ...parameter };
                  }

                  return {
                      ...parameter,
                      title: legends[index],
                  };
              })
            : [];

        newCharts[chartIndex] = {
            ...newCharts[chartIndex],
            ...(shouldLayoutUpdate && { layout }),
            ...(shouldLegendsUpdate && {
                YParameters: newYParameters,
            }),
        } as IChartItem;

        return {
            ...state,
            graphs: { ...state.graphs, charts: newCharts },
        };
    },
    updateCharts: (state: IWorkspaceState, { payload }: PayloadAction<IGraphsState>) => ({
        ...state,
        graphs: payload,
    }),
    fetchChartsDataRequest: (state: IWorkspaceState) => ({
        ...state,
        graphs: {
            ...state.graphs,
            fetchChartsDataStatus: Statuses.LOADING,
            fetchChartsDataError: null,
        },
    }),
    fetchChartsDataSuccess: (
        state: IWorkspaceState,
        action: PayloadAction<{ fetchLastTime: number; data: TChartsData[] | null }>
    ) => {
        const data =
            action.payload.data !== null
                ? ([
                      ...action.payload.data.map((itemData, index) => {
                          return state.graphs.data && state.graphs.data[index]
                              ? state.graphs.data[index].map((d, i) => [...d, ...itemData[i]])
                              : [...itemData];
                      }),
                  ] as TChartsData[])
                : state.graphs.data
                ? [...state.graphs.data]
                : null;

        return {
            ...state,
            graphs: {
                ...state.graphs,
                fetchChartsDataStatus: Statuses.SUCCEEDED,
                fetchChartsDataError: null,
                fetchChartsDataFailedAttempts: 0,
                fetchLastTime: action.payload.data !== null ? action.payload.fetchLastTime : state.graphs.fetchLastTime,
                data,
                commonStatus: TrendsStatuses.CONNECTED,
            },
        };
    },
    fetchChartsDataFailed: (state: IWorkspaceState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        graphs: {
            ...state.graphs,
            fetchChartsDataStatus: Statuses.FAILED,
            fetchChartsDataError: action.payload.error,
            fetchChartsDataFailedAttempts: state.graphs.fetchChartsDataFailedAttempts + 1,
        },
    }),
    updateChartsList: (state: IWorkspaceState, { payload }: PayloadAction<IChartList>) => ({
        ...state,
        graphs: {
            ...state.graphs,
            charts: payload,
        },
    }),
};
