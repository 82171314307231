import { Pagination } from '@mui/material';

import { STableFooter } from './styled/STableFooter';

type PaginationType = {
    onChange: (event: React.ChangeEvent<unknown>, page: number) => void;
    currentPage: number;
    count: number;
};

export const TableFooter = (props: PaginationType) => {
    const { onChange, currentPage, count } = props;

    const handlePageClick = (_: React.ChangeEvent<unknown>, page: number) => {
        onChange(_, page);
    };

    return (
        <STableFooter>
            <Pagination page={currentPage} count={count} onChange={handlePageClick} />
        </STableFooter>
    );
};
