import { createSelector } from '@reduxjs/toolkit';

import { LicenseActivationStatuses } from 'libs/models/src/lib/license';

import { RootState } from '../store';

export const isInitialized = (state: RootState) => state.app.isInitialized;
export const isLoading = (state: RootState) => state.app.isLoading;

export const getLicenseState = (state: RootState) => state.app.license;
export const getActiveLicenseData = (state: RootState) => state.app.license.activeLicense;
export const getNewLicenseData = (state: RootState) => state.app.license.newLicense;

export const hasActiveLicenseKey = createSelector(
    [getActiveLicenseData],
    (data) =>
        data !== null &&
        data.licenseKey !== null &&
        (data.activationStatus === LicenseActivationStatuses.COMPLETED ||
            (data.activationStatus === LicenseActivationStatuses.REQUIRED && data?.trialPeriodExpireAt !== undefined))
);
export const hasNewNotActivatedLicenseKey = createSelector(
    [getNewLicenseData],
    (data) => data !== null && data.licenseKey !== null && data.activationStatus === LicenseActivationStatuses.REQUIRED
);
export const hasTrialNotActivatedLicenseKey = createSelector(
    [getActiveLicenseData],
    (data) =>
        data !== null &&
        data.licenseKey !== null &&
        data.activationStatus === LicenseActivationStatuses.REQUIRED &&
        data?.trialPeriodExpireAt !== undefined
);
export const getActivatingLicenseData = createSelector(
    [getActiveLicenseData, getNewLicenseData],
    (activeLicense, newLicense) => {
        if (
            activeLicense?.activationStatus === LicenseActivationStatuses.REQUIRED &&
            activeLicense?.trialPeriodExpireAt !== undefined
        ) {
            return activeLicense;
        }

        return newLicense;
    }
);

export const getActivationCode = (state: RootState) => state.app.license.activationCode || null;
export const getActivationCodeState = (state: RootState) => state.app.license.getActivationCode || null;
export const getUploadLicenseFileState = (state: RootState) => state.app.license.uploadLicenseFile;
export const activateLicenseState = (state: RootState) => state.app.license.activateLicense;
