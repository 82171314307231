import React, { FC } from 'react';

import styled from 'styled-components';

import { hexToRGB } from '../../../../shared/constants/src/index';
import { pxToRem, radius, uiColors, zIndex } from '../../config/config';

import { verticalAlign } from '../../config/mixin';

export const SWrapperTable = styled.div`
    background-color: var(--ui-background);
    padding: ${pxToRem(16)};
    border-radius: ${radius.default};
`;

export const STable = styled.table`
    border: 0;
    border-collapse: collapse;
    border-spacing: ${pxToRem(8)};
    position: relative;
    empty-cells: hide;
    width: 100%;
    font-size: ${pxToRem(14)};
    z-index: ${zIndex.base};
    cursor: default;

    thead {
        background-color: ${hexToRGB(uiColors.blueLight, '0.2')};

        th {
            padding: ${pxToRem(16)} ${pxToRem(8)};
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: ${hexToRGB(uiColors.greyLight, '0.4')};
            }
        }
    }

    th,
    td {
        padding: ${pxToRem(8)};
        vertical-align: middle;
    }

    th {
        text-align: left;
        text-transform: uppercase;
        color: ${uiColors.darkGrey};
        cursor: pointer;
    }

    tr {
        min-height: ${pxToRem(32)};
        border-bottom: 1px solid ${uiColors.borderGreyDark};

        > td {
            color: ${uiColors.darkGrey};
        }

        &:last-child {
            border-bottom: 0;
        }
    }

    tr > td:first-child {
        width: 50%;
    }

    tr > td:last-child span {
        justify-content: flex-end;

        i {
            min-height: 24px;
            min-width: 24px;
        }

        menu li {
            i {
                min-height: 20px;
                min-width: 20px;
            }
        }
    }
`;

export const STableRowItem = styled.td<{ isDisabled?: boolean }>`
    &:first-child {
        color: ${({ isDisabled }) => (isDisabled ? `${uiColors.darkGrey}` : `${uiColors.mainBlue}`)};
        cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
    }

    span {
        ${verticalAlign()};
        line-height: ${pxToRem(16)};
    }
`;
export interface ITableRowItem {
    onClick?: () => void;
    children: React.ReactNode;
    isDisabled?: boolean;
}

export const TableRowItem: FC<ITableRowItem> = ({ isDisabled, children, ...rest }) => {
    return (
        <STableRowItem isDisabled={isDisabled} {...rest}>
            <span>{children}</span>
        </STableRowItem>
    );
};

export const SColumnText = styled.span<{ isActive?: boolean }>`
    color: ${({ isActive }) => (isActive ? `var(--ui-link-secondary)` : `${uiColors.darkGrey}`)};
    text-decoration: ${({ isActive }) => (isActive ? 'underline' : 'none')};
`;
