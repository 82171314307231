import { markConnectionsPortsAsUnconnected } from '@repeat/constants';
import { PortTypes, TSchemaConnection, TSchemaGroup, TSchemaNode } from '@repeat/models';

interface ISchemaItems {
    elements: TSchemaNode[];
    wires: TSchemaConnection[];
    groups: TSchemaGroup[];
}

const ELEMENT_SUM_TYPE = 'sum';
const ELEMENT_SUM_DEFAULT_OUTPUT_PORT_NAME = 'out_3';

export const applyPatch_1_8_1 = (schemaItems: ISchemaItems): ISchemaItems => {
    let nodes = schemaItems.elements;
    let connections = schemaItems.wires;

    const sumBlocks = schemaItems.elements.filter((el) => el.data.type === ELEMENT_SUM_TYPE);

    const sumBlocksWithTwoOutputPorts = sumBlocks.filter(
        (el) => el.data.availablePorts.filter((port) => port.type === PortTypes.OUTPUT).length > 1
    );

    if (sumBlocksWithTwoOutputPorts.length !== 0) {
        const nodesExceptSumBlocks = nodes.filter((x) => !sumBlocksWithTwoOutputPorts.some((y) => x.id === y.id));

        const wiresPortsToDelete: string[] = [];

        const nodesUpdated = sumBlocksWithTwoOutputPorts.map((block) => {
            const num = block.data.availablePorts.length;
            wiresPortsToDelete.push(`${block.id}-${num}-${ELEMENT_SUM_DEFAULT_OUTPUT_PORT_NAME}`);

            const ports = block.data.availablePorts.filter(
                (port) => port.name !== ELEMENT_SUM_DEFAULT_OUTPUT_PORT_NAME
            );
            const params = block.data.elemParams.filter((p) => p.name !== ELEMENT_SUM_DEFAULT_OUTPUT_PORT_NAME);
            return { ...block, data: { ...block.data, availablePorts: ports, elemParams: params } };
        });

        const wiresToDelete = schemaItems.wires.filter(
            (wire) => wiresPortsToDelete.includes(wire.sourceHandle) || wiresPortsToDelete.includes(wire.targetHandle)
        );

        const portsNamesByNodeIdMap = markConnectionsPortsAsUnconnected(wiresToDelete);

        const nodesExceptSumBlocksUpdated = nodesExceptSumBlocks.map((node) => {
            const ports = node.data.availablePorts.map((port) => {
                if (portsNamesByNodeIdMap[node.id] && portsNamesByNodeIdMap[node.id].includes(port.name)) {
                    return { ...port, isConnected: false };
                }
                return port;
            });
            return { ...node, data: { ...node.data, availablePorts: ports } };
        });
        nodes = [...nodesExceptSumBlocksUpdated, ...nodesUpdated];

        connections = connections.filter((x) => !wiresToDelete.some((y) => x.id === y.id));
    }

    return {
        ...schemaItems,
        elements: nodes,
        wires: connections,
    };
};
