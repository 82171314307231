import { useMemo } from 'react';

import { replaceKeys } from '@repeat/constants';
import { ILibraryItem } from '@repeat/models';

export const useLibraryItemsFilter = (items: ILibraryItem[], query: string) =>
    useMemo(() => filterItems(items, query), [query, items]);

export const filterItems = (items: ILibraryItem[], value: string) =>
    items?.filter(
        (item) =>
            item.aliases?.filter((name) => name.toLowerCase().includes(value.trim().toLowerCase())).length !== 0 ||
            item.aliases?.filter((name) => name.toLowerCase().includes(replaceKeys(value.trim().toLowerCase())))
                .length !== 0 ||
            item?.name?.toLowerCase().includes(value.trim().toLowerCase()) ||
            item?.name?.toLowerCase().includes(replaceKeys(value.trim().toLowerCase()))
    );
