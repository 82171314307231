import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { ApplicationActions } from '@repeat/common-slices';
import { EDIT_PROJECT_FORM } from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { ModalTypes, Project, Statuses, TProjectEditModal } from '@repeat/models';
import { fetchAvailableLibraries, getProjects } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { Button, Modal } from '@repeat/ui-kit';

import { EditProjectForm } from '../Project/EditProjectForm/EditProjectForm';
import { projectMessages as messages } from '../Project/translations';

export const ProjectEditModal: FC = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const modals = useAppSelector((state) => state.app.modals);
    const modal = modals.find((m) => m.type === ModalTypes.PROJECT_EDIT) as TProjectEditModal;

    const [isValidForm, setIsValidForm] = useState(false);

    const [project] = useState<Project>(modal.data.project);

    const projectsState = useAppSelector((state) => state.projects);

    const isProjectsInitialized = projectsState.status === Statuses.SUCCEEDED;

    useEffect(() => {
        if (isProjectsInitialized === false) {
            dispatch(fetchAvailableLibraries());
            dispatch(getProjects());
        }
    }, [dispatch, isProjectsInitialized]);

    const handleClose = () => {
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.PROJECT_EDIT }));
    };

    const FooterButtons = () => (
        <>
            <Button variant='secondary' onClick={handleClose}>
                {intl.formatMessage(messages[TranslationKey.CANCEL])}
            </Button>
            <Button form={EDIT_PROJECT_FORM} type='submit' disabled={!isValidForm}>
                {intl.formatMessage(messages[TranslationKey.SAVE])}
            </Button>
        </>
    );
    const handleFormValidate = (isValid: boolean) => {
        setIsValidForm(isValid);
    };
    return (
        <Modal
            id={ModalTypes.PROJECT_EDIT}
            title={intl.formatMessage(messages[TranslationKey.PROJECT_EDIT])}
            isOpen={true}
            hasOverlay={true}
            onClose={handleClose}
            type='default'
            size='small'
            footer={<FooterButtons />}
        >
            {project && <EditProjectForm project={project} onValid={handleFormValidate} />}
            {!project && <p>{intl.formatMessage(messages[TranslationKey.PROJECT_EDIT_NOT_SELECTED])}</p>}
        </Modal>
    );
};
