import { PayloadAction } from '@reduxjs/toolkit';

import {
    BlockGoToTypes,
    ElemProps,
    ISchemaGoToMap,
    ISchemaGoToMapItem,
    IWorkspaceState,
    TBlockGoToType,
    TElement,
    TSchemaNode,
} from '@repeat/models';
import { makeGoToTagId } from '@repeat/services';

export const goToMapSlices = {
    refreshGoToMapAfterAdding: (
        state: IWorkspaceState,
        { payload }: PayloadAction<{ blockId: string; block: TElement }>
    ) => {
        const { blockId, block } = payload;
        let refreshingMap = state.schema.goToMap !== null ? { ...state.schema.goToMap } : null;

        if (['GoTo', 'GoFrom'].includes(block.type)) {
            const tagIdProperty = block.elemProps.find((property: ElemProps) => property.name === 'tagId');
            const tagTitle = block.elemProps.find((property: ElemProps) => property.name === 'tagTitle')?.value || '';
            if (tagIdProperty) {
                const tagId = tagIdProperty.value;
                const goToId = block.type === 'GoTo' ? blockId : null;
                const goFromIds = block.type === 'GoFrom' ? [blockId] : [];

                refreshingMap = refreshingMap || {};
                refreshingMap = {
                    ...state.schema.goToMap,
                    [tagId]: {
                        title: tagTitle,
                        goToId:
                            refreshingMap[tagId] !== undefined && refreshingMap[tagId].goToId !== null
                                ? refreshingMap[tagId].goToId
                                : goToId,
                        goFromIds:
                            refreshingMap[tagId] !== undefined && refreshingMap[tagId].goFromIds.length > 0
                                ? [...refreshingMap[tagId].goFromIds, ...goFromIds]
                                : goFromIds,
                    } as ISchemaGoToMapItem,
                };
            }
        }

        return {
            ...state,
            schema: {
                ...state.schema,
                goToMap: { ...refreshingMap },
            },
        };
    },
    refreshGoToMapAfterDeleting: (state: IWorkspaceState, { payload }: PayloadAction<{ blockIds: string[] }>) => {
        if (payload.blockIds.length === 0 || state.schema.goToMap === null) {
            return;
        }

        const refreshingMap: ISchemaGoToMap = { ...state.schema.goToMap };
        Object.keys(refreshingMap).forEach((uuid: string) => {
            const mapItem = { ...refreshingMap[uuid] };
            const goToId = mapItem.goToId as string;
            const goFromIds = mapItem.goFromIds;
            const notUniqueGoToIds = mapItem?.notUniqueGoToIds || [];

            if (payload.blockIds.includes(goToId)) {
                mapItem.goToId = null;
            }

            mapItem.goFromIds = goFromIds.filter((id) => !payload.blockIds.includes(id));
            mapItem.notUniqueGoToIds = notUniqueGoToIds.filter((id) => !payload.blockIds.includes(id));

            if (mapItem.goToId === null && mapItem.goFromIds.length === 0) {
                delete refreshingMap[uuid];
            } else {
                refreshingMap[uuid] = mapItem;
            }
        });

        return {
            ...state,
            schema: {
                ...state.schema,
                goToMap: { ...refreshingMap },
            },
        };
    },
    renameGoToBlockTitle: (
        state: IWorkspaceState,
        { payload }: PayloadAction<{ blockId: string; type: TBlockGoToType; title: string; tagId: string }>
    ) => {
        if (state.schema.goToMap === null) {
            return;
        }

        const { blockId, type, title, tagId } = payload;

        const currentMap: ISchemaGoToMap = state.schema.goToMap;
        let currentMapItem: ISchemaGoToMapItem | null = currentMap[tagId] || null;
        if (currentMapItem === null) {
            return;
        }

        const refreshingTagId = makeGoToTagId(title);

        let refreshingMapItem: ISchemaGoToMapItem | null = null;
        if (currentMap[refreshingTagId]) {
            if (type === BlockGoToTypes.GOTO) {
                if (currentMap[refreshingTagId].goToId !== null) {
                    const refreshingNotUniqueGoToBlockIds = currentMap[refreshingTagId]?.notUniqueGoToIds || [];
                    const currentNotUniqueGoToIds = currentMapItem?.notUniqueGoToIds || [];

                    refreshingMapItem = {
                        ...currentMap[refreshingTagId],
                        notUniqueGoToIds: [...refreshingNotUniqueGoToBlockIds, blockId],
                    };
                    currentMapItem = {
                        ...currentMapItem,
                        goToId: null,
                        notUniqueGoToIds: currentNotUniqueGoToIds.filter((id: string) => ![blockId].includes(id)),
                    };
                } else {
                    refreshingMapItem = {
                        ...currentMap[refreshingTagId],
                        goToId: blockId,
                    };
                    currentMapItem = {
                        ...currentMapItem,
                        goToId: null,
                    };
                }
            } else {
                refreshingMapItem = {
                    ...currentMap[refreshingTagId],
                    goFromIds: [...currentMap[refreshingTagId].goFromIds, blockId],
                };
                currentMapItem = {
                    ...currentMapItem,
                    goFromIds: currentMapItem.goFromIds.filter((id: string) => ![blockId].includes(id)),
                };
            }
        } else {
            refreshingMapItem = {
                title,
                goToId: null,
                goFromIds: [],
            };

            if (type === BlockGoToTypes.GOTO) {
                refreshingMapItem.goToId = blockId;

                const notUniqueGoToIds = currentMapItem?.notUniqueGoToIds || [];
                currentMapItem = {
                    ...currentMapItem,
                    goToId: !notUniqueGoToIds.includes(blockId) ? null : currentMapItem.goToId,
                    notUniqueGoToIds: notUniqueGoToIds.filter((id: string) => ![blockId].includes(id)),
                };
            } else {
                refreshingMapItem.goFromIds = [blockId];
                currentMapItem = {
                    ...currentMapItem,
                    goFromIds: currentMapItem.goFromIds.filter((id: string) => ![blockId].includes(id)),
                };
            }
        }

        const refreshingMap = {
            ...currentMap,
            [tagId]: currentMapItem,
            [refreshingTagId]: refreshingMapItem,
        };

        if (currentMapItem.goToId === null && currentMapItem.goFromIds.length === 0) {
            delete refreshingMap[tagId];
        }

        // update uuid in block properties
        const nodeIdsForUpdate = [blockId];

        const nodes = state.schema.schemaItems.elements.map((node: TSchemaNode) => {
            if (nodeIdsForUpdate.includes(node.id)) {
                const block = node.data;
                const newProps = block.elemProps.map((property: ElemProps) => {
                    if (property.name === 'tagId') {
                        return {
                            ...property,
                            value: refreshingTagId,
                        };
                    }
                    if (property.name === 'tagTitle') {
                        return {
                            ...property,
                            value: title,
                        };
                    }

                    return property;
                });
                return {
                    ...node,
                    data: {
                        ...node.data,
                        elemProps: newProps,
                    },
                };
            }
            return node;
        });

        return {
            ...state,
            schema: {
                ...state.schema,
                schemaItems: {
                    ...state.schema.schemaItems,
                    elements: nodes,
                },
                goToMap: { ...refreshingMap },
            },
        };
    },

    refreshGoToMapAfterPasteItems: (state: IWorkspaceState, { payload }: PayloadAction<{ nodes: TSchemaNode[] }>) => {
        const { nodes } = payload;
        if (nodes.length === 0 || state.schema.goToMap === null) {
            return;
        }

        const goNodes = nodes.filter((node: TSchemaNode) => ['GoTo', 'GoFrom'].includes(node.data.type));
        if (goNodes.length === 0) {
            return;
        }

        const pastedGoToMap: { [uuid: string]: string[] } = {};
        const pastedGoFromMap: { [uuid: string]: string[] } = {};

        goNodes.forEach((node: TSchemaNode) => {
            const blockId = node.id;
            const block = node.data;
            const uuid =
                (block.elemProps.find((property: ElemProps) => property.name === 'tagId')?.value as string) || null;
            if (uuid) {
                if (block.type === 'GoFrom') {
                    pastedGoFromMap[uuid] = pastedGoFromMap[uuid] ? [...pastedGoFromMap[uuid], blockId] : [blockId];
                } else {
                    pastedGoToMap[uuid] = pastedGoToMap[uuid] ? [...pastedGoToMap[uuid], blockId] : [blockId];
                }
            }
        });

        const refreshingMap: ISchemaGoToMap = { ...state.schema.goToMap };
        Object.keys(pastedGoFromMap).forEach((uuid: string) => {
            if (refreshingMap[uuid]) {
                const blockIds = pastedGoFromMap[uuid];
                refreshingMap[uuid] = {
                    ...refreshingMap[uuid],
                    goFromIds: [...refreshingMap[uuid].goFromIds, ...blockIds],
                };
            }
        });
        Object.keys(pastedGoToMap).forEach((uuid: string) => {
            if (refreshingMap[uuid]) {
                const blockIds = pastedGoToMap[uuid];
                refreshingMap[uuid] = {
                    ...refreshingMap[uuid],
                    notUniqueGoToIds:
                        refreshingMap[uuid].notUniqueGoToIds !== undefined
                            ? [...(refreshingMap[uuid].notUniqueGoToIds as string[]), ...blockIds]
                            : [...blockIds],
                };
            }
        });

        return {
            ...state,
            schema: {
                ...state.schema,
                goToMap: { ...refreshingMap },
            },
        };
    },
};
