import styled from 'styled-components';

import { pxToRem } from '@repeat/ui-kit';

export const SBlockDescriptionModal = styled.div`
    .moveable-control.moveable-direction {
        background-color: transparent;
        border-radius: 0;
        border: none;
        margin: 0;
    }
    .moveable-control.moveable-direction.moveable-ne {
        cursor: ne-resize;
        border-top: 2px solid var(--ui-heading);
        border-right: 2px solid var(--ui-heading);
        margin-left: ${pxToRem(-14)};
    }
    .moveable-control.moveable-direction.moveable-se {
        cursor: se-resize;
        border-right: 2px solid var(--ui-heading);
        border-bottom: 2px solid var(--ui-heading);
        margin-left: ${pxToRem(-14)};
        margin-top: ${pxToRem(-14)};
    }
    .moveable-control.moveable-direction.moveable-sw {
        cursor: sw-resize;
        border-left: 2px solid var(--ui-heading);
        border-bottom: 2px solid var(--ui-heading);
        margin-top: ${pxToRem(-14)};
    }
    .moveable-control.moveable-direction.moveable-nw {
        cursor: nw-resize;
        border-left: 2px solid var(--ui-heading);
        border-top: 2px solid var(--ui-heading);
    }
`;
