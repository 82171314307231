import { PreloaderAction, PreloaderTypes } from '../actions/preloaderTypes';

export function isLoading(state = false, action: PreloaderAction) {
    // console.log(action);

    switch (action?.type) {
        case PreloaderTypes.IS_LOADING:
            return action.payload;

        default:
            return state;
    }
}
export default isLoading;
