import React, { FC } from 'react';

import { SSidebarContent, SSidebarTitle } from './SSidebar';

import { Icon } from '../../Icon/Icon';

interface ISidebarContent {
    title: string;
    iconName?: string;
    style?: { [key: string]: string };
    children: React.ReactNode;
}

export const SidebarContent: FC<ISidebarContent> = ({ children, title, iconName = 'list', style }) => (
    <>
        {title && (
            <SSidebarTitle>
                <Icon name={iconName} />
                {title}
            </SSidebarTitle>
        )}
        <SSidebarContent style={style}>{children}</SSidebarContent>
    </>
);
