import { AxiosResponse } from 'axios';

import UploadService from './UploadService';
import { $api } from './http';

export interface ILicenseKeyDataContract {
    activationStatus: string;
    activationPeriod: number;
    licenseKey: string;
    validationStatus: string;
    createdAt: string;
    installedAt: string;
    expireAt: string | null;
}

interface IGetKey {
    (signal?: AbortSignal): Promise<AxiosResponse<ILicenseKeyDataContract>>;
}

interface ISaveKeyPayload {
    licenseKey: string;
}

interface ISaveKey {
    (payload: ISaveKeyPayload, signal?: AbortSignal): Promise<AxiosResponse<ILicenseKeyDataContract>>;
}

export interface IReturnGetActivationCodeContract {
    activationCode: string;
}

interface IGetActivationCode {
    (signal?: AbortSignal): Promise<AxiosResponse<IReturnGetActivationCodeContract>>;
}

export type TUploadedLicenseFile = {
    createdAt: string;
    fileType: string;
    contentType: string;
    originalName: string;
    size: number;
};

interface TUploadLicenseFilePayload {
    file: File;
    onChunkUploaded: (uploadedBytes: number) => void;
    onUploaded: (file: TUploadedLicenseFile) => void;
}

interface IUploadLicenseFile {
    (payload: TUploadLicenseFilePayload): Promise<boolean>;
}

interface IActivateLicense {
    (signal?: AbortSignal): Promise<AxiosResponse<string>>;
}

export default class LicenseService {
    static getActiveKey: IGetKey = async (signal?: AbortSignal) => {
        return $api.get('/api/v1/license/getActiveKey', { signal });
    };

    static getNewKey: IGetKey = async (signal?: AbortSignal) => {
        return $api.get('/api/v1/license/getNewKey', { signal });
    };

    static saveKey: ISaveKey = async (payload: ISaveKeyPayload, signal?: AbortSignal) => {
        return $api.post('/api/v1/license/installNewKey', payload, { signal });
    };

    static getActivationCode: IGetActivationCode = async (signal?: AbortSignal) => {
        return $api.get('/api/v1/license/code', { signal });
    };

    static uploadLicenseFile: IUploadLicenseFile = async (payload: TUploadLicenseFilePayload): Promise<boolean> => {
        const headers = {};
        const chunkSize = 1048576; // 1Mb

        const uploadService = new UploadService('/api/v1/license/uploadLicenseFile', payload.file, headers, chunkSize);
        return await uploadService.uploadFile<TUploadedLicenseFile>(payload.onChunkUploaded, payload.onUploaded);
    };

    static activateLicense: IActivateLicense = async (signal?: AbortSignal) => {
        const payload = null;
        return $api.post('/api/v1/license/activateNewKey ', payload, { signal });
    };
}
