import { FC, memo, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { NodeToolbar, Position } from 'reactflow';

import { extractParametersForVisualization } from 'apps/repeat/src/features/Visualization/helpers';

import { unicodeReplaceSymbol } from '@repeat/constants';
import { useAppSelector, useDemoMode } from '@repeat/hooks';
import { ElemParams, IChartItem, TElement } from '@repeat/models';
import { TranslationKey } from '@repeat/translations';
import { IconButton, uiColors } from '@repeat/ui-kit';

import { messages } from './../../../../Visualization/translation';

import {
    SNodeTrendsTitle,
    SNodeTrendsTrigger,
    SNodeTrendsTriggerItem,
    SNodeTrendsTriggerList,
    SNodeTrendsTriggerWrapper,
} from '../SComponents';

type TParameters = ElemParams[] & { selected?: boolean };

interface IElementTrends {
    rotation: number;
    parameters: TParameters;
    selected: boolean;
    elementData: TElement;
    selectParamHandler: (id: string, status: boolean, elementID: string, index: string) => void;
    selectAllParamHandler: (ids: string[], status: boolean, elementID: string) => void;
}

export const ElementTrends: FC<IElementTrends> = memo(
    ({ selectAllParamHandler, selectParamHandler, elementData, rotation, parameters, selected }) => {
        const { formatMessage } = useIntl();
        const { charts } = useAppSelector((state) => state.workspace.graphs);
        const { isDemo } = useDemoMode();

        const checkSelected = () => {
            let selected = false;
            Object.keys(charts).forEach((chartKey) => {
                const currentChart = charts[parseInt(chartKey)];
                if (currentChart.elementIDs && currentChart.elementIDs.includes(elementData.id.toString())) {
                    selected = true;
                    return;
                }
            });
            return selected;
        };

        const [isTargetHasSelected, setIsTargetHasSelected] = useState(() => {
            return checkSelected();
        });

        useEffect(() => {
            setIsTargetHasSelected(checkSelected());
        }, [charts]);

        const listRef = useRef<HTMLElement | null>(null);
        const btnRef = useRef<HTMLElement | null>(null);

        const { parametersToDisplay, elemParams } = elementData;

        const elementParameters = extractParametersForVisualization(elemParams, parametersToDisplay).filter(
            (parameter) => {
                const exp = new RegExp('in_');
                if (parameter && parameter.name) {
                    return (
                        !exp.test(parameter.name) &&
                        //eslint-disable-next-line
                        (!parameter.hasOwnProperty('isVisible') || parameter.isVisible === true)
                    );
                }

                return false;
            }
        ); // исключаем входные порты;

        if (elementParameters.length > 0) {
            return (
                <SNodeTrendsTriggerWrapper rotation={rotation} selected={selected}>
                    <NodeToolbar style={{ zIndex: '1002' }} align={'start'} offset={32} position={Position.Right}>
                        <SNodeTrendsTrigger>
                            <IconButton
                                ref={btnRef}
                                noHover
                                name={'visualization'}
                                fill={isTargetHasSelected ? uiColors.mainBlue : uiColors.darkGrey}
                            />
                        </SNodeTrendsTrigger>
                        <SNodeTrendsTriggerList ref={listRef}>
                            <SNodeTrendsTriggerItem header>
                                {formatMessage(messages[TranslationKey.WORKSPACE_CHART_ADD])}
                            </SNodeTrendsTriggerItem>
                            {elementParameters.map((item: ElemParams, index) => {
                                let isChecked = false;
                                let currentIndex = null;
                                let selectedChart: IChartItem | Record<string, any> = {};
                                Object.keys(charts).forEach((key: string) => {
                                    const currentChart = charts[parseInt(key)];
                                    if (
                                        currentChart.elementIDs &&
                                        currentChart.elementIDs.includes(elementData.id.toString()) &&
                                        currentChart.modelNames.includes(`${elementData.id}_${item.name}`)
                                    ) {
                                        selectedChart = { ...charts[parseInt(key)] } as IChartItem;
                                        currentIndex = key;
                                        isChecked = currentChart.modelNames.includes(`${elementData.id}_${item.name}`);
                                        return;
                                    }
                                    return;
                                });
                                const parameterTitle = item?.unit
                                    ? `${item.description}, ${unicodeReplaceSymbol(item.unit)}`
                                    : `${item.description}`;
                                return (
                                    <SNodeTrendsTriggerItem key={`trends-params-${index}`}>
                                        <SNodeTrendsTitle>
                                            <span>{parameterTitle}</span>
                                        </SNodeTrendsTitle>
                                        {!isDemo && (
                                            <IconButton
                                                noHover
                                                fill={isChecked ? uiColors.grey : uiColors.mainBlue}
                                                disabled={isChecked}
                                                name={'plus'}
                                                onClick={() => {
                                                    selectParamHandler(
                                                        item.name,
                                                        true,
                                                        elementData.id.toString(),
                                                        elementData.index
                                                    );
                                                }}
                                            />
                                        )}
                                    </SNodeTrendsTriggerItem>
                                );
                            })}
                        </SNodeTrendsTriggerList>
                    </NodeToolbar>
                </SNodeTrendsTriggerWrapper>
            );
        }
        return null;
    }
);
