import { PayloadAction } from '@reduxjs/toolkit';

import { IElement, ISchemaState, IWire } from '@repeat/models';

import { initialState } from '.';

export const selectedItemsReducers = {
    setSelectedItem: (state: ISchemaState, action: PayloadAction<{ id: number }>) => {
        const items = [...state.schemaItems.elements, ...state.schemaItems.wires].filter(
            (el: IElement | IWire) => el.id === action.payload.id
        );
        return {
            ...state,
            selectedItems: [...items],
        };
    },
    setSelectedItemsGroup: (state: ISchemaState, action: PayloadAction<{ id: number }>) => {
        const item = [...state.schemaItems.elements, ...state.schemaItems.wires].filter(
            (el: IElement | IWire) => el.id === action.payload.id
        );
        const newState = [...state.selectedItems, ...item];
        let result;
        if (newState.filter((el) => el.id === action.payload.id)?.length > 1) {
            result = newState.filter((el) => el.id !== action.payload.id);
        } else {
            result = newState;
        }
        return {
            ...state,
            selectedItems: result,
        };
    },
    selectAllItems: (state: ISchemaState) => ({
        ...state,
        selectedItems: [...state.schemaItems.elements, ...state.schemaItems.wires],
    }),
    clearSelectedItems: (state: ISchemaState) => ({
        ...state,
        selectedItems: initialState.selectedItems,
    }),
};
