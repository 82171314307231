import { Icon } from '@repeat/ui-kit';

import { SVariableAddItem } from './SVariables';

const VariableAddItem = ({ appendText, onClick }: { appendText: string; onClick: () => void }) => {
    return (
        <SVariableAddItem onClick={onClick}>
            <Icon fill={'var(--ui-icon-button)'} name={'plus'} />
        </SVariableAddItem>
    );
};

export default VariableAddItem;
