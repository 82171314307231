export enum UndoRedoType {
    UNDO = 'undo',
    REDO = 'redo',
}
export type TUndoRedoActionType = UndoRedoType.UNDO | UndoRedoType.REDO | null;

export type TUndoRedoContainer = {
    current: unknown;
    stack: unknown[];
    next: unknown[];
};

export interface IUndoRedoSetStack {
    type: string;
    payload?: unknown;
}

export type IUndoRedoPayload = {
    type: TUndoRedoActionType;
    payload: TUndoRedoContainer;
};

export interface IUndoRedoState {
    canUndo: boolean;
    canRedo: boolean;
    type: TUndoRedoActionType;
}
