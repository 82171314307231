import { useState } from 'react';
import { useIntl } from 'react-intl';

import { ApplicationActions } from '@repeat/common-slices';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { ModalTypes } from '@repeat/models';
import { TranslationKey } from '@repeat/translations';
import { Button, Modal } from '@repeat/ui-kit';

import { TUserBlockConfirmDeleteModal } from 'libs/models/src/lib/modal';
import { deleteUserBlocks } from 'libs/repeat/store/src/lib/slices/workspace/userBlocks/userBlocksSlice';
import { blockOptionsMessages as messages } from './translation';

export const UserBlockConfirmDeleteModal = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const data = useAppSelector((state) => state.app.modals[0]) as TUserBlockConfirmDeleteModal;

    const {
        data: { blockId, name },
    } = data;

    const handleClose = () => {
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.USER_BLOCK_CONFIRM_DELETE }));
    };

    const handleDelete = (blockId: string) => {
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.USER_BLOCK_CONFIRM_DELETE }));
        dispatch(deleteUserBlocks(blockId));
    };

    const [isValidForm, setIsValidForm] = useState(true);

    const handleFormValidate = (isValid: boolean) => {
        setIsValidForm(isValid);
    };

    const FooterButtons = () => (
        <>
            <Button variant='secondary' onClick={handleClose}>
                {intl.formatMessage(messages[TranslationKey.CANCEL])}
            </Button>
            <Button onClick={() => handleDelete(blockId)} disabled={!isValidForm}>
                {'Удалить'}
            </Button>
        </>
    );

    return (
        <Modal
            id={ModalTypes.USER_BLOCK_CONFIRM_DELETE}
            title={'Подтверждение удаления'}
            isOpen={true}
            hasOverlay={true}
            onClose={handleClose}
            type='default'
            size='medium'
            footer={<FooterButtons />}
        >
            Возможно, блок <b>{name}</b> уже используется в проектах. Подтвердите его удаление.
        </Modal>
    );
};
