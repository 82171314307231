import { useState } from 'react';
import { useIntl } from 'react-intl';

import { ApplicationActions } from '@repeat/common-slices';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { ModalTypes } from '@repeat/models';
import { workspaceSelectors } from '@repeat/store';

import { TranslationKey } from '@repeat/translations';
import { Button, Modal } from '@repeat/ui-kit';

import { blockOptionsMessages as messages } from './translation';

import { TUserBlockData } from 'libs/models/src/lib/element';
import { TUserBlockEditModal } from 'libs/models/src/lib/modal';
import { editUserBlockExternalParameters } from 'libs/repeat/store/src/lib/slices/workspace/userBlocks/userBlocksSlice';
import { UserBlockForm } from '../Project/UserBlockForm/UserBlockForm';

export const UserBlockEditModal = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const data = useAppSelector((state) => state.app.modals[0]) as TUserBlockEditModal;
    const {
        data: { blockId },
    } = data;
    const userBlock = useAppSelector(workspaceSelectors.getUserBlockByBlockId(blockId));

    const [userData, setUserData] = useState<TUserBlockData>({
        name: userBlock?.name || '',
        subLibrary: userBlock?.subLibrary || '',
        description: userBlock?.description || '',
        image: userBlock?.image || '',
    });

    const handleClose = () => {
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.USER_BLOCK_EDIT }));
    };

    const handleSave = () => {
        if (userBlock) {
            dispatch(editUserBlockExternalParameters(userData, userBlock));
        }
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.USER_BLOCK_EDIT }));
    };

    const [isValidForm, setIsValidForm] = useState(true);

    const handleFormValidate = (isValid: boolean) => {
        setIsValidForm(isValid);
    };

    const FooterButtons = () => (
        <>
            <Button variant='secondary' onClick={handleClose}>
                {intl.formatMessage(messages[TranslationKey.CANCEL])}
            </Button>
            <Button onClick={handleSave} disabled={!isValidForm}>
                {'Сохранить изменения'}
            </Button>
        </>
    );

    return (
        <Modal
            id={ModalTypes.USER_BLOCK_EDIT}
            title={'Редактирование блока'}
            isOpen={true}
            hasOverlay={true}
            onClose={handleClose}
            type='default'
            size='big'
            footer={<FooterButtons />}
        >
            <UserBlockForm
                onValid={handleFormValidate}
                groupName={userData.name}
                data={userData}
                setData={setUserData}
            />
        </Modal>
    );
};
