import { AnyAction, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';

import { ApplicationActions } from '@repeat/common-slices';
import {
    CSV_DELIMITER,
    TREND_DATA_MAX_ATTEMPTS,
    TREND_DATA_MAX_STEP_POINTS,
    TREND_TICK,
    TREND_TIMEOUT_ID_STORAGE_KEY,
    convertSecToMs,
    createBlobFile,
} from '@repeat/constants';
import {
    IChartItem,
    IChartItemParameters,
    IChartsDataMap,
    ITrendDataMap,
    IWorkspaceState,
    ModelStatuses,
    NotificationTypes,
    Statuses,
    TChartsData,
    TChartsDataItem,
    TTrendData,
    TTrendDataItem,
    TrendsStatus,
    TrendsStatuses,
} from '@repeat/models';
import { ModelService } from '@repeat/services';
import { TranslationKey } from '@repeat/translations';

import { actions, initialState } from '.';

import { AppDispatch, RootStateFn } from '../../store';

export const trendsReducers = {
    setChartsDataAsReady: (state: IWorkspaceState, { payload }: PayloadAction<IChartItemParameters>) => {
        const dataMap = Object.keys(state.graphs.dataMap).reduce((current, uuid) => {
            return Object.assign(current, {
                [uuid]: {
                    ...state.graphs.dataMap[uuid],
                    isReady: uuid === payload.uuid || state.graphs.dataMap[uuid].isReady,
                },
            });
        }, {} as ITrendDataMap);

        return {
            ...state,
            graphs: {
                ...state.graphs,
                dataMap: { ...dataMap },
            },
        };
    },
    runGraphsRequest: (state: IWorkspaceState, { payload }: PayloadAction<{ isContinue?: boolean } | undefined>) => ({
        ...state,
        graphs: {
            ...initialState.graphs,
            charts: { ...state.graphs.charts },
            commonStatus: payload?.isContinue ? TrendsStatuses.CONNECTED : state.graphs.commonStatus,
            data: payload?.isContinue
                ? state.graphs.data
                    ? ([...state.graphs.data] as TChartsData[])
                    : null
                : ([] as TChartsData[]),
            dataMap: { ...state.graphs.dataMap },
            fetchLastTime: payload?.isContinue ? state.graphs.fetchLastTime : initialState.graphs.fetchLastTime,
        },
    }),
    runGraphsSuccess: (state: IWorkspaceState) => ({
        ...state,
        graphs: {
            ...state.graphs,
            status: Statuses.SUCCEEDED,
        },
    }),
    runGraphsFailed: (state: IWorkspaceState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        graphs: {
            ...state.graphs,
            status: Statuses.FAILED,
            error: action.payload.error,
        },
    }),
    appendChartData: (state: IWorkspaceState, action: PayloadAction<{ index: number; itemData: TChartsData }>) => {
        const chartData = (state.graphs.data && state.graphs.data[action.payload.index]) || [];

        const data: TChartsData[] = [
            ...(state.graphs.data || []).slice(0, action.payload.index),
            chartData.length > 0
                ? chartData.map((d, i) => [...action.payload.itemData[i], ...d])
                : [...action.payload.itemData],
            ...(state.graphs.data || []).slice(action.payload.index + 1),
        ];

        return {
            ...state,
            graphs: {
                ...state.graphs,
                data,
            },
        };
    },
    setGraphsStatus: (state: IWorkspaceState, action: PayloadAction<{ status: TrendsStatus }>) => ({
        ...state,
        graphs: {
            ...state.graphs,
            commonStatus: action.payload.status,
        },
    }),
};

const actualizeDataIndexes = (
    data: TChartsData[],
    uuids: string[],
    dataLengthAfter: number,
    dataMapBefore: IChartsDataMap,
    dataMapAfter: IChartsDataMap
) => {
    const uuidsMap = uuids.map((uuid, dataIndex) => {
        return {
            dataIndex,
            beforeIndex: dataMapBefore[uuid]?.index !== undefined ? dataMapBefore[uuid].index : -1,
            afterIndex: dataMapAfter[uuid]?.index !== undefined ? dataMapAfter[uuid].index : -1,
        };
    });
    const actualData: TTrendData[] = Array(dataLengthAfter)
        .fill([])
        .map((d, index) => {
            const indexes = uuidsMap.find((p) => p.afterIndex === index);
            if (!indexes) {
                return d;
            }

            return [...data[indexes.dataIndex]] || d;
        });
    return actualData;
};

// TODO make using AppDispatch and RootStateFn again
const fetchChartsData =
    (projectId: number, charts: IChartItem[], filter: { timeStart: number; timeEnd: number }) =>
    async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: any) => {
        dispatch(actions.fetchChartsDataRequest());

        try {
            const dataMapBefore: IChartsDataMap = getState().workspace.graphs.dataMap;

            const activeCharts = charts
                // TODO avoid using isEdit in charts, use dataMap instead
                .filter((chart: IChartItem) => !chart.isEdit)
                .map((chart: IChartItem) => ({
                    ...chart,
                    timeStart: filter.timeStart,
                    timeEnd: filter.timeEnd,
                    typeGraph: chart.type,
                }));

            const request = {
                projectId,
                countGraphs: activeCharts.length,
                graphs: activeCharts,
                timeStart: filter.timeStart,
                timeEnd: filter.timeEnd,
            };
            const uuids = activeCharts.map((chart: IChartItem) => chart.uuid);

            const response = await ModelService.getChartsData(request);

            const responseData = response.data;

            const data: TChartsData[] | null =
                responseData.data !== null
                    ? responseData.data.map((itemData: TChartsDataItem[][]) => {
                          return itemData.map((item: TTrendDataItem[], index: number) =>
                              item.map(
                                  (value: number) => (index === 0 ? convertSecToMs(value) : value) as TTrendDataItem
                              )
                          );
                      })
                    : null;

            const dataMapAfter = getState().workspace.graphs.dataMap;
            let dataLengthAfter = 0;
            const chartsMap = getState().workspace.graphs.charts;
            Object.keys(chartsMap).forEach((key: string) => {
                if (!chartsMap[Number(key)].isEdit) {
                    dataLengthAfter++;
                }
            });

            const actualData =
                data !== null ? actualizeDataIndexes(data, uuids, dataLengthAfter, dataMapBefore, dataMapAfter) : data;

            dispatch(actions.fetchChartsDataSuccess({ fetchLastTime: filter.timeEnd, data: actualData }));
        } catch (error) {
            console.error(error);
            const errorKey = TranslationKey.ERROR_FETCH_TREND_DATA;
            dispatch(actions.fetchChartsDataFailed({ error: errorKey }));
        }
    };

// TODO make using AppDispatch and RootStateFn again
export const appendChartData =
    (projectId: number, chartParameters: IChartItemParameters) =>
    async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: any) => {
        const dataMapBefore: IChartsDataMap = getState().workspace.graphs.dataMap;
        const integrationStepString = getState().workspace.settings.integrationStep;
        const integrationStepMs = integrationStepString !== undefined ? Number(integrationStepString) : 50;

        const chartDataMapItemBefore = dataMapBefore[chartParameters.uuid];

        let timeEnd = chartDataMapItemBefore.lateTimeStart;
        while (timeEnd > 0) {
            const maxStepTime = TREND_DATA_MAX_STEP_POINTS * integrationStepMs;
            const timeStart = timeEnd - maxStepTime > 0 ? timeEnd - maxStepTime : 0;
            const filter = {
                timeStart,
                timeEnd,
            };

            if (getState().workspace.modelControl.modelStatus === ModelStatuses.STOP) {
                break;
            }

            await dispatch(fetchAndAppendChartData(projectId, chartParameters, dataMapBefore, filter));

            timeEnd = timeStart;

            if (timeEnd === 0) {
                dispatch(actions.setChartsDataAsReady(chartParameters));
            }
        }
    };

// TODO make using AppDispatch and RootStateFn again
const fetchAndAppendChartData =
    (
        projectId: number,
        chartParameters: IChartItemParameters,
        dataMapBefore: IChartsDataMap,
        filter: { timeStart: number; timeEnd: number }
    ) =>
    async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: any) => {
        try {
            const chartUuids = [chartParameters.uuid];
            const chartsMap = getState().workspace.graphs.charts;

            let charts: IChartItem[] = [];
            let appendCharts: IChartItem[] = [];
            Object.keys(chartsMap).forEach((index: string) => {
                const chart = chartsMap[Number(index)];
                charts = [...charts, chart];
                if (chartUuids.includes(chart.uuid)) {
                    appendCharts = [...appendCharts, chart];
                }
            });

            const request = {
                projectId,
                countGraphs: appendCharts.length,
                graphs: appendCharts.map((chart: IChartItem) => ({
                    ...chart,
                    timeStart: filter.timeStart,
                    timeEnd: filter.timeEnd,
                    typeGraph: chart.type,
                })),
                timeStart: filter.timeStart,
                timeEnd: filter.timeEnd,
            };
            const response = await ModelService.getChartsData(request);

            const responseData = response.data.data;

            if (responseData !== null) {
                const data: TTrendData[] = responseData.map((itemData: TTrendDataItem[][]) =>
                    itemData.map((item: TTrendDataItem[], index: number) =>
                        item.map((value: number) => (index === 0 ? convertSecToMs(value) : value) as TTrendDataItem)
                    )
                );

                const dataMapAfter = getState().workspace.graphs.dataMap;
                const dataLengthAfter = charts.length;
                const actualData = actualizeDataIndexes(data, chartUuids, dataLengthAfter, dataMapBefore, dataMapAfter);

                const dataMapItemAfter = dataMapAfter[chartParameters.uuid] || null;
                if (dataMapItemAfter && actualData.length > 0) {
                    dispatch(
                        actions.appendChartData({
                            index: dataMapItemAfter.index,
                            itemData: actualData[dataMapItemAfter.index],
                        })
                    );
                }
            }
        } catch (error) {
            console.error(error);
            if (getState().workspace.modelControl.modelStatus !== ModelStatuses.STOP) {
                dispatch(
                    ApplicationActions.showNotification({
                        notification: {
                            type: NotificationTypes.ERROR,
                            message: TranslationKey.ERROR_FETCH_TREND_DATA, // TODO make eror fetch init trend data
                        },
                    })
                );
            }
        }
    };

const clearTrendDataPoll = () => {
    const previousTimeoutId = localStorage.getItem(TREND_TIMEOUT_ID_STORAGE_KEY);
    if (previousTimeoutId) {
        clearTimeout(Number(previousTimeoutId));
    }
};

// TODO make using AppDispatch and RootStateFn again
const trendDataPoll = (projectId: number) => (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    clearTrendDataPoll();

    const timeoutId = setTimeout(() => dispatch(trendDataPollDoStep(projectId)), TREND_TICK);
    localStorage.setItem(TREND_TIMEOUT_ID_STORAGE_KEY, String(timeoutId));
};

// TODO make using AppDispatch and RootStateFn again
const trendDataPollDoStep =
    (projectId: number) => (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: any) => {
        const { fetchChartsDataFailedAttempts, fetchChartsDataStatus } = getState().workspace.graphs;
        const modelStatus = getState().workspace.modelControl.modelStatus;

        if ([ModelStatuses.STOP].includes(modelStatus)) {
            return;
        }

        if (fetchChartsDataFailedAttempts >= TREND_DATA_MAX_ATTEMPTS) {
            dispatch(actions.setGraphsStatus({ status: TrendsStatuses.DISCONNECTED }));

            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: TranslationKey.ERROR_FETCH_TREND_DATA,
                    },
                })
            );
            return;
        }

        let shouldNextRequest = false;
        if (fetchChartsDataStatus !== Statuses.LOADING) {
            const modelTimeEndSeconds = Number(getState().workspace.settings.modellingTime);
            const integrationStepString = getState().workspace.settings.integrationStep;
            const integrationStepMs = integrationStepString !== undefined ? Number(integrationStepString) : 50;
            const modelTimeMs = getState().workspace.modelControl.modelTime;
            const fetchLastTime = getState().workspace.graphs.fetchLastTime;

            const timeStartMs = fetchLastTime;
            const maxStepTime = TREND_DATA_MAX_STEP_POINTS * integrationStepMs;
            const timeDiff = modelTimeMs - timeStartMs - maxStepTime;
            let timeEndMs = timeDiff > 0 ? timeStartMs + maxStepTime : modelTimeMs;
            if (timeEndMs > modelTimeEndSeconds * 1000) {
                timeEndMs = modelTimeEndSeconds * 1000;
            }

            const chartsMap = getState().workspace.graphs.charts;

            let allCharts: IChartItem[] = [];
            Object.keys(chartsMap).forEach((index: string) => {
                const chart = chartsMap[Number(index)];
                allCharts = [...allCharts, chart];
            });
            const readyCharts = allCharts.filter((chart: IChartItem) => chart.YParameters.length > 0 && !chart.isEdit);

            if (timeEndMs > timeStartMs) {
                if (readyCharts.length > 0) {
                    dispatch(fetchChartsData(projectId, readyCharts, { timeStart: timeStartMs, timeEnd: timeEndMs }));
                    shouldNextRequest = true;
                }
            } else {
                shouldNextRequest = false;
            }
        }

        if (modelStatus === ModelStatuses.STOP && !shouldNextRequest) {
            return;
        }

        dispatch(trendDataPoll(projectId));
    };

// TODO make using AppDispatch and RootStateFn again
const runMDCoreTrends =
    (projectId: number, isContinue: boolean) =>
    async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: any) => {
        dispatch(actions.runGraphsRequest({ isContinue }));

        dispatch(actions.setGraphsStatus({ status: TrendsStatuses.CONNECTING }));

        const isTrendsRunning = [ModelStatuses.RUN, ModelStatuses.DATA, ModelStatuses.FREEZE].includes(
            getState().workspace.modelControl.modelStatus
        );

        try {
            if (isTrendsRunning && projectId > 0) {
                dispatch(trendDataPoll(projectId));

                dispatch(actions.runGraphsSuccess());
            } else {
                dispatch(
                    ApplicationActions.showNotification({
                        notification: {
                            type: NotificationTypes.WARNING,
                            message: TranslationKey.WORKSPACE_WARNING_CHART_START,
                        },
                    })
                );
            }
        } catch (error) {
            const errorKey = TranslationKey.WORKSPACE_ERROR_CHART;
            dispatch(actions.runGraphsFailed({ error: errorKey }));
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: TranslationKey.WORKSPACE_ERROR_CHART,
                    },
                })
            );
        }
    };

// TODO make using AppDispatch and RootStateFn again
export const runTrends =
    (isContinue: boolean) => async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: any) => {
        const projectId = Number(localStorage.getItem('projectId'));

        dispatch(runMDCoreTrends(projectId, isContinue));
    };

export const downloadChartData =
    (index: number, download: (url: string, filename: string) => void, headerTitles: string[], filename: string) =>
    (dispatch: AppDispatch, getState: RootStateFn) => {
        const data: TTrendData[] = getState().workspace.trends.data;

        try {
            const dataHeader: string[] = headerTitles;
            const dataTime: number[] = data[index][0];
            const dataParameter = data[index][1];

            if (!dataParameter) {
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: TranslationKey.WORKSPACE_ERROR_CHART_CSV,
                    },
                });
            }

            let csvContent = dataHeader.join(CSV_DELIMITER) + '\n';

            dataParameter.forEach((value, rowIndex) => {
                const time = dataTime[rowIndex];
                const row = [time, value];
                csvContent += row.join(CSV_DELIMITER) + '\n';
            });

            const url = createBlobFile<string>(csvContent, 'text/csv');

            download(url, filename);
        } catch (error) {
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: TranslationKey.WORKSPACE_ERROR_CHART_CSV,
                    },
                })
            );
            console.error(error);
        }
    };
