import { forwardRef, MouseEvent, useCallback, useState } from 'react';
import { ErrorCode, useDropzone } from 'react-dropzone';
import { defineMessages, useIntl } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';
import { Button } from '@repeat/ui-kit';

import { SDropZone, SDropZoneFileList, SDropZoneLabel, SDropZoneText, SDropZoneWrapper } from './SComponents';

export interface IDropZone {
    buttonText: string;
    dropZoneText: string;
    acceptFileTypes?: {
        [key: string]: string[];
    };
    maxFileCount?: number;
    maxFileSize?: number;
    onDropFile: (file: File) => void;
    onDialogCancel?: () => void;
    onError?: (isError: boolean) => void;
    label?: string;
    id?: string;
    error?: boolean;
}

const messages = defineMessages({
    [TranslationKey.ERROR_INVALID_TYPE_FILE]: {
        id: TranslationKey.ERROR_INVALID_TYPE_FILE,
        defaultMessage: ru[TranslationKey.ERROR_INVALID_TYPE_FILE],
    },
    [TranslationKey.ERROR_TOO_MANY_FILES_TEXT]: {
        id: TranslationKey.ERROR_TOO_MANY_FILES_TEXT,
        defaultMessage: ru[TranslationKey.ERROR_TOO_MANY_FILES_TEXT],
    },
    [TranslationKey.ERROR_TOO_MANY_FILE]: {
        id: TranslationKey.ERROR_TOO_MANY_FILE,
        defaultMessage: ru[TranslationKey.ERROR_TOO_MANY_FILE],
    },
    [TranslationKey.ERROR_TOO_MANY_FILES]: {
        id: TranslationKey.ERROR_TOO_MANY_FILES,
        defaultMessage: ru[TranslationKey.ERROR_TOO_MANY_FILES],
    },
    [TranslationKey.ERROR_LARGE_FILE]: {
        id: TranslationKey.ERROR_LARGE_FILE,
        defaultMessage: ru[TranslationKey.ERROR_LARGE_FILE],
    },
    [TranslationKey.ERROR_SMALL_FILE]: {
        id: TranslationKey.ERROR_SMALL_FILE,
        defaultMessage: ru[TranslationKey.ERROR_SMALL_FILE],
    },
    [TranslationKey.BYTES]: {
        id: TranslationKey.BYTES,
        defaultMessage: ru[TranslationKey.BYTES],
    },
});

export const DropZone = forwardRef<HTMLInputElement, IDropZone>((props, ref) => {
    const {
        acceptFileTypes,
        onDropFile,
        onDialogCancel,
        onError,
        maxFileCount = 1,
        maxFileSize = 20971520,
        buttonText,
        dropZoneText,
        label,
        id,
        error,
    } = props;

    const [hasError, setError] = useState({ error: false, message: '' });

    const { formatMessage } = useIntl();

    const onDrop = useCallback((acceptedFiles: File[]) => {
        acceptedFiles.forEach((file: File) => {
            onDropFile(file);
        });
    }, []);

    const onFileDialogCancel = () => {
        onDialogCancel && onDialogCancel();
    };

    const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({
        onDrop,
        onDropRejected(fileRejections) {
            const errorsCode = fileRejections
                .map((item) => {
                    return item.errors.map((err) => err.code);
                })
                .flat(Infinity);
            if (errorsCode.includes(ErrorCode.FileInvalidType)) {
                setError({ error: true, message: formatMessage(messages[TranslationKey.ERROR_INVALID_TYPE_FILE]) });
            } else if (errorsCode.includes(ErrorCode.TooManyFiles)) {
                setError({
                    error: true,
                    message: formatMessage(messages[TranslationKey.ERROR_TOO_MANY_FILES_TEXT], {
                        count: maxFileCount,
                        file:
                            maxFileCount === 1
                                ? formatMessage(messages[TranslationKey.ERROR_TOO_MANY_FILE])
                                : formatMessage(messages[TranslationKey.ERROR_TOO_MANY_FILES]),
                    }),
                });
            } else if (errorsCode.includes(ErrorCode.FileTooLarge)) {
                setError({ error: true, message: formatMessage(messages[TranslationKey.ERROR_LARGE_FILE]) });
            } else if (errorsCode.includes(ErrorCode.FileTooSmall)) {
                setError({ error: true, message: formatMessage(messages[TranslationKey.ERROR_SMALL_FILE]) });
            }

            onError && onError(true);
            return null;
        },
        onDropAccepted() {
            setError({ error: false, message: '' });
        },
        onFileDialogCancel,
        noKeyboard: true,
        maxFiles: maxFileCount,
        maxSize: maxFileSize,
        ...(acceptFileTypes && { accept: { ...acceptFileTypes } }),
    });

    const files = acceptedFiles.map((file: File) => (
        <li key={file.name}>
            {file.name} - {file.size} {formatMessage(messages[TranslationKey.BYTES])}
        </li>
    ));

    const buttonUploadHandler = (event: MouseEvent) => {
        event.stopPropagation();
        open();
    };

    return (
        <SDropZoneWrapper>
            {label && (
                <SDropZoneLabel error={hasError.error || !!error} htmlFor={id}>
                    {label}
                </SDropZoneLabel>
            )}
            <SDropZone
                data-name='drop-zone'
                {...getRootProps()}
                onClick={(e: React.MouseEvent<HTMLDivElement>) => e.preventDefault()}
                hasError={hasError.error || !!error}
            >
                <input ref={ref} {...getInputProps()} />
                <SDropZoneText>{dropZoneText}</SDropZoneText>
                <Button onClick={buttonUploadHandler}>{buttonText}</Button>
                <SDropZoneFileList>
                    {files}
                    {hasError.error && <li data-error={hasError.error}>{hasError.message}</li>}
                </SDropZoneFileList>
            </SDropZone>
        </SDropZoneWrapper>
    );
});
