import { FC } from 'react';
import { useIntl } from 'react-intl';

import { ReleaseItem } from 'apps/repeat/src/pages/VersionsPage/ReleaseItem';
import { ReleaseModalCloseType } from 'libs/models/src/lib/modal';

import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { ModalTypes, TReleaseInfoModal } from '@repeat/models';
import { hideReleaseInfoModal } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { Button, Modal } from '@repeat/ui-kit';

import { SMoreDetailedBlock, SReleasesContent } from './SComponents';

import { blockOptionsMessages as messages } from '../translation';

interface PropsModal {
    isShowButtonDontShowReleases?: boolean;
}
export const ReleaseInfoModal: FC<PropsModal> = ({ isShowButtonDontShowReleases = false }) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const modals = useAppSelector((state) => state.app.modals);
    const modal = modals.find((modal) => modal.type === ModalTypes.RELEASE_INFO) as TReleaseInfoModal;

    const handleClose = (type: ReleaseModalCloseType.CLOSE | ReleaseModalCloseType.DONT_SHOW_RELEASES) => {
        dispatch(hideReleaseInfoModal(modal.data.actual_release_version, type));
    };

    const FooterButtons = () => (
        <>
            {isShowButtonDontShowReleases && (
                <Button variant='secondary' onClick={() => handleClose(ReleaseModalCloseType.DONT_SHOW_RELEASES)}>
                    {intl.formatMessage(messages[TranslationKey.MODAL_RELEASE_INFO_DONT_SHOW_UPDATES])}
                </Button>
            )}
            <Button onClick={() => handleClose(ReleaseModalCloseType.CLOSE)}>
                {intl.formatMessage(messages[TranslationKey.UNDERSTAND_THANKS])}
            </Button>
        </>
    );

    return (
        <Modal
            id={ModalTypes.RELEASE_INFO}
            title={intl.formatMessage(messages[TranslationKey.WHATS_NEW])}
            isOpen={true}
            hasOverlay={true}
            onClose={handleClose}
            type='default'
            size='big'
            footer={<FooterButtons />}
        >
            {modal.data.content && (
                <SReleasesContent>
                    {[...modal.data.content].reverse().map(({ id, code, version, release_date, content }) => (
                        <ReleaseItem
                            key={id}
                            code={code}
                            version={version}
                            release_date={release_date}
                            content={content}
                            isModal
                        />
                    ))}
                    <SMoreDetailedBlock>
                        {intl.formatMessage(messages[TranslationKey.MODAL_HELP_SECTION_MORE_DETAILED_INFORMATION])}{' '}
                        <Button to={`/versions`} onClick={() => handleClose(ReleaseModalCloseType.CLOSE)}>
                            {intl.formatMessage(messages[TranslationKey.LINK])}
                        </Button>
                        .
                    </SMoreDetailedBlock>
                </SReleasesContent>
            )}
        </Modal>
    );
};
