import { ApplicationActions } from '@repeat/common-slices';

import { AppDispatch } from '../store';

export const setUndoRedoHotkeysPressed = (payload: { event: React.KeyboardEvent<HTMLInputElement> }) => async (dispatch: AppDispatch) => {
    const { event } = payload;

    if (event.ctrlKey) {
        if (event.shiftKey) {
            dispatch(ApplicationActions.setHotkeyPressed({ keys: ['z'], ctrl: true, shift: true }));
        } else {
            dispatch(ApplicationActions.setHotkeyPressed({ keys: ['z'], ctrl: true, meta: false }));
        }
    }
    if (event.metaKey) {
        if (event.shiftKey) {
            dispatch(ApplicationActions.setHotkeyPressed({ keys: ['z'], meta: true, shift: true }));
        } else {
            dispatch(ApplicationActions.setHotkeyPressed({ keys: ['z'], meta: true }));
        }
    }
};
