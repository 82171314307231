import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { IFileManagerUploadZone } from './FileManagerTypes';
import { SFileManagerUploadZone, SFileManagerUploadZoneDetails } from './SFileManager';

import { Button } from '../Button/Button';

export const FileManagerUploadZone: FC<IFileManagerUploadZone> = ({
    isPreviewOpen,
    isOpenImport,
    isItemDragging,
    translations,
    onImport,
    onOpenImport,
}) => {
    const [hasError, setError] = useState({ error: false, message: '' });

    const acceptFileTypes = { 'text/plain': ['.rpt'] };

    const config = useMemo(
        () => ({
            maxFiles: 1,
            maxSize: 20971520,
            noKeyboard: true,
        }),
        []
    );

    const onValidate = (file: File) => {
        if (file.size > config.maxSize) {
            setError({ error: true, message: '' });
            return null;
        }

        setError({ error: false, message: '' });
        return null;
    };

    const onDrop = useCallback((acceptedFiles: File[]) => {
        acceptedFiles.forEach((file: File) => {
            onImport && onImport(file);
        });
    }, []);

    const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({
        ...config,
        ...(acceptFileTypes && { accept: { ...acceptFileTypes } }),
        onDrop,
        validator: onValidate,
        preventDropOnDocument: false,
    });

    const buttonUploadHandler = useCallback((event: MouseEvent) => {
        event.stopPropagation();
        open();
    }, []);

    const files = acceptedFiles.map((file: File) => (
        <li key={file.name}>
            {file.name} - {file.size}
        </li>
    ));

    return (
        <SFileManagerUploadZone
            className={'file-manager__upload-zone'}
            {...getRootProps()}
            isPreviewOpen={isPreviewOpen}
            onDragLeave={(event: DragEvent) => {
                let target = (event.target as HTMLElement) || null;
                while (!target.classList.contains('file-manager__upload-zone')) {
                    target = target.parentElement as HTMLElement;
                    if (!target) {
                        break;
                    }
                }
                if (!isItemDragging && target) {
                    if (isOpenImport && target.classList.contains('file-manager__upload-zone')) {
                        onOpenImport && onOpenImport(false);
                    }
                }
            }}
        >
            <div
                onDragLeave={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                }}
                onDragEnter={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                }}
            >
                <input {...getInputProps()} />
                <SFileManagerUploadZoneDetails>
                    <h5>{translations?.importTitle}</h5>
                    <p>{translations?.importDetails}</p>
                </SFileManagerUploadZoneDetails>
                <ul>{files}</ul>
                <Button onClick={buttonUploadHandler}>{translations?.importButton}</Button>
            </div>
        </SFileManagerUploadZone>
    );
};
