import { IElemTool, ElemToolTypes } from '@repeat/models';

import { ElementsAction, ElementsTypes } from '../actions/elementsTypes';

export const elemToolReducer = (
    state: IElemTool = {
        x: 0,
        y: 0,
        size: 1,
        height: 1,
        width: 1,
        visible: false,
        picId: 0,
        name: '',
        startSize: 1,
        type: ElemToolTypes.DEFAULT,
        state: '0',
        // angle: 0,
        // warning: false,
    },
    action: ElementsAction
): IElemTool => {
    switch (action.type) {
        case ElementsTypes.ADD_ELEMTOOL:
            return { ...state, ...action.payload };

        case ElementsTypes.GET_PORT:
            return { ...state, port: action.payload };
        case ElementsTypes.START_SIZE:
            return {
                ...state,
                startSize: action.payload,
            };
        case ElementsTypes.CLEAR_ELEMTOOL:
            return state;
        default:
            return state;
    }
};

export default elemToolReducer;
