import { ChangeEvent, FC, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { Divider } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Chip from '@mui/material/Chip';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import type { IUser } from '@repeat/models';
import { ru, TranslationKey } from '@repeat/translations';

import DialogWindow from '../DialogWindow/DialogWindow';
import { BasicList } from '../List/List';

const messages = defineMessages({
    [TranslationKey.PROJECTS_CREATED]: {
        id: TranslationKey.PROJECTS_CREATED,
        defaultMessage: ru[TranslationKey.PROJECTS_CREATED],
    },
    [TranslationKey.LIBRARIES_AVAILABLE]: {
        id: TranslationKey.LIBRARIES_AVAILABLE,
        defaultMessage: ru[TranslationKey.LIBRARIES_AVAILABLE],
    },
});

export const UserCard: FC<IUser> = (el) => {
    const { formatMessage } = useIntl();
    const [checked, setChecked] = useState(false);

    const handleChange = (event: ChangeEvent) => {
        const target = event.target as HTMLInputElement;
        setChecked(target.checked);
    };
    return (
        <div style={{ display: 'flex' }}>
            <Accordion style={{ marginBottom: '20px', width: '85%' }}>
                <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
                    <Typography>{el.name} </Typography> &nbsp;
                    <span>{el.email}</span>{' '}
                </AccordionSummary>
                <Divider variant='middle' />
                <AccordionDetails>
                    <div>
                        {formatMessage(messages[TranslationKey.PROJECTS_CREATED])}: <BasicList userData={el.userData} />{' '}
                    </div>

                    <Typography>{formatMessage(messages[TranslationKey.LIBRARIES_AVAILABLE])}:</Typography>
                    <DialogWindow />
                </AccordionDetails>
            </Accordion>
            <div style={{ marginLeft: 'auto' }}>
                Status:&nbsp;
                <Chip style={{ minWidth: '70px' }} label={checked ? <span>admin</span> : <span>user</span>} />
                <Switch checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
            </div>
        </div>
    );
};
