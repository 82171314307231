import { FC } from 'react';
import { useIntl } from 'react-intl';

// eslint-disable-next-line
import { lte } from 'semver';

// eslint-disable-next-line

import { VERSION } from '@repeat/constants';
import { TVersionHistoryData } from '@repeat/models';
import { TranslationKey } from '@repeat/translations';
import { LoaderFullscreen } from '@repeat/ui-kit';

import { ReleaseItem } from './ReleaseItem';
import { SVersionsContent } from './SComponents';
import { messages } from './translation';

type THelpPageContentProps = {
    isLoading: boolean;
    versionHistoryData: TVersionHistoryData | null;
};

export const VersionsPageContent: FC<THelpPageContentProps> = ({ isLoading, versionHistoryData }) => {
    const intl = useIntl();

    return (
        <SVersionsContent>
            <h3>{intl.formatMessage(messages[TranslationKey.PAGE_HELP_SUBTITLE])}</h3>
            {isLoading ? (
                <LoaderFullscreen />
            ) : (
                <>
                    {versionHistoryData && (
                        <article>
                            <h5>{intl.formatMessage(messages[TranslationKey.HELP_SECTION_CHANGELOG])}</h5>
                            {[...versionHistoryData]
                                .reverse()
                                .filter(({ code }) => lte(code, VERSION))
                                .map(({ id, code, version, release_date, content }) => (
                                    <ReleaseItem
                                        key={id}
                                        code={code}
                                        version={version}
                                        release_date={release_date}
                                        content={content}
                                    />
                                ))}
                        </article>
                    )}
                </>
            )}
        </SVersionsContent>
    );
};
