const convertNumberToNoExponentString = (n: number): string => {
    const sign = +n < 0 ? '-' : '';
    const toStr = n.toString();
    if (!/e/i.test(toStr)) {
        return `${n}`;
    }

    const [lead, decimal, pow] = n
        .toString()
        .replace(/^-/, '')
        .replace(/^([0-9]+)(e.*)/, '$1.$2')
        .split(/e|\./);
    return +pow < 0
        ? sign + '0.' + '0'.repeat(Math.max(Math.abs(Number(pow)) - 1 || 0, 0)) + lead + decimal
        : sign +
              lead +
              (+pow >= decimal.length
                  ? decimal + '0'.repeat(Math.max(+pow - decimal.length || 0, 0))
                  : decimal.slice(0, +pow) + '.' + decimal.slice(+pow));
};

export const convertSecToMs = (seconds: number): number => {
    const ms = seconds / 1000;
    if (ms > 1000) {
        return ms;
    }

    if (ms === Math.ceil(ms)) {
        return ms;
    }

    const MS_LENGTH = 3;
    const secondsWithoutExponent = convertNumberToNoExponentString(seconds);
    const valueParts = String(secondsWithoutExponent).split('.');
    const int = valueParts[0];
    const fractionPart = valueParts[1] || '';

    const fullIntPart = Array(MS_LENGTH).fill(0).join('') + int;

    const msInt = `${fullIntPart.substring(0, fullIntPart.length - MS_LENGTH)}`;
    const msFractionPart = `${fullIntPart.slice(-MS_LENGTH)}${fractionPart}`;

    return Number(`${msInt}.${msFractionPart}`);
};

export const formatModelTime = (ms: number, unitTitle = 'с'): string => {
    if (ms === 0) {
        return `${ms} ${unitTitle}`;
    }

    const FRACTION_LENGTH = 3;
    const MS_IN_SECOND_POWER = 3;

    const seconds = ms / 1000;

    // rule: [t >= 100000: 0.000e+x, т.е. х >=5]
    if (seconds >= 100000) {
        const secondsInt = Math.ceil(seconds);
        const secondsWithoutExponent = convertNumberToNoExponentString(secondsInt);
        const exponentPower = secondsWithoutExponent.length - 1;
        const value = seconds / Math.pow(10, exponentPower);

        const timeParts = String(value).split('.');
        const int = timeParts[0];
        const fractionPart = timeParts[1] || '';
        const fractionPartString = (fractionPart + Array(FRACTION_LENGTH).fill(0).join('')).substring(
            0,
            FRACTION_LENGTH
        );

        return `${int}.${fractionPartString}e+${exponentPower} ${unitTitle}`;
    }

    // rule: [100000 > t >= 0.01: 00000.000]
    if (seconds >= 0.01 && seconds < 100000) {
        const timeParts = String(seconds).split('.');
        const int = timeParts[0];
        const fractionPart = timeParts[1] || '';
        const fractionPartString = (fractionPart + Array(FRACTION_LENGTH).fill(0).join('')).substring(
            0,
            FRACTION_LENGTH
        );

        return `${int}.${fractionPartString} ${unitTitle}`;
    }

    // rule: [0.01 > t: 0.000e+x, т.е. х <=-3]
    const msWithoutExponent = convertNumberToNoExponentString(ms);
    const timeParts = String(msWithoutExponent).split('.');
    let intPart = timeParts[0];
    let fractionPart = timeParts[1] || '';

    let powerCorrection = 0;
    if (intPart.length > 1) {
        const oneDigitInt = intPart.substring(0, 1);
        const restDititsInt = intPart.substring(1, intPart.length);

        powerCorrection = -restDititsInt.length;
        intPart = oneDigitInt;
        fractionPart = `${restDititsInt}${fractionPart}`;
    } else if (Number(intPart) === 0) {
        const fractionPartWithoutHeadZeros = Number(fractionPart).toString();
        const correctionToInt = 1;
        const correctionFromFraction = fractionPart.length - fractionPartWithoutHeadZeros.length;
        powerCorrection = correctionToInt + correctionFromFraction;

        intPart = fractionPartWithoutHeadZeros.substring(0, correctionToInt);
        fractionPart = fractionPartWithoutHeadZeros.substring(correctionToInt);
    }

    const fractionPartString = (fractionPart + Array(FRACTION_LENGTH).fill(0).join('')).substring(0, FRACTION_LENGTH);
    const exponentPower = MS_IN_SECOND_POWER + powerCorrection;

    return `${intPart}.${fractionPartString}e-${exponentPower} ${unitTitle}`;
};
