import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Editor, loader } from '@monaco-editor/react';

import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { ElemProps, PropertyTypes, TSchemaNode } from '@repeat/models';
import { appUserSelectors, workspaceActions, workspaceSelectors } from '@repeat/store';
import { Locales, TranslationKey } from '@repeat/translations';

import { SCodeEditorError, SCodeEditorWrapper } from './SCodeEditor';
import { messages } from './translation';

interface ICodeEditorConfig {
    paths?: {
        vs: string;
    };
    'vs/nls'?: {
        availableLanguages?: object;
    };
}

interface ICodeEditorProps {
    elementId: string;
}

export const CodeEditor: FC<ICodeEditorProps> = ({ elementId }) => {
    const { formatMessage, locale } = useIntl();
    const theme = useAppSelector(appUserSelectors.currentUserTheme);
    const dispatch = useAppDispatch();

    let config: ICodeEditorConfig = {
        paths: {
            vs: '/assets/vs',
        },
    };
    if (locale !== Locales.ENGLISH) {
        config = {
            ...config,
            'vs/nls': {
                availableLanguages: {
                    '*': locale,
                },
            },
        };
    }

    loader.config(config);

    const node: TSchemaNode | null | undefined = useAppSelector(workspaceSelectors.getSchemaNodeById(elementId));

    const codeProperty = node
        ? node.data.elemProps.find((property: ElemProps) => property.type === PropertyTypes.CODE)
        : null;

    const [code, setCode] = useState((codeProperty?.value as string) || '');

    const handleChange = (value: string) => {
        setCode(value);
    };

    useEffect(() => {
        if (!codeProperty) {
            return;
        }

        const updatedProperty = {
            [codeProperty.name]: code,
        };

        dispatch(workspaceActions.setElementPropertiesValues({ id: Number(elementId), elemProps: updatedProperty }));
    }, [code]);

    return (
        <SCodeEditorWrapper>
            {codeProperty && (
                <Editor
                    defaultLanguage={codeProperty.language}
                    defaultValue={code}
                    theme={`vs-${theme}`}
                    onChange={handleChange}
                />
            )}
            {!node && (
                <SCodeEditorError>
                    {formatMessage(messages[TranslationKey.CODE_EDITOR_NODE_NOT_FOUND])}
                </SCodeEditorError>
            )}
            {node && !codeProperty && (
                <SCodeEditorError>
                    {formatMessage(messages[TranslationKey.CODE_EDITOR_PROPERTY_NOT_FOUND])}
                </SCodeEditorError>
            )}
        </SCodeEditorWrapper>
    );
};
