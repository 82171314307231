import { FC } from 'react';
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';

export const TextEditor: FC<{ value: string; setValue: (value: string) => void }> = ({ value, setValue }) => {
    const toolbarOptions = [
        [{ header: '1' }, { header: '2' }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
    ];
    const modules = {
        toolbar: toolbarOptions,
    };

    return <ReactQuill placeholder={'Описание...'} value={value} onChange={setValue} modules={modules} />;
};
