import styled from 'styled-components';

import { uiRGBColors, pxToRem } from '../../config/config';

const StyledAnchor = styled.a`
    color: ${uiRGBColors.darkBlue};
    margin: 0 ${pxToRem(8)};
    text-decoration: none;
    font-size: ${pxToRem(12)};
`;

export { StyledAnchor };
