export enum NotificationTypes {
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
    SUCCESS = 'success',
}

export type TNotificationType = NotificationTypes.ERROR | NotificationTypes.WARNING | NotificationTypes.INFO | NotificationTypes.SUCCESS;

export type TNotificationData = {
    [key: string]: string | number;
};

export type TNotificationDetailMessage = {
    message: string;
    code?: number;
    data?: TNotificationData | null;
};

export type TNotification = {
    message: string;
    type: TNotificationType;
    details?: TNotificationDetailMessage[];
};
