import { createSelector } from '@reduxjs/toolkit';

import { TState } from '@repeat/models';

const elementsSelector = (state: TState) => state.workspace.schema.schemaItems.elements;
const elementsWithParametersSelector = (state: TState) => state.workspace.schema.elementsWithParams;
const selectedElementsSelector = (state: TState) => state.workspace.schema.selectedItems;
export const wiresSelector = (state: TState) => state.workspace.schema.schemaItems.wires;
export const schemaSelector = (state: TState) => state.workspace.schema;

export const getElements = createSelector([elementsSelector], (elements) => elements);
export const getElementsWithParameters = createSelector([elementsWithParametersSelector], (elements) => elements);
export const getElementById = (id: string) => createSelector([elementsSelector], (elements) => elements.find((item) => item.id === id));
export const getSelectedElements = createSelector([selectedElementsSelector], (selectedElements) => selectedElements);
export const getWires = createSelector([wiresSelector], (wires) => wires);
export const getSchema = createSelector([schemaSelector], (schema) => schema);
