import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { TSizesButton, TVariantButton } from './Button';

import { uiRGBColors, pxToRem, radius, shadow, uiColors } from '../../config/config';

const sizesConfig = {
    small: { fontSize: pxToRem(12), padding: `${pxToRem(4)} ${pxToRem(8)}` },
    medium: { fontSize: pxToRem(14), padding: `${pxToRem(8)} ${pxToRem(16)}` },
    big: { fontSize: pxToRem(16), padding: `${pxToRem(8)} ${pxToRem(16)}` },
};

const variantConfig = {
    primary: {
        backgroundColor: uiColors.mainBlue,
        color: uiColors.white,
        backgroundColorHover: uiColors.darkBlue,
        colorHover: uiColors.white,
        border: '1px solid transparent',
    },
    secondary: {
        backgroundColor: uiColors.greyLight,
        color: uiColors.black,
        backgroundColorHover: `rgba(${uiRGBColors.grey}, 0.4)`,
        colorHover: uiColors.black,
        border: '1px solid transparent',
    },
    outlined: {
        backgroundColor: uiColors.white,
        color: uiColors.mainBlue,
        backgroundColorHover: uiColors.lightGrey,
        colorHover: uiColors.darkBlue,
        border: `1px solid ${uiColors.mainBlue}`,
    },
};

export const SButtonWrapper = styled.button<{ size: TSizesButton; variant: TVariantButton }>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    border: ${({ variant }) => variantConfig[variant].border};
    font-size: ${({ size }) => sizesConfig[size].fontSize};
    padding: ${({ size }) => sizesConfig[size].padding};
    color: ${({ variant }) => variantConfig[variant].color};
    background-color: ${({ variant }) => variantConfig[variant].backgroundColor};
    border-radius: ${radius.default};
    box-shadow: ${shadow.controls};
    transition: 0.3s ease-out;

    &:not([disabled]) {
        &:hover {
            background-color: ${({ variant }) => `rgba${variantConfig[variant].backgroundColorHover}, 0.8`};
            color: ${({ variant }) => variantConfig[variant].colorHover};
        }
    }

    &:disabled {
        cursor: not-allowed;
        background-color: rgba(${uiRGBColors.grey}, 0.3);
        color: ${uiColors.white};
    }
`;

export const SAnchor = styled.a`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: ${uiColors.mainBlue};
    text-decoration: none;
    border: none;
    transition: 0.3s ease-out;
    &:hover {
        color: ${uiColors.blueLight};
    }
`;

export const SLink = styled(Link)`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: ${uiColors.mainBlue};
    text-decoration: none;
    border: none;
    transition: 0.3s ease-out;
    &:hover {
        color: ${uiColors.blueLight};
    }
`;
