import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.MALFORMED_EMAIL_MSG]: {
        id: TranslationKey.MALFORMED_EMAIL_MSG,
        defaultMessage: ru[TranslationKey.MALFORMED_EMAIL_MSG],
    },
    [TranslationKey.NAME_ERROR_MSG]: {
        id: TranslationKey.NAME_ERROR_MSG,
        defaultMessage: ru[TranslationKey.NAME_ERROR_MSG],
    },
    [TranslationKey.TOO_MANY_ERROR_MSG]: {
        id: TranslationKey.TOO_MANY_ERROR_MSG,
        defaultMessage: ru[TranslationKey.TOO_MANY_ERROR_MSG],
    },
    [TranslationKey.NAME]: {
        id: TranslationKey.NAME,
        defaultMessage: ru[TranslationKey.NAME],
    },
    [TranslationKey.EMAIL]: {
        id: TranslationKey.EMAIL,
        defaultMessage: ru[TranslationKey.EMAIL],
    },
    [TranslationKey.ENTER_MESSAGE]: {
        id: TranslationKey.ENTER_MESSAGE,
        defaultMessage: ru[TranslationKey.ENTER_MESSAGE],
    },
    [TranslationKey.MESSAGE]: {
        id: TranslationKey.MESSAGE,
        defaultMessage: ru[TranslationKey.MESSAGE],
    },
    [TranslationKey.ERROR_SEND_MESSAGE_TO_SUPPORT]: {
        id: TranslationKey.ERROR_SEND_MESSAGE_TO_SUPPORT,
        defaultMessage: ru[TranslationKey.ERROR_SEND_MESSAGE_TO_SUPPORT],
    },
    [TranslationKey.TECHNICAL_SUPPORT_CAPTION]: {
        id: TranslationKey.TECHNICAL_SUPPORT_CAPTION,
        defaultMessage: ru[TranslationKey.TECHNICAL_SUPPORT_CAPTION],
    },
    [TranslationKey.SUPPORT_SUBJECT]: {
        id: TranslationKey.SUPPORT_SUBJECT,
        defaultMessage: ru[TranslationKey.SUPPORT_SUBJECT],
    },
});
