import { useCallback, useState } from 'react';

import { TFileManagerElement } from '../lib/FileManager/FileManagerTypes';

export const useSelect = (elements: TFileManagerElement[]) => {
    const [selectedBoxes, setSelectedBoxes] = useState<string[]>([]);

    const handleSelect = useCallback(
        (uuid: string) => {
            setSelectedBoxes((state) => {
                if (!state.includes(uuid)) {
                    return [...state, uuid];
                }
                return [...state.filter((filterId) => uuid !== filterId)];
            });
        },
        [selectedBoxes]
    );

    const handleSelectAll = useCallback(() => {
        setSelectedBoxes((state) => {
            const newBoxesIds = state;
            const projectsIds = elements.map((element) => element.uuid);
            if (newBoxesIds.length < projectsIds.length) {
                return Array.from(new Set([...newBoxesIds, ...projectsIds]));
            }
            return [];
        });
    }, [selectedBoxes, elements]);

    const handleUnSelectAll = useCallback(() => {
        setSelectedBoxes([]);
    }, []);

    const handleSelectGroup = useCallback((group: string[]) => {
        setSelectedBoxes([...group]);
    }, []);

    return {
        selectedBoxes,
        handleSelect,
        handleSelectGroup,
        handleSelectAll,
        handleUnSelectAll,
    };
};
