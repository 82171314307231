import { FC, ReactElement, ReactNode, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { ru, TranslationKey } from '@repeat/translations';

const messages = defineMessages({
    [TranslationKey.EDITING]: {
        id: TranslationKey.EDITING,
        defaultMessage: ru[TranslationKey.EDITING],
    },
    [TranslationKey.LIBRARIES_ALL]: {
        id: TranslationKey.LIBRARIES_ALL,
        defaultMessage: ru[TranslationKey.LIBRARIES_ALL],
    },
    [TranslationKey.SAVE]: {
        id: TranslationKey.SAVE,
        defaultMessage: ru[TranslationKey.SAVE],
    },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle: FC<{ id: string; onClose: () => void; children: ReactElement | ReactNode }> = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label='close'
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                />
            ) : null}
        </DialogTitle>
    );
};

export default function DialogWindow() {
    const { formatMessage } = useIntl();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button variant='outlined' onClick={handleClickOpen} />
            <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
                <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
                    {formatMessage(messages[TranslationKey.EDITING])}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom style={{ minWidth: '600px' }}>
                        {formatMessage(messages[TranslationKey.LIBRARIES_ALL])}
                    </Typography>
                    {/* <Typography gutterBottom>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
            Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
            auctor.
          </Typography>
          <Typography gutterBottom>
            Aenean lacinia bibendum nulla sed consectetur. Praesent commodo
            cursus magna, vel scelerisque nisl consectetur et. Donec sed odio
            dui. Donec ullamcorper nulla non metus auctor fringilla.
          </Typography> */}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        {formatMessage(messages[TranslationKey.SAVE])}
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
