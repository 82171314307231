import { ChangeEvent, FC, InputHTMLAttributes, useEffect, useState } from 'react';

import styled from 'styled-components';

import { pxToRem, uiColors } from '../../config/config';

interface IInput extends InputHTMLAttributes<HTMLInputElement> {
    placeholder?: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    id: string;
    label?: string;
    error?: string;
}

const SLabel = styled.label`
    color: var(--ui-label);
    font-size: ${pxToRem(14)};
    margin-bottom: ${pxToRem(8)};
`;

const SError = styled.span`
    color: ${uiColors.red};
    display: flex;
    position: absolute;
    top: 100%;
`;

const SInput = styled.input<{ disabled: boolean | undefined }>`
    background: ${({ disabled }) => (disabled ? `${uiColors.lightGrey}` : `${uiColors.white}`)};
    color: ${({ disabled }) => (disabled ? `${uiColors.darkGrey}` : `${uiColors.black}`)};
    border: var(ui-border);
    border-radius: 2px;
    padding: ${pxToRem(4)} ${pxToRem(8)};
    position: relative;
    width: 100%;
`;

export const Input: FC<IInput> = (props) => {
    const { onChange, label, id, error, defaultValue = '', disabled, ...rest } = props;
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    return (
        <>
            {label && <SLabel htmlFor={id}>{label}</SLabel>}
            <SInput
                {...rest}
                value={value}
                id={id}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const value = event.target.value;
                    setValue(value);
                    onChange(event);
                }}
                disabled={disabled}
            />
            {error && <SError>{error}</SError>}
        </>
    );
};
