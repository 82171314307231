import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { uiColors } from '../../config/config';

export interface IStyledLinkProps {
    alignself?: 'end' | 'start' | 'flex-end' | 'flex-start' | 'center';
    justify?: 'end' | 'start' | 'flex-end' | 'flex-start' | 'center';
    font?: string;
}

const StyledLink = styled(Link)<IStyledLinkProps>`
    color: ${({ color }) => (color ? color : uiColors.mainBlue)};
    align-self: ${({ alignself }) => (alignself ? alignself : 'inherit')};
    justify-content: ${({ justify }) => (justify ? justify : 'inherit')};
    font-size: ${({ font }) => (font ? font : 'inherit')};
    text-decoration: none;
    transition: color 0.2s ease;

    &:hover {
        color: ${uiColors.blueLight};
    }
`;

export { StyledLink };
