import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.DELETE]: {
        id: TranslationKey.DELETE,
        defaultMessage: ru[TranslationKey.DELETE],
    },
});
