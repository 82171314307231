import React, { FC } from 'react';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

export const BasicList: FC<any> = ({ userData }) => {
    return (
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <nav aria-label='main mailbox folders'>
                <List>
                    {userData.length ? (
                        userData.map((el: any) => (
                            <ListItem key={el.id} disablePadding>
                                <ListItemButton>
                                    <ListItemIcon></ListItemIcon>
                                    <ListItemText primary={el.name} />
                                </ListItemButton>
                            </ListItem>
                        ))
                    ) : (
                        <span>no projects</span>
                    )}
                </List>
            </nav>
        </Box>
    );
};
