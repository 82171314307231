import styled from 'styled-components';

import { pxToRem, uiColors } from '../../../config/config';
import { transitionAnimation, verticalAlign } from '../../../config/mixin';

export const SSidebarList = styled.div`
    font-size: ${pxToRem(14)};
    padding: 0 ${pxToRem(16)};

    > * {
        ${verticalAlign()};
        color: var(--ui-heading);
        margin: ${pxToRem(16)} 0;
        text-decoration: none;
    }

    i {
        margin-right: ${pxToRem(8)};
        width: ${pxToRem(24)};

        svg {
            fill: var(--ui-heading);
        }
    }

    span {
        margin-left: 0 !important;
    }

    svg {
        ${transitionAnimation(['fill'])};
    }

    a {
        &:hover,
        &.active {
            color: var(--ui-link-active);

            svg {
                fill: var(--ui-link-active);
            }
        }
    }
`;
