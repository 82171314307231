import UploadService from './UploadService';

type TUploadFilePayload = {
    elementId: number;
    projectId: number;
    file: File;
    onChunkUploaded: (uploadedBytes: number) => void;
    onUploaded: (file: TUploadedFile) => void;
};

export type TUploadedFile = {
    uuid: string;
    createdAt: string;
    fileType: string;
    contentType: string;
    originalName: string;
    size: number;
    projectId: number;
    elementId: number;
};

interface IUploadFile {
    (payload: TUploadFilePayload): void;
}

export default class FileUploadService {
    static uploadFile: IUploadFile = async (payload: TUploadFilePayload) => {
        const headers = {
            projectId: payload.projectId,
            elementId: payload.elementId,
        };
        const chunkSize = 1048576; // 1Mb

        const uploadService = new UploadService('/api/v1/modeling/uploadProjectFile', payload.file, headers, chunkSize);
        await uploadService.uploadFile<TUploadedFile>(payload.onChunkUploaded, payload.onUploaded);
    };
}
