import { AxiosResponse } from 'axios';

import { ILibraryItem, IVariables, TSchemaConnection, TSchemaGroup, TSchemaNode } from '@repeat/models';

import { TProxyMap } from 'libs/models/src/lib/element';
import { $api } from './http';

interface ReturnSaveBlockContract {
    block: ILibraryItem;
    image: string;
    id: string;
    categoryId: string;
    state: { [key: string]: string | boolean };
}
type ReturnGetBlockContract = {
    block: ILibraryItem;
    categoryId: string;
    image: string;
    state: { [key: string]: string | boolean };
    data: { groups: TSchemaGroup[] };
    header: {globalVariables?: IVariables}
};

type SaveBlockPayload = {
    image?: string | null;
    category?: string;
    block: ILibraryItem & { proxyMap: TProxyMap | null };
    schema: { groups: TSchemaGroup[]; elements: TSchemaNode[]; wires: TSchemaConnection[]; proxyMap: TProxyMap | null };
    globalVariables?: IVariables;
};
type EditBlockPayload = {
    image?: string | null;
    category?: string;
    block: ILibraryItem & { proxyMap: TProxyMap | null };
    schema?: {
        groups: TSchemaGroup[];
        elements: TSchemaNode[];
        wires: TSchemaConnection[];
        proxyMap: TProxyMap | null;
    };
};
type GetBlockPayload = {
    blockId: string;
};
interface ISaveBlock {
    (payload: SaveBlockPayload, signal?: AbortSignal): Promise<AxiosResponse<ReturnSaveBlockContract>>;
}
interface IEditBlock {
    (payload: EditBlockPayload, signal?: AbortSignal): Promise<AxiosResponse<ReturnSaveBlockContract>>;
}
interface IGetBlocks {
    (signal?: AbortSignal): Promise<AxiosResponse<ReturnGetBlockContract[]>>;
}
interface IGetBlock {
    (payload: GetBlockPayload, signal?: AbortSignal): Promise<AxiosResponse<ReturnGetBlockContract>>;
}

type ReturnDeleteBlockContract = {
    message: string;
};
type DeleteBlockPayload = {
    blockIds: string[];
};
interface IDeleteBlock {
    (payload: DeleteBlockPayload, signal?: AbortSignal): Promise<AxiosResponse<ReturnDeleteBlockContract>>;
}

export default class UserBlockService {
    static saveBlock: ISaveBlock = async (payload: SaveBlockPayload, signal?: AbortSignal) => {
        return await $api.post(
            '/api/v1/blocks/userBlock',
            {
                block: payload.block,
                schema: {
                    data: { ...payload.schema, elements: [], wires: [], proxyMap: payload.schema.proxyMap },
                    header: { globalVariables: payload.globalVariables },
                },
                image: payload.image || null,
            },
            { signal }
        );
    };
    static getBlocks: IGetBlocks = async (signal?: AbortSignal) => {
        return await $api.get('/api/v1/blocks/userBlock', { signal });
    };
    static getBlock: IGetBlock = async (payload: GetBlockPayload, signal?: AbortSignal) => {
        const { blockId } = payload;
        return await $api.get(`/api/v1/blocks/userBlock?blockId=${blockId}`, { signal });
    };
    static editBlock: ISaveBlock = async (payload: SaveBlockPayload, signal?: AbortSignal) => {
        return await $api.patch(
            '/api/v1/blocks/userBlock',
            { block: payload.block, schema: { data: payload.schema, header: {globalVariables: payload.globalVariables} }, image: payload.image || null },
            { signal }
        );
    };
    static editBlockParameters: IEditBlock = async (payload: EditBlockPayload, signal?: AbortSignal) => {
        return await $api.patch(
            '/api/v1/blocks/userBlock',
            { block: payload.block, image: payload.image || '' },
            { signal }
        );
    };
    static deleteBlocks: IDeleteBlock = async (payload: DeleteBlockPayload, signal?: AbortSignal) => {
        return await $api.delete(`/api/v1/blocks/userBlock?blockId=${payload.blockIds.join('&blockId=')}`, { signal });
    };
    // TODO:
    // static duplicateBlock: ISaveBlock = async (payload: SaveBlockPayload, signal?: AbortSignal) => {
    //     return await $api.post('/api/v1/blocks/userBlock/duplicate', payload, { signal });
    // };
}
