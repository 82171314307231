import styled from 'styled-components';

import { pxToRem } from '@repeat/ui-kit';

export const SProjectsList = styled.ol`
    display: flex;
    flex-direction: column;
    list-style-type: decimal;

    li {
        color: var(--ui-link-secondary);
        overflow: hidden;
        display: inline;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: ${pxToRem(4)} 0;
    }
`;

export const SOpenBusyProjectList = styled.ul`
    margin: ${pxToRem(16)} 0 ${pxToRem(8)};

    li {
        margin: ${pxToRem(4)} 0;
    }
`;
