import { PayloadAction } from '@reduxjs/toolkit';

import { IWorkspaceState, Statuses } from '@repeat/models';

export const SaveProjectSlices = {
    // saveProject actions
    saveProjectRequest: (state: IWorkspaceState) => ({
        ...state,
        schema: {
            ...state.schema,
            saveProject: {
                ...state.schema.saveProject,
                status: Statuses.LOADING,
            },
        },
    }),
    saveProjectSuccess: (state: IWorkspaceState) => ({
        ...state,
        schema: {
            ...state.schema,
            saveProject: {
                ...state.schema.saveProject,
                status: Statuses.SUCCEEDED,
            },
        },
    }),
    saveProjectFailed: (state: IWorkspaceState, { payload }: PayloadAction<{ error: string }>) => ({
        ...state,
        schema: {
            ...state.schema,
            saveProject: {
                ...state.schema.saveProject,
                status: Statuses.FAILED,
                error: payload.error,
            },
        },
    }),
};
