import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { layoutSize, pxToRem, uiColors, uiRGBColors, zIndex } from '../../../config/config';
import { verticalAlign } from '../../../config/mixin';

const SHeaderLogo = styled.div`
    align-items: center;
    color: ${uiColors.white};
    display: flex;

    img {
        max-width: ${pxToRem(130)};
    }

    a {
        ${verticalAlign()};
        text-decoration: none;
    }
`;

const SHeaderUserControls = styled.div`
    ${verticalAlign()};
    justify-content: flex-end;

    i {
        max-height: ${pxToRem(20)};
        max-width: ${pxToRem(20)};
    }

    > * {
        margin-left: ${pxToRem(16)};
    }
`;

const SHeaderVersion = styled.sub`
    font-size: ${pxToRem(10)};
    color: rgba(${uiRGBColors.white});
    margin-left: ${pxToRem(8)};
`;

const SHeaderModelControl = styled.div`
    ${verticalAlign()};
    justify-content: center;
`;

const SHeader = styled.header`
    align-items: center;
    background-color: var(--ui-secondary);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: ${pxToRem(parseInt(layoutSize.header))};
    grid-area: Header;
    gap: ${pxToRem(16)};
    left: 0;
    max-width: 100vw;
    padding: 0 ${pxToRem(16)};
    position: fixed;
    top: 0;
    width: 100%;
    z-index: ${zIndex.header};
`;

interface IHeader {
    Navigation: React.ReactNode | React.ReactElement | null;
    logoUrl?: string;
    version?: string;
    children?: React.ReactNode;
    indexUrl?: string;
    isExternalUrl?: boolean;
}

const Logo: FC<{ logo: string }> = ({ logo }) => <img src={logo} alt='project logo' />;
const UniversalLink: FC<{ url: string; isExternal: boolean; children?: React.ReactNode }> = ({
    url,
    isExternal,
    children,
}) => {
    if (isExternal) {
        return <a href={url}>{children}</a>;
    }

    return <Link to={url}>{children}</Link>;
};

export const Header: FC<IHeader> = ({
    version = '1.0.0',
    children,
    Navigation = null,
    logoUrl = '',
    indexUrl = '/',
    isExternalUrl = false,
}) => {
    return (
        <SHeader>
            <SHeaderLogo>
                <UniversalLink url={indexUrl} isExternal={isExternalUrl}>
                    <Logo logo={logoUrl} />
                    <SHeaderVersion>{version}</SHeaderVersion>
                </UniversalLink>
            </SHeaderLogo>
            <SHeaderModelControl>{children}</SHeaderModelControl>
            {Navigation && <SHeaderUserControls>{Navigation}</SHeaderUserControls>}
        </SHeader>
    );
};
