import React, { FC } from 'react';

import styled from 'styled-components';

import { pxToRem, radius, shadow as shadowConfig } from '../../config/config';

interface IAccordion {
    shadow?: boolean;
    children: React.ReactNode;
}

export const StyledAccordion = styled.ul<IAccordion>`
    box-shadow: ${({ shadow }) => (shadow ? `${shadowConfig.default}` : 'none')};
    border-radius: ${radius.default};
    list-style-type: none;
    margin: ${pxToRem(16)} 0;
    padding: ${({ shadow }) => (shadow ? `${pxToRem(8)} 0 0 0` : '0')};
    width: 100%;
`;

export const Accordion: FC<IAccordion> = ({ children, shadow }) => <StyledAccordion shadow={shadow}>{children}</StyledAccordion>;

export { AccordionItem } from './AccordionItem';
