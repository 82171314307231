import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.PROJECT_IMPORT_FILE_DESCRIPTION]: {
        id: TranslationKey.PROJECT_IMPORT_FILE_DESCRIPTION,
        defaultMessage: ru[TranslationKey.PROJECT_IMPORT_FILE_DESCRIPTION],
    },
    [TranslationKey.PROJECT_IMPORT_SELECT_FILE]: {
        id: TranslationKey.PROJECT_IMPORT_SELECT_FILE,
        defaultMessage: ru[TranslationKey.PROJECT_IMPORT_SELECT_FILE],
    },
});
