import { TTrendData } from './chart';
import { IGraphsState } from './charts';
import { TClickType } from './click';
import { IEventLogData } from './event-log';
import { IDraggableLibraryItemState, ILibraryItem, ILibraryItemsState, ILibraryPortTypesState } from './libraryItem';
import { ModelStatus } from './model-status';
import {
    ISchemaState as INewSchemaState,
    ITrendDataMap,
    TModelParameter,
    TWatchSchemaItemParameter,
} from './schema/index';
import { Status, TrendsStatus } from './status';
import { IUndoRedoState } from './undoredo';
import { EUserPermissions } from './user';

export enum WorkspaceModes {
    MAIN = 'main',
    CODE_EDITOR = 'code-editor',
    SUBMODEL = 'submodel',
    GROUP = 'group',
}
export type TWorkspaceMode =
    | WorkspaceModes.MAIN
    | WorkspaceModes.CODE_EDITOR
    | WorkspaceModes.SUBMODEL // PROJECT block - read only mode
    | WorkspaceModes.GROUP;

export type TLivePermissions = {
    [key in EUserPermissions]: boolean;
};

export interface IWorkspaceState {
    meta: {
        isInitialized: boolean;
        isLoading: boolean;
        mode: TWorkspaceMode;
        elementId: string | null;
        groupId: string | null;
        userBlockId: string | null;
        isUserBlockEditor: boolean;
    };
    libraryItems: ILibraryItemsState;
    libraryPortTypes: ILibraryPortTypesState;
    userBlocks: {
        items: ILibraryItem[];
        addUserBlock: {
            status: Status;
            error: string | null;
        };
        getUserBlock: {
            status: Status;
            error: string | null;
        };
        getUserBlocks: {
            status: Status;
            error: string | null;
        };
        deleteUserBlocks: {
            status: Status;
            error: string | null;
        };
    };
    schema: INewSchemaState;
    draggableLibraryItem: IDraggableLibraryItemState;
    modelControl: {
        modelStatus: ModelStatus;
        modelTime: number;
        runProject: {
            status: Status;
            error: string | null;
        };
        stopProject: {
            status: Status;
            error: string | null;
        };
        softStopProject: {
            status: Status;
            error: string | null;
        };
        freezeProject: {
            status: Status;
            error: string | null;
        };
        generateSourcesCode: {
            status: Status;
            error: string | null;
        };
        generateNoNameFile: {
            status: Status;
            error: string | null;
        };
    };
    trends: {
        watchingParameters: (TWatchSchemaItemParameter | null)[];
        YParameters: (TWatchSchemaItemParameter | null)[];
        modelParameters: TModelParameter[];
        dataMap: ITrendDataMap;
        data: TTrendData[];
        dataBuffer: TTrendData;
        dataBufferLastTimeMs: number;
        status: Status;
        error: string | null;
        commonStatus: TrendsStatus;
        commonError: string | null;
        getTrendDataStatus: Status;
        getTrendDataError: string | null;
        getTrendDataFailedAttempts: number;
        getTrendLastTime: number;
    };
    undoRedo: IUndoRedoState;
    click: TClickType;
    eventLog: { list: IEventLogData[] };
    settings: IProjectSettingsOptions;
    graphs: IGraphsState;
    tools: {
        shortCircuit: {
            status: Status;
            error: boolean | null;
        };
    };
    modules: { [key: string]: number } | null;
    livePermissions: {
        isEstablishingConnection: boolean;
        isConnected: boolean;
        permissions: TLivePermissions;
    };
}

export interface IVariables {
    [key: string]: string;
}

export interface ITool {
    id: string;
    active: boolean | undefined;
    type: string;
}

export type TProjectSettingsOption = boolean | number | string;
export interface IProjectSettingsOptions {
    modellingTime: string;
    discretizationStep: string;
    isRealTimeSync: boolean;
    isCompression: boolean;
    compressionType: string;
    compressionAccuracy: string;
    systemType: string;
    taskNumber: number;
    version: string;
    integrationStep: string;
    taskName?: string;
    modelHost?: string;
    modelPort?: number;
    hash?: string;
    globalVariables?: IVariables;
    tools?: ITool[];
}
