import { TaskAction, TaskTypes } from '../actions/taskTypes';

const initialState = {
    systemType: 'ee',
    taskNumber: 1,
};

export function taskReducer(state = initialState, action: TaskAction) {
    const { type, payload } = action;
    switch (type) {
        case TaskTypes.SET_TASK:
            return { systemType: payload.systemType, taskNumber: Number(payload.taskNumber) };

        default:
            return state;
    }
}
export default taskReducer;
