import { Action } from 'redux';

/* eslint-disable @typescript-eslint/no-explicit-any */

export enum TaskTypes {
    SET_TASK = 'SET_TASK',
}

export interface TaskAction extends Action<TaskTypes> {
    type: TaskTypes;
    payload: any;
}
