import styled from 'styled-components';

import { DefaultTableStyles, Icon, Mixins, pxToRem, radius, uiColors } from '@repeat/ui-kit';

export const SWrapperProjectTable = styled.div`
    background-color: var(--ui-background);
    padding: ${pxToRem(16)};
    border-radius: ${radius.default};
`;

export const SProjectTable = styled.table`
    ${DefaultTableStyles};

    th {
        text-align: left;
        text-transform: uppercase;
        cursor: pointer;
        white-space: nowrap;
    }

    tr > td:first-child {
        width: 50%;
    }

    label {
        margin-bottom: 0;
    }
`;

export const SProjectWrapper = styled.div`
    max-width: 1024px;
    position: relative;

    table {
        margin-bottom: ${pxToRem(32)};
    }
`;

export const STableRowItem = styled.td<{ isDisabled?: boolean }>`
    &[data-title] {
        color: ${({ isDisabled }) => (isDisabled ? `${uiColors.darkGrey}` : `var(--ui-link-secondary)`)};
        cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
    }

    span {
        ${Mixins.verticalAlign()};
        line-height: ${pxToRem(16)};
    }
`;

export const STableRowImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    width: 100%;

    img {
        display: block;
        height: ${pxToRem(100)};
        object-fit: cover;
        object-position: center;
        width: 100%;
    }

    i {
        max-width: ${pxToRem(64)};
        max-height: ${pxToRem(100)};

        svg {
            fill: var(--ui-text);
        }
    }
`;

export const SColumnText = styled.span<{ isActive: boolean }>`
    color: ${({ isActive }) => (isActive ? `var(--ui-link-secondary)` : `var(--ui-link-default)`)};
    text-decoration: ${({ isActive }) => (isActive ? 'underline' : 'none')};
`;

export const RotatedIcon = styled(Icon)`
    svg {
        transform: rotate(180deg);
    }
`;

export const STableRowButtons = styled.div`
    display: flex;
    justify-content: space-between;

    div:not(:first-child):not(:last-child) button {
        margin: 0 ${pxToRem(8)};
    }

    i {
        min-width: ${pxToRem(20)} !important;
    }
`;
