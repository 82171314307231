import { FC } from 'react';

import { Notifications } from '../../features/Notifications/Notifications';

export const RootLayout: FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <>
            {children}
            <Notifications />
        </>
    );
};
