import { FC } from 'react';

import styled from 'styled-components';

import { pxToRem, uiColors } from '../../config/config';
import { IconButton } from '../IconButton/IconButton';

export const STagCloudItem = styled.div<Pick<ITagCloudItem, 'active'>>`
    background-color: var(--ui-tags);
    border-radius: ${pxToRem(25)};
    color: var(--ui-text);
    cursor: pointer;
    display: inline-flex;
    font-size: ${pxToRem(10)};
    padding: ${pxToRem(4)} ${pxToRem(8)};

    ${({ active }) =>
        active &&
        `
        background-color: var(--ui-tags-active);
        color: ${uiColors.white};

        i {
            max-width: ${pxToRem(12)};
        }

        svg {
            fill: white;
        }
    `}
`;

interface ITagCloudItem {
    active: boolean;
    text: string;
    handleSelectItem: (id: number) => void;
    id: number;
    showCloseIcon: boolean;
}

export const TagCloudItem: FC<ITagCloudItem> = ({ active, text, handleSelectItem, id, showCloseIcon }) => {
    const clickHandler = () => {
        handleSelectItem(id);
    };

    if (text) {
        return (
            <STagCloudItem data-index={id} active={active} onClick={clickHandler}>
                {text}
                {showCloseIcon && active && (
                    <IconButton data-index={id} noHover name={'close'} fill={'black'} onClick={clickHandler} />
                )}
            </STagCloudItem>
        );
    }
    return null;
};
