import styled from 'styled-components';

import { pxToRem, radius, uiColors } from '@repeat/ui-kit';

export const SNodeNotificationWrapper = styled.div`
    padding: ${pxToRem(4)};
    border-radius: ${radius.default};
`;

export const SAttentionMessage = styled.div<{ color?: string }>`
    padding: ${pxToRem(4)};
    border-radius: ${radius.default};
    background: var(--ui-notification);
    overflow-wrap: break-word;
    color: ${({ color }) => (color ? color : uiColors.red)};
`;
