import { Status } from './status';

export enum LicenseActivationStatuses {
    REQUIRED = 'ACTIVATION_REQUIRED',
    COMPLETED = 'ACTIVATION_COMPLETED',
}
export type TLicenseActivationStatus = LicenseActivationStatuses.REQUIRED | LicenseActivationStatuses.COMPLETED;

export enum LicenseValidationStatuses {
    VALID = 'LICENSE_VALID',
}
export type TLicenseValidationStatus = LicenseValidationStatuses.VALID;

export interface ILicense {
    licenseKey: string | null;
    activationPeriod: number | null;
    activationStatus: TLicenseActivationStatus | null;
    validationStatus: TLicenseValidationStatus | null;
    installedAt: string | null;
    createdAt: string | null;
    expireAt: string | null;
    trialPeriodExpireAt?: string;
}
export interface ILicenseState {
    activeLicense: ILicense | null;
    newLicense: ILicense | null;
    activationCode: string | null;
    getActiveKey: {
        status: Status;
        error: string | null;
    };
    getNewKey: {
        status: Status;
        error: string | null;
    };
    getActivationCode: {
        status: Status;
        error: string | null;
    };
    saveKey: {
        status: Status;
        error: string | null;
    };
    uploadLicenseFile: {
        status: Status;
        error: string | null;
        fileSizeBytes: number;
        uploadedBytes: number;
    };
    activateLicense: {
        status: Status;
        error: string | null;
    };
}
