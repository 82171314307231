import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import styled from 'styled-components';

import { TranslationKey } from '@repeat/translations';
import {
    Icon,
    pxToRem,
    StyledAccordionItem,
    StyledAccordionItemBody,
    StyledAccordionItemTitle,
    StyledTitleWrapper,
    uiColors,
} from '@repeat/ui-kit';

import { messages } from '../translation';

interface ILibraryItemsAccordionItem {
    title: string;
    iconFill?: string;
    children: React.ReactNode;
    isSubLibrary?: boolean;
    hasSubLibraries?: boolean;
    opened: boolean;
    disabled: boolean;
}

const SLibraryItemsAccordionItem = styled(StyledAccordionItem)<{ open: boolean; isSubLibrary: boolean }>`
    padding: 0 0 0 ${pxToRem(16)};
    border: none;
    margin-bottom: ${({ open }) => (!open ? `${pxToRem(8)}` : 0)};
    overflow: ${({ isSubLibrary }) => (isSubLibrary ? 'visible' : 'hidden')};
`;

const SModifiedTitleWrapper = styled(StyledTitleWrapper)<{ open: boolean; isSubLibrary: boolean }>`
    padding-right: 0;
`;

const SLibraryItemsAccordionItemTitle = styled(StyledAccordionItemTitle)<{
    isSubLibrary: boolean;
    disabled: boolean;
    isDevTitle: boolean;
}>`
    color: ${({ open }) => (open ? `var(--ui-link-active)` : `var(--ui-link-default)`)};
    color: ${({ isDevTitle }) => (isDevTitle ? uiColors.green : 'var(--ui-link-default)')};
    font-size: ${({ isSubLibrary }) => isSubLibrary && `${pxToRem(12)}`};
    padding-left: ${pxToRem(8)};

    > i:last-child {
        transform: ${({ open }) => (!open ? 'rotate(-90deg) translateX(50%)' : 'rotate(0) translateY(-50%)')};
        left: -${pxToRem(16)};
        top: 50%;

        svg {
            fill: var(--ui-link-default);
        }
    }

    &:hover:not([disabled]) {
        ${SModifiedTitleWrapper} {
            color: var(--ui-link-active);
        }

        > i:last-child {
            svg {
                fill: var(--ui-link-active);
            }
        }
    }

    &[disabled] {
        color: var(--ui-label) !important;
        cursor: not-allowed;

        svg {
            fill: var(--ui-label) !important;
        }
    }
`;

const SLibraryItemsAccordionItemBody = styled(StyledAccordionItemBody)<{
    open: boolean;
    isSubLibrary: boolean;
    hasSubLibraries: boolean;
}>`
    margin-left: ${({ isSubLibrary }) => (isSubLibrary ? `-${pxToRem(32)}` : `-${pxToRem(16)}`)};
    padding: 0;
`;

export const LibraryItemsAccordionItem: React.FC<ILibraryItemsAccordionItem> = ({
    opened,
    title,
    children,
    iconFill,
    isSubLibrary = false,
    hasSubLibraries = false,
    disabled,
}) => {
    const { formatMessage } = useIntl();

    const [open, setOpen] = useState(opened);
    const [isDevTitle, setIsDevTitle] = useState<boolean>(false);

    useEffect(() => {
        setOpen(opened);
    }, [opened]);

    const handleClick = () => {
        if (!disabled) {
            setOpen(!open);
        }
    };

    useEffect(() => {
        title === formatMessage(messages[TranslationKey.DEVELOPMENT]) && setIsDevTitle(true);
    }, [isDevTitle]);

    useEffect(() => {
        if (open && disabled) {
            setOpen(false);
        }
    }, [disabled]);

    return (
        <SLibraryItemsAccordionItem open={open} isSubLibrary={isSubLibrary}>
            <SLibraryItemsAccordionItemTitle
                disabled={disabled}
                isSubLibrary={isSubLibrary}
                open={open}
                onClick={handleClick}
                isDevTitle={isDevTitle}
            >
                <SModifiedTitleWrapper isSubLibrary={isSubLibrary} open={open}>
                    {title}
                </SModifiedTitleWrapper>
                <Icon name='chevron' fill={open ? `${uiColors.mainBlue}` : `${uiColors.black}`} />
            </SLibraryItemsAccordionItemTitle>
            <SLibraryItemsAccordionItemBody hasSubLibraries={hasSubLibraries} isSubLibrary={isSubLibrary} open={open}>
                {children}
            </SLibraryItemsAccordionItemBody>
        </SLibraryItemsAccordionItem>
    );
};
