import { Action } from 'redux';

/* eslint-disable @typescript-eslint/no-explicit-any */

export enum TrendsParamsTypes {
    SET_TREND_PARAMS = 'SET_TREND_PARAMS',
    CLEAR_TREND_PARAMS = 'CLEAR_TREND_PARAMS',
}

export interface TrendParamsAction extends Action<TrendsParamsTypes> {
    type: TrendsParamsTypes;
    payload?: any;
}
