import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.WORKSPACE_MEMORY_USAGE]: {
        id: TranslationKey.WORKSPACE_MEMORY_USAGE,
        defaultMessage: ru[TranslationKey.WORKSPACE_MEMORY_USAGE],
    },
    [TranslationKey.WORKSPACE_MEMORY_LIMIT]: {
        id: TranslationKey.WORKSPACE_MEMORY_LIMIT,
        defaultMessage: ru[TranslationKey.WORKSPACE_MEMORY_LIMIT],
    },
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_REALTIME_SYNC]: {
        id: TranslationKey.WORKSPACE_MEMORY_LIMIT_REALTIME_SYNC,
        defaultMessage: ru[TranslationKey.WORKSPACE_MEMORY_LIMIT_REALTIME_SYNC],
    },
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_REALTIME_SYNC_TOOLTIP]: {
        id: TranslationKey.WORKSPACE_MEMORY_LIMIT_REALTIME_SYNC_TOOLTIP,
        defaultMessage: ru[TranslationKey.WORKSPACE_MEMORY_LIMIT_REALTIME_SYNC_TOOLTIP],
    },
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_MODELLING_TIME]: {
        id: TranslationKey.WORKSPACE_MEMORY_LIMIT_MODELLING_TIME,
        defaultMessage: ru[TranslationKey.WORKSPACE_MEMORY_LIMIT_MODELLING_TIME],
    },
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_MODELLING_TIME_TOOLTIP]: {
        id: TranslationKey.WORKSPACE_MEMORY_LIMIT_MODELLING_TIME_TOOLTIP,
        defaultMessage: ru[TranslationKey.WORKSPACE_MEMORY_LIMIT_MODELLING_TIME_TOOLTIP],
    },
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP]: {
        id: TranslationKey.WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP,
        defaultMessage: ru[TranslationKey.WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP],
    },
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP_TOOLTIP]: {
        id: TranslationKey.WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP_TOOLTIP,
        defaultMessage: ru[TranslationKey.WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP_TOOLTIP],
    },
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_COMPRESSION_USE]: {
        id: TranslationKey.WORKSPACE_MEMORY_LIMIT_COMPRESSION_USE,
        defaultMessage: ru[TranslationKey.WORKSPACE_MEMORY_LIMIT_COMPRESSION_USE],
    },
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_COMPRESSION_USE_TOOLTIP]: {
        id: TranslationKey.WORKSPACE_MEMORY_LIMIT_COMPRESSION_USE_TOOLTIP,
        defaultMessage: ru[TranslationKey.WORKSPACE_MEMORY_LIMIT_COMPRESSION_USE_TOOLTIP],
    },
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_COMPRESSION_ACCURACY]: {
        id: TranslationKey.WORKSPACE_MEMORY_LIMIT_COMPRESSION_ACCURACY,
        defaultMessage: ru[TranslationKey.WORKSPACE_MEMORY_LIMIT_COMPRESSION_ACCURACY],
    },
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_COMPRESSION_TYPE]: {
        id: TranslationKey.WORKSPACE_MEMORY_LIMIT_COMPRESSION_TYPE,
        defaultMessage: ru[TranslationKey.WORKSPACE_MEMORY_LIMIT_COMPRESSION_TYPE],
    },
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_CALCULATION]: {
        id: TranslationKey.WORKSPACE_MEMORY_LIMIT_CALCULATION,
        defaultMessage: ru[TranslationKey.WORKSPACE_MEMORY_LIMIT_CALCULATION],
    },
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_MODELLING]: {
        id: TranslationKey.WORKSPACE_MEMORY_LIMIT_MODELLING,
        defaultMessage: ru[TranslationKey.WORKSPACE_MEMORY_LIMIT_MODELLING],
    },
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_GRAPHS]: {
        id: TranslationKey.WORKSPACE_MEMORY_LIMIT_GRAPHS,
        defaultMessage: ru[TranslationKey.WORKSPACE_MEMORY_LIMIT_GRAPHS],
    },
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_MEMORY_CALCULATION]: {
        id: TranslationKey.WORKSPACE_MEMORY_LIMIT_MEMORY_CALCULATION,
        defaultMessage: ru[TranslationKey.WORKSPACE_MEMORY_LIMIT_MEMORY_CALCULATION],
    },
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_MEMORY_POINT]: {
        id: TranslationKey.WORKSPACE_MEMORY_LIMIT_MEMORY_POINT,
        defaultMessage: ru[TranslationKey.WORKSPACE_MEMORY_LIMIT_MEMORY_POINT],
    },
    [TranslationKey.OBLIGATORY_FIELD_MSG]: {
        id: TranslationKey.OBLIGATORY_FIELD_MSG,
        defaultMessage: ru[TranslationKey.OBLIGATORY_FIELD_MSG],
    },
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_INTEGRATION_STEP]: {
        id: TranslationKey.WORKSPACE_MEMORY_LIMIT_INTEGRATION_STEP,
        defaultMessage: ru[TranslationKey.WORKSPACE_MEMORY_LIMIT_INTEGRATION_STEP],
    },
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_INTEGRATION_STEP_ERROR]: {
        id: TranslationKey.WORKSPACE_MEMORY_LIMIT_INTEGRATION_STEP_ERROR,
        defaultMessage: ru[TranslationKey.WORKSPACE_MEMORY_LIMIT_INTEGRATION_STEP_ERROR],
    },
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP_MULTIPLICITY_ERROR]: {
        id: TranslationKey.WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP_MULTIPLICITY_ERROR,
        defaultMessage: ru[TranslationKey.WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP_MULTIPLICITY_ERROR],
    },
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_INTEGRATION_STEP_TOOLTIP]: {
        id: TranslationKey.WORKSPACE_MEMORY_LIMIT_INTEGRATION_STEP_TOOLTIP,
        defaultMessage: ru[TranslationKey.WORKSPACE_MEMORY_LIMIT_INTEGRATION_STEP_TOOLTIP],
    },
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_MORE_THAN_ZERO]: {
        id: TranslationKey.WORKSPACE_MEMORY_LIMIT_MORE_THAN_ZERO,
        defaultMessage: ru[TranslationKey.WORKSPACE_MEMORY_LIMIT_MORE_THAN_ZERO],
    },
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP_ERROR]: {
        id: TranslationKey.WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP_ERROR,
        defaultMessage: ru[TranslationKey.WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP_ERROR],
    },
    [TranslationKey.WORKSPACE_PROJECT_INT_VALUE_ERROR]: {
        id: TranslationKey.WORKSPACE_PROJECT_INT_VALUE_ERROR,
        defaultMessage: ru[TranslationKey.WORKSPACE_PROJECT_INT_VALUE_ERROR],
    },
    [TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_TITLE]: {
        id: TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_TITLE,
        defaultMessage: ru[TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_TITLE],
    },
    [TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_TASKNAME]: {
        id: TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_TASKNAME,
        defaultMessage: ru[TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_TASKNAME],
    },
    [TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_HOST]: {
        id: TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_HOST,
        defaultMessage: ru[TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_HOST],
    },
    [TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_HOST_NOT_VALID]: {
        id: TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_HOST_NOT_VALID,
        defaultMessage: ru[TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_HOST_NOT_VALID],
    },
    [TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_PORT]: {
        id: TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_PORT,
        defaultMessage: ru[TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_PORT],
    },
    [TranslationKey.TOO_MANY_ERROR_MSG]: {
        id: TranslationKey.TOO_MANY_ERROR_MSG,
        defaultMessage: ru[TranslationKey.TOO_MANY_ERROR_MSG],
    },
    [TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_PORT_VALUE]: {
        id: TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_PORT_VALUE,
        defaultMessage: ru[TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_PORT_VALUE],
    },
});
