import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.PAGE_ENGINEER_TITLE]: {
        id: TranslationKey.PAGE_ENGINEER_TITLE,
        defaultMessage: ru[TranslationKey.PAGE_ENGINEER_TITLE],
    },
});
