import { FC, memo } from 'react';

import { IFileManagerFooter } from './FileManagerTypes';
import { SFileManagerFooter } from './SFileManager';

export const FileManagerFooter: FC<IFileManagerFooter> = memo(({ translations, elements, selectedElements }) => (
    <SFileManagerFooter>
        {translations && translations['elements'] && (
            <span>
                {translations['elements']}:&nbsp;{elements.length}
            </span>
        )}
        {selectedElements > 0 && (
            <span>
                {translations?.selected}:&nbsp;{selectedElements}
            </span>
        )}
    </SFileManagerFooter>
));
