import { FC } from 'react';

import styled from 'styled-components';

import { uiColors } from '../../config/config';
import { hexToRGB } from '../../config/helpers';
import { Loader } from '../Loader/Loader';

const SLoaderWrapper = styled.div`
    background: ${hexToRGB(uiColors.darkBlue, '0.1')};
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(4px);
    z-index: 10;

    img {
        max-height: 72px;
        max-width: 72px;
    }
`;

export const LoaderFullscreen: FC = () => {
    return (
        <SLoaderWrapper>
            <Loader />
        </SLoaderWrapper>
    );
};
