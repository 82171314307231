import { PayloadAction } from '@reduxjs/toolkit';

import { ClickTypes, IWorkspaceState, TClickType } from '@repeat/models';

export const clickReducers = {
    addTypeClick: (state: IWorkspaceState, { payload }: PayloadAction<TClickType>) => ({
        ...state,
        click: payload,
    }),
    resetTypeClick: (state: IWorkspaceState) => ({
        ...state,
        click: ClickTypes.DEFAULT_CLICK,
    }),
};
