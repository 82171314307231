import { jsonrepair } from 'jsonrepair';
import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { clearInterval, setInterval } from 'worker-timers';

import { ApplicationActions, refreshUserToken } from '@repeat/common-slices';
import { ModalTypes, TModal, TState } from '@repeat/models';
import { WebSocketOptions, WebSocketWrapper } from '@repeat/services';
import { workspaceActions } from '@repeat/store';

const livePermissionsMiddleware: Middleware = (
    store: MiddlewareAPI<ThunkDispatch<unknown, unknown, AnyAction>, any>
) => {
    let socket: WebSocket;

    return (next) => (action) => {
        if (workspaceActions.startLivePermissionsConnecting.match(action)) {
            let interval: ReturnType<typeof setInterval> | null = null;
            const startPingOptions: WebSocketOptions = {
                onOpen: (socket: WebSocket) => {
                    store.dispatch(workspaceActions.connectionLivePermissionsEstablished());
                    const projectId = localStorage.getItem('projectId');
                    socket.send(JSON.stringify({ projectId }));
                    interval = setInterval(() => {
                        const location = window && window.location.pathname;
                        if (location !== '/') {
                            return socket.close();
                        }
                        return socket.send('IsInUse');
                    }, 500);
                },
                onMessage: (event: MessageEvent) => {
                    const data = JSON.parse(jsonrepair(event.data));
                    if (data && data.type === 'permissions') {
                        const permissions = data.permissions;
                        store.dispatch(
                            workspaceActions.updateLivePermissions({
                                ...permissions,
                            })
                        );
                    }

                    if (data && data.type === 'error') {
                        if (data.code === '401') {
                            store.dispatch(refreshUserToken());
                        }
                        store.dispatch(workspaceActions.connectionLivePermissionsLost());
                        store.dispatch(workspaceActions.startLivePermissionsConnecting());
                        return;
                    }
                },
                onClose: (event, socket: WebSocket) => {
                    store.dispatch(workspaceActions.connectionLivePermissionsLost());
                    const { modals } = store.getState().app;
                    const isModalExist = modals.find(
                        (modal: TModal) => modal.type === ModalTypes.MODAL_SESSION_CONNECT
                    );
                    const location = window && window.location.pathname;

                    const {
                        workspace: { livePermissions },
                    } = store.getState() as TState;
                    if (!livePermissions.isEstablishingConnection) {
                        store.dispatch(workspaceActions.connectionLivePermissionsLost());
                        store.dispatch(workspaceActions.connectionLivePermissionsDisconnect());

                        if (location === '/' && !isModalExist) {
                            store.dispatch(
                                ApplicationActions.showModal({
                                    modal: {
                                        type: ModalTypes.MODAL_SESSION_CONNECT,
                                        data: { message: event.type },
                                    },
                                })
                            );
                        }
                    }
                    if (interval !== null) {
                        clearInterval(interval);
                        socket.close();
                    }
                },
                onError: (e, socket: WebSocket) => {
                    store.dispatch(workspaceActions.connectionLivePermissionsLost());
                    const { modals } = store.getState().app;
                    const isModalExist = modals.find(
                        (modal: TModal) => modal.type === ModalTypes.MODAL_SESSION_CONNECT
                    );
                    const location = window && window.location.pathname;

                    if (location === '/' && !isModalExist) {
                        store.dispatch(
                            ApplicationActions.showModal({
                                modal: {
                                    type: ModalTypes.MODAL_SESSION_CONNECT,
                                    data: { message: e.type },
                                },
                            })
                        );
                    }

                    if (interval !== null) {
                        clearInterval(interval);
                        socket.close();
                    }
                },
            };
            const socketWrapper = new WebSocketWrapper('/api/v1/ws/sessions', startPingOptions);
            socket = socketWrapper.connect();

            return next(action);
        }

        if (workspaceActions.sendMessageAboutNewlyPermissions.match(action)) {
            const newlyModules = action.payload;
            socket.send(JSON.stringify({ action: 'add', modules: newlyModules }));

            return next(action);
        }

        if (workspaceActions.sendMessageAboutDeactivatedPermissions.match(action)) {
            const deactivatedModules = action.payload;
            socket.send(JSON.stringify({ action: 'delete', modules: deactivatedModules }));

            return next(action);
        }

        next(action);
    };
};

export default livePermissionsMiddleware;
