import React, { FC, useRef } from 'react';

import { SElementsWrapper } from './StyledElements';

export { Element } from './Element';

export const ElementsWrapper: FC<{ children: React.ReactNode }> = ({ children, ...rest }) => {
    const ElementsWrapperHandler = (event: React.SyntheticEvent) => {
        event.nativeEvent.stopImmediatePropagation();
        return false;
    };
    const ref = useRef<HTMLDivElement>(null);

    return (
        <SElementsWrapper ref={ref} onContextMenu={ElementsWrapperHandler} {...rest}>
            {children}
        </SElementsWrapper>
    );
};
