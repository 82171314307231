import styled from 'styled-components';

import { Button, pxToRem, uiColors } from '@repeat/ui-kit';

export const SWorkspaceProjectName = styled.div<{ noSave?: boolean; isGroup?: boolean }>`
    font-size: ${pxToRem(14)};
    display: inline-flex;
    padding: ${pxToRem(5)} ${pxToRem(8)};
    max-height: ${pxToRem(26)};
    white-space: nowrap;

    ${({ noSave }) =>
        noSave &&
        `
        white-space: normal;
    `}

    ${({ noSave, isGroup }) =>
        !noSave &&
        `
        max-width: ${pxToRem(300)};
        overflow: hidden;
        text-overflow: ellipsis;
    `}
`;

export const SProjectName = styled.span`
    color: var(--ui-link-secondary);
    cursor: pointer;

    &:hover {
        color: var(--ui-link-secondary-hover);
    }
`;

export const SSaveLoaderWrapper = styled.div`
    height: ${pxToRem(26)};
    width: ${pxToRem(26)};

    #save-button-loader {
        height: ${pxToRem(24)};
        width: ${pxToRem(24)};

        &::after {
            width: ${pxToRem(14)};
            height: ${pxToRem(14)};
        }
    }
`;
export const SMenuBreadCrumbs = styled.div``;

export const SMenuDropdown = styled.ul<{ isVisible: boolean }>`
    position: absolute;
    top: ${pxToRem(30)};
    left: ${pxToRem(36)};
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
    padding: ${pxToRem(5)};
    background-color: ${uiColors.white};
    box-shadow: 0 0 1px 1px rgb(0 0 0 / 8%);
    font-size: ${pxToRem(14)};
`;
export const SMenuBreadCrumbsBlock = styled.div`
    display: flex;
`;

export const SButton = styled(Button)<{ isVisible: boolean }>`
    margin-right: ${pxToRem(8)};
    height: ${pxToRem(26)};
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`;

export const SListItem = styled.li`
    padding: ${pxToRem(4)};
    max-width: ${pxToRem(300)};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
