import styled from 'styled-components';

import { border, pxToRem, radius, uiColors, zIndex } from '../../config/config';
import { hexToRGB } from '../../config/helpers';
import { verticalAlign } from '../../config/mixin';

export const SDropdownTrigger = styled.button`
    background: transparent;
    align-items: center;
    cursor: pointer;
    display: flex;
    border: none;
    padding: 0;
`;

export const SDropdownMenuItem = styled.li`
    border-bottom: ${border.default};
    display: flex;
    font-size: ${pxToRem(14)};
    justify-content: flex-start;
    color: var(--ui-text);
    white-space: nowrap;

    &:last-child {
        border-bottom: 0;
    }

    svg {
        fill: var(--ui-icons);
    }

    &:hover {
        background: ${hexToRGB(uiColors.grey, '0.4')};
    }

    a,
    button {
        ${verticalAlign()};
        flex-direction: row;
        justify-content: flex-start;
        padding: ${pxToRem(8)} ${pxToRem(16)};
    }

    a {
        color: inherit;
        display: flex;
        text-decoration: none;

        span {
            margin-left: ${pxToRem(8)};
        }

        &:hover {
            color: inherit;
        }
    }
`;

export const SDropdownMenu = styled.menu<{ isOpen: boolean }>`
    background: var(--ui-sidebar);
    border: ${border.default};
    border-radius: ${radius.default};
    min-width: ${pxToRem(100)};
    position: absolute;
    right: 0;
    margin: 0;
    padding: 0;
    top: calc(100% + ${pxToRem(4)});
    width: auto;
    visibility: hidden;
    z-index: ${zIndex.below};

    ${({ isOpen }) =>
        isOpen &&
        `
        z-index: ${zIndex.top};
        visibility: visible;
    `};

    button {
        width: 100%;
    }
`;

export const SDropdownContainer = styled.div`
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    padding: 0;
    position: relative;
    width: auto;
`;
