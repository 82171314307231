import { FC, useCallback, useEffect, memo } from 'react';

import { EFileManagerItemType, IFileManagerConfirmDelete, TFileManagerElement } from './FileManagerTypes';
import { SFileManagerConfirmDelete } from './SFileManager';

import { Button, EVariantButton } from '../Button/Button';
import { Icon } from '../Icon/Icon';

export const FileManagerConfirmDelete: FC<IFileManagerConfirmDelete> = memo(
    ({ open, removeElements, translations, onSuccess, onDecline, onHandleShow }) => {
        const handleShowDialog = useCallback((isOpen: boolean) => {
            const target = document.querySelector('#delete-confirm-dialog') as HTMLDialogElement;

            if (isOpen) {
                target.showModal();
            } else {
                target.close();
            }
        }, []);

        useEffect(() => {
            handleShowDialog(open);
        }, [open]);

        return (
            <SFileManagerConfirmDelete id={'delete-confirm-dialog'}>
                <header>
                    <h5>{translations?.remove}</h5>
                </header>
                <section>
                    <p>{translations?.removeDialog}:</p>
                    <ul>
                        {removeElements.map((element: TFileManagerElement) => (
                            <li key={`element-to-delete-${element.uuid}`}>
                                <Icon name={element.type === EFileManagerItemType.FILE ? 'fileNoName' : 'folder'} />
                                <span>{element.name}</span>
                            </li>
                        ))}
                    </ul>
                </section>
                <footer>
                    <Button
                        onClick={() => {
                            onDecline();
                            onHandleShow();
                        }}
                        variant={EVariantButton.SECONDARY}
                    >
                        {translations?.cancel}
                    </Button>
                    <Button
                        onClick={() => {
                            onSuccess();
                            onHandleShow();
                        }}
                        variant={EVariantButton.PRIMARY}
                    >
                        {translations?.remove}
                    </Button>
                </footer>
            </SFileManagerConfirmDelete>
        );
    }
);
