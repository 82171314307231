import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';

import {
    EChartItemType,
    IChartItemParameter,
    IChartItemType,
    ITransferChartData,
    ModelStatuses,
    TChartData,
    TrendsStatuses,
} from '@repeat/models';
import { broadCastService } from '@repeat/services';
import { TranslationKey } from '@repeat/translations';
import { LoaderRepeat, pxToRem } from '@repeat/ui-kit';

import { ChartPlot } from '../../features/Visualization/Charts/ChartPlot';
import { messages } from '../../features/Visualization/translation';

const STrendWrapper = styled.div`
    padding: 0 24px;

    * {
        color: var(--ui-text) !important;
    }

    .uplot {
        height: 100vh;
    }
`;

const STrendWrapperText = styled.div`
    padding: ${pxToRem(32)} 0;
    text-align: center;
`;

const STrendDetailsText = styled.div`
    padding: ${pxToRem(24)};

    dt {
        font-weight: bold;
        margin: ${pxToRem(8)} 0;
    }
`;

const STrendWrapperBox = styled.div`
    background: var(--ui-background);
    height: 100vh;
    width: 100vw;

    h5 {
        display: flex;
        justify-content: center;
        padding: ${pxToRem(24)} ${pxToRem(24)} 0;
        width: 100%;
    }
`;

const initialData: ITransferChartData = {
    data: null,
    dataMap: null,
    status: TrendsStatuses.DISCONNECTED,
    modelStatus: null,
    hasModelNames: false,
    chart: null,
    index: null,
};

export const ChartPage = () => {
    const intl = useIntl();

    const channel = useRef<BroadcastChannel | null>(null);

    const [isLoading, setIsLoading] = useState(true);
    const [transferData, setTransferData] = useState<ITransferChartData>(initialData);
    const { index, hasModelNames, status, chart, data, modelStatus } = transferData || {};
    const hasData = Boolean(data !== null);
    const isNeedToRun =
        hasModelNames && ![TrendsStatuses.CONNECTING, TrendsStatuses.CONNECTED].includes(status) && !hasData;
    const { id } = useParams();

    const parameterId = parseInt(id || '0');

    const handleTransferData = (data: ITransferChartData) => {
        if ('chart' in data) {
            setIsLoading(false);
            setTransferData(data);
        }
    };

    const handleGetTransferData = () => {
        channel.current = broadCastService(`trends${parameterId}`);
        if (transferData.data === null || transferData.dataMap === null) {
            channel.current.postMessage({ isNeedUpdate: true });
        }
        channel.current.addEventListener('message', (event) => {
            handleTransferData(event.data);
        });
    };

    useEffect(() => {
        handleGetTransferData();

        document.body.style.height = '100vh';
        document.body.style.background = 'var(--ui-background)';
        document.body.style.overflowX = 'hidden';

        return () => {
            channel.current && channel.current.removeEventListener('message', () => handleTransferData);
        };
    }, []);

    const chartTypeName = (type: IChartItemType | null) => {
        if (type === EChartItemType.YFromX) {
            return intl.formatMessage(messages[TranslationKey.WORKSPACE_CHART_TYPE_Y_X]);
        }
        return intl.formatMessage(messages[TranslationKey.WORKSPACE_CHART_TYPE_Y_T]);
    };

    if (!transferData.chart) {
        return null;
    }

    if (isLoading || chart?.isEdit) {
        return <LoaderRepeat />;
    }

    if (status === TrendsStatuses.DISCONNECTED && !hasData && !isNeedToRun) {
        return (
            <STrendWrapperText>
                {intl.formatMessage(messages[TranslationKey.WORKSPACE_CHART_SETUP_PARAMETER])}
            </STrendWrapperText>
        );
    }
    if (status === TrendsStatuses.DISCONNECTED && !hasData && isNeedToRun) {
        return (
            <>
                <STrendWrapperText>
                    {intl.formatMessage(messages[TranslationKey.WORKSPACE_WARNING_CHART_START])}
                </STrendWrapperText>
                <STrendDetailsText>
                    <dl>
                        <dt>{intl.formatMessage(messages[TranslationKey.WORKSPACE_PARAMETERS])}:</dt>
                        {chart?.YParameters &&
                            chart?.YParameters?.length > 0 &&
                            chart?.YParameters.map((parameter, parameterIndex) => {
                                return (
                                    <dd key={`parameter-y-${parameterIndex}`}>
                                        {parameterIndex + 1}. {parameter.elementTitle} / {parameter.parameterTitle}
                                    </dd>
                                );
                            })}
                        <dt>{intl.formatMessage(messages[TranslationKey.WORKSPACE_PARAMETERS_X])}:</dt>
                        {chart?.XParameters && (
                            <dd key={`parameter-y-${index}`}>
                                {chart?.XParameters?.elementTitle} / {chart?.XParameters?.parameterTitle}
                            </dd>
                        )}
                    </dl>
                </STrendDetailsText>
            </>
        );
    }

    if (modelStatus && [ModelStatuses.RUN, ModelStatuses.DATA].includes(modelStatus) && !data?.length) {
        return <LoaderRepeat />;
    }

    return (
        <STrendWrapperBox>
            {chart?.YParameters !== null && chart?.YParameters?.length && (
                <strong style={{ margin: '16px', display: 'inline-block' }}>
                    <span>{parameterId + 1}.&nbsp;</span>
                    <span>{intl.formatMessage(messages[TranslationKey.CHART])}&nbsp;</span>
                    <span>{chartTypeName(chart?.type)}.&nbsp;</span>
                    <span>
                        {intl.formatMessage(messages[TranslationKey.PARAMETERS])}:&nbsp;{chart?.YParameters?.length}
                        &nbsp;
                    </span>
                </strong>
            )}
            <STrendWrapper>
                <ChartPlot
                    uuid={chart?.uuid || ''}
                    type={chart?.type || null}
                    data={data as TChartData}
                    xParameters={chart?.XParameters as IChartItemParameter}
                    yParameters={chart?.YParameters as IChartItemParameter[]}
                />
            </STrendWrapper>
        </STrendWrapperBox>
    );
};
