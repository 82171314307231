import { FC, memo } from 'react';
import { useIntl } from 'react-intl';
import { useReactFlow } from 'reactflow';

import { TranslationKey } from '@repeat/translations';

import { ApplicationActions } from '@repeat/common-slices';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { TSchemaGroup, TSchemaNode, WorkspaceModes } from '@repeat/models';
import { workspaceSelectors } from '@repeat/store';
import { ModalTypes, TUserBlockSaveModal } from 'libs/models/src/lib/modal';
import { SContextMenu, SContextMenuButton } from './SContextMenu';
import { messages } from './translation';

export interface IContextMenu {
    id: string;
    top: number;
    left: number;
    right?: number;
    bottom?: number;
    onDelete?: (id: string) => void;
    onGroupClick?: () => void;
    isGroup?: boolean;
    ref?: null | HTMLDivElement | any;
    isBlockSavingAvailable?: boolean;
    element: TSchemaNode;
    groups: TSchemaGroup[];
}

const ContextMenu: FC<IContextMenu> = memo(
    ({
        id,
        top,
        left,
        right,
        bottom,
        onDelete,
        onGroupClick,
        isGroup,
        isBlockSavingAvailable,
        element,
        groups,
        ...props
    }) => {
        const { formatMessage } = useIntl();
        const dispatch = useAppDispatch();

        const mode = useAppSelector(workspaceSelectors.workspaceMode);

        const { getNode, setNodes, addNodes, setEdges } = useReactFlow();

        const handleSaveAsBlock = () => {
            const modal: TUserBlockSaveModal = {
                type: ModalTypes.USER_BLOCK_SAVE,
                data: { groups, element },
            };
            dispatch(ApplicationActions.showModal({ modal }));
        };

        return (
            <SContextMenu>
                <div style={{ top, left, right, bottom }} className='context-menu' {...props}>
                    <SContextMenuButton onClick={() => onDelete && onDelete(id)}>
                        {formatMessage(messages[TranslationKey.DELETE])}
                    </SContextMenuButton>
                    {isGroup && (
                        <SContextMenuButton onClick={() => onGroupClick && onGroupClick()}>
                            {'Создать группу'}
                        </SContextMenuButton>
                    )}
                    {isBlockSavingAvailable && mode === WorkspaceModes.MAIN && (
                        <SContextMenuButton onClick={handleSaveAsBlock}>{'Сохранить как блок'}</SContextMenuButton>
                    )}
                </div>
            </SContextMenu>
        );
    }
);

export default ContextMenu;
