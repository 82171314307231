import { breakPointsValues } from '../../config/config';

export const getWindowItemWidthCoefficient = (currentWidth?: number) => {
    const width = currentWidth || window.innerWidth;
    let result = 3;
    if (width <= breakPointsValues.medium) {
        result = 1;
    } else if (width <= breakPointsValues.large) {
        result = 2;
    } else if (width <= breakPointsValues.extraLarge) {
        result = 3;
    }
    return result;
};
