import { FC } from 'react';
import { useIntl } from 'react-intl';

import styled from 'styled-components';

import { ApplicationActions } from '@repeat/common-slices';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { ModalTypes, TSchemaNode } from '@repeat/models';
import { setElementConfigurationCustomizable } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { Button, Modal, pxToRem } from '@repeat/ui-kit';

import { blockOptionsMessages as messages } from './translation';

import { ElementConfiguration } from '../CurrentItemOptions/ElementConfiguration/ElementConfiguration';

const STitle = styled.span`
    font-size: ${pxToRem(16)};
`;
const SSubTitle = styled.span`
    font-size: ${pxToRem(18)};
`;

const ModalTitle: FC<{ title: string; subtitle: string }> = ({ title, subtitle }) => {
    return (
        <div>
            <STitle>{title}</STitle>
            <br />
            <SSubTitle>{subtitle}</SSubTitle>
        </div>
    );
};

export const ElementConfigurationModal = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const selectedItem = useAppSelector((state) => {
        const selectedItems: TSchemaNode[] = state.workspace.schema.selectedItems.elements;
        return selectedItems[0];
    });

    const {
        schemaItems: { elements },
    } = useAppSelector((state) => state.workspace.schema);

    const selectedElement = elements.find((el: TSchemaNode) => el?.id === selectedItem.id);

    const handleClose = () => {
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.ELEMENT_CONFIGURATION }));
    };
    const handleConfigurationCustomizable = () => {
        if (selectedElement) {
            dispatch(setElementConfigurationCustomizable(selectedElement.id));
        }
    };

    const FooterButtons = () => (
        <>
            <Button variant='secondary' onClick={handleClose}>
                {intl.formatMessage(messages[TranslationKey.CANCEL])}
            </Button>
            <Button onClick={handleConfigurationCustomizable}>
                {intl.formatMessage(messages[TranslationKey.ELEMENT_CONFIGURATION_CUSTOMIZABLE_SELECT])}
            </Button>
        </>
    );

    return (
        <Modal
            id={ModalTypes.ELEMENT_CONFIGURATION}
            title={
                <ModalTitle
                    title={intl.formatMessage(messages[TranslationKey.ELEMENT_CONFIGURATION])}
                    subtitle={selectedItem?.data.name}
                />
            }
            isOpen={true}
            hasOverlay={true}
            onClose={handleClose}
            type='default'
            size='medium'
            footer={<FooterButtons />}
        >
            <ElementConfiguration selectedElement={selectedElement} onClose={handleClose} />
        </Modal>
    );
};
