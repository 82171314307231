import styled from 'styled-components';

import { pxToRem } from '@repeat/ui-kit';

export const SSubtitle = styled.div`
    font-size: ${pxToRem(14)};
    padding: ${pxToRem(8)} ${pxToRem(16)};
`;

export const SWrapper = styled.div`
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
    gap: ${pxToRem(20)};
    height: 100%;
`;

export const SLibraryItemsBlock = styled.div``;
