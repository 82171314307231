import { FC } from 'react';

import styled from 'styled-components';

import { ElemProps } from '@repeat/models';
import { pxToRem, uiColors } from '@repeat/ui-kit';

const SPropertyItem = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${pxToRem(16)};
    margin-bottom: ${pxToRem(4)};
`;
const SPropertyValue = styled.span`
    font-size: ${pxToRem(12)};
`;
const SPropertyDescription = styled(SPropertyValue)`
    color: ${uiColors.darkGrey};
`;

interface ElementConfigurationContentProps {
    elementProperties: ElemProps[];
}
export const ElementConfigurationContent: FC<ElementConfigurationContentProps> = ({ elementProperties }) => {
    const elementPropertiesResult = elementProperties.filter((prop) => !prop.editable);
    return (
        <>
            {elementPropertiesResult.map(
                (prop) =>
                    !prop.isStatic && (
                        <SPropertyItem key={prop.name}>
                            <SPropertyDescription>{prop.description}</SPropertyDescription>
                            <SPropertyValue>{prop.value}</SPropertyValue>
                        </SPropertyItem>
                    )
            )}
        </>
    );
};
