import DOMPurify from 'dompurify';

export const sanitizeHTML = (content: string): { __html: string } => {
    const defaultOptions = {
        ALLOWED_TAGS: ['div', 'p', 'ol', 'ul', 'li', 'br', 'a', 'b', 'video', 'img', 'sup', 'sub'],
        ALLOWED_ATTR: ['type', 'href', 'src', 'target', 'rel'],
    };
    return { __html: DOMPurify.sanitize(content, { ...defaultOptions }) };
};

export const hexToRGB = (hex: string, alpha?: string) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    return `rgb(${r}, ${g}, ${b})`;
};
