import React from 'react';
import { useIntl } from 'react-intl';

import { messages } from '@components/ReactFlow/translation';

import { TranslationKey } from '@repeat/translations';
import { uiRGBColors } from '@repeat/ui-kit';

import { SMiniMap } from './SReactFlowMiniMap';

const nodeColor = (node: any) => {
    switch (node.type) {
        case 'input':
            return `${uiRGBColors.green}`;
        case 'output':
            return `${uiRGBColors.blue}`;
        default:
            return `${uiRGBColors.grey}`;
    }
};

export const ReactFlowMiniMap = () => {
    const intl = useIntl();

    return (
        <SMiniMap
            nodeColor={nodeColor}
            nodeStrokeWidth={3}
            zoomable
            pannable
            ariaLabel={intl.formatMessage(messages[TranslationKey.MINI_MAP])}
        />
    );
};
