import { useRef } from 'react';

export const useProjectExport = () => {
    const exportLinkRef = useRef<HTMLAnchorElement | null>(null);

    const download = (url: string, filename: string) => {
        const link = exportLinkRef.current;
        if (link !== null) {
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.click();
        }
    };

    return {
        exportLinkRef,
        download,
    };
};
