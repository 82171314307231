import { FC, useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

import { STrendSetupMessage, STrendSetupWrapper } from '@components/Chart/STrendChartItemComponents';
import { messages } from '@components/Chart/translation';
import { EChartItemType, IChartsDataMapItem } from 'libs/models/src/lib/charts';

import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import {
    IChartItemParameter,
    IChartItemParameters,
    IChartItemType,
    ITransferChartData,
    ModelStatuses,
    TChartData,
    TChartsData,
    TrendsStatuses,
} from '@repeat/models';
import { broadCastService } from '@repeat/services';
import { workspaceActions } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { LoaderRepeat } from '@repeat/ui-kit';

import { AutocompleteParametersForm } from './AutoCompleteParametersForm/AutocompleteParametersForm';
import { ChartPlot } from './ChartPlot';

interface IChartsItem {
    XParameters: IChartItemParameter | null;
    YParameters: IChartItemParameter[] | null;
    index: number;
    isNeedUpdate: boolean;
}

export const ChartsItem: FC<IChartsItem> = ({ isNeedUpdate, XParameters, YParameters, index }) => {
    const intl = useIntl();

    const channel = useRef<BroadcastChannel | null>(null);

    const dispatch = useAppDispatch();
    const {
        charts: { [index]: chart },
        dataMap,
        data,
        commonStatus: status,
    } = useAppSelector((state) => state.workspace.graphs);
    const { modelStatus } = useAppSelector((state) => state.workspace.modelControl);

    const extractChartData = useCallback(
        (type: IChartItemType | null, data: TChartsData[] | null, dataMapItem: IChartsDataMapItem | null) => {
            if (!data || !dataMapItem) {
                return null;
            }

            if (dataMapItem.index < 0) {
                return null;
            }

            const chartData = data[dataMapItem.index];
            if (!chartData) {
                return null;
            }

            if (type === EChartItemType.YFromX) {
                return chartData.slice(1);
            }

            return chartData;
        },
        []
    );

    const chartDataMapItem = dataMap[chart.uuid] || null;
    const chartData = extractChartData(chart.type, data, chartDataMapItem);

    const isEdit = chart.isEdit;
    const isSetup = chart.YParameters && chart.YParameters?.length > 0;
    const hasData = chartData && chartData.length > 0;
    const hasModelNames = Boolean(chart?.modelNames?.length > 0);
    const isNeedToRun = isSetup && ![TrendsStatuses.CONNECTING, TrendsStatuses.CONNECTED].includes(status) && !hasData;
    const isConnecting =
        isSetup && (status === TrendsStatuses.CONNECTING || (modelStatus === ModelStatuses.RUN && !hasData));

    const onSetParameters = (
        index: number,
        uuid: string,
        YParameters: IChartItemParameter[],
        XParameters: IChartItemParameter
    ) => {
        dispatch(
            workspaceActions.addParametersChart({ index, uuid, XParameters, YParameters } as IChartItemParameters)
        );
    };

    useEffect(() => {
        channel.current = broadCastService(`trends${index}`);
        const data: ITransferChartData = {
            index,
            chart,
            status,
            modelStatus,
            data: chartData as TChartData,
            dataMap,
            hasModelNames,
        };
        channel.current.postMessage(data);
    }, [
        chart,
        data,
        dataMap,
        XParameters,
        YParameters,
        modelStatus,
        status,
        index,
        isNeedUpdate,
        chartData,
        chartDataMapItem,
        hasModelNames,
    ]);

    useEffect(() => {
        channel.current &&
            channel.current.addEventListener('message', (event) => {
                if (event.data.isNeedUpdate) {
                    const data: ITransferChartData = {
                        index,
                        chart,
                        status,
                        modelStatus,
                        data: chartData as TChartData,
                        dataMap,
                        hasModelNames,
                    };
                    channel.current && channel.current.postMessage(data);
                }
            });
    }, []);

    if (isEdit || !isSetup || (isSetup && !isConnecting && !hasData)) {
        return (
            <STrendSetupWrapper>
                <AutocompleteParametersForm index={index} uuid={chart.uuid} onSetParameters={onSetParameters} />
                {isNeedToRun && (
                    <STrendSetupMessage>
                        {intl.formatMessage(messages[TranslationKey.WORKSPACE_WARNING_CHART_START])}
                    </STrendSetupMessage>
                )}
            </STrendSetupWrapper>
        );
    }

    if (isConnecting) {
        return <LoaderRepeat />;
    }

    return (
        <ChartPlot
            uuid={chart.uuid}
            type={chart.type}
            data={chartData as TChartData}
            xParameters={XParameters as IChartItemParameter}
            yParameters={YParameters as IChartItemParameter[]}
        />
    );
};
