import { Action } from 'redux';

/* eslint-disable @typescript-eslint/no-explicit-any */

export enum PreloaderTypes {
    IS_LOADING = 'IS_LOADING',
}

export interface PreloaderAction extends Action<PreloaderTypes> {
    type: PreloaderTypes;
    payload: any;
}
