import { ModalOptionsAction, ModalOptionsTypes } from '../actions/modalOptionTypes';

const initialState = 'default';

export function modalReducer(state = initialState, action: ModalOptionsAction) {
    const { type, payload } = action;
    switch (type) {
        case ModalOptionsTypes.SAVE:
            return payload;

        case ModalOptionsTypes.LOAD:
            return payload;

        case ModalOptionsTypes.PRELOADER:
            return payload;

        case ModalOptionsTypes.CHART:
            return payload;

        case ModalOptionsTypes.DEFAULT_MODAL:
            return initialState;

        default:
            return state;
    }
}
export default modalReducer;
