import { FC, forwardRef, MouseEvent, ReactNode } from 'react';

import styled from 'styled-components';

import { pxToRem, radius, uiRGBColors, zIndex } from '../../config/config';
import { IIconProps } from '../../config/types';
import { Icon } from '../Icon/Icon';

export type TSizes = 'big' | 'medium' | 'small';

interface IStyledButton {
    noHover: boolean;
    size?: TSizes;
}

const sizes = {
    small: `${pxToRem(20)}`,
    medium: `${pxToRem(24)}`,
    big: `${pxToRem(32)}`,
};

const SButton = styled.button<IStyledButton>`
    align-items: center;
    background-color: transparent;
    border: 0;
    border-radius: ${radius.circle};
    box-shadow: none;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    display: flex;
    justify-content: center;
    margin: 0;
    position: relative;
    padding: 0;
    width: auto;

    ${({ noHover }) =>
        !noHover &&
        `
            &:hover::before {
                background-color: rgba(${uiRGBColors.whiteSecond}, 0.8);
                border-radius: ${radius.circle};
                content: "";
                display: block;
                height: 100%;
                position: absolute;
                width: 100%;
                z-index: ${zIndex.default};
            }
        `};

    &[disabled] {
        opacity: 0.5;
    }

    span {
        display: flex;
        text-align: left;
        font-size: ${pxToRem(14)};
        margin-left: ${pxToRem(8)};

        &:empty {
            display: none;
        }
    }
`;

interface IIconButton extends IIconProps {
    onClick?: (event: MouseEvent) => void;
    onDoubleClick?: (event: MouseEvent) => void;
    noHover?: boolean;
    size?: TSizes;
    disabled?: boolean;
    text?: string;
    style?: any;
    type?: string;
    title?: string;
    ref?: any;
    children?: ReactNode;
}

export const IconButton: FC<IIconButton> = forwardRef(
    (
        {
            children,
            size = 'small',
            type = 'button',
            text,
            onClick,
            name,
            fill,
            noHover = false,
            disabled = false,
            ...rest
        },
        ref
    ) => {
        return (
            <SButton ref={ref} size={size} noHover={noHover} disabled={disabled} onClick={onClick && onClick} {...rest}>
                {name && <Icon name={name} fill={fill} />}
                <span>
                    {text && text}
                    {children && children}
                </span>
            </SButton>
        );
    }
);
