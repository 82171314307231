import { EUserRoles, User } from '@repeat/models';

export const useUserRole = (user: User | null) => {
    const roles = user?.roles ?? [];

    const demoManageRoles = [EUserRoles.ADMIN];

    const isAdmin = roles && roles.includes(EUserRoles.ADMIN);

    return {
        isAdmin,
        demoManageRoles,
    };
};
