import { Action } from 'redux';

import { IElement } from '@repeat/models';

/* eslint-disable @typescript-eslint/no-explicit-any */

export enum ElementsTypes {
    ADD_ELEMENT = 'ADD_ELEMENT',
    DELETE_ELEMENT = 'DELETE_ELEMENT',
    SAVE_ELEMENTS = 'SAVE_ELEMENTS',
    LOAD_ELEMENTS = 'LOAD_ELEMENTS',
    POSITION_ELEMENT = 'POSITION_ELEMENT',
    NAME_ELEMENT = 'NAME_ELEMENT',
    SIZE_ELEMENT = 'SIZE_ELEMENT',
    COLOR_ELEMENT = 'COLOR_ELEMENT',
    CLEANSTATE_ELEMENT = 'CLEANSTATE_ELEMENT',
    CLEAR_ELEMTOOL = 'CLEAR_ELEMTOOL',
    ANGLE_ELEMENT = 'ANGLE_ELEMENT',
    WARNING_ELEMENT = 'WARNING_ELEMENT',
    STATE_ELEMENT = 'STATE_ELEMENT',
    PROPS_ELEMENT = 'PROPS_ELEMENT',
    PARAMS_ELEMENT = 'PARAMS_ELEMENT',
    GET_PORT = 'GET_PORT',
    ADD_ELEMTOOL = 'ADD_ELEMTOOL',
    GET_INDICATOR = 'GET_INDICATOR',
    START_SIZE = 'START_SIZE',
    ADD_PORTS = 'ADD_PORTS',
    ADD_WIRE_TO_ELEMENT = 'ADD_WIRE_TO_ELEMENT',
    DELETE_LAST_ELEMENT = 'DELETE_LAST_ELEMENT',
    PARAMS_VALUES_ELEMENT = 'PARAMS_VALUES_ELEMENT',
    SET_INDICATOR = 'SET_INDICATOR',
}

export interface ElementsAction extends Action<ElementsTypes> {
    type: ElementsTypes;
    payload?: IElement | IElement[] | any;
}
