import React, { FC } from 'react';

import styled from 'styled-components';

import { uiRGBColors, layoutSize, pxToRem, radius } from '../../config/config';

const SInternalPageContent = styled.main`
    background-color: rgb(${uiRGBColors.white});
    border-radius: ${radius.default};
    max-width: calc(${pxToRem(1280)} - calc(${layoutSize.sidebar} + ${pxToRem(24)} * 2));
    margin: ${pxToRem(24)};
    padding: ${pxToRem(24)};
    width: 100%;

    form {
        max-width: 350px;
    }

    h4 {
        font-size: ${pxToRem(24)};
        margin-bottom: ${pxToRem(24)};
    }
`;

const SInternalPageSection = styled.section<{ sidebar: boolean }>`
    background-color: rgba(${uiRGBColors.whiteSecond});
    display: flex;
    min-height: 100vh;
    padding-top: ${layoutSize.header};

    ${SInternalPageContent} {
        max-width: ${pxToRem(1280)};
    }
`;

interface IInternalPage {
    sidebar: React.Component | React.ReactElement | React.ReactNode;
    children: React.ReactNode;
}

export const InternalPage: FC<IInternalPage> = ({ children, sidebar }) => {
    const withSidebar = sidebar !== null;
    return (
        <SInternalPageSection sidebar={withSidebar}>
            <>
                {sidebar}
                <SInternalPageContent>{children}</SInternalPageContent>
            </>
        </SInternalPageSection>
    );
};
