import { FC } from 'react';
import { IntlShape } from 'react-intl';

import { TranslationKey } from '@repeat/translations';

import { messages } from './translation';

export type TTourTranslationKeys =
    | TranslationKey.TOUR_NEXT
    | TranslationKey.TOUR_PREV
    | TranslationKey.TOUR_SKIP
    | TranslationKey.TOUR_FINISH
    | TranslationKey.TOUR_PROJECTS_LIST
    | TranslationKey.TOUR_PROJECTS_LIST_DESCRIPTION
    | TranslationKey.TOUR_PROJECTS_NEW
    | TranslationKey.TOUR_PROJECTS_NEW_DESCRIPTION
    | TranslationKey.TOUR_WORKSPACE_JOURNAL_AREA
    | TranslationKey.TOUR_WORKSPACE_JOURNAL_AREA_DESCRIPTION
    | TranslationKey.TOUR_WORKSPACE_JOURNAL_TAB
    | TranslationKey.TOUR_WORKSPACE_JOURNAL_TAB_DESCRIPTION
    | TranslationKey.TOUR_WORKSPACE_LIBRARY
    | TranslationKey.TOUR_WORKSPACE_LIBRARY_DESCRIPTION
    | TranslationKey.TOUR_WORKSPACE_LIBRARY_ITEM
    | TranslationKey.TOUR_WORKSPACE_LIBRARY_ITEM_DESCRIPTION
    | TranslationKey.TOUR_WORKSPACE_MODEL_CONTROL
    | TranslationKey.TOUR_WORKSPACE_MODEL_CONTROL_DESCRIPTION
    | TranslationKey.TOUR_WORKSPACE_MODEL_CONTROL_START
    | TranslationKey.TOUR_WORKSPACE_MODEL_CONTROL_START_DESCRIPTION
    | TranslationKey.TOUR_WORKSPACE_PROJECT_SAVE
    | TranslationKey.TOUR_WORKSPACE_PROJECT_SAVE_DESCRIPTION
    | TranslationKey.TOUR_WORKSPACE_PROJECT_TITLE
    | TranslationKey.TOUR_WORKSPACE_PROJECT_TITLE_DESCRIPTION
    | TranslationKey.TOUR_WORKSPACE_RIGHT_PANEL
    | TranslationKey.TOUR_WORKSPACE_RIGHT_PANEL_DESCRIPTION
    | TranslationKey.TOUR_WORKSPACE_VISUALIZATION_AREA
    | TranslationKey.TOUR_WORKSPACE_VISUALIZATION_AREA_DESCRIPTION
    | TranslationKey.TOUR_WORKSPACE_VISUALIZATION_TAB
    | TranslationKey.TOUR_WORKSPACE_VISUALIZATION_TAB_DESCRIPTION
    | TranslationKey.TOUR_WORKSPACE_VISUALIZATION_TRIGGER
    | TranslationKey.TOUR_WORKSPACE_VISUALIZATION_TRIGGER_DESCRIPTION;

export const SimpleTextWithIntl: FC<{ intl: IntlShape; translationKey: TTourTranslationKeys }> = ({
    intl,
    translationKey,
}) => {
    return <p>{intl ? intl.formatMessage(messages[translationKey]) : translationKey}</p>;
};
