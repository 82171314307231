import { FC } from 'react';

import { fileManagerCheckRule } from './FileManagerHelpers';
import { EFileManagerRule, EFIleManagerSort, IFileManagerPanel, TFileManagerTranslation } from './FileManagerTypes';
import { SFileManagerDivider, SFileManagerPanel } from './SFileManager';

import { uiColors } from '../../config/config';
import { EViewMode } from '../../hooks/useViewMode';
import { Dropdown } from '../Dropdown/Dropdown';
import { Icon } from '../Icon/Icon';
import { IconButton } from '../IconButton/IconButton';

export const FileManagerPanel: FC<IFileManagerPanel> = ({
    rules,
    translations,
    elements,
    viewMode,
    sortType,
    isOpenImport,
    selectedElements,
    onGetData,
    onChangeMode,
    onAddFolder,
    onRemove,
    onSelectSort,
    onChangePreviewMode,
    onOpenImport,
    onCreateFile,
    onMultiSelect,
}) => {
    const handleTranslate = (key: string): string => {
        if (key && translations && translations[key as keyof TFileManagerTranslation]) {
            return translations[key as keyof TFileManagerTranslation] as string;
        }
        return '';
    };

    return (
        <SFileManagerPanel
            onMouseUp={(event) => event.stopPropagation()}
            onMouseDown={(event) => event.stopPropagation()}
            onClick={(event) => event.stopPropagation()}
        >
            {fileManagerCheckRule(rules, EFileManagerRule.SELECT) && (
                <>
                    <IconButton
                        title={handleTranslate('selectAll')}
                        name={elements.length !== selectedElements.length ? 'checkboxMulti' : 'checkboxMultiEmpty'}
                        noHover
                        onClick={() => {
                            onMultiSelect && onMultiSelect();
                        }}
                    />
                    <SFileManagerDivider />
                </>
            )}
            <Dropdown clickOutside trigger={<Icon name={'sortDefault'} />}>
                <IconButton
                    text={handleTranslate('sortAsc')}
                    name={'sortASC'}
                    noHover
                    onClick={() => {
                        onSelectSort(EFIleManagerSort.ASC);
                    }}
                    disabled={sortType === EFIleManagerSort.ASC}
                />
                <IconButton
                    text={handleTranslate('sortDesc')}
                    name={'sortDESC'}
                    noHover
                    onClick={() => {
                        onSelectSort(EFIleManagerSort.DESC);
                    }}
                    disabled={sortType === EFIleManagerSort.DESC}
                />
                <IconButton
                    text={handleTranslate('sortType')}
                    name={'sortDefault'}
                    noHover
                    onClick={() => {
                        onSelectSort(EFIleManagerSort.DEFAULT);
                    }}
                    disabled={sortType === EFIleManagerSort.DEFAULT}
                />
                <IconButton
                    text={handleTranslate('sortDate')}
                    name={'sortDateAsc'}
                    noHover
                    onClick={() => {
                        onSelectSort(EFIleManagerSort.DATE_ASC);
                    }}
                    disabled={sortType === EFIleManagerSort.DATE_ASC}
                />
                <IconButton
                    text={handleTranslate('sortDate')}
                    name={'sortDateDesc'}
                    noHover
                    onClick={() => {
                        onSelectSort(EFIleManagerSort.DATE_DESC);
                    }}
                    disabled={sortType === EFIleManagerSort.DATE_DESC}
                />
            </Dropdown>
            <SFileManagerDivider />
            {fileManagerCheckRule(rules, EFileManagerRule.CREATE_FILE) && onCreateFile && (
                <IconButton title={handleTranslate('newFile')} name={'plus'} noHover onClick={() => onCreateFile()} />
            )}
            {fileManagerCheckRule(rules, EFileManagerRule.IMPORT) && (
                <>
                    {!isOpenImport && (
                        <IconButton
                            title={handleTranslate('importTitle')}
                            name={'fileImport'}
                            noHover
                            onClick={() => onOpenImport && onOpenImport()}
                        />
                    )}
                    {isOpenImport && (
                        <IconButton name={'close'} noHover onClick={() => onOpenImport && onOpenImport()} />
                    )}
                </>
            )}
            {fileManagerCheckRule(rules, EFileManagerRule.CREATE_DIRECTORY) && onAddFolder && (
                <IconButton title={handleTranslate('newFolder')} name={'newFolder'} noHover onClick={onAddFolder} />
            )}
            {fileManagerCheckRule(rules, EFileManagerRule.REMOVE) && onRemove && (
                <IconButton
                    title={handleTranslate('remove')}
                    name={'delete'}
                    noHover
                    onClick={onRemove}
                    disabled={selectedElements.length === 0}
                />
            )}

            <IconButton title={handleTranslate('update')} name={'reload'} noHover onClick={onGetData} />
            <SFileManagerDivider />
            <IconButton
                title={handleTranslate('previewShow')}
                fill={uiColors.darkGrey}
                noHover
                name={'previewPanel'}
                onClick={onChangePreviewMode}
            />
            <IconButton
                title={handleTranslate('changeView')}
                fill={uiColors.darkGrey}
                noHover
                name={viewMode === EViewMode.TABLE ? 'gridView' : 'listView'}
                onClick={onChangeMode}
            />
        </SFileManagerPanel>
    );
};
