import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { ApplicationActions } from '@repeat/common-slices';
import { monoLogoUrl, VERSION } from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { NotificationTypes, TVersionHistoryData } from '@repeat/models';
import { HelpPageService } from '@repeat/services';
import { TranslationKey } from '@repeat/translations';
import { Header, Layout, Main, Sidebar } from '@repeat/ui-kit';

import { VersionsPageContent } from './VersionsPageContent';
import { VersionsPageMenu } from './VersionsPageMenu';

import { Navigation } from '../../features/Navigation/Navigation';

export interface INavigationItem {
    id: string;
    title: string;
}

const VersionsPage = () => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const user = useAppSelector((state) => state.appUser);
    const [versionHistoryData, setVersionHistoryData] = useState<TVersionHistoryData | null>(null);
    const [isLoadingVersionHistoryData, setIsLoadingVersionHistoryData] = useState(true);

    useEffect(() => {
        if (isLoadingVersionHistoryData) {
            fetchVersionHistoryData();
        }
    }, [isLoadingVersionHistoryData]);

    const fetchVersionHistoryData = useCallback(async () => {
        try {
            const response = await HelpPageService.getVersionHistoryContent(intl.locale);
            if (response && response.data) {
                setVersionHistoryData(response.data);
            }
        } catch (e) {
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: TranslationKey.HELP_ERROR_FETCH_HISTORY_VERSION_DATA,
                    },
                })
            );
        } finally {
            setIsLoadingVersionHistoryData(false);
        }
    }, []);

    const getNavigation = useCallback((versionHistoryData: TVersionHistoryData | null): INavigationItem[] => {
        const versionHistory = versionHistoryData
            ? versionHistoryData
                  .map((item) => ({
                      id: item.code,
                      title: item.version,
                  }))
                  .reverse()
            : [];

        return [...versionHistory];
    }, []);

    return (
        <Layout internal>
            <Header Navigation={user?.isAuth && <Navigation />} version={VERSION} logoUrl={monoLogoUrl(intl.locale)} />
            <Sidebar position={'LEFT'} canHide={false}>
                {isLoadingVersionHistoryData ? null : (
                    <VersionsPageMenu navigation={getNavigation(versionHistoryData)} />
                )}
            </Sidebar>
            <Main>
                <VersionsPageContent isLoading={isLoadingVersionHistoryData} versionHistoryData={versionHistoryData} />
            </Main>
        </Layout>
    );
};

export default VersionsPage;
