import styled from 'styled-components';

import {
    Mixins,
    STooltipWrapper,
    border,
    layoutSize,
    pxToRem,
    radius,
    uiColors,
    uiRGBColors,
    zIndex,
} from '@repeat/ui-kit';

export const STools = styled.div`
    padding: 0;
`;

export const SToolsItemIcon = styled.div`
    margin-bottom: ${pxToRem(16)};

    i {
        max-width: ${pxToRem(64)};
        max-height: ${pxToRem(64)};
        width: ${pxToRem(64)};
    }
`;

export const SToolsItemName = styled.div`
    color: var(--ui-text);
    font-size: ${pxToRem(12)};
    text-align: center;
`;

export const SToolsItem = styled.a`
    align-items: center;
    border-radius: ${radius.default};
    cursor: pointer;
    border: ${border.default};
    display: flex;
    flex-direction: column;
    padding: ${pxToRem(16)};
    min-width: ${pxToRem(128)};
    max-width: ${pxToRem(128)};
    min-height: ${pxToRem(148)};
    text-decoration: none;
    width: 100%;

    svg {
        fill: var(--ui-heading);
    }

    &:hover {
        border: 1px solid var(--ui-link-secondary);

        ${SToolsItemName} {
            color: var(--ui-link-secondary);
        }

        svg {
            fill: var(--ui-link-secondary);
        }
    }
`;

export const SToolsCategoryName = styled.h3`
    border-bottom: ${border.default};
    font-weight: normal;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(16)};
    margin: 0;
    padding: ${pxToRem(8)} ${pxToRem(16)};
`;

export const SToolsCategoryList = styled.div`
    display: flex;
    gap: ${pxToRem(16)};
`;

export const SToolsCategory = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${pxToRem(16)};
`;

export const SToolsList = styled.div`
    display: flex;
    gap: ${pxToRem(16)};
`;

export const SToolsTabsContent = styled.div`
    margin: ${pxToRem(parseInt(layoutSize.footer))} 0 0;
    padding: ${pxToRem(16)};
    height: 100%;
`;

export const SToolsTabsAdd = styled.div`
    ${Mixins.verticalAlign()};
    justify-content: center;
    border-radius: 50%;
    margin-left: ${pxToRem(8)};
    background-color: rgba(${uiRGBColors.blue}, 30%);
    margin: ${pxToRem(8)};
    height: ${pxToRem(24)};
    width: ${pxToRem(24)};
    z-index: ${zIndex.top};

    i {
        height: ${layoutSize.footer};
        margin: 0;
        padding: 0;
    }
`;

export const SToolsTabsItem = styled.li<{ active: boolean }>`
    ${Mixins.verticalAlign()};
    display: inline-grid;
    grid-template-columns: ${pxToRem(24)} calc(100% - 24px - 16px) ${pxToRem(16)};
    grid-auto-rows: ${pxToRem(24)};
    cursor: pointer;
    color: ${({ active }) => (active ? 'var(--ui-link)' : 'lightgrey')};
    color: ${({ active }) => (active ? `rgba(${uiRGBColors.white}, 10%)` : 'transparent')};
    font-size: ${pxToRem(12)};
    max-height: ${layoutSize.footer};
    padding: ${pxToRem(8)} ${pxToRem(8)} ${pxToRem(8)} ${pxToRem(16)};
    position: relative;
    justify-content: space-between;

    > div {
        ${Mixins.verticalAlign()};
        line-height: ${pxToRem(18)};
        height: ${pxToRem(24)};
        width: fit-content;

        [data-diff='false'] {
            visibility: hidden;
        }

        [data-diff='true'] {
            visibility: visible;
        }

        &[data-edit='true'] {
            text-decoration: underline;
        }

        &[data-error='true'] {
            text-decoration-color: ${uiColors.red};
        }
    }

    &::after {
        background: var(--ui-text);
        content: '';
        position: absolute;
        width: ${pxToRem(1)};
        top: ${pxToRem(6)};
        height: ${pxToRem(28)};
        right: 0;
        opacity: 0.3;
    }

    input {
        background-color: transparent;
        border: 0;
        color: ${({ active }) => (active ? 'white' : 'lightgrey')};
        display: inline-block;
        line-height: ${pxToRem(24)};
        outline: 0;
        margin-right: ${pxToRem(4)};

        &[readonly] {
            cursor: pointer;
        }

        &[data-error='true'] {
            color: ${uiColors.red};
            font-weight: bold;
        }

        &:focus-visible,
        &:focus,
        &:active {
            border: 0 !important;
        }
    }

    i {
        margin-right: ${pxToRem(8)};

        svg {
            fill: ${({ active }) => (active ? 'white' : 'lightgrey')};
        }
    }

    button {
        margin: 0 !important;
        padding: 0 !important;
        max-width: ${pxToRem(16)};
        min-width: ${pxToRem(16)};
        position: relative;

        &[data-close] {
            justify-self: end;
            visibility: hidden;
        }

        i {
            max-width: ${pxToRem(16)};
            min-width: ${pxToRem(16)};

            svg {
                fill: lightgrey;
            }
        }

        &:hover {
            i {
                svg {
                    fill: white;
                }
            }
        }
    }

    &:hover {
        [data-close] {
            visibility: visible;
        }
    }

    [data-edit='true'] + [data-close] {
        visibility: hidden;
    }
`;

export const SToolsTabsWrapper = styled.ul`
    ${Mixins.verticalAlign()};
    max-width: calc(100vw - ${pxToRem(48)});
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
    scroll-padding-block: 0;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    z-index: ${zIndex.top};

    ::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &::after {
        background-color: rgba(${uiRGBColors.white}, 10%);
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
    }
`;

export const SToolsTabsList = styled.div`
    background-color: var(--ui-primary);
    color: ${uiColors.white};
    display: inline-flex;
    padding: 0;
    position: fixed;
    max-width: 100vw;
    width: 100%;
`;

export const SToolsTabs = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
`;

export const SCircuitWrapper = styled.div`
    .MuiFormControl-root {
        flex-direction: row;
        align-items: baseline;

        .MuiInputBase-root {
            width: calc(100% - ${pxToRem(16)});
        }

        ${STooltipWrapper} {
            margin-left: ${pxToRem(8)};

            svg {
                max-width: 98%;
            }
        }
    }

    .MuiSelect-select,
    input {
        font-family: Rosatom, sans-serif !important;
        font-size: ${pxToRem(14)} !important;
        padding: 8px;
        height: auto !important;
        max-height: ${layoutSize.footer} !important;
    }

    form {
        align-items: baseline;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    input {
        height: ${pxToRem(40)};
    }
`;

export const SToolsLoader = styled.div`
    margin: 0 0 0 ${pxToRem(8)};

    > div {
        height: ${pxToRem(20)};
        width: ${pxToRem(20)};

        &::after {
            height: ${pxToRem(14)};
            top: calc(50% + 2px);
            width: ${pxToRem(14)};
        }
    }
`;
