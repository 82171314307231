import React, { DragEvent } from 'react';

import { TViewMode } from '../../hooks/useViewMode';
import { IContextMenuItem } from '../ContextMenu/ContextMenu';

export type TFileManagerTranslation = {
    empty: string;
    selected: string;
    elements: string;
    header: string | null;
    remove: string;
    open: string;
    edit: string;
    clone: string;
    createdAt: string;
    updatedAt: string;
    export: string;
    removeDialog: string;
    cancel: string;
    importTitle: string;
    importDetails: string;
    importButton: string;
    copyId: string;
    addToFavorites: string;
    removeFromFavorites: string;
    newFolder: string;
    sortAsc: string;
    sortDesc: string;
    sortType: string;
    selectAll: string;
    previewShow: string;
    changeView: string;
    isDisabled: string;
    duplicate: string;
    update: string;
    manageDemo: string;
    addToRoot: string;
};

export enum EFIleManagerSort {
    'DEFAULT' = 'default',
    'ASC' = 'asc',
    'DESC' = 'desc',
    'DATE_ASC' = 'dateAsc',
    'DATE_DESC' = 'dateDesc',
}

export enum EFileManagerItemType {
    DIR = 'dir',
    FILE = 'file',
}

export enum Statuses {
    IDLE = 'idle',
    LOADING = 'loading',
    SUCCEEDED = 'succeeded',
    FAILED = 'failed',
}

export type Status = Statuses.IDLE | Statuses.LOADING | Statuses.SUCCEEDED | Statuses.FAILED;
export type TFileManagerItemVariant = EFileManagerItemType.DIR | EFileManagerItemType.FILE;
export type TFileManagerSortVariant =
    | EFIleManagerSort.DEFAULT
    | EFIleManagerSort.ASC
    | EFIleManagerSort.DESC
    | EFIleManagerSort.DATE_ASC
    | EFIleManagerSort.DATE_DESC;

export const FILE_MANAGER_SORT_KEY = 'fileManagerSortType';
export const FILE_MANAGER_PREVIEW_KEY = 'fileManagerPreview';

export interface TOnMovePacketPayload {
    newFolderId: number;
    files?: number[];
    folders?: number[];
}

export type TOnMovePayload = TOnMovePacketPayload;

export type TFileManagerImageLoader = {
    headers: {
        Authorization: string;
    };
    requestKey: string;
    url: string;
    fallback: string;
};

export type TFileManagerElement = {
    uuid: string;
    type: TFileManagerItemVariant;
    name: string;
    isDisabled: boolean;
    createdAt?: string;
    updatedAt?: string;
    id?: number;
    isEdit?: boolean;
    isNew?: boolean;
    ext?: string;
};

export enum EFileManagerRule {
    EDIT = 'edit',
    CLONE = 'clone',
    IMPORT = 'import',
    EXPORT = 'export',
    REMOVE = 'remove',
    SELECT = 'select',
    COPY_ID = 'copyId',
    CREATE_FILE = 'createFile',
    ADD_TO_FAVORITE = 'addToFavorite',
    CREATE_DIRECTORY = 'createDirectory',
    MANAGE_DEMO = 'manageDemo',
    ADD_TO_ROOT = 'addToRoot',
}

export type TFileManagerRule = EFileManagerRule;

export interface IFileManagerDefaultRules {
    edit?: boolean;
    clone?: boolean;
    import?: boolean;
    export?: boolean;
    remove?: boolean;
    select?: boolean;
    copyId?: boolean;
    createFile?: boolean;
    addToFavorite?: boolean;
    createDirectory?: boolean;
    manageDemo?: boolean;
    addToRoot?: boolean;
}

export interface IFileManagerRules {
    edit: boolean;
    clone: boolean;
    import: boolean;
    export: boolean;
    remove: boolean;
    select: boolean;
    copyId: boolean;
    duplicate: boolean;
    createFile: boolean;
    addToFavorite: boolean;
    createDirectory: boolean;
    manageDemo: boolean;
    addToRoot: boolean;
}

export interface IFileManagerConfirmDelete extends IFileManagerCommon {
    open: boolean;
    removeElements: TFileManagerElement[];
    onSuccess: () => void;
    onDecline: () => void;
    onHandleShow: () => void;
}

export interface IFileManagerCommon {
    translations?: TFileManagerTranslation;
    rules: IFileManagerRules;
}

export interface IFileManagerItem {
    file: TFileManagerElement;
    selectedElements: string[];
    translations?: TFileManagerTranslation;
    files: TFileManagerElement[];
    isItemDragging: boolean;
    favoriteList?: number[];
    rules: IFileManagerRules;
    onItemDragging: (status: boolean) => void;
    onSelect: (uuid: string, event?: React.MouseEvent) => void;
    onCheckboxSelect: (uuid: string) => void;
    onSaveFolderName: (name: string, uuid: string) => void;
    onEditFolderName: (uuid: string) => void;
    onOpenFolder: (id: number) => void;
    onMove: (event: DragEvent<Element>) => boolean;
    onOpenFile: (id: number) => void;
    onCopyId?: (id: number) => void;
    onRemove?: () => void;
    onExport?: (fileId: number) => void;
    onUnSelectAll: () => void;
    onContextMenu: (event: React.MouseEvent, id: number, items: IContextMenuItem[]) => void;
    onAddToFavorites?: (id: number, status: boolean) => void;
    onDuplicate?: (id: number) => void;
    onManageDemo?: (id: number) => void;
    onAddToRoot?: (id: number) => void;
}

export interface IFileManagerUpItem {
    id: number;
    onClick: () => void;
    onMove: (event: DragEvent<Element>) => boolean;
}

export interface IFileManager {
    title?: string;
    rules?: IFileManagerDefaultRules;
    translations?: TFileManagerTranslation;
    store: { elements: TFileManagerElement[]; status: Statuses; error: null | string };
    imageLoader?: TFileManagerImageLoader;
    onGetData?: (id: number) => Promise<any>;
    nameListCompare?: string[];
    favoriteList?: number[];
    onCreateFolder?: (folderId: number, name: string) => Promise<any>;
    onEditFolder?: (folderId: number, name: string) => Promise<any>;
    onEditFile?: (fileId: number, name: string) => Promise<any>;
    onExport?: (fileId: number) => void;
    onDelete?: (folders: TFileManagerElement[]) => Promise<any>;
    onMove?: (payload: TOnMovePayload) => Promise<any>;
    onOpenFile?: (id: number) => Promise<any>;
    onCopyId?: (id: number) => void;
    onImport?: (file: File) => Promise<any>;
    onCreateFile?: (folderId: number) => void;
    onGetFavoriteList?: () => void;
    onAddToFavorites?: (id: number, status: boolean) => void;
    onDuplicate?: (id: number) => Promise<any>;
    onManageDemo?: (id: number) => void;
    onAddToRoot?: (id: number) => void;
}

export interface IFileManagerHeader extends IFileManagerCommon {
    viewMode: TViewMode;
    elements: TFileManagerElement[];
    sortType: TFileManagerSortVariant;
    selectedElements: string[];
    isOpenImport: boolean;
    onChangeMode: () => void;
    onAddFolder: () => void;
    onRemove: () => void;
    onSelectSort: (type: TFileManagerSortVariant) => void;
    onSearch: (value: string) => void;
    onChangePreviewMode: () => void;
    onOpenImport?: (status?: boolean) => void;
    onCreateFile?: (status?: boolean) => void;
    onMultiSelect?: () => void;
    onGetData?: () => void;
}
export interface IFileManagerBody extends IFileManagerCommon {
    files: TFileManagerElement[];
    previousFolderIds: number[];
    currentFolderId: number;
    viewMode: TViewMode;
    selectedElements: string[];
    imageLoader?: TFileManagerImageLoader;
    isPreviewOpen: boolean;
    isOpenImport: boolean;
    isItemDragging: boolean;
    isShowPreloader: boolean;
    favoriteList?: number[];
    onItemDragging: (status: boolean) => void;
    onSelect: (uuid: string, event?: React.MouseEvent) => void;
    onCheckboxSelect: (uuid: string) => void;
    onSaveFolderName: (name: string, uuid: string) => void;
    onEditFolderName: (uuid: string) => void;
    onUnSelectAll: () => void;
    onSort: (a: TFileManagerElement, b: TFileManagerElement) => number;
    onOpenFolder: (id: number) => void;
    onReturnFolder: () => void;
    onMove: (payload: TOnMovePayload) => void;
    onOpenFile: (id: number) => void;
    onRemove?: () => void;
    onEditFile?: (fileId: number, name: string) => void;
    onExport?: (fileId: number) => void;
    onImport?: (file: File) => void;
    onCopyId?: (id: number) => void;
    onOpenImport?: (status?: boolean) => void;
    onAddToFavorites?: (id: number, status: boolean) => void;
    onDuplicate?: (id: number) => void;
    onManageDemo?: (id: number) => void;
    onAddToRoot?: (id: number) => void;
}

export interface IFileManagerFooter extends IFileManagerCommon {
    elements: TFileManagerElement[];
    selectedElements: number;
}

export interface IFileManagerPanel extends IFileManagerCommon {
    viewMode: TViewMode;
    elements: TFileManagerElement[];
    sortType: TFileManagerSortVariant;
    selectedElements: string[];
    isOpenImport: boolean;
    onChangeMode: () => void;
    onAddFolder: () => void;
    onRemove: () => void;
    onSelectSort: (type: TFileManagerSortVariant) => void;
    onChangePreviewMode: () => void;
    onOpenImport?: (status?: boolean) => void;
    onCreateFile?: () => void;
    onMultiSelect?: () => void;
    onGetData?: () => void;
}

export interface IFileManagerPreview extends IFileManagerCommon {
    files: TFileManagerElement[];
    selectedElements: string[];
    imageLoader: TFileManagerImageLoader | null;
}

export interface IFileManagerUploadZone extends IFileManagerCommon {
    isPreviewOpen: boolean;
    isOpenImport: boolean;
    isItemDragging: boolean;
    onImport?: (file: File) => void;
    onOpenImport?: (status?: boolean) => void;
}

export interface IFileManagerSelectionArea {
    selectedElements: string[];
    children: React.ReactNode;
    isItemDragging: boolean;
    onSelectGroup: (group: string[]) => void;
}
