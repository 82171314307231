import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { Modals } from '../../features/Modals/Modals';
import { RootLayout } from '../RootLayout/RootLayout';

export const AppLayout: FC = () => {
    return (
        <RootLayout>
            <Outlet />
            <Modals />
        </RootLayout>
    );
};
