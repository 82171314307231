export const LOCAL_STORAGE_KEYS = {
    LAST_SEEN_VERSION: 'last_seen_version',
    IS_DONT_SHOW_RELEASES: 'is_dont_show_releases',
    LOCALE: 'locale',
};
export const KEEP_LOCAL_STORAGE_KEYS = [
    LOCAL_STORAGE_KEYS.LAST_SEEN_VERSION,
    LOCAL_STORAGE_KEYS.IS_DONT_SHOW_RELEASES,
    LOCAL_STORAGE_KEYS.LOCALE,
];

export const isLocalStorageState = (value: string) => {
    return Boolean(localStorage.getItem(value));
};

export const getLocalStorageState = (type: string): boolean => {
    const value = localStorage.getItem(type);
    return value === 'true';
};

export const setLocalStorageState = (type: string, e: boolean | number | string) => {
    localStorage.setItem(`${type}`, `${e}`);
};

export const isLeftBarOpened = () => {
    if (isLocalStorageState('left-bar')) {
        return getLocalStorageState('left-bar');
    }
    return true;
};
export const isBottomBarOpened = () => {
    if (isLocalStorageState('footer-fullscreen')) {
        const isFullscreen = getLocalStorageState('footer-fullscreen');
        if (isFullscreen) {
            return false;
        }
    }
    if (isLocalStorageState('footer')) {
        return getLocalStorageState('footer');
    }
    return false;
};

export const getLocale = (): string | null => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.LOCALE);
};

export const setLocale = (locale: string) => {
    return localStorage.setItem(LOCAL_STORAGE_KEYS.LOCALE, locale);
};

export const getTheme = () => localStorage.getItem('theme');
export const setTheme = (theme: string) => localStorage.setItem('theme', theme);
export const setMode = (isEngineer: boolean) => localStorage.setItem('isEngineer', isEngineer.toString());

export const getIsDemoMode = () => {
    const demoValue = localStorage.getItem('demoProject') || 'false';
    return new RegExp(/true/).test(demoValue.toLowerCase());
};
