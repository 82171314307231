import { User } from '@repeat/models';

import { AdminAction, AdminTypes } from '../actions/adminTypes';

export const adminReducer = (state: User[] = [], action: AdminAction): User[] => {
    switch (action.type) {
        case AdminTypes.SET_USERS:
            return action.payload;

        default:
            return state;
    }
};

export default adminReducer;
