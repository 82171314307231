import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { TAvailableLibraries } from '@repeat/models';
import { AvailableLibraries } from '@repeat/services';
import { RootState } from '@repeat/store';

/* eslint-disable @typescript-eslint/no-explicit-any */

export interface FetchAvailableLibrariesAction {
    type: string;
    payload: TAvailableLibraries;
}

export enum AvailableLibrariesType {
    SET_AVAILABLE_LIBRARIES = 'SET_AVAILABLE_LIBRARIES',
}

export interface IAvailableLibrariesAction extends Action<AvailableLibrariesType> {
    type: AvailableLibrariesType.SET_AVAILABLE_LIBRARIES;
    payload: TAvailableLibraries;
}

export const setAvailableLibraries = (items: TAvailableLibraries): FetchAvailableLibrariesAction => {
    return {
        type: AvailableLibrariesType.SET_AVAILABLE_LIBRARIES,
        payload: items,
    };
};

export const fetchAvailableLibraries = () => async (dispatch: ThunkDispatch<RootState, any, FetchAvailableLibrariesAction>) => {
    try {
        const availableLibraries = await AvailableLibraries.getAvailableLibraries();

        if (availableLibraries && availableLibraries.data) {
            dispatch(setAvailableLibraries(availableLibraries.data));
        }
    } catch (e) {
        console.log(e);
    }
};
