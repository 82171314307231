import { FC, useEffect } from 'react';
import { useHotkeysContext } from 'react-hotkeys-hook';
import { useIntl } from 'react-intl';

import styled from 'styled-components';

import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { IElementConfiguration, Statuses, TSchemaNode } from '@repeat/models';
import { getElementPropertiesSets, selectElementConfiguration, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { Accordion, Loader, pxToRem, uiColors } from '@repeat/ui-kit';

import { ElementConfigurationAccordionItem } from './ElementConfigurationAccordionItem';
import { ElementConfigurationContent } from './ElementConfigurationContent';
import { ElementConfigurationTitle } from './ElementConfigurationTitle';

import { messages } from '../translation';

const SElementConfiguration = styled.div`
    margin-bottom: -${pxToRem(16)};
`;
const SConfigurationNotice = styled.div<{ color?: string }>`
    font-size: ${pxToRem(12)};
    margin-bottom: ${pxToRem(16)};
    color: ${({ color }) => color && color};
`;

const SErrorBlock = styled.span`
    color: ${uiColors.red};
`;

interface ElementConfigurationProps {
    selectedElement: TSchemaNode | undefined;
    onClose: () => void;
}

export const ElementConfiguration: FC<ElementConfigurationProps> = ({ selectedElement, onClose }) => {
    const { enableScope, disableScope } = useHotkeysContext();

    const intl = useIntl();
    const dispatch = useAppDispatch();

    const elementPropertiesSetsState = useAppSelector(workspaceSelectors.schemaElementPropertiesSetsState);
    const getElementPropertiesSetsStatus = elementPropertiesSetsState.status;
    const availableConfigurations = elementPropertiesSetsState.configurations;

    const selectedConfigurationName = selectedElement?.data?.selectedConfiguration?.name;

    useEffect(() => {
        if (selectedElement) {
            const { type, library, solver } = selectedElement.data;
            if (type && library && solver) {
                dispatch(
                    getElementPropertiesSets({
                        elementType: type,
                        libraryType: library,
                        solverType: solver,
                    })
                );
            }
        }
        disableScope('workspacePage');
        return () => {
            enableScope('workspacePage');
        };
    }, []);

    const selectHandler = (elementId: string | undefined, configurationId: number) => {
        if (elementId) {
            dispatch(selectElementConfiguration(elementId, configurationId));
        }
        onClose();
    };

    if (getElementPropertiesSetsStatus === Statuses.LOADING) {
        return <Loader />;
    }
    if (getElementPropertiesSetsStatus === Statuses.FAILED) {
        return (
            <SErrorBlock>
                {intl.formatMessage(messages[TranslationKey.ERROR_GETTING_ELEMENT_PROPERTIES_SETS])}
            </SErrorBlock>
        );
    }
    const isSelectedConfigurationDeleted =
        availableConfigurations?.filter((config) => config.isSelected).length === 0 && selectedConfigurationName;

    return (
        <SElementConfiguration>
            <SConfigurationNotice>
                {intl.formatMessage(messages[TranslationKey.ELEMENT_CONFIGURATION_NOTICE])}
            </SConfigurationNotice>
            {isSelectedConfigurationDeleted && (
                <SConfigurationNotice color={uiColors.orange}>
                    {intl.formatMessage(messages[TranslationKey.ELEMENT_CONFIGURATION_IS_NOT_RELEVANT], {
                        name: selectedConfigurationName,
                    })}
                </SConfigurationNotice>
            )}
            <Accordion>
                {availableConfigurations?.map((config: IElementConfiguration) => (
                    <ElementConfigurationAccordionItem
                        iconName={false}
                        key={config.id}
                        title={
                            <ElementConfigurationTitle
                                title={config.name}
                                elementId={selectedElement?.id}
                                config={config}
                                onClick={selectHandler}
                            />
                        }
                    >
                        <ElementConfigurationContent elementProperties={config.elementProperties} />
                    </ElementConfigurationAccordionItem>
                ))}
            </Accordion>
        </SElementConfiguration>
    );
};
