export enum SelectorModelStatuses {
    RUN = '1',
    SOFT_STOP_BY_SOLVER = '0',
    SOFT_STOP_BY_USER = '2',
    STOP_BY_USER = '3',
    EXCEEDED_SYSTEM_TIME_LIMIT = '50',
    EXCEEDED_DOCUMENT_TIME_LIMIT = '21',
    EXCEEDED_USER_TIME_LIMIT = '22',
    EXCEEDED_MEMORY_HEAP_SIZE_LIMIT = '51',
    EXCEEDED_DB_SIZE_LIMIT = '52',
    ERROR = '100',
    BUILD_ERROR = '101',
}

export type TSelectorStatusModel =
    | SelectorModelStatuses.RUN
    | SelectorModelStatuses.SOFT_STOP_BY_SOLVER
    | SelectorModelStatuses.SOFT_STOP_BY_USER
    | SelectorModelStatuses.STOP_BY_USER
    | SelectorModelStatuses.ERROR
    | SelectorModelStatuses.BUILD_ERROR
    | SelectorModelStatuses.EXCEEDED_DB_SIZE_LIMIT
    | SelectorModelStatuses.EXCEEDED_SYSTEM_TIME_LIMIT
    | SelectorModelStatuses.EXCEEDED_DOCUMENT_TIME_LIMIT
    | SelectorModelStatuses.EXCEEDED_USER_TIME_LIMIT
    | SelectorModelStatuses.EXCEEDED_MEMORY_HEAP_SIZE_LIMIT;

export interface ISelectorMessage {
    statusModel: TSelectorStatusModel;
    frameModel: string;
    timeModel: number;
    mapValueParams: { [key: string]: number | string };
}
