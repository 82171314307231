import { isAnyOf, ListenerMiddlewareInstance } from '@reduxjs/toolkit';

import { ProjectsActions } from '@repeat/store';

import { getFavProjects, getProjects } from '../slices/projectsSlice';

export const appendProjectsListeners = (listenerMiddleware: ListenerMiddlewareInstance) => {
    listenerMiddleware.startListening({
        matcher: isAnyOf(ProjectsActions.createProjectSuccess, ProjectsActions.updateProjectSuccess),
        effect: async (action, listenerApi) => {
            await listenerApi.dispatch(getProjects());
        },
    });
    listenerMiddleware.startListening({
        matcher: isAnyOf(ProjectsActions.deleteProjectsSuccess, ProjectsActions.deleteProjectSuccess),
        effect: async (action, listenerApi) => {
            await listenerApi.dispatch(getProjects());
            await listenerApi.dispatch(getFavProjects());
        },
    });
    listenerMiddleware.startListening({
        matcher: isAnyOf(ProjectsActions.addFavProjectSuccess),
        effect: async (action, listenerApi) => {
            await listenerApi.dispatch(getProjects());
            await listenerApi.dispatch(getFavProjects());
        },
    });
    listenerMiddleware.startListening({
        matcher: isAnyOf(ProjectsActions.duplicateProjectSuccess),
        effect: async (action, listenerApi) => {
            await listenerApi.dispatch(getProjects());
        },
    });
};
