import { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import styled from '@emotion/styled';

import { ru, TranslationKey } from '@repeat/translations';

import { UserCard } from '../UserCard/UserCard';

const messages = defineMessages({
    [TranslationKey.SECTION_IN_PROGRESS]: {
        id: TranslationKey.SECTION_IN_PROGRESS,
        defaultMessage: ru[TranslationKey.SECTION_IN_PROGRESS],
    },
});

const StyledAdminPage = styled.div`
    background-color: var(--ui-background);
    margin-top: 48px;
    padding: 48px;
    height: 100vh;
`;

const StyledInDevelopmentAdminPageWrapper = styled(StyledAdminPage)`
    height: 100vh;
    width: 100%;
    backdrop-filter: blur(1px);
    display: block;
    text-align: center;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 48px;

    & * {
        z-index: -1;
    }
`;

const StyledDevelopmentTitle = styled.h1`
    font-size: xx-large;
    font-weight: bold;
`;

export const AdminPage: FC = () => {
    const { formatMessage } = useIntl();

    const users = [
        {
            id: 1,
            name: 'Ivan',
            email: 'ivan@ivan',
            isAdmin: true,
            userData: [
                { id: 1, name: '111' },
                { id: 2, name: '222' },
            ],
        },
        {
            id: 2,
            name: 'Petr',
            email: 'petr@petr',
            isAdmin: false,
            userData: [
                { id: 3, name: '333' },
                { id: 4, name: '444' },
            ],
        },
    ];

    return (
        <StyledInDevelopmentAdminPageWrapper className='wrapper'>
            <StyledDevelopmentTitle>
                {formatMessage(messages[TranslationKey.SECTION_IN_PROGRESS])}
            </StyledDevelopmentTitle>
            <StyledAdminPage>
                {users.length ? users.map((el) => <UserCard key={el.id} {...el} />) : <p>no users</p>}
            </StyledAdminPage>
            ;
        </StyledInDevelopmentAdminPageWrapper>
    );
};
