import { FC, forwardRef, useCallback, ChangeEvent, useState, useEffect } from 'react';

import { SCheckBoxError, SCheckboxLabel, SCheckboxContent, SCheckboxCheckmark } from './SComponents';

interface ICheckbox {
    id?: string;
    checked?: boolean;
    defaultChecked?: boolean;
    disabled?: boolean;
    label?: React.ReactNode;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClick?: (e: React.MouseEvent) => void;
    errorText?: string | boolean;
    error?: boolean | undefined;
}

export const CheckBox: FC<ICheckbox> = forwardRef<HTMLInputElement, ICheckbox>((props, ref) => {
    const { label, error, disabled, errorText, ...rest } = props;
    return (
        <SCheckboxLabel disabled={Boolean(disabled)}>
            <input disabled={disabled} ref={ref} {...rest} type='checkbox' />
            <SCheckboxCheckmark error={Boolean(error)} />
            <SCheckboxContent>
                {label}
                {errorText && <SCheckBoxError>{errorText}</SCheckBoxError>}
            </SCheckboxContent>
        </SCheckboxLabel>
    );
});

export const CustomCheckBox = forwardRef<HTMLInputElement, ICheckbox & { checked?: boolean }>((props, ref) => {
    const { onChange, checked, onInput } = props;

    const [isChecked, setIsChecked] = useState(checked || false);

    useEffect(() => {
        if (typeof checked !== 'undefined') {
            setIsChecked(checked);
        }
    }, [checked]);

    const changeHandler = useCallback((event: ChangeEvent) => {
        const target = event.target as HTMLInputElement;
        setIsChecked(target.checked);
        onChange && onChange(event as ChangeEvent<HTMLInputElement>);
        onInput && onInput(event as ChangeEvent<HTMLInputElement>);
    }, []);

    return <input {...props} type='checkbox' onChange={changeHandler} checked={isChecked} />;
});
