import { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import Button from '@mui/material/Button';

import { DATE_TIME_FORMAT } from '@repeat/constants';
import { environment } from '@repeat/environment';
import { getIsDemoMode, useAppDispatch, useAppSelector } from '@repeat/hooks';
import {
    LibraryTypes,
    SchemaSelectionModes,
    Statuses,
    TSchemaNode,
    TSchemaSelectionMode,
    WorkspaceModes,
} from '@repeat/models';
import { getProjects, workspaceActions, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { Icon, IconButton, ShowMore, SidebarContent, layoutSize, pxToRem, uiColors } from '@repeat/ui-kit';

import 'react-tabs/style/react-tabs.css';
import { GroupName } from './GroupName';
import { SElementId, SElementName, SIconTab, SOptionsWrapper, SToolsTabsWrapper } from './SRightBar';
import { messages } from './translation';

import { IBlockDescription, ModalContext } from '../BlockDescriptionModal/BlockDescriptionModalContext';
import { ConnectionCurrent } from '../CurrentItemOptions/ConnectionCurrent';
import { NodeCurrent } from '../CurrentItemOptions/NodeCurrent';
import { ElementParameters } from '../CurrentItemOptions/PropertiesAndParameters/ElementParameters';
import { ElementPropertiesCustom } from '../CurrentItemOptions/PropertiesAndParameters/ElementPropertiesCustom';
import { ProjectDate } from '../Project/ProjectDate';
import { ProjectSettings } from '../ProjectSettings/ProjectSettings';
import { WorkspaceProjectName } from '../Workspace/ProjectName/ProjectName';

const { DOCS_URL } = environment;

export const RightBar = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const [selectedTabs, setSelectedTabs] = useState({ settings: true, block: false });

    const handleBackToSchemaClick = useCallback(() => {
        dispatch(workspaceActions.changeWorkspaceMode({ mode: WorkspaceModes.MAIN, userBlockId: null }));
        localStorage.setItem('demoProject', 'false');
    }, []);

    const selectedItems = useAppSelector((state) => state.workspace.schema.selectedItems);
    const workspaceMode = useAppSelector((state) => state.workspace.meta.mode);
    const isUserBlockEditorMode = useAppSelector(workspaceSelectors.isUserBlockEditorMode);
    const isMainGroupLevel = useAppSelector(workspaceSelectors.isMainGroupLevel);
    const isDemo = getIsDemoMode();

    const isMainGroupUserBlockEditor = useAppSelector(workspaceSelectors.isMainGroupUserBlockEditor);

    const projects = useAppSelector((state) => state.projects);

    const currentNodeNameId = useAppSelector(workspaceSelectors.currentNodeIndicator);
    const currentNodeNameAndDescription = useAppSelector(workspaceSelectors.currentNodeNameAndDescription);

    const selectedNode = selectedItems.elements.length === 1 ? selectedItems.elements[0] : null;
    const isProjectBlockSelected = selectedNode?.data.type === 'project';
    const currentSubmodelProject = useAppSelector(
        workspaceSelectors.currentSubmodelProjectById(selectedItems.elements[0]?.id)
    );
    let currentSubmodelProjectUpdatedAt = '';
    let currentSubmodelProjectCreatedAt = '';
    if (projects.status === Statuses.SUCCEEDED && isProjectBlockSelected) {
        currentSubmodelProjectUpdatedAt =
            projects.items.find((pr) => pr.projectId === Number(currentSubmodelProject?.projectId))?.updatedAt || '';
        currentSubmodelProjectCreatedAt =
            projects.items.find((pr) => pr.projectId === Number(currentSubmodelProject?.projectId))?.createdAt || '';
    }

    const selectedConnection = selectedItems.wires.length === 1 ? selectedItems.wires[0] : null;

    const getMode = (): TSchemaSelectionMode => {
        if (selectedItems.elements.length === 0 && selectedItems.wires.length === 0) {
            return SchemaSelectionModes.PROJECT;
        }
        if (selectedNode && !selectedConnection) {
            return SchemaSelectionModes.NODE;
        }
        if (!selectedNode && selectedConnection) {
            return SchemaSelectionModes.CONNECTION;
        }
        if (selectedItems.elements.length > 1) {
            return SchemaSelectionModes.MULTIPLE_OBJECTS;
        }
        if (selectedItems.wires.length > 1) {
            return SchemaSelectionModes.MULTIPLE_OBJECTS;
        }
        if (selectedItems.elements.length >= 1 && selectedItems.wires.length >= 1) {
            return SchemaSelectionModes.MULTIPLE_OBJECTS;
        }

        return SchemaSelectionModes.PROJECT;
    };

    const [mode, setMode] = useState<TSchemaSelectionMode>(getMode());

    useEffect(() => {
        setSelectedTabs({ settings: true, block: false });
        setMode(getMode());
    }, [selectedNode, selectedConnection, selectedItems]);

    useEffect(() => {
        dispatch(getProjects());
    }, []);

    const handleDelete = () => {
        dispatch(workspaceActions.deleteSchemaItems());
    };

    const getObjectTitle = (mode: TSchemaSelectionMode): string => {
        switch (mode) {
            case SchemaSelectionModes.CONNECTION:
                return intl.formatMessage(messages[TranslationKey.WORKSPACE_CONNECTION_TITLE]);
            case SchemaSelectionModes.NODE:
            default:
                return intl.formatMessage(messages[TranslationKey.WORKSPACE_ELEMENT_TITLE]);
        }
    };

    const { openModal } = useContext(ModalContext)!;

    // TODO use modal when docs API is ready
    const handleModal = (id: string, name: string, description: string) => {
        const newModal: IBlockDescription = { id: id, name: name, description: description };
        openModal(newModal);
    };

    const blockDocsUrl = useCallback((node: TSchemaNode) => {
        const block = node.data;

        // TODO remove check on systemDesign library when docs are ready (subdirectories for all library)
        let blockFullLibrary: string = block.library;
        if (block.library === LibraryTypes.SYSTEM_DESIGN) {
            if (!block.subLibrary) {
                return `${DOCS_URL}/${intl.locale}/search.html?q=${block.name}`;
            }

            blockFullLibrary = `${block.library}/${block.subLibrary}`;
        }

        const blockFullType = block.subtype ? `${block.type}_${block.subtype}` : block.type;
        const blockDocsUrl = `${DOCS_URL}/${intl.locale}/reference/blocklib/${blockFullLibrary}/${blockFullType}/index.html`;

        return blockDocsUrl;
    }, []);

    const title = isUserBlockEditorMode
        ? intl.formatMessage(messages[TranslationKey.WORKSPACE_BLOCK_TITLE])
        : intl.formatMessage(messages[TranslationKey.WORKSPACE_PROJECT_TITLE]);

    const getElementName = (node: TSchemaNode) => {
        if (!node) return '';
        const { type, elemProps } = node.data;
        if (type === 'project') {
            const projectNameProp = elemProps.find((prop) => prop.name === 'projectName');
            return projectNameProp ? projectNameProp.value : '';
        } else if (type === 'group') {
            return <GroupName name={currentNodeNameAndDescription?.name || ''} />;
        } else {
            return currentNodeNameAndDescription?.name || '';
        }
    };

    return (
        <div
            data-tour='right-sidebar'
            style={{
                height: `100%`,
                overflowY: 'auto',
                maxHeight: `calc(100vh - ${layoutSize.header} - ${layoutSize.footer})`,
            }}
            id='rightBar'
        >
            {mode === SchemaSelectionModes.MULTIPLE_OBJECTS && !isDemo && (
                <SidebarContent title={intl.formatMessage(messages[TranslationKey.WORKSPACE_OBJECTS_TITLE])}>
                    <Button onClick={handleDelete} type='button' style={{ width: '94px', margin: '8px' }}>
                        <Icon name='close' fill={uiColors.mainBlue} />
                        {intl.formatMessage(messages[TranslationKey.DELETE])}
                    </Button>
                </SidebarContent>
            )}
            {mode === SchemaSelectionModes.PROJECT && (
                <SidebarContent title={title}>
                    <ShowMore>
                        <div style={{ marginTop: `${pxToRem(16)}` }}>
                            <WorkspaceProjectName noSave noClose />
                            {workspaceMode === WorkspaceModes.MAIN && !isUserBlockEditorMode && (
                                <SToolsTabsWrapper>
                                    <Tabs>
                                        <TabList style={{ display: 'flex' }}>
                                            <Tab
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    fontSize: '14px',
                                                    color: `${
                                                        selectedTabs.settings ? uiColors.mainBlue : uiColors.darkGrey
                                                    }`,
                                                }}
                                                onClick={() => setSelectedTabs({ settings: true, block: false })}
                                            >
                                                <SIconTab
                                                    fill={`${
                                                        selectedTabs.settings ? uiColors.mainBlue : uiColors.darkGrey
                                                    }`}
                                                    name={'settings'}
                                                />
                                                {intl.formatMessage(messages[TranslationKey.SETTINGS])}
                                            </Tab>
                                            <Tab
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    fontSize: '14px',
                                                    color: `${
                                                        selectedTabs.block ? uiColors.mainBlue : uiColors.darkGrey
                                                    }`,
                                                }}
                                                onClick={() => setSelectedTabs({ settings: false, block: true })}
                                            >
                                                <SIconTab
                                                    fill={`${
                                                        selectedTabs.block ? uiColors.mainBlue : uiColors.darkGrey
                                                    }`}
                                                    name={'submodel'}
                                                />
                                                {intl.formatMessage(messages[TranslationKey.WORKSPACE_ELEMENT_TITLE])}
                                            </Tab>
                                        </TabList>
                                        <TabPanel>
                                            <ProjectSettings />
                                        </TabPanel>
                                        <TabPanel style={{ margin: `0 -${pxToRem(16)}` }}>
                                            <ElementPropertiesCustom mode={mode} />
                                            <ElementParameters mode={mode} />
                                        </TabPanel>
                                    </Tabs>
                                </SToolsTabsWrapper>
                            )}

                            {(workspaceMode === WorkspaceModes.SUBMODEL ||
                                (workspaceMode === WorkspaceModes.GROUP && !isMainGroupUserBlockEditor)) && (
                                <Button onClick={handleBackToSchemaClick} type='button'>
                                    <Icon name='back' fill={uiColors.mainBlue} />
                                    &nbsp;{intl.formatMessage(messages[TranslationKey.SCHEMA_ELEMENT_BACK_TO_SCHEMA])}
                                </Button>
                            )}
                        </div>
                        {isUserBlockEditorMode && isMainGroupLevel && (
                            <div style={{ margin: `0 -${pxToRem(16)}` }}>
                                <ElementPropertiesCustom mode={mode} />
                                <ElementParameters mode={mode} />
                            </div>
                        )}
                    </ShowMore>
                </SidebarContent>
            )}
            {(mode === SchemaSelectionModes.NODE || mode === SchemaSelectionModes.CONNECTION) && (
                <SidebarContent title={getObjectTitle(mode)}>
                    {currentNodeNameAndDescription?.name && (
                        <ShowMore>
                            <SElementName data-name={'element-name'}>
                                {selectedNode && getElementName(selectedNode)}
                                {selectedNode && (
                                    <a href={blockDocsUrl(selectedNode)} target='_blank' rel='noreferrer'>
                                        <IconButton
                                            name='info'
                                            noHover
                                            fill={uiColors.darkGrey}
                                            style={{ minWidth: pxToRem(20) }}
                                        />
                                    </a>
                                )}
                            </SElementName>
                            {selectedNode?.id && <SElementId>Id: {selectedNode.id}</SElementId>}

                            {isProjectBlockSelected &&
                                (workspaceMode === WorkspaceModes.MAIN || workspaceMode === WorkspaceModes.GROUP) &&
                                !selectedNode.data.diff?.isErrorGettingProject && (
                                    <div style={{ fontSize: pxToRem(12), marginTop: pxToRem(8) }}>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ marginRight: '8px', color: 'var(--ui-text)' }}>
                                                {intl.formatMessage(
                                                    messages[TranslationKey.PROJECTS_COLUMN_CREATED_AT]
                                                )}
                                                :
                                            </div>
                                            {currentSubmodelProjectCreatedAt.length !== 0 && (
                                                <ProjectDate
                                                    date={currentSubmodelProjectCreatedAt}
                                                    format={DATE_TIME_FORMAT}
                                                />
                                            )}
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ marginRight: '8px', color: uiColors.darkGrey }}>
                                                {intl.formatMessage(
                                                    messages[TranslationKey.PROJECTS_COLUMN_UPDATED_AT]
                                                )}
                                                :
                                            </div>
                                            {currentSubmodelProjectUpdatedAt.length !== 0 && (
                                                <ProjectDate
                                                    date={currentSubmodelProjectUpdatedAt}
                                                    format={DATE_TIME_FORMAT}
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}
                        </ShowMore>
                    )}
                    <SOptionsWrapper data-name={'options-container'}>
                        {mode === SchemaSelectionModes.NODE && <NodeCurrent />}
                        {mode === SchemaSelectionModes.CONNECTION && <ConnectionCurrent />}
                    </SOptionsWrapper>
                </SidebarContent>
            )}
        </div>
    );
};
