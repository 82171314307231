import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.CODE_EDITOR_NODE_NOT_FOUND]: {
        id: TranslationKey.CODE_EDITOR_NODE_NOT_FOUND,
        defaultMessage: ru[TranslationKey.CODE_EDITOR_NODE_NOT_FOUND],
    },
    [TranslationKey.CODE_EDITOR_PROPERTY_NOT_FOUND]: {
        id: TranslationKey.CODE_EDITOR_PROPERTY_NOT_FOUND,
        defaultMessage: ru[TranslationKey.CODE_EDITOR_PROPERTY_NOT_FOUND],
    },
});
