import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    Autocomplete,
    AutocompleteRenderGetTagProps,
    AutocompleteRenderGroupParams,
    AutocompleteRenderInputParams,
    Chip,
    createFilterOptions,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
} from '@mui/material';

import { StyledForm } from 'libs/ui-kit/src/lib/Form/StyledForm';

import { ApplicationActions } from '@repeat/common-slices';
import { unicodeReplaceSymbol, unicodeToHtml, validateEmptySpaces } from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import {
    ElemParams,
    IElementPropsBase,
    ILibraryItem,
    LibraryTypes,
    ModalTypes,
    PortPositions,
    PortPositionTypes,
    PortTypes,
    TLibraryItemPort,
    TNewBlockInfoModal,
} from '@repeat/models';
import { TranslationKey } from '@repeat/translations';
import { Button, InputField } from '@repeat/ui-kit';

import { SAutocomplete } from '../../Visualization/Charts/AutoCompleteParametersForm/SAutocompleteParametersForm';
import { EngineerService } from '../EngineerService';
import {
    SBlockPortEdit,
    SDisplaySection,
    SGroupHeader,
    SGroupItems,
    SInputNotice,
    StyledButtons,
} from '../styled/SEditBlock';
import { messages } from '../translations';

interface IEditBlockUIProps {
    currentBlockProp: ILibraryItem;
    engineerService: EngineerService;
}

interface IAutocompleteListBoxProps extends React.HTMLAttributes<HTMLUListElement> {
    'data-test-id'?: string;
}

export const EditBlockUI: FC<IEditBlockUIProps> = (props) => {
    const intl = useIntl();
    const location = useLocation();
    const [isValidForm, setIsValidForm] = useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const engineerService = props.engineerService;
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm<Pick<ILibraryItem, 'availablePorts' | 'view' | 'parametersToDisplay' | 'propertiesToDisplay'>>({
        mode: 'onChange',
    });
    const portTypes: PortTypes[] = [PortTypes.INPUT, PortTypes.OUTPUT];
    const modals = useAppSelector((state) => state.app.modals);
    const modal = modals.find((m) => m.type === ModalTypes.NEWBLOCK_INFO) as TNewBlockInfoModal;
    const [block] = useState(modal.data.block);

    const portPositions = [
        {
            position: PortPositionTypes.LEFT,
            title: 'Слева',
        },
        {
            position: PortPositionTypes.RIGHT,
            title: 'Справа',
        },
        {
            position: PortPositionTypes.TOP,
            title: 'Сверху',
        },
        {
            position: PortPositionTypes.BOTTOM,
            title: 'Снизу',
        },
    ];
    const [blockIsRotatable, setBlockIsRotatable] = useState(block.view ? block.view.isRotatable : false);
    const [blockImageRotatable, setBlockImageRotatable] = useState(block.view ? block.view.isImageRotatable : false);
    const [blockIsResizable, setBlockIsResizable] = useState(block.view ? block.view.isResizable : false);
    const [paramsToDisplay, setParamsToDisplay] = useState<IElementPropsBase[]>();
    const [propsToDisplay, setPropsToDisplay] = useState<IElementPropsBase[]>();
    const [ports, setPorts] = useState<TLibraryItemPort[]>([]);
    const [portPosition, setPortPosition] = useState<PortPositions>();

    useEffect(() => {
        if (block.availablePorts && block.availablePorts.length > 0) {
            setPorts(block.availablePorts);
            block.availablePorts.forEach((port) => {
                if (port.position) {
                    setPortPosition(port.position);
                }
            });
        }
    }, [block]);

    const handleViewChange = () => {
        setBlockIsRotatable(!blockIsRotatable);
    };

    const handleSaveClick = async (
        data: Pick<ILibraryItem, 'aliases' | 'availablePorts' | 'view' | 'parametersToDisplay' | 'propertiesToDisplay'>
    ) => {
        const engineerBlock = structuredClone(block);

        data.availablePorts.forEach((port, i) => {
            port.libraries = Object.values(LibraryTypes);
            port.typeConnection = block.availablePorts[i].typeConnection;
        });
        data.parametersToDisplay = paramsToDisplay?.map((param) => param.name) ?? [];

        data.propertiesToDisplay = propsToDisplay?.map((prop) => prop.name) ?? null;
        data.parametersToDisplay.slice().sort(sortParamsNames);
        const allParamsIncluded = Object.values(engineerBlock.elemParams).every((param) =>
            data.parametersToDisplay?.includes(param.name)
        );

        if (allParamsIncluded) {
          data.parametersToDisplay = null;
        }

        Object.assign(engineerBlock, data);
        await engineerService.updateBlockByCursor(engineerBlock.type, engineerBlock).then(() => navigate(0));
    };

    const getOptionLabel = useCallback(
        (option: IElementPropsBase) => unicodeReplaceSymbol(option?.name) || '',
        [propsToDisplay, paramsToDisplay]
    );
    const getAliasOptionLabel = (option: string) => unicodeReplaceSymbol(option) || '';
    const optionEqualToValue = (option: IElementPropsBase, value: IElementPropsBase) => {
        if (option && value) {
            return option.name === value.name;
        }
        return false;
    };
    const aliasOptionEqualToValue = (option: string, value: string) => {
        if (option && value) {
            return option === value;
        }
        return false;
    };

    const renderInput = (label: string) => (params: AutocompleteRenderInputParams) =>
        <TextField {...params} label={label} />;
    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: (option: IElementPropsBase) => `${option.name} ${unicodeToHtml(option.name)}`,
    });
    const filterAliasOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: (option: string) => `${option} ${unicodeToHtml(option)}`,
    });

    const renderDataTestId = (value: string) =>
        ({
            'data-test-id': `${value}`,
        } as IAutocompleteListBoxProps);

    const renderGroup = (params: AutocompleteRenderGroupParams) => (
        <li key={`${params.group}`}>
            <SGroupHeader>{params.group}</SGroupHeader>
            <SGroupItems>{params.children}</SGroupItems>
        </li>
    );

    const renderTag = (tagValue: IElementPropsBase[], getTagProps: AutocompleteRenderGetTagProps) => {
        return tagValue.map((option: IElementPropsBase, index: number) => {
            const html = (
                <span
                    style={{ color: 'var(--ui-text)' }}
                    dangerouslySetInnerHTML={{
                        __html: `${option.name}`,
                    }}
                />
            );
            return <Chip {...getTagProps({ index })} label={html} />;
        });
    };

    const handleClose = () => {
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.NEWBLOCK_INFO }));
    };

    const sortPortName = (a: ElemParams, b: ElemParams) => {
        const numA = parseInt(a.name.match(/_\d+$/)![0].slice(1));
        const numB = parseInt(b.name.match(/_\d+$/)![0].slice(1));
        if (numA < numB) {
            return -1;
        }
        return 1;
    };

    const sortParamsNames = (a: string, b: string) => {
        const numA = parseInt(a.match(/_\d+$/)![0].slice(1));
        const numB = parseInt(b.match(/_\d+$/)![0].slice(1));
        if (numA < numB) {
            return -1;
        }
        return 1;
    };

    return (
        <StyledForm>
            <h5>Порты</h5>
            {block.elemParams.length > 0 &&
                block.elemParams
                    .slice()
                    .sort(sortPortName)
                    .map((port, index) => (
                        <SBlockPortEdit>
                            <InputField
                                key={port.name}
                                label={port.name}
                                defaultValue={port.name ?? ''}
                                type='text'
                                id='standard-required'
                                {...register(`availablePorts.${index}.name`, {
                                    required: true,
                                    validate: {
                                        noWhitespace: validateEmptySpaces,
                                    },
                                })}
                            />
                            <FormControl fullWidth>
                                <InputLabel id='select-position'>Расположение порта</InputLabel>
                                <Select
                                    label={'Расположение порта'}
                                    id='select-position'
                                    defaultValue={
                                        block.availablePorts?.find((p) => port.name === p.name)?.position ??
                                        portPositions[0].position
                                    }
                                    {...register(`availablePorts.${index}.position`, { required: true })}
                                >
                                    {portPositions.map((position) => (
                                        <MenuItem key={position.position} value={position.position}>
                                            {position.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id='select-position'>Тип порта</InputLabel>
                                <Select
                                    label={'Тип порта'}
                                    defaultValue={block.availablePorts?.find((p) => port.name === p.name)?.type}
                                    {...register(`availablePorts.${index}.type`, { required: true })}
                                >
                                    {portTypes.map((type) => (
                                        <MenuItem key={type} value={type}>
                                            {type === PortTypes.INPUT ? 'Входной порт' : 'Выходной порт'}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <SInputNotice>{port.name}</SInputNotice>
                        </SBlockPortEdit>
                    ))}
            <h5>Отображаемые свойства</h5>
            <>
                <FormControlLabel
                    control={
                        <Switch
                            {...register('view.isRotatable')}
                            checked={blockIsRotatable}
                            onChange={handleViewChange}
                        />
                    }
                    label='Вращаемость'
                />
                <FormControlLabel
                    control={
                        <Switch
                            {...register('view.isImageRotatable')}
                            checked={blockImageRotatable}
                            onChange={() => setBlockImageRotatable(!blockImageRotatable)}
                        />
                    }
                    label='Вращаемость иконки'
                />
                <FormControlLabel
                    control={
                        <Switch
                            {...register('view.isResizable')}
                            checked={blockIsResizable}
                            onChange={() => setBlockIsResizable(!blockIsResizable)}
                        />
                    }
                    label='Изменяемый размер'
                />
            </>
            {block.elemParams && (
                <SDisplaySection>
                    <h5>Отображаемые параметры</h5>
                    <SAutocomplete>
                        <Autocomplete
                            multiple
                            disableClearable
                            size={'small'}
                            id='demo-select-small'
                            options={block.elemParams ? block.elemParams : []}
                            noOptionsText={'Нет таких параметров'}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>, option: IElementPropsBase[]) => {
                                if (option.length === 0) {
                                    setParamsToDisplay([
                                        { name: e.target.value, description: e.target.value, value: '' },
                                    ]);
                                } else {
                                    setParamsToDisplay(option);
                                }
                            }}
                            isOptionEqualToValue={optionEqualToValue}
                            getOptionLabel={getOptionLabel}
                            renderInput={renderInput('')}
                            renderOption={(props, option: IElementPropsBase, state) => {
                                return (
                                    <li {...props} key={option.name}>
                                        {option.name}
                                    </li>
                                );
                            }}
                            filterOptions={filterOptions}
                            defaultValue={block.parametersToDisplay?.map((param) => ({
                                name: param,
                                description: '',
                                value: '',
                            }))}
                            renderTags={renderTag}
                        />
                    </SAutocomplete>
                </SDisplaySection>
            )}

            {block.elemProps && (
                <SDisplaySection>
                    <h5>Отображаемые Свойства</h5>
                    <SAutocomplete>
                        <Autocomplete
                            multiple
                            disableClearable
                            size={'small'}
                            id='demo-select-small'
                            options={block.elemProps ? block.elemProps : []}
                            noOptionsText={'Нет таких свойств'}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>, option: IElementPropsBase[]) => {
                                if (option.length === 0) {
                                    setPropsToDisplay([
                                        { name: e.target.value, description: e.target.value, value: '' },
                                    ]);
                                } else {
                                    setPropsToDisplay(option);
                                }
                            }}
                            isOptionEqualToValue={optionEqualToValue}
                            defaultValue={block.propertiesToDisplay?.map((prop) => ({
                                name: prop,
                                description: '',
                                value: '',
                            }))}
                            getOptionLabel={getOptionLabel}
                            renderInput={renderInput('')}
                            renderOption={(props, option: IElementPropsBase) => {
                                return (
                                    <li {...props} key={option.name}>
                                        {option.description}
                                    </li>
                                );
                            }}
                            renderGroup={renderGroup}
                            filterOptions={filterOptions}
                            renderTags={renderTag}
                            ListboxProps={renderDataTestId('propertiesToDisplay-box')}
                        />
                    </SAutocomplete>
                </SDisplaySection>
            )}

            <StyledButtons>
                <Button variant='secondary' onClick={handleClose}>
                    Отмена
                </Button>
                <Button onClick={handleSubmit(handleSaveClick)}>
                    {intl.formatMessage(messages[TranslationKey.SAVE])}
                </Button>
            </StyledButtons>
        </StyledForm>
    );
};
