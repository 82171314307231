import { FC } from 'react';

import styled from 'styled-components';

import { pxToRem, uiColors } from '@repeat/ui-kit';

const SEngineerContent = styled.div`
    padding: ${pxToRem(24)};
    overflow-y: auto;
    height: 100%;

    form {
        max-width: 400px;
    }

    h3 {
        color: ${uiColors.dark};
        margin-bottom: ${pxToRem(24)};
    }
`;

export const EngineerContent: FC<{ children: React.ReactNode }> = ({ children }) => (
    <SEngineerContent>{children}</SEngineerContent>
);
