import { createListenerMiddleware } from '@reduxjs/toolkit';

import { UserActions } from '@repeat/common-slices';

import { appendGraphsListeners } from './graphsListeners';
import { appendHotkeyListeners } from './hotkeyListener';
import { appendModelControlListeners } from './modelControlListeners';
import { appendProjectsListeners } from './projectListeners';
import { appendSchemaListeners } from './schemaListeners';
import { appendUndoRedoListener } from './undoRedoListener';
import { appendGoToMapListeners } from './workspace/goToMapListeners';
import { appendWorkspaceListeners } from './workspaceListeners';

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
    actionCreator: UserActions.logoutRequest,
    effect: (action, listenerApi) => {
        // @todo do cleaning stuff here instead of separate method doCleaningStuff()
    },
});

// appendAppListeners(listenerMiddleware);
appendProjectsListeners(listenerMiddleware);
appendWorkspaceListeners(listenerMiddleware);
appendGoToMapListeners(listenerMiddleware);
appendSchemaListeners(listenerMiddleware);
appendModelControlListeners(listenerMiddleware);
appendHotkeyListeners(listenerMiddleware);
appendUndoRedoListener(listenerMiddleware);
appendGraphsListeners(listenerMiddleware);

export default listenerMiddleware;
