import React, { FC } from 'react';

import styled from 'styled-components';

import { Icon, pxToRem, uiColors } from '@repeat/ui-kit';

const SNotFoundWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: ${pxToRem(128)};
`;

const SNotFoundIcon = styled.div`
    margin-right: ${pxToRem(16)};

    i {
        max-width: ${pxToRem(64)};
    }
`;

const SNotFoundText = styled.p`
    color: ${uiColors.darkGrey};
`;

interface INotFoundMessage {
    children: React.ReactNode;
}

export const NotFoundMessage: FC<INotFoundMessage> = ({ children }) => {
    return (
        <SNotFoundWrapper>
            <SNotFoundIcon>
                <Icon name={'empty'} fill={uiColors.darkGrey} />
            </SNotFoundIcon>
            <SNotFoundText>{children}</SNotFoundText>
        </SNotFoundWrapper>
    );
};
