import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { InputField } from '@repeat/ui-kit';

import { SSearchField } from './../components/SearchField/SSearchField';

interface Filter {
    query: string;
}

export const useSearch = (initialFilter: Filter) => {
    const [filter, setFilter] = useState<Filter>(initialFilter);

    const inputRef = useRef<HTMLInputElement>(null);

    const searchHandler = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setFilter({ ...filter, query: e.target.value });
            inputRef.current?.focus();
        },
        [filter]
    );

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [filter.query]);

    const SearchField = useMemo(() => {
        return ({ id }: { id: string }) => (
            <SSearchField>
                <InputField
                    id={id}
                    ref={inputRef}
                    placeholder={'Поиск блока по названию'}
                    value={filter.query}
                    onChange={searchHandler}
                />
            </SSearchField>
        );
    }, [filter.query, inputRef, searchHandler]);

    return { filter, setFilter, searchHandler, SearchField };
};
