import React, { FC } from 'react';

import styled from 'styled-components';

import { pxToRem, radius, uiColors } from 'libs/ui-kit/src/config/config';
import { verticalAlign } from 'libs/ui-kit/src/config/mixin';

const SMain = styled.main`
    padding: ${pxToRem(24)};
    background: ${uiColors.white};
    border-radius: ${radius.default};
    transition: margin 0.3s ease-in-out;
    max-height: 100%;
    overflow: scroll;
    scrollbar-width: none;
`;

const SLogo = styled.div`
    align-items: center;
    color: ${uiColors.white};
    display: flex;

    img {
        ${verticalAlign()};
        max-width: ${pxToRem(120)};
    }
`;

const SVersion = styled.sub`
    font-size: ${pxToRem(10)};
    color: ${uiColors.black};
    margin-left: ${pxToRem(8)};
`;

const Logo: FC<{ logo: string }> = ({ logo }) => <img src={logo} alt='REPEAT logo' />;

interface IMain {
    logoUrl?: string;
    version?: string;
    children?: React.ReactNode;
}

export const Main: FC<IMain> = ({ children, version = '1.0.0', logoUrl = '/assets/logo/repeat-horizontal-ru.png' }) => {
    return (
        <SMain>
            <SLogo>
                <Logo logo={logoUrl} />
                <SVersion>{version}</SVersion>
            </SLogo>
            {children}
        </SMain>
    );
};
