import { useEffect, useState } from 'react';

export const getIsDemoMode = () => {
    const demoValue = localStorage.getItem('demoProject') || 'false';
    return new RegExp(/true/).test(demoValue.toLowerCase());
};

export const useDemoMode = (watchOptions?: any[]) => {
    const demoValue = localStorage.getItem('demoProject') || 'false';
    const [isDemo, setIsDemo] = useState(new RegExp(/true/).test(demoValue.toLowerCase()));

    useEffect(() => {
        const demoValue = localStorage.getItem('demoProject') || 'false';
        setIsDemo(new RegExp(/true/).test(demoValue.toLowerCase()));
    }, Array.from(watchOptions || []));

    return {
        isDemo,
    };
};
