import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.PAGE_ENGINEER_TITLE]: {
        id: TranslationKey.PAGE_ENGINEER_TITLE,
        defaultMessage: ru[TranslationKey.PAGE_ENGINEER_TITLE],
    },
    [TranslationKey.PAGE_PROJECTS_TITLE]: {
        id: TranslationKey.PAGE_PROJECTS_TITLE,
        defaultMessage: ru[TranslationKey.PAGE_PROJECTS_TITLE],
    },
    [TranslationKey.BLOCK_NEW]: {
        id: TranslationKey.BLOCK_NEW,
        defaultMessage: ru[TranslationKey.BLOCK_NEW],
    },
    [TranslationKey.LIBRARYITEM_IMPORT_FILE_DESCRIPTION]: {
        id: TranslationKey.LIBRARYITEM_IMPORT_FILE_DESCRIPTION,
        defaultMessage: ru[TranslationKey.LIBRARYITEM_IMPORT_FILE_DESCRIPTION],
    },
    [TranslationKey.LIBRARYITEM_IMPORT_SELECT_FILE]: {
        id: TranslationKey.LIBRARYITEM_IMPORT_SELECT_FILE,
        defaultMessage: ru[TranslationKey.LIBRARYITEM_IMPORT_SELECT_FILE],
    },
    [TranslationKey.LIBRARYITEM_IMPORT_MODAL_TITLE]: {
        id: TranslationKey.LIBRARYITEM_IMPORT_MODAL_TITLE,
        defaultMessage: ru[TranslationKey.LIBRARYITEM_IMPORT_MODAL_TITLE],
    },
    [TranslationKey.PROJECT_EDIT_DROPDOWN]: {
        id: TranslationKey.PROJECT_EDIT_DROPDOWN,
        defaultMessage: ru[TranslationKey.PROJECT_EDIT_DROPDOWN],
    },
    [TranslationKey.PROJECT_DELETE_DROPDOWN]: {
        id: TranslationKey.PROJECT_DELETE_DROPDOWN,
        defaultMessage: ru[TranslationKey.PROJECT_DELETE_DROPDOWN],
    },
    [TranslationKey.ERROR_NEW_BLOCK_NAME]: {
        id: TranslationKey.ERROR_NEW_BLOCK_NAME,
        defaultMessage: ru[TranslationKey.ERROR_NEW_BLOCK_NAME],
    },
    [TranslationKey.ERROR_BLOCK_NAME_IS_EMPTY]: {
        id: TranslationKey.ERROR_BLOCK_NAME_IS_EMPTY,
        defaultMessage: ru[TranslationKey.ERROR_BLOCK_NAME_IS_EMPTY],
    },
    [TranslationKey.ENGINEER_CLEAR_ALL]: {
        id: TranslationKey.ENGINEER_CLEAR_ALL,
        defaultMessage: ru[TranslationKey.ENGINEER_CLEAR_ALL],
    },
    [TranslationKey.PROJECT_LIBRARY]: {
        id: TranslationKey.PROJECT_LIBRARY,
        defaultMessage: ru[TranslationKey.PROJECT_LIBRARY],
    },
    [TranslationKey.ENGINEER_COLUMN_TYPE]: {
        id: TranslationKey.ENGINEER_COLUMN_TYPE,
        defaultMessage: ru[TranslationKey.ENGINEER_COLUMN_TYPE],
    },
    [TranslationKey.ENGINEER_COLUMN_NAME]: {
        id: TranslationKey.ENGINEER_COLUMN_NAME,
        defaultMessage: ru[TranslationKey.ENGINEER_COLUMN_NAME],
    },
    [TranslationKey.ENGINEER_EXPORT_BlOCK]: {
        id: TranslationKey.ENGINEER_EXPORT_BlOCK,
        defaultMessage: ru[TranslationKey.ENGINEER_EXPORT_BlOCK],
    },
    [TranslationKey.SAVE]: {
        id: TranslationKey.SAVE,
        defaultMessage: ru[TranslationKey.SAVE],
    },
});
