export const increaseUserBlocksCount = (obj: { [blockId: string]: number }, blockIds: string[]) => {
    blockIds.forEach((blockId) => {
        if (Object.prototype.hasOwnProperty.call(obj, blockId)) {
            obj[blockId] += 1;
        } else {
            obj[blockId] = 1;
        }
    });
    return obj;
};

export const decreaseUserBlocksCount = (obj: { [blockId: string]: number }, blockIds: string[]) => {
    blockIds.forEach((blockId) => {
        if (Object.prototype.hasOwnProperty.call(obj, blockId)) {
            obj[blockId] -= 1;
        }
    });

    Object.keys(obj).forEach((key) => {
        if (obj[key] === 0) {
            delete obj[key];
        }
    });
    return obj;
};
