import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.ELEMENT_NOT_SUPPORTED]: {
        id: TranslationKey.ELEMENT_NOT_SUPPORTED,
        defaultMessage: ru[TranslationKey.ELEMENT_NOT_SUPPORTED],
    },
    [TranslationKey.WORKSPACE_ERROR_GETTING_PROJECT_DATA]: {
        id: TranslationKey.WORKSPACE_ERROR_GETTING_PROJECT_DATA,
        defaultMessage: ru[TranslationKey.WORKSPACE_ERROR_GETTING_PROJECT_DATA],
    },
    [TranslationKey.WORKSPACE_WARNING_PROJECT_CHANGED]: {
        id: TranslationKey.WORKSPACE_WARNING_PROJECT_CHANGED,
        defaultMessage: ru[TranslationKey.WORKSPACE_WARNING_PROJECT_CHANGED],
    },
    [TranslationKey.ELEMENT_HAS_NO_GOTO_PAIR]: {
        id: TranslationKey.ELEMENT_HAS_NO_GOTO_PAIR,
        defaultMessage: ru[TranslationKey.ELEMENT_HAS_NO_GOTO_PAIR],
    },
    [TranslationKey.ELEMENT_HAS_NOT_UNIQUE_GOTO]: {
        id: TranslationKey.ELEMENT_HAS_NOT_UNIQUE_GOTO,
        defaultMessage: ru[TranslationKey.ELEMENT_HAS_NOT_UNIQUE_GOTO],
    },
    [TranslationKey.SCHEMA_ELEMENT_IS_DEPRECATED]: {
        id: TranslationKey.SCHEMA_ELEMENT_IS_DEPRECATED,
        defaultMessage: ru[TranslationKey.SCHEMA_ELEMENT_IS_DEPRECATED],
    },
});
