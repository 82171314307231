import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { ApplicationActions } from '@repeat/common-slices';
import { useAppDispatch, useAppSelector, useProjectId } from '@repeat/hooks';
import { ModalTypes, Statuses } from '@repeat/models';
import { TranslationKey } from '@repeat/translations';
import { Button, Loader, Modal } from '@repeat/ui-kit';

import { blockOptionsMessages as messages } from './translation';

import { getProjects, workspaceSelectors } from '@repeat/store';
import { TUserBlockData } from 'libs/models/src/lib/element';
import { TUserBlockSaveModal } from 'libs/models/src/lib/modal';
import { addUserBlock } from 'libs/repeat/store/src/lib/slices/workspace/userBlocks/userBlocksSlice';
import { UserBlockForm } from '../Project/UserBlockForm/UserBlockForm';

export const UserBlockSaveModal = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const { projectId } = useProjectId() || { projectId: null };

    const data = useAppSelector((state) => state.app.modals[0]) as TUserBlockSaveModal;
    const status = useAppSelector((state) => state.workspace.userBlocks.addUserBlock.status);

    const subLibraries = useAppSelector(workspaceSelectors.customSubLibraries);

    const {
        data: { element, groups },
    } = data;
    const group = groups.find((group) => group.id.toString() === element.id);
    const groupName = group?.name || '';

    const [userData, setUserData] = useState<TUserBlockData>({
        name: groupName,
        subLibrary: subLibraries[0] || groupName,
        image: '',
    });

    const handleClose = () => {
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.USER_BLOCK_SAVE }));
    };

    const handleAdd = () => {
        dispatch(addUserBlock(element, userData));
    };

    const [isValidForm, setIsValidForm] = useState(false);
    const projectsState = useAppSelector((state) => state.projects);
    const isProjectsInitialized = projectsState.status === Statuses.SUCCEEDED;
    useEffect(() => {
        if (isProjectsInitialized === false) {
            dispatch(getProjects());
        }
    }, [dispatch, isProjectsInitialized]);

    const handleFormValidate = (isValid: boolean) => {
        setIsValidForm(isValid);
    };

    const FooterButtons = () => (
        <>
            <Button variant='secondary' onClick={handleClose}>
                {intl.formatMessage(messages[TranslationKey.CANCEL])}
            </Button>
            <Button onClick={handleAdd} disabled={!isValidForm || status === Statuses.LOADING}>
                {intl.formatMessage(messages[TranslationKey.SAVE])}
            </Button>
        </>
    );

    return (
        <Modal
            id={ModalTypes.USER_BLOCK_SAVE}
            title={'Новый блок'}
            isOpen={true}
            hasOverlay={true}
            onClose={handleClose}
            type='default'
            size='big'
            footer={<FooterButtons />}
        >
            {projectsState.status === Statuses.LOADING && <Loader />}
            {projectsState.status === Statuses.FAILED && (
                <span>{intl.formatMessage(messages[TranslationKey.WORKSPACE_ERROR_GETTING_PROJECTS_LIST])}</span>
            )}
            <UserBlockForm onValid={handleFormValidate} groupName={groupName} data={userData} setData={setUserData} />
        </Modal>
    );
};
