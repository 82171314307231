import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { ApplicationActions } from '@repeat/common-slices';
import { getIsDemoMode, useAppDispatch, useAppSelector, useDemoMode, useProjectId } from '@repeat/hooks';
import { ModalTypes, Project, SchemaItemTypes, TModal, WorkspaceModes } from '@repeat/models';
import { closeProject, saveProject, workspaceActions, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { IconButton, LoaderDefault, Tooltip, uiColors } from '@repeat/ui-kit';

import { MenuCodeGenerationItem } from './MenuCodeGenerationItem';
import { MenuGetNoNameItem } from './MenuGetNoNameItem';
import { MenuReadOnlyModeItem } from './MenuReadOnlyModeItem';
import { SProjectName, SWorkspaceProjectName } from './SWorkspaceProjectMenu';
import { messages } from './translation';

import { editUserBlock } from 'libs/repeat/store/src/lib/slices/workspace/userBlocks/userBlocksSlice';
import { AbilityContext } from '../../../context/Can';
import { useProjectScreenshot } from '../../Project/hooks/useProjectScreenshot';
import { MenuBreadCrumbs } from './MenuBreadCrumbs';

export const WorkspaceProjectMenu: FC<{ noSave?: boolean; noClose?: boolean }> = ({
    noSave = false,
    noClose = false,
}) => {
    const { formatMessage } = useIntl();

    const ability = useContext(AbilityContext);
    const isCodeGenAvailable = ability.can('use', `tool-CODE_GENERATION`);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { projectId } = useProjectId();

    const [currentProjectName, setCurrentProjectName] = useState<string>('');
    const [path, setPath] = useState<{ id: string | null; name: string }[]>([]);

    const { mode, elementId } = useAppSelector((state) => state.workspace.meta);
    const libraryType = useAppSelector(workspaceSelectors.libraryType);
    const solverType = useAppSelector(workspaceSelectors.solverType);
    const node = useAppSelector(workspaceSelectors.getSchemaNodeById(elementId));
    const selectedItemsCount = useAppSelector(workspaceSelectors.schemaSelectedItemsCount);
    const schemaGroups = useAppSelector(workspaceSelectors.schemaGroups);
    const userBlockGroups = useAppSelector((state) => state.workspace.schema.schemaItems.userBlockGroups);
    const workspaceMetaUserBlockId = useAppSelector(workspaceSelectors.workspaceMetaUserBlockId);
    const workspaceMode = useAppSelector(workspaceSelectors.workspaceMode);
    const isUserBlockEditorMode = useAppSelector(workspaceSelectors.isUserBlockEditorMode);
    const userBlockName = localStorage.getItem('userBlockName');

    const { isDemo } = useDemoMode([workspaceMode, workspaceMetaUserBlockId]);
    const isDemoMode = getIsDemoMode();

    const createIdArray = () => {
        const result = [];
        let parentId = elementId;
        if (!schemaGroups) {
            return [];
        }

        const findNode = (parentId: string) => {
            if (isDemoMode && userBlockGroups) {
                return (
                    userBlockGroups.find((obj) => obj.id.toString() === parentId) ||
                    userBlockGroups.find((group) => group.parentGroupId === null)
                );
            }
            if (userBlockName) {
                // режим редактирования пользовательского блока
                return schemaGroups
                    .filter((group) => group.parentGroupId !== null)
                    .find((obj) => obj.id.toString() === parentId);
            }
            return schemaGroups.find((obj) => obj.id.toString() === parentId);
        };

        while (parentId !== null) {
            const node = findNode(parentId);
            if (node) {
                result.push({ id: node.id.toString(), name: node.name });
                parentId = node.parentGroupId;
            } else {
                break;
            }
        }

        return result;
    };

    const { handleScreenShot, handleDownloadScreenshot, isDownloading, isSaving } = useProjectScreenshot();

    useEffect(() => {
        if ((workspaceMode === WorkspaceModes.MAIN && workspaceMetaUserBlockId) || userBlockName) {
            setCurrentProjectName(userBlockName || '');
        } else {
            setCurrentProjectName(localStorage.getItem('currentProjectName') || '');
        }
    }, [workspaceMode]);

    useEffect(() => {
        setPath(createIdArray().reverse());
    }, [elementId, userBlockGroups]);

    const handleSaveClick = async () => {
        if (isUserBlockEditorMode) {
            dispatch(editUserBlock());
        } else {
            if (projectId && solverType && libraryType) {
                await dispatch(saveProject(projectId, libraryType, solverType));
                handleScreenShot(projectId);
            }
        }
    };
    const handleSaveNewClick = (project: Pick<Project, 'projectName' | 'projectId'>) => {
        const modal: TModal = {
            type: ModalTypes.PROJECT_SAVE_NEW,
            data: { project },
        };
        dispatch(ApplicationActions.showModal({ modal }));
    };

    const handleCloseClick = async () => {
        await dispatch(closeProject());
        if (isDemo) {
            return navigate('/projects/demo');
        }
        return navigate('/projects');
    };

    const handleProjectNameClick = useCallback(() => {
        dispatch(workspaceActions.changeWorkspaceMode({ mode: WorkspaceModes.MAIN, userBlockId: null }));
        localStorage.setItem('demoProject', 'false');
    }, []);

    const handleGroupNameClick = (el: string) => {
        dispatch(
            workspaceActions.changeWorkspaceMode({
                mode: WorkspaceModes.GROUP,
                elementId: el,
                groupId: el,
            })
        );
    };

    const handleClearSelection = () => {
        dispatch(workspaceActions.setSelectedItems({ ids: [], type: SchemaItemTypes.NODE }));
    };

    if (currentProjectName) {
        return (
            <>
                {mode !== WorkspaceModes.CODE_EDITOR && mode !== WorkspaceModes.SUBMODEL && (
                    <Tooltip text={formatMessage(messages[TranslationKey.BACK_TO_PROJECTS])}>
                        <IconButton fill={uiColors.darkGrey} noHover onClick={handleCloseClick} name={'arrowLeft'} />
                    </Tooltip>
                )}
                {mode !== WorkspaceModes.GROUP && (
                    <SWorkspaceProjectName data-tour='workspace-project-name' noSave={noSave}>
                        {mode !== WorkspaceModes.CODE_EDITOR &&
                            mode !== WorkspaceModes.SUBMODEL &&
                            selectedItemsCount > 0 && (
                                <SProjectName onClick={handleClearSelection}>{currentProjectName}</SProjectName>
                            )}
                        {mode !== WorkspaceModes.CODE_EDITOR &&
                            mode !== WorkspaceModes.SUBMODEL &&
                            selectedItemsCount === 0 &&
                            currentProjectName}
                        {mode === WorkspaceModes.CODE_EDITOR && node && (
                            <>
                                <SProjectName onClick={handleProjectNameClick}>{currentProjectName}</SProjectName>
                                &nbsp;/&nbsp;
                                {node.data.shortName} [{node.data.index}]
                            </>
                        )}
                        {mode === WorkspaceModes.SUBMODEL && node && (
                            <>
                                <SProjectName onClick={handleProjectNameClick}>{currentProjectName}</SProjectName>
                                &nbsp;/&nbsp;
                                {node.data.shortName} [{node.data.index}]
                            </>
                        )}
                        {mode === WorkspaceModes.CODE_EDITOR && !node && (
                            <>
                                <SProjectName onClick={handleProjectNameClick}>{currentProjectName}</SProjectName>
                                &nbsp;/&nbsp;
                                {formatMessage(messages[TranslationKey.SCHEMA_ELEMENT_DELETED])}
                            </>
                        )}
                    </SWorkspaceProjectName>
                )}
                {mode === WorkspaceModes.GROUP && (
                    <MenuBreadCrumbs
                        handleProjectNameClick={handleProjectNameClick}
                        handleGroupNameClick={handleGroupNameClick}
                        projectName={currentProjectName}
                        path={path}
                    />
                )}
                {!noSave && !isDemo && (
                    <>
                        <Tooltip text={formatMessage(messages[TranslationKey.SAVE])}>
                            {!isSaving && (
                                <IconButton
                                    data-tour='workspace-project-save'
                                    fill={uiColors.darkGrey}
                                    noHover
                                    name='save'
                                    onClick={handleSaveClick}
                                />
                            )}
                            {isSaving && <LoaderDefault width={28} height={26} color={'var(--ui-text)'} />}
                        </Tooltip>
                        {!workspaceMetaUserBlockId && (
                            <>
                                <Tooltip text={formatMessage(messages[TranslationKey.SAVE_AS])}>
                                    <IconButton
                                        fill={uiColors.darkGrey}
                                        noHover
                                        name='saveEdit'
                                        onClick={() =>
                                            projectId &&
                                            handleSaveNewClick({ projectName: currentProjectName, projectId })
                                        }
                                    />
                                </Tooltip>
                                <Tooltip text={formatMessage(messages[TranslationKey.SCHEMA_SCREENSHOT])}>
                                    {!isDownloading && (
                                        <IconButton
                                            fill={uiColors.darkGrey}
                                            noHover
                                            name='screenShot'
                                            onClick={() => {
                                                handleDownloadScreenshot();
                                            }}
                                        />
                                    )}
                                    {isDownloading && <LoaderDefault width={28} height={26} color={'var(--ui-text)'} />}
                                </Tooltip>

                                {projectId && (
                                    <>
                                        {isCodeGenAvailable && (
                                            <MenuCodeGenerationItem
                                                projectId={projectId}
                                                projectName={currentProjectName}
                                            />
                                        )}
                                        <MenuGetNoNameItem projectId={projectId} projectName={currentProjectName} />
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
                {(isDemo || workspaceMode === WorkspaceModes.SUBMODEL) && <MenuReadOnlyModeItem />}
            </>
        );
    }

    return null;
};
