import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import { pxToRem, uiColors } from '../../config/config';
import { sanitizeHTML } from '../../config/helpers';
import { useClickOutside } from '../../hooks/useClickOutside';
import { IconButton } from '../IconButton/IconButton';

interface IRects {
    bottom: number;
    height: number;
    left: number;
    right: number;
    top: number;
    width: number;
    x: number;
    y: number;
}
interface Option {
    [key: string]: string;
}

interface ISelectProps {
    options: Option[];
    placeholder: string;
    noOptionsText: string;
    categoryValue?: string;
    optionValue: string;
    optionKey: string;
    onSelectOption: (option: Option) => void;
    disabledKeys: string[];
    style?: any;
    name?: string;
    onChange: (event: React.ChangeEvent) => void;
}
interface ISelectedOption {
    isSelected: boolean;
}
interface IOptionsList {
    isOpen: boolean;
}
const SSelect = styled.div`
    margin-right: ${pxToRem(8)};
    flex: 1 1 100%;
    position: relative;
`;

const SGroupTitle = styled.div`
    background: ${uiColors.lightGrey};
    color: ${uiColors.darkGrey};
    padding: ${pxToRem(8)};
`;

const SSelectedOption = styled.div<ISelectedOption>`
    width: 100%;
    border: 1px solid ${uiColors.grey};
    border-radius: ${pxToRem(4)};
    min-height: ${pxToRem(40)};
    padding-left: ${pxToRem(16)};
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({ isSelected }) => (isSelected ? uiColors.black : uiColors.darkGrey)};
`;

const SOptionsList = styled.ul<IOptionsList>`
    min-height: ${pxToRem(40)};
    max-height: ${pxToRem(176)};
    border: 1px solid ${uiColors.grey};
    border-radius: ${pxToRem(4)};
    background: ${uiColors.white};
    position: fixed;
    overflow-y: auto;
    visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
`;

const SOption = styled.li<{ isDisabled: boolean }>`
    cursor: ${({ isDisabled }) => !isDisabled && 'pointer'};
    padding: ${pxToRem(4)} ${pxToRem(8)};
    font-size: ${pxToRem(12)};
    color: ${({ isDisabled }) => isDisabled && uiColors.grey};
    &:hover {
        background: ${({ isDisabled }) => !isDisabled && uiColors.grey};
    }
`;
const SButtons = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: ${pxToRem(24)};
    display: flex;
`;

const SIconButton = styled(IconButton)<IOptionsList>`
    > i:last-child {
        transform: ${({ isOpen }) => (!isOpen ? '' : 'rotate(180deg)')};
    }
`;

export const Select: React.FC<ISelectProps> = ({
    options,
    placeholder,
    noOptionsText,
    categoryValue,
    optionValue,
    optionKey,
    onSelectOption,
    disabledKeys,
    ...rest
}) => {
    const [inputValue, setInputValue] = useState('');
    const [filteredOptions, setFilteredOptions] = useState<{
        [key: string]: Option[];
    }>({});
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const elementRef = useRef<HTMLDivElement | null>(null);
    const selectedOptionRef = useRef<HTMLDivElement | null>(null);
    const optionsListRef = useRef<HTMLUListElement | null>(null);

    useEffect(() => {
        const newFilteredOptions: {
            [key: string]: Option[];
        } = {};

        options.forEach((option) => {
            if (categoryValue) {
                if (option[categoryValue] in newFilteredOptions) {
                    newFilteredOptions[option[categoryValue]].push(option);
                } else {
                    newFilteredOptions[option[categoryValue]] = [option];
                }
            }
        });

        setFilteredOptions(newFilteredOptions);
    }, [options]);

    useEffect(() => {
        if (
            selectedOptionRef !== null &&
            optionsListRef !== null &&
            selectedOptionRef.current &&
            optionsListRef.current
        ) {
            const currentSelectedOption = selectedOptionRef.current && selectedOptionRef.current;
            const currentOptionsList = optionsListRef.current && optionsListRef.current;

            const selectedOptionRects: IRects = currentSelectedOption && currentSelectedOption.getBoundingClientRect();
            const optionsListRects: IRects = currentOptionsList && currentOptionsList.getBoundingClientRect();

            if (selectedOptionRects !== null && currentOptionsList) {
                if (document.documentElement.clientHeight - selectedOptionRects.bottom <= optionsListRects.height) {
                    currentOptionsList.style.top = `${selectedOptionRects.top - optionsListRects.height}px`;
                } else {
                    currentOptionsList.style.top = `${selectedOptionRects.bottom}px`;
                }
                currentOptionsList.style.width = `${selectedOptionRects.width}px`;
            }
        }
    }, [isOpen]);

    useClickOutside({
        elementRef,
        condition: isOpen,
        callback: () => {
            setIsOpen(false);
        },
    });

    const handleOptionChange = (option: Option) => {
        setInputValue(option[optionValue]);
        onSelectOption(option);
        setIsOpen(false);
    };
    const value = inputValue === '' ? placeholder : inputValue;
    const isSelected = value === placeholder ? false : true;

    return (
        <SSelect ref={elementRef} style={rest.style}>
            <SSelectedOption
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
                isSelected={isSelected}
                ref={selectedOptionRef}
                dangerouslySetInnerHTML={sanitizeHTML(value)}
            />
            <SButtons>
                {inputValue !== '' && (
                    <SIconButton
                        noHover
                        name={'close'}
                        size='medium'
                        isOpen={isOpen}
                        onClick={(event) => {
                            event.stopPropagation();
                            setInputValue('');
                        }}
                    />
                )}
                <SIconButton
                    noHover
                    name={'chevron'}
                    size='big'
                    isOpen={isOpen}
                    onClick={(event) => {
                        event.stopPropagation();
                        setIsOpen(!isOpen);
                    }}
                />
            </SButtons>
            {Object.entries(filteredOptions).length !== 0 && (
                <SOptionsList ref={optionsListRef} isOpen={isOpen}>
                    {Object.entries(filteredOptions).map(([groupTitle, optionGroup]) => (
                        <li key={groupTitle}>
                            <SGroupTitle>{groupTitle}</SGroupTitle>
                            <ul>
                                {optionGroup.map((option) => (
                                    <SOption
                                        isDisabled={disabledKeys.includes(option[optionKey])}
                                        key={option[optionKey]}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            if (!disabledKeys.includes(option[optionKey])) {
                                                handleOptionChange(option);
                                            }
                                        }}
                                        dangerouslySetInnerHTML={sanitizeHTML(option[optionValue])}
                                    />
                                ))}
                            </ul>
                        </li>
                    ))}
                </SOptionsList>
            )}
            {Object.entries(filteredOptions).length === 0 && (
                <SOptionsList ref={optionsListRef} isOpen={isOpen}>
                    <SSelectedOption isSelected={false}>{noOptionsText}</SSelectedOption>
                </SOptionsList>
            )}
        </SSelect>
    );
};
