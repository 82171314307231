import { useCallback, useEffect, useState } from 'react';
import Drawer from 'react-modern-drawer';

import 'react-modern-drawer/dist/index.css';

import { Figure } from 'react-plotly.js';

import { Annotations } from 'plotly.js';

import { chartTitle } from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { EChartItemType, IChartItem, IChartItemType, ModelStatus, ModelStatuses } from '@repeat/models';
import { workspaceActions } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { Alert, AlertType, Button, IconButton, Layout, Main, ShowMore, Sidebar } from '@repeat/ui-kit';

import { useIntl } from 'react-intl';

import { messages } from './../translation';
import { ChartAnnotations } from './Annotations';
import { ConnectedPlotlyChart } from './PlotlyChart/ConnectedPlotlyChart';
import {
    SChartContent,
    SChartToolName,
    SChartTools,
    SChartsList,
    SChartsListItem,
    SHeader,
    SHeaderTitle,
    STrendWrapper,
    STrendWrapperBox,
} from './SChartsEditor';

const defaultPlotLayout: Partial<Plotly.Layout> = {
    title: '',
    annotations: [],
    showlegend: true,
    legend: {
        xanchor: 'left',
        yanchor: 'bottom',
        x: 0,
        y: -0.5,
        orientation: 'v',
    },
    xaxis: {
        title: '',
    },
    yaxis: {
        title: '',
    },
    font: {
        family: 'RosAtom, sans-serif',
    },
    autosize: true,
};

export const ChartsEditor = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const {
        editor: { isOpen, currentGraphUuid },
        charts,
    } = useAppSelector((state) => state.workspace.graphs);
    const { modelStatus } = useAppSelector((state) => state.workspace.modelControl);

    const [isInitialized, setIsInitialized] = useState(false);
    const [selectedChart, setSelectedChart] = useState<IChartItem | null>(null);

    useEffect(() => {
        if (isOpen) {
            let foundChart = Object.values(charts).find((chart: IChartItem) => chart.uuid === currentGraphUuid) || null;
            if (!foundChart?.layout) {
                foundChart = {
                    ...foundChart,
                    layout: { ...defaultPlotLayout },
                };
            }

            // https://stackoverflow.com/questions/76126033/how-to-use-redux-with-react-plotly-js
            const clonedChart = structuredClone(foundChart);
            setSelectedChart(clonedChart);

            setIsInitialized(true);
        }
    }, [currentGraphUuid, isOpen]);

    const handleClose = useCallback(() => {
        setIsInitialized(false);
        dispatch(workspaceActions.closeChartsEditor());
    }, []);

    const handleChartChange = useCallback(
        (uuid: string) => {
            if (uuid !== currentGraphUuid) {
                setIsInitialized(false);
                dispatch(workspaceActions.changeEditorChart({ uuid }));
            }
        },
        [currentGraphUuid]
    );

    const handleChartLayoutUpdate = useCallback((uuid: string, figure: Figure) => {
        dispatch(workspaceActions.updateChartEditorLayout({ uuid, figure: structuredClone(figure) }));
    }, []);

    const handleChartAnnotationDelete = useCallback(
        (index: number) => {
            if (!selectedChart?.layout) {
                return;
            }

            const annotations = selectedChart.layout?.annotations || [];
            const updatingSelectedChart = {
                ...selectedChart,
                layout: {
                    ...selectedChart.layout,
                    annotations: [...annotations.slice(0, index), ...annotations.slice(index + 1)],
                },
            };
            setSelectedChart(updatingSelectedChart);
        },
        [selectedChart]
    );

    const handleChartAnnotationAdd = useCallback(() => {
        if (!selectedChart?.layout) {
            return;
        }

        const annotations = selectedChart.layout?.annotations || ([] as Partial<Annotations>[]);
        const updatingSelectedChart = {
            ...selectedChart,
            layout: {
                ...selectedChart.layout,
                annotations: [...annotations, { text: 'test' }],
            },
        };
        setSelectedChart(updatingSelectedChart);
    }, [selectedChart]);

    const chartTypeName = useCallback((type: IChartItemType | null) => {
        if (type === EChartItemType.YFromX) {
            return intl.formatMessage(messages[TranslationKey.WORKSPACE_CHART_TYPE_Y_X]);
        }
        return intl.formatMessage(messages[TranslationKey.WORKSPACE_CHART_TYPE_Y_T]);
    }, []);

    const isEditable = useCallback((modelStatus: ModelStatus) => {
        return !modelStatus || ![ModelStatuses.RUN].includes(modelStatus);
    }, []);

    const defaultTitle = useCallback(
        (chart: IChartItem) =>
            `${intl.formatMessage(messages[TranslationKey.CHART])} ${chartTypeName(chart.type)}. ${intl.formatMessage(
                messages[TranslationKey.PARAMETERS]
            )}: ${chart.YParameters.length}`,
        []
    );

    return (
        <Drawer open={isOpen} onClose={handleClose} direction='right' size={'90vw'}>
            <Layout internal withRightSidebar withFooter>
                <SHeader>
                    <SHeaderTitle>{intl.formatMessage(messages[TranslationKey.WORKSPACE_CHART_EDITOR])}</SHeaderTitle>
                    <IconButton
                        noHover
                        name='close'
                        onClick={() => {
                            handleClose();
                        }}
                    />
                </SHeader>
                <Sidebar canHide={false}>
                    <SChartsList>
                        {Object.values(charts).map((chart: IChartItem, index: number) => (
                            <SChartsListItem isActive={chart.uuid === selectedChart?.uuid} key={`chart-item-${index}`}>
                                <div onClick={() => handleChartChange(chart.uuid)} key={chart.uuid}>
                                    {`${index + 1}. ${chartTitle(chart?.layout?.title, defaultTitle(chart))}`}
                                </div>
                            </SChartsListItem>
                        ))}
                    </SChartsList>
                </Sidebar>
                <Sidebar position={'RIGHT'} canHide={false}>
                    <SChartTools>
                        {isInitialized && selectedChart && (
                            <ShowMore>
                                <SChartToolName>
                                    {intl.formatMessage(messages[TranslationKey.WORKSPACE_CHART_EDITOR_ANNOTATIONS])}
                                </SChartToolName>
                                <Button
                                    onClick={handleChartAnnotationAdd}
                                    size={'small'}
                                    disabled={!isEditable(modelStatus)}
                                >
                                    {intl.formatMessage(
                                        messages[TranslationKey.WORKSPACE_CHART_EDITOR_ANNOTATIONS_ADD]
                                    )}
                                </Button>
                                <ChartAnnotations
                                    isEditable={isEditable(modelStatus)}
                                    layout={selectedChart.layout as Partial<Plotly.Layout>}
                                    onDelete={handleChartAnnotationDelete}
                                />
                            </ShowMore>
                        )}
                    </SChartTools>
                </Sidebar>
                <Main>
                    <SChartContent>
                        <STrendWrapperBox>
                            {isInitialized && selectedChart && (
                                <STrendWrapper>
                                    {!isEditable(modelStatus) && (
                                        <Alert type={AlertType.WARNING}>
                                            {intl.formatMessage(
                                                messages[TranslationKey.WORKSPACE_CHART_EDITOR_NOT_EDITABLE]
                                            )}
                                        </Alert>
                                    )}
                                    <ConnectedPlotlyChart
                                        chart={selectedChart}
                                        isEditable={isEditable(modelStatus)}
                                        onUpdate={(figure: Figure) => {
                                            handleChartLayoutUpdate(currentGraphUuid as string, figure);
                                        }}
                                    />
                                </STrendWrapper>
                            )}
                        </STrendWrapperBox>
                    </SChartContent>
                </Main>
            </Layout>
        </Drawer>
    );
};
