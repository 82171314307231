import { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { MenuItem } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

import { createSupportTicket } from '@repeat/common-thunks';
import {
    API_BAD_REQUEST_CODE,
    CREATE_TICKET_TO_TECHNICAL_SUPPORT,
    EMAIL_PATTERN,
    LOGIN_PATTERN,
    validateEmptySpaces,
} from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import {
    IValidationError,
    TechnicalSupportSubject,
    TTechnicalSupportTicket,
    ValidationErrorCode,
} from '@repeat/models';
import { TranslationKey } from '@repeat/translations';
import { InputField, LowAccent, pxToRem, TextAreaField } from '@repeat/ui-kit';

import { SNetworkErrorBlock, StyledForm } from './SComponents';
import { messages } from './translation';

export const TechnicalSupportForm: FC<
    Pick<TTechnicalSupportTicket, 'userName' | 'email'> & {
        onValid: (isValid: boolean) => void;
        onLoad: (isLoading: boolean) => void;
    }
> = ({ userName, email, onValid, onLoad }) => {
    const [networkError, setNetworkError] = useState(false);
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();
    const {
        register,
        handleSubmit,
        reset,
        setError,
        formState: { errors, isValid },
    } = useForm<TTechnicalSupportTicket>({ mode: 'onChange' });
    const supportSubjects = useMemo(() => {
        return [
            {
                subject: TechnicalSupportSubject.FEATURES_CONSULT,
                titleKey: TranslationKey.FEATURES_SUPPORT_VALUE,
            },
            {
                subject: TechnicalSupportSubject.TARIFF_CONSULT,
                titleKey: TranslationKey.TARIFF_SUPPORT_VALUE,
            },
            {
                subject: TechnicalSupportSubject.AUTH_HELP,
                titleKey: TranslationKey.AUTH_SUPPORT_VALUE,
            },
            {
                subject: TechnicalSupportSubject.OTHER_HELP,
                titleKey: TranslationKey.OTHER_SUPPORT_VALUE,
            },
        ];
    }, []);
    const isUserAuthenticated = useAppSelector((state) => state.appUser.isAuth);

    useEffect(() => {
        onValid(isValid);
    }, [isValid]);

    const onRequest = () => {
        onLoad(true);
        setNetworkError(false);
    };

    const onSuccess = () => {
        onLoad(false);
        reset();
    };

    const onError = (httpStatus: number, errors: IValidationError[] | []) => {
        onLoad(false);
        if (httpStatus === API_BAD_REQUEST_CODE) {
            errors &&
                errors.forEach(({ field, code }) => {
                    const errorType =
                        (field === 'email' && code === ValidationErrorCode.EMAIL_PATTERN && 'pattern') ||
                        (field === 'message' && code === ValidationErrorCode.MESSAGE_TOO_BIG && 'maxLength') ||
                        'required';
                    setError(field, { type: errorType });
                });
        } else {
            setNetworkError(true);
        }
    };

    const handleCreateClick = (data: TTechnicalSupportTicket) => {
        dispatch(createSupportTicket(data, onRequest, onSuccess, onError));
    };

    const inputEmailErrors =
        (errors.email?.type === 'required' && true) ||
        (errors.email?.type === 'pattern' && formatMessage(messages[TranslationKey.MALFORMED_EMAIL_MSG]));

    const inputUserNameErrors =
        errors.userName?.type === 'pattern' && formatMessage(messages[TranslationKey.NAME_ERROR_MSG]);

    const inputMessageErrors =
        errors.message?.type === 'maxLength' &&
        formatMessage(messages[TranslationKey.TOO_MANY_ERROR_MSG], { num: 2046 });

    return (
        <StyledForm onSubmit={handleSubmit(handleCreateClick)} id={CREATE_TICKET_TO_TECHNICAL_SUPPORT}>
            <InputField
                error={!!errors?.userName}
                errorText={inputUserNameErrors}
                id='userName'
                label={formatMessage(messages[TranslationKey.NAME])}
                defaultValue={userName}
                maxLength={20}
                {...register('userName', {
                    pattern: LOGIN_PATTERN,
                    validate: validateEmptySpaces,
                })}
            />
            <InputField
                error={!!errors?.email}
                errorText={inputEmailErrors}
                id='email'
                type='email'
                label={formatMessage(messages[TranslationKey.EMAIL])}
                defaultValue={email}
                maxLength={100}
                {...register('email', { required: true, pattern: EMAIL_PATTERN })}
            />
            {!isUserAuthenticated && (
                <>
                    <InputLabel size='normal' id='support-subject-select' style={{ fontSize: pxToRem(14) }}>
                        {formatMessage(messages[TranslationKey.SUPPORT_SUBJECT])}
                    </InputLabel>
                    <Select size='small' {...register('subject', { required: true })} id='support-subject-select'>
                        {supportSubjects.map((subject) => (
                            <MenuItem value={subject.subject} key={`ts-${subject.subject}`}>
                                {formatMessage({ id: subject.titleKey })}
                            </MenuItem>
                        ))}
                    </Select>
                </>
            )}

            <TextAreaField
                id='message'
                placeholder={formatMessage(messages[TranslationKey.ENTER_MESSAGE])}
                error={!!errors?.message}
                errorText={inputMessageErrors}
                label={formatMessage(messages[TranslationKey.MESSAGE])}
                {...register('message', {
                    validate: validateEmptySpaces,
                    maxLength: 2048,
                })}
            />
            {isUserAuthenticated && (
                <LowAccent>{formatMessage(messages[TranslationKey.TECHNICAL_SUPPORT_CAPTION])}</LowAccent>
            )}
            {networkError && (
                <SNetworkErrorBlock>
                    {formatMessage(messages[TranslationKey.ERROR_SEND_MESSAGE_TO_SUPPORT])}
                </SNetworkErrorBlock>
            )}
        </StyledForm>
    );
};
