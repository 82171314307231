export const Typography = `
    h1, h2, h3, h4, h5, h6 {
        font-weight: bold;
        display: block;
        margin-start: 0;
        margin-end: 0;
        margin-bottom: 1rem;
    }

    h1 {
        display: block;
        font-size: 3rem;
        line-height: 3rem;
        font-weight: bold;
    }
    h2 {
        display: block;
        font-size: 2.6rem;
        line-height: 2.6rem;
        font-weight: bold;
    }
    h3 {
        display: block;
        font-size: 2.2rem;
        line-height: 2.2rem;
        font-weight: bold;
    }
    h4 {
        display: block;
        font-size: 1.8rem;
        line-height 1.8rem;
        font-weight: bold;
    }
    h5 {
        display: block;
        font-size: 1.2rem;
        line-height: 1.2rem;
        font-weight: bold;
    }
    h6 {
        display: block;
        font-size: 0.8em;
        line-height: 0.8em;
        font-weight: bold;
    }
`;
