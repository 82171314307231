import { XYPosition } from 'reactflow';

import { TPortTypeComplex } from 'libs/models/src/lib/libraryItem';

import { CANVAS } from '@repeat/constants';
import {
    ILibraryItem,
    LibraryTypes,
    TLibraryItemPort,
    TSchemaConnection,
    TSchemaHandle,
    TSchemaNode,
} from '@repeat/models';

import { makeSchemaConnection, makeSchemaNode } from '../../factory/BlockFactory';

const ELEMENT_ELECTROCITY_BUS_TYPE = 'electrocityBus';
interface ISchemaItems {
    elements: TSchemaNode[];
    wires: TSchemaConnection[];
}

const findLibraryItem = (type: string, items: ILibraryItem[]) => {
    return items.find((i) => i.type === type);
};

const findAvailablePort = (num: number, ports: TLibraryItemPort[]) => {
    return ports.find((p, index) => num === index + 1);
};

export const applyPatch_1_4_0 = (
    schemaItems: {
        elements: unknown[];
        wires: unknown[];
    },
    libraryItems: ILibraryItem[],
    versionFrom: string | null,
    versionTo: string,
    libraryPortTypes: TPortTypeComplex[]
): ISchemaItems => {
    if (versionFrom !== null) {
        return {
            elements: schemaItems.elements as TSchemaNode[],
            wires: schemaItems.wires as TSchemaConnection[],
        };
    }

    const connectedHandleNamesByElementAndNumberMap: { [key: string]: string | null } = {};
    schemaItems.wires.forEach((w: any) => {
        connectedHandleNamesByElementAndNumberMap[`${w.firstElement}-${w.firstPort}`] = null;
        connectedHandleNamesByElementAndNumberMap[`${w.secondElement}-${w.secondPort}`] = null;
    });

    const elements = schemaItems.elements.map((e: any) => {
        const libraryItem = findLibraryItem(e.type, libraryItems);
        if (!libraryItem) {
            throw new Error(
                `Error on schema upgrading to ${versionTo} version. Could not create element '${e.type}' in library '${e.library}' of solver '${e.solver}'`
            );
        }

        const minWidth = libraryItem.view?.minWidth || CANVAS.ELEMENT_MIN_WIDTH;
        const minHeight = libraryItem.view?.minHeight || CANVAS.ELEMENT_MIN_HEIGHT;

        const position: XYPosition = {
            x: e.x / 2.5 - (e.x % CANVAS.GRID_STEP) - minWidth / 2,
            y: e.y / 2.5 - (e.y % CANVAS.GRID_STEP) - minHeight / 2,
        };
        let libraryItemAvailablePorts = libraryItem.availablePorts;
        if (e.type === ELEMENT_ELECTROCITY_BUS_TYPE) {
            const oldPortsCount = e.ports.length;
            if (libraryItem.availablePorts) {
                const newInputAvailablePorts: TLibraryItemPort[] = [libraryItem.availablePorts[0]];
                const newOutputAvailablePorts: TLibraryItemPort[] = [];
                const newOutputPort: TLibraryItemPort =
                    libraryItem.availablePorts[libraryItem.availablePorts.length - 1];

                let portsCount = newInputAvailablePorts && newInputAvailablePorts?.length;
                while (portsCount < oldPortsCount / 2) {
                    newInputAvailablePorts.push({ ...newInputAvailablePorts[0], name: `in_${portsCount + 1}` });
                    portsCount++;
                }

                while (portsCount < oldPortsCount) {
                    newOutputAvailablePorts.push({ ...newOutputPort, name: `out_${portsCount + 1}` });
                    portsCount++;
                }
                libraryItemAvailablePorts = [...newInputAvailablePorts, ...newOutputAvailablePorts];
            }
        }
        return makeSchemaNode(
            libraryItem,
            {
                id: e.id as number,
                index: e.indexDisplay,
                position,
                nodeType: 'element',
                elementProps: e.elemProps,
                elementParams: e.elemParams,
                selectedConfiguration: e.selectedConfiguration,
                indicator: e.indicator,
                availablePorts: e.ports.map((p: any) => {
                    const availablePort = libraryItemAvailablePorts
                        ? findAvailablePort(p.num, libraryItemAvailablePorts)
                        : null;
                    const handleName = `${p.type}_${p.num}`;
                    const isConnected = connectedHandleNamesByElementAndNumberMap[`${e.id}-${p.num}`] !== undefined;
                    if (isConnected) {
                        connectedHandleNamesByElementAndNumberMap[
                            `${e.id}-${p.num}`
                        ] = `${e.id}-${p.num}-${handleName}`;
                    }

                    return {
                        isConnected: isConnected,
                        name: handleName,
                        type: availablePort ? availablePort.type : p.type,
                        position: availablePort ? availablePort.position : p.positionType,
                        libraries: availablePort
                            ? availablePort.libraries
                            : [
                                  LibraryTypes.AUTO,
                                  LibraryTypes.THERMOHYDRAULICS,
                                  LibraryTypes.ELECTROCITY,
                                  LibraryTypes.EXTERNAL_MODELS,
                                  LibraryTypes.SIMULATION,
                                  LibraryTypes.SYSTEM_DESIGN,
                              ],
                    } as TSchemaHandle;
                }),
            },
            libraryPortTypes
        );
    });

    const wires = schemaItems.wires.map((w: any) => {
        const sourceHandle = connectedHandleNamesByElementAndNumberMap[`${w.firstElement}-${w.firstPort}`]
            ? connectedHandleNamesByElementAndNumberMap[`${w.firstElement}-${w.firstPort}`]
            : null;
        const targetHandle = connectedHandleNamesByElementAndNumberMap[`${w.secondElement}-${w.secondPort}`]
            ? connectedHandleNamesByElementAndNumberMap[`${w.secondElement}-${w.secondPort}`]
            : null;

        if (!sourceHandle) {
            throw new Error(
                `Error on schema upgrading to ${versionTo} version. Could not create connection from source '${w.firstElement}' (port: ${w.firstPort}). Port not found.`
            );
        }
        if (!targetHandle) {
            throw new Error(
                `Error on schema upgrading to ${versionTo} version. Could not create connection to target '${w.secondElement}' (port: ${w.secondPort}). Port not found.`
            );
        }

        return makeSchemaConnection({
            id: w.id,
            index: w.index,
            source: w.firstElement.toString(),
            target: w.secondElement.toString(),
            sourceHandle: sourceHandle,
            targetHandle: targetHandle,
        });
    });
    return {
        elements,
        wires,
    };
};
