import { DistribTypes, type TDistribType, type envType } from '@repeat/models';

export const environment: envType = {
    production: true,
    API_URL: window?._env_?.API_URL || '',
    WS_URL: window?._env_?.WS_URL || '',
    AUTH_DOMAIN: window?._env_?.AUTH_DOMAIN || '',
    ACCOUNT_DOMAIN: window?._env_?.ACCOUNT_DOMAIN || '',
    REPEAT_DOMAIN: window?._env_?.REPEAT_DOMAIN || '',
    DISTRIB: (window?._env_?.DISTRIB as TDistribType) || DistribTypes.CLOUD,
    NOTEBOOK_URL: window?._env_?.NOTEBOOK_URL || '',
    DOCS_URL: window?._env_?.DOCS_URL || '',
    APP_BUILD: window?._env_?.APP_BUILD || '',
};
