import { IUndoRedoSetStack } from '@repeat/models';

import { UndoRedoDBStorage } from './UndoRedoDBStorage';
import { UndoRedoService } from './UndoRedoService';

const storeName = 'UNDO_REDO_STATE';

const undoRedoDBStorage = new UndoRedoDBStorage(storeName);
const undoRedoService = new UndoRedoService();

export const clearDBStack = () => undoRedoDBStorage.clearStorageItems();

export const getDBStack = async () => {
    const store = await undoRedoDBStorage.getStorageItem(storeName);
    return undoRedoService.getStack(store);
};

export const setDBStack = async (stackItem: IUndoRedoSetStack) => {
    const store = await undoRedoDBStorage.getStorageItem(storeName);
    const defaultValues = { current: stackItem, stack: [stackItem], next: [] };

    if (!store) {
        await undoRedoDBStorage.setStorageItem(defaultValues);
    } else {
        const newStore = undoRedoService.setStack(store, stackItem);
        await undoRedoDBStorage.setStorageItem(newStore);
    }
};

export const undo = async () => {
    const DBStore = await undoRedoDBStorage.getStorageItem(storeName);

    const { stack: DBStack } = DBStore;

    if (DBStack && DBStack.length > 1) {
        const newDBStore = undoRedoService.undo(DBStore);
        await undoRedoDBStorage.setStorageItem(newDBStore);
        return newDBStore;
    }
    return DBStore;
};

export const redo = async () => {
    const DBStore = await undoRedoDBStorage.getStorageItem(storeName);
    const { next } = DBStore;
    if (next && next.length > 0) {
        const newDBStore = undoRedoService.redo(DBStore);
        await undoRedoDBStorage.setStorageItem(newDBStore);
        return newDBStore;
    }
    return DBStore;
};
