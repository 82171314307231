import { environment } from '@repeat/environment';
import { ILibraryItem, LibraryTypes, TLibraryType, TSolverType } from '@repeat/models';

import { $api } from './http';

const { API_URL: baseURL } = environment;

export interface IBlockResponse {
    id: string;
    image: string;
    categoryId: string;
    block: ILibraryItem;
}

interface ReturnGetLibraryItemsContract {
    data: IBlockResponse[];
}

export default class LibraryItemsService {
    config: { [key: string]: string | number };
    type: TLibraryType;
    url: string;

    constructor({ solverType, type, locale }: { solverType: TSolverType; type?: TLibraryType; locale: string }) {
        this.config = {
            baseURL: '/',
        };
        this.type = type || LibraryTypes.ELECTROCITY;

        this.url = `${baseURL}/api/v1/blocks?locale=${locale}`;
    }

    public getLibraryItems = async (): Promise<ReturnGetLibraryItemsContract> => {
        return $api.get(this.url, {
            ...this.config,
        });
    };
}
