import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.MODEL_STATUS_STOP_HELP]: {
        id: TranslationKey.MODEL_STATUS_STOP_HELP,
        defaultMessage: ru[TranslationKey.MODEL_STATUS_STOP_HELP],
    },
    [TranslationKey.MODEL_STATUS_INIT_HELP]: {
        id: TranslationKey.MODEL_STATUS_INIT_HELP,
        defaultMessage: ru[TranslationKey.MODEL_STATUS_INIT_HELP],
    },
    [TranslationKey.MODEL_STATUS_RUN_HELP]: {
        id: TranslationKey.MODEL_STATUS_RUN_HELP,
        defaultMessage: ru[TranslationKey.MODEL_STATUS_RUN_HELP],
    },
    [TranslationKey.MODEL_STATUS_FREEZE_HELP]: {
        id: TranslationKey.MODEL_STATUS_FREEZE_HELP,
        defaultMessage: ru[TranslationKey.MODEL_STATUS_FREEZE_HELP],
    },
    [TranslationKey.MODEL_STATUS_DATA_HELP]: {
        id: TranslationKey.MODEL_STATUS_DATA_HELP,
        defaultMessage: ru[TranslationKey.MODEL_STATUS_DATA_HELP],
    },
    [TranslationKey.MODEL_STATUS_CODE_HELP]: {
        id: TranslationKey.MODEL_STATUS_CODE_HELP,
        defaultMessage: ru[TranslationKey.MODEL_STATUS_CODE_HELP],
    },
    [TranslationKey.MODEL_RUN_HELP]: {
        id: TranslationKey.MODEL_RUN_HELP,
        defaultMessage: ru[TranslationKey.MODEL_RUN_HELP],
    },
    [TranslationKey.MODEL_STOP_HELP]: {
        id: TranslationKey.MODEL_STOP_HELP,
        defaultMessage: ru[TranslationKey.MODEL_STOP_HELP],
    },
    [TranslationKey.MODEL_CONTINUE_HELP]: {
        id: TranslationKey.MODEL_CONTINUE_HELP,
        defaultMessage: ru[TranslationKey.MODEL_CONTINUE_HELP],
    },
    [TranslationKey.MODEL_FREEZE_HELP]: {
        id: TranslationKey.MODEL_FREEZE_HELP,
        defaultMessage: ru[TranslationKey.MODEL_FREEZE_HELP],
    },
    [TranslationKey.MODEL_SOFT_STOP_HELP]: {
        id: TranslationKey.MODEL_SOFT_STOP_HELP,
        defaultMessage: ru[TranslationKey.MODEL_SOFT_STOP_HELP],
    },
    [TranslationKey.MODEL_TIME_UNIT]: {
        id: TranslationKey.MODEL_TIME_UNIT,
        defaultMessage: ru[TranslationKey.MODEL_TIME_UNIT],
    },
});
