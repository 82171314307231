import { Action } from 'redux';

import { ITariffItem } from '@repeat/models';

export enum TariffsTypes {
    SET_TARIFFS = 'SET_TARIFFS',
}

export interface TariffsAction extends Action<TariffsTypes> {
    type: TariffsTypes;
    payload: ITariffItem[];
}
