import { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Figure } from 'react-plotly.js';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

import {
    EChartItemType,
    IChartItem,
    IChartItemParameter,
    IChartsDataMapItem,
    TChartData,
} from 'libs/models/src/lib/charts';

import { getTheme } from '@repeat/constants';
import { useAppSelector } from '@repeat/hooks';
import { IChartItemType, TChartsData } from '@repeat/models';
import { uiColors } from '@repeat/ui-kit';

import 'uplot/dist/uPlot.min.css';
import { customIcons, ICustomIcon } from './Icons/CustomIcons';
import { PlotlyChart } from './PlotlyChart';
import * as ruLocaleDictionary from './PlotlyDictionaryRu';

import { SUPlot } from '../../Charts/SCharts';

interface IConnectedPlotlyChartProps {
    chart: IChartItem;
    isEditable: boolean;
    onUpdate: (figure: Readonly<Figure>) => void;
}

const colors = [uiColors.mainBlue, 'red', 'green', 'brown', 'purple', 'orange', 'yellow'];

export const ConnectedPlotlyChart: FC<IConnectedPlotlyChartProps> = ({ chart, isEditable, onUpdate }) => {
    const theme = getTheme();
    const intl = useIntl();

    const { dataMap, data } = useAppSelector((state) => state.workspace.graphs);
    const [figure, setFigure] = useState<Figure | null>(null);

    useEffect(() => {
        const chartDataMapItem = dataMap[chart.uuid] || null;
        const chartData = extractChartData(chart.type, data, chartDataMapItem);

        setFigure({
            layout: chart.layout as Partial<Plotly.Layout>,
            data: prepareData(chart, chartData),
            frames: [],
        });
    }, [chart, data]);

    const extractChartData = (
        type: IChartItemType | null,
        data: TChartsData[] | null,
        dataMapItem: IChartsDataMapItem | null
    ) => {
        if (!data || !dataMapItem) {
            return null;
        }

        if (dataMapItem.index < 0) {
            return null;
        }

        const chartData = data[dataMapItem.index];
        if (!chartData) {
            return null;
        }

        if (type === EChartItemType.YFromX) {
            return chartData.slice(1);
        }

        return chartData;
    };

    const prepareData = (chart: IChartItem, plotData: TChartData) => {
        if (plotData === null) {
            return [];
        }

        const chartLegends: string[] = chart
            ? chart.YParameters.map((parameter: IChartItemParameter, index: number) => {
                  return parameter.title;
              })
            : [];

        const length = plotData.length - 1;
        let preparedData: Plotly.Data[] = [];
        for (let i = 0; i < length; i++) {
            preparedData = [
                ...preparedData,
                {
                    x: plotData[0],
                    y: plotData[i + 1],
                    type: 'scatter',
                    mode: 'lines',
                    marker: { color: colors[i] },
                    name: chartLegends[i] || '', // легенда
                },
            ];
        }

        return preparedData;
    };

    const plotLocale = useCallback(() => {
        if (intl.locale === 'ru') {
            return 'ru-RU';
        }
        return 'en-EN';
    }, [intl.locale]);

    const plotlyButtons = useCallback(() => customIcons.map((icon: ICustomIcon) => icon.name), []);

    if (!figure) {
        return null;
    }

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <SUPlot theme={theme}>
                <PlotlyChart
                    config={{
                        locales: { 'ru-RU': ruLocaleDictionary },
                        locale: plotLocale(),
                        editable: isEditable,
                        toImageButtonOptions: {
                            format: 'svg',
                            filename: 'plot',
                            height: 500,
                            width: 700,
                            scale: 1,
                        },
                        displaylogo: false,
                        doubleClickDelay: 1000, // сброс масштаба до дефолтного
                        modeBarButtons: [plotlyButtons()],
                    }}
                    figure={figure}
                    onUpdate={onUpdate}
                />
            </SUPlot>
        </div>
    );
};
