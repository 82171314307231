import { FC, useEffect, useState, memo, useRef } from 'react';

import styled from 'styled-components';

import { pxToRem } from '../../config/config';
import { Icon } from '../Icon/Icon';
import { LoaderRepeat } from '../LoaderRepeat/LoaderRepeat';

interface IAuthImage {
    fallback?: string;
    url: string;
    src?: string;
    alt?: string;
    headers: Record<string, string>;
}

const SLoaderWrapper = styled.div`
    img {
        max-width: ${pxToRem(72)};
    }
`;

const controller = new AbortController();

export const AuthImage: FC<IAuthImage> = ({ headers, fallback, alt, src, url }) => {
    const [imageSrc, setImageSrc] = useState<string | undefined>(src);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    useEffect(() => {
        if (!imageSrc || !src || !isLoading) {
            setIsLoading(true);
            fetch(url, {
                method: 'GET',
                cache: 'no-cache',
                headers,
                signal: controller.signal,
            })
                .then((response) => {
                    return response.json();
                })
                .then((image) => {
                    setImageSrc(image.preview);
                    return !!image;
                })
                .then(() => setIsLoading(false))
                .catch((e) => {
                    setIsLoading(false);
                    setIsError(true);
                });
        }
    }, []);

    if (isLoading) {
        return (
            <SLoaderWrapper>
                <LoaderRepeat />
            </SLoaderWrapper>
        );
    }

    if ((!imageSrc && fallback) || (isError && fallback)) {
        return <Icon name={fallback} />;
    }

    return <img src={imageSrc} alt={alt || ''} />;
};
