import styled from 'styled-components';

import { Button, pxToRem } from '@repeat/ui-kit';

export const SGroupHeader = styled.div`
    font-size: ${pxToRem(14)};
    position: sticky;
    top: -8px;
    padding: 4px 10px;
    color: var(--ui-text);
    background: var(--ui-input);
`;

export const SGroupItems = styled.ul`
    background-color: var(--ui-background);
    padding: 0;
    font-size: 12px;
`;
export const SAutocompleteForm = styled.form`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: ${pxToRem(8)};
    padding: 0 ${pxToRem(8)};
    width: 100%;

    > div {
        width: 100% !important;

        &:not(&:last-child) {
            margin-bottom: ${pxToRem(16)};
        }

        .MuiInputBase-root {
            width: 100%;
        }
    }
`;

export const SAutocomplete = styled.div`
    margin-right: ${pxToRem(8)};
    width: 100%;

    .MuiChip-root {
        height: ${pxToRem(26)};
    }

    .MuiChip-label {
        font-size: ${pxToRem(10)};
        padding: 0 ${pxToRem(8)};
    }
`;

export const SSubmit = styled(Button)`
    width: ${pxToRem(64)};
    height: ${pxToRem(40)};

    svg {
        fill: var(--ui-text);
    }
`;

export const SMenuItemText = styled.span`
    font-size: ${pxToRem(12)};
`;
