import { Action } from 'redux';

/* eslint-disable @typescript-eslint/no-explicit-any */

export const INC = 'INC';

export enum CounterTypes {
    INC = 'INC',
}

export interface CounterAction extends Action<CounterTypes> {
    type: CounterTypes;
    payload?: any;
}
