import styled from 'styled-components';

import { uiColors } from '../../config/config';

export interface IStyledTextProps {
    textAlign?: 'end' | 'start';
    color?: string;
    children: React.ReactNode;
}

export const StyledText = styled.div<IStyledTextProps>`
    display: block;
    width: 100%;
    padding: 0.2rem;
    text-align: ${({ textAlign }) => (textAlign ? textAlign : 'end')};
    color: ${({ color }) => (color ? color : uiColors.mainBlue)};
`;
