import styled from 'styled-components';

import { IEventLogData } from '@repeat/models';
import { border, pxToRem, uiColors } from '@repeat/ui-kit';

export const SEventLogItem = styled.div<Partial<IEventLogData>>`
    align-items: baseline;
    display: flex;
    color: var(--ui-text);
    font-size: ${pxToRem(14)};
    margin-bottom: ${pxToRem(4)};
    width: 100%;

    time {
        display: inline-block;
        margin-right: ${pxToRem(24)};
    }

    ${({ level }) =>
        level === 'critical' &&
        `
        color: ${uiColors.red}
    `};

    ${({ level }) =>
        level === 'error' &&
        `
        color: ${uiColors.red}
    `};

    ${({ level }) =>
        level === 'warning' &&
        `
        color: ${uiColors.orange}
    `};

    > div {
        display: flex;
        flex-direction: column;
    }
`;
export const SStacktraceTitle = styled.div`
    margin-top: ${pxToRem(8)};
    font-weight: bold;
`;
export const SStacktrace = styled.pre`
    border: ${border.default};
    padding: ${pxToRem(12)};
    margin: ${pxToRem(8)} 0;
`;

export const SEventLog = styled.div`
    padding: ${pxToRem(16)};
`;
