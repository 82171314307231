import React, { FC } from 'react';

import { SGridViewAddItem } from './SGridView';
import { getWindowItemWidthCoefficient } from './helpers';

import { uiColors } from '../../config/config';
import { Icon } from '../Icon/Icon';

interface IGridViewAppendItem {
    itemCount: number;
    maxItemsCount?: number;
    onAppendItem?: () => void;
    appendItemIcon?: string;
    appendText?: string | null;
}

export const GridViewAppendItem: FC<IGridViewAppendItem> = ({
    itemCount,
    maxItemsCount = 10,
    onAppendItem,
    appendItemIcon = 'addChart',
    appendText = null,
}) => {
    if (onAppendItem && itemCount >= maxItemsCount) {
        return null;
    }

    const handleAddItem = () =>
        new Promise((resolve, reject) => {
            if (onAppendItem) {
                onAppendItem();
                resolve(true);
            } else {
                reject();
            }
        }).then(() => {
            const target = document.querySelector('.grid-view');
            if (target && itemCount > getWindowItemWidthCoefficient() - 1)
                target.scrollIntoView({ behavior: 'smooth', block: 'end' });
        });

    return (
        <SGridViewAddItem isNotAdded={itemCount === 0} isMaximum={itemCount >= maxItemsCount} onClick={handleAddItem}>
            <Icon fill={uiColors.white} name={appendItemIcon} />
            {appendText && <span>{appendText}</span>}
        </SGridViewAddItem>
    );
};
