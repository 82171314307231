export const remainderOfDivision = (n1: number, n2: number): number => {
    if (Number.isInteger(n1) && Number.isInteger(n2)) {
        return n1 % n2;
    }

    const n1Parts = String(n1).split('.');
    const n1FractionPart = n1Parts[1] || '';
    const n1Coef = Math.pow(10, n1FractionPart.length);

    const n2Parts = String(n2).split('.');
    const n2FractionPart = n2Parts[1] || '';
    const n2Coef = Math.pow(10, n2FractionPart.length);

    const coef = n1Coef > n2Coef ? n1Coef : n2Coef;

    return (n1 * coef) % (n2 * coef);
};
