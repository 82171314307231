import { CSSProperties, FC, memo } from 'react';
import { NodeToolbar, Position } from 'reactflow';

import { TElementNotificationType, TSchemaNode } from '@repeat/models';
import { nodeNotificationWidth } from '@repeat/ui-kit';

import { useElementNotificationMessage } from './../../../../../hooks/useElementNotificationMessage';
import { SNodeNotificationWrapper } from './SAttentionMessage';

interface IElementNotificationProps {
    isVisible: boolean;
    notificationType: TElementNotificationType | null;
    currentNode: TSchemaNode;
}

export const ElementNotification: FC<IElementNotificationProps> = memo(
    ({ isVisible, notificationType, currentNode }) => {
        const baseStyle: CSSProperties = {
            width: nodeNotificationWidth.medium,
        };

        const { getMessage } = useElementNotificationMessage(notificationType, currentNode);

        if (!getMessage(notificationType as TElementNotificationType)) {
            return null;
        }

        return (
            <NodeToolbar isVisible={isVisible} position={Position.Top} style={baseStyle}>
                <SNodeNotificationWrapper>
                    {getMessage(notificationType as TElementNotificationType)}
                </SNodeNotificationWrapper>
            </NodeToolbar>
        );
    }
);
