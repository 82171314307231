import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { $api } from './http';

import { Mime } from '..';

export interface IDownloadFileParams {
    onLoadStart?: () => void;
    onLoadSuccess?: (secureUrl: string, fileExtension: string, filename?: string | null) => void;
    onLoadError?: (e: Error) => void;
    abortController?: AbortController;
}

interface IDownloadFile {
    <T>(url: string, params: IDownloadFileParams, method?: string, config?: AxiosRequestConfig, data?: T | null): void;
}

export default class FileDownloadService {
    static downloadFile: IDownloadFile = async <T>(
        url: string,
        params: IDownloadFileParams,
        method = 'get',
        config?: AxiosRequestConfig,
        data?: T | null
    ) => {
        const { onLoadStart, onLoadSuccess, onLoadError, abortController } = params;

        try {
            if (onLoadStart) {
                onLoadStart();
            }

            const requestConfig = {
                responseType: 'blob',
                signal: abortController?.signal,
                ...config,
            } as AxiosRequestConfig;

            await FileDownloadService.downloadRequest(url, requestConfig, method, data).then(
                (response: AxiosResponse) => {
                    const localUrl = URL.createObjectURL(response.data);

                    const contentDisposition = response.headers['content-disposition'];
                    const filenameMatch = contentDisposition ? contentDisposition.match(/filename="([^"]+)"/) : null;

                    const filename = filenameMatch ? filenameMatch[1] : null;

                    const contentType = response.headers['content-type'] || '';
                    const fileExtension = Mime.getExtension(contentType) || '';

                    if (onLoadSuccess) {
                        onLoadSuccess(localUrl, fileExtension, filename);
                    }
                },
                (e) => {
                    if (!abortController?.signal?.aborted) {
                        console.error(e);
                        if (onLoadError) {
                            onLoadError(e);
                        }
                    }
                }
            );
        } catch (e) {
            if (!abortController?.signal?.aborted) {
                console.error(e);
                const error = e instanceof Error ? e : new Error();
                if (onLoadError) {
                    onLoadError(error);
                }
            }
        }
    };

    static downloadRequest = async <T>(
        url: string,
        config: AxiosRequestConfig,
        method: string,
        data: T | null
    ): Promise<AxiosResponse> => {
        if (method === 'post') {
            return await $api.post(url, data, config);
        }

        return await $api.get(url, config);
    };
}
