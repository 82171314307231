import { IElemTool } from '@repeat/models';

import { ElementsTypes } from '../actions/elementsTypes';

/* eslint-disable @typescript-eslint/no-explicit-any */

const defaultElemCur = {
    x: 0,
    y: 0,
    size: 1,
    height: 1,
    width: 1,
    visible: false,
    picId: 0,
    name: '',
    startSize: 1,
    angle: 0,
    warning: false,
    state: '0',
    id: 0,
};

export const addElemCur = (current: IElemTool) => {
    return {
        type: ElementsTypes.ADD_ELEMTOOL,
        payload: {
            ...current,
        },
    };
};

export const addStartSize = (size: any) => {
    return {
        type: ElementsTypes.START_SIZE,
        payload: size,
    };
};

export const getPort = (fun: any) => {
    return {
        type: ElementsTypes.GET_PORT,
        payload: fun,
    };
};

export const clearElemCur = () => {
    return {
        type: ElementsTypes.CLEAR_ELEMTOOL,
        payload: { ...defaultElemCur },
    };
};
