import React, { FC, useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import { uiRGBColors, pxToRem, radius, uiColors, zIndex } from '../../config/config';

export enum Position {
    LEFT = 'left',
    RIGHT = 'right',
    TOP = 'top',
    BOTTOM = 'bottom',
}

export enum Variant {
    LIGHT = 'light',
    DARK = 'dark',
}

export type TPosition = Position.LEFT | Position.RIGHT | Position.TOP | Position.BOTTOM;
export type TVariant = Variant.DARK | Variant.LIGHT;

interface ISTooltip {
    position: TPosition;
    variant?: TVariant;
    width?: number;
}

const STooltipBox = styled.div<ISTooltip>`
    border-radius: ${radius.default};
    font-size: ${pxToRem(10)};
    height: auto;
    padding: ${pxToRem(4)} ${pxToRem(8)};
    position: fixed;
    max-width: ${({ width }) => (!width ? `${pxToRem(120)}` : `${pxToRem(width)}`)};
    text-align: center;
    z-index: ${zIndex.super};
    visibility: hidden;
    width: auto;
    white-space: break-spaces;

    &:before {
        color: rgba(${uiRGBColors.greySecond}, 1);
        content: '▲';
        border: none;
        left: calc(100% / 2 - 4px);
        position: absolute;
    }

    ${({ variant }) =>
        variant === Variant.DARK &&
        `
        background-color: rgba(${uiRGBColors.greySecond}, 1);
        color: ${uiColors.white};
    `}

    ${({ variant }) =>
        variant === Variant.LIGHT &&
        `
        background-color: rgba(${uiRGBColors.white}, 1);
        color: ${uiColors.black};
        border: 1px solid ${uiColors.borderGreyDark};

        &::before {
            text-shadow: 0 -2px 0 ${uiColors.borderGreyDark};
            color: rgba(${uiRGBColors.white}, 1);
            content: '▲';
            border: none;
        }
    `}

    ${({ position }) =>
        position === Position.BOTTOM &&
        `
        &::before {
            top: -9px;
        }
    `};

    ${({ position }) =>
        position === Position.TOP &&
        `
        &::before {
            bottom: -9px;
            transform: rotate(182deg);
        }
    `};

    ${({ position }) =>
        position === Position.LEFT &&
        `
        &::before {
            left: calc(100% - ${pxToRem(2)});
            transform: rotate(90deg);
            top: calc(100% / 2 - ${pxToRem(8)});
        }
    `};

    ${({ position }) =>
        position === Position.RIGHT &&
        `
        &::before {
            left: -8px;
            transform: rotate(-90deg);
            top: calc(100% / 2 - ${pxToRem(8)});
        }
    `};

    ${({ variant, position }) =>
        position === Position.LEFT &&
        variant === Variant.LIGHT &&
        `
        &::before {
            left: calc(100% - ${pxToRem(3)});
            top: calc(100% / 2 - ${pxToRem(8)});
        }
    `}

    ${({ variant, position }) =>
        position === Position.RIGHT &&
        variant === Variant.LIGHT &&
        `
        &::before {
            left: -${pxToRem(7)};
            top: calc(100% / 2 - ${pxToRem(8)});
        }
    `}
`;

export const STooltipWrapper = styled.div<{ isOpen: boolean }>`
    align-items: center;
    justify-content: center;
    position: relative;
    display: inline-flex;
    width: auto;

    ${({ isOpen }) =>
        isOpen &&
        `
        ${STooltipBox} {
            display: block;
            visibility: visible;
        }
    `}
`;

interface IRects {
    bottom: number;
    height: number;
    left: number;
    right: number;
    top: number;
    width: number;
    x: number;
    y: number;
}

interface ITooltip {
    text: string | React.ReactNode;
    position?: TPosition;
    variant?: TVariant;
    tooltipWidth?: number;
    delayShow?: number;
    delayHide?: number;
    children: React.ReactNode;
    style?: any;
}

export const Tooltip: FC<ITooltip> = ({
    children,
    text,
    position = Position.BOTTOM,
    tooltipWidth,
    delayShow = 100,
    delayHide = 100,
    variant = Variant.DARK,
    ...rest
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const openTimeoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const closeTimeoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const gap = 8;

    const wrapperRef = useRef<HTMLDivElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);

    const handleOpen = () => {
        if (closeTimeoutIdRef?.current) {
            clearTimeout(closeTimeoutIdRef.current);
            closeTimeoutIdRef.current = null;
        }

        const openTimeout: ReturnType<typeof setTimeout> = setTimeout(() => {
            setIsOpen(true);
            return clearTimeout(openTimeout);
        }, delayShow);

        openTimeoutIdRef.current = openTimeout;
    };

    const handleClose = () => {
        if (openTimeoutIdRef?.current) {
            clearTimeout(openTimeoutIdRef.current);
            openTimeoutIdRef.current = null;
        }

        const closeTimeout: ReturnType<typeof setTimeout> = setTimeout(() => {
            setIsOpen(false);
            return clearTimeout(closeTimeout);
        }, delayHide);

        closeTimeoutIdRef.current = closeTimeout;
    };

    useEffect(() => {
        if (wrapperRef !== null && tooltipRef !== null && wrapperRef.current && tooltipRef.current) {
            const currentWrapper = wrapperRef.current && wrapperRef.current;
            const currentTooltip = tooltipRef.current && tooltipRef.current;

            const currentRects: IRects = currentWrapper && currentWrapper.getBoundingClientRect();
            const tooltipRects: IRects = currentTooltip.getBoundingClientRect();

            if (currentRects !== null && tooltipRef.current) {
                if (position === Position.BOTTOM || position === Position.TOP) {
                    tooltipRef.current.style.left = `${
                        currentRects.x - tooltipRects.width / 2 + currentRects.width / 2
                    }px`;
                }
                if (position === Position.LEFT || position === Position.RIGHT) {
                    tooltipRef.current.style.top = `${
                        currentRects.y + currentRects.height / 2 - tooltipRects.height / 2
                    }px`;
                }
                if (position === Position.BOTTOM) {
                    tooltipRef.current.style.top = `${currentRects.y + currentRects.height + gap}px`;
                }
                if (position === Position.TOP) {
                    tooltipRef.current.style.top = `${currentRects.y - tooltipRects.height - gap}px`;
                }
                if (position === Position.RIGHT) {
                    tooltipRef.current.style.left = `${currentRects.x + currentRects.width + gap}px`;
                }
                if (position === Position.LEFT) {
                    tooltipRef.current.style.left = `${currentRects.x - tooltipRects.width - gap}px`;
                }
            }
        }
    }, [isOpen]);

    return (
        <STooltipWrapper {...rest} ref={wrapperRef} isOpen={isOpen} onMouseOver={handleOpen} onMouseOut={handleClose}>
            {children}
            <STooltipBox
                style={{ ...rest.style }}
                ref={tooltipRef}
                data-position={position}
                position={position}
                width={tooltipWidth}
                variant={variant}
            >
                {text}
            </STooltipBox>
        </STooltipWrapper>
    );
};
