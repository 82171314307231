import { IntlShape } from 'react-intl';

import { SimpleTextWithIntl } from '@components/Tour/SimpleTextWithIntl';

import { TranslationKey } from '@repeat/translations';

export const STEPS = [
    {
        element: '[data-tour="model-control-wrapper"]',
        intlTitleKey: TranslationKey.TOUR_WORKSPACE_MODEL_CONTROL,
        createIntlIntro: (intl: IntlShape) => (
            <SimpleTextWithIntl intl={intl} translationKey={TranslationKey.TOUR_WORKSPACE_MODEL_CONTROL_DESCRIPTION} />
        ),
        callback: () => {
            document.body.style.overflow = 'hidden';
        },
    },
    {
        element: '[data-tour="model-control-start"]',
        intlTitleKey: TranslationKey.TOUR_WORKSPACE_MODEL_CONTROL_START,
        createIntlIntro: (intl: IntlShape) => (
            <SimpleTextWithIntl
                intl={intl}
                translationKey={TranslationKey.TOUR_WORKSPACE_MODEL_CONTROL_START_DESCRIPTION}
            />
        ),
    },
    {
        element: '[data-tour="workspace-project-name"]',
        intlTitleKey: TranslationKey.TOUR_WORKSPACE_PROJECT_TITLE,
        createIntlIntro: (intl: IntlShape) => (
            <SimpleTextWithIntl intl={intl} translationKey={TranslationKey.TOUR_WORKSPACE_PROJECT_TITLE_DESCRIPTION} />
        ),
    },
    {
        element: '[data-tour="workspace-project-save"]',
        intlTitleKey: TranslationKey.TOUR_WORKSPACE_PROJECT_SAVE,
        createIntlIntro: (intl: IntlShape) => (
            <SimpleTextWithIntl intl={intl} translationKey={TranslationKey.TOUR_WORKSPACE_PROJECT_SAVE_DESCRIPTION} />
        ),
    },
    {
        element: '[data-tour="library-items-wrapper"]',
        intlTitleKey: TranslationKey.TOUR_WORKSPACE_LIBRARY,
        createIntlIntro: (intl: IntlShape) => (
            <SimpleTextWithIntl intl={intl} translationKey={TranslationKey.TOUR_WORKSPACE_LIBRARY_DESCRIPTION} />
        ),
        position: 'right',
    },
    {
        element: '[data-tour="workspace-visualization-trigger"]',
        intlTitleKey: TranslationKey.TOUR_WORKSPACE_VISUALIZATION_TRIGGER,
        createIntlIntro: (intl: IntlShape) => (
            <SimpleTextWithIntl
                intl={intl}
                translationKey={TranslationKey.TOUR_WORKSPACE_VISUALIZATION_TRIGGER_DESCRIPTION}
            />
        ),
        callback: () => {
            const visualTab = document.querySelector(
                '[data-tour="footer-tabs"] li:first-child button'
            ) as HTMLButtonElement;
            if (visualTab !== null) {
                visualTab.click();
            }
        },
    },
    {
        element: '[data-tour="footer-tabs"] li:first-child',
        intlTitleKey: TranslationKey.TOUR_WORKSPACE_VISUALIZATION_TAB,
        createIntlIntro: (intl: IntlShape) => (
            <SimpleTextWithIntl
                intl={intl}
                translationKey={TranslationKey.TOUR_WORKSPACE_VISUALIZATION_TAB_DESCRIPTION}
            />
        ),
    },
    {
        element: 'footer',
        intlTitleKey: TranslationKey.TOUR_WORKSPACE_VISUALIZATION_AREA,
        createIntlIntro: (intl: IntlShape) => (
            <SimpleTextWithIntl
                intl={intl}
                translationKey={TranslationKey.TOUR_WORKSPACE_VISUALIZATION_AREA_DESCRIPTION}
            />
        ),
    },
    {
        element: '[data-tour="footer-tabs"] li:nth-child(2)',
        intlTitleKey: TranslationKey.TOUR_WORKSPACE_JOURNAL_TAB,
        createIntlIntro: (intl: IntlShape) => (
            <SimpleTextWithIntl intl={intl} translationKey={TranslationKey.TOUR_WORKSPACE_JOURNAL_TAB_DESCRIPTION} />
        ),
    },
    {
        element: 'footer',
        intlTitleKey: TranslationKey.TOUR_WORKSPACE_JOURNAL_AREA,
        createIntlIntro: (intl: IntlShape) => (
            <SimpleTextWithIntl intl={intl} translationKey={TranslationKey.TOUR_WORKSPACE_JOURNAL_AREA_DESCRIPTION} />
        ),
        position: 'top',
    },
    {
        element: '[data-tour="right-sidebar"]',
        intlTitleKey: TranslationKey.TOUR_WORKSPACE_RIGHT_PANEL,
        createIntlIntro: (intl: IntlShape) => (
            <SimpleTextWithIntl intl={intl} translationKey={TranslationKey.TOUR_WORKSPACE_RIGHT_PANEL_DESCRIPTION} />
        ),
        position: 'left',
        callback: () => {
            const listItem = document.querySelector(
                'div[data-tour="library-items-wrapper"] ul li:first-child div'
            ) as HTMLElement;
            listItem.click();
        },
    },
    {
        element: 'div[data-tour="library-items-wrapper"] ul li:first-child div:nth-child(2) div div:first-child',
        intlTitleKey: TranslationKey.TOUR_WORKSPACE_LIBRARY_ITEM,
        createIntlIntro: (intl: IntlShape) => (
            <SimpleTextWithIntl intl={intl} translationKey={TranslationKey.TOUR_WORKSPACE_LIBRARY_ITEM_DESCRIPTION} />
        ),
        position: 'right',
        callback: () => {
            document.body.style.overflow = 'initial';
        },
        onExit: () => {
            const visualTab = document.querySelector(
                '[data-tour="footer-tabs"] li:last-child div:last-child button'
            ) as HTMLButtonElement;
            if (visualTab !== null) {
                visualTab.click();
            }
        },
    },
];
