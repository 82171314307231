import { Font, Padding } from 'plotly.js';

export const chartTitle = (
    title:
        | string
        | Partial<{
              text: string;
              font: Partial<Font>;
              xref: 'container' | 'paper';
              yref: 'container' | 'paper';
              x: number;
              y: number;
              xanchor: 'auto' | 'left' | 'center' | 'right';
              yanchor: 'auto' | 'top' | 'middle' | 'bottom';
              pad: Partial<Padding>;
          }>
        | undefined,
    defaultTitle: string
) => {
    if (typeof title === 'string') {
        return title.length > 0 ? title : defaultTitle;
    }

    return title?.text && title.text.length > 0 ? title.text : defaultTitle;
};
