const ALPHABET_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

export const generateTitleByAlphabet = (usedTitles: string[]) => {
    const usedOneCharTitles = usedTitles.filter((title) => title.length === 1);

    const availableChars = ALPHABET_CHARS.filter((char) => !usedOneCharTitles.includes(char));

    if (availableChars.length > 0) {
        return availableChars[0];
    }

    let hasUniqueTitle = false;
    let title = '-';
    for (let i = 0; i < ALPHABET_CHARS.length; i++) {
        const char = ALPHABET_CHARS[i];

        let usedChars: string[] = [];
        for (let j = 0; j < ALPHABET_CHARS.length; j++) {
            const additionalChar = generateTitleByAlphabet(usedChars);

            title = `${char}${additionalChar}`;

            if (!usedTitles.includes(title)) {
                hasUniqueTitle = true;
                break;
            }
            usedChars = [...usedChars, additionalChar];
        }

        if (hasUniqueTitle) {
            break;
        }
    }

    return title;
};
