import { keyframes } from 'styled-components';

import { TSizes } from './interfaces';

import { pxToRem } from '../../config/config';

export const styles = {
    overlayPadding: '4rem',
};

interface ISizes {
    [key: string | TSizes]: string;
}

export const sizes = {
    large: `${pxToRem(1000)}`,
    big: `${pxToRem(700)}`,
    medium: `${pxToRem(600)}`,
    small: `${pxToRem(500)}`,
};

export const slide = keyframes`
    from {
      opacity: 0.8;
      top: -10px;
    }
    to {
      top: 0;
      opacity: 1;
    }
`;
