import { useCallback, useState } from 'react';

import { uiColors } from '@repeat/ui-kit';

export interface ISortOptions {
    column: any;
    direction: 'asc' | 'desc';
}

export const useSortOptions = () => {
    const [sortOptions, setSortOptions] = useState<ISortOptions>({
        column: 'createdAt',
        direction: 'asc',
    });

    const getFillColor = useCallback(
        ({ column, direction }: ISortOptions) => {
            return sortOptions.column === column && sortOptions.direction === direction
                ? 'var(--ui-link-secondary)'
                : uiColors.darkGrey;
        },
        [sortOptions]
    );

    return {
        sortOptions,
        setSortOptions,
        getFillColor,
    };
};
