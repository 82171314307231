import { IElement } from './element';

export type IElemProp = {
    description: string;
    name: string;
    value: string;
};

export type IElemTool = Partial<IElement> & {
    visible?: boolean;
    height?: number;
    width?: number;
    startSize?: number;
    port?: number;
    type?: ElemToolType;
};

export enum ElemToolTypes {
    DEFAULT = 'default',
    ELEMENT = 'element',
    TRACING = 'tracing',
}

export type ElemToolType = ElemToolTypes.DEFAULT | ElemToolTypes.ELEMENT | ElemToolTypes.TRACING;
