import React, { FC } from 'react';

import styled from 'styled-components';

import { pxToRem, uiColors } from '../../config/config';

interface ICentertedLayout {
    withRightSidebar?: boolean;
    withFooter?: boolean;
    internal?: boolean;
    children: React.ReactNode;
    background?: string;
}

const SCenteredLayout = styled.section<ICentertedLayout>`
    background: ${uiColors.mainBlue};
    display: flex;
    height: 100vh;
    max-width: 100vw;
    overflow: hidden;
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: ${pxToRem(24)};
`;

export const CenteredLayout: FC<ICentertedLayout> = ({ children }) => {
    return <SCenteredLayout>{children}</SCenteredLayout>;
};
