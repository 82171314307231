import { useCallback } from 'react';
import { Connection } from 'reactflow';

import { TSchemaHandle, TSchemaNode } from '@repeat/models';

import { IHandleViewData } from './useInitNodeViewData';


export const useValidConnection = (elements: TSchemaNode[]) => {    
    const isValidConnection = useCallback(
        (connection: Connection, handleViewData: IHandleViewData) => {

            const {                
                compatibleTypes: sourceHandleCompatibleTypes,
            } = handleViewData.meta;
            const splittedTargetHandleID = connection?.targetHandle?.split('-');
            const targetHandleName =
                splittedTargetHandleID && splittedTargetHandleID[splittedTargetHandleID?.length - 1];
            const targetElement = elements.find((el: { id: string }) => el.id === connection.target);
            const targetHandle = targetElement?.data.availablePorts.find(
                (port) => port.name === targetHandleName
            ) as TSchemaHandle;
            const {
                typeConnection: targetHandleTypeConnection,
            } = targetHandle;

            const isTypeCompatible = sourceHandleCompatibleTypes?.includes(targetHandleTypeConnection);

            return !!(
                (!targetHandle.isConnected && isTypeCompatible)
            );
        },
        [elements]
    );
    return isValidConnection;
};
