import { FC } from 'react';

import styled from 'styled-components';

import { pxToRem, uiColors } from '../../config/config';
import { Icon } from '../Icon/Icon';

const SEmptyData = styled.div`
    background-color: transparent;
    display: flex;
    align-items: center;
    padding: ${pxToRem(32)};

    span {
        font-size: ${pxToRem(16)};
        color: ${uiColors.darkGrey};
        margin-left: ${pxToRem(16)};
    }

    i {
        max-height: ${pxToRem(32)};
        max-width: ${pxToRem(32)};

        svg {
            fill: ${uiColors.darkGrey};
        }
    }
`;

interface IEmptyData {
    iconName: string;
    text: string;
    style?: Record<string, any>;
}

export const EmptyData: FC<IEmptyData> = ({ iconName, text, style }) => (
    <SEmptyData style={style}>
        <Icon name={iconName} />
        <span>{text}</span>
    </SEmptyData>
);
