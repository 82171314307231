import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector, useProjectId } from '@repeat/hooks';
import { ModalTypes, Statuses } from '@repeat/models';
import {
    deleteUninitializedElement,
    initializeElement,
    initializeElementByFile,
    uploadSourcesFileAndInitialize,
    workspaceActions,
} from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { Button, Modal } from '@repeat/ui-kit';

import { blockOptionsMessages as messages } from './translation';

import { UploadForm } from '../Upload/UploadForm';

export const ElementInitializationModal = () => {
    const intl = useIntl();
    const { formatMessage } = useIntl();
    const dispatch = useAppDispatch();

    const element = useAppSelector((state) => state.workspace.schema.selectedItems.elements[0]);
    const { elementInitialization } = useAppSelector((state) => state.workspace.schema);
    const { projectId } = useProjectId() || { projectId: null };

    useEffect(() => {
        return () => {
            dispatch(workspaceActions.resetElementInitialization());
        };
    }, []);

    const handleClose = () => {
        dispatch(deleteUninitializedElement());
    };
    const handleAdd = () => {
        dispatch(initializeElement());
    };

    const handleFileUpload = (file: File) => {
        if (!element?.id || !projectId) {
            return;
        }

        dispatch(uploadSourcesFileAndInitialize({ file, projectId, elementId: parseInt(element.id) }));
    };

    const handleInitialize = () => {
        if (!element?.id || !projectId) {
            return;
        }

        dispatch(initializeElementByFile());
    };

    const FooterButtons = () => (
        <>
            <Button variant='secondary' onClick={handleClose}>
                {intl.formatMessage(messages[TranslationKey.CANCEL])}
            </Button>
            <Button
                onClick={handleAdd}
                disabled={elementInitialization.initializeElementByFile.status !== Statuses.SUCCEEDED}
            >
                {intl.formatMessage(messages[TranslationKey.ADD])}
            </Button>
        </>
    );

    const formMessages = {
        title: formatMessage(messages[TranslationKey.ELEMENT_INITIALIZATION_NOTICE], { name: 'FMI', extension: 'FMU' }),
        attentionMessage: formatMessage(messages[TranslationKey.ELEMENT_INITIALIZATION_ATTENTION_MESSAGE]),
        dropZoneMessages: {
            uploadHelp: formatMessage(messages[TranslationKey.ELEMENT_INITIALIZATION_DROPZONE], { extension: '.fmu' }),
            uploadButton: formatMessage(messages[TranslationKey.ELEMENT_INITIALIZATION_SELECT], {
                extension: '.fmu',
            }),
        },
    };

    return (
        <Modal
            id={ModalTypes.ELEMENT_INITIALIZATION}
            title={intl.formatMessage(messages[TranslationKey.ELEMENT_INITIALIZATION], { name: 'FMI' })}
            isOpen={true}
            hasOverlay={true}
            onClose={handleClose}
            type='default'
            size='medium'
            footer={<FooterButtons />}
        >
            <UploadForm
                acceptFileTypes={{ 'application/x-fmu-sharedlibrary': ['.fmu'] }}
                maxFileSize={52428800}
                onFileUpload={handleFileUpload}
                onInitialize={handleInitialize}
                uploadStatusInfo={elementInitialization.uploadElementSourcesFile}
                initializeStatusInfo={elementInitialization.initializeElementByFile}
                messages={formMessages}
            />
        </Modal>
    );
};
