import { IntlShape } from 'react-intl';

import { SimpleTextWithIntl } from '@components/Tour/SimpleTextWithIntl';

import { TranslationKey } from '@repeat/translations';

export const STEPS = [
    {
        element: '[data-tour="create-new-project"]',
        intlTitleKey: TranslationKey.TOUR_PROJECTS_NEW,
        createIntlIntro: (intl: IntlShape) => (
            <SimpleTextWithIntl intl={intl} translationKey={TranslationKey.TOUR_PROJECTS_NEW_DESCRIPTION} />
        ),
        callback: () => {
            document.body.style.overflow = 'hidden';
        },
    },
    {
        element: '[data-tour="project-table"]',
        intlTitleKey: TranslationKey.TOUR_PROJECTS_LIST,
        createIntlIntro: (intl: IntlShape) => (
            <SimpleTextWithIntl intl={intl} translationKey={TranslationKey.TOUR_PROJECTS_LIST_DESCRIPTION} />
        ),
    },
];
