import styled from 'styled-components';

import { InputField, pxToRem } from '@repeat/ui-kit';

export const SLibraryItemsSearchContainer = styled.div`
    margin: 0;
    padding: ${pxToRem(16)};
    position: relative;

    input {
        position: relative;
        padding-right: ${pxToRem(8)};

        &::placeholder {
            font-size: ${pxToRem(12)};
        }
    }

    button {
        max-width: 16px;
        position: absolute;
        left: calc(100% - ${pxToRem(36)});
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    }
`;

export const SInputField = styled(InputField)`
    margin-bottom: -${pxToRem(16)};
`;
