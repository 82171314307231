import { VERSION } from '@repeat/constants';

import { TPortTypeComplex } from 'libs/models/src/lib/libraryItem';
import { $api } from './http';

interface ReturnGetLibraryPortTypesContract {
    data: TPortTypeComplex[];
}

export default class LibraryPortTypesService {
    config: { [key: string]: string | number };

    url: string;

    constructor() {
        this.config = {
            baseURL: '/',
        };

        this.url = `assets/library/ports.json?${VERSION}`;
    }

    public getLibraryPortTypes = async (): Promise<ReturnGetLibraryPortTypesContract> => {
        return $api.get(this.url, this.config);
    };
}
