import { FC, useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useAppSelector } from '@repeat/hooks';
import { TranslationKey, ru } from '@repeat/translations';

import { Autocomplete, TextField } from '@mui/material';
import { workspaceSelectors } from '@repeat/store';
import { InputField, pxToRem, uiColors } from '@repeat/ui-kit';
import { TUserBlockData } from 'libs/models/src/lib/element';
import styled from 'styled-components';
import { ImageCrop } from './ImageEditor/ImageCrop';
import { SAutocomplete } from './SUserBlockForm';
import { TextEditor } from './TextEditor/TextEditor';

const messages = defineMessages({
    [TranslationKey.WORKSPACE_PROJECT_TITLE]: {
        id: TranslationKey.WORKSPACE_PROJECT_TITLE,
        defaultMessage: ru[TranslationKey.WORKSPACE_PROJECT_TITLE],
    },
    [TranslationKey.WORKSPACE_SELECT_PROJECT]: {
        id: TranslationKey.WORKSPACE_SELECT_PROJECT,
        defaultMessage: ru[TranslationKey.WORKSPACE_SELECT_PROJECT],
    },
    [TranslationKey.WORKSPACE_NO_PROJECTS_TO_SELECT]: {
        id: TranslationKey.WORKSPACE_NO_PROJECTS_TO_SELECT,
        defaultMessage: ru[TranslationKey.WORKSPACE_NO_PROJECTS_TO_SELECT],
    },
});

interface IProjectBlockForm {
    onValid: (isValid: boolean) => void;
    groupName: string;
    data: TUserBlockData;
    setData: (state: TUserBlockData) => void;
}

const STextEditor = styled.div`
    margin-bottom: ${pxToRem(16)};
    em {
        font-style: italic;
    }
`;

interface IAutocompleteListBoxProps extends React.HTMLAttributes<HTMLUListElement> {
    'data-test-id'?: string;
}

export const UserBlockForm: FC<IProjectBlockForm> = ({ onValid, groupName, data, setData }) => {
    const { formatMessage } = useIntl();
    const subLibraries = useAppSelector(workspaceSelectors.customSubLibraries);
    const [valueAutocomplete, setValueAutocomplete] = useState(data.subLibrary || '');

    const handleSelect = (option: string) => {
        setData({ ...data, subLibrary: option });
    };

    const setValue = (value: string) => {
        setData({ ...data, description: value });
    };
    useEffect(() => {
        if (data.name && data.subLibrary) {
            onValid(true);
        }
    }, [data.name, data.subLibrary]);

    return (
        <>
            <InputField
                defaultValue={data.name}
                label={'Название'}
                type='text'
                onChange={(e) => {
                    setData({ ...data, name: e.target.value });
                    if (e.target.value === '') {
                        onValid(false);
                    }
                }}
            />
            <SAutocomplete>
                <Autocomplete
                    freeSolo
                    noOptionsText={'Нет вариантов для выбора'}
                    onChange={(e: any, option: string) => {
                        if (option === null) {
                            onValid(false);
                        } else {
                            handleSelect(option);
                        }
                    }}
                    onBlur={(e: any) => {
                        handleSelect(e.target.value);
                    }}
                    size='small'
                    id='grouped-demo'
                    options={subLibraries}
                    getOptionLabel={(option: string) => option || ''}
                    value={valueAutocomplete}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={'Подраздел'}
                            placeholder={'Выберите подраздел или введите его название'}
                        />
                    )}
                    ListboxProps={
                        {
                            'data-test-id': 'visualization-params-box',
                        } as IAutocompleteListBoxProps
                    }
                    renderOption={(props, option: string, state) => {
                        return (
                            <li {...props} key={option}>
                                {option}
                            </li>
                        );
                    }}
                    defaultValue={data.subLibrary}
                />
            </SAutocomplete>

            <STextEditor>
                <TextEditor value={data.description || ''} setValue={setValue} />
            </STextEditor>
            <div style={{ marginBottom: '8px', fontSize: '14px', color: `${uiColors.darkGrey}` }}>Изображение</div>
            <ImageCrop data={data} setData={setData} />
        </>
    );
};
