import { useState } from 'react';
import { useIntl } from 'react-intl';

import { ApplicationActions } from '@repeat/common-slices';
import { CREATE_PROJECT_FORM } from '@repeat/constants';
import { useAppDispatch } from '@repeat/hooks';
import { ModalTypes } from '@repeat/models';
import { TranslationKey } from '@repeat/translations';
import { Button, Modal } from '@repeat/ui-kit';

import { CreateProjectForm } from '../Project/CreateProjectForm/CreateProjectForm';
import { projectMessages as messages } from '../Project/translations';

export const ProjectCreateModal = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const [isValidForm, setIsValidForm] = useState(false);

    const handleClose = () => {
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.PROJECT_CREATE }));
    };

    const FooterButtons = () => (
        <>
            <Button variant='secondary' onClick={handleClose}>
                {intl.formatMessage(messages[TranslationKey.CANCEL])}
            </Button>
            <Button form={CREATE_PROJECT_FORM} type='submit' disabled={!isValidForm}>
                {intl.formatMessage(messages[TranslationKey.SAVE])}
            </Button>
        </>
    );
    const handleFormValidate = (isValid: boolean) => {
        setIsValidForm(isValid);
    };
    return (
        <Modal
            id={ModalTypes.PROJECT_CREATE}
            title={intl.formatMessage(messages[TranslationKey.PROJECT_NEW])}
            isOpen={true}
            hasOverlay={true}
            onClose={handleClose}
            type='default'
            size='small'
            footer={<FooterButtons />}
        >
            <CreateProjectForm onValid={handleFormValidate} />
        </Modal>
    );
};
