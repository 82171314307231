import { ChangeEvent, FC, useRef, useState } from 'react';

import { SFileManagerSearch } from './SFileManager';

import { uiColors } from '../../config/config';
import { IconButton } from '../IconButton/IconButton';
import { InputField } from '../InputField/InputField';

export const FileManagerSearch: FC<{ onSearch: (value: string) => void }> = ({ onSearch }) => {
    const [value, setValue] = useState('');
    const ref = useRef(null);

    const handleChange = (event: ChangeEvent) => {
        const target = event.target as HTMLInputElement;
        setValue(target.value);
        onSearch && onSearch(target.value);
    };

    const handleClear = () => {
        onSearch('');
        setValue('');
    };

    return (
        <SFileManagerSearch>
            <InputField ref={ref} value={value} onChange={handleChange} />
            {value.length > 0 && <IconButton fill={uiColors.darkGrey} noHover name={'close'} onClick={handleClear} />}
        </SFileManagerSearch>
    );
};
