import { FC, MouseEvent } from 'react';
import { createPortal } from 'react-dom';

import styled from 'styled-components';

import { ModalBody } from './ModalBody/ModalBody';
import { ModalFooter } from './ModalFooter/ModalFooter';
import { ModalHeader } from './ModalHeader/ModalHeader';
import { IModal, IStyledModal, IStyledModalOverlay } from './interfaces';
import { sizes, slide, styles } from './options';

import { uiRGBColors, pxToRem, radius, shadow, zIndex } from '../../config/config';

const StyledModalOverlay = styled.div<IStyledModalOverlay>`
    background-color: ${({ isOpen }) => (isOpen ? `rgba(${uiRGBColors.black}, 0.5)` : 'transparent')};
    display: flex;
    height: 100vh;
    left: 0;
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    transition: opacity 0.3s ease-in-out;
    padding: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: ${({ isOpen }) => (isOpen ? zIndex.top + zIndex.header : zIndex.below)};
    z-index: ${({ type, isOpen }) => type === 'confirm' && isOpen && zIndex.top + zIndex.header + 1};
`;

const StyledModal = styled.div<IStyledModal>`
    animation: ${slide} 0.2s linear;
    background-color: var(--ui-sidebar);
    border: 0;
    border-radius: ${radius.default};
    box-shadow: ${shadow.default};
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 0;
    max-height: calc(100vh - (${styles.overlayPadding} * 2));
    min-height: ${({ type }) => (type !== 'confirm' ? `${pxToRem(250)}` : `${pxToRem(150)}`)};
    min-width: ${pxToRem(300)};
    max-width: ${({ size }) => sizes[size]};
    max-width: ${({ type }) => type === 'confirm' && `${pxToRem(400)}`};
    overflow: hidden;
    width: 100%;

    @media screen and (max-width: 768px) {
        margin: auto ${pxToRem(24)};
    }

    > * {
        width: 100%;
    }
`;

export const Modal: FC<IModal> = ({
    id,
    type,
    children,
    title = 'modal title',
    isOpen,
    onClose,
    footer = null,
    hasOverlay = true,
    size = 'small',
    hasCloseButton = true,
}) => {
    const overlayClickHandler = (event: MouseEvent) => {
        event.nativeEvent.stopImmediatePropagation();
        return false;
    };

    return createPortal(
        <StyledModalOverlay
            overlay={hasOverlay}
            type={type}
            isOpen={isOpen}
            onContextMenu={(e) => overlayClickHandler(e)}
        >
            <StyledModal type={type} id={id} size={size} isOpen={isOpen}>
                <ModalHeader hasCloseButton={hasCloseButton} onClose={() => onClose(id)}>
                    {title}
                </ModalHeader>
                <ModalBody>{children}</ModalBody>
                <ModalFooter>{footer}</ModalFooter>
            </StyledModal>
        </StyledModalOverlay>,
        document.getElementsByTagName('body')[0]
    );
};
