import { IUserAccount, TAccountAddFormData, TAccountDeleteFormData, TAccountEdiFormtData } from '@repeat/models';

interface IGetStudents {
    (signal?: AbortSignal): Promise<IUserAccount[]>;
}

type ReturnAddStudentContract = {
    status: string;
};

interface IAddStudent {
    (payload: TAccountAddFormData, signal?: AbortSignal): Promise<ReturnAddStudentContract>;
}

type ReturnEditStudentContract = {
    status: string;
};

interface IEditStudent {
    (payload: TAccountEdiFormtData, signal?: AbortSignal): Promise<ReturnEditStudentContract>;
}

type ReturnDeleteProjectContract = {
    status: string;
};

interface IDeleteStudent {
    (payload: Pick<TAccountDeleteFormData, 'id'>, signal?: AbortSignal): Promise<ReturnDeleteProjectContract>;
}

export default class AccountAdministrationService {
    static getAccounts: IGetStudents = async (payload: any, signal?: AbortSignal) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve([
                    { id: 0, userName: 'Иванов А.П', userRole: 'DEFAULT', userRegistrationDate: '22.03.2023' },
                    { id: 1, userName: 'Петров Д.И', userRole: 'ADMINISTRATOR', userRegistrationDate: '26.02.2023' },
                ]);
            }, 1000);
        });
    };

    static addAccount: IAddStudent = async (payload: TAccountAddFormData, signal?: AbortSignal) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({
                    status: 'Аккаунт добавлен',
                });
            }, 1000);
        });
    };

    static editAccount: IEditStudent = async (payload: TAccountEdiFormtData, signal?: AbortSignal) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({
                    status: 'Изменения сохранены',
                });
            }, 1000);
        });
    };

    static deleteAccount: IDeleteStudent = async (payload: Pick<TAccountDeleteFormData, 'id'>, signal?: AbortSignal) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                reject({
                    status: 'Изменения сохранены',
                });
            }, 1000);
        });
    };
}
