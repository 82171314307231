import { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

import { ProjectsPageSubLayout } from './ProjectsPageSubLayout';

import { Projects } from '../../features/Project/Projects';

const messages = defineMessages({
    [TranslationKey.PAGE_PROJECTS_TITLE]: {
        id: TranslationKey.PAGE_PROJECTS_TITLE,
        defaultMessage: ru[TranslationKey.PAGE_PROJECTS_TITLE],
    },
});

export const ProjectsPage: FC = () => {
    const intl = useIntl();

    return (
        <ProjectsPageSubLayout title={intl.formatMessage(messages[TranslationKey.PAGE_PROJECTS_TITLE])}>
            <Projects />
        </ProjectsPageSubLayout>
    );
};
