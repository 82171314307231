import React, { useReducer } from 'react';

import BlockDescription from './BlockDescriptionModal';
import { BDAction, blockDescriptionModalReducer } from './blockDescriptionModalReducer';

export interface IBlockDescription {
    id: string;
    name: string;
    description: string;
}

export const ModalContext = React.createContext<{
    modals: IBlockDescription[];
    modalDispatch: React.Dispatch<BDAction> | null;
    openModal: (data: IBlockDescription) => void | null;
    closeModal: (modalId: string) => void | null;
}>({
    modals: [],
    modalDispatch: null,
    openModal: () => null,
    closeModal: () => null,
});

export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [modals, modalDispatch] = useReducer(blockDescriptionModalReducer, []);

    const openModal = (data: IBlockDescription) => {
        modalDispatch({ type: 'OPEN_BD_MODAL', payload: data });
    };

    const closeModal = (modalId: string) => {
        modalDispatch({ type: 'CLOSE_BD_MODAL', payload: modalId });
    };

    return (
        <ModalContext.Provider value={{ modals, modalDispatch, openModal, closeModal }}>
            {children}
            {modals.map((modal) => (
                <BlockDescription key={modal.id} id={modal.id} name={modal.name} description={modal.description} />
            ))}
        </ModalContext.Provider>
    );
};
