import { createSlice } from '@reduxjs/toolkit';

import { ISchemaState, LibraryTypes, SolverTypes, Statuses } from '@repeat/models';

import { elementsReducers } from './elementsSlice';
import { selectedItemsReducers } from './selectedItemsSlice';
import { wiresReducers } from './wiresSlice';

export const initialState: ISchemaState = {
    libraryType: LibraryTypes.ELECTROCITY,
    solverType: SolverTypes.USDS,
    schemaItems: {
        elements: [],
        wires: [],
    },
    getProject: {
        status: Statuses.IDLE,
        error: null,
    },
    saveProject: {
        status: Statuses.IDLE,
        error: null,
    },
    getElementPropertiesSets: {
        status: Statuses.IDLE,
        error: null,
    },
    elementInitialization: {
        elementId: null,
        projectId: null,
        fileName: null,
        data: null,
        uploadElementSourcesFile: {
            fileSizeBytes: 0,
            uploadedBytes: 0,
            status: Statuses.IDLE,
            error: null,
        },
        initializeElementByFile: {
            status: Statuses.IDLE,
            error: null,
        },
    },
    selectedItems: [],
    itemsToCopy: [],
};

const reducers = { ...elementsReducers, ...wiresReducers, ...selectedItemsReducers };

export const schemaSlice = createSlice({
    name: 'schema',
    initialState,
    reducers,
});

export const { actions, reducer } = schemaSlice;
