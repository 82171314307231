import { ApplicationActions } from '@repeat/common-slices';
import { IWorkspaceState, NotificationTypes, Statuses, TSchemaConnection } from '@repeat/models';
import { ShortCircuitService } from '@repeat/services';
import { AppDispatch, RootStateFn } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';

import { portIdSplit } from './modelControl/modelControlSlice';

import { actions } from './index';

interface IShortCircuitCSVData {
    bus_name: string;
    initial_fault_current: string;
    fault_current: string;
    thermal_equivalent_current: string;
}

const SCErrors: Record<string, string> = {
    '1084': TranslationKey.ERROR_SC_RESPONSE,
    '1001': TranslationKey.ERROR_SEND_REQUEST,
};

export const shortCircuitReducer = {
    shortCircuitRequest: (state: IWorkspaceState) => ({
        ...state,
        tools: {
            ...state.tools,
            shortCircuit: {
                ...state.tools.shortCircuit,
                status: Statuses.LOADING,
                error: false,
            },
        },
    }),
    shortCircuitSuccess: (state: IWorkspaceState) => ({
        ...state,
        tools: {
            ...state.tools,
            shortCircuit: {
                ...state.tools.shortCircuit,
                status: Statuses.SUCCEEDED,
                error: false,
            },
        },
    }),
    shortCircuitError: (state: IWorkspaceState) => ({
        ...state,
        tools: {
            ...state.tools,
            shortCircuit: {
                ...state.tools.shortCircuit,
                status: Statuses.FAILED,
                error: true,
            },
        },
    }),
};

export const shortCircuitCalculate =
    (options: Record<string, string>, downloadCSV: (csv: IShortCircuitCSVData[]) => void) =>
    async (dispatch: AppDispatch, getState: RootStateFn) => {
        const {
            schema: {
                solverType,
                libraryType,
                schemaItems: { elements, wires },
            },
            settings,
        } = getState().workspace;

        const requestedElements = elements.map(
            ({ id, position, data: { type, name, index, elemProps, elemParams } }) => ({
                id,
                position,
                type,
                elemProps,
                elemParams,
                index,
                name,
            })
        );

        const task = getState().task;
        const projectId = localStorage.getItem('projectId') as string;

        try {
            dispatch(actions.shortCircuitRequest());

            const requestData = {
                elements: requestedElements,
                wires: wires.map((connection: TSchemaConnection) => ({
                    id: Number(connection.id),
                    firstElement: Number(connection.source),
                    firstPort: portIdSplit(connection.sourceHandle).portName,
                    secondElement: Number(connection.target),
                    secondPort: portIdSplit(connection.targetHandle).portName,
                    type: connection.data?.type,
                    wireParams: connection.data?.wireParams,
                    wireProps: connection.data?.wireProps,
                })),
                libraryType,
                solverType,
                projectId,
            };

            const dataHeader = { ...task, ...settings };

            const response = await ShortCircuitService.calculate(projectId, {
                ...options,
                data: { ...requestData },
                header: dataHeader,
            });

            if (response.data) {
                setTimeout(() => dispatch(actions.shortCircuitSuccess()), 300);

                return downloadCSV(response.data);
            }
        } catch (e: any) {
            dispatch(actions.shortCircuitError());

            if (e?.response?.data && e?.response?.data?.errors) {
                const codes = e?.response?.data?.errors.map((error: Record<string, string>) => error['code']);
                return codes.forEach((code: string) => {
                    dispatch(
                        ApplicationActions.showNotification({
                            notification: {
                                type: NotificationTypes.ERROR,
                                message: SCErrors[code],
                            },
                        })
                    );
                });
            }

            return dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: TranslationKey.ERROR_UNKNOWN,
                    },
                })
            );
        }
    };
