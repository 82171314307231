
import { ILibraryItem, IWorkspaceState } from '@repeat/models';
import { PayloadAction } from '@reduxjs/toolkit';


export const draggableLibraryItemReducers = {
    setDraggableLibraryItem: (state: IWorkspaceState, action: PayloadAction<ILibraryItem>) => ({
        ...state,
        draggableLibraryItem: {
            item: action.payload,
        },
    }),
};
