import React, { Children, FC, useEffect, useRef, useState } from 'react';

import { SDropdownContainer, SDropdownMenu, SDropdownMenuItem, SDropdownTrigger } from './SDropdown';

interface IDropdown {
    trigger: React.ReactNode | React.ReactElement;
    children: React.ReactNode;
    clickOutside?: boolean;
    onMouseOver?: (event: React.MouseEvent) => void;
}

export const Dropdown: FC<IDropdown> = ({ children, trigger, clickOutside = false, ...rest }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (clickOutside) {
            document.body.addEventListener('click', (event) => {
                if (!dropdownRef.current) {
                    return;
                }
                const target = event.target as HTMLElement;
                if (!target) {
                    return;
                }
                const hasClickedTrigger = dropdownRef.current.contains(target);
                const closeTarget = dropdownRef.current;

                if (!hasClickedTrigger && closeTarget && !closeTarget.contains(target)) {
                    setIsOpen(false);
                }
            });
            return () => {
                document.body.removeEventListener('click', () => setIsOpen(false));
            };
        }
        return;
    }, []);

    const handleOnMouseUp = () => {
        setIsOpen(false);
    };
    const DropdownChildren =
        children && Children.map(children, (child) => <SDropdownMenuItem>{child}</SDropdownMenuItem>);

    return (
        <SDropdownContainer ref={dropdownRef}>
            <SDropdownTrigger data-open={isOpen} onClick={() => setIsOpen(true)}>
                {trigger}
            </SDropdownTrigger>
            <SDropdownMenu onMouseUp={handleOnMouseUp} role='menu' isOpen={isOpen} {...rest}>
                {DropdownChildren}
            </SDropdownMenu>
        </SDropdownContainer>
    );
};
