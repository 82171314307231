import { createContext, FC, useEffect, useState } from 'react';

import { Steps } from 'intro.js-react';

import 'intro.js/introjs.css';

import './Tour.css';

interface ITourContext {
    open: boolean;
    setOpen: (state: boolean) => void;
}

export interface ITourOptions {
    showProgress?: boolean;
    nextLabel?: string;
    prevLabel?: string;
    skipLabel?: string;
    doneLabel?: string;
    overlayOpacity?: number;
    tooltipPosition?: string;
}

export interface IStep {
    element: string;
    intlTitleKey: string;
    createIntlIntro: React.ReactNode;
    position: string;
    callback?: () => void;
    onExit?: () => void;
    intro: string;
}

export const TourContext = createContext<ITourContext>({
    open: false,
    setOpen: () => null,
});

export const TourProvider = TourContext.Provider;

export interface ITour {
    steps: IStep[];
    isTourOpen: boolean;
    options: ITourOptions;
    children?: React.ReactNode;
}

export const Tour: FC<ITour> = ({ children, steps, isTourOpen = false, options }) => {
    const [isOpen, setIsOpen] = useState(isTourOpen || false);
    const [renderTutorial, setRenderTutorial] = useState(false);

    useEffect(() => {
        setTimeout(setRenderTutorial, 1000, true);
    }, []);

    return (
        <TourProvider value={{ open: isOpen, setOpen: (state: boolean) => setIsOpen(state) }}>
            {children}
            {renderTutorial && (
                <Steps
                    initialStep={0}
                    steps={steps}
                    enabled={isOpen}
                    onExit={(stepIndex) => {
                        const step = steps[stepIndex];
                        if (step && step.onExit) {
                            step.onExit();
                        }
                        setIsOpen(false);
                    }}
                    options={Object.assign(
                        {
                            showProgress: false,
                            nextLabel: 'Дальше',
                            prevLabel: 'Назад',
                            skipLabel: '',
                            doneLabel: 'Завершить',
                            overlayOpacity: 0.3,
                            tooltipPosition: 'bottom-middle-aligned',
                        },
                        options
                    )}
                    onBeforeChange={(nextIndex) => {
                        const step = steps[nextIndex];
                        if (step && step.callback) {
                            step.callback();
                        }
                        if (step && step.onExit) {
                            step.onExit();
                        }
                    }}
                />
            )}
        </TourProvider>
    );
};
