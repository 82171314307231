import { ElemParams, IWatchingParameterOption, TSchemaNode } from '@repeat/models';

export const extractParametersForVisualization = (elemParams: ElemParams[], parametersToDisplay: string[] | null) => {
    if (parametersToDisplay === null || parametersToDisplay === undefined) {
        return elemParams;
    } else if (parametersToDisplay && parametersToDisplay?.length !== 0) {
        return parametersToDisplay.map((str) => elemParams.find((param) => param.name.includes(str))) as ElemParams[];
    } else if (parametersToDisplay && parametersToDisplay.length === 0) {
        return [];
    } else {
        return elemParams;
    }
};

export const getElementsOptions = (elements: TSchemaNode[]): IWatchingParameterOption[] => {
    return elements
        .map((element) => {
            const elementParameters = extractParametersForVisualization(
                element.data.elemParams,
                element.data.parametersToDisplay
            );
            return elementParameters
                .filter((parameter) => {
                    // parameter.name.indexOf('in_') === -1; // fix error
                    const exp = new RegExp('in_');
                    if (parameter && parameter.name) {
                        return (
                            !exp.test(parameter.name) &&
                            (!Object.prototype.hasOwnProperty.call(parameter, 'isVisible') ||
                                parameter.isVisible === true)
                        );
                    }
                    return;
                }) // исключаем входные порты
                .map((parameter) => {
                    const parameterTitle = parameter?.unit
                        ? `${parameter?.description}, ${parameter?.unit}`
                        : `${parameter?.description}`;
                    return {
                        key: `${element.data.id}_${parameter.name}`,
                        title: `${element.data.name} [${element.data.index}] / ${parameterTitle}`,
                        elementTitle: `${element.data.name} [${element.data.index}]`,
                        parameterTitle: parameterTitle,
                    };
                });
        })
        .flat();
};

export const compileParameterOptions = (
    nodes: TSchemaNode[],
    selectedNodes: TSchemaNode[]
): IWatchingParameterOption[] => {
    const selectedNodesIds = selectedNodes.map((n) => n.id);
    const filteredNodes = selectedNodesIds.length > 0 ? nodes.filter((n) => selectedNodesIds.includes(n.id)) : nodes;
    return getElementsOptions(filteredNodes);
};
