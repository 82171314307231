import { IBlockDescription } from './BlockDescriptionModalContext';

export type BDAction =
    | { type: 'OPEN_BD_MODAL'; payload: IBlockDescription }
    | { type: 'CLOSE_BD_MODAL'; payload: string };

export const blockDescriptionModalReducer = (state: IBlockDescription[], action: BDAction): IBlockDescription[] => {
    switch (action.type) {
        case 'OPEN_BD_MODAL':
            if (state.find((bd) => bd.name === action.payload.name)) {
                return state;
            } else {
                return [...state, action.payload];
            }
        case 'CLOSE_BD_MODAL':
            return state.filter((bd) => bd.id !== action.payload);
        default:
            return state;
    }
};
