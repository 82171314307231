import { PayloadAction, current } from '@reduxjs/toolkit';

import {
    IWorkspaceState,
    SchemaItemTypes,
    TSchemaConnection,
    TSchemaItemType,
    TSchemaNode,
    TWorkspaceMode,
    WorkspaceModes,
} from '@repeat/models';

import { getIsDemoMode } from '@repeat/hooks';
import { initialState } from '../schemaSlice';

export const SelectElementsSlice = {
    // selection
    setSelectedItem: (state: IWorkspaceState, action: PayloadAction<{ id: string; type: TSchemaItemType }>) => {
        const { id, type } = action.payload;
        const metaElementId = state.meta.elementId;
        const groups = state.schema.schemaItems.groups || [];
        let nodes: TSchemaNode[] = [];
        let connections: TSchemaConnection[] = [];

        if (metaElementId === null) {
            nodes =
                type === SchemaItemTypes.NODE
                    ? [...state.schema.schemaItems.elements].filter((node: TSchemaNode) => node.id === id)
                    : [];
            connections =
                type === SchemaItemTypes.CONNECTION
                    ? [...state.schema.schemaItems.wires].filter(
                          (connection: TSchemaConnection) => connection.id === id
                      )
                    : [];
        } else {
            const currentGroup = current(groups).find((group) => group.id.toString() === metaElementId);
            nodes =
                type === SchemaItemTypes.NODE && currentGroup
                    ? [...currentGroup.elements].filter((node: TSchemaNode) => node.id === id)
                    : [];
            connections =
                type === SchemaItemTypes.CONNECTION && currentGroup
                    ? [...currentGroup.wires].filter((connection: TSchemaConnection) => connection.id === id)
                    : [];
        }

        return {
            ...state,
            schema: {
                ...state.schema,
                selectedItems: {
                    elements: nodes,
                    wires: connections,
                },
            },
        };
    },
    setSelectedItems: (state: IWorkspaceState, action: PayloadAction<{ ids: string[]; type: TSchemaItemType }>) => {
        const { ids, type } = action.payload;
        const { mode, elementId, groupId } = state.meta;
        let nodes: TSchemaNode[] = [];
        let connections: TSchemaConnection[] = [];

        const currentGroup = state.schema.schemaItems.groups?.find((group) => group.id.toString() === elementId);
        let currentGroupUserBlock;
        const workspaceMetaUserBlockId = state.meta.userBlockId;
        const isDemo = getIsDemoMode();
        const isGettingUserBlockViewMode = workspaceMetaUserBlockId && isDemo;

        if (type === SchemaItemTypes.NODE) {
            const nodesFilteredMain = state.schema.schemaItems.elements.filter((node: TSchemaNode) =>
                ids.includes(node.id)
            );
            let currentElement;
            if (groupId === null) {
                currentElement = state.schema.schemaItems.elements.find((el: TSchemaNode) => el.id === elementId);
            } else {
                const userBlockGroups = state.schema.schemaItems.userBlockGroups || [];

                const currentGroup = isGettingUserBlockViewMode
                    ? userBlockGroups?.find((group) => group.id.toString() === elementId) ||
                      userBlockGroups?.find((group) => group.parentGroupId === null)
                    : state.schema.schemaItems.groups?.find((group) => group.id.toString() === groupId);
                currentGroupUserBlock = currentGroup;

                if (currentGroup && !isGettingUserBlockViewMode) {
                    currentElement = currentGroup.elements.find((el: TSchemaNode) => el.id === elementId);
                }
            }

            const nodesFilteredSubmodel =
                currentElement && currentElement.data.submodelItems
                    ? currentElement.data.submodelItems.elements.filter((node: TSchemaNode) => ids.includes(node.id))
                    : [];
            let nodesFilteredGroup: TSchemaNode[] = [];
            if (isGettingUserBlockViewMode) {
                nodesFilteredGroup = currentGroupUserBlock
                    ? currentGroupUserBlock.elements.filter((node: TSchemaNode) => ids.includes(node.id))
                    : [];
            } else {
                nodesFilteredGroup = currentGroup
                    ? currentGroup.elements.filter((node: TSchemaNode) => ids.includes(node.id))
                    : [];
            }

            const setFilteredNodes = (mode: TWorkspaceMode) => {
                switch (mode) {
                    case WorkspaceModes.SUBMODEL:
                        return nodesFilteredSubmodel;
                    case WorkspaceModes.GROUP:
                        return nodesFilteredGroup;
                    default:
                        return nodesFilteredMain;
                }
            };

            const nodesFiltered = setFilteredNodes(mode);

            nodes = ids.length ? nodesFiltered : [];
            connections = state.schema.selectedItems.wires;
        } else if (type === SchemaItemTypes.CONNECTION) {
            const connectionsFilteredMain = state.schema.schemaItems.wires.filter((connection: TSchemaConnection) =>
                ids.includes(connection.id)
            );
            const connectionsFilteredGroup = currentGroup
                ? currentGroup.wires.filter((connection: TSchemaConnection) => ids.includes(connection.id))
                : [];

            const connectionsFiltered =
                mode === WorkspaceModes.GROUP ? connectionsFilteredGroup : connectionsFilteredMain;

            nodes = state.schema.selectedItems.elements;
            connections = ids.length ? connectionsFiltered : [];
        }

        return {
            ...state,
            schema: {
                ...state.schema,
                selectedItems: {
                    elements: nodes,
                    wires: connections,
                },
            },
        };
    },
    setItemsForSubmodel: (state: IWorkspaceState, action: PayloadAction<{ ids: string[]; type: TSchemaItemType }>) => {
        const { ids, type } = action.payload;
        const { mode, elementId } = state.meta;
        let nodes: TSchemaNode[] = [];
        let connections: TSchemaConnection[] = [];

        if (type === SchemaItemTypes.NODE) {
            const nodesFilteredMain = state.schema.schemaItems.elements.filter((node: TSchemaNode) =>
                ids.includes(node.id)
            );
            const currentElement = state.schema.schemaItems.elements.find((el: TSchemaNode) => el.id === elementId);

            const nodesFilteredSubmodel =
                currentElement && currentElement.data.submodelItems
                    ? currentElement.data.submodelItems.elements.filter((node: TSchemaNode) => ids.includes(node.id))
                    : [];
            const nodesFiltered = mode === WorkspaceModes.SUBMODEL ? nodesFilteredSubmodel : nodesFilteredMain;

            nodes = ids.length ? nodesFiltered : [];
            connections = state.schema.selectedItems.wires;
        } else if (type === SchemaItemTypes.CONNECTION) {
            const connectionsFiltered = state.schema.schemaItems.wires.filter((connection: TSchemaConnection) =>
                ids.includes(connection.id)
            );
            nodes = state.schema.selectedItems.elements;
            connections = ids.length ? connectionsFiltered : [];
        }

        return {
            ...state,
            schema: {
                ...state.schema,
                itemsForSubmodel: {
                    elements: nodes,
                    wires: connections,
                },
            },
        };
    },
    selectAllItems: (state: IWorkspaceState) => {
        const metaElementId = state.meta.elementId;
        const groups = state.schema.schemaItems.groups || [];
        if (metaElementId === null) {
            return {
                ...state,
                schema: {
                    ...state.schema,
                    selectedItems: {
                        elements: [...state.schema.schemaItems.elements],
                        wires: [...state.schema.schemaItems.wires],
                    },
                },
            };
        } else {
            const currentGroup = groups.find((group) => group.id.toString() === metaElementId);
            if (!currentGroup) {
                return state;
            }
            return {
                ...state,
                schema: {
                    ...state.schema,
                    selectedItems: {
                        elements: [...currentGroup.elements],
                        wires: [...currentGroup.wires],
                    },
                },
            };
        }
    },
    clearSelectedItems: (state: IWorkspaceState) => ({
        ...state,
        schema: {
            ...state.schema,
            selectedItems: initialState.selectedItems,
        },
    }),
    setSelectedGroup: (
        state: IWorkspaceState,
        action: PayloadAction<{ nodes: TSchemaNode[]; connections: TSchemaConnection[] }>
    ) => ({
        ...state,
        schema: {
            ...state.schema,
            selectedItems: {
                ...state.schema.selectedItems,
                elements: action.payload.nodes,
                wires: action.payload.connections,
            },
        },
    }),
};
