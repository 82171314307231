import { $api } from './http';

interface IUploadHeaders {
    [key: string]: string | number;
}

export default class UploadService {
    readonly chunkSize: number;
    readonly apiEndpoint: string;
    readonly file: File;

    currentChunkStartByte: number;
    currentChunkFinalByte: number;
    uploadedBytes: number;
    uuid: string | null;

    headers: IUploadHeaders;

    constructor(apiEndpoint: string, file: File, headers: IUploadHeaders = {}, chunkSize?: number) {
        this.apiEndpoint = apiEndpoint;
        this.chunkSize = chunkSize || 0;
        this.file = file;
        this.headers = { ...headers };

        this.currentChunkStartByte = 0;
        this.currentChunkFinalByte =
            this.chunkSize > 0 ? (this.chunkSize > this.file.size ? this.file.size : this.chunkSize) : this.file.size;
        this.uploadedBytes = 0;
        this.uuid = null;
    }

    async uploadFile<T>(
        onChunkUploaded?: (uploadedBytes: number) => void,
        onUploaded?: (file: T) => void
    ): Promise<boolean> {
        const chunk: Blob = this.file.slice(this.currentChunkStartByte, this.currentChunkFinalByte);

        const formData = new FormData();
        formData.append('file', chunk, this.file.name);

        let headers: IUploadHeaders = {
            ...this.headers,
            'Content-Range': `bytes ${this.currentChunkStartByte}-${this.currentChunkFinalByte}/${this.file.size}`,
        };
        if (this.uuid !== null) {
            headers = {
                ...headers,
                Uuid: this.uuid as string,
            };
        }
        const response = await $api.post(this.apiEndpoint, formData, {
            headers,
        });

        const remainingBytes = this.file.size - this.currentChunkFinalByte;

        if (onChunkUploaded) {
            onChunkUploaded(this.currentChunkFinalByte);
        }

        if (remainingBytes > 0) {
            if (remainingBytes < this.chunkSize) {
                this.currentChunkStartByte = this.currentChunkFinalByte;
                this.currentChunkFinalByte = this.currentChunkStartByte + remainingBytes;
            } else {
                this.currentChunkStartByte = this.currentChunkFinalByte;
                this.currentChunkFinalByte = this.currentChunkStartByte + this.chunkSize;
            }

            if (response.headers['uuid'] !== undefined) {
                this.uuid = response.headers['uuid'];
            }

            return await this.uploadFile(onChunkUploaded, onUploaded);
        }

        if (onUploaded) {
            onUploaded(response.data);
        }

        return new Promise((resolve) => resolve(true));
    }
}
