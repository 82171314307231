import { configureStore } from '@reduxjs/toolkit';
import { ThunkDispatch } from 'redux-thunk';

import { LOCAL_STORAGE_APP_REDUX_STATE_KEY } from '@repeat/constants';
import { environment } from '@repeat/environment';

import { AppActions } from './actions';
import { reduceStoreByKeys } from './initState';
import listenerMiddleware from './listeners/rootListener';
import livePermissionsMiddleware from './middleware/livePermissionsMiddleware';
import rootReducer from './reducers/rootReducer';

const { production } = environment || { production: false };
const isDevelopmentEnv = !production;

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().prepend(listenerMiddleware.middleware).concat([livePermissionsMiddleware]),
    devTools: isDevelopmentEnv,
});

store.subscribe(() => {
    const persistedState = reduceStoreByKeys(
        ['app', 'appUser', 'projects', 'workspace', 'task', 'fileManager'],
        store.getState()
    );
    window.localStorage.setItem(LOCAL_STORAGE_APP_REDUX_STATE_KEY, JSON.stringify(persistedState));
});

export type RootState = ReturnType<typeof store.getState>;

export type RootStateFn = () => RootState;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch & ThunkDispatch<RootState, any, AppActions>;

export { store };
