import { changeHandleId, markConnectionsPortsAsUnconnected } from '@repeat/constants';
import { ILibraryItem, PortTypes, TSchemaConnection, TSchemaGroup, TSchemaHandle, TSchemaNode } from '@repeat/models';

interface ISchemaItems {
    elements: TSchemaNode[];
    wires: TSchemaConnection[];
    groups: TSchemaGroup[];
}

const ELEMENT_DC_MACHINE_TYPE = '_DCMachine_Simple';

export const applyPatch_1_8_0 = (schemaItems: ISchemaItems, libraryItems: ILibraryItem[]): ISchemaItems => {
    const dcMachinesIds = schemaItems.elements
        .filter((node) => node.data.type === ELEMENT_DC_MACHINE_TYPE)
        .map((node) => node.id);

    const dcMachinePortsIds = schemaItems.elements
        .filter((node) => node.data.type === ELEMENT_DC_MACHINE_TYPE)
        .map((node) => `${node.id}-2-in_2`);
    const wiresToDelete = schemaItems.wires.filter(
        (wire) => dcMachinePortsIds.includes(wire.sourceHandle) || dcMachinePortsIds.includes(wire.targetHandle)
    );
    const portsNamesByNodeIdMap = markConnectionsPortsAsUnconnected(wiresToDelete);

    let nodes = schemaItems.elements;
    let connections = schemaItems.wires;

    if (dcMachinesIds.length !== 0) {
        nodes = schemaItems.elements.map((node: TSchemaNode) => {
            if (node.data.type === ELEMENT_DC_MACHINE_TYPE) {
                const libraryItem = libraryItems.find((item) => item.type === node.data.type);
                if (libraryItem) {
                    const { availablePorts, elemParams } = libraryItem;

                    const currentInputPorts = node.data.availablePorts.filter((p) => p.type === PortTypes.INPUT);
                    const currentOutputPorts = node.data.availablePorts.filter((p) => p.type === PortTypes.OUTPUT);
                    if (availablePorts) {
                        const libraryInputPorts = availablePorts.filter((p) => p.type === PortTypes.INPUT);
                        const libraryOutputPorts = availablePorts.filter((p) => p.type === PortTypes.OUTPUT);

                        const updatedInputPorts: TSchemaHandle[] = currentInputPorts;
                        let updatedOutputPorts: TSchemaHandle[] = currentOutputPorts;

                        if (currentInputPorts.length > libraryInputPorts?.length) {
                            const diff = currentInputPorts.length - libraryInputPorts.length;
                            updatedInputPorts.splice(-diff, diff);
                        }

                        if (currentOutputPorts.length < libraryOutputPorts.length) {
                            updatedOutputPorts = currentOutputPorts.map((p, index) => ({
                                ...p,
                                name: `out_${updatedInputPorts.length + 1 + index}`,
                            }));

                            let number = updatedOutputPorts.length + 1;
                            while (number <= libraryOutputPorts.length) {
                                updatedOutputPorts.push({
                                    ...updatedOutputPorts[updatedOutputPorts.length - 1],
                                    name: `out_${number + 1}`,
                                    isConnected: false,
                                });
                                number++;
                            }
                        }
                        const portsUpdated = [...updatedInputPorts, ...updatedOutputPorts];

                        return { ...node, data: { ...node.data, availablePorts: portsUpdated, elemParams } };
                    }
                }
            }
            const ports = node.data.availablePorts.map((port) => {
                if (portsNamesByNodeIdMap[node.id] && portsNamesByNodeIdMap[node.id].includes(port.name)) {
                    return { ...port, isConnected: false };
                }
                return port;
            });

            return { ...node, data: { ...node.data, availablePorts: ports } };
        });

        const dcMachineOutputPortsIds = schemaItems.elements
            .filter((node) => node.data.type === ELEMENT_DC_MACHINE_TYPE)
            .map((node) => [`${node.id}-3-out_3`, `${node.id}-4-out_4`])
            .flat();

        const wiresConnectedToDCMachinesOutputs = schemaItems.wires.filter(
            (wire) =>
                dcMachineOutputPortsIds.includes(wire.sourceHandle) ||
                dcMachineOutputPortsIds.includes(wire.targetHandle)
        );

        const newPortsNamesByOldPortsNames: { [key: string]: string } = { out_3: 'out_2', out_4: 'out_3' };

        const newWires = wiresConnectedToDCMachinesOutputs.map((wire) => {
            if (dcMachinesIds.some((id) => wire.sourceHandle.includes(id))) {
                return { ...wire, sourceHandle: changeHandleId(wire.sourceHandle, newPortsNamesByOldPortsNames) };
            } else if (dcMachinesIds.some((id) => wire.targetHandle.includes(id))) {
                return { ...wire, targetHandle: changeHandleId(wire.targetHandle, newPortsNamesByOldPortsNames) };
            }
            return wire;
        });

        const dcMachineDeletedPortsIds = schemaItems.elements
            .filter((node) => node.data.type === ELEMENT_DC_MACHINE_TYPE)
            .map((node) => `${node.id}-2-in_2`);

        const updatedWires = schemaItems.wires.filter(
            (wire) =>
                !dcMachineDeletedPortsIds.includes(wire.sourceHandle) &&
                !dcMachineDeletedPortsIds.includes(wire.targetHandle)
        );
        const oldWires = updatedWires.filter((x) => !wiresConnectedToDCMachinesOutputs.some((y) => x.id === y.id));

        connections = [...oldWires, ...newWires];
    }

    return {
        ...schemaItems,
        elements: nodes,
        wires: connections,
        groups: schemaItems.groups || [],
    };
};
