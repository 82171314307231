declare global {
    interface Window {
        _env_: {
            API_URL: string;
            WS_URL: string;
            AUTH_DOMAIN: string;
            ACCOUNT_DOMAIN: string;
            REPEAT_DOMAIN: string;
            DISTRIB?: string | null;
            NOTEBOOK_URL: string;
            DOCS_URL: string;
            APP_BUILD?: string;
        };
    }
}

window._env_ = window._env_ || {};

export {};
