import { IProjectSettingsOptions, TSchemaNode } from '@repeat/models';

const calculate = (portsCount: number, dt = 0, t = 0) => {
    return 5 + (portsCount * (1000 / dt) * t * 40) / 1000000;
};

export const memoryCalculation = (
    elements: TSchemaNode[],
    projectSettings: IProjectSettingsOptions,
    cb: (value: number) => void
) => {
    let elementsPortCount = 0;
    const elementDt = Number(projectSettings.discretizationStep);
    const elementSt = Number(projectSettings.modellingTime);
    elements.forEach((item: TSchemaNode) => {
        const itemPorts = item.data.availablePorts.length;
        elementsPortCount += itemPorts;
    });
    const value = calculate(elementsPortCount, elementDt, elementSt);
    if (!isNaN(value)) {
        cb(value);
    }
};
