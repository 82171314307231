import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { TranslationKey } from '@repeat/translations';
import { IconButton } from '@repeat/ui-kit';

import { SInputField, SLibraryItemsSearchContainer } from './SLibraryItemsSearch';

import { messages } from '../translation';

interface ILibraryItemsSearch {
    onChange: React.ChangeEventHandler;
    onClose: React.MouseEventHandler;
    filter: { query: string };
    libraryItemsState: { error?: string | null };
}

export const LibraryItemsSearch: FC<ILibraryItemsSearch> = ({ onChange, onClose, filter, libraryItemsState }) => {
    const intl = useIntl();

    const getErrorMessage = () => {
        return intl.formatMessage(messages[TranslationKey.ERROR_LIBRARY_ITEMS]);
    };

    return (
        <SLibraryItemsSearchContainer data-name={'elements-search'}>
            <SInputField
                placeholder={intl.formatMessage(messages[TranslationKey.FILTER_BY_NAME])}
                onChange={onChange}
                id={'search-library-items'}
                value={filter.query}
                size='small'
            />
            {filter.query.length > 0 && <IconButton noHover name={'close'} onClick={onClose} />}
            {libraryItemsState.error && <p style={{ color: 'red' }}>{getErrorMessage()}</p>}
        </SLibraryItemsSearchContainer>
    );
};
