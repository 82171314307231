import { FC, memo, useCallback, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ApplicationActions } from '@repeat/common-slices';
import { environment } from '@repeat/environment';
import { useAppDispatch } from '@repeat/hooks';
import { NotificationTypes } from '@repeat/models';
import { JupyterHubService } from '@repeat/services';
import { TranslationKey } from '@repeat/translations';

import { AbilityContext } from './../../../../context/Can';

import { ToolsCategory } from '../ToolsCategory';
import ToolsItem, { IToolsItem } from '../ToolsItem';
import { ToolsList } from '../ToolsList';
import { toolsIcons, toolsId, toolsType } from '../helpers';
import { messages } from '../translation';

export const ToolsStartContent: FC = memo(() => {
    const { locale, formatMessage } = useIntl();
    const dispatch = useAppDispatch();
    const ability = useContext(AbilityContext);

    const redirectToNotebookWithCore = useCallback(async () => {
        try {
            const response = await JupyterHubService.generateNotebookWithCoreLink(locale);
            if (response && response.data) {
                const link = response.data?.link || null;
                if (link) {
                    window.open(link, '_blank');
                } else {
                    dispatch(
                        ApplicationActions.showNotification({
                            notification: {
                                type: NotificationTypes.ERROR,
                                message: TranslationKey.WORKSPACE_NOTEBOOK_WITH_CORE_TOOL_LINK_ERROR,
                            },
                        })
                    );
                }
            }
        } catch (e) {
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: TranslationKey.WORKSPACE_NOTEBOOK_WITH_CORE_TOOL_LINK_ERROR,
                    },
                })
            );
        }
    }, [locale]);

    const toolsList: IToolsItem[] = useMemo(
        () => [
            {
                type: toolsType.COMMON,
                name: formatMessage(messages[TranslationKey.WORKSPACE_NOTEBOOK_TOOL]),
                icon: toolsIcons.NOTEBOOK,
                link: environment?.NOTEBOOK_URL,
                id: toolsId.NOTEBOOK,
            },
            {
                isActive: false,
                type: toolsType.COMMON,
                name: formatMessage(messages[TranslationKey.WORKSPACE_NOTEBOOK_WITH_CORE_TOOL]),
                icon: toolsIcons.NOTEBOOK,
                onClick: () => redirectToNotebookWithCore(),
                id: toolsId.NOTEBOOK_WITH_CORE,
            },
            ...(ability.can('use', `tool-${toolsType.ELECTRIC}`)
                ? [
                      {
                          type: toolsType.ELECTRIC,
                          name: formatMessage(messages[TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL]),
                          icon: toolsIcons.CIRCUIT,
                          id: toolsId.CIRCUIT,
                      },
                  ]
                : []),
            {
                isActive: false,
                type: toolsType.ELECTRIC,
                name: formatMessage(messages[TranslationKey.WORKSPACE_HARMONIC_TOOL]),
                icon: toolsIcons.HARMONIC,
                id: toolsId.HARMONIC,
            },
        ],
        []
    );

    const types: string[] = useMemo(() => {
        return Array.from(
            new Set(
                toolsList
                    .filter(
                        (tool) =>
                            tool?.isActive === undefined || (tool?.isActive !== undefined && tool.isActive === true)
                    )
                    .map((tool) => tool.type)
            )
        );
    }, [toolsList]);

    return (
        <ToolsList>
            {types.map((type: string, index) => {
                const name = formatMessage(
                    messages[TranslationKey[type as keyof typeof TranslationKey] as keyof typeof messages]
                );
                return (
                    <ToolsCategory name={name} key={`tolls-category-item-${index}`}>
                        {toolsList
                            .filter(
                                (tool) =>
                                    tool.type === type &&
                                    (tool?.isActive === undefined ||
                                        (tool?.isActive !== undefined && tool.isActive === true))
                            )
                            .map((tool) => (
                                <ToolsItem {...tool} key={tool.id} />
                            ))}
                    </ToolsCategory>
                );
            })}
        </ToolsList>
    );
});
