import { FC } from 'react';

import styled from 'styled-components';

import { uiRGBColors, pxToRem } from '../../../config/config';
import { IModalFooter } from '../interfaces';

const StyledModalFooter = styled.footer`
    border-top: 1px solid var(--ui-table-hover);
    flex: 0 0 auto;
    padding: ${pxToRem(16)};

    button {
        margin-right: ${pxToRem(16)};
    }

    &:empty {
        border: none;
    }
`;

export const ModalFooter: FC<IModalFooter> = ({ children }) => {
    return <StyledModalFooter>{children}</StyledModalFooter>;
};
