import styled from 'styled-components';

import { Mixins, pxToRem, uiColors } from '@repeat/ui-kit';

export const SModelControlWrapper = styled.div`
    font-size: ${pxToRem(12)};
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 300px;
    padding: 0;
    width: 100%;
`;

export const SModelControlItems = styled.div`
    ${Mixins.verticalAlign()};
    padding: 0 ${pxToRem(8)};
    width: 100%;
`;

export const SModelControlItem = styled.div`
    color: ${uiColors.white};
    ${Mixins.verticalAlign()};
    margin-left: ${pxToRem(16)};

    button {
        min-width: 24px;

        i {
            max-height: ${pxToRem(28)};
            max-width: ${pxToRem(28)};
        }
    }

    &:first-child,
    &:nth-child(2) {
        margin-right: ${pxToRem(8)};
    }
`;
export const SModelControlTime = styled.span`
    min-width: ${pxToRem(72)};
    text-align: right;
    text-wrap: nowrap;
`;

export const SModelControlStatus = styled.span`
    color: ${uiColors.white};
    width: 24px;
`;
