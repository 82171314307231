import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { TranslationKey } from '@repeat/translations';
import { ITour, ITourOptions, Tour as TourWithoutIntl } from '@repeat/ui-kit';

import { TTourTranslationKeys } from './SimpleTextWithIntl';
import { messages } from './translation';

export const Tour: FC<ITour> = ({ children, steps, isTourOpen = false }) => {
    const intl = useIntl();

    const intlSteps = useCallback(
        () =>
            steps.map((step) => {
                const titleKey = step.intlTitleKey as TTourTranslationKeys;
                return {
                    ...step,
                    title: intl.formatMessage(messages[titleKey]),
                    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                    /* @ts-ignore */
                    intro: step.createIntlIntro(intl),
                };
            }),
        [intl]
    );

    const tourOptions: ITourOptions = {
        nextLabel: intl.formatMessage(messages[TranslationKey.TOUR_NEXT]),
        prevLabel: intl.formatMessage(messages[TranslationKey.TOUR_PREV]),
        doneLabel: intl.formatMessage(messages[TranslationKey.TOUR_FINISH]),
    };

    return (
        <TourWithoutIntl steps={intlSteps()} isTourOpen={isTourOpen} options={tourOptions}>
            {children}
        </TourWithoutIntl>
    );
};
