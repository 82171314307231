import { FC } from 'react';
import { useIntl } from 'react-intl';

import styled from 'styled-components';

import { IElementConfiguration } from '@repeat/models';
import { TranslationKey } from '@repeat/translations';
import { Button, pxToRem, uiColors } from '@repeat/ui-kit';

import { messages } from '../translation';

interface ElementConfigurationTitleProps {
    title: string;
    elementId: number | string | undefined;
    config: IElementConfiguration;
    onClick: (elementId: number | string | undefined, configId: number | null) => void;
}

const SCurrentConfiguration = styled.div`
    color: ${uiColors.darkBlue};
`;
export const SButton = styled(Button)`
    display: none;
    width: ${pxToRem(100)};
    height: ${pxToRem(40)};
`;
const SConfigurationTitleBlock = styled.div`
    min-height: ${pxToRem(48)};
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const SConfigurationTitle = styled.span`
    font-size: ${pxToRem(16)};
`;

export const ElementConfigurationTitle: FC<ElementConfigurationTitleProps> = ({
    title,
    elementId,
    config,
    onClick,
}) => {
    const intl = useIntl();
    return (
        <SConfigurationTitleBlock>
            <SConfigurationTitle>{title}</SConfigurationTitle>
            {config.isSelected ? (
                <SCurrentConfiguration>
                    {intl.formatMessage(messages[TranslationKey.ELEMENT_CONFIGURATION_CURRENT])}
                </SCurrentConfiguration>
            ) : (
                <SButton onClick={() => onClick(elementId, config.id)}>
                    {intl.formatMessage(messages[TranslationKey.SELECT])}
                </SButton>
            )}
        </SConfigurationTitleBlock>
    );
};
