import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { TranslationKey } from '@repeat/translations';

import { projectMessages as messages } from '../translations';

export const useFileManagerTranslate = () => {
    const intl = useIntl();

    return useMemo(
        () => ({
            header: null,
            empty: intl.formatMessage(messages[TranslationKey.PROJECT_LIST_EMPTY]),
            selected: intl.formatMessage(messages[TranslationKey.FILE_MANAGER_SELECTED_COUNT]),
            elements: intl.formatMessage(messages[TranslationKey.FILE_MANAGER_FILES_COUNT]),
            remove: intl.formatMessage(messages[TranslationKey.PROJECT_DELETE_DROPDOWN]),
            open: intl.formatMessage(messages[TranslationKey.FILE_MANAGER_OPEN_FILE]),
            edit: intl.formatMessage(messages[TranslationKey.PROJECT_EDIT_DROPDOWN]),
            clone: intl.formatMessage(messages[TranslationKey.PROJECT_COPY_ID_DROPDOWN]),
            createdAt: intl.formatMessage(messages[TranslationKey.PROJECTS_COLUMN_CREATED_AT]),
            updatedAt: intl.formatMessage(messages[TranslationKey.PROJECTS_COLUMN_UPDATED_AT]),
            export: intl.formatMessage(messages[TranslationKey.PROJECT_EXPORT_DROPDOWN]),
            removeDialog: intl.formatMessage(messages[TranslationKey.FILE_MANAGER_REMOVE_DIALOG]),
            cancel: intl.formatMessage(messages[TranslationKey.CANCEL]),
            importTitle: intl.formatMessage(messages[TranslationKey.PROJECT_IMPORT_MODAL_TITLE]),
            importDetails: intl.formatMessage(messages[TranslationKey.PROJECT_IMPORT_FILE_DESCRIPTION]),
            importButton: intl.formatMessage(messages[TranslationKey.PROJECT_IMPORT_SELECT_FILE]),
            copyId: intl.formatMessage(messages[TranslationKey.PROJECT_COPY_ID_DROPDOWN]),
            addToFavorites: intl.formatMessage(messages[TranslationKey.PAGE_PROJECT_ADD_FAV]),
            removeFromFavorites: intl.formatMessage(messages[TranslationKey.PAGE_PROJECT_DELETE_FAV]),
            newFile: intl.formatMessage(messages[TranslationKey.CREATE_TITLE]),
            newFolder: intl.formatMessage(messages[TranslationKey.FILE_MANAGER_NEW_FOLDER]),
            sortAsc: intl.formatMessage(messages[TranslationKey.FILE_MANAGER_SORT_ASC_TOOLTIP]),
            sortDesc: intl.formatMessage(messages[TranslationKey.FILE_MANAGER_SORT_DESC_TOOLTIP]),
            sortType: intl.formatMessage(messages[TranslationKey.FILE_MANAGER_SORT_DEFAULT_TOOLTIP]),
            sortDate: intl.formatMessage(messages[TranslationKey.FILE_MANAGER_SORT_DATE]),
            selectAll: intl.formatMessage(messages[TranslationKey.FILE_MANAGER_SELECT_ALL_TOOLTIP]),
            previewShow: intl.formatMessage(messages[TranslationKey.FILE_MANAGER_PREVIEW_TOOLTIP]),
            changeView: intl.formatMessage(messages[TranslationKey.FILE_MANAGER_MANAGER_VIEW_TOOLTIP]),
            isDisabled: intl.formatMessage(messages[TranslationKey.PROJECT_NOT_SUPPORTED]),
            duplicate: intl.formatMessage(messages[TranslationKey.PROJECT_DUPLICATE_DROPDOWN]),
            update: intl.formatMessage(messages[TranslationKey.FILE_MANAGER_MANAGER_VIEW_UPDATE]),
        }),
        []
    );
};
