import { IPropertyAvailableValuesSrc, IPropertyAvailableValuesSrcFilter } from 'libs/models/src/lib/element';

import { ElemParams, ElemProps, TElement, TPropertyValue, TSchemaHandle, TSchemaNode } from '@repeat/models';

const blocks = (blockType: string, filter: IPropertyAvailableValuesSrcFilter | null, schemaNodes: TSchemaNode[]) => {
    const libraries: string[] | null = filter?.libraries !== undefined ? filter.libraries : null;
    const blocksExceptions: string[] | null = filter?.blocksExceptions !== undefined ? filter.blocksExceptions : null;
    const stateParameters: string[] | null = filter?.stateParameters !== undefined ? filter.stateParameters : null;

    const availableValues = schemaNodes
        .filter((node: TSchemaNode) => {
            const block = node.data;
            const blockFullType = block?.subtype ? `${block.type}${block.subtype}` : block.type;

            // libraries filter
            const isLibrariesPassed = libraries === null ? true : libraries.includes(node.data.library);

            // blocks exceptions filter
            const isBlocksExceptionsPassed =
                blocksExceptions === null ? true : !blocksExceptions.includes(blockFullType);

            // state parameters filter
            let isStateParametersPassed = true;
            if (stateParameters !== null) {
                const blockStateParametersNames = node.data?.stateParameters
                    ? node.data.stateParameters.map((parameter: ElemParams) => parameter.name)
                    : [];
                isStateParametersPassed =
                    blockStateParametersNames.length > 0
                        ? stateParameters.filter((parameter: string) => blockStateParametersNames.includes(parameter))
                              .length > 0
                        : false;
            }

            return (
                node.data.type !== blockType && isLibrariesPassed && isBlocksExceptionsPassed && isStateParametersPassed
            );
        })
        .map((node: TSchemaNode) => {
            return {
                title: `[${node.data.index}] ${node.data.name}`,
                value: node.id,
            } as TPropertyValue;
        });

    return availableValues;
};

const blockParameters = (
    parentId: string | number | null,
    filter: IPropertyAvailableValuesSrcFilter | null,
    schemaNodes: TSchemaNode[]
) => {
    const parentBlock = parentId !== null ? schemaNodes.find((node: TSchemaNode) => node.id === parentId)?.data : null;
    if (!parentBlock) {
        return [];
    }

    const libraries: string[] | null = filter?.libraries !== undefined ? filter.libraries : null;
    const portLibrariesByNameMap: { [name: string]: string[] } = {};
    parentBlock.availablePorts.forEach((port: TSchemaHandle) => {
        portLibrariesByNameMap[port.name] = port.libraries;
    });

    const availableValues = parentBlock.elemParams
        .filter((parameter: ElemParams) => {
            // libraries filter
            const parameterLibraries = portLibrariesByNameMap[parameter.name] || [];
            const isLibrariesPassed =
                libraries === null ? true : libraries.filter((name) => parameterLibraries.includes(name)).length > 0;

            return isLibrariesPassed;
        })
        .map((parameter: ElemParams) => {
            return {
                title: parameter.description,
                value: parameter.name,
            } as TPropertyValue;
        });

    return availableValues;
};

export const makeBlockPropertyValues = (
    propertiesSrc: IPropertyAvailableValuesSrc,
    property: ElemProps,
    block: TElement,
    schemaNodes: TSchemaNode[]
): TPropertyValue[] => {
    const dependencyPropertyName = property?.dependency !== undefined ? property.dependency : null;
    const parentId = dependencyPropertyName
        ? block.elemProps.find((property: ElemProps) => property.name === 'parentID')?.value || null
        : null;
    const filter = propertiesSrc?.filter ? propertiesSrc?.filter : null;

    switch (propertiesSrc.method) {
        case 'blocks':
            return blocks(block.type, filter, schemaNodes);
        case 'blockParameters':
            return blockParameters(parentId, filter, schemaNodes);
        default:
            return [];
    }
};
