import { FC, ReactElement } from 'react';

import { useAppSelector } from '@repeat/hooks';
import { ModalTypes, TModal } from '@repeat/models';

import { ElementConfigurationModal } from './ElementConfigurationModal';
import { ElementInitializationModal } from './ElementInitializationModal';
import { ErrorModal } from './ErrorModal';
import { LibraryItemAddModal } from './LibraryItemAddModal';
import { NewBlockInfoModal } from './NewBlockInfoModal';
import { OpenBusyProjectModal } from './OpenProjectmodal';
import { ProjectBlockInitializationModal } from './ProjectBlockInitializationModal';
import { ProjectConfirmSavingModal } from './ProjectConfirmSavingModal';
import { ProjectCreateModal } from './ProjectCreateModal';
import { ProjectDeleteModal } from './ProjectDeleteModal';
import { ProjectEditModal } from './ProjectEditModal';
import { ProjectImportModal } from './ProjectImportModal';
import { ProjectSaveNewModal } from './ProjectSaveNewModal';
import { ProjectsDeleteModal } from './ProjectsDeleteModal';
import { PulSeqInitializationModal } from './PulSeqInitializationModal';
import { ReleaseInfoModal } from './ReleaseInfoModal/ReleaseInfoModal';
import { SessionConnectModal } from './SessionModal';
import { TechnicalSupportModal } from './TechnicalSupportModal';
import { UserBlockConfirmDeleteModal } from './UserBlockConfirmDeleteModal';
import { UserBlockEditModal } from './UserBlockEditModal';
import { UserBlockSaveModal } from './UserBlockSaveModal';

const allModals: { [key: string]: ReactElement } = {
    [ModalTypes.PROJECT_CREATE]: <ProjectCreateModal key='create-modal' />,
    [ModalTypes.PROJECT_IMPORT]: <ProjectImportModal key='import-modal' />,
    [ModalTypes.PROJECT_EDIT]: <ProjectEditModal key='edit-modal' />,
    [ModalTypes.PROJECT_DELETE]: <ProjectDeleteModal key='delete-modal' />,
    [ModalTypes.PROJECTS_DELETE]: <ProjectsDeleteModal key='projects-delete-modal' />,
    [ModalTypes.ELEMENT_CONFIGURATION]: <ElementConfigurationModal key='element-configuration-modal' />,
    [ModalTypes.ELEMENT_INITIALIZATION]: <ElementInitializationModal key='element-initialization-modal' />,
    [ModalTypes.RELEASE_INFO]: <ReleaseInfoModal key='release-info-modal' />,
    [ModalTypes.TECHNICAL_SUPPORT]: <TechnicalSupportModal key='technical-support-modal' />,
    [ModalTypes.ERROR]: <ErrorModal key='error-modal' />,
    [ModalTypes.PROJECT_SAVE_NEW]: <ProjectSaveNewModal key='save-new-modal' />,
    [ModalTypes.PROJECT_BLOCK_INITIALIZATION]: <ProjectBlockInitializationModal key='project-block-modal' />,
    [ModalTypes.PROJECT_CONFIRM_SAVING]: <ProjectConfirmSavingModal key='project-confirm-saving' />,
    [ModalTypes.LIBRARYITEM_IMPORT]: <LibraryItemAddModal key='add-item' />,
    [ModalTypes.NEWBLOCK_INFO]: <NewBlockInfoModal key='new-block-info' />,
    [ModalTypes.PULSEQ_INITIALIZATION]: <PulSeqInitializationModal key='block-file-upload-modal' />,
    [ModalTypes.USER_BLOCK_SAVE]: <UserBlockSaveModal key='user-block-save' />,
    [ModalTypes.USER_BLOCK_EDIT]: <UserBlockEditModal key='user-block-edit' />,
    [ModalTypes.USER_BLOCK_CONFIRM_DELETE]: <UserBlockConfirmDeleteModal key='user-block-confirm-delete' />,
    [ModalTypes.MODAL_SESSION_CONNECT]: <SessionConnectModal key='session-modal' />,
    [ModalTypes.OPEN_BUSY_PROJECT_MODAL]: <OpenBusyProjectModal key='open-busy-project-modal' />,
};

export const Modals: FC = () => {
    const modals = useAppSelector((state) => state.app.modals);
    const ModalComponents = () => <>{modals.map((m: TModal) => allModals[m.type])}</>;

    return <ModalComponents />;
};
