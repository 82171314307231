import { FC } from 'react';
import { useIntl } from 'react-intl';

import { Annotations, Layout } from 'plotly.js';

import { TranslationKey } from '@repeat/translations';
import { IconButton, uiColors } from '@repeat/ui-kit';

import { messages } from './../translation';
import { SAnnotation, SAnnotationTitle, SAnnotationsEmpty } from './SAnnotations';

interface IAnnotationsProperties {
    isEditable: boolean;
    layout: Partial<Layout>;
    onDelete: (index: number) => void;
}

export const ChartAnnotations: FC<IAnnotationsProperties> = ({ isEditable, layout: { annotations }, onDelete }) => {
    const intl = useIntl();

    if (!annotations || annotations.length === 0) {
        return (
            <SAnnotationsEmpty>
                {intl.formatMessage(messages[TranslationKey.WORKSPACE_CHART_EDITOR_NO_ANNOTATIONS])}
            </SAnnotationsEmpty>
        );
    }

    return (
        <div>
            {annotations.map((annotation: Annotations, index: number) => {
                return (
                    <SAnnotation key={index}>
                        <SAnnotationTitle>{annotation.text}</SAnnotationTitle>
                        <IconButton
                            noHover
                            name={'close'}
                            fill={uiColors.darkGrey}
                            onClick={() => onDelete(index)}
                            disabled={!isEditable}
                        />
                    </SAnnotation>
                );
            })}
        </div>
    );
};
