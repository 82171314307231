import { FC } from 'react';

import styled from 'styled-components';

import { pxToRem } from '../../config/config';
import { verticalAlign } from '../../config/mixin';

const SLoaderRepeat = styled.div`
    ${verticalAlign()};
    height: 100%;
    justify-content: center;
    position: relative;
    width: 100%;

    img {
        width: ${pxToRem(50)};
        height: ${pxToRem(50)};
    }
`;

export const LoaderRepeat: FC = () => {
    return (
        <SLoaderRepeat>
            <img src='assets/loader-repeat.gif' alt='' />
        </SLoaderRepeat>
    );
};
