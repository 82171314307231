import { FC, ReactElement, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { Link, Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { Tour } from '@components/Tour/Tour';

import { defineAbilityFor } from '@repeat/common-ability';
import { initializeApp } from '@repeat/common-slices';
import { getAppLocale, IS_USE_TARIFFS, shouldHasLicense } from '@repeat/constants';
import { environment } from '@repeat/environment';
import { useAppDispatch, useAppSelector, useProjectId } from '@repeat/hooks';
import { appSelectors, appUserSelectors, showSkippedReleaseInfo } from '@repeat/store';
import { en as enMessages, Locales, ru as ruMessages } from '@repeat/translations';
import { Loader } from '@repeat/ui-kit';

import { AbilityContext } from './../context/Can';

import { Hotkeys } from '../features/Hotkeys/Hotkeys';
import { AppLayout } from '../hoc/AppLayout/AppLayout';
import { LazyComponent } from '../hoc/LazyLoading/LazyLoading';
import { NotFoundPage } from '../pages/404/NotFoundPage';
import { AdminPage } from '../pages/admin/AdminPage/AdminPage';
import { ChartPage } from '../pages/Chart/ChartPage';
import { EngineerPage } from '../pages/EngineerPage/EngineerPage';
import { ProjectsPage } from '../pages/ProjectsPage/ProjectsPage';
import { STEPS as PROJECT_STEPS } from '../pages/ProjectsPage/tour.steps';
import VersionsPage from '../pages/VersionsPage/VersionsPage';
import { STEPS } from '../pages/WorkspacePage/tour.steps';
import { WorkspacePage } from '../pages/WorkspacePage/WorkspacePage';

const { production } = environment || { production: false };
const isDevelopmentEnv = !production;

const setThemeInactive = (): void => {
    const root = document.body.parentNode as HTMLElement;
    root.removeAttribute('data-theme');
};
const { REPEAT_DOMAIN: repeatDomain, AUTH_DOMAIN: authDomain } = environment;

const PrivateRoute: FC<{ children: ReactElement }> = ({ children }) => {
    const dispatch = useAppDispatch();
    const isAuth = useAppSelector(appUserSelectors.isAuth);

    if (!isAuth) {
        setThemeInactive();
    }

    return isAuth ? children : <Link to={authDomain} />;
};

const TariffSelectedGuard: FC<{ children: ReactElement }> = ({ children }) => {
    const currentUser = useAppSelector(appUserSelectors.currentUser);
    const isInitialized = useAppSelector(appSelectors.isInitialized);
    const hasActiveLicense = useAppSelector(appSelectors.hasActiveLicenseKey);
    const hasNewNotActivatedLicense = useAppSelector(appSelectors.hasNewNotActivatedLicenseKey);

    const isAuth = useAppSelector(appUserSelectors.isAuth);

    if (!isAuth) {
        setThemeInactive();
    }

    if (isInitialized && shouldHasLicense() && !hasActiveLicense) {
        if (!hasNewNotActivatedLicense) {
            return <Navigate to='/license/new' />;
        }

        return <Navigate to='/license/activation' />;
    }

    if (IS_USE_TARIFFS) {
        return currentUser && currentUser?.tariffId > 0 ? children : <Navigate to='/tariffs' />;
    }

    return children;
};

const LicensedGuard: FC<{ children: ReactElement }> = ({ children }) => {
    const isInitialized = useAppSelector(appSelectors.isInitialized);
    const hasActiveLicense = useAppSelector(appSelectors.hasActiveLicenseKey);
    const hasNewNotActivatedLicense = useAppSelector(appSelectors.hasNewNotActivatedLicenseKey);
    const isAuth = useAppSelector(appUserSelectors.isAuth);

    if (!isAuth) {
        setThemeInactive();
    }

    if (isInitialized && shouldHasLicense() && !hasActiveLicense) {
        if (!hasNewNotActivatedLicense) {
            return <Navigate to='/license/new' />;
        }

        return <Navigate to='/license/activation' />;
    }

    return children;
};

const ProjectSelectedGuard: FC<{ children: ReactElement }> = ({ children }) => {
    const dispatch = useAppDispatch();

    // TODO: @karlvolf88  - this clears the storage on reload
    // useUnload((event: Event) => {
    //     event.preventDefault();
    //     localStorage.clear();
    //     dispatch(logout());
    // });

    const { projectId } = useProjectId() || { projectId: null };
    const userBlockId = localStorage.getItem('userBlockId');
    return projectId || userBlockId ? children : <Navigate to='/projects' />;
};

export const SLoaderWrapper = styled.div`
    background: var(--ui-backround);
    position: absolute;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(4px);
    z-index: 10;

    img {
        max-height: 72px;
        max-width: 72px;
    }
`;

const defaultLocale = Locales.RUSSIAN;
const locale = getAppLocale();

const getTranslationMessages = (locale: string) => {
    switch (locale) {
        case Locales.ENGLISH:
            return enMessages;
        case Locales.RUSSIAN:
        default:
            return ruMessages;
    }
};

const App = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isInitialized = useAppSelector(appSelectors.isInitialized);
    const isLoading = useAppSelector(appSelectors.isLoading);
    const isAuth = useAppSelector(appUserSelectors.isAuth);
    const isEngineer = useAppSelector(appUserSelectors.currentUserMode);
    const theme = useAppSelector(appUserSelectors.currentUserTheme);
    const userLocale = useAppSelector(appUserSelectors.currentUserLocale);
    const user = useAppSelector(appUserSelectors.currentUser);

    useEffect(() => {
        if (!isInitialized && !isLoading) {
            dispatch(initializeApp(repeatDomain, navigate));
        }
    }, [isInitialized, isLoading]);

    useEffect(() => {
        if (theme) {
            (() => {
                const root = document.body.parentNode as HTMLElement;
                root.setAttribute('data-theme', theme);
            })();
        }
    }, [theme]);

    useEffect(() => {
        if (isInitialized && isAuth) {
            dispatch(showSkippedReleaseInfo());
        }
    }, [isInitialized, isAuth]);

    const ability = defineAbilityFor(user);

    if (!isInitialized || isLoading) {
        return (
            <SLoaderWrapper>
                <Loader />
            </SLoaderWrapper>
        );
    }

    return (
        <IntlProvider
            defaultLocale={defaultLocale}
            locale={userLocale || locale}
            messages={getTranslationMessages(userLocale || locale)}
        >
            <AbilityContext.Provider value={ability}>
                <Routes>
                    <Route path='/' element={<AppLayout />}>
                        <Route
                            index
                            element={
                                <PrivateRoute>
                                    <TariffSelectedGuard>
                                        <ProjectSelectedGuard>
                                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                            {/* @ts-ignore */}
                                            <Tour steps={STEPS} isTourOpen={false}>
                                                <WorkspacePage />
                                            </Tour>
                                        </ProjectSelectedGuard>
                                    </TariffSelectedGuard>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={'trend/:id'}
                            element={
                                <PrivateRoute>
                                    <ChartPage />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path='projects'
                            element={
                                <PrivateRoute>
                                    <TariffSelectedGuard>
                                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                        {/* @ts-ignore */}
                                        <Tour steps={PROJECT_STEPS} isTourOpen={false}>
                                            <ProjectsPage />
                                        </Tour>
                                    </TariffSelectedGuard>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path='projects/demo'
                            element={
                                <PrivateRoute>
                                    <TariffSelectedGuard>
                                        <LazyComponent path={'ProjectsPage/Demo/DemoProjectPage'} />
                                    </TariffSelectedGuard>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path='projects/favorites'
                            element={
                                <PrivateRoute>
                                    <TariffSelectedGuard>
                                        <LazyComponent path={'ProjectsPage/Favorites/FavoriteProjectPage'} />
                                    </TariffSelectedGuard>
                                </PrivateRoute>
                            }
                        />
                        <Route
                        path='projects/userBlocks'
                        element={
                            <PrivateRoute>
                                <TariffSelectedGuard>
                                    <LazyComponent path={'ProjectsPage/UserBlocks/UserBlocksPage'} />
                                </TariffSelectedGuard>
                            </PrivateRoute>
                        } 
                        />                    
                        <Route
                            path='admin'
                            element={
                                <PrivateRoute>
                                    <TariffSelectedGuard>
                                        <AdminPage />
                                    </TariffSelectedGuard>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path='versions'
                            element={
                                <PrivateRoute>
                                    <LicensedGuard>
                                        <VersionsPage />
                                    </LicensedGuard>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path='*'
                            element={
                                <LicensedGuard>
                                    <NotFoundPage />
                                </LicensedGuard>
                            }
                        />
                        {isDevelopmentEnv && isEngineer && (
                            <Route
                                path='engineer'
                                element={
                                    <PrivateRoute>
                                        <LicensedGuard>
                                            <EngineerPage />
                                        </LicensedGuard>
                                    </PrivateRoute>
                                }
                            />
                        )}
                        <Route
                            path='*'
                            element={
                                <LicensedGuard>
                                    <NotFoundPage />
                                </LicensedGuard>
                            }
                        />
                    </Route>
                </Routes>
                <Hotkeys />
            </AbilityContext.Provider>
        </IntlProvider>
    );
};

export default App;
