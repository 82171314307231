import styled from 'styled-components';

import { TInputVariant } from './InputField';

import { pxToRem, radius, uiColors } from '../../config/config';
import { verticalAlign } from '../../config/mixin';

export enum InputVariant {
    DEFAULT = 'default',
    SUCCESS = 'success',
    WARNING = 'warning',
    DANGER = 'danger',
}

export const SInputWrapper = styled.div<{ error: boolean; dataType?: string; size?: string }>`
    display: inline-flex;
    flex-direction: column;
    margin-bottom: ${pxToRem(16)};
    position: relative;
    width: 100%;

    input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill {
        background-color: var(--ui-input);
        border: 1px solid var(--ui-border);
        border-radius: ${radius.default};
        -webkit-box-shadow: none;
        color: var(--ui-text);
    }

    input,
    input::placeholder {
        font-family: inherit;
    }

    input,
    textarea {
        background-color: var(--ui-input);
        border-radius: ${radius.default};
        border-width: 1px;
        border-style: solid;
        border-color: var(--ui-border);
        color: var(--ui-text);
        font-size: ${({ size }) => {
            if (!size) {
                return `${pxToRem(14)}`;
            }

            switch (size) {
                case 'small':
                    return `${pxToRem(14)}`;
                default:
                    return `${pxToRem(13)}`;
            }
        }};
        letter-spacing: 0.5px;
        outline: none;
        padding: ${({ size }) => {
            if (!size) {
                return `${pxToRem(8)}`;
            }

            switch (size) {
                case 'small':
                    return `${pxToRem(4)} ${pxToRem(8)}`;
                default:
                    return `${pxToRem(8)}`;
            }
        }};
        ${({ dataType }) => dataType === 'password' && `padding-right: ${pxToRem(32)}`};
        transition: border 0.3s ease;
        width: 100%;

        &:active,
        &:focus {
            border-color: var(--ui-border-hover);
        }
    }

    .react-datepicker__input-container input,
    .react-tel-input > .flag-dropdown,
    .react-tel-input > input {
        background-color: var(--ui-input);
        ${({ error }) =>
            error ? `border: 1px solid var(--ui-link-default)` : `border: 1px solid var(--ui-link-active);`};
    }

    .react-tel-input > input {
        width: 100%;
    }

    .special-label {
        display: none;
    }

    .react-datepicker__triangle {
        display: none;
    }

    [disabled] {
        color: ${uiColors.darkGrey};
    }
`;

export const SInputField = styled.input<{ error: boolean | undefined; variant?: TInputVariant }>`
    border-color: rgba(49, 49, 49, 20%);
    ${({ error }) => error && `border-color: ${uiColors.red} !important`};
    ${({ variant }) => variant === 'success' && `border-color: ${uiColors.green} !important`};
    ${({ variant }) => variant === 'warning' && `border-color: ${uiColors.yellow} !important`};
    ${({ variant }) => variant === 'danger' && `border-color: ${uiColors.red} !important`};
`;

export const STextAreaField = styled.textarea<{
    resize: 'both' | 'horizontal' | 'vertical' | 'inherit' | undefined;
    height: number | undefined;
    error: boolean | undefined;
}>`
    resize: ${({ resize }) => (resize ? `${resize}` : 'none')};
    height: ${({ height }) => (height ? `${pxToRem(height)}` : `${pxToRem(100)}`)};
    border: ${({ error }) => (error ? ` 1px solid ${uiColors.red} !important` : ` 1px solid rgba(49, 49, 49, 20%);`)};
`;

export const SInputError = styled.span`
    color: ${uiColors.red};
    font-size: ${pxToRem(12)};
    padding: ${pxToRem(4)} 0;
    position: absolute;
    top: calc(100% - 2px);
`;

export const SLabel = styled.label<{ error: boolean }>`
    color: ${({ error }) => (!error ? `var(--ui-label)` : `${uiColors.red}`)};
    font-size: ${pxToRem(14)};
    overflow: hidden;
    padding: ${pxToRem(4)} 0;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const SInputButton = styled.span<{ label: unknown }>`
    ${verticalAlign()};
    cursor: pointer;
    left: calc(100% - ${pxToRem(32)});
    position: absolute;
    top: calc((100% / 2) - ${pxToRem(4)});
    height: 32px;

    svg {
        fill: ${uiColors.darkGrey};
    }
`;
