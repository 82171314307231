interface IData<T> {
    id: number;
    content: T;
}

interface IReleaseNoteBlock {
    block: string;
    value: string;
}

export interface IVersionData extends IData<IReleaseNoteBlock[]> {
    code: string;
    version: string;
    release_date: string | null;
}

export enum HelpPageSectionTypes {
    CHANGE_LOG = 'change_log',
}

export type TVersionHistoryData = IVersionData[];
