import { VERSION } from '@repeat/constants';
import { environment } from '@repeat/environment';
import { ILibraryItem, LibraryTypes, SolverTypes, TLibraryType, TSolverType } from '@repeat/models';

import { $api } from './http';

interface ReturnGetLibraryItemsContract {
    data: ILibraryItem[];
}

export default class LibraryItemsAssetsService {
    config: { [key: string]: string | number };
    type: TLibraryType;
    url: string;

    constructor({ solverType, type, locale }: { solverType: TSolverType; type?: TLibraryType; locale: string }) {
        this.config = {
            baseURL: '/',
        };
        this.type = type || LibraryTypes.ELECTROCITY;

        const hash = environment.APP_BUILD.length > 0 ? environment.APP_BUILD : VERSION;
        this.url = [SolverTypes.JAUTO, SolverTypes.MDCORE].includes(solverType)
            ? `assets/library/library_${locale}.json?${hash}`
            : `assets/${type}/${type}.json?type=${this.type}&${hash}`;
    }

    public getLibraryItems = async (): Promise<ReturnGetLibraryItemsContract> => {
        return $api.get(this.url, this.config);
    };
}
