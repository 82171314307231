import { defineMessages, useIntl } from 'react-intl';

import styled from 'styled-components';

import { ru, TranslationKey } from '@repeat/translations';
import { IconButton, pxToRem, uiColors } from '@repeat/ui-kit';

const SMenuReadOnlyModeItem = styled.div`
    margin-left: ${pxToRem(16)};
    display: flex;
    white-space: nowrap;
    align-items: center;
    border-radius: ${pxToRem(2)};
    border: 1px solid ${uiColors.orange};
    padding-right: ${pxToRem(8)};
    color: ${uiColors.darkGrey};
    font-size: ${pxToRem(14)};
`;
const messages = defineMessages({
    [TranslationKey.WORKSPACE_VIEW_MODE]: {
        id: TranslationKey.WORKSPACE_VIEW_MODE,
        defaultMessage: ru[TranslationKey.WORKSPACE_VIEW_MODE],
    },
});

export const MenuReadOnlyModeItem = () => {
    const { formatMessage } = useIntl();
    return (
        <SMenuReadOnlyModeItem style={{ border: `1px solid ${uiColors.orange}`, cursor: 'default' }}>
            <IconButton style={{ cursor: 'default' }} noHover name={'eyeOpen'} />
            <span>{formatMessage(messages[TranslationKey.WORKSPACE_VIEW_MODE])}</span>
        </SMenuReadOnlyModeItem>
    );
};
