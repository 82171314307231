import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { EDIT_PROJECT_FORM, validateEmptySpaces, validateExistingProjectName } from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { Project, Statuses } from '@repeat/models';
import { fetchAvailableLibraries, getProjects, updateProject } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { InputField, StyledForm } from '@repeat/ui-kit';

import { projectMessages as messages } from '../translations';

interface IEditProjectForm {
    project: Project;
    onValid: (isValid: boolean) => void;
}

export const EditProjectForm: FC<IEditProjectForm> = ({ project, onValid }) => {
    const dispatch = useAppDispatch();
    const projectsState = useAppSelector((state) => state.projects);
    const projects = projectsState.items;
    const isProjectsInitialized = projectsState.status === Statuses.SUCCEEDED;
    const { formatMessage } = useIntl();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm<Pick<Project, 'projectName'>>({ mode: 'onChange' });

    useEffect(() => {
        onValid(isValid);
    }, [isValid]);

    useEffect(() => {
        if (isProjectsInitialized === false) {
            dispatch(fetchAvailableLibraries());
            dispatch(getProjects());
        }
    }, [dispatch, isProjectsInitialized]);

    const onSubmit = (data: Pick<Project, 'projectName'>) => {
        dispatch(updateProject({ ...data, projectId: project.projectId })).finally(() => reset());
    };

    const inputProjectNameErrors =
        (errors.projectName?.type === 'required' && true) ||
        (errors.projectName?.type === 'uniqueName' && formatMessage(messages[TranslationKey.ERROR_NEW_PROJECT_NAME])) ||
        (errors.projectName?.type === 'noWhitespace' &&
            formatMessage(messages[TranslationKey.ERROR_PROJECT_NAME_IS_EMPTY]));

    return (
        <StyledForm onSubmit={handleSubmit(onSubmit)} id={EDIT_PROJECT_FORM}>
            <InputField
                label={formatMessage(messages[TranslationKey.PROJECT_NAME])}
                type='text'
                id='standard-required'
                {...register('projectName', {
                    required: true,
                    validate: {
                        noWhitespace: validateEmptySpaces,
                        uniqueName: (value) => validateExistingProjectName(value, projects, project.projectId),
                    },
                })}
                error={!!errors?.projectName}
                errorText={inputProjectNameErrors}
                defaultValue={project.projectName}
            />
        </StyledForm>
    );
};
