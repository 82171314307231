import { useIntl } from 'react-intl';

import { CircularProgress } from '@mui/material';

import { ApplicationActions } from '@repeat/common-slices';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { ModalTypes, Statuses, TProjectsDeleteModal } from '@repeat/models';
import { deleteProjects } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { Button, Modal } from '@repeat/ui-kit';

import { SProjectsList } from './SModals';
import { projectDeleteModalMessages as messages } from './translation';

export const ProjectsDeleteModal = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const modals = useAppSelector((state) => state.app.modals);
    const deleteModal = modals.find((modal) => modal.type === ModalTypes.PROJECTS_DELETE) as TProjectsDeleteModal;

    const deleteProjectStatus = useAppSelector((state) => state.projects.deleteProjects.status);

    const projects = deleteModal?.data?.projects || null;

    const handleClose = () => {
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.PROJECTS_DELETE }));
    };

    const handleDeleteClick = (ids: number[]) => {
        dispatch(deleteProjects(ids));
    };

    const footer = projects && (
        <>
            <Button variant='secondary' onClick={handleClose}>
                {intl.formatMessage(messages[TranslationKey.CANCEL])}
            </Button>
            <Button
                data-test-id='confirmDeleteProjects'
                variant='primary'
                disabled={deleteProjectStatus === Statuses.LOADING}
                onClick={() => {
                    handleDeleteClick(projects.map((project) => project.projectId));
                }}
            >
                {intl.formatMessage(messages[TranslationKey.DELETE])}
                {deleteProjectStatus === Statuses.LOADING && (
                    <CircularProgress color='primary' size={20} style={{ position: 'absolute' }} />
                )}
            </Button>
        </>
    );

    return (
        <Modal
            id={ModalTypes.PROJECT_DELETE}
            title={intl.formatMessage(messages[TranslationKey.PROJECT_DELETE_TITLE])}
            isOpen={true}
            hasOverlay={true}
            onClose={handleClose}
            type='default'
            size='small'
            footer={footer}
        >
            <SProjectsList>
                <p style={{ marginBottom: '16px' }}>
                    {intl.formatMessage(messages[TranslationKey.PROJECTS_DELETE_CONFIRMATION])}
                </p>
                {projects &&
                    projects.map((project) => (
                        <li key={`delete-modal-list-item-${project.projectId}`}>{project.projectName}</li>
                    ))}
            </SProjectsList>
        </Modal>
    );
};
