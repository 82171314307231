import { AxiosResponse } from 'axios';

import { EmailData, IApiHeaders, ResetPasswordData, UpdateForgottenPasswordData } from '@repeat/models';

import { $api, apiEndpoint } from './http';

type ReturnForgottenPassword = {
    [key: string]: string | number;
};

export type UpdateForgottenPasswordPayload = UpdateForgottenPasswordData;
interface IUpdateForgottenPassword {
    (payload: UpdateForgottenPasswordPayload, signal?: AbortSignal): Promise<AxiosResponse<ReturnForgottenPassword>>;
}

export type ForgotPasswordPayload = EmailData & { locale?: string };
interface IForgotPassword {
    (payload: ForgotPasswordPayload, signal?: AbortSignal): Promise<AxiosResponse<ReturnForgottenPassword>>;
}

export type ResetPasswordPayload = ResetPasswordData;
interface IResetPassword {
    (payload: ResetPasswordPayload, signal?: AbortSignal): Promise<AxiosResponse<ReturnForgottenPassword>>;
}

export default class ForgotPasswordService {
    static userForgotPassword: IForgotPassword = ({ email, locale }, signal?: AbortSignal) => {
        let headers: IApiHeaders = {
            'Content-Type': 'application/json',
        };
        if (locale) {
            headers = {
                ...headers,
                locale,
            };
        }
        return $api.post(apiEndpoint.forgotPassword, { email }, { headers, signal }).then((response) => response.data);
    };

    static userUpdateForgottenPassword: IUpdateForgottenPassword = (payload, signal?: AbortSignal) =>
        $api.post(apiEndpoint.updatePassword, payload, { signal }).then((response) => response.data);

    static userResetPassword: IResetPassword = ({ token }, signal?: AbortSignal) =>
        $api.get(`${apiEndpoint.resetPassword}?token=${token}`, { signal }).then((response) => response.data);
}
