import { Action } from 'redux';

/* eslint-disable @typescript-eslint/no-explicit-any */

export enum TmpTrendsTypes {
    ADD_TREND = 'ADD_TREND',
    CLEAR_TREND = 'CLEAR_TREND',
}

export interface TmpTrendsAction extends Action<TmpTrendsTypes> {
    type: TmpTrendsTypes;
    payload: any;
}
