import { VERSION } from '@repeat/constants';
import { environment } from '@repeat/environment';
import { TVersionHistoryData } from '@repeat/models';

import { $api } from '../http';

interface IReturnContent<T extends TVersionHistoryData> {
    data: T;
}

export default class HelpPageService {
    static getVersionHistoryContent = async (locale: string): Promise<IReturnContent<TVersionHistoryData>> => {
        const hash = environment.APP_BUILD.length > 0 ? environment.APP_BUILD : VERSION;
        return $api.get(`assets/help-page/version-history_${locale}.json?${hash}`, {
            baseURL: '/',
        });
    };
}
