import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.JOURNAL_STACKTRACE_TITLE]: {
        id: TranslationKey.JOURNAL_STACKTRACE_TITLE,
        defaultMessage: ru[TranslationKey.JOURNAL_STACKTRACE_TITLE],
    },
    [TranslationKey.CRITICAL_ERROR]: {
        id: TranslationKey.CRITICAL_ERROR,
        defaultMessage: ru[TranslationKey.CRITICAL_ERROR],
    },
});
