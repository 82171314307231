export enum ClickTypes {
    ADD_TYPE_CLICK = 'ADD_TYPE_CLICK',
    DELETE_TYPE_CLICK = 'DELETE_TYPE_CLICK',
    DEFAULT_CLICK = 'DEFAULT_CLICK',
    ELEMENT_CLICK = 'ELEMENT_CLICK',
    PORT_CLICK = 'PORT_CLICK',
    TRACING_CLICK = 'TRACING_CLICK',
}

export type TClickType = keyof typeof ClickTypes;
