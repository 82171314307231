import { PayloadAction } from '@reduxjs/toolkit';

import { ISchemaBlocksNotifications, IWorkspaceState, TElementNotificationType } from '@repeat/models';

export const blockNotificationsSlices = {
    notifyGoToBlocks: (
        state: IWorkspaceState,
        { payload }: PayloadAction<{ blockIds: string[]; type: TElementNotificationType }>
    ) => {
        const { blockIds, type } = payload;

        const refreshedNotificationMap: ISchemaBlocksNotifications = {};
        if (state.schema.blockNotifications !== null) {
            const blockNotifications = state.schema.blockNotifications;
            Object.keys(state.schema.blockNotifications).forEach((blockId: string) => {
                const notificationType: TElementNotificationType = blockNotifications[blockId];
                if (notificationType !== type) {
                    refreshedNotificationMap[blockId] = blockNotifications[blockId];
                }
            });
        }
        blockIds.forEach((blockId: string) => {
            refreshedNotificationMap[blockId] = type;
        });

        return {
            ...state,
            schema: {
                ...state.schema,
                blockNotifications: refreshedNotificationMap,
            },
        };
    },
};
