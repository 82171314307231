import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.WORKSPACE_NOTEBOOK_TOOL]: {
        id: TranslationKey.WORKSPACE_NOTEBOOK_TOOL,
        defaultMessage: ru[TranslationKey.WORKSPACE_NOTEBOOK_TOOL],
    },
    [TranslationKey.WORKSPACE_NOTEBOOK_WITH_CORE_TOOL]: {
        id: TranslationKey.WORKSPACE_NOTEBOOK_WITH_CORE_TOOL,
        defaultMessage: ru[TranslationKey.WORKSPACE_NOTEBOOK_WITH_CORE_TOOL],
    },
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL]: {
        id: TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL,
        defaultMessage: ru[TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL],
    },
    [TranslationKey.WORKSPACE_HARMONIC_TOOL]: {
        id: TranslationKey.WORKSPACE_HARMONIC_TOOL,
        defaultMessage: ru[TranslationKey.WORKSPACE_HARMONIC_TOOL],
    },
    [TranslationKey.COMMON]: {
        id: TranslationKey.COMMON,
        defaultMessage: ru[TranslationKey.COMMON],
    },
    [TranslationKey.ELECTROCITY]: {
        id: TranslationKey.ELECTROCITY,
        defaultMessage: ru[TranslationKey.ELECTROCITY],
    },
    [TranslationKey.WORKSPACE_PAGE_TOOLS]: {
        id: TranslationKey.WORKSPACE_PAGE_TOOLS,
        defaultMessage: ru[TranslationKey.WORKSPACE_PAGE_TOOLS],
    },
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_START]: {
        id: TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_START,
        defaultMessage: ru[TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_START],
    },
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_FAULT]: {
        id: TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_FAULT,
        defaultMessage: ru[TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_FAULT],
    },
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_CASE]: {
        id: TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_CASE,
        defaultMessage: ru[TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_CASE],
    },
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_PERCENT]: {
        id: TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_PERCENT,
        defaultMessage: ru[TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_PERCENT],
    },
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_PERCENT_FULL]: {
        id: TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_PERCENT_FULL,
        defaultMessage: ru[TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_PERCENT_FULL],
    },
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_DURATION]: {
        id: TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_DURATION,
        defaultMessage: ru[TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_DURATION],
    },
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_FAULT_3]: {
        id: TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_FAULT_3,
        defaultMessage: ru[TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_FAULT_3],
    },
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_FAULT_2]: {
        id: TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_FAULT_2,
        defaultMessage: ru[TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_FAULT_2],
    },
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_FAULT_1]: {
        id: TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_FAULT_1,
        defaultMessage: ru[TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_FAULT_1],
    },
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_CASE_MAX]: {
        id: TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_CASE_MAX,
        defaultMessage: ru[TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_CASE_MAX],
    },
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_CASE_MIN]: {
        id: TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_CASE_MIN,
        defaultMessage: ru[TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_CASE_MIN],
    },
    [TranslationKey.OBLIGATORY_FIELD_MSG]: {
        id: TranslationKey.OBLIGATORY_FIELD_MSG,
        defaultMessage: ru[TranslationKey.OBLIGATORY_FIELD_MSG],
    },
    [TranslationKey.WORKSPACE_NOTEBOOK_WITH_CORE_TOOL_LINK_ERROR]: {
        id: TranslationKey.WORKSPACE_NOTEBOOK_WITH_CORE_TOOL_LINK_ERROR,
        defaultMessage: ru[TranslationKey.WORKSPACE_NOTEBOOK_WITH_CORE_TOOL_LINK_ERROR],
    },
});
