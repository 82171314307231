import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages: { [key: string]: { id: string; defaultMessage: string } } = defineMessages({
    [TranslationKey.ZOOM_IN]: {
        id: TranslationKey.ZOOM_IN,
        defaultMessage: ru[TranslationKey.ZOOM_IN],
    },
    [TranslationKey.ZOOM_OUT]: {
        id: TranslationKey.ZOOM_OUT,
        defaultMessage: ru[TranslationKey.ZOOM_OUT],
    },
    [TranslationKey.FIT_VIEW]: {
        id: TranslationKey.FIT_VIEW,
        defaultMessage: ru[TranslationKey.FIT_VIEW],
    },
    [TranslationKey.LOCK_CANVAS]: {
        id: TranslationKey.LOCK_CANVAS,
        defaultMessage: ru[TranslationKey.LOCK_CANVAS],
    },
    [TranslationKey.UNLOCK_CANVAS]: {
        id: TranslationKey.UNLOCK_CANVAS,
        defaultMessage: ru[TranslationKey.UNLOCK_CANVAS],
    },
    [TranslationKey.MINI_MAP]: {
        id: TranslationKey.MINI_MAP,
        defaultMessage: ru[TranslationKey.MINI_MAP],
    },
});
