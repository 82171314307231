import { FC, MouseEvent } from 'react';
import { useIntl } from 'react-intl';

import { LinearProgress } from '@mui/material';

import { Status, Statuses } from '@repeat/models';
import { TranslationKey } from '@repeat/translations';
import { uiColors } from '@repeat/ui-kit';

import {
    SStatusItem,
    SStatusItemLabel,
    SStatusItemProgress,
    SStatusItemProgressValue,
    SStatusWrapper,
} from './SProgress';
import { SRunAgainButton } from './SRunAgainButton';
import { messages } from './translation';

const WARNING_ERROR_KEYS: string[] = [TranslationKey.WARNING_ALL_VMS_ARE_BUSY];
const ERROR_KEYS: string[] = [
    TranslationKey.WARNING_ALL_VMS_ARE_BUSY,
    TranslationKey.ELEMENT_INITIALIZATION_FMU_NOT_VALID,
];

export const Progress: FC<{
    uploadStatusInfo: {
        fileSizeBytes: number;
        uploadedBytes: number;
        status: Status;
    };
    initializeStatusInfo: {
        status: Status;
        error: string | null;
    };
    onInitialize?: () => void;
}> = ({ uploadStatusInfo, initializeStatusInfo, onInitialize }) => {
    const { formatMessage } = useIntl();

    const statusColor = (status: Status, errorKey: string | null = null): string => {
        switch (status) {
            case Statuses.FAILED:
                return errorKey !== null && WARNING_ERROR_KEYS.includes(errorKey) ? uiColors.orange : uiColors.red;
            case Statuses.IDLE:
                return uiColors.darkGrey;
            case Statuses.SUCCEEDED:
                return uiColors.darkGreen;
            default:
                return uiColors.mainBlue;
        }
    };

    const handleInitialize = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        onInitialize && onInitialize();
    };

    if (uploadStatusInfo.status === Statuses.IDLE) {
        return null;
    }

    return (
        <SStatusWrapper>
            <SStatusItem>
                <SStatusItemLabel>
                    1. {formatMessage(messages[TranslationKey.ELEMENT_INITIALIZATION_STATUS_UPLOAD_LABEL])}
                </SStatusItemLabel>
                <SStatusItemProgress>
                    <LinearProgress
                        variant='determinate'
                        value={(uploadStatusInfo.uploadedBytes / uploadStatusInfo.fileSizeBytes) * 100}
                        style={{ height: '20px', color: statusColor(uploadStatusInfo.status) }}
                        color='inherit'
                    />
                    <SStatusItemProgressValue>
                        {uploadStatusInfo.status === Statuses.LOADING && (
                            <>
                                {uploadStatusInfo.uploadedBytes} / {uploadStatusInfo.fileSizeBytes}{' '}
                                {formatMessage(messages[TranslationKey.BYTES])}
                            </>
                        )}
                        {uploadStatusInfo.status === Statuses.SUCCEEDED && (
                            <>{formatMessage(messages[TranslationKey.ELEMENT_INITIALIZATION_STATUS_SUCCESS])}</>
                        )}
                        {uploadStatusInfo.status === Statuses.FAILED && (
                            <>{formatMessage(messages[TranslationKey.ELEMENT_INITIALIZATION_STATUS_FAILED])}</>
                        )}
                    </SStatusItemProgressValue>
                </SStatusItemProgress>
            </SStatusItem>

            {onInitialize && (
                <SStatusItem>
                    <SStatusItemLabel>
                        2. {formatMessage(messages[TranslationKey.ELEMENT_INITIALIZATION_STATUS_INITIALIZE_LABEL])}
                    </SStatusItemLabel>
                    <SStatusItemProgress>
                        {![Statuses.SUCCEEDED, Statuses.FAILED].includes(initializeStatusInfo.status) && (
                            <LinearProgress
                                style={{
                                    height: '20px',
                                    color: statusColor(initializeStatusInfo.status, initializeStatusInfo.error),
                                }}
                                color='inherit'
                            />
                        )}
                        {[Statuses.SUCCEEDED, Statuses.FAILED].includes(initializeStatusInfo.status) && (
                            <LinearProgress
                                style={{
                                    height: '20px',
                                    color: statusColor(initializeStatusInfo.status, initializeStatusInfo.error),
                                }}
                                color='inherit'
                                value={100}
                                variant={'determinate'}
                            />
                        )}
                        <SStatusItemProgressValue>
                            {initializeStatusInfo.status === Statuses.IDLE && (
                                <>
                                    {formatMessage(
                                        messages[TranslationKey.ELEMENT_INITIALIZATION_STATUS_UPLOAD_WAITING]
                                    )}
                                </>
                            )}
                            {initializeStatusInfo.status === Statuses.LOADING && (
                                <>{formatMessage(messages[TranslationKey.ELEMENT_INITIALIZATION_STATUS_LOADING])}</>
                            )}
                            {initializeStatusInfo.status === Statuses.SUCCEEDED && (
                                <>{formatMessage(messages[TranslationKey.ELEMENT_INITIALIZATION_STATUS_SUCCESS])}</>
                            )}
                            {initializeStatusInfo.status === Statuses.FAILED && (
                                <>
                                    {initializeStatusInfo.error &&
                                        ERROR_KEYS.includes(initializeStatusInfo.error) &&
                                        formatMessage({ id: initializeStatusInfo.error })}
                                    {initializeStatusInfo.error === null ||
                                        (initializeStatusInfo.error &&
                                            !ERROR_KEYS.includes(initializeStatusInfo.error) &&
                                            formatMessage(
                                                messages[TranslationKey.ELEMENT_INITIALIZATION_STATUS_FAILED]
                                            ))}
                                    {initializeStatusInfo.error &&
                                        WARNING_ERROR_KEYS.includes(initializeStatusInfo.error) && (
                                            <SRunAgainButton
                                                onClick={(e: MouseEvent<HTMLButtonElement>) => handleInitialize(e)}
                                            >
                                                {formatMessage(
                                                    messages[TranslationKey.ELEMENT_INITIALIZATION_RUN_AGAIN]
                                                )}
                                            </SRunAgainButton>
                                        )}
                                </>
                            )}
                        </SStatusItemProgressValue>
                    </SStatusItemProgress>
                </SStatusItem>
            )}
        </SStatusWrapper>
    );
};
