import styled from 'styled-components';

import { pxToRem } from '@repeat/ui-kit';

export const SSortedBlock = styled.span`
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: ${pxToRem(5)};
    width: ${pxToRem(10)};
    height: ${pxToRem(12)};
    i {
        position: absolute;
    }
    i:first-child {
        bottom: ${pxToRem(4)};
    }

    i:last-child {
        top: ${pxToRem(4)};
    }

    svg {
        width: ${pxToRem(10)};
        height: ${pxToRem(10)};
    }
`;
