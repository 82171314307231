import { AxiosResponse } from 'axios';

import { Company, IApiHeaders, IDocument, ITransaction, RegisterUserData, User, UserCurrentUser } from '@repeat/models';

import { $api } from './http';

type SingInPayload = { login: string; password: string };

interface ISignIn {
    (payload: SingInPayload, signal?: AbortSignal): Promise<UserCurrentUser>;
}

type SingUpPayload = RegisterUserData | (RegisterUserData & Record<string, string | number>);
interface ISignUp {
    (payload: SingUpPayload, locale?: string, signal?: AbortSignal): Promise<AxiosResponse<unknown>>;
}

interface ReturnUpdateUserContract {
    email: string;
    id: number;
    login: string;
    phone: string;
    tariffId: number;
    userName: string;
}

type UpdateUserPayload = { userName: string; email: string; phone: string };

interface IUpdateUser {
    (payload: UpdateUserPayload, signal?: AbortSignal): Promise<AxiosResponse<ReturnUpdateUserContract>>;
}

interface IReturnUpdateSettingsContract {
    settings: {
        locale: string;
        isEngineer: boolean;
    };
}

interface IUpdateSettingsPayload {
    settings: {
        locale: string;
        isEngineer: boolean;
        theme: string;
    };
}

interface IUpdateSettings {
    (payload: IUpdateSettingsPayload, signal?: AbortSignal): Promise<AxiosResponse<IReturnUpdateSettingsContract>>;
}

interface ReturnUpdatePasswordContract {
    [key: string]: unknown;
}

type UpdatePasswordPayload = { password: string; newPassword: string };
interface IUpdatePassword {
    (payload: UpdatePasswordPayload, signal?: AbortSignal): Promise<AxiosResponse<ReturnUpdatePasswordContract>>;
}

type ReturnGetUserCompanyContract = Company;

interface IGetUserCompany {
    (signal?: AbortSignal): Promise<AxiosResponse<ReturnGetUserCompanyContract>>;
}
interface IGetUserBalance {
    (signal?: AbortSignal): Promise<ITransaction[]>;
}
interface IGetUserDocuments {
    (signal?: AbortSignal): Promise<IDocument[]>;
}

type ReturnGetUserContract = User;
interface IGetUser {
    (signal?: AbortSignal): Promise<ReturnGetUserContract>;
}

export default class UserService {
    static signUp: ISignUp = async (payload: SingUpPayload, locale?: string, signal?: AbortSignal) => {
        let headers: IApiHeaders = {};
        if (locale) {
            headers = {
                ...headers,
                locale,
            };
        }
        return $api.post('/api/v1/auth/signUp', payload, { headers, signal });
    };

    static signIn: ISignIn = async (payload: SingInPayload, signal?: AbortSignal) => {
        return await $api
            .post('/api/v1/auth/signIn', payload, { signal })
            .then((resp) => resp.data)
            .then(
                (currentUser: UserCurrentUser) =>
                    <UserCurrentUser>{
                        ...currentUser,
                        user: { ...currentUser.user, accountType: currentUser.user?.accountType?.toUpperCase() },
                    }
            );
    };

    // проверка accessToken
    static async checkAuth<T>(token: any, signal?: AbortSignal) {
        return $api.post<T>('/api/v1/auth/isAuthorization', { accessToken: token }, { signal });
    }

    static async getCompanyInfo<T extends { companies: ReturnGetUserCompanyContract[] }>(signal?: AbortSignal) {
        return $api
            .get<T>('/api/v1/user/company', { signal })
            .then((resp) => resp.data)
            .then(({ companies }) => companies[0]);
    }

    // редактирование данных пользователя
    static async editUser<T>(newUser: any, signal?: AbortSignal) {
        return $api.post<T>('/api/v1/user/edit', newUser, { signal });
    }

    static updateUser: IUpdateUser = async (payload: UpdateUserPayload, signal?: AbortSignal) => {
        return await $api.post('/api/v1/user/edit', payload, { signal });
    };

    static updateSettings: IUpdateSettings = async (payload: IUpdateSettingsPayload, signal?: AbortSignal) => {
        return await $api.post('/api/v1/user/edit', payload, { signal });
    };

    static updatePassword: IUpdatePassword = async (payload: UpdatePasswordPayload, signal?: AbortSignal) => {
        return await $api.post('/api/v1/user/edit', payload, { signal });
    };

    static getCompany: IGetUserCompany = async (signal?: AbortSignal) => {
        return $api.get('/api/v1/user/company', { signal });
    };

    static getUser: IGetUser = async (signal?: AbortSignal) => {
        return $api.get('/api/v1/user', { signal }).then((resp) => resp.data);
    };

    static existUser = async (value: string, type: string, signal?: AbortSignal) => {
        return $api.get(`/api/v1/auth/checkFieldForUniqueness?field=${type}&value=${value}`, { signal });
    };

    static setOnline = async (signal?: AbortSignal) => {
        return $api.put('/api/v1/billing/tariff/updateLastActive', { signal });
    };

    static refreshToken = async (refreshToken: string, signal?: AbortSignal) => {
        return $api.post(
            `/api/v1/auth/refreshToken`,
            {
                refreshToken,
            },
            { signal }
        );
    };
}
