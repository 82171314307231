export const verticalAlign = (align = 'center') => `
    align-items: ${align};
    display: flex;
`;

export const overflow = () => `
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const transitionAnimation = (properties?: string[]) => `
    transition: ${properties ? properties.join(', ') : 'all'} 0.3s ease-out;
    will-change: ${properties ? properties.join(', ') : 'auto'};
`;
