export const CANVAS = {
    MIN_ZOOM: 0.2,
    MAX_ZOOM: 1.5,
    GRID_STEP: 15,
    ELEMENT_MIN_WIDTH: 90,
    ELEMENT_MIN_HEIGHT: 90,
    PORT_MARGIN: 15,
    //// consts below are not used in react flow
    DEFAULT_ZOOM: 0.25,
    ELEMENT: {
        DEFAULT_SIZE: 30,
        MIN_WIDTH: 630,
        MIN_HEIGHT: 630,
        MARGIN: 10,
        TEXT_MARGIN: {
            X: 130,
            Y: 170,
        },
        STROKE_WIDTH: 20,
        TEXT_STYLE: {
            fontFamily: ['RosAtom', 'Arial', 'sans-serif'],
            fontWeight: 'bold',
            fontSize: 130,
        },
    },
    PORT: {
        WIDTH: 50,
        MARGIN: 100,
        STROKE_WIDTH: 15,
        PROTECTED_DELTA: 35,
    },
};

export const ELEMENTS_TYPE: { [key: string]: string } = {
    text: 'text',
    element: 'element',
    image: 'image',
};

export const ELEMENTS_COLORS: { [key: string]: string } = {
    electrocity: '#b65b00',
    electrocitydc: '#b65b00',
    simulation: '#0a007f',
    systemDesign: '#ad1213',
    externalModels: '#000000',
    common: '#000000',
    auto: '#000000',
    cms: '#000000',
};

export const MEMORY_USAGE_LIMIT = 500;
