import { Action } from 'redux';

/* eslint-disable @typescript-eslint/no-explicit-any */

// TODO: consider naming !!!
export enum ModalOptionsTypes {
    SAVE = 'save',
    LOAD = 'load',
    CONTEXT_MENU = 'CONTEXT_MENU',
    DEFAULT_MODAL = 'DEFAULT_MODAL',
    PRELOADER = 'PRELOADER',
    CHART = 'CHART',
}

export interface ModalOptionsAction extends Action<ModalOptionsTypes> {
    type: ModalOptionsTypes;
    payload?: any;
}
