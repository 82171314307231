import styled from 'styled-components';

import { uiRGBColors, layoutSize, pxToRem, uiColors, zIndex } from '../../../config/config';
import { verticalAlign } from '../../../config/mixin';

interface IActive {
    isActive?: boolean;
}

interface IIsWide {
    isWideActive?: boolean;
}

interface ISFooter extends IActive, IIsWide {}

export const SFooterControl = styled.div`
    ${verticalAlign()};
    font-size: ${pxToRem(14)};
    justify-self: end;
    width: ${pxToRem(32)};
`;

export const SFooterControlWrapper = styled.li`
    display: flex;
    justify-content: flex-end;
    position: relative;
`;

export const SFooterTabBadge = styled.div`
    align-items: center;
    display: flex;
    border-radius: 50%;
    background: ${uiColors.green};
    color: ${uiColors.white};
    font-size: ${pxToRem(12)};
    justify-content: center;
    padding: ${pxToRem(4)};
    width: 20px;
    height: 20px;
    margin-left: ${pxToRem(8)};
`;

export const SFooterTab = styled.li<IActive>`
    ${verticalAlign()};
    background: ${({ isActive }) => isActive && `rgba(${uiRGBColors.white}, 10%)`};
    color: ${uiColors.white};
    display: inline-flex;
    height: ${layoutSize.footer};
    padding: ${pxToRem(8)} ${pxToRem(16)};

    button {
        color: inherit;
        order: 0;
    }

    svg {
        fill: ${uiColors.white} !important;
        min-width: ${pxToRem(24)};
    }
`;

export const SFooterTabs = styled.ul`
    background-color: var(--ui-primary);
    display: grid;
    grid-template-columns: repeat(4, min-content) 1fr;
    gap: ${pxToRem(16)};
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
`;

export const SFooterContentItem = styled.div<IActive>`
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    position: relative;
    height: 100%;
`;

export const SFooterContentItems = styled.div<{ isWideActive: boolean }>`
    background-color: var(--ui-background);
    position: relative;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0;
    transition: height 0.3s ease;
`;

export const SFooter = styled.footer<ISFooter>`
    background: var(--ui-backround);
    grid-area: Footer;
    position: fixed;
    top: ${({ isActive }) => !isActive && `calc(100% - ${layoutSize.footer})`};
    top: ${({ isActive }) => isActive && `calc(100vh / 2)`};
    top: ${({ isActive, isWideActive }) => isActive && isWideActive && `calc(${layoutSize.header})`};
    height: ${({ isActive, isWideActive }) => isActive && isWideActive && `calc(100vh - ${layoutSize.header})`};
    height: ${({ isActive, isWideActive }) => isActive && !isWideActive && `calc(100vh / 2)`};
    transition: all 0.3s ease;
    will-change: height, top;
    width: 100%;
    z-index: ${zIndex.footer};
    padding-bottom: ${pxToRem(32)};
`;
