import { defineMessages, useIntl } from 'react-intl';

import { css } from 'styled-components';

import { monoLogoUrl, VERSION } from '@repeat/constants';
import { useAppSelector } from '@repeat/hooks';
import { ru, TranslationKey } from '@repeat/translations';
import { Header, Layout, Main } from '@repeat/ui-kit';

import { NotFoundMessage } from './NotFoundMessage';

import { Navigation } from '../../features/Navigation/Navigation';

const messages = defineMessages({
    [TranslationKey.ERROR_PAGE_NOT_FOUND]: {
        id: TranslationKey.ERROR_PAGE_NOT_FOUND,
        defaultMessage: ru[TranslationKey.ERROR_PAGE_NOT_FOUND],
    },
});

export const NotFoundPage = () => {
    const { formatMessage, locale } = useIntl();
    const user = useAppSelector((state) => state.appUser);

    return (
        <Layout>
            <Header
                Navigation={user?.isAuth && <Navigation />}
                version={VERSION}
                logoUrl={monoLogoUrl(locale)}
                indexUrl='/projects'
            />
            <Main style={{ margin: '0' } as keyof typeof css}>
                <NotFoundMessage>{formatMessage(messages[TranslationKey.ERROR_PAGE_NOT_FOUND])}</NotFoundMessage>
            </Main>
        </Layout>
    );
};
