import { changeHandleId } from '@repeat/constants';
import { ILibraryItem, TSchemaConnection, TSchemaGroup, TSchemaNode } from '@repeat/models';

interface ISchemaItems {
    elements: TSchemaNode[];
    wires: TSchemaConnection[];
    groups: TSchemaGroup[];
}

const ELEMENTS_TO_UPDATE_TYPES = ['_TorqueSource', '_ForceSource'];

export const applyPatch_1_9_0 = (schemaItems: ISchemaItems, libraryItems: ILibraryItem[]): ISchemaItems => {
    let nodes = schemaItems.elements;
    let connections = schemaItems.wires;

    const elementsToUpdate = nodes.filter((el) => ELEMENTS_TO_UPDATE_TYPES.includes(el.data.type));
    const elementsToUpdateIds = elementsToUpdate.map((el) => el.id);

    const newPortsNamesByOldPortsNames: { [key: string]: string } = { in_1: 'in_2', in_2: 'in_1' };

    if (elementsToUpdate.length !== 0) {
        nodes = schemaItems.elements.map((node: TSchemaNode) => {
            if (ELEMENTS_TO_UPDATE_TYPES.includes(node.data.type)) {
                const libraryItem = libraryItems.find((item) => item.type === node.data.type);
                if (libraryItem) {
                    const { availablePorts } = libraryItem;
                    if (availablePorts) {
                        const currentNodePorts = node.data.availablePorts.map((port, index) => {
                            let isConnected = port.isConnected;

                            if (Object.keys(newPortsNamesByOldPortsNames).includes(port.name)) {
                                if (port.name === 'in_1') {
                                    const port = node.data.availablePorts.find((port) => port.name === 'in_2');
                                    if (port) {
                                        isConnected = port.isConnected;
                                    }
                                } else if (port.name === 'in_2') {
                                    const port = node.data.availablePorts.find((port) => port.name === 'in_1');
                                    if (port) {
                                        isConnected = port.isConnected;
                                    }
                                }
                            }
                            return {
                                ...port,
                                position: availablePorts[index].position,
                                isConnected,
                            };
                        });

                        return { ...node, data: { ...node.data, availablePorts: currentNodePorts } };
                    }
                }
            }
            return node;
        });

        const portsIds = elementsToUpdate.map((node) => [`${node.id}-1-in_1`, `${node.id}-2-in_2`]).flat();
        const wiresConnectedToUpdatedElements = connections.filter(
            (connection) => portsIds.includes(connection.sourceHandle) || portsIds.includes(connection.targetHandle)
        );

        const oldWires = connections.filter((connection) => !portsIds.includes(connection.sourceHandle) && !portsIds.includes(connection.targetHandle));

        const newWires = wiresConnectedToUpdatedElements.map((wire) => {
            if (elementsToUpdateIds.some((id) => wire.sourceHandle.includes(id))) {
                return { ...wire, sourceHandle: changeHandleId(wire.sourceHandle, newPortsNamesByOldPortsNames) };
            } else if (elementsToUpdateIds.some((id) => wire.targetHandle.includes(id))) {
                return { ...wire, targetHandle: changeHandleId(wire.targetHandle, newPortsNamesByOldPortsNames) };
            }
            return wire;
        });

        connections = [...oldWires, ...newWires];
    }

    return {
        ...schemaItems,
        elements: nodes,
        wires: connections,
    };
};
