import { FC } from 'react';

import styled from 'styled-components';

import { sanitizeHTML } from '@repeat/constants';

interface TooltipContentProps {
    help?: { description: string; image: string };
}

const SImage = styled.img`
    display: block;
    width: 100%;
    min-width: 150px;
`;

const SDescription = styled.div`
    ol {
        list-style-type: decimal;
        list-style-position: inside;
        text-align: left;
    }
`;

export const TooltipContent: FC<TooltipContentProps> = ({ help }) => {
    if (!help) {
        return null;
    }

    return (
        <>
            {help?.description && <SDescription dangerouslySetInnerHTML={sanitizeHTML(help.description)} />}
            {help?.image && <SImage src={help.image} alt={''} />}
        </>
    );
};
