import React, { FC, useEffect, useRef, useState } from 'react';
import ReactCrop, { type Crop, type PixelCrop, centerCrop, makeAspectCrop } from 'react-image-crop';

import { TUserBlockData } from 'libs/models/src/lib/element';

import { Button } from '@repeat/ui-kit';

import 'react-image-crop/dist/ReactCrop.css';
import { SControlButtons, SImage, SInputFile, SPreviewBlock, SPreviewCanvas } from './SImageEditor';
import { canvasPreview } from './canvasPreview';

const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) => {
    return centerCrop(
        makeAspectCrop(
            {
                unit: 'px',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight
        ),
        mediaWidth,
        mediaHeight
    );
};

export const ImageCrop: FC<{
    data: TUserBlockData;
    setData: (state: TUserBlockData) => void;
}> = ({ data, setData }) => {
    const [imgSrc, setImgSrc] = useState(data.image);
    const imgSrcDefault = '/assets/library/images/1.svg';
    const filePicker = useRef<HTMLInputElement>(null);

    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [aspect, setAspect] = useState<number | undefined>(1);

    const handlePick = () => {
        filePicker?.current?.click();
    };

    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined);
            const reader = new FileReader();
            reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget;
            setCrop(centerAspectCrop(width, height, aspect));
        }
    }

    useEffect(() => {
        if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
            canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate);
        }

        const imgCropped = previewCanvasRef.current?.toDataURL('image/*');

        setData({ ...data, image: imgCropped || '' });
    }, [completedCrop, scale, rotate]);

    return (
        <div>
            {!imgSrc && (
                <SImage>
                    <img src={imgSrcDefault} />
                </SImage>
            )}
            <Button variant='outlined' size='small' onClick={handlePick}>
                Изменить
            </Button>
            <SInputFile type='file' accept='image/*' ref={filePicker} onChange={onSelectFile} />
            <div className='Crop-Controls'>
                {!!imgSrc && (
                    <SControlButtons>
                        <div>
                            <label htmlFor='scale-input'>Масштабировать: </label>
                            <input
                                id='scale-input'
                                type='number'
                                step='0.1'
                                value={scale}
                                disabled={!imgSrc}
                                onChange={(e) => setScale(Number(e.target.value))}
                            />
                        </div>
                        <div>
                            <label htmlFor='rotate-input'>Повернуть: </label>
                            <input
                                id='rotate-input'
                                type='number'
                                value={rotate}
                                disabled={!imgSrc}
                                onChange={(e) => setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))}
                            />
                        </div>
                    </SControlButtons>
                )}
            </div>
            <SPreviewBlock>
                {!!imgSrc && (
                    <ReactCrop
                        crop={crop}
                        onChange={(pixelCrop, percentCrop) => setCrop(pixelCrop)}
                        onComplete={(c) => setCompletedCrop(c)}
                        aspect={aspect}
                        keepSelection
                        minHeight={90}
                    >
                        <img
                            ref={imgRef}
                            alt='Crop me'
                            src={imgSrc}
                            style={{ maxHeight: 300, maxWidth: 300, transform: `scale(${scale}) rotate(${rotate}deg)` }}
                            onLoad={onImageLoad}
                        />
                    </ReactCrop>
                )}
                {!!completedCrop && (
                    <SPreviewCanvas>
                        <canvas
                            ref={previewCanvasRef}
                            style={{
                                border: '1px solid black',
                                objectFit: 'contain',
                                width: completedCrop.width,
                                height: completedCrop.height,
                            }}
                        />
                    </SPreviewCanvas>
                )}
            </SPreviewBlock>
        </div>
    );
};
