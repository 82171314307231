import { toJpeg, toSvg } from 'html-to-image';

import { SCREENSHOT_OPTIONS } from '@repeat/constants';

export const screenshotGenerator = async (
    callback: (dataUrl: string) => void,
    onStart?: () => void,
    onEnd?: () => void
) => {
    const SSTarget = document.querySelector('.react-flow__pane') as HTMLDivElement;

    if (SSTarget) {
        onStart && onStart();
        toJpeg(SSTarget, {
            ...SCREENSHOT_OPTIONS(SSTarget),
        })
            .then((canvas) => {
                callback(canvas);
            })
            .then(() => onEnd && onEnd());
    }
};

export const screenshotDownloader = async (onStart?: () => void, onEnd?: () => void) => {
    const SSTarget = document.querySelector('.react-flow__pane') as HTMLDivElement;

    if (SSTarget) {
        onStart && onStart();
        toSvg(SSTarget, {
            ...SCREENSHOT_OPTIONS(SSTarget),
            quality: 1,
        })
            .then(function (dataUrl) {
                const link = document.createElement('a');
                link.setAttribute('id', 'screenshot-download-link');
                link.download = 'my-image-name.svg';
                link.href = dataUrl;
                link.click();
            })
            .then(() => onEnd && onEnd());
    }
};
