import { Action } from 'redux';

/* eslint-disable @typescript-eslint/no-explicit-any */

export enum TrendRequestTypes {
    TREND_REQUEST = 'TREND_REQUEST',
    CLEAR_TREND_REQUEST = 'CLEAR_TREND_REQUEST',
}

export interface TrendRequestAction extends Action<TrendRequestTypes> {
    type: TrendRequestTypes;
    payload?: any;
}
