import styled from 'styled-components';

import { pxToRem } from '@repeat/ui-kit';

export const STrendSetupWrapper = styled.div`
    min-height: 70px;
    width: 100%;
`;
export const STrendSetupMessage = styled.div`
    font-size: ${pxToRem(12)};
    padding: 0 ${pxToRem(8)};
`;

export const SChartGridTitle = styled.div`
    font-size: 12px;
    align-self: start;
    display: flex;
    justify-content: space-between;
    width: 100%;

    span {
        flex-basis: 100%;
    }

    a {
        color: var(--ui-link-default);
        text-decoration: none;
        display: inline-block;
        cursor: pointer;
    }

    i {
        margin: 0 ${pxToRem(2)} 0 0;
        justify-self: end;
        width: ${pxToRem(18)};
    }
`;

export const SChartDownloadLink = styled.a`
    align-items: center;
    display: inline-flex;
    justify-content: center;
    height: ${pxToRem(20)};
    margin: ${pxToRem(3)} ${pxToRem(0)} 0 0;
    width: ${pxToRem(20)};
`;

export const SUPlot = styled.div<{ theme: string }>`
    userselect: 'none';
    height: 100%;
    position: relative;

    ${({ theme }) =>
        theme === 'dark' &&
        `
        canvas {
            filter: invert(1);
        }
    `}

    .uplot {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 100%;
    }

    .u-inline.u-live th::after {
        content: '';
        vertical-align: middle;
    }

    .u-label {
        font-weight: normal !important;
        font-size: ${pxToRem(12)};
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .u-legend {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        height: 100%;
        max-height: 98px;
        width: 100%;

        .u-series {
            display: flex;
            align-items: center;
            max-height: ${pxToRem(20)};
            margin-right: ${pxToRem(8)};
            margin-bottom: ${pxToRem(4)};
        }

        .u-series th {
            align-items: center;
            display: flex;
            padding: 0;
        }
    }

    .u-series {
        display: inline-flex;
    }
`;
