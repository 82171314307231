import { useCallback, useState } from 'react';

export enum EViewMode {
    TABLE = 'table',
    CARD = 'card',
}

export type TViewMode = EViewMode.TABLE | EViewMode.CARD;

export const useViewMode = (viewModeName = 'defaultViewMode') => {
    const [mode, setMode] = useState<TViewMode>((localStorage.getItem(viewModeName) as TViewMode) || EViewMode.TABLE);

    const handleChangeMode = useCallback(() => {
        const newMode = mode === EViewMode.TABLE ? EViewMode.CARD : EViewMode.TABLE;
        setMode(newMode);
        localStorage.setItem(viewModeName, newMode);
    }, [mode]);

    return {
        mode,
        handleChangeMode,
    };
};

export const DATA_VIEW_MODE_PROPERTY = 'data-view-mode';
export const DATA_VIEW_PREVIEW_MODE_PROPERTY = 'data-preview-mode';
