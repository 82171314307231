import { useState } from 'react';

export const useCopyTextToClipboard = () => {
    const [isCopied, setIsCopied] = useState(false);

    async function copyTextToClipboard(text: string) {
        return await navigator.clipboard.writeText(text);
    }

    const handleCopyEvent = (text: string, onError: () => void, onSuccess?: () => void) => {
        copyTextToClipboard(text)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
                onSuccess && onSuccess();
            })
            .catch((err) => {
                onError();
                console.error(err);
            });
    };

    return {
        isCopied,
        handleCopyEvent,
    };
};
