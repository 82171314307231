import { Figure } from 'react-plotly.js';

import { ModelStatus } from './model-status';
import { Status, TrendsStatuses } from './status';

export type XFromT = 'y/t';
export type XFromY = 'y/x';

export type IChartItemType = XFromT | XFromY;

export enum EChartItemType {
    YFromT = 'y/t',
    YFromX = 'y/x',
}

export interface IChartItemParameter {
    uuid: string;
    parameterTitle: string;
    key: string;
    title: string;
    elementTitle: string;
}

export interface IChartItemParameters {
    index: number;
    uuid: string;
    XParameters: IChartItemParameter;
    YParameters: IChartItemParameter[];
}

export interface IChartEditorParameters {
    uuid: string;
    figure: Figure;
}

export type IChartWithParameters = Omit<IChartItemParameters, 'index'>;

export interface IChartItem {
    uuid: string;
    XParameters: IChartItemParameter | null; // if user are not selected parameters
    YParameters: IChartItemParameter[];
    modelNames: string[];
    type: IChartItemType | null; // if type are not selected by parameters
    timeStart: number;
    timeEnd: number;
    data: number[] | null; // if data are not requested
    elementIDs: null | string[];
    width: number;
    height: number;
    isEdit: boolean;
    layout?: Partial<Plotly.Layout>;
}

// null if response from any chart has return error
export interface IChartList {
    [key: number]: IChartItem;
}

export interface IChartsDataMapItem {
    index: number;
    lateTimeStart: number;
    isReady: boolean;
    // isEdit: boolean;
}
export interface IChartsDataMap {
    [key: string]: IChartsDataMapItem;
}

export type TChartsDataItem = number;
export type TChartsData = TChartsDataItem[][];

export interface IChartResponseItem {
    typeGraph: IChartItemType;
    timeStart: number;
    timeEnd: number;
    modelNames: string[];
    config?: unknown[] | null;
}

export type TChartDataValue = number;
export type TChartData = TChartDataValue[][] | null;

export interface IChartResponse {
    projectId: number;
    countGraphs: number;
    graphs: IChartResponseItem[];
    data: TChartData;
}

export interface IGraphsEditorState {
    isOpen: boolean;
    currentGraphUuid: string | null;
}

export interface IGraphsState {
    charts: IChartList;
    commonStatus: TrendsStatuses;
    fetchChartsDataStatus: Status;
    fetchChartsDataError: string | null;
    fetchChartsDataFailedAttempts: number;
    fetchLastTime: number;
    dataMap: IChartsDataMap;
    data: TChartsData[] | null;
    editor: IGraphsEditorState;
}

export interface ITransferChartData {
    index: number | null;
    chart: IChartItem | null;
    data: TChartData;
    dataMap: IChartsDataMap | null;
    status: TrendsStatuses;
    modelStatus: ModelStatus | null;
    hasModelNames: boolean;
    layout?: Partial<Plotly.Layout> | null;
}

export interface IAPIChart {
    typeGraph: string;
    timeStart: number;
    timeEnd: number;
    modelNames: string[];
}
export type TAPIChartDataValue = number | null;
export type TAPIChartData = [TAPIChartDataValue][];

export interface IAPIChartsData {
    projectId: number;
    countGraphs: number;
    graphs: IAPIChart[];
    data: TAPIChartData | null;
}

export const makeDefaultChartItem = (uuid: string, width: number, height: number, isEditMode: boolean): IChartItem => ({
    uuid,
    YParameters: [],
    XParameters: null,
    modelNames: [],
    data: null,
    timeEnd: 0,
    timeStart: 0,
    type: null,
    height,
    width,
    elementIDs: null,
    isEdit: isEditMode,
});
