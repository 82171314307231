import { User } from '../user';

export enum ValidationErrorCode {
    EMAIL_PATTERN = 1238,
    EMAIL_REQUIRED = 1210,
    MESSAGE_TOO_BIG = 1245,
}

export interface IValidationError {
    code: number;
    field: keyof TTechnicalSupportTicket;
    createAt: string;
    message: string;
}

export enum TechnicalSupportSubject {
    FEATURES_CONSULT = 'features-consult',
    TARIFF_CONSULT = 'tariff-consult',
    AUTH_HELP = 'auth-help',
    OTHER_HELP = 'other-help',
}

export type TTechnicalSupportTicket = Pick<User, 'userName' | 'email'> & {
    message: string;
    subject?: TechnicalSupportSubject;
};
