import { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useAppSelector } from '@repeat/hooks';
import { workspaceSelectors } from '@repeat/store';
import { ru, TranslationKey } from '@repeat/translations';

import { EventLogItem } from './EventLogItem';
import { SEventLog } from './SEventLogItem';

const messages = defineMessages({
    [TranslationKey.JOURNAL_EMPTY]: {
        id: TranslationKey.JOURNAL_EMPTY,
        defaultMessage: ru[TranslationKey.JOURNAL_EMPTY],
    },
});

export const EventLog: FC = () => {
    const { formatMessage } = useIntl();
    const eventLogItems = useAppSelector(workspaceSelectors.getEventLogItems);

    if (eventLogItems.length === 0) {
        return <SEventLog>{formatMessage(messages[TranslationKey.JOURNAL_EMPTY])}</SEventLog>;
    }

    return (
        <SEventLog>
            {eventLogItems.map((eventLogItem, index) => {
                return <EventLogItem details={eventLogItem} key={`event-log-item-${index}`} />;
            })}
        </SEventLog>
    );
};
