import { IAppUser, ModelStatuses } from '@repeat/models';
import { UserService } from '@repeat/services';
import { stopProject, workspaceActions } from '@repeat/store';

import { clearLog } from './logAC';

import { UserAction, UserTypes } from '../actions/userTypes';
import { AppDispatch, RootStateFn } from '../store';

export const setUser = (user: IAppUser): UserAction => ({
    type: UserTypes.SET_USER,
    payload: user,
});

export const deleteUser = (): UserAction => ({
    type: UserTypes.DELETE_USER,
    payload: {
        currentUser: {},
        isAuth: false,
    },
});

export const checkAuth = (token: string) => async () => {
    try {
        const response = await UserService.checkAuth(token);
    } catch (error) {
        console.log(error);
    }
};

export const doCleaningStuff = () => async (dispatch: AppDispatch, getState: RootStateFn) => {
    const modelStatus = getState().workspace.modelControl.modelStatus;
    if (modelStatus != ModelStatuses.STOP) {
        await dispatch(stopProject());
    }

    return new Promise((resolve) => {
        setTimeout(() => {
            dispatch(workspaceActions.deleteSchema());
            dispatch(clearLog());

            resolve(true);
        }, 100);
    });
};
