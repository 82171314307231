import { Action } from 'redux';

/* eslint-disable @typescript-eslint/no-explicit-any */

export enum UserTypes {
    SET_USER = 'SET_USER',
    DELETE_USER = 'DELETE_USER',
    USER_HISTORY = 'USER_HISTORY',
    VALID = 'VALID',
    EDIT_USER = 'EDIT_USER',
}

export interface UserAction extends Action<UserTypes> {
    type: UserTypes;
    payload: any;
}
