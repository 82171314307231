import { FC } from 'react';

import moment from 'moment-timezone';

moment.locale('ru');

interface IProjectDate {
    date: string;
    format?: string;
    timezone?: string;
}

export const ProjectDate: FC<IProjectDate> = ({ date, format = 'DD.MM.Y HH:mm:ss' }) => {
    const timezone = moment.tz.guess();
    const parsedDate = moment.tz(date, timezone).format(format);
    return <>{parsedDate}</>;
};
