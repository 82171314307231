import { FC } from 'react';
import { useIntl } from 'react-intl';

import { TranslationKey } from '@repeat/translations';

import { EngineerPageSubLayout } from './EngineerPageSubLayout';
import { messages } from './translate';

import { EngineerBlocks } from '../../features/EngineerMode/EngineerBlocks';

export const EngineerPage: FC = () => {
    const intl = useIntl();

    return (
        <EngineerPageSubLayout title={intl.formatMessage(messages[TranslationKey.PAGE_ENGINEER_TITLE])}>
            <EngineerBlocks />
        </EngineerPageSubLayout>
    );
};
