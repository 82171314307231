import React, { FC, useRef, useState, forwardRef, memo, useLayoutEffect, useEffect, useCallback } from 'react';
import { ResizableBox } from 'react-resizable';

import { heIL } from '@mui/material/locale';

import { SGridViewListItem, SGridViewListItemBody, SGridViewListItemHeader } from './SGridView';
import { getWindowItemWidthCoefficient } from './helpers';

import { uiColors } from '../../config/config';
import { IconButton } from '../IconButton/IconButton';

interface IAdderItem {
    id: number;
    children: React.ReactNode;
    title?: React.ReactNode | string;
    isAppendItem?: boolean;
    onClose?: (index: number) => void;
    onResize?: (arg: any) => void;
    height?: number;
    width?: number;
    options?:
        | (Record<string, unknown> & {
              subtractionHeight?: number;
              tabHeight?: number;
              defaultItemHeight: number;
          })
        | null;
}

export const GridViewItem: FC<IAdderItem> = memo(
    ({ onResize, width, height, id, title, children, isAppendItem = false, onClose, options, ...rest }) => {
        const ref = useRef<HTMLDivElement | null>(null);
        const itemHeightPercentage = 0.75;
        const itemHeight = options?.defaultItemHeight || 350;

        const getValidItemWidth = () => {
            const parentWidth = window.innerWidth;
            return parentWidth / getWindowItemWidthCoefficient();
        };

        const [isFullScreenOpen, setIsFullScreenOpen] = useState(false);
        const [isResized, setIsResized] = useState(false);

        const [defaultSize, setDefaultSize] = useState({
            minWidthConstrains: getValidItemWidth(),
            minHeightConstrains: itemHeight,
            maxWidthConstrains: window.innerWidth,
            maxHeightConstrains: options?.subtractionHeight
                ? window.innerHeight - (options?.subtractionHeight / 2) * itemHeightPercentage
                : itemHeight,
        });

        const onOpenFullScreenHandler = useCallback(() => {
            const newSizeValues = {
                width: defaultSize.maxWidthConstrains,
                height: defaultSize.maxHeightConstrains,
            };
            setIsFullScreenOpen(true);
            setIsResized(true);
            if (onResize) onResize(newSizeValues);
        }, []);

        const onCloseFullScreenHandler = useCallback(() => {
            const newSizeValues = { width: defaultSize.minWidthConstrains, height: defaultSize.minHeightConstrains };
            setIsFullScreenOpen(false);
            setIsResized(false);
            if (onResize) onResize(newSizeValues);
        }, []);

        const onResizeHandler = useCallback((event: any, { size: newSize }: any) => {
            setIsFullScreenOpen(true);
            setIsResized(true);
        }, []);

        const onResizeStopHandler = useCallback((event: any, { size: newSize }: any) => {
            const newSizeValues = { width: newSize.width, height: newSize.height };
            setIsResized(true);
            if (onResize) onResize(newSizeValues);
        }, []);

        const ResizeHandleComponent = forwardRef((props: any, ref) => {
            const { handleAxis, ...restProps } = props;
            return <div ref={ref} className={`react-resizable-handle handle-${handleAxis}`} {...restProps} />;
        });

        return (
            <ResizableBox
                className={'react-resize__grid-item'}
                draggableOpts={{ grid: [1, 1] }}
                data-resized={width ? 'true' : isResized}
                axis={'both'}
                width={width || getValidItemWidth()}
                height={height || itemHeight}
                minConstraints={[defaultSize.minWidthConstrains, defaultSize.minHeightConstrains]}
                maxConstraints={[defaultSize.maxWidthConstrains, defaultSize.maxHeightConstrains]}
                onResize={onResizeHandler}
                onResizeStop={onResizeStopHandler}
                handle={<ResizeHandleComponent ref={ref} />}
                {...rest}
            >
                <SGridViewListItem append={isAppendItem}>
                    {title && (
                        <SGridViewListItemHeader>
                            {title}
                            {!isAppendItem && !isFullScreenOpen && (
                                <IconButton
                                    noHover
                                    name={'fullScreenOpen'}
                                    fill={uiColors.darkGrey}
                                    onClick={onOpenFullScreenHandler}
                                />
                            )}
                            {!isAppendItem && isFullScreenOpen && (
                                <IconButton
                                    noHover
                                    name={'fullScreenClose'}
                                    fill={uiColors.darkGrey}
                                    onClick={onCloseFullScreenHandler}
                                />
                            )}
                            {!isAppendItem && (
                                <IconButton
                                    noHover
                                    name={'close'}
                                    fill={uiColors.darkGrey}
                                    onClick={() => onClose && onClose(id)}
                                />
                            )}
                        </SGridViewListItemHeader>
                    )}
                    <SGridViewListItemBody>{children}</SGridViewListItemBody>
                </SGridViewListItem>
            </ResizableBox>
        );
    }
);
