import React, { FC } from 'react';

import styled from 'styled-components';

import { pxToRem } from '../../../config/config';
import { IModalDody } from "../interfaces";

const StyledModalBody = styled.div`
    max-height: ${pxToRem(600)};
    overflow-y: auto;
    flex: 1 1 auto;
    padding: ${pxToRem(16)};
`;

export const ModalBody: FC<IModalDody> = ({ children }) => <StyledModalBody>{children}</StyledModalBody>;
