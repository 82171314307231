import { getHandleName } from '@repeat/constants';
import {
    ElemParams,
    LibraryTypes,
    PortTypes,
    TSchemaConnection,
    TSchemaGroup,
    TSchemaHandle,
    TSchemaNode,
} from '@repeat/models';

interface ISchemaItems {
    elements: TSchemaNode[];
    wires: TSchemaConnection[];
    groups?: TSchemaGroup[];
}

export const applyPatch_1_4_1 = (schemaItems: ISchemaItems) => {
    const connectedHandlesNamesByElementId = schemaItems.wires.reduce(
        (acc: { [key: string]: string[] }, { source, target, sourceHandle, targetHandle }) => {
            acc[source] = acc[source] || [];
            acc[target] = acc[target] || [];
            acc[source].push(getHandleName(sourceHandle));
            acc[target].push(getHandleName(targetHandle));
            return acc;
        },
        {}
    );

    const nodes = schemaItems.elements.map((node: TSchemaNode) => {
        if (node.data.type === 'sum') {
            const hasOutputElemParams =
                node.data.elemParams.filter((param: ElemParams) => param.name.includes('out')).length > 0;
            if (hasOutputElemParams) {
                return node;
            }

            const inputParamsCount = node.data.elemParams.filter((param: ElemParams) =>
                param.name.includes('in')
            ).length;

            return {
                ...node,
                data: {
                    ...node.data,
                    elemParams: [
                        ...node.data.elemParams,
                        {
                            description: `Выход`,
                            name: `out_${inputParamsCount + 1}`,
                            modelName: '',
                            value: '',
                            unit: '',
                        },
                    ],
                },
            };
        }

        if (node.data.type === 'inductionMotor') {
            const inputAvailablePortsWithoutElectrocity = node.data.availablePorts.filter(
                (port) => port.type === PortTypes.INPUT && !port.libraries.includes(LibraryTypes.ELECTROCITY)
            );
            if (inputAvailablePortsWithoutElectrocity.length > 0) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        availablePorts: node.data.availablePorts.map((port) => {
                            if (port.type === PortTypes.OUTPUT) {
                                return port;
                            }

                            return {
                                ...port,
                                libraries: [...port.libraries, LibraryTypes.ELECTROCITY],
                            };
                        }) as TSchemaHandle[],
                    },
                };
            }
        }

        const availablePorts = node.data.availablePorts.map((port) => {
            let newPort = port;

            Object.keys(connectedHandlesNamesByElementId).map((elementId) => {
                if (node.id === elementId && connectedHandlesNamesByElementId[elementId].includes(port.name)) {
                    newPort = { ...port, isConnected: true };
                }
            });

            return newPort;
        });

        return { ...node, data: { ...node.data, availablePorts } };
    });

    return {
        ...schemaItems,
        groups: schemaItems.groups || [],
        elements: nodes,
    };
};
