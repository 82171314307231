import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { IProjectImportData } from 'libs/models/src/lib/project';

import { IMPORT_PROJECT_FORM, PROJECT_FILE_EXTENSION } from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { Statuses } from '@repeat/models';
import { getProjects, importProject } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { StyledForm } from '@repeat/ui-kit';

import { messages } from './translation';

import { DropZone } from '../../DropZone/DropZone';

interface IImportProjectForm {
    onValid: (isValid: boolean) => void;
}

export const ImportProjectForm: FC<IImportProjectForm> = ({ onValid }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const projectsStatus = useAppSelector((state) => state.projects.status);

    const { formatMessage } = useIntl();

    const {
        handleSubmit,
        formState: { isValid },
        setError,
        clearErrors,
    } = useForm<IProjectImportData>({ mode: 'onChange' });

    const isProjectsInitialized = projectsStatus === Statuses.SUCCEEDED;

    useEffect(() => {
        if (isProjectsInitialized === false) {
            dispatch(getProjects());
        }
    }, [dispatch, isProjectsInitialized]);

    useEffect(() => {
        onValid(isValid);
    }, [isValid]);

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const onSubmit = () => {};

    const acceptFileTypes = { 'text/plain': [`.${PROJECT_FILE_EXTENSION}`] };
    const maxFileSize = 20971520;

    const onFileUpload = async (file: File) => {
        await dispatch(importProject(file, navigate));
    };

    const handleDropZoneError = (hasError: boolean) => {
        if (!hasError) {
            clearErrors();
            return;
        }

        setError('file', { type: 'required' });
    };

    return (
        <StyledForm onSubmit={handleSubmit(onSubmit)} id={IMPORT_PROJECT_FORM}>
            <DropZone
                dropZoneText={formatMessage(messages[TranslationKey.PROJECT_IMPORT_FILE_DESCRIPTION])}
                buttonText={formatMessage(messages[TranslationKey.PROJECT_IMPORT_SELECT_FILE])}
                acceptFileTypes={acceptFileTypes}
                maxFileCount={1}
                maxFileSize={maxFileSize}
                onDropFile={onFileUpload}
                onError={handleDropZoneError}
                error={!isValid}
            />
        </StyledForm>
    );
};
