import { FC, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { Autocomplete, TextField } from '@mui/material';

import { useAppSelector } from '@repeat/hooks';
import { SolverTypes } from '@repeat/models';
import { TranslationKey, ru } from '@repeat/translations';

import {
    SAutocomplete,
    SAutocompleteForm,
} from './../../Visualization/Charts/AutoCompleteParametersForm/SAutocompleteParametersForm';

const messages = defineMessages({
    [TranslationKey.WORKSPACE_PROJECT_TITLE]: {
        id: TranslationKey.WORKSPACE_PROJECT_TITLE,
        defaultMessage: ru[TranslationKey.WORKSPACE_PROJECT_TITLE],
    },
    [TranslationKey.WORKSPACE_SELECT_PROJECT]: {
        id: TranslationKey.WORKSPACE_SELECT_PROJECT,
        defaultMessage: ru[TranslationKey.WORKSPACE_SELECT_PROJECT],
    },
    [TranslationKey.WORKSPACE_NO_PROJECTS_TO_SELECT]: {
        id: TranslationKey.WORKSPACE_NO_PROJECTS_TO_SELECT,
        defaultMessage: ru[TranslationKey.WORKSPACE_NO_PROJECTS_TO_SELECT],
    },
});

interface ISelectedProject {
    id: string;
    title: string;
}

interface IProjectBlockForm {
    onValid: (isValid: boolean) => void;
    onSelectProject: (selectedProject: ISelectedProject | null) => void;
    projectId: number | null;
}
interface ISuggestion {
    projectName: string;
    projectId: number;
}
interface IAutocompleteListBoxProps extends React.HTMLAttributes<HTMLUListElement> {
    'data-test-id'?: string;
}

export const ProjectBlockForm: FC<IProjectBlockForm> = ({ onValid, onSelectProject, projectId }) => {
    const { formatMessage } = useIntl();
    const projectsState = useAppSelector((state) => state.projects);

    const projects = projectsState.items || [];

    const [value, setValue] = useState<ISuggestion | null>(null);

    useEffect(() => {
        onValid(!!value);
    }, [value]);

    const handleSelect = (suggestion: ISuggestion | null) => {
        setValue(suggestion);
        const selectedProject: ISelectedProject | null = suggestion
            ? { id: suggestion.projectId.toString(), title: suggestion.projectName }
            : null;
        onSelectProject(selectedProject);
    };

    const options = projects
        .filter((pr) => projectId && pr.projectId !== projectId && pr.solverType !== SolverTypes.USDS)
        .map((project) => ({ projectName: project.projectName, projectId: project.projectId } as ISuggestion));

    return (
        <SAutocompleteForm
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <SAutocomplete>
                <Autocomplete
                    noOptionsText={formatMessage(messages[TranslationKey.WORKSPACE_NO_PROJECTS_TO_SELECT])}
                    onChange={(e: any, option: ISuggestion) => {
                        handleSelect(option);
                    }}
                    size='small'
                    id='grouped-demo'
                    options={options}
                    getOptionLabel={(option: ISuggestion) => option.projectName || ''}
                    value={value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={formatMessage(messages[TranslationKey.WORKSPACE_PROJECT_TITLE])}
                            placeholder={formatMessage(messages[TranslationKey.WORKSPACE_SELECT_PROJECT])}
                        />
                    )}
                    ListboxProps={
                        {
                            'data-test-id': 'visualization-params-box',
                        } as IAutocompleteListBoxProps
                    }
                    renderOption={(props, option: ISuggestion, state) => {
                        return (
                            <li {...props} key={option.projectId}>
                                {option.projectName}
                            </li>
                        );
                    }}
                />
            </SAutocomplete>
        </SAutocompleteForm>
    );
};
