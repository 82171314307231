import { useState } from 'react';
import { useIntl } from 'react-intl';

import { CircularProgress } from '@mui/material';
import { TTechnicalSupportModal } from 'libs/models/src/lib/modal';

import { ApplicationActions } from '@repeat/common-slices';
import { CREATE_TICKET_TO_TECHNICAL_SUPPORT } from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { ModalTypes } from '@repeat/models';
import { TranslationKey } from '@repeat/translations';
import { Button, Modal } from '@repeat/ui-kit';

import { blockOptionsMessages as messages } from './translation';

import { TechnicalSupportForm } from '../Forms/TechnicalSupportForm';

export const TechnicalSupportModal = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const modals = useAppSelector((state) => state.app.modals);
    const modal = modals.find((modal) => modal.type === ModalTypes.TECHNICAL_SUPPORT) as TTechnicalSupportModal;
    const [isValidForm, setIsValidForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const isUserAuthenticated = useAppSelector((state) => state.appUser.isAuth);

    const handleClose = () => {
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.TECHNICAL_SUPPORT }));
    };
    const handleFormValidate = (isValid: boolean) => {
        setIsValidForm(isValid);
    };
    const FooterButtons = () => (
        <>
            <Button onClick={handleClose} variant='secondary'>
                {intl.formatMessage(messages[TranslationKey.CANCEL])}
            </Button>
            <Button form={CREATE_TICKET_TO_TECHNICAL_SUPPORT} type='submit' disabled={!isValidForm || isLoading}>
                {intl.formatMessage(messages[TranslationKey.SEND])}
                {isLoading && <CircularProgress color='primary' size={20} style={{ position: 'absolute' }} />}
            </Button>
        </>
    );

    return (
        <Modal
            id={ModalTypes.TECHNICAL_SUPPORT}
            title={intl.formatMessage(
                messages[
                    isUserAuthenticated
                        ? TranslationKey.CONTACTING_TECHNICAL_SUPPORT
                        : TranslationKey.CALLING_TECHNICAL_SUPPORT
                ]
            )}
            isOpen={true}
            hasOverlay={true}
            onClose={handleClose}
            type='default'
            size='medium'
            footer={FooterButtons()}
        >
            <TechnicalSupportForm
                email={modal.data.email}
                userName={modal.data.userName}
                onValid={handleFormValidate}
                onLoad={setIsLoading}
            />
        </Modal>
    );
};
