import { PayloadAction } from '@reduxjs/toolkit';

import {
    ElemProps,
    IElementConfiguration,
    ISchemaSelectedBlockProperties,
    IWorkspaceState,
    Statuses,
    TLibraryType,
    TPropertyValue,
    TSchemaNode,
    TSolverType,
} from '@repeat/models';
import { makeBlockPropertyValues } from '@repeat/services';
import { TProxyMap } from 'libs/models/src/lib/element';

export const GetElementProperties = {
    getElementPropertiesSetsRequest: (
        state: IWorkspaceState,
        { payload }: PayloadAction<{ elementType: string; libraryType: TLibraryType; solverType: TSolverType }>
    ) => ({
        ...state,
        schema: {
            ...state.schema,
            getElementPropertiesSets: {
                status: Statuses.LOADING,
                error: null,
                elementType: payload.elementType,
                libraryType: payload.libraryType,
                solverType: payload.solverType,
                configurations: [],
            },
        },
    }),
    getElementPropertiesSetsSuccess: (
        state: IWorkspaceState,
        { payload }: PayloadAction<{ id: number; sets: IElementConfiguration[] }>
    ) => {
        return {
            ...state,
            schema: {
                ...state.schema,
                getElementPropertiesSets: {
                    ...state.schema.getElementPropertiesSets,
                    status: Statuses.SUCCEEDED,
                    error: null,
                    configurations: payload.sets,
                },
            },
        };
    },
    getElementPropertiesSetsFailed: (state: IWorkspaceState, { payload }: PayloadAction<{ error: string }>) => ({
        ...state,
        schema: {
            ...state.schema,
            getElementPropertiesSets: {
                ...state.schema.getElementPropertiesSets,
                status: Statuses.FAILED,
                error: payload.error,
            },
        },
    }),
    setElementConfiguration: (
        state: IWorkspaceState,
        { payload }: PayloadAction<{ id: string; configurationId: number }>
    ) => {
        const elements = state.schema.schemaItems.elements.map((node: TSchemaNode) => {
            if (node.data.id === parseInt(payload.id)) {
                const selectedConfiguration = state.schema.getElementPropertiesSets.configurations?.find(
                    (config) => config.id === payload.configurationId
                );
                if (selectedConfiguration) {
                    const { elementProperties } = selectedConfiguration;
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            elemProps: elementProperties,
                            selectedConfiguration: { ...selectedConfiguration, elementProperties, isSelected: true },
                        },
                    };
                }
            }
            return { ...node };
        });
        return {
            ...state,
            schema: {
                ...state.schema,
                schemaItems: {
                    ...state.schema.schemaItems,
                    elements,
                },
            },
        };
    },
    clearElementConfiguration: (state: IWorkspaceState) => {
        const selectedNode = state.schema.selectedItems.elements[0];
        const elements = state.schema.schemaItems.elements.map((node: TSchemaNode) => {
            if (selectedNode && node.id === selectedNode.id) {
                const props = node.data.elemProps.map((prop: ElemProps) => ({ ...prop, editable: true }));
                return {
                    ...node,
                    data: {
                        ...node.data,
                        elemProps: props,
                        selectedConfiguration: {
                            id: null,
                            name: '',
                            description: '',
                            elementProperties: [],
                            isSelected: false,
                        },
                    },
                };
            }
            return { ...node };
        });
        return {
            ...state,
            schema: {
                ...state.schema,
                schemaItems: { ...state.schema.schemaItems, elements },
            },
        };
    },
    initializeElementProperties: (state: IWorkspaceState, action: PayloadAction<TSchemaNode>) => {
        const block = action.payload.data;

        const selectPropertiesWithDynamicValues = block.elemProps.filter(
            (property: ElemProps) => property.type === 'select' && !!property?.availableValuesSrc
        );
        const selectedItemProperties: ISchemaSelectedBlockProperties = {};
        selectPropertiesWithDynamicValues.forEach((property: ElemProps) => {
            let availableValues: TPropertyValue[] = [];
            if (property?.availableValuesSrc) {
                availableValues = makeBlockPropertyValues(
                    property.availableValuesSrc,
                    property,
                    block,
                    state.schema.schemaItems.elements
                );
            }

            selectedItemProperties[property.name] = {
                availableValues,
            };
        });

        return {
            ...state,
            schema: {
                ...state.schema,
                selectedItemProperties: Object.keys(selectedItemProperties).length > 0 ? selectedItemProperties : null,
            },
        };
    },

    resetElementProperties: (state: IWorkspaceState) => {
        return {
            ...state,
            schema: {
                ...state.schema,
                selectedItemProperties: null,
            },
        };
    },
    setGroupBlockProperty: (
        state: IWorkspaceState,
        { payload }: PayloadAction<{ groupId: string; elementId: string; name: string }>
    ) => {
        const metaElementId = state.meta.elementId;
        const groups = state.schema.schemaItems.groups || [];


        const updateElements = (elements: TSchemaNode[]) => {
            return elements.map((el) => {
                if (el.id === payload.groupId) {
                    const group = groups.find((group) => group.id.toString() === payload.groupId);
                    const element = group?.elements.find((el) => el.id === payload.elementId);
                    const prop = element?.data.elemProps.find((prop) => prop.name === payload.name);
                    if (element && prop) {
                        const propertyName = `${prop.name}-${element.id}`;
                        const resultProperty = {
                            ...prop,
                            name: propertyName,
                            description: `${element.data.name} [${element.data.index}] / ${prop.description}`,
                        } as ElemProps;
                        const proxyMap = el.data.proxyMap || { props: [], params: [] };
                        return {
                            ...el,
                            data: {
                                ...el.data,
                                elemProps: [...el.data.elemProps, resultProperty],
                                proxyMap: {
                                    ...proxyMap,
                                    props: [
                                        ...proxyMap.props,
                                        {
                                            name: `${prop.name}-${element.id}`,
                                            internalBlockId: Number(element.id),
                                            internalName: prop.name,
                                        },
                                    ],
                                },
                            },
                        };
                    }
                }
                return el;
            });
        };

        if (metaElementId === null) {
            const elements = updateElements(state.schema.schemaItems.elements);
            return {
                ...state,
                schema: {
                    ...state.schema,
                    schemaItems: { ...state.schema.schemaItems, elements },
                },
            };
        }
        const groupsUpdated = state.schema.schemaItems.groups?.map((group) => {
            if (group.id.toString() === metaElementId) {
                const elements = updateElements(group.elements);
                return { ...group, elements };
            }
            return group;
        });
        return {
            ...state,
            schema: {
                ...state.schema,
                schemaItems: { ...state.schema.schemaItems, groups: groupsUpdated },
            },
        };
    },
    deleteGroupBlockProperty: (
        state: IWorkspaceState,
        { payload }: PayloadAction<{ groupId: string; propertyName: string }>
    ) => {
        const metaElementId = state.meta.elementId;
        const updateElements = (elements: TSchemaNode[]) => {
            return elements.map((el) => {
                if (el.id === payload.groupId) {
                    const elemProps = el.data.elemProps.filter((prop) => prop.name !== payload.propertyName);
                    const proxyMap = (
                        el.data.proxyMap
                            ? {
                                  ...el.data.proxyMap,
                                  props: el.data.proxyMap.props.filter((prop) => prop.name !== payload.propertyName),
                              }
                            : null
                    ) as TProxyMap;
                    return { ...el, data: { ...el.data, elemProps, proxyMap } };
                }
                return el;
            });
        };

        if (metaElementId === null) {
            const elements = updateElements(state.schema.schemaItems.elements);
            return {
                ...state,
                schema: {
                    ...state.schema,
                    schemaItems: { ...state.schema.schemaItems, elements },
                },
            };
        }
        const groupsUpdated = state.schema.schemaItems.groups?.map((group) => {
            if (group.id.toString() === metaElementId) {
                const elements = updateElements(group.elements);
                return { ...group, elements };
            }
            return group;
        });
        return {
            ...state,
            schema: {
                ...state.schema,
                schemaItems: { ...state.schema.schemaItems, groups: groupsUpdated },
            },
        };
    },
};
