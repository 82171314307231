import { PayloadAction } from '@reduxjs/toolkit';

import { extractNumbers } from '@repeat/constants';
import { ElemParams, ElemProps, ISchemaState, IWire, TLibraryType } from '@repeat/models';

const props = [
    {
        description: 'Минимальное сопротивление, Ом',
        name: 'minr',
        value: '1e-8',
        editable: true,
    },
];

const params = [
    {
        description: 'Полная составляющая тока в начале',
        name: 'i_i_b',
        modelName: '',
        value: '',
        unit: 'А',
    },
    {
        description: 'Полная составляющая тока в конце',
        name: 'i_i_e',
        modelName: '',
        value: '',
        unit: 'А',
    },
    {
        description: 'Реактивная  составляющая тока в начале',
        name: 'i_ii_b',
        modelName: '',
        value: '',
        unit: 'А',
    },
    {
        description: 'Реактивная составляющая тока в конце',
        name: 'i_ii_e',
        modelName: '',
        value: '',
        unit: 'А',
    },
    {
        description: 'Активная  составляющая тока в начале',
        name: 'i_ir_b',
        modelName: '',
        value: '',
        unit: 'А',
    },
    {
        description: 'Активная составляющая тока в конце',
        name: 'i_ir_e',
        modelName: '',
        value: '',
        unit: 'А',
    },
    {
        description: 'Активная мощность',
        name: 'w_p',
        modelName: '',
        value: '',
        unit: 'Вт',
    },
    {
        description: 'Реактивная мощность',
        name: 'w_q',
        modelName: '',
        value: '',
        unit: 'Вар',
    },
    {
        description: 'Полная мощность',
        name: 'w_s',
        modelName: '',
        value: '',
        unit: 'ВА',
    },
];

export const wiresReducers = {
    addWireFirstElement: (state: ISchemaState, { payload }: PayloadAction<{ elementId: number; num: number; libraryType: TLibraryType }>) => {
        const wires = state.schemaItems.wires;
        let maxIndex = '';
        if (wires.length > 1) {
            maxIndex = wires.reduce((acc: IWire, curr: IWire) => (extractNumbers(acc?.index) > extractNumbers(curr?.index) ? acc : curr))?.index;
        }
        const numberIndex = extractNumbers(maxIndex) + 1;
        const properties = payload.libraryType === ('auto' as TLibraryType) ? [] : props;
        const parameters = payload.libraryType === ('auto' as TLibraryType) ? [] : params;
        const wire: IWire = {
            firstElement: payload.elementId,
            firstPort: payload.num,
            id: Date.now(),
            width: 1,
            wireProps: properties,
            wireParams: parameters,
            type: 'wire',
            index: `w${numberIndex}`,
            secondElement: 0,
            secondPort: 0,
        };

        return {
            ...state,
            schemaItems: {
                ...state.schemaItems,
                wires: [...state.schemaItems.wires, wire],
            },
        };
    },
    addWireSecondElement: (state: ISchemaState, { payload }: PayloadAction<{ elementId: number; num: number }>) => {
        const wires = JSON.parse(JSON.stringify(state.schemaItems.wires));

        if (wires[wires.length - 1]?.firstElement === payload.elementId) {
            return { ...state };
        }

        wires[wires.length - 1].secondElement = payload.elementId;
        wires[wires.length - 1].secondPort = payload.num;

        return {
            ...state,
            schemaItems: {
                ...state.schemaItems,
                wires,
            },
        };
    },
    deleteCrashWire: (state: ISchemaState) => {
        const wires = JSON.parse(JSON.stringify(state.schemaItems.wires));
        wires.pop();
        return {
            ...state,
            schemaItems: {
                ...state.schemaItems,
                wires,
            },
        };
    },
    setCurrentWire: (state: ISchemaState, { payload }: PayloadAction<{ id: number | null }>) => {
        const wires = state.schemaItems.wires.map((wire: IWire) => {
            if (wire.id === payload.id) {
                return {
                    ...wire,
                    color: 0x0000cc,
                };
            } else {
                return { ...wire, color: 0x333333 };
            }
        });
        return {
            ...state,
            schemaItems: {
                ...state.schemaItems,
                wires,
            },
        };
    },
    setParentWire: (state: ISchemaState, { payload }: PayloadAction<{ id: number | null }>) => {
        const wires = state.schemaItems.wires.map((wire: IWire) => {
            if (wire.id === payload.id) {
                return {
                    ...wire,
                    color: 0xfff000,
                };
            } else {
                return { ...wire, color: 0x333333 };
            }
        });
        return {
            ...state,
            schemaItems: {
                ...state.schemaItems,
                wires,
            },
        };
    },
    changeWireWidth: (state: ISchemaState, { payload }: PayloadAction<{ id: number; width: number }>) => {
        const wires = state.schemaItems.wires.map((wire: IWire) => {
            if (wire.id === payload.id) {
                return {
                    ...wire,
                    width: payload.width,
                };
            } else return wire;
        });
        return {
            ...state,
            schemaItems: {
                ...state.schemaItems,
                wires,
            },
        };
    },
    deleteWireFromElement: (state: ISchemaState, { payload }: PayloadAction<{ id: number }>) => {
        const wires: IWire[] = [];
        state.schemaItems.wires.map((wire: IWire) => {
            if (!(wire.firstElement === payload.id || wire.secondElement === payload.id)) {
                wires.push(wire);
            }
        });
        return {
            ...state,
            schemaItems: {
                ...state.schemaItems,
                wires,
            },
        };
    },
    setWirePropertiesValues: (state: ISchemaState, { payload }: PayloadAction<{ id: number; elemProps: { [key: string]: string } }>) => {
        const wires = state.schemaItems.wires.map((wire: IWire) => {
            if (wire.id === payload.id) {
                const keys = Object.keys(payload.elemProps);
                const newProps = wire?.wireProps?.map((option: ElemProps) => {
                    for (let i = 0; i <= keys.length; i++) {
                        if (option.name === keys[i]) {
                            return {
                                ...option,
                                value: payload.elemProps[keys[i]],
                            };
                        }
                    }
                    return option;
                });
                return {
                    ...wire,
                    wireProps: newProps,
                };
            }
            return wire;
        });
        return {
            ...state,
            schemaItems: {
                ...state.schemaItems,
                wires,
            },
        };
    },
    setWireParameters: (state: ISchemaState, { payload }: PayloadAction<{ [key: string]: { index: number; params: ElemParams[] } } | null>) => {
        if (!payload) {
            return {
                ...state,
            };
        }

        const wires = state.schemaItems.wires.map((wire: IWire) => {
            const keys = Object.keys(payload);
            for (let i = 0; i <= keys.length; i++) {
                if (wire.id === Number(keys[i])) {
                    return {
                        ...wire,
                        wireParams: payload[keys[i]].params,
                    };
                }
            }
            return wire;
        });

        return {
            ...state,
            schemaItems: {
                ...state.schemaItems,
                wires: [...wires],
            },
        };
    },
    setWireParametersValues: (state: ISchemaState, { payload }: PayloadAction<{ [key: string]: string | number }>) => {
        const wires = state.schemaItems.wires.map((wire: IWire) => {
            let allParametersModelNames: string[] = [];
            if (payload) {
                allParametersModelNames = Object.keys(payload);
            }
            const newParams = wire?.wireParams?.map((param: ElemParams) => {
                let newValue: string | number = '';
                for (let i = 0; i <= allParametersModelNames.length; i++) {
                    if (param.modelName === allParametersModelNames[i]) {
                        newValue = payload[allParametersModelNames[i]] !== '-' ? Number(payload[allParametersModelNames[i]]) : '-';
                        return { ...param, value: newValue };
                    }
                }
                return param;
            });
            return { ...wire, wireParams: newParams };
        });

        return {
            ...state,
            schemaItems: {
                ...state.schemaItems,
                wires: [...wires],
            },
        };
    },
};
