import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.WORKSPACE_CHART_SETUP_PARAMETER]: {
        id: TranslationKey.WORKSPACE_CHART_SETUP_PARAMETER,
        defaultMessage: ru[TranslationKey.WORKSPACE_CHART_SETUP_PARAMETER],
    },
    [TranslationKey.WORKSPACE_CHART_DOWNLOAD_CSV]: {
        id: TranslationKey.WORKSPACE_CHART_DOWNLOAD_CSV,
        defaultMessage: ru[TranslationKey.WORKSPACE_CHART_DOWNLOAD_CSV],
    },
    [TranslationKey.WORKSPACE_PARAMETERS_NOT_FOUND]: {
        id: TranslationKey.WORKSPACE_PARAMETERS_NOT_FOUND,
        defaultMessage: ru[TranslationKey.WORKSPACE_PARAMETERS_NOT_FOUND],
    },
    [TranslationKey.WORKSPACE_PARAMETERS]: {
        id: TranslationKey.WORKSPACE_PARAMETERS,
        defaultMessage: ru[TranslationKey.WORKSPACE_PARAMETERS],
    },
    [TranslationKey.WORKSPACE_PARAMETERS_X]: {
        id: TranslationKey.WORKSPACE_PARAMETERS_X,
        defaultMessage: ru[TranslationKey.WORKSPACE_PARAMETERS_X],
    },
    [TranslationKey.WORKSPACE_WARNING_CHART_START]: {
        id: TranslationKey.WORKSPACE_WARNING_CHART_START,
        defaultMessage: ru[TranslationKey.WORKSPACE_WARNING_CHART_START],
    },
    [TranslationKey.WORKSPACE_CHART_CSV_TIME_TITLE]: {
        id: TranslationKey.WORKSPACE_CHART_CSV_TIME_TITLE,
        defaultMessage: ru[TranslationKey.WORKSPACE_CHART_CSV_TIME_TITLE],
    },
    [TranslationKey.WORKSPACE_CHART_POINT_VALUE]: {
        id: TranslationKey.WORKSPACE_CHART_POINT_VALUE,
        defaultMessage: ru[TranslationKey.WORKSPACE_CHART_POINT_VALUE],
    },
    [TranslationKey.UNIT_SECOND]: {
        id: TranslationKey.UNIT_SECOND,
        defaultMessage: ru[TranslationKey.UNIT_SECOND],
    },
    [TranslationKey.WORKSPACE_CHART_TYPE_Y_T]: {
        id: TranslationKey.WORKSPACE_CHART_TYPE_Y_T,
        defaultMessage: ru[TranslationKey.WORKSPACE_CHART_TYPE_Y_T],
    },
    [TranslationKey.WORKSPACE_CHART_TYPE_Y_X]: {
        id: TranslationKey.WORKSPACE_CHART_TYPE_Y_X,
        defaultMessage: ru[TranslationKey.WORKSPACE_CHART_TYPE_Y_X],
    },
    [TranslationKey.CHART]: {
        id: TranslationKey.CHART,
        defaultMessage: ru[TranslationKey.CHART],
    },
    [TranslationKey.PARAMETERS]: {
        id: TranslationKey.PARAMETERS,
        defaultMessage: ru[TranslationKey.PARAMETERS],
    },
    [TranslationKey.ADD]: {
        id: TranslationKey.ADD,
        defaultMessage: ru[TranslationKey.ADD],
    },
    [TranslationKey.EDITING]: {
        id: TranslationKey.EDITING,
        defaultMessage: ru[TranslationKey.EDITING],
    },
    [TranslationKey.NEW_WINDOW_OPEN]: {
        id: TranslationKey.NEW_WINDOW_OPEN,
        defaultMessage: ru[TranslationKey.NEW_WINDOW_OPEN],
    },
    [TranslationKey.WORKSPACE_CHART_ADD]: {
        id: TranslationKey.WORKSPACE_CHART_ADD,
        defaultMessage: ru[TranslationKey.WORKSPACE_CHART_ADD],
    },
    [TranslationKey.SCHEMA_INVALID_CONNECTION]: {
        id: TranslationKey.SCHEMA_INVALID_CONNECTION,
        defaultMessage: ru[TranslationKey.SCHEMA_INVALID_CONNECTION],
    },
    [TranslationKey.WORKSPACE_CHART_EDITOR_OPEN]: {
        id: TranslationKey.WORKSPACE_CHART_EDITOR_OPEN,
        defaultMessage: ru[TranslationKey.WORKSPACE_CHART_EDITOR_OPEN],
    },
    [TranslationKey.WORKSPACE_CHART_EDITOR]: {
        id: TranslationKey.WORKSPACE_CHART_EDITOR,
        defaultMessage: ru[TranslationKey.WORKSPACE_CHART_EDITOR],
    },
    [TranslationKey.WORKSPACE_CHART_EDITOR_ANNOTATIONS]: {
        id: TranslationKey.WORKSPACE_CHART_EDITOR_ANNOTATIONS,
        defaultMessage: ru[TranslationKey.WORKSPACE_CHART_EDITOR_ANNOTATIONS],
    },
    [TranslationKey.WORKSPACE_CHART_EDITOR_ANNOTATIONS_ADD]: {
        id: TranslationKey.WORKSPACE_CHART_EDITOR_ANNOTATIONS_ADD,
        defaultMessage: ru[TranslationKey.WORKSPACE_CHART_EDITOR_ANNOTATIONS_ADD],
    },
    [TranslationKey.WORKSPACE_CHART_EDITOR_ANNOTATIONS_EMPTY]: {
        id: TranslationKey.WORKSPACE_CHART_EDITOR_ANNOTATIONS_EMPTY,
        defaultMessage: ru[TranslationKey.WORKSPACE_CHART_EDITOR_ANNOTATIONS_EMPTY],
    },
    [TranslationKey.WORKSPACE_CHART_EDITOR_NOT_EDITABLE]: {
        id: TranslationKey.WORKSPACE_CHART_EDITOR_NOT_EDITABLE,
        defaultMessage: ru[TranslationKey.WORKSPACE_CHART_EDITOR_NOT_EDITABLE],
    },
    [TranslationKey.WORKSPACE_CHART_EDITOR_NO_ANNOTATIONS]: {
        id: TranslationKey.WORKSPACE_CHART_EDITOR_NO_ANNOTATIONS,
        defaultMessage: ru[TranslationKey.WORKSPACE_CHART_EDITOR_NO_ANNOTATIONS],
    },
});
