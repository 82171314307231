import { FC, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

import { TranslationKey } from '@repeat/translations';

import { SSidebarVersions, SVersionTitle } from './SComponents';
import { INavigationItem } from './VersionsPage';
import { messages } from './translation';

type PropsHelpPageMenu = {
    navigation: INavigationItem[];
};

export const VersionsPageMenu: FC<PropsHelpPageMenu> = ({ navigation }) => {
    const { hash } = useLocation();
    const intl = useIntl();

    useEffect(() => {
        if (hash) {
            const id = hash.slice(1);
            scrollById(id);
        }
    }, [hash]);

    const scrollById = (id: string) => {
        const block = document.getElementById(id);
        block && block.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const isActive = useCallback(
        (id: string, index: number) => {
            return (hash && hash === `#${id}`) || (!hash && index === 0);
        },
        [hash]
    );

    return (
        <SSidebarVersions>
            <SVersionTitle>{intl.formatMessage(messages[TranslationKey.HELP_SECTION_VERSIONS_TITLE])}</SVersionTitle>
            {navigation.map((item, index) => (
                <Link
                    to={`#${item.id}`}
                    key={item.id}
                    data-target={item.id}
                    className={isActive(item.id, index) ? 'active' : ''}
                >
                    {item.id}
                </Link>
            ))}
        </SSidebarVersions>
    );
};
