import { LibraryTypeAction, LibraryTypeTypes } from '../actions/libraryTypeTypes';

/* eslint-disable @typescript-eslint/no-explicit-any */

export const addLibraryType = (type: any): LibraryTypeAction => {
    return {
        type: LibraryTypeTypes.ADD_LIBRARY_TYPE,
        payload: type,
    };
};
