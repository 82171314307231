import { PayloadAction } from '@reduxjs/toolkit';

import { IWorkspaceState } from '@repeat/models';

export const chartsEditorReducer = {
    openChartsEditor: (state: IWorkspaceState, { payload: { uuid } }: PayloadAction<{ uuid: string }>) => {
        return {
            ...state,
            graphs: {
                ...state.graphs,
                editor: {
                    isOpen: true,
                    currentGraphUuid: uuid,
                },
            },
        };
    },
    changeEditorChart: (state: IWorkspaceState, { payload: { uuid } }: PayloadAction<{ uuid: string }>) => {
        return {
            ...state,
            graphs: {
                ...state.graphs,
                editor: {
                    isOpen: true,
                    currentGraphUuid: uuid,
                },
            },
        };
    },
    closeChartsEditor: (state: IWorkspaceState) => {
        return {
            ...state,
            graphs: {
                ...state.graphs,
                editor: {
                    isOpen: false,
                    currentGraphUuid: null,
                },
            },
        };
    },
};
