import React, { FC } from 'react';

import { ILibraryItem, TLibraryType, TSolverType } from '@repeat/models';
import { Tooltip } from '@repeat/ui-kit';

import { SElement, SElementText } from './StyledElements';

const MAX_NAME_LENGTH = 9;

interface IElementProps {
    options: ILibraryItem;
    solverType: TSolverType;
    libraryType: TLibraryType;
    imageSrc: string;
    newTitle: string;
    onDragStart: (e: React.DragEvent<HTMLDivElement>) => void;
}

export const Element: FC<IElementProps> = ({
    options,
    onDragStart,
    solverType,
    libraryType,
    imageSrc,
    newTitle,
    ...rest
}) => {
    const { isNew, name, shortName } = options;

    const hasTooltip = shortName.length >= MAX_NAME_LENGTH || isNew;
    const tooltipText = isNew ? `[${newTitle}] ${name}` : name;

    if (hasTooltip) {
        return (
            <SElement
                onDragStart={(e) => {
                    onDragStart(e);
                }}
                hasNewBadge={isNew}
            >
                <Tooltip text={tooltipText}>
                    <img alt={name} src={imageSrc} />
                </Tooltip>
                <SElementText>{shortName}</SElementText>
            </SElement>
        );
    }

    return (
        <SElement onDragStart={(e) => onDragStart(e)} hasNewBadge={isNew} {...rest}>
            <img alt={name} src={imageSrc} />
            <SElementText>{shortName || name}</SElementText>
        </SElement>
    );
};
