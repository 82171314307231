import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { ApplicationActions } from '@repeat/common-slices';
import { useAppDispatch, useAppSelector, useProjectId } from '@repeat/hooks';
import { ModalTypes, Statuses, TProjectBlockInitialization } from '@repeat/models';
import { addProjectBlock, getProjects, workspaceActions } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { Button, Loader, Modal } from '@repeat/ui-kit';

import { blockOptionsMessages as messages } from './translation';

import { ProjectBlockForm } from '../Project/ProjectBlockForm/ProjectBlockForm';

interface ISelectedProject {
    id: string;
    title: string;
}

export const ProjectBlockInitializationModal = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const modals = useAppSelector((state) => state.app.modals);
    const modal = modals.find(
        (modal) => modal.type === ModalTypes.PROJECT_BLOCK_INITIALIZATION
    ) as TProjectBlockInitialization;
    const node = modal.data.node;
    const { projectId } = useProjectId() || { projectId: null };

    const [selectedProject, setSelectedProject] = useState<ISelectedProject | null>(null);

    useEffect(() => {
        return () => {
            dispatch(workspaceActions.resetElementInitialization());
        };
    }, []);

    const handleClose = () => {
        dispatch(workspaceActions.deleteNode());
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.PROJECT_BLOCK_INITIALIZATION }));
    };
    const handleAdd = () => {
        if (!selectedProject) {
            return;
        }

        dispatch(
            addProjectBlock({
                node,
                elemProps: {
                    projectId: selectedProject.id,
                    projectName: selectedProject.title,
                },
            })
        );
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.PROJECT_BLOCK_INITIALIZATION }));
    };

    const [isValidForm, setIsValidForm] = useState(false);
    const projectsState = useAppSelector((state) => state.projects);
    const isProjectsInitialized = projectsState.status === Statuses.SUCCEEDED;
    useEffect(() => {
        if (isProjectsInitialized === false) {
            dispatch(getProjects());
        }
    }, [dispatch, isProjectsInitialized]);

    const handleFormValidate = (isValid: boolean) => {
        setIsValidForm(isValid);
    };
    const handleSelectProject = (selectedProject: ISelectedProject | null) => {
        setSelectedProject(selectedProject);
    };

    const FooterButtons = () => (
        <>
            <Button variant='secondary' onClick={handleClose}>
                {intl.formatMessage(messages[TranslationKey.CANCEL])}
            </Button>
            <Button onClick={handleAdd} disabled={!isValidForm}>
                {intl.formatMessage(messages[TranslationKey.ADD])}
            </Button>
        </>
    );

    return (
        <Modal
            id={ModalTypes.PROJECT_BLOCK_INITIALIZATION}
            title={intl.formatMessage(messages[TranslationKey.PROJECT_BLOCK_INITIALIZATION])}
            isOpen={true}
            hasOverlay={true}
            onClose={handleClose}
            type='default'
            size='medium'
            footer={<FooterButtons />}
        >
            {projectsState.status === Statuses.LOADING && <Loader />}
            {projectsState.status === Statuses.FAILED && (
                <span>{intl.formatMessage(messages[TranslationKey.WORKSPACE_ERROR_GETTING_PROJECTS_LIST])}</span>
            )}

            {isProjectsInitialized && (
                <ProjectBlockForm
                    onValid={handleFormValidate}
                    onSelectProject={handleSelectProject}
                    projectId={projectId}
                />
            )}
        </Modal>
    );
};
