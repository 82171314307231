export const openInNewWindowOptions = (width?: number, height?: number) => `
    width=${width || window.innerWidth / 2},
    height=${height || window.innerHeight / 2},
    scrollbars=no,
    resizable=no,
    status=no,
    location=no,
    toolbar=no,
    menubar=no
`;

export const broadCastService = (name: string) => {
    return new BroadcastChannel(name);
};
