import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const blockOptionsMessages = defineMessages({
    [TranslationKey.SAVE]: {
        id: TranslationKey.SAVE,
        defaultMessage: ru[TranslationKey.SAVE],
    },
    [TranslationKey.CANCEL]: {
        id: TranslationKey.CANCEL,
        defaultMessage: ru[TranslationKey.CANCEL],
    },
    [TranslationKey.SEND]: {
        id: TranslationKey.SEND,
        defaultMessage: ru[TranslationKey.SEND],
    },
    [TranslationKey.ELEMENT_CONFIGURATION]: {
        id: TranslationKey.ELEMENT_CONFIGURATION,
        defaultMessage: ru[TranslationKey.ELEMENT_CONFIGURATION],
    },
    [TranslationKey.ELEMENT_CONFIGURATION_CUSTOMIZABLE_SELECT]: {
        id: TranslationKey.ELEMENT_CONFIGURATION_CUSTOMIZABLE_SELECT,
        defaultMessage: ru[TranslationKey.ELEMENT_CONFIGURATION_CUSTOMIZABLE_SELECT],
    },
    [TranslationKey.ADD]: {
        id: TranslationKey.ADD,
        defaultMessage: ru[TranslationKey.ADD],
    },
    [TranslationKey.ELEMENT_INITIALIZATION]: {
        id: TranslationKey.ELEMENT_INITIALIZATION,
        defaultMessage: ru[TranslationKey.ELEMENT_INITIALIZATION],
    },
    [TranslationKey.WHATS_NEW]: {
        id: TranslationKey.WHATS_NEW,
        defaultMessage: ru[TranslationKey.WHATS_NEW],
    },
    [TranslationKey.UNDERSTAND_THANKS]: {
        id: TranslationKey.UNDERSTAND_THANKS,
        defaultMessage: ru[TranslationKey.UNDERSTAND_THANKS],
    },
    [TranslationKey.MODAL_HELP_SECTION_MORE_DETAILED_INFORMATION]: {
        id: TranslationKey.MODAL_HELP_SECTION_MORE_DETAILED_INFORMATION,
        defaultMessage: ru[TranslationKey.MODAL_HELP_SECTION_MORE_DETAILED_INFORMATION],
    },
    [TranslationKey.LINK]: {
        id: TranslationKey.LINK,
        defaultMessage: ru[TranslationKey.LINK],
    },
    [TranslationKey.CONTACTING_TECHNICAL_SUPPORT]: {
        id: TranslationKey.CONTACTING_TECHNICAL_SUPPORT,
        defaultMessage: ru[TranslationKey.CONTACTING_TECHNICAL_SUPPORT],
    },
    [TranslationKey.CALLING_TECHNICAL_SUPPORT]: {
        id: TranslationKey.CALLING_TECHNICAL_SUPPORT,
        defaultMessage: ru[TranslationKey.CALLING_TECHNICAL_SUPPORT],
    },
    [TranslationKey.MODAL_RELEASE_INFO_DONT_SHOW_UPDATES]: {
        id: TranslationKey.MODAL_RELEASE_INFO_DONT_SHOW_UPDATES,
        defaultMessage: ru[TranslationKey.MODAL_RELEASE_INFO_DONT_SHOW_UPDATES],
    },
    [TranslationKey.OK_THANKS]: {
        id: TranslationKey.OK_THANKS,
        defaultMessage: ru[TranslationKey.OK_THANKS],
    },
    [TranslationKey.ERROR]: {
        id: TranslationKey.ERROR,
        defaultMessage: ru[TranslationKey.ERROR],
    },
    [TranslationKey.PROJECT_BLOCK_INITIALIZATION]: {
        id: TranslationKey.PROJECT_BLOCK_INITIALIZATION,
        defaultMessage: ru[TranslationKey.PROJECT_BLOCK_INITIALIZATION],
    },
    [TranslationKey.WORKSPACE_ERROR_GETTING_PROJECTS_LIST]: {
        id: TranslationKey.WORKSPACE_ERROR_GETTING_PROJECTS_LIST,
        defaultMessage: ru[TranslationKey.WORKSPACE_ERROR_GETTING_PROJECTS_LIST],
    },
    [TranslationKey.ELEMENT_INITIALIZATION_DROPZONE]: {
        id: TranslationKey.ELEMENT_INITIALIZATION_DROPZONE,
        defaultMessage: ru[TranslationKey.ELEMENT_INITIALIZATION_DROPZONE],
    },
    [TranslationKey.ELEMENT_INITIALIZATION_SELECT]: {
        id: TranslationKey.ELEMENT_INITIALIZATION_SELECT,
        defaultMessage: ru[TranslationKey.ELEMENT_INITIALIZATION_SELECT],
    },
    [TranslationKey.ELEMENT_INITIALIZATION_NOTICE]: {
        id: TranslationKey.ELEMENT_INITIALIZATION_NOTICE,
        defaultMessage: ru[TranslationKey.ELEMENT_INITIALIZATION_NOTICE],
    },
    [TranslationKey.ELEMENT_INITIALIZATION_ATTENTION_MESSAGE]: {
        id: TranslationKey.ELEMENT_INITIALIZATION_ATTENTION_MESSAGE,
        defaultMessage: ru[TranslationKey.ELEMENT_INITIALIZATION_ATTENTION_MESSAGE],
    },
});

export const sessionModalMessages = defineMessages({
    [TranslationKey.SESSION_CONNECTION_LOST_TITLE]: {
        id: TranslationKey.SESSION_CONNECTION_LOST_TITLE,
        defaultMessage: ru[TranslationKey.SESSION_CONNECTION_LOST_TITLE],
    },
    [TranslationKey.SESSION_CONNECTION_LOST_DESCRIPTION]: {
        id: TranslationKey.SESSION_CONNECTION_LOST_DESCRIPTION,
        defaultMessage: ru[TranslationKey.SESSION_CONNECTION_LOST_DESCRIPTION],
    },
    [TranslationKey.SESSION_CONNECTION_LOST_BUTTON]: {
        id: TranslationKey.SESSION_CONNECTION_LOST_BUTTON,
        defaultMessage: ru[TranslationKey.SESSION_CONNECTION_LOST_BUTTON],
    },
    [TranslationKey.CLOSE_PROJECT]: {
        id: TranslationKey.CLOSE_PROJECT,
        defaultMessage: ru[TranslationKey.CLOSE_PROJECT],
    },
});

export const openBusyProjectModalMessages = defineMessages({
    [TranslationKey.CLOSE]: {
        id: TranslationKey.CLOSE,
        defaultMessage: ru[TranslationKey.CLOSE],
    },
    [TranslationKey.PROJECT_OPEN_ERROR]: {
        id: TranslationKey.PROJECT_OPEN_ERROR,
        defaultMessage: ru[TranslationKey.PROJECT_OPEN_ERROR],
    },
    [TranslationKey.PROJECT_BUSY_MODULES]: {
        id: TranslationKey.PROJECT_BUSY_MODULES,
        defaultMessage: ru[TranslationKey.PROJECT_BUSY_MODULES],
    },
});

export const projectDeleteModalMessages = defineMessages({
    [TranslationKey.PROJECT_DELETE_TITLE]: {
        id: TranslationKey.PROJECT_DELETE_TITLE,
        defaultMessage: ru[TranslationKey.PROJECT_DELETE_TITLE],
    },
    [TranslationKey.PROJECT_DELETE_CONFIRMATION]: {
        id: TranslationKey.PROJECT_DELETE_CONFIRMATION,
        defaultMessage: ru[TranslationKey.PROJECT_DELETE_CONFIRMATION],
    },
    [TranslationKey.PROJECTS_DELETE_CONFIRMATION]: {
        id: TranslationKey.PROJECTS_DELETE_CONFIRMATION,
        defaultMessage: ru[TranslationKey.PROJECTS_DELETE_CONFIRMATION],
    },
    [TranslationKey.PROJECT_DELETE_NOT_SELECTED]: {
        id: TranslationKey.PROJECT_DELETE_NOT_SELECTED,
        defaultMessage: ru[TranslationKey.PROJECT_DELETE_NOT_SELECTED],
    },
    [TranslationKey.DELETE]: {
        id: TranslationKey.DELETE,
        defaultMessage: ru[TranslationKey.DELETE],
    },
    [TranslationKey.CANCEL]: {
        id: TranslationKey.CANCEL,
        defaultMessage: ru[TranslationKey.CANCEL],
    },
});
