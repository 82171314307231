import { FC, useState } from 'react';

import styled from 'styled-components';

import { TagCloudItem } from './TagCloudItem';

import { pxToRem } from '../../config/config';

interface ITagCloud {
    items: string[];
    watch: (list: string[]) => void;
}

export const STagCloud = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    margin-bottom: ${pxToRem(16)};
    padding: 0 ${pxToRem(16)};
`;

export const TagCloud: FC<ITagCloud> = ({ items, watch, ...rest }) => {
    const [tagList, setTagList] = useState(() => {
        const objects = items.map((item, index) => ({ text: item, active: false, id: index }));
        return [...objects];
    });

    const handleSelectItem = (id: number) => {
        const items = tagList.map((tag) => {
            return {
                ...tag,
                active: id === tag.id ? !tag.active : tag.active,
            };
        });
        setTagList(items);

        const activeItems = items.filter((item) => item.active).map((item) => item.text);
        watch(activeItems);
    };

    if (tagList.length > 0) {
        return (
            <STagCloud {...rest}>
                {tagList.map((item, index) => {
                    return (
                        <TagCloudItem
                            {...item}
                            key={`tag-cloud-item-${index}`}
                            handleSelectItem={(id: number) => handleSelectItem(id)}
                            showCloseIcon={false}
                        />
                    );
                })}
            </STagCloud>
        );
    }

    return null;
};
