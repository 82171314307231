import { AVAILABLE_LOCALES, DEFAULT_LOCALE } from '../app';
import { getLocale } from '../local-storage';

export const detectBrowserLocale = (): string => {
    return navigator.language.split('-')[0];
};

export const getAppLocale = (): string => {
    const locale = getLocale();
    const browserLocale = detectBrowserLocale();

    const selectedLocale = locale || browserLocale;
    if (AVAILABLE_LOCALES.includes(selectedLocale)) {
        return selectedLocale;
    }

    return DEFAULT_LOCALE;
};
