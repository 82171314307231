// TODO: remove any types

export function compareObjects(obj1: any, obj2: any): string[] {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    const differentKeys: string[] = [];

    for (const key of keys1) {
        if (obj1[key] !== obj2[key]) {
            differentKeys.push(key);
        }
    }

    for (const key of keys2) {
        if (obj1[key] !== obj2[key] && !differentKeys.includes(key)) {
            differentKeys.push(key);
        }
    }

    return differentKeys;
}
