import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.ELEMENT_INITIALIZATION_NOTICE]: {
        id: TranslationKey.ELEMENT_INITIALIZATION_NOTICE,
        defaultMessage: ru[TranslationKey.ELEMENT_INITIALIZATION_NOTICE],
    },
    [TranslationKey.ELEMENT_INITIALIZATION_ATTENTION_MESSAGE]: {
        id: TranslationKey.ELEMENT_INITIALIZATION_ATTENTION_MESSAGE,
        defaultMessage: ru[TranslationKey.ELEMENT_INITIALIZATION_ATTENTION_MESSAGE],
    },
    [TranslationKey.ELEMENT_INITIALIZATION_DROPZONE]: {
        id: TranslationKey.ELEMENT_INITIALIZATION_DROPZONE,
        defaultMessage: ru[TranslationKey.ELEMENT_INITIALIZATION_DROPZONE],
    },
    [TranslationKey.ELEMENT_INITIALIZATION_SELECT]: {
        id: TranslationKey.ELEMENT_INITIALIZATION_SELECT,
        defaultMessage: ru[TranslationKey.ELEMENT_INITIALIZATION_SELECT],
    },
    [TranslationKey.ERROR_LARGE_FILE]: {
        id: TranslationKey.ERROR_LARGE_FILE,
        defaultMessage: ru[TranslationKey.ERROR_LARGE_FILE],
    },
    [TranslationKey.BYTES]: {
        id: TranslationKey.BYTES,
        defaultMessage: ru[TranslationKey.BYTES],
    },
    [TranslationKey.ELEMENT_INITIALIZATION_STATUS_UPLOAD_LABEL]: {
        id: TranslationKey.ELEMENT_INITIALIZATION_STATUS_UPLOAD_LABEL,
        defaultMessage: ru[TranslationKey.ELEMENT_INITIALIZATION_STATUS_UPLOAD_LABEL],
    },
    [TranslationKey.ELEMENT_INITIALIZATION_STATUS_INITIALIZE_LABEL]: {
        id: TranslationKey.ELEMENT_INITIALIZATION_STATUS_INITIALIZE_LABEL,
        defaultMessage: ru[TranslationKey.ELEMENT_INITIALIZATION_STATUS_INITIALIZE_LABEL],
    },
    [TranslationKey.ELEMENT_INITIALIZATION_STATUS_SUCCESS]: {
        id: TranslationKey.ELEMENT_INITIALIZATION_STATUS_SUCCESS,
        defaultMessage: ru[TranslationKey.ELEMENT_INITIALIZATION_STATUS_SUCCESS],
    },
    [TranslationKey.ELEMENT_INITIALIZATION_STATUS_FAILED]: {
        id: TranslationKey.ELEMENT_INITIALIZATION_STATUS_FAILED,
        defaultMessage: ru[TranslationKey.ELEMENT_INITIALIZATION_STATUS_FAILED],
    },
    [TranslationKey.ELEMENT_INITIALIZATION_STATUS_LOADING]: {
        id: TranslationKey.ELEMENT_INITIALIZATION_STATUS_LOADING,
        defaultMessage: ru[TranslationKey.ELEMENT_INITIALIZATION_STATUS_LOADING],
    },
    [TranslationKey.ELEMENT_INITIALIZATION_STATUS_UPLOAD_WAITING]: {
        id: TranslationKey.ELEMENT_INITIALIZATION_STATUS_UPLOAD_WAITING,
        defaultMessage: ru[TranslationKey.ELEMENT_INITIALIZATION_STATUS_UPLOAD_WAITING],
    },
    [TranslationKey.ELEMENT_INITIALIZATION_RUN_AGAIN]: {
        id: TranslationKey.ELEMENT_INITIALIZATION_RUN_AGAIN,
        defaultMessage: ru[TranslationKey.ELEMENT_INITIALIZATION_RUN_AGAIN],
    },
});
