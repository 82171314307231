import localforage from 'localforage';

export class UndoRedoDBStorage {
    private readonly storageKey: string;

    constructor(storageKey: string) {
        this.storageKey = storageKey;
    }

    public async getStorageItem(key: string): Promise<any> {
        try {
            const storageData = await localforage.getItem(key);
            return storageData ? storageData : undefined;
        } catch (error) {
            throw new Error('Failed to retrieve storage item.');
        }
    }

    public async setStorageItem(data: Record<string, unknown>): Promise<void> {
        try {
            const storageData = await localforage.getItem(this.storageKey);
            const parsedData = storageData ? storageData : undefined;
            const updatedData = { ...parsedData, ...data };
            await localforage.setItem(this.storageKey, updatedData);
        } catch (error) {
            throw new Error('Failed to set storage item.');
        }
    }

    public async clearStorageItems(): Promise<void> {
        try {
            await localforage.clear();
        } catch (error) {
            throw new Error('Failed to clear storage items.');
        }
    }
}
