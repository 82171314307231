import { Action } from 'redux';

/* eslint-disable @typescript-eslint/no-explicit-any */

export enum TracingTypes {
    ADD_FIRST_POINT = 'ADD_FIRST_POINT',
    ADD_SECOND_POINT = 'ADD_SECOND_POINT',
    DELETE_CRASH_POINT = 'DELETE_CRASH_POINT',
    ADD_TRACING_CUR = 'ADD_TRACING_CUR',
    DELETE_LINE = 'DELETE_LINE',
    CHANGE_WIDTH = 'CHANGE_WIDTH',
    DELETE_LINE_FROM_ELEMENT = 'DELETE_LINE_FROM_ELEMENT',
    CLEAR_STATE_LINES = 'CLEAR_STATE_LINES',
    LOAD_LINES = 'LOAD_LINES',
    OPTIONS_LINE = 'OPTIONS_LINE',
    PARAMS_LINE = 'PARAMS_LINE',
    PARAMS_VALUES_LINE = 'PARAMS_VALUES_LINE',
    SHOW_PARENT_WIRE = 'SHOW_PARENT_WIRE',
}

export interface TracingAction extends Action<TracingTypes> {
    type: TracingTypes;
    // TODO: check the reducer !!!
    color?: any;
    payload?: any;
}
