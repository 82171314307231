import { ModeBarDefaultButtons } from 'plotly.js';

export interface ICustomIcon {
    name: ModeBarDefaultButtons;
    width: number;
    height: number;
    path: string;
}

export const customIcons: ICustomIcon[] = [
    {
        name: 'resetViews',
        width: 24,
        height: 24,
        path: 'M240-200h120v-240h240v240h120v-360L480-740 240-560v360Zm-80 80v-480l320-240 320 240v480H520v-240h-80v240H160Zm320-350Z',
    },
    {
        name: 'autoScale2d',
        width: 24,
        height: 24,
        path: 'M800-600v-120H680v-80h120q33 0 56.5 23.5T880-720v120h-80Zm-720 0v-120q0-33 23.5-56.5T160-800h120v80H160v120H80Zm600 440v-80h120v-120h80v120q0 33-23.5 56.5T800-160H680Zm-520 0q-33 0-56.5-23.5T80-240v-120h80v120h120v80H160Zm80-160v-320h480v320H240Zm80-80h320v-160H320v160Zm0 0v-160 160Z',
    },
    {
        name: 'zoomIn2d',
        width: 24,
        height: 24,
        path: 'M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z',
    },
    {
        name: 'zoomOut2d',
        width: 24,
        height: 24,
        path: 'M200-440v-80h560v80H200Z',
    },
    {
        name: 'pan2d',
        width: 24,
        height: 24,
        path: 'M480-80 310-250l57-57 73 73v-206H235l73 72-58 58L80-480l169-169 57 57-72 72h206v-206l-73 73-57-57 170-170 170 170-57 57-73-73v206h205l-73-72 58-58 170 170-170 170-57-57 73-73H520v205l72-73 58 58L480-80Z',
    },
    {
        name: 'zoom2d',
        width: 24,
        height: 24,
        path: 'M200-120q-33 0-56.5-23.5T120-200v-160h80v160h160v80H200Zm400 0v-80h160v-160h80v160q0 33-23.5 56.5T760-120H600ZM120-600v-160q0-33 23.5-56.5T200-840h160v80H200v160h-80Zm640 0v-160H600v-80h160q33 0 56.5 23.5T840-760v160h-80Z',
    },
    {
        name: 'toImage',
        width: 24,
        height: 24,
        path: 'M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z',
    },
];
