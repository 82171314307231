import { css } from 'styled-components';

import { pxToRem, uiColors, uiColorsDark } from '../config/config';
import { hexToRGB } from '../config/helpers';

// Do not use config externally
// Use colors with css variables
export const THEME_COLOR_CONFIG = {
    primary: {
        dark: uiColorsDark.mainBlue,
        light: uiColors.mainBlue,
    },
    secondary: {
        dark: uiColorsDark.darkBlue,
        light: uiColors.darkBlue,
    },
    background: {
        dark: '#303030',
        light: uiColors.lightGrey,
    },
    sidebar: {
        dark: uiColorsDark.white,
        light: uiColors.white,
    },
    heading: {
        dark: uiColors.lightGrey,
        light: uiColorsDark.white,
    },
    linkDefault: {
        dark: '#fafafa',
        light: uiColors.mainBlue,
    },
    linkActive: {
        dark: '#a9a4a4',
        light: uiColors.darkBlue,
    },
    text: {
        dark: '#bdbdbd',
        light: uiColors.dark,
    },
    label: {
        dark: '#999',
        light: uiColors.darkGrey,
    },
    input: {
        dark: '#414141',
        light: uiColors.smokeWhite,
    },
    border: {
        dark: '#868686',
        light: uiColors.grey,
    },
    borderHover: {
        dark: '#999',
        light: uiColors.borderGrey,
    },
    icons: {
        dark: '#BFBFBF',
        light: uiColors.darkGrey,
    },
    iconsHover: {
        dark: '#eee',
        light: uiColors.dark,
    },
    canvas: {
        dark: '#444',
        light: uiColors.lightGrey,
    },
    tags: {
        dark: '#303030',
        light: uiColors.grey,
    },
    tagsActive: {
        dark: uiColors.mainBlue,
        light: uiColors.mainBlue,
    },
    shadow: {
        dark: 'rgba(255,255,255, 8%)',
        light: 'rgba(0,0,0, 8%)',
    },
    table: {
        dark: hexToRGB(uiColors.darkBlue, '0.3'),
        light: hexToRGB(uiColors.blueLight, '0.3'),
    },
    tableText: {
        dark: '#bdbdbd',
        light: uiColors.dark,
    },
    tableLink: {
        dark: '#63a6e9',
        light: uiColors.mainBlue,
    },
    linkSecondary: {
        dark: '#2f7bc5',
        light: uiColors.darkBlue,
    },
    tableHover: {
        dark: hexToRGB(uiColors.darkGrey, '0.1'),
        light: hexToRGB(uiColors.mainBlue, '0.1'),
    },
    notificaton: {
        dark: uiColorsDark.white,
        light: uiColors.white,
    },
    alert: {
        dark: uiColors.red,
        light: uiColors.red,
    },
    managedListItem: {
        dark: '#303030',
        light: uiColors.smokeWhite,
    },
    managedListItemActive: {
        dark: uiColors.mainBlue,
        light: uiColors.lightGrey,
    },
};

export const SThemeConfig = css`
    :root {
        color-scheme: light;
        --ui-main-blue: ${uiColors.mainBlue};
        --ui-dark-blue: ${uiColors.darkBlue};
        --ui-icon-button: ${uiColors.white};

        --ui-primary: ${THEME_COLOR_CONFIG.primary.light};
        --ui-secondary: ${THEME_COLOR_CONFIG.secondary.light};
        --ui-sidebar: ${THEME_COLOR_CONFIG.sidebar.light};
        --ui-background: ${THEME_COLOR_CONFIG.background.light};
        --ui-canvas: ${THEME_COLOR_CONFIG.canvas.light};
        --ui-heading: ${THEME_COLOR_CONFIG.heading.light};
        --ui-link-default: ${THEME_COLOR_CONFIG.linkDefault.light};
        --ui-link-active: ${THEME_COLOR_CONFIG.linkActive.light};
        --ui-link-secondary: ${THEME_COLOR_CONFIG.tableLink.light};
        --ui-link-secondary-hover: ${THEME_COLOR_CONFIG.linkSecondary.light};
        --ui-text: ${THEME_COLOR_CONFIG.text.light};
        --ui-label: ${THEME_COLOR_CONFIG.label.light};
        --ui-input: ${THEME_COLOR_CONFIG.input.light};
        --ui-border: ${THEME_COLOR_CONFIG.border.light};
        --ui-border-hover: ${THEME_COLOR_CONFIG.borderHover.light};
        --ui-icons: ${THEME_COLOR_CONFIG.icons.light};
        --ui-icons-hover: ${THEME_COLOR_CONFIG.iconsHover.light};
        --ui-tags: ${THEME_COLOR_CONFIG.tags.light};
        --ui-tags-active: ${THEME_COLOR_CONFIG.tagsActive.light};
        --ui-shadow: ${THEME_COLOR_CONFIG.shadow.light};
        --ui-table: ${THEME_COLOR_CONFIG.table.light};
        --ui-table-text: ${THEME_COLOR_CONFIG.tableText.light};
        --ui-table-hover: ${THEME_COLOR_CONFIG.tableHover.light};
        --ui-notification: ${THEME_COLOR_CONFIG.notificaton.light};
        --ui-alert: ${THEME_COLOR_CONFIG.alert.light};
        --ui-managed-list-item: ${THEME_COLOR_CONFIG.managedListItem.light};
        --ui-managed-list-item-active: ${THEME_COLOR_CONFIG.managedListItemActive.light};

        &[data-theme='dark'] {
            color-scheme: dark;
            --ui-primary: ${THEME_COLOR_CONFIG.primary.dark};
            --ui-secondary: ${THEME_COLOR_CONFIG.secondary.dark};
            --ui-sidebar: ${THEME_COLOR_CONFIG.sidebar.dark};
            --ui-background: ${THEME_COLOR_CONFIG.background.dark};
            --ui-canvas: ${THEME_COLOR_CONFIG.canvas.dark};
            --ui-heading: ${THEME_COLOR_CONFIG.heading.dark};
            --ui-link-default: ${THEME_COLOR_CONFIG.linkDefault.dark};
            --ui-link-active: ${THEME_COLOR_CONFIG.linkActive.dark};
            --ui-link-secondary: ${THEME_COLOR_CONFIG.tableLink.dark};
            --ui-link-secondary-hover: ${THEME_COLOR_CONFIG.linkSecondary.dark};
            --ui-text: ${THEME_COLOR_CONFIG.text.dark};
            --ui-label: ${THEME_COLOR_CONFIG.label.dark};
            --ui-input: ${THEME_COLOR_CONFIG.input.dark};
            --ui-border: ${THEME_COLOR_CONFIG.border.dark};
            --ui-border-hover: ${THEME_COLOR_CONFIG.borderHover.dark};
            --ui-icons: ${THEME_COLOR_CONFIG.icons.dark};
            --ui-icons-hover: ${THEME_COLOR_CONFIG.iconsHover.dark};
            --ui-tags: ${THEME_COLOR_CONFIG.tags.dark};
            --ui-tags-active: ${THEME_COLOR_CONFIG.tagsActive.dark};
            --ui-shadow: ${THEME_COLOR_CONFIG.shadow.dark};
            --ui-table: ${THEME_COLOR_CONFIG.table.dark};
            --ui-table-text: ${THEME_COLOR_CONFIG.tableText.dark};
            --ui-table-hover: ${THEME_COLOR_CONFIG.tableHover.dark};
            --ui-notification: ${THEME_COLOR_CONFIG.notificaton.dark};
            --ui-alert: ${THEME_COLOR_CONFIG.alert.dark};
            --ui-managed-list-item: ${THEME_COLOR_CONFIG.managedListItem.dark};
            --ui-managed-list-item-active: ${THEME_COLOR_CONFIG.managedListItemActive.dark};

            .react-flow__minimap {
                background-color: #555;
            }

            .react-flow__minimap-mask {
                fill: rgba(0, 0, 0, 0.6);
            }
        }
    }

    p,
    ul li,
    ol li {
        color: var(--ui-text);
    }

    * {
        [class*='Mui'] {
            font-family: Rosatom, sans-serif !important;
        }

        .MuiChip-root {
            border-radius: 25px !important;

            span {
                color: var(--ui-text) !important;
            }
        }

        .MuiAutocomplete-paper,
        .MuiAutocomplete-popper,
        .MuiPopover-paper {
            background-color: var(--ui-background) !important;
            color: var(--ui-text) !important;
        }

        .MuiFormLabel-root,
        .MuiInputBase-root {
            .MuiSvgIcon-root + .MuiOutlinedInput-notchedOutline,
            fieldset {
                border-color: var(--ui-border) !important;
            }

            svg {
                fill: var(--ui-border) !important;
            }

            * {
                color: var(--ui-text) !important;
                -webkit-text-fill-color: var(--ui-text) !important;
                outline: none !important;
            }
        }

        .MuiFormLabel-root {
            color: var(--ui-text) !important;
            line-height: ${pxToRem(20)} !important;
        }
    }
`;
