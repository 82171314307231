import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';

import JSZip from 'jszip';

import { ApplicationActions } from '@repeat/common-slices';
import { environment } from '@repeat/environment';
import { useAppDispatch } from '@repeat/hooks';
import { ILibraryItem, ModalTypes, TModal } from '@repeat/models';
import { TranslationKey } from '@repeat/translations';
import { Icon, IconButton, SSidebarList } from '@repeat/ui-kit';

import { EngineerService } from './EngineerService';
import { messages } from './translations';

import { SSeparationLine } from '../Project/styled/SSeparationLine';

const { production } = environment;
const isDevEnvironment = !production;

export const EngineerToolbar: FC = () => {
    const dispatch = useAppDispatch();

    const engineerService = new EngineerService('engineerBlocks');
    const engineerServiceEN = new EngineerService('engineerBlocksEN');

    const intl = useIntl();
    const [data, setData] = useState<ILibraryItem[]>([]);
    const [enData, setEnData] = useState<ILibraryItem[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const allData = await engineerService.getBlocks();
            setData(allData);
        };

        fetchData().catch((err) => console.error('Failed to get blocks: ', err));
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const allData = await engineerServiceEN.getBlocks();
            setEnData(allData);
        };

        fetchData().catch((err) => console.error('Failed to get blocks: ', err));
    }, []);

    const handleAddBlockClick = () => {
        const modal: TModal = {
            type: ModalTypes.LIBRARYITEM_IMPORT,
        };
        dispatch(ApplicationActions.showModal({ modal }));
    };

    const downloadJSONFile = ({
        data,
        enData,
        fileName,
    }: {
        data: ILibraryItem[];
        enData: ILibraryItem[];
        fileName: string;
    }) => {
        const zip = new JSZip();
        data.map((block) => Reflect.deleteProperty(block, 'createdAt'));
        data.map((block) => Reflect.deleteProperty(block, 'updatedAt'));

        zip.file('data.json', JSON.stringify(data));
        zip.file('enData.json', JSON.stringify(enData));

        zip.generateAsync({ type: 'blob' }).then((content) => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(content);
            link.download = fileName;
            link.click();
            link.remove();
        });
    };

    const exportJSONFile = (e: React.MouseEvent) => {
        e.preventDefault();

        downloadJSONFile({
            data: data,
            enData: enData,
            fileName: `exportedBlocks.zip`,
        });
    };

    return (
        <SSidebarList>
            <NavLink to='/projects' key='project-item'>
                <Icon name='projectList' fill='mainBlue' />{' '}
                {intl.formatMessage(messages[TranslationKey.PAGE_PROJECTS_TITLE])}
            </NavLink>
            <SSeparationLine />
            <IconButton
                noHover
                name={'add'}
                text={intl.formatMessage(messages[TranslationKey.BLOCK_NEW])}
                onClick={handleAddBlockClick}
            />
            <IconButton noHover name={'export'} text='Экспортировать все' onClick={exportJSONFile} />
        </SSidebarList>
    );
};
