import { createGlobalStyle } from 'styled-components';

import { Mixins, pxToRem, uiColors, SThemeConfig } from '@repeat/ui-kit';

import { Reset } from './reset';
import { Typography } from './typography';

export const GlobalStyles = createGlobalStyle`
    ${Reset};

    ${Typography};

    ${SThemeConfig};

    * {
        box-sizing: border-box;
    }

    body {
        font-size: 16px;
        font-family: Rosatom, sans-serif;
        line-height: ${pxToRem(16)};
        margin: 0;
        padding: 0;

        * {
            font-family: Rosatom, sans-serif;
            scrollbar-width: thin;
        }
    }

    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    textarea {
        font-family: inherit;
         cursor: auto;
    }

    button {
        text-transform: none !important;
    }

    a {
        ${Mixins.transitionAnimation(['color'])}
         color: ${uiColors.mainBlue};

        &:hover,
        &.active {
            color: ${uiColors.darkBlue};
        }
    }

`;
