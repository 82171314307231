import { FC, memo, useEffect } from 'react';
import Plot, { Figure } from 'react-plotly.js';

import { Config } from 'plotly.js';

import { customIcons } from './Icons/CustomIcons';

interface IChartPlotProps {
    figure: Figure;
    config: Partial<Config>;
    onUpdate: (figure: Readonly<Figure>) => void;
}

export const PlotlyChart: FC<IChartPlotProps> = memo(({ figure: { layout, data }, config, onUpdate }) => {
    useEffect(() => {
        setTimeout(() => {
            // у Plotly нет возможности кастомизировать иконки встроенных функций, можно только добавлять свои кастомные функции и иконки соответственно
            // https://plotly.com/javascript/configuration-options/#add-buttons-to-modebar
            const modeBar = document.querySelector<HTMLInputElement>('.modebar');
            const modeButtons = document.querySelectorAll<HTMLInputElement>('.modebar-btn');

            const width = '20px';
            const height = width;

            modeButtons.forEach((button: Element, index: number) => {
                const svg = button.getElementsByTagName('svg')[0] || null;
                const svgPath = button.getElementsByTagName('path')[0] || null;
                if (!svg && !svgPath) {
                    return;
                }

                if (!customIcons[index]) {
                    return;
                }

                const icon = customIcons[index];

                svg.setAttribute('viewBox', '0 -960 960 960');
                svg.setAttribute('width', width);
                svg.setAttribute('height', height);
                svgPath.setAttribute('d', icon.path);
                svgPath.setAttribute('transform', '');
            });

            if (modeBar) {
                modeBar.style.visibility = 'visible';
            }
        }, 100);
    }, []);

    return (
        <Plot
            data={data}
            layout={layout}
            config={config}
            onUpdate={onUpdate}
            useResizeHandler
            style={{ width: '100%', height: '100%' }}
        />
    );
});
