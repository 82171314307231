import React, { FC, useRef } from 'react';

import { SGridView, SGridViewList } from './SGridView';

interface IGridView {
    children: React.ReactNode | React.ReactNode[];
}

export const GridView: FC<IGridView> = ({ children }) => {
    const ref = useRef(null);

    return (
        <SGridView ref={ref} className={'grid-view'}>
            <SGridViewList>{children}</SGridViewList>
        </SGridView>
    );
};
