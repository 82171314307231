import { Charts } from './Charts/Charts';
import { ChartsEditor } from './ChartsEditor/ChartsEditor';

export const Visualization = () => {
    return (
        <>
            <Charts />
            <ChartsEditor />
        </>
    );
};
