import { ListenerMiddlewareInstance } from '@reduxjs/toolkit';

import { ElementNotificationTypes, TState } from '@repeat/models';
import { findGoToBlockIdsWithoutPair, findNotUniqueGoToBlockIds } from '@repeat/services';
import { workspaceActions } from '@repeat/store';

export const appendGoToMapListeners = (listenerMiddleware: ListenerMiddlewareInstance) => {
    listenerMiddleware.startListening({
        actionCreator: workspaceActions.refreshGoToMapAfterAdding,
        effect: (action, listenerApi) => {
            const {
                workspace: {
                    schema: { goToMap },
                },
            } = listenerApi.getState() as TState;

            const notPairedBlockIds = findGoToBlockIdsWithoutPair(goToMap);

            listenerApi.dispatch(
                workspaceActions.notifyGoToBlocks({
                    blockIds: notPairedBlockIds,
                    type: ElementNotificationTypes.GOTO_WITHOUT_PAIR,
                })
            );
        },
    });

    listenerMiddleware.startListening({
        actionCreator: workspaceActions.refreshGoToMapAfterDeleting,
        effect: (action, listenerApi) => {
            const {
                workspace: {
                    schema: { goToMap },
                },
            } = listenerApi.getState() as TState;

            const notPairedBlockIds = findGoToBlockIdsWithoutPair(goToMap);

            listenerApi.dispatch(
                workspaceActions.notifyGoToBlocks({
                    blockIds: notPairedBlockIds,
                    type: ElementNotificationTypes.GOTO_WITHOUT_PAIR,
                })
            );
        },
    });

    listenerMiddleware.startListening({
        actionCreator: workspaceActions.renameGoToBlockTitle,
        effect: (action, listenerApi) => {
            const {
                workspace: {
                    schema: { goToMap },
                },
            } = listenerApi.getState() as TState;
            const { blockId, tagId } = action.payload;

            // mark goto blocks without pairs
            const notPairedBlockIds = findGoToBlockIdsWithoutPair(goToMap);
            listenerApi.dispatch(
                workspaceActions.notifyGoToBlocks({
                    blockIds: notPairedBlockIds,
                    type: ElementNotificationTypes.GOTO_WITHOUT_PAIR,
                })
            );

            // mark related goto blocks
            if (notPairedBlockIds.includes(blockId)) {
                listenerApi.dispatch(
                    workspaceActions.notifyGoToBlocks({
                        blockIds: [],
                        type: ElementNotificationTypes.GOTO_RELATED_BLOCK,
                    })
                );
            } else {
                if (goToMap && goToMap[tagId]) {
                    const item = goToMap[tagId];
                    if (item.goToId !== null && item.goFromIds.length > 0) {
                        const blockIds = [item.goToId, ...item.goFromIds];

                        listenerApi.dispatch(
                            workspaceActions.notifyGoToBlocks({
                                blockIds,
                                type: ElementNotificationTypes.GOTO_RELATED_BLOCK,
                            })
                        );
                    }
                }
            }

            // mark not unique goto blocks
            const notUniqueGoToBlockIds = findNotUniqueGoToBlockIds(goToMap);
            listenerApi.dispatch(
                workspaceActions.notifyGoToBlocks({
                    blockIds: notUniqueGoToBlockIds,
                    type: ElementNotificationTypes.GOTO_NOT_UNIQUE,
                })
            );
        },
    });

    listenerMiddleware.startListening({
        actionCreator: workspaceActions.refreshGoToMapAfterPasteItems,
        effect: (action, listenerApi) => {
            const {
                workspace: {
                    schema: { goToMap },
                },
            } = listenerApi.getState() as TState;

            const notUniqueGoToBlockIds = findNotUniqueGoToBlockIds(goToMap);

            listenerApi.dispatch(
                workspaceActions.notifyGoToBlocks({
                    blockIds: notUniqueGoToBlockIds,
                    type: ElementNotificationTypes.GOTO_NOT_UNIQUE,
                })
            );
        },
    });
};
