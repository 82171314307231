import { LibraryTypes, TLibraryType, TSchemaConnection } from '@repeat/models';

import { getHandleName } from '../helpers';

export const markConnectionsPortsAsUnconnected = (wiresToDelete: TSchemaConnection[]) => {
    const portsNamesByNodeIdMap: { [key: string]: string[] } = {};

    wiresToDelete.forEach((wire) => {
        if (portsNamesByNodeIdMap[wire.source] === undefined) {
            portsNamesByNodeIdMap[wire.source] = [getHandleName(wire.sourceHandle)];
        } else {
            portsNamesByNodeIdMap[wire.source] = [
                ...portsNamesByNodeIdMap[wire.source],
                getHandleName(wire.sourceHandle),
            ];
        }

        if (portsNamesByNodeIdMap[wire.target] === undefined) {
            portsNamesByNodeIdMap[wire.target] = [getHandleName(wire.targetHandle)];
        } else {
            portsNamesByNodeIdMap[wire.target] = [
                ...portsNamesByNodeIdMap[wire.target],
                getHandleName(wire.targetHandle),
            ];
        }
    });
    return portsNamesByNodeIdMap;
};

export const changeHandleId = (oldHandleId: string, newPortsNamesByOldPortsNames: { [key: string]: string }) => {
    const handleName = getHandleName(oldHandleId);
    const splittedHandleId = oldHandleId.split('-');
    const splittedNewHandleName = newPortsNamesByOldPortsNames[handleName].split('_');
    const newHandleNumber = splittedNewHandleName[splittedNewHandleName.length - 1];

    splittedHandleId.splice(-2, 2, newHandleNumber, newPortsNamesByOldPortsNames[handleName]);
    const updatedHandleId = splittedHandleId.join('-');

    return updatedHandleId;
};

export const LIBRARIES_SORTING = [
    LibraryTypes.SIMULATION,
    LibraryTypes.AUTO,
    LibraryTypes.MECHANICS,
    LibraryTypes.THERMOHYDRAULICS,
    LibraryTypes.ISOTHERMAL_HYDRAULICS,
    LibraryTypes.HEAT_EXCHANGE,
    LibraryTypes.ELECTROCITY,
    LibraryTypes.ELECTRONICS,
    LibraryTypes.SYSTEM_DESIGN,
    LibraryTypes.ELECTRIC_DRIVE,
    LibraryTypes.EXTERNAL_MODELS,
    LibraryTypes.COMMON,
];

export const sortLibrariesCompare = (a: TLibraryType, b: TLibraryType) =>
    LIBRARIES_SORTING.indexOf(a) - LIBRARIES_SORTING.indexOf(b);

export const LIBRARIES_DEFAULT_LIST = Object.values(LibraryTypes);
