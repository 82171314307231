import React, { useEffect, useRef, useState } from 'react';

import { IFileManagerSelectionArea } from './FileManagerTypes';

export const FileManagerSelectionArea: React.FC<IFileManagerSelectionArea> = ({
    isItemDragging,
    children,
    selectedElements,
    onSelectGroup,
}) => {
    const [isSelecting, setIsSelecting] = useState(false);
    const [startPoint, setStartPoint] = useState<{ x: number; y: number } | null>(null);
    const [endPoint, setEndPoint] = useState<{ x: number; y: number } | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleMouseUp = () => {
            setIsSelecting(false);
            updateSelectedItems();
        };

        window.addEventListener('mouseup', handleMouseUp);

        return () => {
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [startPoint, endPoint]);

    const isItemNowDragging = useRef(isItemDragging);
    useEffect(() => {
        isItemNowDragging.current = isItemDragging;
        if (isItemNowDragging.current) {
            setStartPoint(null);
            setEndPoint(null);
        }
    }, [isItemDragging]);

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        if (!containerRef.current) return;

        if (isItemDragging) return;

        const containerRect = containerRef.current.getBoundingClientRect();
        const offsetX = event.clientX - containerRect.left;
        const offsetY = event.clientY - containerRect.top;

        const clickedElement = document.elementFromPoint(event.clientX, event.clientY);
        const isClickOnPanel = clickedElement?.closest('[data-name="file-manager-preview"]');
        const isClickOnContextMenuItem = clickedElement?.closest('[data-name="context-menu-item"]');
        const isClickOnCheckbox = clickedElement?.closest('[type="checkbox"]');

        if (isClickOnContextMenuItem || isClickOnCheckbox || isClickOnPanel) {
            return;
        }

        const isClickOnSelectedItem =
            clickedElement && selectedElements.includes(clickedElement.getAttribute('data-file-manager-uuid') || '');

        if (!isClickOnSelectedItem) {
            setStartPoint({ x: offsetX, y: offsetY });
            setIsSelecting(true);
        } else {
            setIsSelecting(false);
        }
    };

    const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
        if (!isSelecting || !containerRef.current) return;

        const containerRect = containerRef.current.getBoundingClientRect();
        const offsetX = event.clientX - containerRect.left;
        const offsetY = event.clientY - containerRect.top;

        setEndPoint({ x: offsetX, y: offsetY });
    };

    const getSelectionStyle = () => {
        if (!startPoint || !endPoint) return {};

        const top = Math.min(startPoint.y, endPoint.y);
        const left = Math.min(startPoint.x, endPoint.x);
        const width = Math.abs(startPoint.x - endPoint.x);
        const height = Math.abs(startPoint.y - endPoint.y);

        return {
            position: 'absolute' as const,
            top,
            left,
            width,
            height,
            border: '1px dashed red',
            backgroundColor: 'rgba(255, 0, 0, 0.1)',
            pointerEvents: 'none' as const,
        };
    };

    const updateSelectedItems = () => {
        if (!startPoint || !endPoint || !containerRef.current) return;

        const selectedUuids: string[] = [];
        const selectionRect = {
            x: Math.min(startPoint.x, endPoint.x),
            y: Math.min(startPoint.y, endPoint.y),
            width: Math.abs(startPoint.x - endPoint.x),
            height: Math.abs(startPoint.y - endPoint.y),
        };

        const elements = containerRef.current.querySelectorAll('[data-file-manager-uuid]');
        elements.forEach((element) => {
            const rect = element.getBoundingClientRect();
            const offsetX = rect.left - containerRef.current!.getBoundingClientRect().left;
            const offsetY = rect.top - containerRef.current!.getBoundingClientRect().top;

            if (isIntersecting(selectionRect, { x: offsetX, y: offsetY, width: rect.width, height: rect.height })) {
                const uuid = element.getAttribute('data-file-manager-uuid');
                if (uuid) {
                    selectedUuids.push(uuid);
                }
            }
        });

        onSelectGroup(selectedUuids);
        setStartPoint(null);
        setEndPoint(null);
    };

    const isIntersecting = (
        rect1: { x: number; y: number; width: number; height: number },
        rect2: { x: number; y: number; width: number; height: number }
    ) => {
        return (
            rect1.x < rect2.x + rect2.width &&
            rect1.x + rect1.width > rect2.x &&
            rect1.y < rect2.y + rect2.height &&
            rect1.y + rect1.height > rect2.y
        );
    };

    return (
        <div
            ref={containerRef}
            style={{ position: 'relative', width: '100%', height: '100%', backgroundColor: 'transparent' }}
            onMouseMove={handleMouseMove}
            onMouseUp={(event) => {
                const target = event.target as HTMLElement;
                const isClickOnCheckbox = target.getAttribute('type') === 'checkbox';
                const isClickOnPanel = target.getAttribute('data-name') === 'file-manager-preview';
                const isClickOnContextMenu = target.closest('.context-menu-item');

                if (isClickOnContextMenu || isClickOnPanel || isClickOnCheckbox) {
                    return;
                }

                if (!event.ctrlKey) {
                    onSelectGroup([]);
                    return setIsSelecting(false);
                }
            }}
            {...(!isItemDragging && { onMouseDown: handleMouseDown })}
        >
            {!isItemDragging && isSelecting && startPoint && endPoint && <div style={getSelectionStyle()} />}
            {children}
        </div>
    );
};
