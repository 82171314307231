import { TInputVariant, uiColors } from '@repeat/ui-kit';

export const CSV_DELIMITER = ';';

export const IS_SHOW_OPTIONS_SAVE_BUTTON = false;

export const IS_UNDO_REDO_USE_LOCAL_STORE = false;

export const TREND_CHART_INTERVAL_OPTION = 5;

export const getStylesByVariant = (variant: TInputVariant) => {
    switch (variant) {
        case 'success':
            return {
                '.MuiOutlinedInput-notchedOutline': {
                    borderWidth: '1px !important',
                    borderColor: `${uiColors.green} !important`,
                },
            };

        case 'warning':
            return {
                '.MuiOutlinedInput-notchedOutline': {
                    borderWidth: '1px !important',
                    borderColor: `${uiColors.yellow} !important`,
                },
            };
        case 'default':
        default:
            return {
                '.MuiOutlinedInput-notchedOutline': {
                    borderWidth: '1px !important',
                    borderColor: `rgba(49, 49, 49, 20%) !important`,
                },
            };
    }
};

export const MAX_CHART_HEIGHT = 330;

export const SCREENSHOT_OPTIONS = (SSTarget: HTMLElement) => ({
    width: SSTarget.clientWidth,
    height: SSTarget.clientHeight,
    quality: 0.5,
    backgroundColor: '#eee',
});
