export const CREATE_PROJECT_FORM = 'create-project-form';
export const IMPORT_PROJECT_FORM = 'import-project-form';
export const EDIT_PROJECT_FORM = 'edit-project-form';
export const NEWBLOCK_EDIT = 'new-block-edit';
export const DELETE_PROJECT_FORM = 'delete-project-form';
export const CREATE_TICKET_TO_TECHNICAL_SUPPORT = 'create-ticket-technical-support';
export const SAVE_NEW_PROJECT_FORM = 'save-new-project-form';
export const IMPORT_LIBRARYITEM_FORM = 'import-libraryItem-form';
export const CREATE_API_KEY_FORM = 'create-api-key-form';
export const ACCOUNT_ADD = 'student-add-form';
export const ACCOUNT_EDIT = 'student-edit-form';
