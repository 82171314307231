import { FC } from 'react';

import styled from 'styled-components';

import { Color } from './colorTypes';
import { Icons } from './icons/icons';

import { layoutSize } from '../../config/config';
import { IIconProps } from '../../config/types';

const StyledIcon = styled.i<{ fill: Color | string }>`
    align-items: center;
    border-radius: 25%;
    display: flex;
    padding: 0;
    max-height: ${layoutSize.icons};
    max-width: ${layoutSize.icons};
    width: 100%;

    svg {
        fill: ${({ fill }) => fill};
        height: 100%;
        width: 100%;
    }
`;

export const Icon: FC<IIconProps> = ({ name, fill = 'black', ...props }) => {
    const SvgIcon = Icons[name];

    if (!name) {
        return null;
    }

    return (
        <StyledIcon data-name={name} fill={fill} {...props}>
            {SvgIcon && <SvgIcon />}
        </StyledIcon>
    );
};
