import { FC } from 'react';

import styled from 'styled-components';

import { uiColors } from '../../config/config';

interface ISLoaderDefault {
    color: string;
    width: number;
    height: number;
    internalWidth: number;
    internalHeight: number;
}

const SLoaderDefault = styled.div<ISLoaderDefault>`
    display: inline-block;
    width: ${(props) => `${props.width}px`};
    height: ${(props) => `${props.height}px`};
    position: relative;

    &:after {
        animation: lds-dual-ring 1.2s linear infinite;
        border: ${(props) => `3px solid ${props.color}`};
        border-color: ${(props) => `${props.color} transparent ${props.color} transparent`};
        content: ' ';
        display: block;
        border-radius: 50%;
        position: absolute;
        width: ${(props) => `${props.internalWidth}px`};
        height: ${(props) => `${props.internalHeight}px`};
        top: 50%;
        left: 50%;
    }
    @keyframes lds-dual-ring {
        0% {
            transform: translate(-50%, -50%) rotate(0deg);
        }
        100% {
            transform: translate(-50%, -50%) rotate(360deg);
        }
    }
`;

const DEFAULT_WIDTH = 28;
const DEFAULT_HEIGHT = 28;

const DEFAULT_INTERNAL_WIDTH = 17;
const DEFAULT_INTERNAL_HEIGHT = 17;

interface ILoaderDefault {
    id?: string;
    color?: string;
    width?: number;
    height?: number;
    onClick?: () => void;
}

export const LoaderDefault: FC<ILoaderDefault> = ({
    onClick,
    color = `${uiColors.white}`,
    width = DEFAULT_WIDTH,
    height = DEFAULT_HEIGHT,
    id,
}) => {
    const internalSizeCoefficient = DEFAULT_WIDTH - width > 0 ? 3 : 0.5;
    const internalWidth = DEFAULT_INTERNAL_WIDTH - (DEFAULT_WIDTH - width) * internalSizeCoefficient;
    const internalHeight = DEFAULT_INTERNAL_HEIGHT - (DEFAULT_HEIGHT - height) * internalSizeCoefficient;

    const sLoaderProps = {
        ...(id && { id }),
        color,
        width,
        height,
        internalWidth,
        internalHeight,
        ...(onClick !== undefined && {
            onClick,
        }),
    };

    return <SLoaderDefault {...sLoaderProps} />;
};
