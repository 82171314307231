import { FC, memo } from 'react';
import { useIntl } from 'react-intl';

import { IEventLogData } from '@repeat/models';
import { CODE_LIST, TranslationKey } from '@repeat/translations';

import { EventLogItemContext } from './EventLogItemContext';
import { SEventLogItem } from './SEventLogItem';
import { messages } from './translation';

import { ProjectDate } from '../../Project/ProjectDate';

export interface IEventLogItem {
    details: IEventLogData;
}

export const EventLogItem: FC<IEventLogItem> = memo(({ details }) => {
    const { level, currentTime, code, message } = details;
    const { formatMessage } = useIntl();

    let shownMessage = message;
    if (level === 'critical') {
        shownMessage = formatMessage(messages[TranslationKey.CRITICAL_ERROR]);
    } else {
        try {
            if (code in CODE_LIST) {
                shownMessage = formatMessage({ id: CODE_LIST[code] });
            }
            // eslint-disable-next-line
        } catch (e) {}
    }

    return (
        <SEventLogItem level={level}>
            <time>
                <ProjectDate date={currentTime} />
            </time>
            <div>
                <span>{shownMessage}</span>
                {details.context && <EventLogItemContext context={details.context} currentTime={currentTime} />}
            </div>
        </SEventLogItem>
    );
});
