import styled from 'styled-components';

import { pxToRem, radius } from '@repeat/ui-kit';

export const SSearchField = styled.div`
    margin-bottom: ${pxToRem(16)};
    background-color: var(--ui-notification);
    padding: ${pxToRem(16)};
    border-radius: ${radius.default};
    input {
        max-width: 50%;
        padding-left: ${pxToRem(24)};
    }

    div {
        &::before {
            content: '';
            position: absolute;
            left: ${pxToRem(6)};
            top: 50%;
            transform: translateY(-50%);
            width: ${pxToRem(14)};
            height: ${pxToRem(14)};

            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='64 64 896 896' focusable='false' data-icon='search'  fill='grey' aria-hidden='true'%3E%3Cpath d='M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z'%3E%3C/path%3E%3C/svg%3E");
        }

        margin: 0;
    }
`;
