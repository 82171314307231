export enum Statuses {
    IDLE = 'idle',
    LOADING = 'loading',
    SUCCEEDED = 'succeeded',
    FAILED = 'failed',
}
export type Status = Statuses.IDLE | Statuses.LOADING | Statuses.SUCCEEDED | Statuses.FAILED;

export enum TrendsStatuses {
    DISCONNECTED = 'disconnected',
    CONNECTING = 'connecting',
    CONNECTED = 'connected',
}
export type TrendsStatus = TrendsStatuses.DISCONNECTED | TrendsStatuses.CONNECTING | TrendsStatuses.CONNECTED;
