import { Action } from 'redux';

export enum ValidTypes {
    VALID = 'VALID',
}

export interface ValidAction extends Action<ValidTypes> {
    type: ValidTypes;
    payload: number;
}
