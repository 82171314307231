import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { useActionPrompt } from '@repeat/common-hooks';
import { logoutNew, updateSettings } from '@repeat/common-slices';
import { openTechnicalSupportModal } from '@repeat/common-thunks';
import { supportEmail, supportSubject } from '@repeat/constants';
import { environment } from '@repeat/environment';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { DistribTypes } from '@repeat/models';
import { appUserSelectors, doCleaningStuff } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { Dropdown, Icon, IconButton, LowAccent, Tooltip, uiColors } from '@repeat/ui-kit';

import { SNavigationDropDownFirstItem } from './SNavigation';
import { messages } from './translation';

const { DISTRIB, production } = environment || { production: false };
const isDevEnvironment = !production;
const { ACCOUNT_DOMAIN: accountDomain } = environment;

export const Navigation: FC<{ setTourOpen?: (open: boolean) => void }> = ({ setTourOpen }) => {
    const { formatMessage, locale } = useIntl();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const currentLocale = useAppSelector(appUserSelectors.currentUserLocale);
    const login = useAppSelector(appUserSelectors.currentUserLogin);
    const email = useAppSelector(appUserSelectors.currentUserEmail);
    const userName = useAppSelector(appUserSelectors.currentUserName);
    const userTheme = useAppSelector(appUserSelectors.currentUserTheme)!;
    const isEngineer = useAppSelector(appUserSelectors.currentUserMode);

    const [confirmationMode, setConfirmationMode] = useState(false);
    const location = useLocation();

    const isDistribIsCloud = DistribTypes.CLOUD;

    const handleLogout = async () => {
        await dispatch(doCleaningStuff());
        dispatch(logoutNew());
    };

    const handleLogoutClick = () => {
        if (location.pathname === '/') {
            setConfirmationMode(true);
            return;
        }

        handleLogout();
    };
    const handleOpenTechnicalSupportModal = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        dispatch(openTechnicalSupportModal(userName, email));
    };

    const devModeToggle = (locale: string, theme: string, isEngineer: boolean) => {
        dispatch(updateSettings({ locale, theme, isEngineer }, navigate));
    };

    useActionPrompt(
        `${formatMessage(messages[TranslationKey.LOG_OUT_MESSAGE])}`,
        confirmationMode,
        () => {
            setConfirmationMode(false);
            handleLogout();
        },
        () => {
            setConfirmationMode(false);
        }
    );

    return (
        <>
            {isDevEnvironment && (
                <Tooltip text={formatMessage(messages[TranslationKey.ENGINEER_MODE_TITLE])}>
                    <IconButton
                        fill={isEngineer ? uiColors.green : uiColors.grey}
                        noHover
                        name='devMode'
                        onClick={() => devModeToggle(currentLocale!, userTheme, !isEngineer)}
                    />
                </Tooltip>
            )}

            {setTourOpen && (
                <Tooltip text={formatMessage(messages[TranslationKey.TOUR_ITEM])}>
                    <IconButton
                        fill={'white'}
                        noHover
                        name={'presentation'}
                        onClick={() => {
                            setTourOpen(true);
                        }}
                    />
                </Tooltip>
            )}
            <Tooltip text={formatMessage(messages[TranslationKey.CONTACT_SUPPORT])}>
                {DISTRIB === isDistribIsCloud ? (
                    <a onClick={handleOpenTechnicalSupportModal} href='!#'>
                        <Icon name='support' fill='white' />
                    </a>
                ) : (
                    <a
                        href={`mailto:${supportEmail}?subject=${supportSubject}`}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <Icon name='support' fill='white' />
                    </a>
                )}
            </Tooltip>
            <Tooltip text={formatMessage(messages[TranslationKey.PAGE_VERSIONS_HISTORY_TITLE])}>
                <Link to='/versions' key='help-item'>
                    <Icon name='history' fill='white' />
                </Link>
            </Tooltip>
            <Tooltip text={formatMessage(messages[TranslationKey.PAGE_HELP_TITLE])}>
                <a href={`${environment.DOCS_URL}/${locale}/index.html`} target='_blank' rel='noopener noreferrer'>
                    <Icon name='help' fill='white' />
                </a>
            </Tooltip>
            <Dropdown clickOutside trigger={<Icon name='account' fill='white' />}>
                <SNavigationDropDownFirstItem style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                    <span>{login}</span>
                    <LowAccent>{email}</LowAccent>
                </SNavigationDropDownFirstItem>
                <a href={accountDomain} key='profile-item'>
                    <Icon fill={uiColors.darkGrey} name='settings' />
                    <span>{formatMessage(messages[TranslationKey.SETTINGS_MENU_ITEM])}</span>
                </a>
                <a href={`${accountDomain}/account/api-keys`} key='api-keys-item'>
                    <Icon fill={uiColors.darkGrey} name='key' style={{ minWidth: '20px' }} />
                    <span>{formatMessage(messages[TranslationKey.API_KEYS_MENU_ITEM])}</span>
                </a>
                <IconButton
                    fill={uiColors.darkGrey}
                    text={formatMessage(messages[TranslationKey.EXIT_MENU_ITEM])}
                    noHover
                    key='exit-item'
                    name='exit'
                    onClick={handleLogoutClick}
                />
            </Dropdown>
        </>
    );
};
