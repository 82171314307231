import React, { useState } from 'react';
import { ReactFlowInstance, XYPosition } from 'reactflow';

import { isBottomBarOpened, isLeftBarOpened } from '@repeat/constants';

export interface ILayoutParams {
    leftBarWidth: number;
    bottomBarHeight: number;
    headerHeight: number;
    searchInputHeight: number;
    safeDropdownHeight: number;
}

export enum SearchDropdownDirections {
    UPWARDS = 'upwards',
    DOWNWARDS = 'downwards',
}
export type TSearchDropdownDirection = SearchDropdownDirections.UPWARDS | SearchDropdownDirections.DOWNWARDS;

const DEFAULT_SEARCH_META = {
    searchPosition: null,
    searchDropdownDirection: SearchDropdownDirections.DOWNWARDS,
    addNodePosition: null,
};

interface ISearchMeta {
    searchPosition: XYPosition | null;
    searchDropdownDirection: TSearchDropdownDirection;
    addNodePosition: XYPosition | null;
}

interface IUseSearchModeReturn {
    isSearchMode: boolean;
    searchMeta: ISearchMeta;
    turnOnSearchModeByClick: (
        event: React.MouseEvent,
        reactFlowInstance: ReactFlowInstance | null,
        reactFlowWrapper: React.RefObject<HTMLInputElement>,
        layoutParams: ILayoutParams
    ) => void;
    turnOffSearchMode: () => void;
}

export const useSearchMode = (): IUseSearchModeReturn => {
    const [isSearchMode, setIsSearchMode] = useState(false);
    const [searchMeta, setSearchMeta] = useState<ISearchMeta>(DEFAULT_SEARCH_META);

    const turnOnSearchModeByClick = (
        event: React.MouseEvent,
        reactFlowInstance: ReactFlowInstance | null,
        reactFlowWrapper: React.RefObject<HTMLInputElement>,
        layoutParams: ILayoutParams
    ) => {
        if (reactFlowInstance && reactFlowWrapper?.current) {
            const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
            const canvasPosition = reactFlowInstance.project({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
            });
            const leftMargin = isLeftBarOpened() ? -layoutParams.leftBarWidth : 0;
            const bottomMargin = isBottomBarOpened() ? -(layoutParams.bottomBarHeight - layoutParams.headerHeight) : 0;

            const x =
                reactFlowBounds.right - reactFlowBounds.left - (event.clientX + leftMargin) >= layoutParams.leftBarWidth
                    ? event.clientX + leftMargin
                    : event.clientX + leftMargin - layoutParams.leftBarWidth;
            const y =
                reactFlowBounds.bottom - event.clientY - layoutParams.headerHeight + bottomMargin >=
                layoutParams.searchInputHeight
                    ? event.clientY - layoutParams.headerHeight
                    : event.clientY - layoutParams.headerHeight - layoutParams.searchInputHeight;
            const isVerticalRevert =
                reactFlowBounds.bottom - event.clientY + bottomMargin < layoutParams.safeDropdownHeight;

            const position: XYPosition = {
                x: x,
                y: y,
            };

            setIsSearchMode(true);
            setSearchMeta({
                searchPosition: position,
                searchDropdownDirection: isVerticalRevert
                    ? SearchDropdownDirections.UPWARDS
                    : SearchDropdownDirections.DOWNWARDS,
                addNodePosition: canvasPosition,
            });
        }
    };

    const turnOffSearchMode = () => {
        setIsSearchMode(false);
        setSearchMeta(DEFAULT_SEARCH_META);
    };

    return {
        isSearchMode,
        searchMeta,
        turnOnSearchModeByClick,
        turnOffSearchMode,
    };
};
