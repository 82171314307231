import { Action } from 'redux';

/* eslint-disable @typescript-eslint/no-explicit-any */

export enum DeletedElementsTypes {
    ADD_DELETED_ELEMENT = 'ADD_DELETED_ELEMENT',
    DELETE_DELETED_ELEMENT = 'DELETE_DELETED_ELEMENT',
}

export interface DeletedElementsAction extends Action<DeletedElementsTypes> {
    type: DeletedElementsTypes;
    payload?: any;
}
