import styled from 'styled-components';

import { Icon, IconButton, pxToRem, uiColors } from '@repeat/ui-kit';

export const SIconButton = styled(IconButton)`
    display: none;
`;
export const SParametersItem = styled.div`
    margin-bottom: ${pxToRem(8)};
    border-bottom: 1px solid ${uiColors.grey};
    display: flex;
    justify-content: space-between;
    &:hover {
        ${SIconButton} {
            display: block;
        }
    }
`;

export const SPoint = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${uiColors.dark};
    margin-left: ${pxToRem(8)};
`;
export const SParameterDescription = styled.div`
    color: ${uiColors.darkGrey};
    font-size: ${pxToRem(12)};
    margin-bottom: ${pxToRem(4)};
`;
export const SParameterModelName = styled.span`
    color: ${uiColors.grey};
    font-size: ${pxToRem(10)};
`;

export const SParameterValue = styled.div`
    min-height: ${pxToRem(16)};
`;
export const SIcon = styled(Icon)`
    width: 14px;
    height: 14px;
    margin-left: ${pxToRem(8)};
`;
