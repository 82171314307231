import { FC, memo } from 'react';

import { FileManagerPanel } from './FileManagerPanel';
import { FileManagerSearch } from './FileManagerSearch';
import { IFileManagerHeader } from './FileManagerTypes';
import { SFileManagerHeader, SFileManagerTitle } from './SFileManager';

export const FileManagerHeader: FC<IFileManagerHeader> = memo(
    ({
        elements,
        sortType,
        viewMode,
        selectedElements,
        translations,
        isOpenImport,
        rules,
        onGetData,
        onRemove,
        onAddFolder,
        onChangeMode,
        onSelectSort,
        onSearch,
        onChangePreviewMode,
        onOpenImport,
        onCreateFile,
        onMultiSelect,
    }) => (
        <SFileManagerHeader>
            <SFileManagerTitle>
                {translations && translations['header'] && <span>{translations['header']}</span>}
                <FileManagerSearch onSearch={onSearch} />
            </SFileManagerTitle>
            <FileManagerPanel
                rules={rules}
                translations={translations}
                elements={elements}
                sortType={sortType}
                selectedElements={selectedElements}
                viewMode={viewMode}
                isOpenImport={isOpenImport}
                onGetData={onGetData}
                onChangeMode={onChangeMode}
                onAddFolder={onAddFolder}
                onRemove={onRemove}
                onSelectSort={onSelectSort}
                onChangePreviewMode={onChangePreviewMode}
                onOpenImport={onOpenImport}
                onCreateFile={onCreateFile}
                onMultiSelect={onMultiSelect}
            />
        </SFileManagerHeader>
    )
);
