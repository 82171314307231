import { PayloadAction } from '@reduxjs/toolkit';

import { IWorkspaceState, TSchemaNode } from '@repeat/models';

export const ChangeElementSlices = {
    changeElementSize: (
        state: IWorkspaceState,
        { payload }: PayloadAction<{ id: number; size: { width: number; height: number } }>
    ) => {
        const { elements } = state.schema.schemaItems;
        const newElements = elements.map((el: TSchemaNode) => {
            if (el.data.id === payload.id) {
                return {
                    ...el,
                    height: payload.size.height,
                    width: payload.size.width,
                };
            }
            return el;
        });
        return {
            ...state,
            schema: {
                ...state.schema,
                schemaItems: {
                    ...state.schema.schemaItems,
                    elements: newElements,
                },
            },
        };
    },
    changeElementRotation: (state: IWorkspaceState, { payload }: PayloadAction<{ id: number; rotation: number }>) => {
        const { elements } = state.schema.schemaItems;
        const newElements = elements.map((el: TSchemaNode) => {
            if (el.data.id === payload.id) {
                return {
                    ...el,
                    rotation: payload.rotation,
                };
            }
            return el;
        });
        return {
            ...state,
            schema: {
                ...state.schema,
                schemaItems: {
                    ...state.schema.schemaItems,
                    elements: newElements,
                },
            },
        };
    },
    changeElementCustomName: (state: IWorkspaceState, action: PayloadAction<{ id: number; name: string }>) => {
        const { id, name } = action.payload;

        const { elements } = state.schema.schemaItems;
        const newElements = elements.map((el: TSchemaNode) => {
            if (el.data.id === id) {
                return {
                    ...el,
                    customName: name,
                };
            }
            return el;
        });

        return {
            ...state,
            schema: {
                ...state.schema,
                schemaItems: {
                    ...state.schema.schemaItems,
                    elements: newElements,
                },
            },
        };
    },
    changeElementCustomImage: (state: IWorkspaceState, action: PayloadAction<{ id: number; image: string }>) => {
        const { id, image } = action.payload;

        const { elements } = state.schema.schemaItems;
        const newElements = elements.map((el: TSchemaNode) => {
            if (el.data.id === id) {
                return {
                    ...el,
                    customImage: image,
                };
            }
            return el;
        });

        return {
            ...state,
            schema: {
                ...state.schema,
                schemaItems: {
                    ...state.schema.schemaItems,
                    elements: newElements,
                },
            },
        };
    },
};
