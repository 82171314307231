import { AnyAction, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';

import { IWorkspaceState, SchemaUpdateTypes, TSchemaNode, TUndoRedoContainer, UndoRedoType } from '@repeat/models';
import { getDBStack, redo, undo } from '@repeat/services';
import { workspaceActions as actions, workspaceActions } from '@repeat/store';

const isPortBlock = (el: TSchemaNode) => el.data.type === 'InPort' || el.data.type === 'OutPort';
const isNotPortBlock = (el: TSchemaNode) => el.data.type !== 'InPort' && el.data.type !== 'OutPort';

export const compareElements = ({ oldList, newList }: { oldList: TSchemaNode[]; newList: TSchemaNode[] }) => {
    const newListByIds = newList.map((item) => item.id);
    const oldListByIds = oldList.map((item) => item.id);
    let elements = [...oldList] as TSchemaNode[];
    if (newList.length === oldList.length) {
        newList.forEach((item: TSchemaNode, index: number) => {
            const element = oldList.find((oldItem: TSchemaNode) => oldItem.id === item.id);
            if (element && JSON.stringify(element) !== JSON.stringify(item)) {
                elements[index] = { ...item };
            }
        });
    }
    if (newList.length > oldList.length) {
        const diffList = newListByIds.filter((item) => !oldListByIds.includes(item));
        if (diffList.length > 0) {
            elements = elements.filter((el) => newListByIds.includes(el.id));
            diffList.forEach((diffItem) => {
                const newListFindItem = newList.find((newListItem) => newListItem.id === diffItem);
                if (newListFindItem) {
                    elements[elements.length] = newListFindItem;
                }
            });
        }
    }
    if (newList.length < oldList.length) {
        const diffList = oldListByIds.filter((item) => !newListByIds.includes(item));
        if (diffList.length > 0) {
            const missedElements = newList.filter((el) => !oldListByIds.includes(el.id));
            elements = [...elements, ...missedElements];
            diffList.reverse().forEach((diffItem) => {
                const index = oldList.findIndex((item) => item.id === diffItem);
                if (typeof index !== 'undefined') {
                    elements.splice(index, 1);
                }
            });
        }
    }
    return { elements };
};

export const undoRedoReducers = {
    undo: (state: IWorkspaceState, { payload }: PayloadAction<TUndoRedoContainer>) => ({
        ...state,
        undoRedo: {
            type: UndoRedoType.UNDO,
            canUndo: payload.stack.length > 1,
            canRedo: payload.next.length > 0,
        },
    }),
    redo: (state: IWorkspaceState, { payload }: PayloadAction<TUndoRedoContainer>) => ({
        ...state,
        undoRedo: {
            type: UndoRedoType.REDO,
            canUndo: payload.stack.length > 1,
            canRedo: payload.next.length > 0,
        },
    }),
    status: (state: IWorkspaceState, { payload }: PayloadAction<TUndoRedoContainer>) => ({
        ...state,
        undoRedo: {
            ...state.undoRedo,
            canUndo: payload.stack.length > 1,
            canRedo: payload.next.length > 0,
        },
    }),
};

// TODO make using AppDispatch and RootStateFn again
export const Undo = () => async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: any) => {
    const state = await undo();

    const currentSchema = getState().workspace.schema;
    const currentCharts = getState().workspace.graphs;

    const { elements } = compareElements({
        oldList: currentSchema.schemaItems.elements.filter((el: TSchemaNode) => isNotPortBlock(el)),
        newList: state.current.payload.schemaItems.elements.filter((el: TSchemaNode) => isNotPortBlock(el)),
    });

    const elementsWithParamsNotPortsBlocks = elements.map((el) => {
        const {
            data: { id, name, index, elemParams },
        } = el;
        return { id, name, index, elemParams };
    });
    const elementsWithParamsPortsBlocks = state.current.payload.schemaItems.elements
        .filter((el: TSchemaNode) => isPortBlock(el))
        .map((el: TSchemaNode) => {
            const {
                data: { id, name, index, elemParams },
            } = el;
            return { id, name, index, elemParams };
        });

    const newSchema = {
        ...currentSchema,
        schemaItems: {
            ...currentSchema.schemaItems,
            elements: [
                ...elements,
                ...state.current.payload.schemaItems.elements.filter((el: TSchemaNode) => isPortBlock(el)),
            ],
            wires: state.current.payload.schemaItems.wires,
            groups: state.current.payload.schemaItems.groups,
        },
        proxyMap: state.current.payload.proxyMap,
        externalInfo: state.current.payload.externalInfo,
        elementsWithParams: [...elementsWithParamsNotPortsBlocks, ...elementsWithParamsPortsBlocks],
        goToMap: state.current.payload.goToMap,
        blockNotifications: state.current.payload.blockNotifications,
    };
    dispatch(actions.redo(state));
    dispatch(workspaceActions.updateSchema({ schema: newSchema, type: SchemaUpdateTypes.UNDOREDO }));
    dispatch(workspaceActions.updateProjectSettings(state.current.payload.settings));
    dispatch(workspaceActions.setModules(state.current.payload.modules));
    const newCharts = { ...state.current.payload.graphs, data: currentCharts.data, dataMap: currentCharts.dataMap };
    dispatch(workspaceActions.updateCharts(newCharts));
};

// TODO make using AppDispatch and RootStateFn again
export const Redo = () => async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: any) => {
    const state = await redo();

    const currentSchema = getState().workspace.schema;
    const currentCharts = getState().workspace.graphs;

    const { elements } = compareElements({
        oldList: currentSchema.schemaItems.elements.filter((el: TSchemaNode) => isNotPortBlock(el)),
        newList: state.current.payload.schemaItems.elements.filter((el: TSchemaNode) => isNotPortBlock(el)),
    });
    const elementsWithParamsNotPortsBlocks = elements.map((el) => {
        const {
            data: { id, name, index, elemParams },
        } = el;
        return { id, name, index, elemParams };
    });
    const elementsWithParamsPortsBlocks = state.current.payload.schemaItems.elements
        .filter((el: TSchemaNode) => isPortBlock(el))
        .map((el: TSchemaNode) => {
            const {
                data: { id, name, index, elemParams },
            } = el;
            return { id, name, index, elemParams };
        });

    const newSchema = {
        ...currentSchema,
        schemaItems: {
            ...getState().workspace.schema.schemaItems,
            elements: [
                ...elements,
                ...state.current.payload.schemaItems.elements.filter((el: TSchemaNode) => isPortBlock(el)),
            ],
            wires: state.current.payload.schemaItems.wires,
        },
        proxyMap: state.current.payload.proxyMap,
        externalInfo: state.current.payload.externalInfo,
        elementsWithParams: [...elementsWithParamsNotPortsBlocks, ...elementsWithParamsPortsBlocks],
        goToMap: state.current.payload.goToMap,
        blockNotifications: state.current.payload.blockNotifications,
    };
    dispatch(actions.redo(state));
    dispatch(workspaceActions.updateSchema({ schema: newSchema, type: SchemaUpdateTypes.UNDOREDO }));
    dispatch(workspaceActions.updateProjectSettings(state.current.payload.settings));
    dispatch(workspaceActions.setModules(state.current.payload.modules));
    const newCharts = { ...state.current.payload.graphs, data: currentCharts.data, dataMap: currentCharts.dataMap };
    dispatch(workspaceActions.updateCharts(newCharts));
};

// TODO make using AppDispatch again
export const UndoRedoStatus = () => async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    const state = await getDBStack();
    dispatch(actions.status(state));
};
