import styled from 'styled-components';

import { pxToRem, uiColors } from '@repeat/ui-kit';

export const SCodeEditorWrapper = styled.div`
    border: none;
    background-color: initial;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    height: calc(100vh - 48px - 40px);
    position: relative;
    width: 100%;
    z-index: 0;

    padding: ${pxToRem(42)} 0 0 ${pxToRem(16)};
`;

export const SCodeEditorError = styled.div`
    color: ${uiColors.red};
`;
