import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import JSZip from 'jszip';

import { IEngineerBlockSortOptions } from 'libs/models/src/lib/libraryItem';
import { ModalTypes, TNewBlockInfoModal } from 'libs/models/src/lib/modal';

import { useSortOptions } from '@repeat/common-hooks';
import { ApplicationActions } from '@repeat/common-slices';
import { useAppDispatch, useEngineerBlocks } from '@repeat/hooks';
import { ILibraryItem } from '@repeat/models';
import { TranslationKey } from '@repeat/translations';
import { Button, Dropdown, Icon, IconButton, uiColors } from '@repeat/ui-kit';

import { EngineerService } from './EngineerService';
import { TableFooter } from './TableFooter';
import { STableHeader } from './styled/STableHeader';
import { messages } from './translations';

import { SColumnText, STable, SWrapperTable, TableRowItem } from '../../../../../libs/ui-kit/src/lib/Table/STable';
import { useSearch } from '../../hooks/useSearch';
import { RotatedIcon } from '../Project/styled/SProjectTable';
import { SSortedBlock } from '../Project/styled/SSortedBlock';

const engineerService = new EngineerService('engineerBlocks');
const engineerServiceEN = new EngineerService('engineerBlocksEN');

export const EngineerBlocks: FC = () => {
    const dispatch = useAppDispatch();

    const intl = useIntl();
    const navigate = useNavigate();

    const { sortOptions, setSortOptions, getFillColor } = useSortOptions();

    const [data, setData] = useState<ILibraryItem[]>([]);
    const memoizedData = useMemo(() => data, [data]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const lastIndex = currentPage * rowsPerPage;
    const firstIndex = lastIndex - rowsPerPage;
    const npage = Math.ceil(memoizedData.length / rowsPerPage);
    const { filter, searchHandler, SearchField } = useSearch({ query: '' });
    const recordsRef = useRef<ILibraryItem[]>([]);
    recordsRef.current = memoizedData.length >= 1 ? memoizedData.slice(firstIndex, lastIndex) : memoizedData;
    const records = recordsRef.current;
    const [enData, setEnData] = useState<ILibraryItem[]>([]);

    const sortBlocks = useCallback(
        ({ column, direction }: IEngineerBlockSortOptions) => {
            if (!records?.length) {
                return [];
            }
            return [...records].sort((a, b) => {
                const aValue = a[column];
                const bValue = b[column];

                if (column === 'name') {
                    return direction === 'asc'
                        ? aValue!.localeCompare(bValue as string)
                        : (bValue as string).localeCompare(aValue!);
                } else {
                    return 0;
                }
            });
        },
        [records]
    );

    const sortedData = useMemo(
        () => sortBlocks({ column: sortOptions.column, direction: sortOptions.direction }),
        [sortOptions, sortBlocks]
    );

    const searchedBlocks = useEngineerBlocks(sortedData || [], filter.query);

    useEffect(() => {
        const fetchBlocks = async () => {
            try {
                const blocks = await engineerService.getBlocks();
                setData(blocks);
            } catch (error) {
                console.error('Ошибка при получении блоков:', error);
            }
        };
        fetchBlocks();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const allData = await engineerServiceEN.getBlocks();
            setEnData(allData);
        };

        fetchData().catch((err) => console.error('Failed to get blocks: ', err));
    }, []);

    const handleOpenBlockDescriptionClick = (block: ILibraryItem) => {
        const modal: TNewBlockInfoModal = {
            type: ModalTypes.NEWBLOCK_INFO,
            data: {
                block,
            },
        };
        dispatch(ApplicationActions.showModal({ modal }));
    };

    const handleDeleteBlockClick = async (block: ILibraryItem) => {
        await engineerService.deleteBlock(block.type);
        setData(await engineerService.getBlocks());
    };

    const handleOnColumnClick = (title: keyof Pick<ILibraryItem, 'name' | 'type'>) => {
        setSortOptions((sortOptions) => ({
            column: title,
            direction: sortOptions.column === title && sortOptions.direction === 'asc' ? 'desc' : 'asc',
        }));
    };
    useEffect(() => {
        recordsRef.current = memoizedData.length >= 1 ? memoizedData.slice(firstIndex, lastIndex) : memoizedData;
    }, [currentPage, rowsPerPage]);

    const handleClearAllClick = async () => {
        await engineerService.deleteAll().catch((err) => console.error('Failed to delete all blocks: ', err));
        await engineerServiceEN.deleteAll().catch((err) => console.error('Failed to delete all blocks: ', err));
        setData(await engineerService.getBlocks());
    };

    const downloadJSONFile = ({
        data,
        enData,
        fileName,
    }: {
        data: ILibraryItem;
        enData: ILibraryItem;
        fileName: string;
    }) => {
        const zip = new JSZip();
        Reflect.deleteProperty(data, 'createdAt');
        Reflect.deleteProperty(data, 'updatedAt');

        zip.file('data.json', JSON.stringify(data));
        zip.file('enData.json', JSON.stringify(enData));

        zip.generateAsync({ type: 'blob' })
            .then((content) => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(content);
                link.download = fileName;
                link.click();
                link.remove();
            })
            .catch((err) => console.error(err));
    };

    const exportJSONFile = (e: React.MouseEvent, exportedBlockType: string) => {
        e.preventDefault();
        const currentBlock: ILibraryItem | undefined = data.find((item) => item.type === exportedBlockType);
        const currentEnBlock: ILibraryItem | undefined = enData.find((item) => item.type === exportedBlockType);

        downloadJSONFile({
            data: currentBlock!,
            enData: currentEnBlock!,
            fileName: `exportedBlocks.zip`,
        });
    };

    return (
        <>
            <STableHeader>
                <SearchField id='search' />
                <Button
                    type='button'
                    disabled={searchedBlocks.length === 0 && true}
                    onClick={handleClearAllClick}
                    variant='outlined'
                    size='medium'
                >
                    {intl.formatMessage(messages[TranslationKey.ENGINEER_CLEAR_ALL])}
                </Button>
            </STableHeader>

            <SWrapperTable>
                <STable data-tour='blocks-table'>
                    <thead>
                        <tr>
                            <th onClick={() => handleOnColumnClick('name')}>
                                <SColumnText isActive={sortOptions.column === 'name'}>
                                    {intl.formatMessage(messages[TranslationKey.ENGINEER_COLUMN_NAME])}
                                </SColumnText>
                                <SSortedBlock>
                                    <Icon name='triangle' fill={getFillColor({ column: 'name', direction: 'asc' })} />
                                    <RotatedIcon
                                        name='triangle'
                                        fill={getFillColor({ column: 'name', direction: 'desc' })}
                                    />
                                </SSortedBlock>
                            </th>
                            <th>
                                <SColumnText>
                                    {intl.formatMessage(messages[TranslationKey.ENGINEER_COLUMN_TYPE])}
                                </SColumnText>
                            </th>
                            <th>
                                <SColumnText>EN</SColumnText>
                            </th>
                            <th>
                                <SColumnText>Создан</SColumnText>
                            </th>
                            <th>
                                <SColumnText>Обновлен</SColumnText>
                            </th>
                            <th style={{ cursor: 'default' }} />
                        </tr>
                    </thead>
                    <tbody>
                        {searchedBlocks.length > 0 &&
                            searchedBlocks.map((item: ILibraryItem) => (
                                <tr id={String(item.id)} key={item.id}>
                                    <TableRowItem onClick={() => handleOpenBlockDescriptionClick(item)}>
                                        {item.name}
                                    </TableRowItem>
                                    <TableRowItem>{item.type}</TableRowItem>
                                    <TableRowItem>
                                        {enData.some((el, i) => el.type === item.type) ? <>Есть</> : <>Отсутствует</>}
                                    </TableRowItem>
                                    <TableRowItem>{item.createdAt?.toString()}</TableRowItem>
                                    <TableRowItem>{item.updatedAt?.toString()}</TableRowItem>
                                    <TableRowItem>
                                        <Dropdown
                                            clickOutside
                                            trigger={<Icon key={item.id} name={'dots'} fill={uiColors.darkGrey} />}
                                        >
                                            <IconButton
                                                fill={uiColors.darkGrey}
                                                noHover
                                                text='Редактировать'
                                                name='edit'
                                                onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                                                    handleOpenBlockDescriptionClick(item)
                                                }
                                            />
                                            <IconButton
                                                fill={uiColors.darkGrey}
                                                noHover
                                                text={intl.formatMessage(
                                                    messages[TranslationKey.PROJECT_DELETE_DROPDOWN]
                                                )}
                                                name='delete'
                                                onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                                                    handleDeleteBlockClick(item)
                                                }
                                            />
                                            <IconButton
                                                fill={uiColors.darkGrey}
                                                noHover
                                                text={intl.formatMessage(
                                                    messages[TranslationKey.ENGINEER_EXPORT_BlOCK]
                                                )}
                                                name='export'
                                                onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                                                    exportJSONFile(event, item.type)
                                                }
                                            />
                                        </Dropdown>
                                    </TableRowItem>
                                </tr>
                            ))}
                    </tbody>
                </STable>
            </SWrapperTable>
            {memoizedData.length > rowsPerPage && (
                <TableFooter
                    currentPage={currentPage}
                    count={npage}
                    onChange={(_: any, num: number) => setCurrentPage(num)}
                />
            )}
        </>
    );
};
