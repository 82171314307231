/* eslint-disable react/jsx-no-useless-fragment */
import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { Element } from '@components/Elements';

import { libraryElementImageSrc } from '@repeat/constants';
import { useAppSelector, useDemoMode } from '@repeat/hooks';
import { ILibraryItem, TLibraryType, TSolverType } from '@repeat/models';
import { workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';

import { LibraryItemsAccordionItem } from './LibraryItemsAccordionItem';
import { SWrapper } from './SLibraryItemsBlock';
import { messages } from './translation';

import { LibraryItemsSubLibrary } from '../LibraryItemsSubLibrary/LibraryItemsSubLibrary';

interface ILibraryItemsBlockProps {
    items: ILibraryItem[];
    subtitle: string;
    solverType: TSolverType;
    libraryType: TLibraryType;
    onDragStart: (e: React.DragEvent<HTMLDivElement>, options: ILibraryItem) => void;
    isFiltered: boolean;
    accessible: boolean;
}

export const LibraryItemsBlock: FC<ILibraryItemsBlockProps> = (props) => {
    const { formatMessage } = useIntl();

    const { accessible, items: itemsAll, subtitle, solverType, libraryType, onDragStart, isFiltered } = props;
    const workspaceMetaUserBlockId = useAppSelector(workspaceSelectors.workspaceMetaUserBlockId);
    const { isDemo } = useDemoMode([workspaceMetaUserBlockId]);

    const items = itemsAll
        .filter((i) => {
            if (!isDemo) {
                return i.isActive && !(i.blockId && i.blockId === workspaceMetaUserBlockId);
            }
            return i.isActive;
        })
        .sort((x: { name: string }, y: { name: string }) => x.name.localeCompare(y.name));

    const itemsBySublibraries = items.reduce((result: { [key: string]: ILibraryItem[] }, obj: ILibraryItem) => {
        const key = obj.subLibrary;
        if (key) {
            if (!result[key]) {
                result[key] = [];
            }
            result[key].push(obj);
        }
        return result;
    }, {});

    const hasSubLibraries = Object.keys(itemsBySublibraries).length !== 0;

    const getImageSrc = useCallback((solverType: TSolverType, libraryType: TLibraryType, picId: number) => {
        const assetsPath = `/assets`;
        return libraryElementImageSrc(assetsPath, solverType, libraryType, `${picId}`);
    }, []);

    return (
        <>
            {items.length !== 0 && (
                <LibraryItemsAccordionItem
                    opened={accessible && items.length !== 0 && isFiltered}
                    key={subtitle}
                    title={subtitle}
                    hasSubLibraries={hasSubLibraries}
                    disabled={!accessible}
                >
                    <SWrapper>
                        {hasSubLibraries ? (
                            <LibraryItemsSubLibrary
                                opened={items.length !== 0 && isFiltered}
                                items={itemsBySublibraries}
                                solverType={solverType}
                                libraryType={libraryType}
                                onDragStart={onDragStart}
                            />
                        ) : (
                            items.map((el: ILibraryItem) => {
                                const blockId = el.blockId || '';

                                return (
                                    el.isActive && (
                                        <Element
                                            data-tour={'library-items-element'}
                                            solverType={solverType}
                                            libraryType={libraryType}
                                            options={el}
                                            key={`${el.picId}-${blockId}`}
                                            onDragStart={(e) => {
                                                onDragStart(e, el);
                                            }}
                                            imageSrc={getImageSrc(solverType, libraryType, el.picId)}
                                            newTitle={formatMessage(messages[TranslationKey.LIBRARY_NEW_BLOCK])}
                                        />
                                    )
                                );
                            })
                        )}
                    </SWrapper>
                </LibraryItemsAccordionItem>
            )}
        </>
    );
};
