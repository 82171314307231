import { AnyAction, ListenerEffectAPI, ListenerMiddlewareInstance, ThunkDispatch } from '@reduxjs/toolkit';

import { ApplicationActions } from '@repeat/common-slices';
import { TREND_TIMEOUT_ID_STORAGE_KEY } from '@repeat/constants';
import {
    ISelectorMessage,
    ModelStatuses,
    NotificationTypes,
    SelectorModelStatuses,
    SolverTypes,
    TNotification,
    TrendsStatuses,
    TSelectorStatusModel,
    TSolverType,
    TState,
} from '@repeat/models';
import { RootState, workspaceActions } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';

import { webSocketClient } from '@repeat/services';
import { initialState, runTrends, stopProject } from '../slices/workspace';

const prepareAndRunTrends = (
    listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, AnyAction>, unknown>,
    isContinue = false
) => {
    listenerApi.dispatch(runTrends(isContinue));
};

const stopTrends = (solverType: TSolverType) => {
    if (solverType !== SolverTypes.USDS) {
        const trendsTimeoutId = localStorage.getItem(TREND_TIMEOUT_ID_STORAGE_KEY);
        if (trendsTimeoutId !== null) {
            clearTimeout(Number(trendsTimeoutId));
        }
    }
};

const getSavedFooterMode = () => {
    const footerMode = localStorage.getItem('footer') === 'true';
    const activeTab = localStorage.getItem('footer-tab');

    return {
        isActive: footerMode,
        value: activeTab && typeof activeTab === 'string' ? parseInt(activeTab) : 0,
    };
};

const isVisualizationMode = () => {
    const footerMode = getSavedFooterMode();
    return footerMode.isActive && footerMode.value === 0;
};

const getNotificationByStatusModel = (modelStatus: TSelectorStatusModel): TNotification => {
    switch (modelStatus) {
        case SelectorModelStatuses.BUILD_ERROR:
            return {
                type: NotificationTypes.ERROR,
                message: TranslationKey.MESSAGE_BUILD_ERROR_FROM_SOLVER,
            };

        case SelectorModelStatuses.ERROR:
        default:
            return {
                type: NotificationTypes.ERROR,
                message: TranslationKey.MESSAGE_ERROR_FROM_SOLVER,
            };
    }
};

const getMessageKey = (status: TSelectorStatusModel): string | null => {
    switch (status) {
        case SelectorModelStatuses.EXCEEDED_DB_SIZE_LIMIT:
            return TranslationKey.MESSAGE_EXCEEDED_DB_SIZE_LIMIT_FROM_SOLVER;
        case SelectorModelStatuses.EXCEEDED_MEMORY_HEAP_SIZE_LIMIT:
            return TranslationKey.MESSAGE_EXCEEDED_MEMORY_HEAP_SIZE_LIMIT_FROM_SOLVER;
        case SelectorModelStatuses.EXCEEDED_DOCUMENT_TIME_LIMIT:
        case SelectorModelStatuses.EXCEEDED_SYSTEM_TIME_LIMIT:
        case SelectorModelStatuses.EXCEEDED_USER_TIME_LIMIT:
            return TranslationKey.MESSAGE_EXCEEDED_TIME_LIMIT_FROM_SOLVER;
        case SelectorModelStatuses.SOFT_STOP_BY_SOLVER:
            return TranslationKey.MESSAGE_STOP_FROM_SOLVER;
        case SelectorModelStatuses.STOP_BY_USER:
            return TranslationKey.MESSAGE_STOP_BY_USER;
        default:
            return null;
    }
};

const handleModelStatus = (
    listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, AnyAction>, unknown>,
    status: TSelectorStatusModel
) => {
    if (![SelectorModelStatuses.RUN, SelectorModelStatuses.SOFT_STOP_BY_USER].includes(status)) {
        listenerApi.dispatch(workspaceActions.setModelStatus(ModelStatuses.DATA));
    }

    if ([SelectorModelStatuses.STOP_BY_USER].includes(status)) {
        listenerApi.dispatch(workspaceActions.setModelStatus(ModelStatuses.STOP));
    }

    const messageKey = getMessageKey(status);
    if (messageKey !== null) {
        listenerApi.dispatch(
            ApplicationActions.showNotification({
                notification: {
                    type: NotificationTypes.INFO,
                    message: messageKey,
                },
            })
        );
    }

    return;
};

export const appendModelControlListeners = (listenerMiddleware: ListenerMiddlewareInstance) => {
    listenerMiddleware.startListening({
        actionCreator: workspaceActions.runProjectSuccess,
        effect: (action, listenerApi) => {
            const projectId = localStorage.getItem('projectId');

            const webSocketConnectSelector = () => {
                const onMessage = (event: MessageEvent, socket: WebSocket) => {
                    const state = listenerApi.getState() as TState;
                    const data: ISelectorMessage = JSON.parse(event.data);

                    const timeModel = data.timeModel > 0 ? data.timeModel : initialState.modelControl.modelTime;
                    listenerApi.dispatch(workspaceActions.setModelTime(timeModel));

                    socket.send(JSON.stringify({ isOnline: true }));

                    if (!isVisualizationMode()) {
                        listenerApi.dispatch(workspaceActions.setElementParametersValues(data.mapValueParams));
                    }

                    handleModelStatus(listenerApi, data.statusModel);

                    // get error from solver
                    if ([SelectorModelStatuses.ERROR, SelectorModelStatuses.BUILD_ERROR].includes(data.statusModel)) {
                        const notification = getNotificationByStatusModel(data.statusModel);
                        listenerApi.dispatch(
                            ApplicationActions.showNotification({
                                notification,
                            })
                        );
                        listenerApi.dispatch(workspaceActions.setGraphsStatus({ status: TrendsStatuses.DISCONNECTED }));
                        listenerApi.dispatch(stopProject());
                        return;
                    }
                };
                /* eslint-disable  @typescript-eslint/no-empty-function */
                const onOpen = () => {
                    const state = listenerApi.getState() as TState;
                    if (state.workspace.modelControl.modelStatus !== ModelStatuses.RUN) {
                        listenerApi.dispatch(workspaceActions.setModelStatus(ModelStatuses.RUN));
                    }
                };
                const onClose = () => {};
                const onError = () => {
                    listenerApi.dispatch(
                        ApplicationActions.showNotification({
                            notification: {
                                type: NotificationTypes.WARNING,
                                message: TranslationKey.ERROR_CONNECT_SELECTOR,
                            },
                        })
                    );
                };
                const url = `/api/v1/ws/connectSelector${projectId && `?projectId=${projectId}`}`;
                webSocketClient(url, onOpen, onMessage, onClose, onError);
            };
            webSocketConnectSelector();
        },
    });

    listenerMiddleware.startListening({
        actionCreator: workspaceActions.setModelStatus,
        effect: (action, listenerApi) => {
            if (action.payload === ModelStatuses.RUN) {
                prepareAndRunTrends(listenerApi);
            }
        },
    });

    listenerMiddleware.startListening({
        actionCreator: workspaceActions.stopProjectRequest,
        effect: (action, listenerApi) => {
            const rootState = listenerApi.getState() as RootState;
            const solverType = rootState.schema.solverType;

            listenerApi.dispatch(workspaceActions.setGraphsStatus({ status: TrendsStatuses.DISCONNECTED }));
            stopTrends(solverType);
        },
    });

    listenerMiddleware.startListening({
        actionCreator: workspaceActions.freezeProjectRequest,
        effect: (action, listenerApi) => {
            const rootState = listenerApi.getState() as RootState;
            const solverType = rootState.schema.solverType;

            listenerApi.dispatch(workspaceActions.setGraphsStatus({ status: TrendsStatuses.DISCONNECTED }));
            stopTrends(solverType);
        },
    });

    listenerMiddleware.startListening({
        actionCreator: workspaceActions.continueProjectSuccess,
        effect: (action, listenerApi) => {
            const isContinue = true;
            prepareAndRunTrends(listenerApi, isContinue);
        },
    });
};
