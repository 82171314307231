import { PayloadAction } from '@reduxjs/toolkit';

import { IWorkspaceState, Statuses } from '@repeat/models';
import { LibraryPortTypesService } from '@repeat/services';
import { TranslationKey } from '@repeat/translations';

import { TPortTypeComplex } from 'libs/models/src/lib/libraryItem';
import { AppDispatch, RootStateFn } from '../../../store';
import { actions, initialState } from '../index';

export const libraryPortTypesReducers = {
    getLibraryPortTypesRequest: (state: IWorkspaceState) => ({
        ...state,
        libraryPortTypes: {
            ...initialState.libraryPortTypes,
            status: Statuses.LOADING,
        },
    }),
    getLibraryPortTypesSuccess: (state: IWorkspaceState, action: PayloadAction<TPortTypeComplex[]>) => ({
        ...state,
        libraryPortTypes: {
            ...state.libraryPortTypes,
            items: action.payload,
            status: Statuses.SUCCEEDED,
        },
    }),
    getLibraryPortTypesFailed: (state: IWorkspaceState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        libraryPortTypes: {
            ...state.libraryPortTypes,
            status: Statuses.FAILED,
            error: action.payload.error,
        },
    }),
};

export const fetchLibraryPortTypes = () => async (dispatch: AppDispatch, getState: RootStateFn) => {
    dispatch(actions.getLibraryPortTypesRequest());
    try {
        const libraryPortTypesService = new LibraryPortTypesService();
        const items = await libraryPortTypesService.getLibraryPortTypes();

        if (items && items.data) {
            dispatch(actions.getLibraryPortTypesSuccess(items.data));
        }
    } catch (error: any) {
        const errorKey = TranslationKey.ERROR_LIBRARY_ITEMS;
        dispatch(actions.getLibraryItemsFailed({ error: errorKey }));
    }
};
