import { AxiosResponse } from 'axios';

import { TLibraryType, TSolverType } from '@repeat/models';

import { $api } from './http';

type GetElementPropertiesSetPayload = {
    elementType: string;
    libraryType: TLibraryType;
    solverType: TSolverType;
};
interface IReturnGetElementPropertiesSetContract {
    ElementPropertySets: {
        id: number;
        createdAt: string;
        updatedAt: string;
        solverType: TSolverType;
        libraryType: TLibraryType;
        elementType: string;
        name: string;
        description: string;
        currentProperties: { [key: string]: number };
        lastProperties: { [key: string]: number };
    }[];
}

interface IGetElementPropertiesSet {
    (payload: GetElementPropertiesSetPayload, signal?: AbortSignal): Promise<AxiosResponse<IReturnGetElementPropertiesSetContract>>;
}

export class ElementsPropertiesService {
    static getElementPropertiesSet: IGetElementPropertiesSet = async (payload: GetElementPropertiesSetPayload, signal?: AbortSignal) => {
        return await $api.get(
            `/api/v1/project/getElementPropertySets?solverType=${payload.solverType}&libraryType=${payload.libraryType}&elementType=${payload.elementType}`,
            {
                signal,
            }
        );
    };
}
