import {
    ElemParams,
    PortConnectionTypes,
    PortPositions,
    PortTypes,
    TLibraryType,
    TPortType,
    TSchemaHandle,
} from '@repeat/models';
import { TPortTypeComplex } from 'libs/models/src/lib/libraryItem';

const DEFAULT_LIBRARIES = ['auto', 'simulation', 'systemDesign'] as TLibraryType[];

export const usePortsCreator = (
    elemParams: ElemParams[],
    libraries: TLibraryType[] = DEFAULT_LIBRARIES,
    libraryPortTypes: TPortTypeComplex[]
) => {
    const ports: TSchemaHandle[] = [];

    elemParams.forEach((port) => {
        const portType = port.name.includes('in') ? PortTypes.INPUT : (PortTypes.OUTPUT as TPortType);
        const position = port.name.includes('in') ? PortPositions.LEFT : PortPositions.RIGHT;

        const portConnectionType = port.name.includes('in')
            ? PortConnectionTypes.AUTO_IN
            : PortConnectionTypes.AUTO_OUT;

        const portDetails = libraryPortTypes.find((typeComplex) => typeComplex.type === portConnectionType);

        if (portDetails) {
            const { type, ...rest } = portDetails;

            ports.push({
                name: port.name,
                position,
                libraries: [...libraries],
                type: portType,
                isConnected: false,
                typeConnection: type,
                ...rest,
            });
        }
    });

    return { ports };
};
