import React, { Suspense } from 'react';

import { LoaderFullscreen } from '@repeat/ui-kit';

const LoadingPageComponent = (path: string) => React.lazy(() => import(`../../pages/${path}`));

export function LazyComponent({ path }: { path: string }) {
    const Component = LoadingPageComponent(path);
    return (
        <Suspense fallback={<LoaderFullscreen />}>
            <Component />
        </Suspense>
    );
}
