import { useCallback, useEffect } from 'react';

export function useBlocker(blocker: () => void, when = true) {
    useEffect(() => {
        if (!when) {
            return;
        }
        return blocker();
    }, [blocker, when]);
}

export const useActionPrompt = (message: string, when = true, onConfirm: () => void, onCancel: () => void) => {
    const blocker = useCallback(() => {
        if (window.confirm(message)) {
            onConfirm();
        } else {
            onCancel();
        }
    }, [message, onConfirm, onCancel]);

    useBlocker(blocker, when);
};
