import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { XYPosition } from 'reactflow';

import { libraryElementImageSrc, replaceKeys } from '@repeat/constants';
import { useAppSelector } from '@repeat/hooks';
import { ILibraryItem, SolverTypes, TLibraryType } from '@repeat/models';
import { workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { AutoSuggest } from '@repeat/ui-kit';

import {
    SContextSearchOverlay,
    SContextSearchWrapper,
    SSuggestionItem,
    SSuggestionItemInfo,
    SSuggestionItemSubTitle,
    SSuggestionItemTitle,
} from './SContextSearch';
import { messages } from './translation';

interface ISuggestion {
    picId: number;
    title: string;
    library: TLibraryType;
    sublibrary: string | null;
    solverType: SolverTypes;
    type: string;
    subtype?: string | null;
}

export const ContextSearch: FC<{
    position: XYPosition | null;
    isUpwards: boolean;
    onAddNode: (item: ILibraryItem) => void;
    onClose: () => void;
}> = ({ position, isUpwards, onAddNode, onClose }) => {
    const intl = useIntl();

    const items = useAppSelector(workspaceSelectors.libraryItems);
    const availableItems = items
        .filter((i: ILibraryItem) => i.isActive && !i.isDisabled)
        .map((i: ILibraryItem) => ({
            picId: i.picId,
            title: i.name,
            library: i.library,
            sublibrary: i?.subLibrary || null,
            solverType: i.solver,
            aliases: i.aliases,
            type: i.type,
            subtype: i.subtype || null,
        }));

    const getSuggestions = (value: string): ISuggestion[] => {
        return availableItems.filter(
            (c) =>
                c.title.toLowerCase().includes(value.trim().toLowerCase()) ||
                c.aliases.filter((name) => name.toLowerCase().includes(value.trim().toLowerCase())).length !== 0 ||
                c.aliases.filter((name) => name.toLowerCase().includes(replaceKeys(value.trim().toLowerCase())))
                    .length !== 0 ||
                c.title.toLowerCase().includes(replaceKeys(value.trim().toLowerCase()))
        );
    };

    const libraryTranslationKey = useCallback((str: string, isSublibrary: boolean): string | null => {
        let key = str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`).toUpperCase();
        if (isSublibrary) {
            key = `SUBLIBRARY_${key}`;
        }

        const index = Object.keys(TranslationKey).indexOf(key);
        if (index < 0) {
            return null;
        }

        return Object.values(TranslationKey)[index];
    }, []);

    const getLibraryTitle = useCallback((libraryCode: string, isSublibrary = false) => {
        const translationKey = libraryTranslationKey(libraryCode, isSublibrary);
        if (!translationKey) {
            return '-';
        }

        return intl.formatMessage({ id: translationKey });
    }, []);

    const handleSelect = useCallback(
        (suggestion: ISuggestion) => {
            const item = items
                .filter((i: ILibraryItem) => i.isActive && !i.isDisabled)
                .find((i: ILibraryItem) => {
                    if (i.subtype && suggestion.subtype) {
                        return i.subtype.toLowerCase() === suggestion.subtype.toLowerCase();
                    }
                    return i.type.toLowerCase() === suggestion.type.toLowerCase();
                });
            if (item) {
                onAddNode(item);
            }
            onClose();
        },
        [items]
    );
    const getSuggestionTitle = (suggestion: ISuggestion | null) => {
        return suggestion?.title || '';
    };
    const renderSuggestion = (suggestion: ISuggestion | null) => {
        if (suggestion === null) {
            return <div>undefined</div>;
        }

        const assetsPath = `/assets`;
        const elementImage = libraryElementImageSrc(
            assetsPath,
            suggestion.solverType,
            suggestion.library,
            `${suggestion.picId}`
        );
        return (
            <SSuggestionItem>
                <img src={elementImage} alt={suggestion.title} width='30' />
                <SSuggestionItemInfo>
                    <SSuggestionItemTitle>{suggestion.title}</SSuggestionItemTitle>
                    <SSuggestionItemSubTitle>
                        {suggestion.sublibrary
                            ? `${getLibraryTitle(suggestion.library)} / ${getLibraryTitle(suggestion.sublibrary, true)}`
                            : getLibraryTitle(suggestion.library)}
                    </SSuggestionItemSubTitle>
                </SSuggestionItemInfo>
            </SSuggestionItem>
        );
    };
    const placeholder = intl.formatMessage(messages[TranslationKey.CONTEXT_SEARCH_PLACEHOLDER]);

    return (
        <SContextSearchOverlay onClick={onClose}>
            <SContextSearchWrapper
                top={position?.y || null}
                left={position?.x || null}
                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                    e.stopPropagation();
                }}
            >
                <AutoSuggest<ISuggestion>
                    getSuggestions={getSuggestions}
                    onSelect={handleSelect}
                    placeholder={placeholder}
                    getSuggestionTitle={getSuggestionTitle}
                    renderSuggestion={renderSuggestion}
                    isUpwards={isUpwards}
                    width={255}
                />
            </SContextSearchWrapper>
        </SContextSearchOverlay>
    );
};
