import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { ApplicationActions } from '@repeat/common-slices';
import { API_BAD_REQUEST_CODE } from '@repeat/constants';
import {
    IValidationError,
    ModalTypes,
    NotificationTypes,
    TTechnicalSupportModal,
    TTechnicalSupportTicket,
} from '@repeat/models';
import { TechnicalSupportService } from '@repeat/services';
import { TranslationKey } from '@repeat/translations';

export const createSupportTicket =
    (
        data: TTechnicalSupportTicket,
        onRequest: () => void,
        onSuccess: () => void,
        onError: (httpStatus: number, errors: IValidationError[] | []) => void
    ) =>
    async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
        onRequest();
        try {
            await TechnicalSupportService.createTicket(data);
            dispatch(ApplicationActions.hideModal({ type: ModalTypes.TECHNICAL_SUPPORT }));
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.SUCCESS,
                        message: TranslationKey.MESSAGE_SENT_SUCCESSFULLY,
                    },
                })
            );
            onSuccess();
        } catch (error: any) {
            const httpStatus: number = error?.response?.status;
            let errors: IValidationError[] | [] = [];
            if (httpStatus === API_BAD_REQUEST_CODE) {
                errors = error?.response.data.errors;
            }
            onError(httpStatus, errors);
        }
    };
export const openTechnicalSupportModal =
    (userName?: string, email?: string) => (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
        const modal: TTechnicalSupportModal = {
            type: ModalTypes.TECHNICAL_SUPPORT,
            data: { userName: userName ?? '', email: email ?? '' },
        };

        dispatch(ApplicationActions.showModal({ modal }));
    };
