import { FC } from 'react';

import styled from 'styled-components';

import { pxToRem } from '../../config/config';

const StyledPage = styled.section`
    min-height: 100%;
    padding: ${pxToRem(48)} 0 0;
`;

const StyledPageTitle = styled.h5`
    font-size: ${pxToRem(32)};
    margin: ${pxToRem(32)} 0;
    padding: 0 ${pxToRem(32)};
`;

const StyledPageBody = styled.main`
    padding: 0 ${pxToRem(32)};
`;

export interface IPage {
    children: React.ReactNode;
    title: boolean | string;
}

export const Page: FC<IPage> = ({ children, title }) => (
    <StyledPage>
        {title && <StyledPageTitle>{title}</StyledPageTitle>}
        <StyledPageBody>{children}</StyledPageBody>
    </StyledPage>
);
