import { FC } from 'react';

import styled from 'styled-components';

import { fontSize, pxToRem } from '../../../config/config';
import { IconButton } from '../../IconButton/IconButton';
import { IModalHeader } from '../interfaces';

const StyledModalHeader = styled.header`
    align-items: center;
    border-bottom: 1px solid var(--ui-table-hover);
    display: flex;
    flex: 0 0 auto;
    font-size: ${fontSize.modalTitle};
    line-height: ${pxToRem(24)};
    justify-content: space-between;
    padding: ${pxToRem(16)};

    button {
        order: 2;
    }

    svg {
        fill: var(--ui-text);
    }
`;

export const ModalHeader: FC<IModalHeader> = ({ children, onClose, hasCloseButton = true }) => {
    return (
        <StyledModalHeader>
            {hasCloseButton && (
                <IconButton
                    noHover
                    name='close'
                    onClick={() => {
                        onClose();
                    }}
                />
            )}
            {children}
        </StyledModalHeader>
    );
};
