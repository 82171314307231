import { isAnyOf, ListenerMiddlewareInstance } from '@reduxjs/toolkit';

import { IChartItemParameters, IChartsDataMap, ModelStatuses, TState } from '@repeat/models';
import { appendChartData, workspaceActions } from '@repeat/store';

export const appendGraphsListeners = (listenerMiddleware: ListenerMiddlewareInstance) => {
    listenerMiddleware.startListening({
        actionCreator: workspaceActions.getProjectSuccess,
        effect: (action, { getState, dispatch }) => {
            const {
                workspace: {
                    graphs: { charts },
                },
            } = getState() as TState;

            const chartsIndexes = Object.keys(charts);

            if (chartsIndexes.length > 0) {
                const dataMap: IChartsDataMap = {};

                chartsIndexes.forEach((index) => {
                    const chartIndex = parseInt(index);
                    const chart = charts[chartIndex];
                    const graphsLastTime = 0;

                    dataMap[chart.uuid] = {
                        index: chartIndex,
                        lateTimeStart: graphsLastTime,
                        isReady: !(graphsLastTime > 0),
                    };
                });

                dispatch(workspaceActions.setChartDataMap({ dataMap }));
            }
        },
    });

    listenerMiddleware.startListening({
        matcher: isAnyOf(workspaceActions.addParametersChart, workspaceActions.addChartWithParameters),
        effect: (action, { getState, dispatch }) => {
            const {
                workspace: {
                    modelControl: { modelStatus },
                    graphs: { charts, dataMap },
                },
            } = getState() as TState;
            const chartParameters: IChartItemParameters = action['payload'] as IChartItemParameters;
            const isEditing = charts[chartParameters.index] ? charts[chartParameters.index].isEdit : true;
            if (
                [ModelStatuses.RUN, ModelStatuses.DATA, ModelStatuses.FREEZE].includes(modelStatus) &&
                !isEditing &&
                chartParameters.uuid &&
                chartParameters.YParameters.length > 0
            ) {
                const chartDataMapItem = dataMap[chartParameters.uuid] || null;
                if (chartDataMapItem) {
                    const projectId = Number(localStorage.getItem('projectId'));
                    dispatch(appendChartData(projectId, chartParameters));
                }
            }
        },
    });

    listenerMiddleware.startListening({
        actionCreator: workspaceActions.setEditChart,
        effect: (action, { getState, dispatch }) => {
            const {
                workspace: {
                    modelControl: { modelStatus },
                    graphs: { charts, dataMap },
                },
            } = getState() as TState;
            const { index } = action.payload;

            const chart = charts[index] ? charts[index] : null;
            if (!chart) {
                return;
            }

            const isEditing = chart.isEdit;
            if (
                [ModelStatuses.RUN, ModelStatuses.DATA, ModelStatuses.FREEZE].includes(modelStatus) &&
                !isEditing &&
                chart.uuid &&
                chart.YParameters.length > 0
            ) {
                const chartDataMapItem = dataMap[chart.uuid] || null;
                if (chartDataMapItem) {
                    const projectId = Number(localStorage.getItem('projectId'));
                    const chartParameters = {
                        index,
                        uuid: chart.uuid,
                        XParameters: chart.XParameters,
                        YParameters: chart.YParameters,
                    } as IChartItemParameters;
                    dispatch(appendChartData(projectId, chartParameters));
                }
            }
        },
    });
};
