import styled from 'styled-components';

import { pxToRem, uiColors } from '@repeat/ui-kit';

export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    position: relative;
    > div {
        margin-bottom: ${pxToRem(16)};
        width: 100%;
    }
    margin-bottom: 8px;
`;

export const SNetworkErrorBlock = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    top: 100%;
    padding: 4px 0;
    font-size: ${pxToRem(12)};
    color: ${uiColors.red};
    margin-bottom: 0 !important;
`;
