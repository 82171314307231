import styled from 'styled-components';

import { hexToRGB } from '@repeat/constants';
import { Icon, Mixins, pxToRem, radius, shadow, uiColors, zIndex } from '@repeat/ui-kit';

import { transitionAnimation } from '../../config/mixin';

export const SWrapperTable = styled.div`
    background-color: white;
    border-radius: ${radius.default};
`;

export const STable = styled.table`
    border: 0;
    border-collapse: collapse;
    border-spacing: ${pxToRem(8)};
    position: relative;
    empty-cells: hide;
    width: 100%;
    font-size: ${pxToRem(14)};
    z-index: ${zIndex.base};
    cursor: default;

    thead {
        background-color: ${hexToRGB(uiColors.blueLight, '0.2')};

        th {
            padding: ${pxToRem(16)} ${pxToRem(8)};
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: ${hexToRGB(uiColors.greyLight, '0.4')};
            }
        }
    }

    th,
    td {
        padding: ${pxToRem(8)};
        vertical-align: middle;
    }

    th {
        text-align: left;
        text-transform: uppercase;
        color: ${uiColors.darkGrey};
        cursor: pointer;
    }

    tr {
        min-height: ${pxToRem(32)};
        border-bottom: 1px solid ${uiColors.borderGreyDark};

        > td {
            color: ${uiColors.darkGrey};
        }

        &:last-child {
            border-bottom: 0;
        }
    }

    tr > td:last-child span {
        i {
            min-height: 24px;
            min-width: 24px;
        }

        menu li {
            i {
                min-height: 20px;
                min-width: 20px;
            }
        }
    }
`;

export const STableRowItem = styled.td<{ isDisabled?: boolean }>`
    &:first-child {
        color: ${({ isDisabled }) => (isDisabled ? `${uiColors.darkGrey}` : `${uiColors.mainBlue}`)};
        cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
    }

    span {
        ${Mixins.verticalAlign()};
        line-height: ${pxToRem(16)};
    }
`;
export interface ITableRowItem {
    onClick?: () => void;
    children: React.ReactNode;
    isDisabled?: boolean;
}

export const SColumnText = styled.span<{ isActive: boolean }>`
    color: ${({ isActive }) => (isActive ? `${uiColors.mainBlue}` : `${uiColors.darkGrey}`)};
    text-decoration: ${({ isActive }) => (isActive ? 'underline' : 'none')};
`;

export const RotatedIcon = styled(Icon)`
    svg {
        transform: rotate(180deg);
    }
`;

export const SSortedBlock = styled.span`
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: ${pxToRem(5)};
    width: ${pxToRem(10)};
    height: ${pxToRem(12)};
    i {
        position: absolute;
    }
    i:first-child {
        bottom: ${pxToRem(4)};
    }

    i:last-child {
        top: ${pxToRem(4)};
    }

    svg {
        width: ${pxToRem(10)};
        height: ${pxToRem(10)};
    }
`;

export const SPagination = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: ${pxToRem(4)} 0;
    margin-top: ${pxToRem(16)};

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        border: 1px solid ${uiColors.darkGrey};
        color: ${uiColors.darkGrey};
        background-color: white;
        box-shadow: ${shadow.controls};
        cursor: pointer;
        ${transitionAnimation(['color', ' border-color'])}

        :disabled {
            cursor: not-allowed;
            border: 1px solid ${uiColors.grey};
            color: ${uiColors.grey};
        }

        :hover:not([disabled]) {
            color: ${uiColors.black};
            border-color: ${uiColors.black};
        }
    }

    div {
        padding: 0 ${pxToRem(4)};
        font-weight: bold;
    }
`;

export const SInformationBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
`;
