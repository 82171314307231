import styled, { css } from 'styled-components';

import MenuItem from '@mui/material/MenuItem';

import { PropertyTypes, TPropertyType } from '@repeat/models';
import { Icon, IconButton, InputField, pxToRem, uiColors } from '@repeat/ui-kit';

export const SIconButton = styled(IconButton)<{ isLabelInput?: boolean }>`
    width: ${pxToRem(14)};
    height: ${pxToRem(14)};

    &:first-child {
        display: ${({ isLabelInput }) => (isLabelInput ? 'block' : 'none')};
    }
`;

export const StyledPropertiesItem = styled.div<{ type?: TPropertyType; isEditable?: boolean }>`
    margin-bottom: ${pxToRem(8)};
    ${({ type }) =>
        type === PropertyTypes.TOGGLE &&
        css`
            display: flex;
            align-items: center;
        `}
    position: ${({ isEditable }) => isEditable && 'relative'};
    label {
        color: ${uiColors.darkGrey};
        font-size: ${pxToRem(12)};
    }
    &:hover {
        ${SIconButton} {
            display: block;
        }
    }
`;

export const SPropertyItemBlock = styled.div<{ isEditable: boolean }>`
    display: flex;
    justify-content: space-between;
    ${({ isEditable }) =>
        isEditable &&
        css`
            margin-right: ${pxToRem(16)};
        `}
`;

export const SInputs = styled.div<{ isEditable?: boolean }>`
    margin-right: ${({ isEditable }) => isEditable && pxToRem(16)};
`;

export const StyledIcon = styled(Icon)`
    width: 14px;
    height: 14px;
`;
export const StyledLabel = styled.label<{ isLabelInput: boolean }>`
    margin-right: ${pxToRem(4)};
    display: ${({ isLabelInput }) => (isLabelInput ? 'none' : 'block')};
`;
export const StyledLabelInput = styled(InputField)`
    margin-right: ${pxToRem(16)};
`;

export const SSelectItem = styled(MenuItem)`
    display: block;
    padding: 0 5px;
    width: 100%;
`;
export const SButtons = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;
