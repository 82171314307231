import { AnyAction, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import { lte } from 'semver';

import { updateSchema_patch_2_5_1, wiresCheckValidConnections } from 'libs/services/src/lib/Schema/patches/2.5.0';

import { ApplicationActions } from '@repeat/common-slices';
import {
    CANVAS,
    LIBRARIES_DEFAULT_LIST,
    calculatePortBlockCoordinates,
    calculatePortsLength,
    countMaxOfInputsAndOutputs,
    extractLetters,
    extractNumbers,
    findOuterGroupsPropertiesToUpdate,
    findParentGroupBlock,
    findPortDetails,
    getHandleName,
    getParentParameterName,
    getPortElementLibraryName,
    setPortsByParameters,
} from '@repeat/constants';
import {
    ElemParams,
    ElemProps,
    ILibraryItem,
    IProjectInfo,
    IWorkspaceState,
    NotificationTypes,
    PortConnectionTypes,
    PortPositions,
    PortTypes,
    Statuses,
    TProxyMap,
    TSchemaConnection,
    TSchemaElementWithParams,
    TSchemaGroup,
    TSchemaHandle,
    TSchemaNode,
    TSubmodelProxyItem,
    WireTypes,
    WorkspaceModes,
} from '@repeat/models';
import { ProjectsService, makeSchemaNode } from '@repeat/services';
import { TranslationKey } from '@repeat/translations';

import { actions } from '../..';
import { increaseUserBlocksCount } from '../helper';

const setParameterByPort = (
    port: TSchemaHandle,
    inputPortLibraryItem: ILibraryItem,
    outputPortLibraryItem: ILibraryItem
) => {
    const parameter = {
        description: '',
        modelName: '',
        name: port.name,
        unit: '',
        value: '',
    };
    const portNumber = extractNumbers(port.name);
    if (port.type === PortTypes.INPUT) {
        return { ...parameter, description: `${inputPortLibraryItem.name}_${portNumber}` };
    }

    return { ...parameter, description: `${outputPortLibraryItem.name}_${portNumber}` };
};

const setProxyMapParameter = (node: TSchemaNode): TSubmodelProxyItem => ({
    internalBlockId: node.data.id,
    internalName: '',
    name: node.data.elemProps.find((prop) => prop.name === 'parentParameter')?.value.toString() || '',
});

export const submodelSlice = {
    getSubmodelItemsRequest: (state: IWorkspaceState) => {
        return {
            ...state,
            schema: {
                ...state.schema,
                getSubmodelsSchemaItems: { ...state.schema.getSubmodelsSchemaItems, status: Statuses.LOADING },
            },
        };
    },

    getSubmodelItemsSuccess: (
        state: IWorkspaceState,
        action: PayloadAction<{
            elementId: string;
            schemaData: {
                elements: TSchemaNode[];
                wires: TSchemaConnection[];
                proxyMap: TProxyMap | null;
                externalInfo: { parameters: ElemParams[] | null; properties: ElemProps[] | null } | null;
            };
        }>
    ) => {
        const {
            elementId,
            schemaData: { elements, wires, proxyMap, externalInfo },
        } = action.payload;
        const { groupId } = state.meta;
        const groups = state.schema.schemaItems.groups || [];

        if (!elementId) {
            return;
        }

        const updateElements = (elementsState: TSchemaNode[]) => {
            return elementsState.map((el) => {
                if (el.id === elementId) {
                    const obj: {
                        [key: string]: {
                            internalElementId: string;
                            internalPropName: string;
                            internalPropValue: string;
                        };
                    } = {};
                    const parentElementProperties = el.data.elemProps.filter((p) => !p.isStatic);

                    parentElementProperties.forEach(
                        (p) =>
                            (obj[p.name] = {
                                internalElementId: p.name.split('-')[1],
                                internalPropName: p.name.split('-')[0],
                                internalPropValue: p.value.toString(),
                            })
                    );

                    const elementsWithUpdatedPropertiesValues = elements.map((element) => {
                        const props = element.data.elemProps.map((prop) => {
                            const key = `${prop.name}-${element.id}`;
                            const parentProperty = obj[key];
                            if (parentProperty && prop.name === parentProperty.internalPropName) {
                                return { ...prop, value: parentProperty.internalPropValue };
                            }
                            return prop;
                        });
                        return { ...element, data: { ...element.data, elemProps: props } };
                    });

                    return {
                        ...el,
                        data: {
                            ...el.data,
                            submodelItems: { elements: elementsWithUpdatedPropertiesValues, wires },
                        },
                    };
                }
                return el;
            });
        };

        if (groupId) {
            const groupsNew = groups.map((group) => {
                if (group.id.toString() === groupId) {
                    const elements = updateElements(group.elements);
                    return { ...group, elements };
                }
                return group;
            });
            return {
                ...state,
                schema: {
                    ...state.schema,
                    schemaItems: { ...state.schema.schemaItems, groups: groupsNew },
                    getSubmodelsSchemaItems: { ...state.schema.getSubmodelsSchemaItems, status: Statuses.SUCCEEDED },
                },
            };
        }

        const elementsNew = updateElements(state.schema.schemaItems.elements);

        return {
            ...state,
            schema: {
                ...state.schema,
                schemaItems: { ...state.schema.schemaItems, elements: elementsNew },
                getSubmodelsSchemaItems: { ...state.schema.getSubmodelsSchemaItems, status: Statuses.SUCCEEDED },
            },
        };
    },

    setBlocksGroup: (
        state: IWorkspaceState,
        action: PayloadAction<{
            elementId: string;
            node: TSchemaNode;
        }>
    ) => {
        const { elementId } = action.payload;

        const updateStateWithNewNode = (workspaceState: IWorkspaceState, node: TSchemaNode) => {
            const { mode, elementId } = workspaceState.meta;
            let userBlocksCount = workspaceState.schema.userBlocksCount || {};
            const blockId = node.data.blockId;
            if (node.data.type === 'userBlock' && blockId) {
                userBlocksCount = increaseUserBlocksCount({ ...userBlocksCount }, [blockId]);
            }
            if (mode === WorkspaceModes.MAIN) {
                return {
                    ...workspaceState,
                    schema: {
                        ...workspaceState.schema,
                        schemaItems: {
                            ...workspaceState.schema.schemaItems,
                            elements: [...workspaceState.schema.schemaItems.elements, node],
                        },
                        userBlocksCount,
                        elementsWithParams: [
                            ...workspaceState.schema.elementsWithParams,
                            {
                                id: node.data.id,
                                blockId: node.data.blockId || null,
                                name: node.data.name,
                                index: node.data.index,
                                elemParams: [...node.data.elemParams],
                            } as TSchemaElementWithParams,
                        ],
                    },
                };
            }
            const groups: TSchemaGroup[] = workspaceState.schema.schemaItems.groups
                ? workspaceState.schema.schemaItems.groups.map((group: TSchemaGroup) => {
                      if (group.id.toString() === elementId) {
                          return { ...group, elements: [...group.elements, node] };
                      }
                      return group;
                  })
                : [];
            return {
                ...workspaceState,
                schema: {
                    ...workspaceState.schema,
                    schemaItems: {
                        ...workspaceState.schema.schemaItems,
                        groups,
                    },
                    userBlocksCount,
                },
            };
        };

        const stateUpdatedWithNewNode = updateStateWithNewNode(state, action.payload.node);

        const groupsState = stateUpdatedWithNewNode.schema.schemaItems.groups || [];
        const portsTypes = stateUpdatedWithNewNode.libraryPortTypes.items;
        const elementsState = stateUpdatedWithNewNode.schema.schemaItems.elements;
        const itemsForSubmodel = stateUpdatedWithNewNode.schema.itemsForSubmodel;
        const libraryItems = stateUpdatedWithNewNode.libraryItems.items;
        const metaElementId = stateUpdatedWithNewNode.meta.elementId;
        const { elements, wires } = itemsForSubmodel;
        const inputPortLibraryItem = libraryItems.find((item) => item.type === 'InPort');
        const outputPortLibraryItem = libraryItems.find((item) => item.type === 'OutPort');
        const groupBlocksInItemsForSubmodelIds = elements.filter((el) => el.data.type === 'group').map((el) => el.id);

        if (!elementId || !inputPortLibraryItem || !outputPortLibraryItem) {
            return;
        }

        const elementsIds = elements.map((el) => el.id);

        const elementsInPorts = elements.filter((el) => el.data.type === 'InPort');
        const elementsOutPorts = elements.filter((el) => el.data.type === 'OutPort');

        const elementsInPortsNames = elementsInPorts.map((el) => getParentParameterName(el));
        const elementsOutPortsNames = elementsOutPorts.map((el) => getParentParameterName(el));

        const elementsPortsNames = elements
            .filter((el) => el.data.type === 'InPort' || el.data.type === 'OutPort')
            .map((el) => getParentParameterName(el));

        const arePortsInElements =
            elements.filter((el) => el.data.type === 'InPort' || el.data.type === 'OutPort').length !== 0;

        const isPortConnected = (wires: TSchemaConnection[], id: string, name: string) => {
            const handlesIds = wires.map((wire) => [wire.sourceHandle, wire.targetHandle]).flat();

            const handleIdWithCurrentElementId = handlesIds.filter((handleId) => handleId.includes(id));
            if (
                handleIdWithCurrentElementId &&
                handleIdWithCurrentElementId.filter((id) => id.includes(name)).length !== 0
            ) {
                return true;
            }
            return false;
        };

        const updateSchemaItems = (schemaElements: TSchemaNode[], schemaWires: TSchemaConnection[]) => {
            const wiresNew = schemaWires.filter(
                (wire) => elementsIds.includes(wire.source) && elementsIds.includes(wire.target)
            ); // в группу попадают только те соединения, у которых начальный и конечный блоки есть в группе. Необязательно выделенные

            const wiresToUpdate = schemaWires.filter((x) => !wiresNew.some((y) => x.id === y.id)); // которые нужно перестроить к блоку Группа

            const internalPortsByElementId: { [key: string]: string[] } = {}; // занятые порты по элементам внутри группы
            wiresNew.forEach((wire) => {
                if (wire.source in internalPortsByElementId) {
                    internalPortsByElementId[wire.source].push(getHandleName(wire.sourceHandle));
                } else {
                    internalPortsByElementId[wire.source] = [getHandleName(wire.sourceHandle)];
                }

                if (wire.target in internalPortsByElementId) {
                    internalPortsByElementId[wire.target].push(getHandleName(wire.targetHandle));
                } else {
                    internalPortsByElementId[wire.target] = [getHandleName(wire.targetHandle)];
                }
            });

            const externalPorts: string[] = []; // свободные порты элементов в группе, для которых надо добавить блоки-порты

            const externalPortsByElementId: { [key: string]: string } = {};
            const newPortsNamesByOldPorts: { [key: string]: string } = {}; // имена блоков-портов по айди портов, как они были до группировки
            const externalPortsConnectionTypesByPortId: { [key: string]: string } = {};

            elements.forEach((el) => {
                el.data.availablePorts.forEach((port) => {
                    if (
                        !(el.id in internalPortsByElementId) ||
                        (el.id in internalPortsByElementId && !internalPortsByElementId[el.id].includes(port.name))
                    ) {
                        externalPorts.push(port.name);
                        const portId = `${el.id}-${extractNumbers(port.name)}-${port.name}`;

                        externalPortsByElementId[portId] = el.id;
                        externalPortsConnectionTypesByPortId[portId] = port.typeConnection;
                    }
                });
            });

            const externalPortsInputNames = externalPorts.filter((p) => p.includes('in'));
            const externalPortsOutputNames = externalPorts.filter((p) => p.includes('out'));

            const externalInputPorts = externalPortsInputNames.map((name, i) => {
                const elementId = Date.now() + i;

                const index = ''; // ???

                const { x, y } = calculatePortBlockCoordinates(itemsForSubmodel.elements, PortTypes.INPUT);

                const newNode = makeSchemaNode(
                    inputPortLibraryItem,
                    {
                        id: elementId,
                        index,
                        position: { x, y: y + 30 * i },
                        nodeType: 'element',
                    },
                    portsTypes
                );
                const {
                    data: { view },
                } = newNode;

                const updatedNewNode = {
                    ...newNode,
                    width: view?.minWidth || CANVAS.ELEMENT_MIN_WIDTH,
                    height: view?.minHeight || CANVAS.ELEMENT_MIN_HEIGHT,
                    rotation: 0,
                };
                const elemProps = updatedNewNode.data.elemProps.map((prop) => {
                    const parentParameterValue = `in_${elementsInPortsNames.length + i + 1}`;

                    if (prop.name === 'parentParameter') {
                        return { ...prop, value: parentParameterValue };
                    }
                    const description = getPortElementLibraryName(libraryItems, 'in');
                    if (prop.name === 'userParameterName') {
                        return { ...prop, value: `${description}_${extractNumbers(parentParameterValue)}` };
                    }
                    return prop;
                });
                const availablePorts = updatedNewNode.data.availablePorts.map((port) => ({
                    ...port,
                    isConnected: true,
                }));
                const updatedNewNodeWithProps = {
                    ...updatedNewNode,
                    data: { ...updatedNewNode.data, elemProps, availablePorts },
                };

                return updatedNewNodeWithProps;
            });

            const externalOutputPorts = externalPortsOutputNames.map((name, i) => {
                const elementId = Date.now() + i + externalInputPorts.length;

                const index = ''; // ???

                const { x, y } = calculatePortBlockCoordinates(itemsForSubmodel.elements, PortTypes.OUTPUT);

                const newNode = makeSchemaNode(
                    outputPortLibraryItem,
                    {
                        id: elementId,
                        index,
                        position: { x, y: y + 30 * i },
                        nodeType: 'element',
                    },
                    portsTypes
                );
                const {
                    data: { view },
                } = newNode;

                const updatedNewNode = {
                    ...newNode,
                    width: view?.minWidth || CANVAS.ELEMENT_MIN_WIDTH,
                    height: view?.minHeight || CANVAS.ELEMENT_MIN_HEIGHT,
                    rotation: 0,
                };
                const elemProps = updatedNewNode.data.elemProps.map((prop) => {
                    const parentParameterValue = `out_${elementsOutPortsNames.length + i + 1}`;
                    if (prop.name === 'parentParameter') {
                        return { ...prop, value: parentParameterValue };
                    }
                    const description = getPortElementLibraryName(libraryItems, 'out');
                    if (prop.name === 'userParameterName') {
                        return { ...prop, value: `${description}_${extractNumbers(parentParameterValue)}` };
                    }
                    return prop;
                });
                const availablePorts = updatedNewNode.data.availablePorts.map((port) => ({
                    ...port,
                    isConnected: true,
                }));

                const updatedNewNodeWithProps = {
                    ...updatedNewNode,
                    data: { ...updatedNewNode.data, elemProps, availablePorts },
                };

                return updatedNewNodeWithProps;
            });
            const wiresCreatedForInputPorts: TSchemaConnection[] = [];
            const wiresCreatedForOutputPorts: TSchemaConnection[] = [];

            const externalInputs = [...externalInputPorts, ...elementsInPorts];
            const externalOutputs = [...externalOutputPorts, ...elementsOutPorts];

            const proxyMapParams = [...externalInputs, ...externalOutputs].map((node) => setProxyMapParameter(node));

            Object.keys(externalPortsByElementId)
                .filter((id) => id.includes('in'))
                .forEach((id, i) => {
                    const data = { id: 0, index: '', type: WireTypes.WIRE, wireParams: [], wireProps: [] };
                    const port = externalInputs[0];

                    wiresCreatedForInputPorts.push({
                        data,
                        id: (Date.now() + Number(port.id)).toString(), // TODO generate unique id without Date.now()
                        source: port.id,
                        sourceHandle: `${port.id}-1-out_1`,
                        target: externalPortsByElementId[id],
                        targetHandle: id,
                        type: 'custom-edge',
                    });
                    const portParentParameter =
                        port.data.elemProps.find((prop) => prop.name === 'parentParameter')?.value.toString() || '';
                    newPortsNamesByOldPorts[id] = portParentParameter;
                    externalInputs.shift();
                });

            Object.keys(externalPortsByElementId)
                .filter((id) => id.includes('out'))
                .forEach((id, i) => {
                    const data = { id: 0, index: '', type: WireTypes.WIRE, wireParams: [], wireProps: [] };
                    const port = externalOutputs[0];
                    wiresCreatedForOutputPorts.push({
                        data,
                        id: (Date.now() + Number(port.id)).toString(), // TODO generate unique id without Date.now()
                        source: port.id,

                        sourceHandle: `${port.id}-1-in_1`,
                        target: externalPortsByElementId[id],
                        targetHandle: id,
                        type: 'custom-edge',
                    });
                    const portParentParameter =
                        port.data.elemProps.find((prop) => prop.name === 'parentParameter')?.value.toString() || '';
                    newPortsNamesByOldPorts[id] = portParentParameter;
                    externalOutputs.shift();
                });

            const updatedWires = wiresToUpdate.map((wire) => {
                if (!elementsIds.includes(wire.source) && elementsIds.includes(wire.target)) {
                    return {
                        ...wire,
                        target: elementId,
                        targetHandle: `${elementId}-${extractNumbers(newPortsNamesByOldPorts[wire.targetHandle])}-${
                            newPortsNamesByOldPorts[wire.targetHandle]
                        }`,
                    };
                }
                if (elementsIds.includes(wire.source) && !elementsIds.includes(wire.target)) {
                    return {
                        ...wire,
                        source: elementId,
                        sourceHandle: `${elementId}-${extractNumbers(newPortsNamesByOldPorts[wire.sourceHandle])}-${
                            newPortsNamesByOldPorts[wire.sourceHandle]
                        }`,
                    };
                }
                return wire;
            });

            const groups: TSchemaGroup[] = stateUpdatedWithNewNode.schema.schemaItems.groups || [];

            let groupsNew: TSchemaGroup[] = [];

            const elementsNew = schemaElements
                .filter((x) => !elements.some((y) => x.id === y.id))
                .map((el) => {
                    if (el.id === elementId) {
                        const inputPorts = [...externalPortsInputNames, ...elementsInPortsNames].map((name, i) => {
                            const isConnected = isPortConnected(
                                updatedWires,
                                elementId,
                                `${extractLetters(name)}_${i + 1}`
                            );
                            const typeConnection = PortConnectionTypes.DEFAULT_INPUT;
                            const { direction, compatibleTypes } = findPortDetails(portsTypes, typeConnection, name);
                            return {
                                name: `${extractLetters(name)}_${i + 1}`,
                                position: PortPositions.LEFT,
                                libraries: LIBRARIES_DEFAULT_LIST,
                                type: PortTypes.INPUT,
                                typeConnection: PortConnectionTypes.DEFAULT_INPUT, // TODO !!! наследовать от блока внутри
                                direction,
                                compatibleTypes,
                                isConnected,
                            };
                        });
                        const outputPorts = [...externalPortsOutputNames, ...elementsOutPortsNames].map((name, i) => {
                            const isConnected = isPortConnected(
                                updatedWires,
                                elementId,
                                `${extractLetters(name)}_${i + 1}`
                            );
                            const typeConnection = PortConnectionTypes.DEFAULT_OUTPUT;
                            const { direction, compatibleTypes } = findPortDetails(portsTypes, typeConnection, name);

                            return {
                                name: `${extractLetters(name)}_${i + 1}`,
                                position: PortPositions.RIGHT,
                                libraries: LIBRARIES_DEFAULT_LIST,
                                type: PortTypes.OUTPUT,
                                typeConnection, // TODO !!! наследовать от блока внутри
                                direction,
                                compatibleTypes,
                                isConnected,
                            };
                        });
                        const ports = [...inputPorts, ...outputPorts];

                        const elemParams = ports.map((p) =>
                            setParameterByPort(p, inputPortLibraryItem, outputPortLibraryItem)
                        );
                        const maxPortsLength = calculatePortsLength(
                            countMaxOfInputsAndOutputs(ports),
                            CANVAS.PORT_MARGIN
                        );
                        const newHeight = Math.max(maxPortsLength, CANVAS.ELEMENT_MIN_HEIGHT);

                        const elementsConnected = elements.map((el) => {
                            const availablePorts = el.data.availablePorts.map((port) => ({
                                ...port,
                                isConnected: true,
                            }));
                            return { ...el, data: { ...el.data, availablePorts } };
                        }); // все порты элементов внутри группы всегда подключены
                        const groupsUpd = groups.map((group) => {
                            if (groupBlocksInItemsForSubmodelIds.includes(group.id.toString())) {
                                return { ...group, parentGroupId: elementId };
                            }
                            return group;
                        });
                        groupsNew = [
                            ...groupsUpd,
                            {
                                id: Number(elementId),
                                elements: [...elementsConnected, ...externalInputPorts, ...externalOutputPorts],
                                wires: [...wiresNew, ...wiresCreatedForInputPorts, ...wiresCreatedForOutputPorts],
                                parentGroupId: metaElementId,
                                name: '',
                            },
                        ];

                        return {
                            ...el,
                            height: newHeight,
                            data: {
                                ...el.data,
                                availablePorts: ports,
                                elemParams,
                                proxyMap: { params: proxyMapParams, props: [] },
                            },
                        };
                    }
                    return el;
                });
            const elementsWithParamsNew = stateUpdatedWithNewNode.schema.elementsWithParams
                .filter((x) => !elements.some((y) => x.id.toString() === y.id))
                .map((el) => {
                    if (el.id.toString() === elementId) {
                        const inputPorts = externalPortsInputNames.map((name, i) => {
                            const typeConnection = PortConnectionTypes.DEFAULT_INPUT;
                            const { direction, compatibleTypes } = findPortDetails(portsTypes, typeConnection, name);
                            return {
                                name: `${extractLetters(name)}_${i + 1}`,
                                position: PortPositions.LEFT,
                                libraries: LIBRARIES_DEFAULT_LIST,
                                type: PortTypes.INPUT,
                                isConnected: false, // TODO !!! не всегда
                                typeConnection,
                                direction,
                                compatibleTypes,
                            };
                        });
                        const outputPorts = externalPortsOutputNames.map((name, i) => {
                            const typeConnection = PortConnectionTypes.DEFAULT_OUTPUT;
                            const { direction, compatibleTypes } = findPortDetails(portsTypes, typeConnection, name);
                            return {
                                name: `${extractLetters(name)}_${i + 1}`,
                                position: PortPositions.RIGHT,
                                libraries: LIBRARIES_DEFAULT_LIST,
                                type: PortTypes.OUTPUT,
                                isConnected: false, // TODO !!! не всегда
                                typeConnection,
                                direction,
                                compatibleTypes,
                            };
                        });
                        const ports = [...inputPorts, ...outputPorts];

                        const elemParams = ports.map((p) =>
                            setParameterByPort(p, inputPortLibraryItem, outputPortLibraryItem)
                        );

                        return {
                            ...el,
                            elemParams,
                        };
                    }
                    return el;
                });
            return { elementsNew, updatedWires, groupsNew, elementsWithParamsNew };
        };

        if (metaElementId === null) {
            // верхний уровень схемы
            const {
                schemaItems: { elements, wires },
                externalInfo,
                proxyMap,
            } = stateUpdatedWithNewNode.schema;
            const { elementsNew, updatedWires, groupsNew, elementsWithParamsNew } = updateSchemaItems(elements, wires);
            const externalInfoUpdated = externalInfo
                ? {
                      ...externalInfo,
                      parameters:
                          externalInfo?.parameters?.filter((param) => !elementsPortsNames.includes(param.name)) || [],
                  }
                : { parameters: [], properties: [] };

            const proxyMapUpdated = proxyMap
                ? { ...proxyMap, params: proxyMap.params.filter((param) => !elementsPortsNames.includes(param.name)) }
                : { params: [], props: [] };
            return {
                ...state,
                schema: {
                    ...stateUpdatedWithNewNode.schema,
                    schemaItems: {
                        ...stateUpdatedWithNewNode.schema.schemaItems,
                        elements: elementsNew,
                        wires: updatedWires,
                        groups: groupsNew,
                    },
                    externalInfo: externalInfoUpdated,
                    proxyMap: proxyMapUpdated,
                    elementsWithParams: elementsWithParamsNew,
                    getSubmodelsSchemaItems: {
                        ...stateUpdatedWithNewNode.schema.getSubmodelsSchemaItems,
                        status: Statuses.SUCCEEDED,
                    },
                    itemsForSubmodel: { elements: [], wires: [] },
                },
            };
        }
        const currentGroup = stateUpdatedWithNewNode.schema.schemaItems.groups?.find(
            (group) => group.id.toString() === metaElementId
        );
        const elementsToUpdate = currentGroup ? currentGroup.elements : [];
        const wiresToUpdate = currentGroup ? currentGroup.wires : [];
        const { elementsNew, updatedWires, groupsNew, elementsWithParamsNew } = updateSchemaItems(
            elementsToUpdate,
            wiresToUpdate
        );

        const groupsResult = groupsNew.map((group) => {
            if (group.id.toString() === metaElementId) {
                return { ...group, elements: elementsNew, wires: updatedWires };
            }
            return group;
        });
        const { propsByElementId, parentIdByGroupId } = findOuterGroupsPropertiesToUpdate(
            itemsForSubmodel.elements,
            metaElementId,
            groupsResult,
            elementsState,
            elementId
        );
        const allPropsToUpdate: { [oldName: string]: string }[] = [];
        Object.values(propsByElementId).forEach((pr) => {
            allPropsToUpdate.push(...pr);
        });

        // ВЫНЕСТИ В ФУНКЦИЮ findOuterGroupsPropertiesToUpdate, чтобы она возвращала уже objForUpd:
        const objForUpd: { [parentId: string]: { elId: string; props: { [old: string]: string }[] } } = {};
        Object.keys(parentIdByGroupId).forEach((groupId) => {
            if (propsByElementId[groupId]) {
                if (parentIdByGroupId[groupId] !== null) {
                    objForUpd[parentIdByGroupId[groupId] || 'MAIN'] = {
                        elId: groupId,
                        props: propsByElementId[groupId],
                    };
                } else {
                    objForUpd['MAIN'] = { elId: groupId, props: propsByElementId[groupId] };
                }
            }
        });
        const currentElementParentGroup = findParentGroupBlock(groupsResult, elementsState, metaElementId);
        const currentProps = currentElementParentGroup ? currentElementParentGroup.data.elemProps : [];

        const submodelElementsIds = itemsForSubmodel.elements.map((el) => el.id);

        const groupsResRes = groupsResult.map((group) => {
            const propsByElementToUpdate = objForUpd[group.id.toString()];

            if (group.id.toString() === metaElementId) {
                const elements = group.elements.map((el) => {
                    if (el.id === elementId) {
                        const props = itemsForSubmodel.elements.map((item) => [...item.data.elemProps]).flat();
                        const propsNames = props.map((prop) => prop.name);

                        const possibleNames = propsNames
                            .map((name) => {
                                return submodelElementsIds.map((id) => {
                                    return `${name}-${id}`;
                                });
                            })
                            .flat();

                        const propsRes = currentProps.filter((prop) =>
                            possibleNames.some((str) => prop.name.includes(str))
                        );
                        return {
                            ...el,
                            data: {
                                ...el.data,
                                elemProps: propsRes,
                            },
                        };
                    }
                    return el;
                });
                return { ...group, elements };
            }
            if (!propsByElementToUpdate) {
                return group;
            }
            // TODO вынести в функцию обновление элементов - т к то же самое нужно на верхнем уровне схемы
            const elements = group.elements.map((el) => {
                if (el.id === elementId) {
                    return el;
                }
                if (el.id === propsByElementToUpdate.elId) {
                    const elemProps = el.data.elemProps.map((prop) => {
                        const foundProp = propsByElementToUpdate.props.find((p) => p[prop.name]);
                        if (foundProp) {
                            return { ...prop, name: Object.values(foundProp)[0] };
                        }
                        return prop;
                    });

                    const proxyMapProps = el.data.proxyMap
                        ? el.data.proxyMap.props.map((prop) => {
                              const foundProp = propsByElementToUpdate.props.find((p) => p[prop.name]);
                              const foundInternalName = allPropsToUpdate.find((p) => p[prop.internalName]);
                              const name = foundProp ? Object.values(foundProp)[0] : '';
                              const nameSplitted = name.split('-');
                              const internalBlockId = Number(nameSplitted[nameSplitted.length - 1]);
                              if (extractNumbers(prop.internalName) === 0 && foundProp) {
                                  return {
                                      ...prop,
                                      name,
                                      internalName: Object.keys(foundProp)[0],
                                      internalBlockId,
                                  };
                              }
                              if (foundProp && foundInternalName) {
                                  return {
                                      ...prop,
                                      name,
                                      internalName: Object.values(foundInternalName)[0],
                                      internalBlockId,
                                  };
                              }
                              return prop;
                          })
                        : [];
                    return {
                        ...el,
                        data: {
                            ...el.data,
                            elemProps,
                            proxyMap: el.data.proxyMap
                                ? { ...el.data.proxyMap, props: proxyMapProps }
                                : { params: [], props: proxyMapProps },
                        },
                    };
                }
                return el;
            });
            return { ...group, elements };
        });

        const elementsNewUpd = elementsState.map((el) => {
            const propsByElementToUpdate = objForUpd['MAIN'];
            if (!propsByElementToUpdate) {
                return el;
            }
            if (el.id === propsByElementToUpdate.elId) {
                const elemProps = el.data.elemProps.map((prop) => {
                    const foundProp = propsByElementToUpdate.props.find((p) => p[prop.name]);

                    if (foundProp) {
                        return {
                            ...prop,
                            name: Object.values(foundProp)[0],
                        };
                    }
                    return prop;
                });

                const proxyMapProps = el.data.proxyMap
                    ? el.data.proxyMap.props.map((prop) => {
                          const foundProp = propsByElementToUpdate.props.find((p) => p[prop.name]);
                          const foundInternalName = allPropsToUpdate.find((p) => p[prop.internalName]);
                          const name = foundProp ? Object.values(foundProp)[0] : '';
                          const nameSplitted = name.split('-');
                          const internalBlockId = Number(nameSplitted[nameSplitted.length - 1]);

                          if (extractNumbers(prop.internalName) === 0 && foundProp) {
                              return {
                                  ...prop,
                                  name,
                                  internalName: Object.keys(foundProp)[0],
                                  internalBlockId,
                              };
                          }

                          if (foundProp && foundInternalName) {
                              return {
                                  ...prop,
                                  name,
                                  internalName: Object.values(foundInternalName)[0],
                                  internalBlockId,
                              };
                          }
                          return prop;
                      })
                    : [];
                return {
                    ...el,
                    data: {
                        ...el.data,
                        elemProps,
                        proxyMap: el.data.proxyMap
                            ? { ...el.data.proxyMap, props: proxyMapProps }
                            : { params: [], props: proxyMapProps },
                    },
                };
            }
            return el;
        });

        return {
            ...state,
            schema: {
                ...stateUpdatedWithNewNode.schema,
                schemaItems: {
                    ...stateUpdatedWithNewNode.schema.schemaItems,
                    elements: elementsNewUpd,
                    groups: groupsResRes,
                },
                getSubmodelsSchemaItems: {
                    ...stateUpdatedWithNewNode.schema.getSubmodelsSchemaItems,
                    status: Statuses.SUCCEEDED,
                },
                itemsForSubmodel: { elements: [], wires: [] },
            },
        };
    },
    setGroupName: (state: IWorkspaceState, { payload }: PayloadAction<{ groupId: number; name: string }>) => {
        const { groupId, name } = payload;
        const elements = state.schema.schemaItems.elements;
        const groupsState = state.schema.schemaItems.groups || [];
        const { elementId } = state.meta;
        const groups = groupsState.map((group) => {
            if (group.id === groupId) {
                return { ...group, name };
            }
            return group;
        });
        const updateElements = (elements: TSchemaNode[]) => {
            return elements.map((el) => {
                if (el.id === groupId.toString()) {
                    return { ...el, data: { ...el.data, name } };
                }
                return el;
            });
        };
        if (elementId === null) {
            return {
                ...state,
                schema: {
                    ...state.schema,
                    schemaItems: { ...state.schema.schemaItems, elements: updateElements(elements), groups },
                },
            };
        } else {
            const group = groups.find((group) => group.id.toString() === elementId);
            if (!group) {
                return;
            }
            const groupsUpdated = groups.map((group) => {
                if (group.id.toString() === elementId) {
                    return { ...group, elements: updateElements(group.elements) };
                }
                return group;
            });
            return {
                ...state,
                schema: {
                    ...state.schema,
                    schemaItems: { ...state.schema.schemaItems, groups: groupsUpdated },
                },
            };
        }
    },

    setGroupPropertyDescription: (
        state: IWorkspaceState,
        { payload }: PayloadAction<{ groupId: number; name: string; description: string }>
    ) => {
        const { groupId, name, description } = payload;
        const groups = state.schema.schemaItems.groups || [];
        const elements = state.schema.schemaItems.elements;
        const { elementId } = state.meta;
        if (elementId === null) {
            const index = elements.findIndex((el) => el.id === groupId.toString());
            if (index !== -1) {
                const prop = elements[index].data.elemProps.find((prop) => prop.name === name);

                if (prop) {
                    prop.description = description;
                }
            }
        } else {
            const group = groups.find((group) => group.id.toString() === elementId);
            if (!group) {
                return;
            }

            const index = group.elements.findIndex((el) => el.id === groupId.toString());
            if (index !== -1) {
                const prop = group.elements[index].data.elemProps.find((prop) => prop.name === name);

                if (prop) {
                    prop.description = description;
                }
            }
        }
    },

    getSubmodelItemsFailed: (state: IWorkspaceState, { payload }: PayloadAction<{ error: string }>) => {
        return {
            ...state,
            schema: {
                ...state.schema,
                getSubmodelsSchemaItems: {
                    ...state.schema.getSubmodelsSchemaItems,
                    status: Statuses.FAILED,
                    error: payload.error,
                },
            },
        };
    },

    getProjectBlockRequest: (state: IWorkspaceState) => {
        return {
            ...state,
            schema: {
                ...state.schema,
                getProjectBlock: { ...state.schema.getProjectBlock, status: Statuses.LOADING },
            },
        };
    },
    getProjectBlockSuccess: (state: IWorkspaceState, { payload }: PayloadAction<IProjectInfo>) => {
        return {
            ...state,
            schema: {
                ...state.schema,
                getProjectBlock: { ...state.schema.getProjectBlock, status: Statuses.SUCCEEDED, project: payload },
            },
        };
    },
    getProjectBlockFailed: (state: IWorkspaceState, { payload }: PayloadAction<{ error: string }>) => {
        return {
            ...state,
            schema: {
                ...state.schema,
                getProjectBlock: { ...state.schema.getProjectBlock, status: Statuses.FAILED, error: payload.error },
            },
        };
    },

    updateProjectBlockSuccess: (
        state: IWorkspaceState,
        action: PayloadAction<{
            elementId: string;
            data: {
                proxyMap: TProxyMap | null;
                externalInfo: { parameters: ElemParams[]; properties: ElemProps[] } | null;
                wires: TSchemaConnection[];
                elements: TSchemaNode[];
            };
            ports: TSchemaHandle[];
            height: number;
            hash: string;
        }>
    ) => {
        const {
            elementId,
            data: { proxyMap, externalInfo },
            ports,
            height,
            hash,
        } = action.payload;

        if (!elementId) {
            return;
        }

        const elemParams = externalInfo ? externalInfo.parameters : [];
        const elemProps = externalInfo ? externalInfo.properties : [];

        const portsByParametersInputs: TSchemaHandle[] = ports.filter((port) => port.type === PortTypes.INPUT);
        const portsByParametersOutputs: TSchemaHandle[] = ports.filter((port) => port.type === PortTypes.OUTPUT);

        const elementsNew = state.schema.schemaItems.elements.map((el) => {
            const oldHash = el.data.hash || '';

            if (el.id === elementId) {
                const diff = el.data.diff
                    ? { ...el.data.diff }
                    : { connectedProject: false, isErrorGettingProject: false };
                const availablePorts = el.data.availablePorts;

                const availablePortsInputs = availablePorts.filter((p) => p.name.includes('in'));
                const availablePortsOutputs = availablePorts.filter((p) => p.name.includes('out'));

                const availablePortsInputsNames = availablePortsInputs.map((p) => p.name);
                const availablePortsOutputsNames = availablePortsOutputs.map((p) => p.name);

                let portsResultInputs = [...availablePortsInputs];
                let portsResultOutputs = [...availablePortsOutputs];

                if (availablePortsInputs.length < portsByParametersInputs.length) {
                    portsByParametersInputs.forEach((port) => {
                        if (!availablePortsInputsNames.includes(port.name)) {
                            portsResultInputs.push(port);
                        }
                    });
                } else if (availablePortsInputs.length > portsByParametersInputs.length) {
                    const portsByParametersInputsNames = portsByParametersInputs.map((p) => p.name);
                    portsResultInputs = portsResultInputs.filter((p) => portsByParametersInputsNames.includes(p.name));
                } else {
                    portsResultInputs = portsByParametersInputs;
                }

                if (availablePortsOutputs.length < portsByParametersOutputs.length) {
                    portsByParametersOutputs.forEach((port) => {
                        if (!availablePortsOutputsNames.includes(port.name)) {
                            portsResultOutputs.push(port);
                        }
                    });
                } else if (availablePortsOutputs.length > portsByParametersOutputs.length) {
                    const portsByParametersOutputsNames = portsByParametersOutputs.map((p) => p.name);
                    portsResultOutputs = portsResultOutputs.filter((p) =>
                        portsByParametersOutputsNames.includes(p.name)
                    );
                } else {
                    portsResultOutputs = portsByParametersOutputs;
                }

                const diffUpd =
                    oldHash !== hash ? { ...diff, connectedProject: true } : { ...diff, connectedProject: false };

                const currentProps = el.data.elemProps;
                const currentPropsNames = currentProps.map((prop) => prop.name);
                const newProps = elemProps.map((prop) => {
                    if (currentPropsNames.includes(prop.name)) {
                        const currentProperty = currentProps.find((p) => p.name === prop.name);
                        if (currentProperty) {
                            return { ...prop, value: currentProperty.value.toString() };
                        }
                    }
                    return prop;
                });

                const elementData = {
                    ...el,
                    height,
                    data: {
                        ...el.data,
                        elemParams,
                        elemProps: [...el.data.elemProps.filter((pr) => pr.isStatic), ...newProps],
                        availablePorts: [...portsResultInputs, ...portsResultOutputs],
                        diff: diffUpd,
                        hash,
                    },
                };
                if (proxyMap) {
                    return { ...elementData, data: { ...elementData.data, proxyMap } };
                }
                return elementData;
            }
            return el;
        });

        const elementsWithParams = state.schema.elementsWithParams.map((el) => {
            if (el.id.toString() === elementId) {
                return { ...el, elemParams: externalInfo?.parameters || [] };
            }
            return el;
        });

        const wiresNew = state.schema.schemaItems.wires.filter((wire) => {
            const sourceElement = elementsNew.find((el) => el.id === wire.source);
            const sourceHandleName = getHandleName(wire.sourceHandle);

            const targetElement = elementsNew.find((el) => el.id === wire.target);
            const targetHandleName = getHandleName(wire.targetHandle);
            return (
                sourceElement?.data.elemParams.find((p) => p.name === sourceHandleName) &&
                targetElement?.data.elemParams.find((p) => p.name === targetHandleName)
            );
        });
        const wiresNewSourceIds = wiresNew.map((wire) => wire.source);
        const wiresNewTargetIds = wiresNew.map((wire) => wire.target);

        const deletedWires = state.schema.schemaItems.wires.filter((x) => !wiresNew.some((y) => x.id === y.id));
        const deletedWiresSourceIds = deletedWires.map((wire) => wire.source);
        const deletedWiresTargetIds = deletedWires.map((wire) => wire.target);
        const elementsUpd = elementsNew.map((el) => {
            if (deletedWiresSourceIds.includes(el.id)) {
                const wire = deletedWires.find((wire) => wire.source === el.id);
                const portName = getHandleName(wire?.sourceHandle || '');
                const ports = el.data.availablePorts.map((port) => {
                    if (port.name === portName) {
                        return { ...port, isConnected: false };
                    }
                    return port;
                });
                return { ...el, data: { ...el.data, availablePorts: ports } };
            }
            if (deletedWiresTargetIds.includes(el.id)) {
                const wire = deletedWires.find((wire) => wire.target === el.id);
                const portName = getHandleName(wire?.targetHandle || '');
                const ports = el.data.availablePorts.map((port) => {
                    if (port.name === portName) {
                        return { ...port, isConnected: false };
                    }
                    return port;
                });
                return { ...el, data: { ...el.data, availablePorts: ports } };
            }
            if (el.data.type === 'project' && wiresNewSourceIds.includes(el.id)) {
                const wire = wiresNew.find((wire) => wire.source === el.id);
                const sourcePortName = getHandleName(wire?.sourceHandle || '');

                const ports = el.data.availablePorts.map((port) => {
                    if (port.name === sourcePortName) {
                        return { ...port, isConnected: true };
                    }
                    return port;
                });
                return { ...el, data: { ...el.data, availablePorts: ports } };
            }

            if (el.data.type === 'project' && wiresNewTargetIds.includes(el.id)) {
                const wire = wiresNew.find((wire) => wire.target === el.id);

                const targetPortName = getHandleName(wire?.targetHandle || '');
                const ports = el.data.availablePorts.map((port) => {
                    if (port.name === targetPortName) {
                        return { ...port, isConnected: true };
                    }
                    return port;
                });
                return { ...el, data: { ...el.data, availablePorts: ports } };
            }

            return el;
        });

        return {
            ...state,
            schema: {
                ...state.schema,
                schemaItems: {
                    ...state.schema.schemaItems,
                    elements: elementsUpd,
                    wires: wiresCheckValidConnections(wiresNew, elementsUpd),
                },
                elementsWithParams,
            },
        };
    },
    updateProjectBlockFailed: (
        state: IWorkspaceState,
        { payload }: PayloadAction<{ error: string; projectsIds: string[] }>
    ) => {
        const elements = state.schema.schemaItems.elements.map((el) => {
            const projectIdValue = el.data.elemProps.find((prop) => prop.name === 'projectId')?.value.toString() || '';

            if (payload.projectsIds.includes(projectIdValue)) {
                const diff = el.data.diff
                    ? { ...el.data.diff }
                    : { connectedProject: false, isErrorGettingProject: false };
                return { ...el, data: { ...el.data, diff: { ...diff, isErrorGettingProject: true } } };
            }
            return el;
        });
        return {
            ...state,
            schema: {
                ...state.schema,
                schemaItems: { ...state.schema.schemaItems, elements },
            },
        };
    },
    getProjectsForProjectBlocksRequest: (state: IWorkspaceState) => {
        return {
            ...state,
            schema: {
                ...state.schema,
                getProjectsForProjectsBlock: {
                    ...state.schema.getProjectsForProjectsBlock,
                    status: Statuses.LOADING,
                },
            },
        };
    },
    getProjectsForProjectBlocksSuccess: (state: IWorkspaceState) => {
        return {
            ...state,
            schema: {
                ...state.schema,
                getProjectsForProjectsBlock: {
                    ...state.schema.getProjectsForProjectsBlock,
                    status: Statuses.SUCCEEDED,
                },
            },
        };
    },
};

export const setSubmodelItems =
    (payload: { projectId: number }) => async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: any) => {
        dispatch(actions.getSubmodelItemsRequest());
        try {
            const response = await ProjectsService.loadProject(payload.projectId);
            const schemaData = response.data.data;

            const state = getState().workspace;
            const elementId = state.schema.selectedItems.elements[0]?.id || state.meta.elementId;

            if (elementId && schemaData) {
                dispatch(actions.getSubmodelItemsSuccess({ elementId, schemaData }));
            }
        } catch (error) {
            const errorKey = TranslationKey.ERROR_UNKNOWN;
            dispatch(actions.getSubmodelItemsFailed({ error: errorKey }));

            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: errorKey,
                    },
                })
            );
        }
    };

export const getProjectBlock =
    (payload: { projectId: number }) => async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
        dispatch(actions.getProjectBlockRequest());
        try {
            const response = await ProjectsService.loadProject(payload.projectId);
            const projectInfo = response.data;
            dispatch(actions.getProjectBlockSuccess(projectInfo));
        } catch (error) {
            const errorKey = TranslationKey.ERROR_UNKNOWN;
            dispatch(actions.getProjectBlockFailed({ error: errorKey }));
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: errorKey,
                    },
                })
            );
        }
    };

const prepareProjectBlock = (
    block: TSchemaNode,
    // meta:
    parameters: {
        data: {
            proxyMap: TProxyMap | null;
            externalInfo: { parameters: ElemParams[]; properties: ElemProps[] } | null;
            modules: { [key: string]: number };
        };
        ports: TSchemaHandle[];
        height: number;
        hash: string;
    }
) => {
    const {
        data: { proxyMap, externalInfo, modules },
        ports,
        height,
        hash,
    } = parameters;

    const elemParams = externalInfo ? externalInfo.parameters : [];
    const elemProps = externalInfo ? externalInfo.properties : [];

    const updateBlock = (block: TSchemaNode) => {
        const blockData = {
            ...block,
            height,
            data: {
                ...block.data,
                elemParams,
                elemProps: [...block.data.elemProps.filter((pr: ElemProps) => pr.isStatic), ...elemProps],
                availablePorts: ports,
                hash,
                modules,
            },
        };
        if (proxyMap) {
            return { ...blockData, data: { ...blockData.data, proxyMap } };
        }
        return blockData;
    };

    return updateBlock(block);
};

export const addProjectBlock =
    (payload: { node: TSchemaNode; elemProps: { [key: string]: string } }) =>
    async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: any) => {
        if (!Object.keys(payload.elemProps).length) {
            return;
        }

        const projectId = payload.elemProps['projectId'] ?? null;
        let projectBlock = payload.node;

        const keys = Object.keys(payload.elemProps);
        const newProps = projectBlock.data.elemProps.map((option: ElemProps) => {
            for (let i = 0; i <= keys.length; i++) {
                if (option.name === keys[i]) {
                    return {
                        ...option,
                        value: payload.elemProps[keys[i]].toString(),
                    };
                }
            }
            return option;
        });

        projectBlock = {
            ...projectBlock,
            data: {
                ...projectBlock.data,
                elemProps: newProps,
            },
        };

        // TODO check get project by 403 code and display message about modules
        return dispatch(getProjectBlock({ projectId: +projectId }))
            .then(() => {
                const state = getState().workspace;
                const project = state.schema.getProjectBlock.project;

                const hash = project.header.hash || '';
                const libraryPortTypes = state.libraryPortTypes.items;
                const parameters = project.data.externalInfo?.parameters;

                const ports = parameters
                    ? setPortsByParameters(parameters, project.data.elements, project.data.wires, libraryPortTypes)
                    : [];
                const maxPortsLength = calculatePortsLength(countMaxOfInputsAndOutputs(ports), CANVAS.PORT_MARGIN);
                const newHeight = Math.max(maxPortsLength, CANVAS.ELEMENT_MIN_HEIGHT);
                if (!project.data) {
                    return;
                }

                projectBlock = prepareProjectBlock(projectBlock, {
                    data: project.data,
                    ports,
                    height: newHeight,
                    hash,
                });

                return dispatch(actions.addNode(projectBlock));
            })
            .catch((e) => {
                throw e;
            });
    };

export const updateProjectBlocks =
    (payload: { projectsIds: string[]; projectBlocksIds: string[] }) =>
    async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: any) => {
        dispatch(actions.getProjectsForProjectBlocksRequest());
        try {
            const ids = Array.from(new Set(payload.projectsIds));

            const response = await ProjectsService.loadProjects(ids);

            const subProjects = (!Array.isArray(response.data) ? [response.data] : response.data) as (IProjectInfo & {
                projectId: string;
            })[];
            const subProjectsIds = subProjects.map((project) => project.projectId.toString());
            const deletedProjects = ids.filter((id) => !subProjectsIds.includes(id));
            const libraryItems = getState().workspace.libraryItems.items;
            const libraryPortTypes = getState().workspace.libraryPortTypes.items;

            if (deletedProjects.length !== 0) {
                const errorKey = TranslationKey.WORKSPACE_ERROR_GETTING_PROJECTS_FOR_PROJECT_BLOCKS;
                dispatch(actions.updateProjectBlockFailed({ error: errorKey, projectsIds: deletedProjects }));
            }

            payload.projectsIds.forEach((id, index) => {
                const projectInfo = subProjects.find((p) => p.projectId.toString() === id);
                if (projectInfo) {
                    const data = projectInfo.data;
                    const elementId = payload.projectBlocksIds[index];
                    const hash = projectInfo.header.hash || '';
                    const parameters = data.externalInfo?.parameters;
                    let updatedSchema = { elements: data.elements, wires: data.wires };
                    const version = projectInfo.header.version;

                    if (version && lte(version, '2.5.1')) {
                        updatedSchema = updateSchema_patch_2_5_1(
                            { elements: data.elements, wires: data.wires, groups: data.groups || [] },
                            libraryItems,
                            libraryPortTypes
                        );
                    }

                    const ports = parameters
                        ? setPortsByParameters(
                              parameters,
                              updatedSchema.elements,
                              updatedSchema.wires,
                              libraryPortTypes
                          )
                        : [];
                    const maxPortsLength = calculatePortsLength(countMaxOfInputsAndOutputs(ports), CANVAS.PORT_MARGIN);
                    const newHeight = Math.max(maxPortsLength, CANVAS.ELEMENT_MIN_HEIGHT);
                    if (elementId && data) {
                        dispatch(
                            actions.updateProjectBlockSuccess({ elementId, data, ports, height: newHeight, hash })
                        );
                    }
                }
            });
        } catch (error) {
            const errorKey = TranslationKey.ERROR_UNKNOWN;
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: errorKey,
                    },
                })
            );
        }
        dispatch(actions.getProjectsForProjectBlocksSuccess());
    };
