import React, { FC, MutableRefObject, useContext, useEffect, useRef, useState } from 'react';

import { IconButton } from '@repeat/ui-kit';

import { ToolsTabsContext } from './ToolsTabsContext';

import { toolsTabType } from '../helpers';

interface IToolsEditableTabName {
    name: string;
    id: string;
    type: toolsTabType;
}

const TAB_NAME_MIN_LENGTH = 3;
const TAB_NAME_MAX_LENGTH = 48;
const TAB_NAME_MULTIPLIER = 0.6;
const TAB_NAME_FONT_SIZE = 14;

export const ToolsEditableTabName: FC<IToolsEditableTabName> = ({ name, id, type }) => {
    const [disabled, setDisabled] = useState(true);
    const [value, setValue] = useState(name);
    const [isValueDifferent, setIsValueDifferent] = useState(false);
    const [isNotUnique, setIsNotUnique] = useState(false);

    const targetRef = useRef() as MutableRefObject<HTMLInputElement>;

    const { toolsTabs, handleSetTabName } = useContext(ToolsTabsContext);

    useEffect(() => {
        setValue(name);
    }, [name]);

    useEffect(() => {
        setIsValueDifferent(name !== value);
    }, [name, value]);

    const handleGetTabWidth = (value: number) => {
        return value * TAB_NAME_MULTIPLIER * TAB_NAME_FONT_SIZE;
    };

    const handleValidateUniqueName = (tabName: string) => {
        const tabNames = toolsTabs.map((tab) => id !== tab.id && tab.name.trim());
        if (tabNames.includes(tabName.trim())) {
            setIsNotUnique(true);
        } else {
            setIsNotUnique(false);
        }
    };

    const handleStartEdit = () => {
        if (type !== toolsTabType.START) {
            setDisabled(false);
        }
    };

    const handleChange = (event: React.ChangeEvent) => {
        if (type !== toolsTabType.START) {
            const target = event.target as HTMLInputElement;
            handleValidateUniqueName(target.value);
            if (target.value.length < TAB_NAME_MAX_LENGTH && target.value.length >= TAB_NAME_MIN_LENGTH) {
                setValue(target.value);
            }
        }
    };

    const handleSaveName = () => {
        if (type !== toolsTabType.START && !isNotUnique) {
            setDisabled(true);
            handleSetTabName(id, value.trim());
        }
    };

    useEffect(() => {
        document.body.addEventListener('click', (event) => {
            if (!targetRef.current) {
                return;
            }
            const target = event.target as HTMLElement;
            if (!target) {
                return;
            }
            if (isNotUnique) {
                return;
            }
            const hasClickedTrigger = targetRef.current.contains(target);
            const closeTarget = targetRef.current;

            if (!hasClickedTrigger && closeTarget && !closeTarget.contains(target)) {
                setDisabled(true);
            }
        });
        return () => {
            document.body.removeEventListener('click', () => setDisabled(true));
        };
    }, []);

    return (
        <div data-edit={!disabled} data-error={isNotUnique}>
            <input
                type='text'
                value={value}
                ref={targetRef}
                readOnly={disabled}
                data-error={isNotUnique}
                onDoubleClick={handleStartEdit}
                style={{ width: `${handleGetTabWidth(value.length)}px` }}
                onChange={(event) => handleChange(event)}
            />
            <IconButton data-diff={isValueDifferent} name={'done'} noHover onClick={handleSaveName} />
        </div>
    );
};
