import { defineMessages } from 'react-intl';

import { ILogFormat } from '@repeat/models';
import { CODE_LIST, ru, TranslationKey } from '@repeat/translations';

const CODE_LIST_FORMAT: ILogFormat = {
    [CODE_LIST[7006]]: {
        id: CODE_LIST[7006],
        defaultMessage: ru[CODE_LIST[7006]],
    },
    [CODE_LIST[7007]]: {
        id: CODE_LIST[7007],
        defaultMessage: ru[CODE_LIST[7007]],
    },
    [CODE_LIST[7008]]: {
        id: CODE_LIST[7008],
        defaultMessage: ru[CODE_LIST[7008]],
    },
    [CODE_LIST[7009]]: {
        id: CODE_LIST[7009],
        defaultMessage: ru[CODE_LIST[7009]],
    },
    [CODE_LIST[7010]]: {
        id: CODE_LIST[7010],
        defaultMessage: ru[CODE_LIST[7010]],
    },
    [CODE_LIST[7011]]: {
        id: CODE_LIST[7011],
        defaultMessage: ru[CODE_LIST[7011]],
    },
    [CODE_LIST[7012]]: {
        id: CODE_LIST[7012],
        defaultMessage: ru[CODE_LIST[7012]],
    },
    [CODE_LIST[7014]]: {
        id: CODE_LIST[7014],
        defaultMessage: ru[CODE_LIST[7014]],
    },
    [CODE_LIST[7015]]: {
        id: CODE_LIST[7015],
        defaultMessage: ru[CODE_LIST[7015]],
    },
    [CODE_LIST[7050]]: {
        id: CODE_LIST[7050],
        defaultMessage: ru[CODE_LIST[7050]],
    },
    [CODE_LIST[7051]]: {
        id: CODE_LIST[7051],
        defaultMessage: ru[CODE_LIST[7051]],
    },
    [CODE_LIST[7052]]: {
        id: CODE_LIST[7052],
        defaultMessage: ru[CODE_LIST[7052]],
    },
    [CODE_LIST[7053]]: {
        id: CODE_LIST[7053],
        defaultMessage: ru[CODE_LIST[7053]],
    },
    [CODE_LIST[7054]]: {
        id: CODE_LIST[7054],
        defaultMessage: ru[CODE_LIST[7054]],
    },
    [CODE_LIST[7055]]: {
        id: CODE_LIST[7055],
        defaultMessage: ru[CODE_LIST[7055]],
    },
    [CODE_LIST[1085]]: {
        id: CODE_LIST[1085],
        defaultMessage: ru[CODE_LIST[1085]],
    },
    [CODE_LIST[1057]]: {
        id: CODE_LIST[1057],
        defaultMessage: ru[CODE_LIST[1057]],
    },
    [CODE_LIST[6001]]: {
        id: CODE_LIST[6001],
        defaultMessage: ru[CODE_LIST[6001]],
    },
    [CODE_LIST[6501]]: {
        id: CODE_LIST[6501],
        defaultMessage: ru[CODE_LIST[6501]],
    },
    [CODE_LIST[1001]]: {
        id: CODE_LIST[1001],
        defaultMessage: ru[CODE_LIST[1001]],
    },
    [CODE_LIST[1002]]: {
        id: CODE_LIST[1002],
        defaultMessage: ru[CODE_LIST[1002]],
    },
    [CODE_LIST[1003]]: {
        id: CODE_LIST[1003],
        defaultMessage: ru[CODE_LIST[1003]],
    },
    [CODE_LIST[1004]]: {
        id: CODE_LIST[1004],
        defaultMessage: ru[CODE_LIST[1004]],
    },
    [CODE_LIST[1005]]: {
        id: CODE_LIST[1005],
        defaultMessage: ru[CODE_LIST[1005]],
    },
    [CODE_LIST[1006]]: {
        id: CODE_LIST[1006],
        defaultMessage: ru[CODE_LIST[1006]],
    },
    [CODE_LIST[1007]]: {
        id: CODE_LIST[1007],
        defaultMessage: ru[CODE_LIST[1007]],
    },
    [CODE_LIST[1008]]: {
        id: CODE_LIST[1008],
        defaultMessage: ru[CODE_LIST[1008]],
    },
    [CODE_LIST[1009]]: {
        id: CODE_LIST[1009],
        defaultMessage: ru[CODE_LIST[1009]],
    },
    [CODE_LIST[1010]]: {
        id: CODE_LIST[1010],
        defaultMessage: ru[CODE_LIST[1010]],
    },
    [CODE_LIST[1011]]: {
        id: CODE_LIST[1011],
        defaultMessage: ru[CODE_LIST[1011]],
    },
    [CODE_LIST[1012]]: {
        id: CODE_LIST[1012],
        defaultMessage: ru[CODE_LIST[1012]],
    },
    [CODE_LIST[1013]]: {
        id: CODE_LIST[1013],
        defaultMessage: ru[CODE_LIST[1013]],
    },
    [CODE_LIST[1014]]: {
        id: CODE_LIST[1014],
        defaultMessage: ru[CODE_LIST[1014]],
    },
    [CODE_LIST[1015]]: {
        id: CODE_LIST[1015],
        defaultMessage: ru[CODE_LIST[1015]],
    },
    [CODE_LIST[1207]]: {
        id: CODE_LIST[1207],
        defaultMessage: ru[CODE_LIST[1207]],
    },
    [CODE_LIST[8002]]: {
        id: CODE_LIST[8002],
        defaultMessage: ru[CODE_LIST[8002]],
    },
};

export const workspaceMessages: ILogFormat = defineMessages({
    [TranslationKey.WORKSPACE_MEMORY_USAGE]: {
        id: TranslationKey.WORKSPACE_MEMORY_USAGE,
        defaultMessage: ru[TranslationKey.WORKSPACE_MEMORY_USAGE],
    },
    [TranslationKey.WORKSPACE_MEMORY_LIMIT]: {
        id: TranslationKey.WORKSPACE_MEMORY_LIMIT,
        defaultMessage: ru[TranslationKey.WORKSPACE_MEMORY_LIMIT],
    },
    [TranslationKey.WORKSPACE_PAGE_VISUALIZATION]: {
        id: TranslationKey.WORKSPACE_PAGE_VISUALIZATION,
        defaultMessage: ru[TranslationKey.WORKSPACE_PAGE_VISUALIZATION],
    },
    [TranslationKey.WORKSPACE_PAGE_VARIABLES]: {
        id: TranslationKey.WORKSPACE_PAGE_VARIABLES,
        defaultMessage: ru[TranslationKey.WORKSPACE_PAGE_VARIABLES],
    },
    [TranslationKey.WORKSPACE_PAGE_PRESENTATION]: {
        id: TranslationKey.WORKSPACE_PAGE_PRESENTATION,
        defaultMessage: ru[TranslationKey.WORKSPACE_PAGE_PRESENTATION],
    },
    [TranslationKey.WORKSPACE_PAGE_JOURNAL]: {
        id: TranslationKey.WORKSPACE_PAGE_JOURNAL,
        defaultMessage: ru[TranslationKey.WORKSPACE_PAGE_JOURNAL],
    },
    [TranslationKey.WORKSPACE_PAGE_SAVE]: {
        id: TranslationKey.WORKSPACE_PAGE_SAVE,
        defaultMessage: ru[TranslationKey.WORKSPACE_PAGE_SAVE],
    },
    [TranslationKey.WORKSPACE_UNDO]: {
        id: TranslationKey.WORKSPACE_UNDO,
        defaultMessage: ru[TranslationKey.WORKSPACE_UNDO],
    },
    [TranslationKey.WORKSPACE_REDO]: {
        id: TranslationKey.WORKSPACE_REDO,
        defaultMessage: ru[TranslationKey.WORKSPACE_REDO],
    },
    [TranslationKey.LEAVE_PAGE_MESSAGE]: {
        id: TranslationKey.LEAVE_PAGE_MESSAGE,
        defaultMessage: ru[TranslationKey.LEAVE_PAGE_MESSAGE],
    },
    [TranslationKey.DELETE]: {
        id: TranslationKey.DELETE,
        defaultMessage: ru[TranslationKey.DELETE],
    },
    [TranslationKey.WORKSPACE_PAGE_TOOLS]: {
        id: TranslationKey.WORKSPACE_PAGE_TOOLS,
        defaultMessage: ru[TranslationKey.WORKSPACE_PAGE_TOOLS],
    },
    [TranslationKey.BASE_PERMISSION]: {
        id: TranslationKey.BASE_PERMISSION,
        defaultMessage: ru[TranslationKey.BASE_PERMISSION],
    },
    [TranslationKey.PNEUMO_PERMISSION]: {
        id: TranslationKey.PNEUMO_PERMISSION,
        defaultMessage: ru[TranslationKey.PNEUMO_PERMISSION],
    },
    [TranslationKey.HEATHYDRO_PERMISSION]: {
        id: TranslationKey.HEATHYDRO_PERMISSION,
        defaultMessage: ru[TranslationKey.HEATHYDRO_PERMISSION],
    },
    [TranslationKey.CODEGENERATING_PERMISSION]: {
        id: TranslationKey.CODEGENERATING_PERMISSION,
        defaultMessage: ru[TranslationKey.CODEGENERATING_PERMISSION],
    },
    [TranslationKey.ELECTRODYNAMICS_PERMISSION]: {
        id: TranslationKey.ELECTRODYNAMICS_PERMISSION,
        defaultMessage: ru[TranslationKey.ELECTRODYNAMICS_PERMISSION],
    },
    [TranslationKey.MECHANICS]: {
        id: TranslationKey.MECHANICS,
        defaultMessage: ru[TranslationKey.MECHANICS],
    },
    [TranslationKey.ISOTHERMAL_HYDRAULICS]: {
        id: TranslationKey.ISOTHERMAL_HYDRAULICS,
        defaultMessage: ru[TranslationKey.ISOTHERMAL_HYDRAULICS],
    },
    [TranslationKey.WORKSPACE_INVOLVED_MODULES]: {
        id: TranslationKey.WORKSPACE_INVOLVED_MODULES,
        defaultMessage: ru[TranslationKey.WORKSPACE_INVOLVED_MODULES],
    },
    [TranslationKey.WORKSPACE_LIVE_PERMISSION_MODULES]: {
        id: TranslationKey.WORKSPACE_LIVE_PERMISSION_MODULES,
        defaultMessage: ru[TranslationKey.WORKSPACE_LIVE_PERMISSION_MODULES],
    },
    ...CODE_LIST_FORMAT,
});
