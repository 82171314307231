import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { IFileManagerFilesAndFoldersResponse, IFileManagerState, Project, SolverTypes, Statuses } from '@repeat/models';
import { ProjectsService } from '@repeat/services';
import { TranslationKey } from '@repeat/translations';
import { EFileManagerItemType, TOnMovePayload, type TFileManagerElement } from '@repeat/ui-kit';

import { actions as projectActions } from './projectsSlice';

import { AppDispatch, RootStateFn } from '../store';

export const initialState: IFileManagerState = {
    elements: [],
    status: Statuses.IDLE,
    error: null,
};

const reducers = {
    getFilesAndFoldersRequest: () => ({
        ...initialState,
        status: Statuses.LOADING,
    }),
    getFilesAndFoldersSuccess: (state: IFileManagerState, action: PayloadAction<{ data: TFileManagerElement[] }>) => ({
        ...state,
        status: Statuses.SUCCEEDED,
        error: null,
        elements: action.payload.data,
    }),
    getFilesAndFoldersFailed: (state: IFileManagerState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        status: Statuses.FAILED,
        error: action.payload.error,
    }),
};

const fileManagerSlice = createSlice({
    name: 'fileManager',
    initialState,
    reducers,
});

export const { actions, reducer } = fileManagerSlice;

const prepareFileManagerElements = (data: IFileManagerFilesAndFoldersResponse) => {
    const folders = data?.folders || [];
    const projects = data?.projects || data?.examples || [];
    const elements = [...folders, ...projects];

    const newElements = elements.map((element: any) => ({
        name:
            element?.projectName && typeof element?.projectName === 'string'
                ? element?.projectName
                : '' || element.name,
        id: element.id || element?.projectId || element?.exampleId,
        type: element?.projectId || element?.exampleId ? EFileManagerItemType.FILE : EFileManagerItemType.DIR,
        uuid: uuidv4(),
        updatedAt: element.updatedAt,
        createdAt: element.createdAt,
        isDisabled: element?.projectName ? element.solverType === SolverTypes.USDS : false,
        isEdit: false,
    }));

    return newElements as TFileManagerElement[];
};

export const getFilesAndFolders = (folderId: number) => async (dispatch: AppDispatch) => {
    dispatch(actions.getFilesAndFoldersRequest());

    try {
        const response = await ProjectsService.getFilesAndFolders({ folderId });
        const preparedData = prepareFileManagerElements(response.data);
        dispatch(actions.getFilesAndFoldersSuccess({ data: preparedData }));
        return preparedData;
    } catch (e: any) {
        dispatch(actions.getFilesAndFoldersFailed({ error: e?.data?.error }));

        return null;
    }
};

export const getFavoritesFilesAndFolders = () => async (dispatch: AppDispatch, getState: RootStateFn) => {

    dispatch(actions.getFilesAndFoldersRequest());

    const status = getState().projects.favProjects.status;

    if (status === Statuses.LOADING) {
        return;
    }

    dispatch(projectActions.getFavProjectsRequest());

    try {
        const response = await ProjectsService.getFavProjects();

        const dataValues = {
            projects: response.data as Project[],
            examples: [],
            folders: [],
        };
        const preparedData = prepareFileManagerElements(dataValues);

        dispatch(actions.getFilesAndFoldersSuccess({ data: preparedData }));
        dispatch(projectActions.getFavProjectsSuccess(response.data));

        return preparedData;
    } catch (e: any) {
        const errorKey = TranslationKey.ERROR_UNKNOWN;
        dispatch(actions.getFilesAndFoldersFailed({ error: e?.data?.error }));
        dispatch(projectActions.getFavProjectsFailed({ error: errorKey }));
        return null;
    }
};

export const getDemoFilesAndFolders = (folderId: number) => async (dispatch: AppDispatch) => {
    dispatch(actions.getFilesAndFoldersRequest());

    try {
        const response = await ProjectsService.getDemoFilesAndFolders({ folderId });
        const preparedData = prepareFileManagerElements(response.data);
        dispatch(actions.getFilesAndFoldersSuccess({ data: preparedData }));
        return preparedData;
    } catch (e: any) {
        dispatch(actions.getFilesAndFoldersFailed({ error: e?.data?.error }));

        return null;
    }
};

export const createFolder = (folderId: number, name: string) => async (dispatch: AppDispatch) => {
    try {
        await ProjectsService.createFolder({ folderId, name });
    } catch (e) {
        // eslint-disable-next-line
        console.log(e);
    }
};

export const createDemoFolder = (folderId: number, name: string) => async (dispatch: AppDispatch) => {
    try {
        await ProjectsService.createDemoFolder({ folderId, name });
    } catch (e) {
        // eslint-disable-next-line
        console.log(e);
    }
};

export const editFolder = (folderId: number, name: string) => async (dispatch: AppDispatch) => {
    try {
        await ProjectsService.editFolder({ folderId, name });
    } catch (e) {
        // eslint-disable-next-line
        console.log(e);
    }
};

export const editDemoFolder = (folderId: number, name: string) => async (dispatch: AppDispatch) => {
    try {
        await ProjectsService.editDemoFolder({ folderId, name });
    } catch (e) {
        // eslint-disable-next-line
        console.log(e);
    }
};

export const moveFileAndFolder = (payload: TOnMovePayload) => async (dispatch: AppDispatch) => {
    try {
        const { files, newFolderId, folders } = payload;
        await ProjectsService.moveFileAndFolder({ newFolderId, folders, ...(files && { projects: files }) });
    } catch (e) {
        // eslint-disable-next-line
        console.log(e);
    }
};

export const moveDemoFileAndFolder = (payload: TOnMovePayload) => async (dispatch: AppDispatch) => {
    try {
        const { files, newFolderId, folders } = payload;
        await ProjectsService.moveDemoFileAndFolder({ newFolderId, folders, ...(files && { examples: files }) });
    } catch (e) {
        // eslint-disable-next-line
        console.log(e);
    }
};

export const deleteFolder = (elements: TFileManagerElement[]) => async (dispatch: AppDispatch) => {
    try {
        const folderIds = elements
            .filter((element) => element.type === EFileManagerItemType.DIR)
            .map((element) => element?.id as number);
        const projectIds = elements
            .filter((element) => element.type === EFileManagerItemType.FILE)
            .map((element) => element?.id as number);
        await ProjectsService.deleteFolder({ folderIds, projectIds });
    } catch (e) {
        // eslint-disable-next-line
        console.log(e);
    }
};

export const deleteDemoFolder = (elements: TFileManagerElement[]) => async (dispatch: AppDispatch) => {
    try {
        const folderIds = elements
            .filter((element) => element.type === EFileManagerItemType.DIR)
            .map((element) => element?.id as number);
        const exampleIds = elements
            .filter((element) => element.type === EFileManagerItemType.FILE)
            .map((element) => element?.id as number);
        await ProjectsService.deleteDemoFolder({ folderIds, exampleIds });
    } catch (e) {
        // eslint-disable-next-line
        console.log(e);
    }
};
