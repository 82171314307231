import { TranslationKey } from '@repeat/translations';

import { ToolsCircuitContent } from './ToolsContent/ToolsCircuitContent';
import { ToolsHarmonicContent } from './ToolsContent/ToolsHarmonicContent';
import { ToolsStartContent } from './ToolsContent/ToolsStartContent';

export enum toolsType {
    COMMON = 'COMMON',
    ELECTRIC = 'ELECTROCITY',
}

export enum toolsTabType {
    START = 'START',
    CIRCUIT = 'CIRCUIT',
    HARMONIC = 'HARMONIC',
}

export enum toolsId {
    NOTEBOOK = 'NOTEBOOK',
    NOTEBOOK_WITH_CORE = 'NOTEBOOK_WITH_CORE',
    CIRCUIT = 'CIRCUIT',
    HARMONIC = 'HARMONIC',
}

export enum toolsIcons {
    NOTEBOOK = 'bookClosed',
    CIRCUIT = 'voltage',
    HARMONIC = 'harmonic',
    START = 'browse',
}

export const toolsTabContent = {
    START: <ToolsStartContent />,
    CIRCUIT: <ToolsCircuitContent />,
    HARMONIC: <ToolsHarmonicContent />,
};

export const toolsTabNames = {
    START: TranslationKey.WORKSPACE_PAGE_TOOLS,
    CIRCUIT: TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL,
    HARMONIC: TranslationKey.WORKSPACE_HARMONIC_TOOL,
};
