import { AxiosResponse } from 'axios';

import { TApiKey } from '@repeat/models';

import { $api } from './http';

type TReturnApiKeysContract = TApiKey[];
interface IGetApiKeys {
    (signal?: AbortSignal): Promise<AxiosResponse<TReturnApiKeysContract>>;
}

type TReturnDeleteApiKeyContract = {
    result: string;
};
interface IDeleteApiKeyPayload {
    apiKey: string;
}
interface IDeleteApiKey {
    (payload: IDeleteApiKeyPayload, signal?: AbortSignal): Promise<AxiosResponse<TReturnDeleteApiKeyContract>>;
}

type TReturnCreateApiKeyContract = TApiKey;
interface ICreateApiKeyPayload {
    name: string;
    expireAt: string | null;
}
interface ICreateApiKey {
    (payload: ICreateApiKeyPayload, signal?: AbortSignal): Promise<AxiosResponse<TReturnCreateApiKeyContract>>;
}

export default class ApiKeysService {
    static getApiKeys: IGetApiKeys = async (signal?: AbortSignal) => {
        return await $api.get('/api/v1/key', { signal });
    };

    static createApiKey: ICreateApiKey = async (payload: ICreateApiKeyPayload, signal?: AbortSignal) => {
        return await $api.post('/api/v1/key', { ...payload, signal });
    };

    static deleteApiKey: IDeleteApiKey = async (payload: IDeleteApiKeyPayload, signal?: AbortSignal) => {
        return await $api.delete(`/api/v1/key/${payload.apiKey}`, { signal });
    };
}
