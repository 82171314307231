import React from 'react';
import { useIntl } from 'react-intl';

import { ApplicationActions } from '@repeat/common-slices';
import { useAppDispatch, useAppSelector, useGetTariffData } from '@repeat/hooks';
import { ModalTypes, TOpenBusyProjectModal } from '@repeat/models';
import { CODE_LIST, TranslationKey } from '@repeat/translations';
import { Button, Modal, uiColors } from '@repeat/ui-kit';

import { SOpenBusyProjectList } from './SModals';
import { openBusyProjectModalMessages } from './translation';

export const OpenBusyProjectModal = () => {
    const { formatMessage } = useIntl();
    const dispatch = useAppDispatch();
    const modal = useAppSelector((state) =>
        state.app.modals.find((modal) => modal.type === ModalTypes.OPEN_BUSY_PROJECT_MODAL)
    ) as TOpenBusyProjectModal;

    const { userModules } = useGetTariffData();

    const handleClose = () => {
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.OPEN_BUSY_PROJECT_MODAL }));
    };

    const FooterButtons = () => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={handleClose}>{formatMessage(openBusyProjectModalMessages[TranslationKey.CLOSE])}</Button>
        </div>
    );

    return (
        <Modal
            id={ModalTypes.MODAL_SESSION_CONNECT}
            title={formatMessage(openBusyProjectModalMessages[TranslationKey.PROJECT_OPEN_ERROR])}
            isOpen={true}
            hasOverlay={true}
            onClose={handleClose}
            type='default'
            size='small'
            footer={<FooterButtons />}
            hasCloseButton={false}
        >
            {modal?.data && <p>{formatMessage({ id: CODE_LIST[modal.data.code] })}</p>}
            {modal?.data && (
                <SOpenBusyProjectList>
                    <li>
                        {formatMessage(openBusyProjectModalMessages[TranslationKey.PROJECT_BUSY_MODULES])}
                        :&nbsp;
                    </li>
                    {modal.data.modules.map((module) => (
                        <li key={`busy-module-${module}`} style={{ color: uiColors.orange }}>
                            {formatMessage({ id: `${userModules(module)}` })}
                        </li>
                    ))}
                </SOpenBusyProjectList>
            )}
        </Modal>
    );
};
