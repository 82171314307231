import { FC } from 'react';
import { useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { Redo, Undo, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { IconButton, Tooltip, uiColors } from '@repeat/ui-kit';

import { workspaceMessages } from '../../../pages/WorkspacePage/translation';

export const UndoRedoButtons: FC = () => {
    const { formatMessage } = useIntl();
    const dispatch = useAppDispatch();
    const undoRedo = useAppSelector(workspaceSelectors.undoRedo);
    const undoHandler = () => dispatch(Undo());
    const redoHandler = () => dispatch(Redo());

    return (
        <>
            <Tooltip text={formatMessage(workspaceMessages[TranslationKey.WORKSPACE_UNDO])}>
                <IconButton
                    fill={uiColors.darkGrey}
                    noHover
                    name='undo'
                    onClick={undoHandler}
                    disabled={!undoRedo.canUndo}
                />
            </Tooltip>
            <Tooltip text={formatMessage(workspaceMessages[TranslationKey.WORKSPACE_REDO])}>
                <IconButton
                    fill={uiColors.darkGrey}
                    noHover
                    name='redo'
                    onClick={redoHandler}
                    disabled={!undoRedo.canRedo}
                />
            </Tooltip>
        </>
    );
};
