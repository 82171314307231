import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.TOUR_ITEM]: {
        id: TranslationKey.TOUR_ITEM,
        defaultMessage: ru[TranslationKey.TOUR_ITEM],
    },
    [TranslationKey.CONTACT_SUPPORT]: {
        id: TranslationKey.CONTACT_SUPPORT,
        defaultMessage: ru[TranslationKey.CONTACT_SUPPORT],
    },
    [TranslationKey.SETTINGS_MENU_ITEM]: {
        id: TranslationKey.SETTINGS_MENU_ITEM,
        defaultMessage: ru[TranslationKey.SETTINGS_MENU_ITEM],
    },
    [TranslationKey.API_KEYS_MENU_ITEM]: {
        id: TranslationKey.API_KEYS_MENU_ITEM,
        defaultMessage: ru[TranslationKey.API_KEYS_MENU_ITEM],
    },
    [TranslationKey.EXIT_MENU_ITEM]: {
        id: TranslationKey.EXIT_MENU_ITEM,
        defaultMessage: ru[TranslationKey.EXIT_MENU_ITEM],
    },
    [TranslationKey.LOG_OUT_MESSAGE]: {
        id: TranslationKey.LOG_OUT_MESSAGE,
        defaultMessage: ru[TranslationKey.LOG_OUT_MESSAGE],
    },
    [TranslationKey.PAGE_HELP_TITLE]: {
        id: TranslationKey.PAGE_HELP_TITLE,
        defaultMessage: ru[TranslationKey.PAGE_HELP_TITLE],
    },
    [TranslationKey.ENGINEER_MODE_TITLE]: {
        id: TranslationKey.ENGINEER_MODE_TITLE,
        defaultMessage: ru[TranslationKey.ENGINEER_MODE_TITLE],
    },
    [TranslationKey.PAGE_VERSIONS_HISTORY_TITLE]: {
        id: TranslationKey.PAGE_VERSIONS_HISTORY_TITLE,
        defaultMessage: ru[TranslationKey.PAGE_VERSIONS_HISTORY_TITLE],
    },
});
