import React from 'react';
import { useIntl } from 'react-intl';

import { sanitizeHTML } from '@repeat/constants';
import { IVersionData } from '@repeat/models';
import { TranslationKey } from '@repeat/translations';
import { Badge, uiColors } from '@repeat/ui-kit';

import { ReleaseBlock, ReleaseBlockContent, ReleaseContainer, ReleaseHeader, ReleaseSubheader } from './SComponents';
import { messages } from './translation';

export enum ReleaseNotesBlocksTypes {
    SUMMARY = 'summary',
    NEW = 'new',
    IMPROVEMENTS = 'improvements',
    FIXES = 'fixes',
    ADDITIONAL = 'additional',
}

type TReleasesProps = Pick<IVersionData, 'code' | 'version' | 'release_date' | 'content'> & { isModal?: boolean };

export const ReleaseItem: React.FC<TReleasesProps> = ({ code, version, release_date, content, isModal }) => {
    const intl = useIntl();

    const defineTagConfig = (key: string) => {
        switch (key) {
            case ReleaseNotesBlocksTypes.NEW:
                return {
                    text: intl.formatMessage(messages[TranslationKey.HELP_BLOCK_NEW]),
                    color: uiColors.green,
                };
            case ReleaseNotesBlocksTypes.IMPROVEMENTS:
                return {
                    text: intl.formatMessage(messages[TranslationKey.HELP_BLOCK_IMPROVEMENTS]),
                    color: uiColors.seaWave,
                };
            case ReleaseNotesBlocksTypes.FIXES:
                return {
                    text: intl.formatMessage(messages[TranslationKey.HELP_BLOCK_FIXES]),
                    color: uiColors.orange,
                };
            case ReleaseNotesBlocksTypes.ADDITIONAL:
                return {
                    text: intl.formatMessage(messages[TranslationKey.HELP_BLOCK_ADDITIONAL]),
                    color: uiColors.mainBlue,
                };
            default:
                return { text: '', color: '' };
        }
    };
    return (
        <ReleaseContainer isModal={isModal} id={code}>
            <ReleaseHeader>{version}</ReleaseHeader>
            {release_date && <ReleaseSubheader>{release_date}</ReleaseSubheader>}

            {content.map(({ block, value }) => {
                const { text, color } = defineTagConfig(block);
                const isSummary = block === ReleaseNotesBlocksTypes.SUMMARY;
                return (
                    <ReleaseBlock key={block}>
                        {!isSummary && <Badge text={text} color={color} size='small' />}
                        <ReleaseBlockContent dangerouslySetInnerHTML={sanitizeHTML(value)} />
                    </ReleaseBlock>
                );
            })}
        </ReleaseContainer>
    );
};
