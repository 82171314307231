import React, { createContext, FC, useCallback, useEffect, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { toolsTabType } from '../helpers';

export interface ITab {
    id: string;
    name: string;
    type: toolsTabType;
    content: React.ReactNode;
    icon: string;
    active?: boolean;
    data?: any;
}

export interface IToolsTabsProvider {
    children: React.ReactNode;
    tabs: ITab[];
    defaultTab: ITab;
    onSave: (tabs: ITab[]) => void | undefined;
}

export interface IToolsTabsContext {
    toolsTabs: ITab[];
    activeTab: ITab | undefined;
    handleTabActive: (id: string) => void;
    handleTabAdd: (callback: any) => void;
    handleTabClose: (id: string) => void;
    handleTabOpen: (args: Pick<ITab, 'id' | 'type'>) => void;
    handleGetTabById: (id: string) => ITab | undefined | null;
    handleSetTabData: (id: string, data: Record<string, string>) => void;
    handleSetTabName: (id: string, name: string) => void;
}

export const ToolsTabsContext = createContext<IToolsTabsContext>({
    toolsTabs: [],
    activeTab: undefined,
    handleTabAdd: (callback: any) => null,
    handleTabClose: (id: string) => null,
    handleTabActive: () => null,
    handleTabOpen: (args: Pick<ITab, 'id' | 'type'>) => null,
    handleGetTabById: (id: string) => null,
    handleSetTabData: (id: string, data: Record<string, string>) => null,
    handleSetTabName: (id: string, name: string) => null,
});

export const ToolsTabsProvider: FC<IToolsTabsProvider> = ({ children, tabs, defaultTab, onSave = undefined }) => {
    const defaultAddTimeOut = 300;

    const [toolsTabs, setToolsTabs] = useState<ITab[]>(() => {
        if (tabs.length > 0) {
            return tabs.map((tab, index) => ({
                ...tab,
            }));
        } else {
            return [{ ...defaultTab, id: uuidv4(), active: true }];
        }
    });

    const handleTabActive = useCallback(
        (id: string) => {
            const activeTab = toolsTabs.find((tab) => tab.id === id);
            if (activeTab && !activeTab.active) {
                const updatedTabs: ITab[] = toolsTabs.map((tab) => ({ ...tab, active: false }));
                setToolsTabs(updatedTabs.map((tab) => ({ ...tab, active: tab.id === id })));
            }
        },
        [toolsTabs]
    );

    const handleTabAdd = useCallback(
        (callback: any) => {
            const updatedTabs: ITab[] = toolsTabs.map((tab) => ({ ...tab, active: false }));
            const newTabs = [...updatedTabs, { ...defaultTab, id: uuidv4(), active: true }];
            setToolsTabs(newTabs);
            setTimeout(() => callback(newTabs), defaultAddTimeOut);
        },
        [toolsTabs]
    );

    const handleTabOpen = useCallback(
        (newTab: ITab) => {
            const updatedTabs = toolsTabs.map((tab) => (newTab.id === tab.id ? { ...newTab } : tab));
            setToolsTabs(updatedTabs);
        },
        [toolsTabs]
    );

    const handleTabClose = useCallback(
        (id: string) => {
            if (toolsTabs.length > 1) {
                const updatedTabs: ITab[] = toolsTabs.filter((tab) => tab.id !== id);
                setToolsTabs(
                    updatedTabs.map((tab, index) => {
                        return { ...tab, ...(index === updatedTabs.length - 1 && { active: true }) };
                    })
                );
            }
        },
        [toolsTabs]
    );

    const handleGetTabById = useCallback(
        (id: string) => {
            return toolsTabs.find((tab) => tab.id === id);
        },
        [toolsTabs]
    );

    const handleSetTabData = useCallback(
        (id: string, data: Record<string, string>) => {
            const newTabs = toolsTabs.map((tab) => {
                return { ...tab, ...(tab.id === id && { data }) };
            });
            setToolsTabs(newTabs);
        },
        [toolsTabs]
    );

    const handleSetTabName = useCallback(
        (id: string, name: string) => {
            const newTabs = toolsTabs.map((tab) => {
                return { ...tab, ...(tab.id === id && { name }) };
            });
            setToolsTabs(newTabs);
        },
        [toolsTabs]
    );

    useEffect(() => {
        onSave && onSave(toolsTabs);
    }, [toolsTabs]);

    const providerValues: IToolsTabsContext = {
        toolsTabs,
        activeTab: toolsTabs.find((tab) => tab.active),
        handleTabActive,
        handleTabOpen,
        handleGetTabById,
        handleTabAdd,
        handleTabClose,
        handleSetTabData,
        handleSetTabName,
    };

    return <ToolsTabsContext.Provider value={providerValues}>{children}</ToolsTabsContext.Provider>;
};
