import { Action } from 'redux';

import { User } from '@repeat/models';

export enum AdminTypes {
    SET_USERS = 'SET_USERS',
}

export interface AdminAction extends Action<AdminTypes> {
    type: AdminTypes.SET_USERS;
    payload: User[];
}
