import styled from 'styled-components';

import { pxToRem, uiColors } from '@repeat/ui-kit';

export const SStatusWrapper = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr;
    margin: ${pxToRem(16)} 0;
    font-size: 13px;
`;
export const SStatusItem = styled.div`
    display: grid;
    grid-template-columns: 120px 1fr;
    grid-column-gap: ${pxToRem(8)};
`;
export const SStatusItemLabel = styled.div``;
export const SStatusItemProgress = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr;
    align-items: end;
`;
export const SStatusItemProgressValue = styled.div`
    position: relative;
    top: -22px;
    font-size: 13px;
    display: block;
    text-align: center;
    color: ${uiColors.white};
    button {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin-top: 40px;
    }
`;
