import styled from 'styled-components';

import { SVersionsContent } from 'apps/repeat/src/pages/VersionsPage/SComponents';

import { pxToRem } from '@repeat/ui-kit';

export const SReleasesContent = styled(SVersionsContent)`
    padding: 0 ${pxToRem(16)};
`;
export const SMoreDetailedBlock = styled.p`
    padding-top: ${pxToRem(24)};
`;
