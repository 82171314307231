import { pxToRem } from '@repeat/ui-kit';
import styled from 'styled-components';

export const SImage = styled.div`
    max-width: ${pxToRem(100)};

    img {
        display: block;
        height: auto;
        margin: 0 auto;
        width: 100%;
    }
`;
export const SInputFile = styled.input`
    opacity: 0;
    height: 0;
    width: 0;
    line-height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
`;

export const SControlButtons = styled.div`
    margin-bottom: ${pxToRem(8)};
    margin-top: ${pxToRem(8)};
    & > :first-child {
        margin-bottom: ${pxToRem(8)};
    }
`;

export const SPreviewBlock = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    & > :first-child {
        margin-right: ${pxToRem(32)};
    }
`;


export const SPreviewCanvas = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;
`;



