import styled from 'styled-components';

import { uiRGBColors, pxToRem, radius, uiColors } from '../../config/config';

export const SCheckboxCheckmark = styled.span<{ error: boolean }>`
    &::before {
        content: '';
        display: inline-block;
        width: ${pxToRem(16)};
        height: ${pxToRem(16)};
        flex-shrink: 0;
        flex-grow: 0;
        border: 2px solid ${({ error }) => (error ? uiColors.red : `rgba(49, 49, 49, 20%)`)};
        border-radius: ${radius.default};
        transition: 0.3s ease-out;
        margin-right: ${pxToRem(8)};
    }
`;
export const SCheckboxContent = styled.div`
    padding-top: ${pxToRem(2)};
    font-size: ${pxToRem(14)};
    margin-bottom: ${pxToRem(8)};
    position: relative;
`;

export const SCheckboxLabel = styled.label<{ disabled?: boolean }>`
    display: flex;
    margin-bottom: ${pxToRem(16)};
    width: 100%;

    color: ${({ disabled }) => (disabled ? uiColors.darkGrey : uiColors.black)};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

    input {
        display: none;

        &:checked + ${SCheckboxCheckmark}::before {
            border-color: ${uiColors.mainBlue};
            background-color: ${uiColors.mainBlue};
            background-image: url('data:image/svg+xml,%3Csvg fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"%3E%3Cpath d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" /%3E%3C/svg%3E');
        }

        &:checked:hover:not([disabled]) + ${SCheckboxCheckmark}::before {
            background-color: ${uiColors.blueLight};
        }

        &:hover:not([disabled]) + ${SCheckboxCheckmark}::before {
            border-color: ${uiColors.blueLight};
        }

        &:disabled + ${SCheckboxCheckmark}::before {
            background-color: rgba(${uiRGBColors.grey}, 0.1);
            border-color: rgba(${uiRGBColors.grey}, 0.2);
        }
        &:checked:disabled + ${SCheckboxCheckmark}::before {
            background-image: url('data:image/svg+xml,%3Csvg fill="grey" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"%3E%3Cpath d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" /%3E%3C/svg%3E');
        }
    }
`;

export const SCheckBoxError = styled.span`
    color: ${uiColors.red};
    font-size: ${pxToRem(12)};
    padding: ${pxToRem(4)} 0;
    position: absolute;
    top: calc(100% - ${pxToRem(4)});
    left: 0;
`;
