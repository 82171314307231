import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { ApplicationActions } from '@repeat/common-slices';
import { useAppDispatch, useDemoMode } from '@repeat/hooks';
import { ModalTypes } from '@repeat/models';
import { closeProject, workspaceActions } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { Button, Modal, EVariantButton } from '@repeat/ui-kit';

import { sessionModalMessages } from './translation';

export const SessionConnectModal = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const { isDemo } = useDemoMode();
    const navigate = useNavigate();

    const handleClose = () => {
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.MODAL_SESSION_CONNECT }));
    };

    const handleCloseProject = async () => {
        await dispatch(closeProject());
        dispatch(workspaceActions.connectionLivePermissionsLost());
        dispatch(workspaceActions.connectionLivePermissionsDisconnect());
        if (isDemo) {
            return navigate('/projects/demo');
        }
        return navigate('/projects');
    };

    const handleReload = async () => {
        handleClose();
        setTimeout(() => dispatch(workspaceActions.startLivePermissionsConnecting()), 500);
    };

    const FooterButtons = () => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={handleReload}>
                {intl.formatMessage(sessionModalMessages[TranslationKey.SESSION_CONNECTION_LOST_BUTTON])}
            </Button>
            <Button variant={EVariantButton.SECONDARY} onClick={handleCloseProject}>
                {intl.formatMessage(sessionModalMessages[TranslationKey.CLOSE_PROJECT])}
            </Button>
        </div>
    );

    return (
        <Modal
            id={ModalTypes.MODAL_SESSION_CONNECT}
            title={intl.formatMessage(sessionModalMessages[TranslationKey.SESSION_CONNECTION_LOST_TITLE])}
            isOpen={true}
            hasOverlay={true}
            onClose={handleClose}
            type='default'
            size='small'
            footer={<FooterButtons />}
            hasCloseButton={false}
        >
            <p>{intl.formatMessage(sessionModalMessages[TranslationKey.SESSION_CONNECTION_LOST_DESCRIPTION])}</p>
        </Modal>
    );
};
