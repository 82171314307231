import React, { FC } from 'react';

import { SToolsCategory, SToolsCategoryList, SToolsCategoryName } from './STools';

interface IToolsCategoryName {
    children: React.ReactNode;
    name: string;
}

export const ToolsCategory: FC<IToolsCategoryName> = ({ children, name }) => {
    return (
        <SToolsCategory>
            <SToolsCategoryName>{name}</SToolsCategoryName>
            <SToolsCategoryList>{children}</SToolsCategoryList>
        </SToolsCategory>
    );
};
