import 'katex/dist/katex.min.css';
import { useContext, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import Markdown from 'react-markdown';
import Moveable, { OnDrag, OnDragEnd, OnDragStart } from 'react-moveable';

import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';
import remarkMath from 'remark-math';

import { IconButton, uiColors } from '@repeat/ui-kit';

import styled from 'styled-components';
import { ModalContext } from './BlockDescriptionModalContext';
import { SBlockDescriptionModal } from './styled/SBlockDescriptionModal';
import { SElementDescription, SElementDescriptionTitle } from './styled/SElementDescription';

const SDescription = styled.div`
    ol {
        list-style-type: decimal;
    }
    ul {
        list-style-type: disc;
    }
    ul,
    ol {
        list-style-position: inside;
        text-align: left;
    }
    em {
        font-style: italic;
    }
`;

export default function BlockDescription(props: { id: string; name: string; description: string }) {
    const elementRef = useRef<HTMLDivElement | null>(null);
    const moveableRef = useRef<Moveable | null>(null);
    const [selected, setSelected] = useState(false);

    const { closeModal } = useContext(ModalContext);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (!elementRef.current?.contains(event.target as Node)) {
                elementRef.current && (elementRef.current.style.zIndex = '20');
                setSelected(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
    }, [elementRef]);
    return createPortal(
        <SBlockDescriptionModal>
            {selected && (
                <Moveable
                    ref={moveableRef}
                    edge={true}
                    origin={false}
                    container={null}
                    target={elementRef}
                    resizable={true}
                    throttleResize={0}
                    draggable={true}
                    throttleDrag={0}
                    rotationPosition='none'
                    hideDefaultLines={true}
                    onResize={(e) => {
                        e.target.style.width = `${e.width}px`;
                        e.target.style.height = `${e.height}px`;
                        e.target.style.transform = e.drag.transform;
                    }}
                    onDrag={({ target, transform }: OnDrag) => {
                        target!.style.transform = transform;
                        target!.style.cursor = 'grab';
                    }}
                    onDragStart={({ target }: OnDragStart) => {
                        target!.style.zIndex = '21';
                    }}
                    onDragEnd={({ target }: OnDragEnd) => {
                        target!.style.cursor = 'default';
                    }}
                />
            )}

            <SElementDescription
                data-name={'element-name'}
                ref={elementRef}
                onMouseDownCapture={(event: any) => {
                    event.target.style.zIndex = '21';
                    setSelected(true);
                }}
            >
                <SElementDescriptionTitle>
                    {props.name}
                    <IconButton onClick={() => closeModal(props.id)} noHover name='close' fill={uiColors.darkGrey} />
                </SElementDescriptionTitle>
                <SDescription>
                    <Markdown skipHtml={false} remarkPlugins={[remarkMath]} rehypePlugins={[rehypeKatex, rehypeRaw]}>
                        {props.description}
                    </Markdown>
                </SDescription>
            </SElementDescription>
        </SBlockDescriptionModal>,
        document.getElementById('mainWork')!
    );
}
