import { PayloadAction } from '@reduxjs/toolkit';

import { TProxyMap } from 'libs/models/src/lib/element';

import { containsNumbersOnly, extractNumbers } from '@repeat/constants';
import {
    ElemParams,
    ElemProps,
    IWorkspaceState,
    TLibraryType,
    TSchemaNode,
    TSubmodelProxyItem,
    WorkspaceModes,
} from '@repeat/models';

const sortParameters = (params: ElemParams[]) => {
    return params.sort((a, b) => {
        const aIsIn = a.name.includes('in');
        const bIsIn = b.name.includes('in');
        if (aIsIn === bIsIn) {
            const aNum = parseInt(a.name.split('_')[1]);
            const bNum = parseInt(b.name.split('_')[1]);
            return aNum - bNum;
        } else if (aIsIn) {
            return -1;
        } else {
            return 1;
        }
    });
};

export const findMissedIndexes = (arr: number[]) => {
    if (arr.length) {
        const maxNum = Math.max(...arr);
        const fullArr = new Array(maxNum + 1).fill(false);
        for (const num of arr) {
            fullArr[num] = true;
        }
        const missingNumbers = [];
        for (let i = 1; i <= maxNum; i++) {
            if (!fullArr[i]) {
                missingNumbers.push(i);
            }
        }
        return missingNumbers;
    }
    return [];
};

export const addExternalParameters = (
    state: IWorkspaceState,
    payload: TSchemaNode[]
):
    | {
          elements: TSchemaNode[];
          externalInfo: { properties: ElemProps[]; parameters: ElemParams[] };
          proxyMap: TProxyMap;
      }
    | IWorkspaceState
    | undefined => {
    const inputPorts = payload.filter((el) => el.data.type === 'InPort');
    const outputPorts = payload.filter((el) => el.data.type === 'OutPort');

    const {
        meta: { mode },
        schema: {
            schemaItems: { elements },
            externalInfo,
            proxyMap,
        },
    } = state;
    if (mode === WorkspaceModes.SUBMODEL) {
        return;
    }

    const inputLibraryName = inputPorts.length ? inputPorts[0].data.name : '';
    const outputLibraryName = outputPorts.length ? outputPorts[0].data.name : '';

    if (externalInfo && proxyMap) {
        const params = externalInfo.parameters;
        const inputParams = params.filter((p) => p.name.includes('in'));
        const outputParams = params.filter((p) => p.name.includes('out'));
        const inputParamsDefaultDescriptions: ElemParams[] = [];
        const outputParamsDefaultDescriptions: ElemParams[] = [];
        const currentInputsIndexes = inputParams.map((p) => {
            const splittedDescription = p.description.split('_');

            if (
                splittedDescription.length === 2 &&
                splittedDescription[0] === inputLibraryName &&
                containsNumbersOnly(splittedDescription[1])
            ) {
                inputParamsDefaultDescriptions.push(p);
                return Number(extractNumbers(splittedDescription[1]));
            }
            return null;
        });

        const currentOutputsIndexes = outputParams.map((p) => {
            const splittedDescription = p.description.split('_');
            if (
                splittedDescription.length === 2 &&
                splittedDescription[0] === outputLibraryName &&
                containsNumbersOnly(splittedDescription[1])
            ) {
                outputParamsDefaultDescriptions.push(p);
                return Number(extractNumbers(splittedDescription[1]));
            }
            return null;
        });

        const missedInputDescriptionIndexes = findMissedIndexes(currentInputsIndexes.filter((i) => !!i) as number[]);
        const missedOutputDescriptionIndexes = findMissedIndexes(currentOutputsIndexes.filter((i) => !!i) as number[]);

        const inputParamsNew = inputPorts.map((p, index) => {
            let indexInDescription;

            if (missedInputDescriptionIndexes.length !== 0) {
                indexInDescription = missedInputDescriptionIndexes[0];
            } else {
                indexInDescription = inputParamsDefaultDescriptions.length + index + 1;
            }
            missedInputDescriptionIndexes.shift();

            return {
                description: `${inputLibraryName}_${indexInDescription}`,
                name: `in_${index + inputParams.length + 1}`,
                modelName: '',
                unit: '',
                value: '',
            };
        });

        const outputParamsNew = outputPorts.map((p, index) => {
            let indexInDescription;

            if (missedOutputDescriptionIndexes.length !== 0) {
                indexInDescription = missedOutputDescriptionIndexes[0];
            } else {
                indexInDescription = outputParamsDefaultDescriptions.length + index + 1;
            }
            missedOutputDescriptionIndexes.shift();
            return {
                description: `${outputLibraryName}_${indexInDescription}`,
                name: `out_${index + outputParams.length + 1}`,
                modelName: '',
                unit: '',
                value: '',
            };
        });

        const paramsNew = [...externalInfo.parameters, ...inputParamsNew, ...outputParamsNew];

        const proxyMapInputParamsNew = inputPorts.map((port, index) => ({
            name: `${inputParamsNew[index].name}`,
            internalBlockId: port.data.id,
            internalName: '',
        }));
        const proxyMapOutputParamsNew = outputPorts.map((port, index) => ({
            name: `${outputParamsNew[index].name}`,
            internalBlockId: port.data.id,
            internalName: '',
        }));
        const proxyMapNew = {
            ...proxyMap,
            params: [...proxyMap.params, ...proxyMapInputParamsNew, ...proxyMapOutputParamsNew],
        };

        const updateElementsWithParentParameter = () => {
            return elements.map((el) => {
                if (el.data.type === 'InPort' || el.data.type === 'OutPort') {
                    let elemProps: ElemProps[] = el.data.elemProps;
                    if (el.data.type === 'InPort' && inputPorts.map((p) => p.id).includes(el.id)) {
                        inputPorts.forEach((port: TSchemaNode, index: number) => {
                            if (el.id === port.id) {
                                elemProps = el.data.elemProps.map((p) => {
                                    if (p.name === 'parentParameter') {
                                        return { ...p, value: inputParamsNew[index].name };
                                    }
                                    if (p.name === 'userParameterName') {
                                        return {
                                            ...p,
                                            value: inputParamsNew[index].description,
                                        };
                                    }
                                    return p;
                                });
                            }
                        });
                    }
                    if (el.data.type === 'OutPort' && outputPorts.map((p) => p.id).includes(el.id)) {
                        outputPorts.forEach((port, index) => {
                            if (el.id === port.id) {
                                elemProps = el.data.elemProps.map((p) => {
                                    if (p.name === 'parentParameter') {
                                        return { ...p, value: outputParamsNew[index].name };
                                    }
                                    if (p.name === 'userParameterName') {
                                        return {
                                            ...p,
                                            value: outputParamsNew[index].description,
                                        };
                                    }
                                    return p;
                                });
                            }
                        });
                    }
                    return { ...el, data: { ...el.data, elemProps } };
                }
                return el;
            });
        };

        const externalProperties = externalInfo && externalInfo.properties ? externalInfo.properties : [];

        return {
            elements: updateElementsWithParentParameter(),
            externalInfo: { properties: externalProperties, parameters: sortParameters(paramsNew) },
            proxyMap: proxyMapNew,
        };
    }

    return state;
};

export const externalInfoSlice = {
    addExternalProperty: (state: IWorkspaceState, action: PayloadAction<{ id: string; name: string }>) => {
        const { id, name } = action.payload;

        const currentPropertyElement = state.schema.schemaItems.elements.find((el) => el.id === id);
        const currentProperty = currentPropertyElement?.data.elemProps.find((prop) => prop.name === name);
        const propertyName = `${currentProperty?.name}-${currentPropertyElement?.id}`;
        const proxyMapProperty: TSubmodelProxyItem = currentPropertyElement
            ? { name: propertyName, internalBlockId: currentPropertyElement.data.id, internalName: name }
            : { name: '', internalBlockId: 0, internalName: '' };
        const proxyMap = state.schema.proxyMap
            ? { ...state.schema.proxyMap, props: [...state.schema.proxyMap.props, proxyMapProperty] }
            : { params: [], props: [proxyMapProperty] };
        if (currentProperty) {
            const resultProperty = {
                ...currentProperty,
                name: propertyName,
                description: `${currentPropertyElement?.data.name} [${currentPropertyElement?.data.index}] / ${currentProperty?.description}`,
            };
            const externalInfo = state.schema.externalInfo
                ? {
                      ...state.schema.externalInfo,
                      properties: [...state.schema.externalInfo.properties, resultProperty],
                  }
                : { parameters: [], properties: [resultProperty] };

            return {
                ...state,
                schema: {
                    ...state.schema,
                    externalInfo,
                    proxyMap,
                },
            };
        }

        return state;
    },
    deleteExternalProperty: (state: IWorkspaceState, action: PayloadAction<string>) => {
        const externalInfo = state.schema.externalInfo
            ? {
                  ...state.schema.externalInfo,
                  properties: state.schema.externalInfo?.properties.filter((prop) => prop.name !== action.payload),
              }
            : null;
        const proxyMap = state.schema.proxyMap
            ? {
                  ...state.schema.proxyMap,
                  props: state.schema.proxyMap.props.filter((prop) => prop.name !== action.payload),
              }
            : null;

        return {
            ...state,
            schema: {
                ...state.schema,
                externalInfo,
                proxyMap,
            },
        };
    },
    setExternalPropertyDescription: (
        state: IWorkspaceState,
        action: PayloadAction<{ name: string; description: string }>
    ) => {
        const { name, description } = action.payload;
        if (state.schema.externalInfo) {
            const properties = state.schema.externalInfo.properties.map((prop) => {
                if (prop.name === name) {
                    return { ...prop, description };
                }
                return prop;
            });

            return {
                ...state,
                schema: {
                    ...state.schema,
                    externalInfo: { ...state.schema.externalInfo, properties },
                },
            };
        }
        return state;
    },
    setExternalPropertyValue: (state: IWorkspaceState, { payload }: PayloadAction<{ [key: string]: string }>) => {
        const fullPropertyName = Object.keys(payload)[0];
        const splittedKey = Object.keys(payload)[0].split('-');
        const {
            schema: { externalInfo },
        } = state;
        if (splittedKey.length !== 2) {
            return;
        }
        const propertyName = splittedKey[0];
        const elementId = splittedKey[1];
        const propertyValue = Object.values(payload)[0];
        const elements = state.schema.schemaItems.elements.map((el) => {
            if (el.id === elementId) {
                const elemProps = el.data.elemProps.map((prop) => {
                    if (prop.name === propertyName) {
                        return { ...prop, value: propertyValue };
                    }
                    return prop;
                });
                return { ...el, data: { ...el.data, elemProps } };
            }
            return el;
        });
        const externalProperties = externalInfo ? externalInfo.properties : [];
        const externalParameters = externalInfo ? externalInfo.parameters : [];
        const externalPropertiesNew = externalProperties.map((prop) => {
            if (prop.name === fullPropertyName) {
                return { ...prop, value: propertyValue };
            }
            return prop;
        });
        return {
            ...state,
            schema: {
                ...state.schema,
                schemaItems: { ...state.schema.schemaItems, elements },
                externalInfo: { parameters: externalParameters, properties: externalPropertiesNew },
            },
        };
    },

    setExternalParameterDescription: (
        state: IWorkspaceState,
        action: PayloadAction<{ name: string; description: string }>
    ) => {
        const { name, description } = action.payload;
        const {
            schema: { externalInfo },
        } = state;
        const externalParameters =
            externalInfo && externalInfo.parameters
                ? externalInfo?.parameters.map((param) => {
                      if (param.name === name) {
                          return { ...param, description };
                      }
                      return param;
                  })
                : [];
        const externalProperties = externalInfo ? externalInfo.properties : [];

        return {
            ...state,
            schema: {
                ...state.schema,
                externalInfo: { properties: externalProperties, parameters: externalParameters },
            },
        };
    },


};
