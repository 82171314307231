import { AxiosResponse } from 'axios';

import { $api } from './http';

interface IReturnGenerateJupyterHubLink {
    link: string;
}

export default class JupyterHubService {
    static generateNotebookWithCoreLink = async (
        locale: string
    ): Promise<AxiosResponse<IReturnGenerateJupyterHubLink>> => {
        // TODO make real api url for getting notebook link
        return $api.get(`/api/v1/...`);
    };
}
