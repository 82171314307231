import { PayloadAction } from '@reduxjs/toolkit';

import { IEventLogData, IWorkspaceState } from '@repeat/models';

export const eventLogReducer = {
    addLog: (state: IWorkspaceState, { payload }: PayloadAction<IEventLogData>) => {
        const { list } = state.eventLog;
        return {
            ...state,
            eventLog: {
                ...state.eventLog,
                list: [...list, payload],
            },
        };
    },
};
