import { ElemProps, ILibraryItem, TSchemaConnection, TSchemaGroup, TSchemaNode } from '@repeat/models';

interface ISchemaItems {
    elements: TSchemaNode[];
    wires: TSchemaConnection[];
    groups: TSchemaGroup[];
}

const FMI_TYPE = 'fmi';

export const applyPatch_FMI_properties = (schemaItems: ISchemaItems, libraryItems: ILibraryItem[]): ISchemaItems => {
    let nodes = schemaItems.elements;
    const connections = schemaItems.wires;

    const elementsToUpdate = nodes.filter((el) => [FMI_TYPE].includes(el.data.type));

    if (elementsToUpdate.length > 0) {
        nodes = schemaItems.elements.map((node: TSchemaNode) => {
            if ([FMI_TYPE].includes(node.data.type)) {
                const libraryItem = libraryItems.find((item) => item.type === node.data.type);
                if (libraryItem) {
                    const elemProps = [
                        ...node.data.elemProps.map((property: ElemProps) => {
                            if (property.name === 'in_count') {
                                return {
                                    ...property,
                                    name: 'Input_ports',
                                };
                            }
                            if (property.name === 'out_count') {
                                return {
                                    ...property,
                                    name: 'Output_ports',
                                };
                            }
                            return { ...property };
                        }),
                    ];
                    return { ...node, data: { ...node.data, elemProps } };
                }
            }
            return node;
        });
    }

    return {
        ...schemaItems,
        elements: nodes,
        wires: connections,
    };
};
