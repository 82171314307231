import { useAppSelector } from '@repeat/hooks';
import { TWorkspaceMode, WorkspaceModes } from '@repeat/models';
import { elementsSelectors, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { FC, memo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Position, SmoothStepEdge } from 'reactflow';
import { messages } from '../../Visualization/translation';

interface IEdge {
    id: string;
    sourceY: number;
    targetX: number;
    sourceX: number;
    targetY: number;
    targetPosition: Position;
    sourcePosition: Position;
}

export const CustomEdge: FC<IEdge> = memo(
    ({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition }) => {
        const intl = useIntl();

        const currentSubmodelItems = useAppSelector(workspaceSelectors.currentSubmodelItems);

        const workspaceMode = useAppSelector(workspaceSelectors.workspaceMode) as TWorkspaceMode;
        const mainWires = useAppSelector(elementsSelectors.getWires);
        const wires =
            workspaceMode === WorkspaceModes.SUBMODEL || workspaceMode === WorkspaceModes.GROUP
                ? currentSubmodelItems?.wires || []
                : mainWires;

        const [wire, setWire] = useState(wires.find((w) => w.id === id));
        const isValidConnection = wire?.data.isValidConnection ?? true;

        if (!wire) {
            return null;
        }

        return (
            <>
                <SmoothStepEdge
                    id={id}
                    source={wire.source}
                    target={wire.target}
                    sourceX={sourceX}
                    sourceY={sourceY}
                    targetX={targetX}
                    targetY={targetY}
                    sourcePosition={sourcePosition}
                    targetPosition={targetPosition}
                    style={{ stroke: isValidConnection ? 'var(--ui-text)' : 'var(--ui-alert)' }}
                    label={!isValidConnection && intl.formatMessage(messages[TranslationKey.SCHEMA_INVALID_CONNECTION])}
                    labelStyle={{ fill: 'red' }}
                />
            </>
        );
    }
);
