import { defineAbility } from '@casl/ability';

import { EUserPermissions, EUserRoles, LibraryTypes, TLibraryType, TUserPermission, User } from '@repeat/models';

interface IAbilityRule {
    libraries: TLibraryType[];
    tools: string[];
}
type TAbilitiesRuleMap = {
    [key in EUserPermissions]: IAbilityRule;
};

export const abilitiesRulesMap: TAbilitiesRuleMap = {
    [EUserPermissions.BASE]: {
        libraries: [
            LibraryTypes.AUTO,
            LibraryTypes.SIMULATION,
            LibraryTypes.SYSTEM_DESIGN,
            LibraryTypes.ELECTRIC_DRIVE,
            LibraryTypes.EXTERNAL_MODELS,
            LibraryTypes.COMMON,
        ],
        tools: [],
    },
    [EUserPermissions.MECHANICS]: {
        libraries: [LibraryTypes.MECHANICS],
        tools: [],
    },
    [EUserPermissions.HYDRO]: {
        libraries: [LibraryTypes.ISOTHERMAL_HYDRAULICS],
        tools: [],
    },
    [EUserPermissions.HEATHYDRO]: {
        libraries: [LibraryTypes.HEAT_EXCHANGE, LibraryTypes.THERMOHYDRAULICS],
        tools: [],
    },
    [EUserPermissions.PNEUMO]: {
        libraries: [LibraryTypes.THERMOHYDRAULICS], // TODO изменить на пневматику, когда появится этот тип (мы его ждем от инженеров)
        tools: [],
    },
    [EUserPermissions.ELECTRODYNAMICS]: {
        libraries: [LibraryTypes.ELECTROCITY, LibraryTypes.ELECTRONICS],
        tools: ['ELECTROCITY'],
    },
    [EUserPermissions.CODE_GENERATION]: {
        libraries: [],
        tools: ['CODE_GENERATION'],
    },
};

export const defineAbilityFor = (user: User | null) => {
    return defineAbility((can, cannot) => {
        if (!user) {
            return;
        }

        const roles = user?.roles || [];
        if (roles.includes(EUserRoles.ADMIN) || roles.includes(EUserRoles.DEV)) {
            can('manage', 'all');
            return;
        }

        const permissions: TUserPermission[] = user?.permissions ?? [];
        permissions.forEach((permission: TUserPermission) => {
            const abilitiesRules: IAbilityRule | null = abilitiesRulesMap[permission] ?? null;
            if (!abilitiesRules) {
                return;
            }

            abilitiesRules.libraries.forEach((library) => {
                can('use', `library-${library}`);
            });

            abilitiesRules.tools.forEach((tool) => {
                can('use', `tool-${tool}`);
            });
        });
    });
};
