import { TranslationKey } from '@repeat/translations';

export const useGetTariffData = () => {
    const userModules = (moduleName: string) => {
        const tariffModules = [
            {
                name: 'base',
                description: `${TranslationKey.BASE_PERMISSION}`,
            },
            {
                name: 'pneumo',
                description: `${TranslationKey.PNEUMO_PERMISSION}`,
            },
            {
                name: 'hydro',
                description: `${TranslationKey.ISOTHERMAL_HYDRAULICS}`,
            },
            {
                name: 'heathydro',
                description: `${TranslationKey.HEATHYDRO_PERMISSION}`,
            },
            {
                name: 'codegenerating',
                description: `${TranslationKey.CODEGENERATING_PERMISSION}`,
            },
            {
                name: 'mechanics',
                description: `${TranslationKey.MECHANICS}`,
            },
            {
                name: 'electrodynamics',
                description: `${TranslationKey.ELECTRODYNAMICS_PERMISSION}`,
            },
        ];

        const tariffModuleName = tariffModules.find((module) => module.name === moduleName)?.description;

        return tariffModuleName;
    };

    const userTariffTypes = (tariffType: string) => {
        const tariffTypes = [
            {
                type: 'limited',
                name: `${TranslationKey.TARIFF_LIMITED_TYPE}`,
            },
            {
                type: 'unlimited',
                name: `${TranslationKey.TARIFF_UNLIMITED_TYPE}`,
            },
        ];

        const tariffTypeName = tariffTypes.find((type) => type.type === tariffType)?.name;

        return tariffTypeName;
    };

    const userTariffStatus = (tariffStatus: number) => {
        const tariffStatuses = [
            {
                status: 1,
                name: `${TranslationKey.TARIFF_STATUS_ACTIVE}`,
            },
            {
                status: 2,
                name: `${TranslationKey.TARIFF_STATUS_NOT_RENEWED}`,
            },
            {
                type: 5,
                name: `${TranslationKey.TARIFF_STATUS_CANCELED}`,
            },
        ];

        const tariffName = tariffStatuses.find((status) => status.status === tariffStatus)?.name;
        return tariffName;
    };

    return {
        userModules,
        userTariffTypes,
        userTariffStatus,
    };
};
