import styled from 'styled-components';

import { pxToRem, radius } from '@repeat/ui-kit';

export const STableHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${pxToRem(16)};
    background-color: var(--ui-background);
    padding: 0 ${pxToRem(16)};
    border-radius: ${radius.default};
    gap: ${pxToRem(10)};
    div:first-child {
        margin-bottom: 0;
        flex-grow: 1;
    }
    button {
        flex-basis: 15%;
    }
`;
