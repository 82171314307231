import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.TOUR_NEXT]: {
        id: TranslationKey.TOUR_NEXT,
        defaultMessage: ru[TranslationKey.TOUR_NEXT],
    },
    [TranslationKey.TOUR_PREV]: {
        id: TranslationKey.TOUR_PREV,
        defaultMessage: ru[TranslationKey.TOUR_PREV],
    },
    [TranslationKey.TOUR_SKIP]: {
        id: TranslationKey.TOUR_SKIP,
        defaultMessage: ru[TranslationKey.TOUR_SKIP],
    },
    [TranslationKey.TOUR_FINISH]: {
        id: TranslationKey.TOUR_FINISH,
        defaultMessage: ru[TranslationKey.TOUR_FINISH],
    },
    [TranslationKey.TOUR_PROJECTS_LIST]: {
        id: TranslationKey.TOUR_PROJECTS_LIST,
        defaultMessage: ru[TranslationKey.TOUR_PROJECTS_LIST],
    },
    [TranslationKey.TOUR_PROJECTS_LIST_DESCRIPTION]: {
        id: TranslationKey.TOUR_PROJECTS_LIST_DESCRIPTION,
        defaultMessage: ru[TranslationKey.TOUR_PROJECTS_LIST_DESCRIPTION],
    },
    [TranslationKey.TOUR_PROJECTS_NEW]: {
        id: TranslationKey.TOUR_PROJECTS_NEW,
        defaultMessage: ru[TranslationKey.TOUR_PROJECTS_NEW],
    },
    [TranslationKey.TOUR_PROJECTS_NEW_DESCRIPTION]: {
        id: TranslationKey.TOUR_PROJECTS_NEW_DESCRIPTION,
        defaultMessage: ru[TranslationKey.TOUR_PROJECTS_NEW_DESCRIPTION],
    },
    [TranslationKey.TOUR_WORKSPACE_MODEL_CONTROL]: {
        id: TranslationKey.TOUR_WORKSPACE_MODEL_CONTROL,
        defaultMessage: ru[TranslationKey.TOUR_WORKSPACE_MODEL_CONTROL],
    },
    [TranslationKey.TOUR_WORKSPACE_MODEL_CONTROL_DESCRIPTION]: {
        id: TranslationKey.TOUR_WORKSPACE_MODEL_CONTROL_DESCRIPTION,
        defaultMessage: ru[TranslationKey.TOUR_WORKSPACE_MODEL_CONTROL_DESCRIPTION],
    },
    [TranslationKey.TOUR_WORKSPACE_MODEL_CONTROL_START]: {
        id: TranslationKey.TOUR_WORKSPACE_MODEL_CONTROL_START,
        defaultMessage: ru[TranslationKey.TOUR_WORKSPACE_MODEL_CONTROL_START],
    },
    [TranslationKey.TOUR_WORKSPACE_MODEL_CONTROL_START_DESCRIPTION]: {
        id: TranslationKey.TOUR_WORKSPACE_MODEL_CONTROL_START_DESCRIPTION,
        defaultMessage: ru[TranslationKey.TOUR_WORKSPACE_MODEL_CONTROL_START_DESCRIPTION],
    },
    [TranslationKey.TOUR_WORKSPACE_PROJECT_SAVE]: {
        id: TranslationKey.TOUR_WORKSPACE_PROJECT_SAVE,
        defaultMessage: ru[TranslationKey.TOUR_WORKSPACE_PROJECT_SAVE],
    },
    [TranslationKey.TOUR_WORKSPACE_PROJECT_SAVE_DESCRIPTION]: {
        id: TranslationKey.TOUR_WORKSPACE_PROJECT_SAVE_DESCRIPTION,
        defaultMessage: ru[TranslationKey.TOUR_WORKSPACE_PROJECT_SAVE_DESCRIPTION],
    },
    [TranslationKey.TOUR_WORKSPACE_JOURNAL_AREA]: {
        id: TranslationKey.TOUR_WORKSPACE_JOURNAL_AREA,
        defaultMessage: ru[TranslationKey.TOUR_WORKSPACE_JOURNAL_AREA],
    },
    [TranslationKey.TOUR_WORKSPACE_JOURNAL_AREA_DESCRIPTION]: {
        id: TranslationKey.TOUR_WORKSPACE_JOURNAL_AREA_DESCRIPTION,
        defaultMessage: ru[TranslationKey.TOUR_WORKSPACE_JOURNAL_AREA_DESCRIPTION],
    },
    [TranslationKey.TOUR_WORKSPACE_JOURNAL_TAB]: {
        id: TranslationKey.TOUR_WORKSPACE_JOURNAL_TAB,
        defaultMessage: ru[TranslationKey.TOUR_WORKSPACE_JOURNAL_TAB],
    },
    [TranslationKey.TOUR_WORKSPACE_JOURNAL_TAB_DESCRIPTION]: {
        id: TranslationKey.TOUR_WORKSPACE_JOURNAL_TAB_DESCRIPTION,
        defaultMessage: ru[TranslationKey.TOUR_WORKSPACE_JOURNAL_TAB_DESCRIPTION],
    },
    [TranslationKey.TOUR_WORKSPACE_LIBRARY]: {
        id: TranslationKey.TOUR_WORKSPACE_LIBRARY,
        defaultMessage: ru[TranslationKey.TOUR_WORKSPACE_LIBRARY],
    },
    [TranslationKey.TOUR_WORKSPACE_LIBRARY_DESCRIPTION]: {
        id: TranslationKey.TOUR_WORKSPACE_LIBRARY_DESCRIPTION,
        defaultMessage: ru[TranslationKey.TOUR_WORKSPACE_LIBRARY_DESCRIPTION],
    },
    [TranslationKey.TOUR_WORKSPACE_LIBRARY_ITEM]: {
        id: TranslationKey.TOUR_WORKSPACE_LIBRARY_ITEM,
        defaultMessage: ru[TranslationKey.TOUR_WORKSPACE_LIBRARY_ITEM],
    },
    [TranslationKey.TOUR_WORKSPACE_LIBRARY_ITEM_DESCRIPTION]: {
        id: TranslationKey.TOUR_WORKSPACE_LIBRARY_ITEM_DESCRIPTION,
        defaultMessage: ru[TranslationKey.TOUR_WORKSPACE_LIBRARY_ITEM_DESCRIPTION],
    },
    [TranslationKey.TOUR_WORKSPACE_VISUALIZATION_TRIGGER]: {
        id: TranslationKey.TOUR_WORKSPACE_VISUALIZATION_TRIGGER,
        defaultMessage: ru[TranslationKey.TOUR_WORKSPACE_VISUALIZATION_TRIGGER],
    },
    [TranslationKey.TOUR_WORKSPACE_VISUALIZATION_TRIGGER_DESCRIPTION]: {
        id: TranslationKey.TOUR_WORKSPACE_VISUALIZATION_TRIGGER_DESCRIPTION,
        defaultMessage: ru[TranslationKey.TOUR_WORKSPACE_VISUALIZATION_TRIGGER_DESCRIPTION],
    },
    [TranslationKey.TOUR_WORKSPACE_VISUALIZATION_TAB]: {
        id: TranslationKey.TOUR_WORKSPACE_VISUALIZATION_TAB,
        defaultMessage: ru[TranslationKey.TOUR_WORKSPACE_VISUALIZATION_TAB],
    },
    [TranslationKey.TOUR_WORKSPACE_VISUALIZATION_TAB_DESCRIPTION]: {
        id: TranslationKey.TOUR_WORKSPACE_VISUALIZATION_TAB_DESCRIPTION,
        defaultMessage: ru[TranslationKey.TOUR_WORKSPACE_VISUALIZATION_TAB_DESCRIPTION],
    },
    [TranslationKey.TOUR_WORKSPACE_VISUALIZATION_AREA]: {
        id: TranslationKey.TOUR_WORKSPACE_VISUALIZATION_AREA,
        defaultMessage: ru[TranslationKey.TOUR_WORKSPACE_VISUALIZATION_AREA],
    },
    [TranslationKey.TOUR_WORKSPACE_VISUALIZATION_AREA_DESCRIPTION]: {
        id: TranslationKey.TOUR_WORKSPACE_VISUALIZATION_AREA_DESCRIPTION,
        defaultMessage: ru[TranslationKey.TOUR_WORKSPACE_VISUALIZATION_AREA_DESCRIPTION],
    },
    [TranslationKey.TOUR_WORKSPACE_JOURNAL_TAB]: {
        id: TranslationKey.TOUR_WORKSPACE_JOURNAL_TAB,
        defaultMessage: ru[TranslationKey.TOUR_WORKSPACE_JOURNAL_TAB],
    },
    [TranslationKey.TOUR_WORKSPACE_JOURNAL_TAB_DESCRIPTION]: {
        id: TranslationKey.TOUR_WORKSPACE_JOURNAL_TAB_DESCRIPTION,
        defaultMessage: ru[TranslationKey.TOUR_WORKSPACE_JOURNAL_TAB_DESCRIPTION],
    },
    [TranslationKey.TOUR_WORKSPACE_JOURNAL_AREA]: {
        id: TranslationKey.TOUR_WORKSPACE_JOURNAL_AREA,
        defaultMessage: ru[TranslationKey.TOUR_WORKSPACE_JOURNAL_AREA],
    },
    [TranslationKey.TOUR_WORKSPACE_JOURNAL_AREA_DESCRIPTION]: {
        id: TranslationKey.TOUR_WORKSPACE_JOURNAL_AREA_DESCRIPTION,
        defaultMessage: ru[TranslationKey.TOUR_WORKSPACE_JOURNAL_AREA_DESCRIPTION],
    },
    [TranslationKey.TOUR_WORKSPACE_RIGHT_PANEL]: {
        id: TranslationKey.TOUR_WORKSPACE_RIGHT_PANEL,
        defaultMessage: ru[TranslationKey.TOUR_WORKSPACE_RIGHT_PANEL],
    },
    [TranslationKey.TOUR_WORKSPACE_RIGHT_PANEL_DESCRIPTION]: {
        id: TranslationKey.TOUR_WORKSPACE_RIGHT_PANEL_DESCRIPTION,
        defaultMessage: ru[TranslationKey.TOUR_WORKSPACE_RIGHT_PANEL_DESCRIPTION],
    },
    [TranslationKey.TOUR_WORKSPACE_PROJECT_TITLE]: {
        id: TranslationKey.TOUR_WORKSPACE_PROJECT_TITLE,
        defaultMessage: ru[TranslationKey.TOUR_WORKSPACE_PROJECT_TITLE],
    },
    [TranslationKey.TOUR_WORKSPACE_PROJECT_TITLE_DESCRIPTION]: {
        id: TranslationKey.TOUR_WORKSPACE_PROJECT_TITLE_DESCRIPTION,
        defaultMessage: ru[TranslationKey.TOUR_WORKSPACE_PROJECT_TITLE_DESCRIPTION],
    },
    [TranslationKey.TOUR_PROJECTS_NEW]: {
        id: TranslationKey.TOUR_PROJECTS_NEW,
        defaultMessage: ru[TranslationKey.TOUR_PROJECTS_NEW],
    },
    [TranslationKey.TOUR_PROJECTS_NEW_DESCRIPTION]: {
        id: TranslationKey.TOUR_PROJECTS_NEW_DESCRIPTION,
        defaultMessage: ru[TranslationKey.TOUR_PROJECTS_NEW_DESCRIPTION],
    },
});
