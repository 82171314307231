import { Dispatch } from 'redux';

import { getCookie } from '@repeat/constants';
import { environment } from '@repeat/environment';
import { User } from '@repeat/models';

import { AdminAction, AdminTypes } from '../actions/adminTypes';

const setUsers = (users: User[]): AdminAction => {
    return {
        type: AdminTypes.SET_USERS,
        payload: users,
    };
};

export const getUsers = () => async (dispatch: Dispatch<AdminAction>) => {
    try {
        const response = await fetch(`${environment.API_URL}/api/users`, {
            headers: { Authorization: `Bearer ${getCookie('accessToken')}` },
            method: 'GET',
        });
        const responseFromServer = await response.json();
        dispatch(setUsers(responseFromServer));
    } catch (error) {
        console.log(error);
    }
};
