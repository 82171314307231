import { TariffsAction, TariffsTypes } from '../actions/tariffsTypes';

const initialState = {
    items: [],
    isInitialized: false,
};

export function tariffsReducer(state = initialState, action: TariffsAction) {
    const { type, payload } = action;
    switch (type) {
        case TariffsTypes.SET_TARIFFS:
            return {
                ...state,
                isInitialized: true,
                items: payload,
            };

        default:
            return state;
    }
}
export default tariffsReducer;
