import { FC } from 'react';

import { SAlert } from './SAlert';

export enum AlertType {
    SUCCESS = 'success',
    WARNING = 'warning',
    ERROR = 'error',
}

export type TAlertType = AlertType.SUCCESS | AlertType.WARNING | AlertType.ERROR;

export interface IAlert {
    type: TAlertType;
    children: React.ReactNode;
}

export const Alert: FC<IAlert> = ({ children, type }) => <SAlert type={type}>{children}</SAlert>;
