import { CounterAction, CounterTypes } from '../actions/counterTypes';

export function counterReducer(state = 0, action: CounterAction) {
    switch (action.type) {
        case CounterTypes.INC:
            return action.payload;

        default:
            return state;
    }
}

export default counterReducer;
