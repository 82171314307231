import { ValidAction, ValidTypes } from '../actions/validTypes';

const initialState = 0;

export function validReducer(state = initialState, action: ValidAction) {
    switch (action.type) {
        case ValidTypes.VALID:
            return action.payload;

        default:
            return state;
    }
}
export default validReducer;
