import { useCallback, useState } from 'react';

import { screenshotGenerator, screenshotDownloader } from '@repeat/constants';
import { useAppDispatch } from '@repeat/hooks';
import { saveProjectPreview } from '@repeat/store';

export const useProjectScreenshot = () => {
    const [isDownloading, setIsDownLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const dispatch = useAppDispatch();

    const handleScreenShot = useCallback(async (projectId: number) => {
        await screenshotGenerator(
            (canvas: string) => dispatch(saveProjectPreview(canvas, projectId)),
            () => setIsSaving(true),
            () => setIsSaving(false)
        );
    }, []);

    const handleDownloadScreenshot = useCallback(
        () =>
            screenshotDownloader(
                () => setIsDownLoading(true),
                () => setIsDownLoading(false)
            ),
        []
    );

    return {
        isSaving,
        isDownloading,
        handleScreenShot,
        handleDownloadScreenshot,
    };
};
