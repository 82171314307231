import { combineReducers } from 'redux';

import { appReducer, appUserReducer } from '@repeat/common-slices';

import { adminReducer } from './adminReducer';
import { availableLibrariesReducer } from './avalableLibrariesReducer';
import { counterReducer } from './counterReducer';
import { deletedElementsReducer } from './deletedElementsReducer';
import { elemToolReducer } from './elemToolReducer';
import { modalReducer } from './modalReducer';
import { isLoading as preloaderReducer } from './preloaderReducer';
import { tariffsReducer } from './tariffsReducer';
import { taskReducer } from './taskReducer';
import { validReducer } from './validReducer';

import { reducer as fileManagerReducer } from '../slices/fileManagerSlice';
import { reducer as projectsReducer } from '../slices/projectsSlice';
import { reducer as schemaReducer } from '../slices/schema';
import { reducer as workspaceReducer } from '../slices/workspace';

const rootReducer = combineReducers({
    fileManager: fileManagerReducer,
    app: appReducer,
    appUser: appUserReducer,
    projects: projectsReducer,
    elemTool: elemToolReducer,
    workspace: workspaceReducer,
    availableLibraries: availableLibrariesReducer,
    counter: counterReducer,
    valid: validReducer,
    modal: modalReducer,
    isLoading: preloaderReducer,
    users: adminReducer,
    task: taskReducer, // данные из формы Управление моделью на левой панели
    deletedElements: deletedElementsReducer,
    tariffs: tariffsReducer,
    schema: schemaReducer,
});

export default rootReducer;
