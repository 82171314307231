import { FieldErrors, FieldValues } from 'react-hook-form';

import DOMPurify from 'dompurify';

import {
    ElemProps,
    ILibraryItem,
    PlatformTypes,
    Project,
    TApiKey,
    TPlatformType,
    TState,
    TStateAccount,
} from '@repeat/models';

export const convertToConventionalNum = (val: number | string, defaultVal = 0) =>
    parseInt(val.toString()) || defaultVal;

export const isValue = <T>(val: T | undefined | null): val is T => {
    return val !== undefined && val !== null;
};

export const exceptObjectByKeys = <T extends TState | TStateAccount>(stateKeys: Array<keyof T>, state: T): T =>
    Object.entries(state).reduce(
        (accu, [key, val]) => ({
            ...accu,
            ...(!stateKeys.some((sKey) => sKey === key) && { [key]: val }),
        }),
        <T>{}
    );

export const sanitizePhone = (value: string) => value.replace(/-/g, '').replace(/[()]/g, '');

export const sanitizeHTML = (content: string): { __html: string } => {
    const defaultOptions = {
        ALLOWED_TAGS: ['div', 'p', 'ol', 'ul', 'li', 'br', 'a', 'b', 'video', 'img', 'sup', 'sub'],
        ALLOWED_ATTR: ['type', 'href', 'src', 'target', 'rel'],
    };
    return { __html: DOMPurify.sanitize(content, { ...defaultOptions }) };
};

export const validateEmptySpaces = (value: string) => value.trim().length !== 0;

export const stringTimeToMs = (stringTime: string): number => {
    const splitTime = stringTime.split(':').map((part: string) => Number(part));
    const timeInSeconds = 3600000 * splitTime[0] + 60000 * splitTime[1] + 1000 * splitTime[2];

    return timeInSeconds;
};
export const msTimeToString = (timeMs: number): string => {
    const HOUR = 3600000;
    const MINUTE = 60000;
    const SECOND = 1000;

    const hours = Math.floor(timeMs / HOUR);
    const minutes = Math.floor((timeMs - hours * HOUR) / MINUTE);
    const seconds = Math.floor((timeMs - hours * HOUR - minutes * MINUTE) / SECOND);
    const milliseconds = timeMs - hours * HOUR - minutes * MINUTE - seconds * SECOND;

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
        2,
        '0'
    )}.${String(milliseconds).padStart(3, '0')}`;
};

export const extractLetters = (str: string) => {
    return str.replace(/[^a-zA-Z]/gi, '').replace(/\s+/gi, ', ');
};

export const extractNumbers = (str: string) => {
    return Number(str.replace(/[^0-9]/g, ''));
};
export const containsNumbersOnly = (str: string) => {
    return /^\d+$/.test(str);
};

const userDevices = [
    { device: PlatformTypes.WINDOWS, platform: /Windows NT/ },
    { device: PlatformTypes.MAC_OS, platform: /Macintosh/ },
    { device: PlatformTypes.ASTRA_LINUX, platform: /Linux/ },
];

const platform = navigator.userAgent;

export const getPlatform = () => {
    return userDevices.find((item) => item.platform.test(platform))?.device as TPlatformType;
};
export const propertiesSetsFormatting = (
    obj: { currentProperties: { [key: string]: string | number } },
    targetPropsArray: ElemProps[]
) => {
    const arr = Object.keys(obj.currentProperties);
    const newArr: ElemProps[] = [];
    targetPropsArray.map((prop) => {
        arr.map((setProp) => {
            if (prop.name === setProp) {
                newArr.push({ ...prop, name: setProp, value: obj.currentProperties[setProp] });
            }
        });
    });

    const arrResult = targetPropsArray.map((prop) => {
        if (arr.includes(prop.name)) {
            return { ...prop, value: obj.currentProperties[prop.name], editable: false };
        }
        return prop;
    });

    return arrResult;
};

export const getHandleName = (handleId: string) => {
    const splittedHandleId = handleId.split('-');
    return splittedHandleId[splittedHandleId.length - 1];
};

export const updateHandleId = (oldHandleId: string, newNodeId: string) => {
    const splittedHandleId = oldHandleId.split('-');
    splittedHandleId.splice(0, 1, newNodeId);
    return splittedHandleId.join('-');
};

export const updateHandleIdWithHandleName = (oldHandleId: string, newHandleName: string) => {
    const index = extractNumbers(newHandleName).toString();
    const splittedHandleId = oldHandleId.split('-');
    splittedHandleId.splice(-2, 1, index);
    splittedHandleId.splice(-1, 1, newHandleName);
    return splittedHandleId.join('-');
};

export const formValidAndNoErrors = (errors: FieldErrors<FieldValues>, isValid: boolean) =>
    Boolean(Object.keys(errors).length) || !isValid;

export const hexToRGB = (hex: string, alpha?: string) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    return `rgb(${r}, ${g}, ${b})`;
};

export const isCyrrilicLetter = (letter: string): boolean => {
    const cyrillicPattern = /[а-яА-ЯЁё]/;
    return cyrillicPattern.test(letter);
};

export const transliterate = (text: string, spaceReplacement?: string): string => {
    const letters: { [key: string]: string } = {
        а: 'a',
        б: 'b',
        в: 'v',
        г: 'g',
        д: 'd',
        е: 'е',
        ё: 'yo',
        ж: 'zh',
        з: 'z',
        и: 'i',
        й: 'y',
        к: 'k',
        л: 'l',
        м: 'm',
        н: 'n',
        о: 'o',
        п: 'p',
        р: 'r',
        с: 's',
        т: 't',
        у: 'u',
        ф: 'f',
        х: 'kh',
        ц: 'ts',
        ч: 'ch',
        ш: 'sh',
        щ: 'shch',
        ъ: '',
        ы: 'i',
        ь: '',
        э: 'e',
        ю: 'yu',
        я: 'ya',
    };

    if (text.length === 0) {
        return '';
    }

    const normalizedText = text.normalize('NFC');

    let translitText = '';
    for (let i = 0; i < normalizedText.length; i++) {
        const letterLowerCase = normalizedText[i].toLowerCase();

        if (letterLowerCase === ' ') {
            translitText += spaceReplacement ? spaceReplacement : ' ';
            continue;
        }

        if (!isCyrrilicLetter(letterLowerCase)) {
            translitText += letterLowerCase;
            continue;
        }

        const newLetter = letters[letterLowerCase] || '-';

        translitText += newLetter;
    }

    return translitText;
};

export const replaceKeys = (text: string): string => {
    const map: { [key: string]: string } = {
        q: 'й',
        w: 'ц',
        e: 'у',
        r: 'к',
        t: 'е',
        y: 'н',
        u: 'г',
        i: 'ш',
        o: 'щ',
        p: 'з',
        '[': 'х',
        ']': 'ъ',
        a: 'ф',
        s: 'ы',
        d: 'в',
        f: 'а',
        g: 'п',
        h: 'р',
        j: 'о',
        k: 'л',
        l: 'д',
        ';': 'ж',
        "'": 'э',
        z: 'я',
        x: 'ч',
        c: 'с',
        v: 'м',
        b: 'и',
        n: 'т',
        m: 'ь',
        ',': 'б',
        '.': 'ю',
        й: 'q',
        ц: 'w',
        у: 'e',
        к: 'r',
        е: 't',
        н: 'y',
        г: 'u',
        ш: 'i',
        щ: 'o',
        з: 'p',
        х: '[',
        ъ: ']',
        ф: 'a',
        ы: 's',
        в: 'd',
        а: 'f',
        п: 'g',
        р: 'h',
        о: 'j',
        л: 'k',
        д: 'l',
        ж: ';',
        э: "'",
        я: 'z',
        ч: 'x',
        с: 'c',
        м: 'v',
        и: 'b',
        т: 'n',
        ь: 'm',
        б: ',',
        ю: '.',
    };

    return text
        .split('')
        .map(function (char) {
            return map[char.toLowerCase()] || char;
        })
        .join('');
};

const htmlByUnicode: { [key: string]: string } = {
    '\\u00b2': '<sup>2</sup>',
    '\\u00B7': '&#183;',
    '\\u2074': '<sup>4</sup>',
};

const regexUnicodeKeys = new RegExp(
    Object.keys(htmlByUnicode)
        .map((str) => str.replace('\\', '\\\\'))
        .join('|'),
    'g'
);
export const unicodeToHtml = (text: string) => {
    return text.replace(regexUnicodeKeys, (match) => htmlByUnicode[match]);
};

export const unicodeReplaceSymbol = (input: string) => {
    return input?.replace(regexUnicodeKeys, (match) => String.fromCharCode(parseInt(match.substring(2), 16)));
};

export const validateExistingApiKeyName = (value: string, items: TApiKey[], excludeId?: number): boolean =>
    !items.filter((item) => {
        if (excludeId && item.id == excludeId) {
            return false;
        }

        return item.name === value.trim();
    }).length;

export const validateExistingProjectName = (value: string, projects: Project[], excludeProjectId?: number): boolean =>
    !projects.filter((p) => {
        if (excludeProjectId && p.projectId == excludeProjectId) {
            return false;
        }

        return p.projectName === value.trim();
    }).length;

export const getUniqueProjectName = (originalProjectName: string, projects: Project[]): string => {
    let isExistingName = false;
    let projectName = originalProjectName;
    let projectNameCounter = 0;
    do {
        isExistingName = !validateExistingProjectName(projectName, projects);
        if (isExistingName) {
            projectNameCounter++;
            projectName = `${originalProjectName}-${projectNameCounter}`;
        }
    } while (isExistingName);

    return projectName;
};

export const validateExistingBlockName = (
    value: string,
    engineerBlocks: ILibraryItem[],
    excludeBlockType?: string
): boolean =>
    !engineerBlocks.filter((devBlock) => {
        if (excludeBlockType && devBlock.type == excludeBlockType) {
            return false;
        }

        return devBlock.name === value.trim();
    }).length;

export const createBlobFile = <T>(data: T, contentType: string): string => {
    const content = typeof data === 'string' || data instanceof String ? (data as string) : JSON.stringify(data);
    const blob = new Blob(
        [
            new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8 BOM
            content,
        ],
        { type: `${contentType};charset=utf-8` }
    );

    return URL.createObjectURL(blob);
};

export const createBlob = (data: string): Blob => {
    return new Blob(['\uFEFF', data], { type: 'text/csv;charset=utf-8;' });
};

export function compareIgnoreCase(arg1: string, arg2: string) {
    return arg1.toLowerCase() === arg2.toLowerCase();
}

export const sanitizeFilename = (name: string) => transliterate(name).replace(/\s+/g, '-').toLowerCase();

export const hex2rgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null;
};

export const logoLocale = (locale: string) => (['ru', 'en'].includes(locale) ? locale : 'en');

export const monoLogoUrl = (locale: string) => {
    return `/assets/logo/repeat-logo-white-${logoLocale(locale)}.png`;
};
export const colorLogoUrl = (locale: string) => {
    return `/assets/logo/repeat-horizontal-${logoLocale(locale)}.png`;
};

const getCookieLifeTime = () => {
    const date = new Date();
    date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);
    return date.toUTCString();
};

const getNegativeCookieLifeTime = () => {
    const date = new Date();
    date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);
    return date.toUTCString();
};

export function getCookie(name: string): string | null {
    const nameLenPlus = name.length + 1;
    return (
        document.cookie
            .split(';')
            .map((c) => c.trim())
            .filter((cookie) => {
                return cookie.substring(0, nameLenPlus) === `${name}=`;
            })
            .map((cookie) => {
                return decodeURIComponent(cookie.substring(nameLenPlus));
            })[0] || null
    );
}

export function setCookie(name: string, value: string) {
    document.cookie = name + '=' + value + '; expires=' + getCookieLifeTime() + '; path=/';
}

export function deleteCookie(name: string) {
    document.cookie = name + '=; expires=' + getNegativeCookieLifeTime() + '; path=/';
}

export function fuzzyСomparison(substr: string, str: string) {
    let count = 0;

    for (let i = 0; i < str.length; i++) {
        if (str[i] === substr[count]) {
            count++;
        }
    }

    return substr.length === count;
}

export * from './license';
export * from './locale';
export * from './math';
export * from './modelTime';
export * from './schema';
export * from './screenshotGenerator';
export * from './titleGenerator';
export * from './submodels';

