import { FC, ReactElement, ReactNode } from 'react';

import styled from 'styled-components';

import { StepIconProps } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';

import { pxToRem, uiColors } from '../../config/config';

export type TStepType = {
    stepIndex: number;
    stepChild: ReactNode | ReactElement;
};

export type IStepperProps = {
    currentIndex: number;
    steps?: TStepType[];
    children?: React.ReactNode;
};

export interface TStepIconProps extends Omit<StepIconProps, 'icon'> {
    children: React.ReactNode;
}

interface IIconProps {
    completed?: boolean;
    active?: boolean;
}

const SStepIcon = muiStyled('div')<IIconProps>(({ completed, active }) => ({
    backgroundColor: uiColors.darkGrey,
    zIndex: 1,
    color: uiColors.white,
    width: pxToRem(28),
    height: pxToRem(28),
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background 0.3s ease',
    ...(active && {
        background: uiColors.darkBlue,
    }),
    ...(completed && {
        background: uiColors.green,
    }),
    '& > i': {
        maxWidth: pxToRem(18),
        maxHeight: pxToRem(18),
    },
}));

const CustomStepIcon: FC<TStepIconProps> = ({ children, ...props }) => {
    const { active = false, completed = false, className } = props;
    return <SStepIcon {...{ completed, active, className }}>{children}</SStepIcon>;
};

const SStepIconWrapperItem = styled.li<IIconProps>`
    margin-right: ${pxToRem(24)};
    position: relative;

    &::after {
        background: ${uiColors.darkGrey};
        content: '';
        height: ${pxToRem(2)};
        left: 100%;
        top: calc(50% - 0.5px);
        position: absolute;
        transition: background 0.3s ease;
        width: ${pxToRem(32)};
    }

    ${({ active }) =>
        active &&
        `
        &::after {
            background: ${uiColors.darkBlue};
        }
    `}

    ${({ completed }) =>
        completed &&
        `
        &::after {
            background: ${uiColors.green};
        }
    `}

    &:last-child::after {
        display: none;
    }
`;

const SStepIconWrapper = styled.ul`
    display: flex;
    margin: 0 0 ${pxToRem(24)};
    padding: 0;
`;

export const FormStepper: FC<IStepperProps> = ({ currentIndex, steps }) => {
    if (steps?.length) {
        return (
            <SStepIconWrapper>
                {steps.map((step: TStepType) => {
                    const { stepIndex, stepChild } = step;
                    const randomId = Math.random() * 100;
                    return (
                        <SStepIconWrapperItem
                            active={currentIndex === stepIndex}
                            completed={currentIndex > stepIndex}
                            key={`form-stepper-icon-${stepIndex * randomId}-${currentIndex === stepIndex}`}
                        >
                            <CustomStepIcon
                                key={`form-stepper-custom-icon-${stepIndex}`}
                                {...{ active: currentIndex === stepIndex, completed: currentIndex > stepIndex }}
                            >
                                {stepChild}
                            </CustomStepIcon>
                        </SStepIconWrapperItem>
                    );
                })}
            </SStepIconWrapper>
        );
    }
    return null;
};
