import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages: { [key: string]: { id: string; defaultMessage: string } } = defineMessages({
    [TranslationKey.CONTEXT_SEARCH_PLACEHOLDER]: {
        id: TranslationKey.CONTEXT_SEARCH_PLACEHOLDER,
        defaultMessage: ru[TranslationKey.CONTEXT_SEARCH_PLACEHOLDER],
    },
});
