import styled from 'styled-components';

import { pxToRem, radius, uiColors } from '../../config/config';

export const SDropZone = styled.div<{ hasError: boolean }>`
    background: ${uiColors.lightGrey};
    border: 2px dashed ${uiColors.grey};
    border-radius: ${radius.default};
    padding: ${pxToRem(48)};

    ${({ hasError }) =>
        hasError &&
        `
        border: 2px dashed ${uiColors.red};
        background: rgba(211,47,47, 0.2);
    `}

    [data-error] {
        color: ${uiColors.red};
    }

    button {
        height: auto;
        margin: ${pxToRem(16)} 0 0;
        max-width: 200px;
        padding: ${pxToRem(8)} ${pxToRem(12)};
    }
`;

export const SDropZoneText = styled.p`
    color: ${uiColors.darkGrey};
    font-size: ${pxToRem(14)};
`;

export const SDropZoneFileList = styled.ul`
    font-size: ${pxToRem(14)};
    color: ${uiColors.darkGrey};
    margin: ${pxToRem(16)} 0;
`;
