import { FC } from 'react';

import styled from 'styled-components';

import { uiRGBColors, breakPoints, pxToRem } from '../../config/config';

const SDataRow = styled.div<{ column?: boolean }>`
    align-items: flex-start;
    display: flex;
    margin-bottom: ${pxToRem(16)};
    ${({ column }) => column && 'flex-direction: column;'};

    @media screen and (${breakPoints.large}) {
        flex-direction: column;
    }
`;

const SDataRowName = styled.div<{ flexBasis?: string }>`
    color: rgb(${uiRGBColors.grey});
    font-size: ${pxToRem(14)};
    flex-basis: ${({ flexBasis }) => (flexBasis ? flexBasis : '30%')};
    padding: 0;

    @media screen and (${breakPoints.large}) {
        margin-bottom: ${pxToRem(8)};
    }
`;

const SDataRowValue = styled.div`
    font-size: ${pxToRem(16)};
    width: 100%;
`;

export interface IDataRow {
    name: string;
    style?: any;
    column?: boolean;
    children: React.ReactNode;
    nameFlexBasis?: string;
}

export const DataRow: FC<IDataRow> = ({ name, children, nameFlexBasis, column = false, ...rest }) => {
    return (
        <SDataRow column={column} {...rest}>
            <SDataRowName flexBasis={nameFlexBasis}>{name}</SDataRowName>
            <SDataRowValue>{children}</SDataRowValue>
        </SDataRow>
    );
};
