import styled from 'styled-components';

import { transitionAnimation, verticalAlign } from 'libs/ui-kit/src/config/mixin';

import { pxToRem, radius, uiColors } from '@repeat/ui-kit';

export const SVersionsContent = styled.div`
    padding: ${pxToRem(24)};
    overflow-y: auto;
    height: 100%;

    h3 {
        color: var(--ui-heading);
        margin-bottom: ${pxToRem(24)};
    }

    h5 {
        font-size: ${pxToRem(22)};
        margin-bottom: ${pxToRem(12)};
    }

    p,
    ul li,
    ol li {
        line-height: ${pxToRem(20)};
    }

    ul,
    ol {
        display: block;
        margin: ${pxToRem(8)} 0;
        padding: 0 0 0 ${pxToRem(32)};
    }
    ol {
        list-style-type: decimal;
    }
    ul {
        list-style-type: '— ';
    }

    li {
        display: list-item;
    }

    p {
        margin-bottom: ${pxToRem(8)};
        text-indent: 0;
    }

    article {
        max-width: ${pxToRem(1024)};
        background-color: var(--ui-sidebar);
        margin-bottom: ${pxToRem(12)};
        border-radius: ${radius.default};
        padding: ${pxToRem(24)};
        overflow: hidden;
    }

    a {
        color: var(--ui-link-default);
        text-decoration: none;
        border: none;
        :hover {
            color: var(--ui-link-active);
        }
    }
`;

export const SSidebarVersions = styled.div`
    font-size: ${pxToRem(14)};
    padding: 0 ${pxToRem(16)};
    overflow-y: auto;
    height: calc(100vh - ${pxToRem(48)});

    > * {
        ${verticalAlign()};
        color: var(--ui-heading);
        margin: ${pxToRem(16)} 0;
        text-decoration: none;
    }

    i {
        margin-right: ${pxToRem(8)};
        width: ${pxToRem(24)};

        svg {
            fill: var(--ui-heading);
        }
    }

    span {
        margin-left: 0 !important;
    }

    svg {
        ${transitionAnimation(['fill'])};
    }

    a {
        &:hover,
        &.active {
            color: var(--ui-link-active);

            svg {
                fill: var(--ui-link-active);
            }
        }
    }
`;

export const SVersionTitle = styled.span`
    font-weight: 600;
`;

export const ReleaseContainer = styled.div<{ isModal: boolean | undefined }>`
    ${({ isModal }) =>
        isModal
            ? `:nth-child(1) {
        padding-top: ${pxToRem(8)};
    };`
            : null};

    padding-top: ${pxToRem(24)};
    border-bottom: 1.5px solid ${uiColors.borderGrey};
    :last-child {
        border-bottom: initial;
    }
`;

export const ReleaseHeader = styled.p`
    font-weight: bold;
    font-size: ${pxToRem(18)};
`;

export const ReleaseSubheader = styled.p`
    color: ${uiColors.darkGrey};
    font-size: ${pxToRem(16)};
`;
export const ReleaseBlock = styled.div`
    margin-top: ${pxToRem(16)};
    margin-bottom: ${pxToRem(16)};
    :last-child {
        margin-bottom: ${pxToRem(24)};
    }
`;
export const ReleaseBlockContent = styled.div`
    margin-top: ${pxToRem(4)};
`;
