import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useDebouncedCallback } from 'use-debounce';

import { IPV4_OR_LOCALHOST, MEMORY_USAGE_LIMIT, remainderOfDivision } from '@repeat/constants';
import { useAppDispatch, useAppSelector, useDemoMode } from '@repeat/hooks';
import { IProjectSettingsOptions, TProjectSettingsOption } from '@repeat/models';
import { compareObjects } from '@repeat/services';
import { elementsSelectors, workspaceActions, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import {
    IconButton,
    InputField,
    InputVariant,
    Label,
    ShowMore,
    TInputVariant,
    Toggle,
    Tooltip,
    TooltipPosition,
    TooltipVariant,
    uiColors,
} from '@repeat/ui-kit';

import {
    SCustomIcon,
    SLabelWrapper,
    SProjectSettingsCompactWrapper,
    SProjectSettingsItem,
    SProjectSettingsWrapper,
} from './SProjectSettings';
import { memoryCalculation as handleCalculation } from './memoryCalculation';
import { messages } from './translate';

const MODEL_PORT_MIN_VALUE = 0;
const MODEL_PORT_MAX_VALUE = 65535;

export enum ProjectSettingsItemTypes {
    FULL = 'full',
    COMPACT = 'compact',
}
export type TProjectSettingsType = ProjectSettingsItemTypes.FULL | ProjectSettingsItemTypes.COMPACT;

interface IProjectSettings {
    children?: React.ReactNode;
    mode?: TProjectSettingsType;
    onClick?: (event?: MouseEvent) => void;
}

interface IFieldVariant {
    fields: string[];
    variant: TInputVariant;
    text?: string;
}

export const ProjectSettings: FC<IProjectSettings> = ({ children, mode = 'full', onClick }) => {
    const { formatMessage } = useIntl();
    const dispatch = useAppDispatch();

    const elements = useAppSelector(elementsSelectors.getElements);
    const elementsWithParams = useAppSelector(elementsSelectors.getElementsWithParameters);
    const { elements: selectedElements } = useAppSelector(elementsSelectors.getSelectedElements);
    const storedProjectSettings = useAppSelector(workspaceSelectors.getProjectSettings);
    const [projectSettings, setProjectSettings] = useState<IProjectSettingsOptions>({ ...storedProjectSettings });
    const isAdmin = useAppSelector((state) => state.appUser.currentUser?.isAdmin);
    const { isDemo } = useDemoMode();

    const [variant, setVariant] = useState<IFieldVariant>({
        fields: [],
        variant: 'default',
    });

    const {
        register,
        formState: { errors },
        clearErrors,
        setError,
    } = useForm<IProjectSettingsOptions>({
        mode: 'onChange',
        reValidateMode: 'onBlur',
        defaultValues: { ...projectSettings },
    });

    const [status, setStatus] = useState<TInputVariant>(InputVariant.DEFAULT);
    const [calculation, setCalculation] = useState<number>(0);

    const debounce = useDebouncedCallback(
        (func: (key: string, value: TProjectSettingsOption) => void, data: Record<string, any>) => {
            func(Object.keys(data)[0], data[Object.keys(data)[0]]);
        },
        1000
    );

    useEffect(() => {
        // TODO come with idea how to avoid useEffect errors setting (use only validate rules of react hook form)
        if (projectSettings.isRealTimeSync) {
            if (Number(projectSettings.modellingTime) > parseInt(projectSettings.modellingTime)) {
                setError('modellingTime', {
                    message: formatMessage(messages[TranslationKey.WORKSPACE_PROJECT_INT_VALUE_ERROR]),
                });
            } else if (Number(projectSettings.integrationStep) <= Number(projectSettings.discretizationStep)) {
                clearErrors('modellingTime');
            } else {
                setError('modellingTime', {
                    message: formatMessage(messages[TranslationKey.WORKSPACE_MEMORY_LIMIT_INTEGRATION_STEP_ERROR]),
                });
            }

            if (Number(projectSettings.integrationStep) > parseInt(projectSettings.integrationStep)) {
                setError('integrationStep', {
                    message: formatMessage(messages[TranslationKey.WORKSPACE_PROJECT_INT_VALUE_ERROR]),
                });
            } else if (Number(projectSettings.integrationStep) <= Number(projectSettings.discretizationStep)) {
                clearErrors('integrationStep');
            } else {
                setError('integrationStep', {
                    message: formatMessage(messages[TranslationKey.WORKSPACE_MEMORY_LIMIT_INTEGRATION_STEP_ERROR]),
                });
            }

            if (Number(projectSettings.discretizationStep) > parseInt(projectSettings.discretizationStep)) {
                setError('discretizationStep', {
                    message: formatMessage(messages[TranslationKey.WORKSPACE_PROJECT_INT_VALUE_ERROR]),
                });
            } else {
                if (
                    Number(projectSettings.discretizationStep) >= Number(projectSettings.integrationStep) &&
                    remainderOfDivision(
                        Number(projectSettings.discretizationStep),
                        Number(projectSettings.integrationStep)
                    ) === 0
                ) {
                    clearErrors('discretizationStep');
                } else {
                    if (
                        remainderOfDivision(
                            Number(projectSettings.discretizationStep),
                            Number(projectSettings.integrationStep)
                        ) > 0
                    ) {
                        setError('discretizationStep', {
                            message: formatMessage(
                                messages[TranslationKey.WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP_MULTIPLICITY_ERROR]
                            ),
                        });
                    } else {
                        setError('discretizationStep', {
                            message: formatMessage(
                                messages[TranslationKey.WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP_ERROR]
                            ),
                        });
                    }
                }
            }
        } else {
            if (Number(projectSettings.modellingTime) >= parseInt(projectSettings.modellingTime)) {
                clearErrors('modellingTime');
            }

            if (Number(projectSettings.integrationStep) <= Number(projectSettings.discretizationStep)) {
                clearErrors('integrationStep');
            } else {
                setError('integrationStep', {
                    message: formatMessage(messages[TranslationKey.WORKSPACE_MEMORY_LIMIT_INTEGRATION_STEP_ERROR]),
                });
            }

            if (
                Number(projectSettings.discretizationStep) >= Number(projectSettings.integrationStep) &&
                remainderOfDivision(
                    Number(projectSettings.discretizationStep),
                    Number(projectSettings.integrationStep)
                ) === 0
            ) {
                clearErrors('discretizationStep');
            } else {
                if (
                    remainderOfDivision(
                        Number(projectSettings.discretizationStep),
                        Number(projectSettings.integrationStep)
                    ) > 0
                ) {
                    setError('discretizationStep', {
                        message: formatMessage(
                            messages[TranslationKey.WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP_MULTIPLICITY_ERROR]
                        ),
                    });
                } else {
                    setError('discretizationStep', {
                        message: formatMessage(
                            messages[TranslationKey.WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP_ERROR]
                        ),
                    });
                }
            }
        }
    }, [projectSettings]);

    const handleToggleChange = (name: string, value: TProjectSettingsOption) => {
        const newProjectSettings: IProjectSettingsOptions = { ...projectSettings, [name]: value };
        dispatch(workspaceActions.setProjectSettings(newProjectSettings));
    };

    const handleOnChange = async (event: ChangeEvent) => {
        const target = event.target as HTMLInputElement;
        const name = target.name as keyof typeof projectSettings;
        const value = target.value;
        await setProjectSettings({ ...projectSettings, [name]: value.trim() });
        setVariant({ fields: [name], variant: InputVariant.WARNING });

        return debounce(handleSettingsChange, { [target.name]: target.value.trim() });
    };

    const handleSettingsChange = (name: string, value: TProjectSettingsOption) => {
        if (Object.keys(errors).includes(name)) {
            return;
        }

        return new Promise((resolve) => {
            const newProjectSettings: IProjectSettingsOptions = { ...projectSettings, [name]: value };
            const keys = compareObjects(projectSettings, newProjectSettings);
            return setTimeout(() => {
                if (keys.length) {
                    resolve(newProjectSettings as IProjectSettingsOptions);
                }
            }, 500);
        })
            .then((data: IProjectSettingsOptions) => {
                dispatch(workspaceActions.setProjectSettings(data));
                return { ...projectSettings, [name]: value };
            })
            .then(() => {
                const newProjectSettings = { ...projectSettings, [name]: value };
                const keys = compareObjects(projectSettings, newProjectSettings);
                setVariant({ ...variant, fields: keys, variant: InputVariant.SUCCESS });
            })
            .then(() => {
                setTimeout(() => setVariant({ ...variant, fields: [], variant: InputVariant.DEFAULT }), 1000);
            });
    };

    useEffect(() => {
        const newSettings = { ...projectSettings, ...storedProjectSettings };
        const keys = compareObjects(projectSettings, storedProjectSettings);
        if (keys.length) {
            setProjectSettings(newSettings);
        }
    }, [storedProjectSettings]);

    useEffect(() => {
        handleCalculation(elements, projectSettings, setCalculation);
    }, [elements, elementsWithParams, projectSettings]);

    useEffect(() => {
        if (calculation < MEMORY_USAGE_LIMIT) {
            setStatus(InputVariant.DEFAULT);
        }
        if (calculation >= MEMORY_USAGE_LIMIT - 30) {
            setStatus(InputVariant.WARNING);
        }
        if (calculation >= MEMORY_USAGE_LIMIT) {
            setStatus(InputVariant.DANGER);
        }
    }, [calculation]);

    if (mode === ProjectSettingsItemTypes.FULL) {
        return (
            <SProjectSettingsWrapper>
                {children}
                <form
                    action=''
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                >
                    <ShowMore title={formatMessage(messages[TranslationKey.WORKSPACE_MEMORY_LIMIT_MODELLING])}>
                        <SProjectSettingsItem>
                            <Toggle
                                disabled={isDemo}
                                isChecked={projectSettings.isRealTimeSync}
                                label={
                                    <>
                                        {formatMessage(messages[TranslationKey.WORKSPACE_MEMORY_LIMIT_REALTIME_SYNC])}
                                        <Tooltip
                                            text={formatMessage(
                                                messages[TranslationKey.WORKSPACE_MEMORY_LIMIT_REALTIME_SYNC_TOOLTIP]
                                            )}
                                            position={TooltipPosition.LEFT}
                                            variant={TooltipVariant.LIGHT}
                                            tooltipWidth={200}
                                        >
                                            <SCustomIcon name='help' fill={uiColors.darkGrey} />
                                        </Tooltip>
                                    </>
                                }
                                onClick={(event) => {
                                    setProjectSettings({ ...projectSettings, isRealTimeSync: event });
                                    handleToggleChange('isRealTimeSync', event);
                                }}
                            />
                        </SProjectSettingsItem>
                        <SProjectSettingsItem>
                            <InputField
                                variant={
                                    !errors?.modellingTime && variant.fields.includes('modellingTime')
                                        ? variant.variant
                                        : InputVariant.DEFAULT
                                }
                                error={!!errors?.modellingTime}
                                errorText={
                                    Object.keys(errors).includes('modellingTime') ? errors?.modellingTime?.message : ''
                                }
                                type={'text'}
                                value={projectSettings.modellingTime}
                                label={
                                    <SLabelWrapper>
                                        {formatMessage(messages[TranslationKey.WORKSPACE_MEMORY_LIMIT_MODELLING_TIME])}
                                        <Tooltip
                                            text={formatMessage(
                                                messages[TranslationKey.WORKSPACE_MEMORY_LIMIT_MODELLING_TIME_TOOLTIP]
                                            )}
                                            position={TooltipPosition.LEFT}
                                            variant={TooltipVariant.LIGHT}
                                            tooltipWidth={200}
                                        >
                                            <SCustomIcon name='help' fill={uiColors.darkGrey} />
                                        </Tooltip>
                                    </SLabelWrapper>
                                }
                                size={'small'}
                                {...register('modellingTime', {
                                    disabled: isDemo,
                                    required: formatMessage(messages[TranslationKey.OBLIGATORY_FIELD_MSG]),
                                    onChange: handleOnChange,
                                    validate: {
                                        moreThanNull: (v) => {
                                            if (projectSettings.isRealTimeSync) {
                                                if (Number(v) > parseInt(v)) {
                                                    return formatMessage(
                                                        messages[TranslationKey.WORKSPACE_PROJECT_INT_VALUE_ERROR]
                                                    );
                                                }

                                                return (
                                                    parseInt(v) > 0 ||
                                                    formatMessage(
                                                        messages[TranslationKey.WORKSPACE_MEMORY_LIMIT_MORE_THAN_ZERO]
                                                    )
                                                );
                                            }
                                            return (
                                                Number(v) > 0 ||
                                                formatMessage(
                                                    messages[TranslationKey.WORKSPACE_MEMORY_LIMIT_MORE_THAN_ZERO]
                                                )
                                            );
                                        },
                                    },
                                })}
                            />
                        </SProjectSettingsItem>
                        <SProjectSettingsItem>
                            <InputField
                                variant={
                                    !errors?.integrationStep && variant.fields.includes('integrationStep')
                                        ? variant.variant
                                        : InputVariant.DEFAULT
                                }
                                error={!!errors?.integrationStep}
                                errorText={
                                    Object.keys(errors).includes('integrationStep')
                                        ? errors?.integrationStep?.message
                                        : ''
                                }
                                type={'text'}
                                value={projectSettings.integrationStep}
                                label={
                                    <SLabelWrapper>
                                        {formatMessage(
                                            messages[TranslationKey.WORKSPACE_MEMORY_LIMIT_INTEGRATION_STEP]
                                        )}
                                        <Tooltip
                                            text={formatMessage(
                                                messages[TranslationKey.WORKSPACE_MEMORY_LIMIT_INTEGRATION_STEP_TOOLTIP]
                                            )}
                                            position={TooltipPosition.LEFT}
                                            variant={TooltipVariant.LIGHT}
                                            tooltipWidth={200}
                                        >
                                            <SCustomIcon name='help' fill={uiColors.darkGrey} />
                                        </Tooltip>
                                    </SLabelWrapper>
                                }
                                size={'small'}
                                {...register('integrationStep', {
                                    disabled: isDemo,
                                    required: formatMessage(messages[TranslationKey.OBLIGATORY_FIELD_MSG]),
                                    onChange: handleOnChange,
                                    validate: {
                                        moreThanNull: (v) => {
                                            if (projectSettings.isRealTimeSync) {
                                                if (Number(v) > parseInt(v)) {
                                                    return formatMessage(
                                                        messages[TranslationKey.WORKSPACE_PROJECT_INT_VALUE_ERROR]
                                                    );
                                                }

                                                return (
                                                    parseInt(v) > 0 ||
                                                    formatMessage(
                                                        messages[TranslationKey.WORKSPACE_MEMORY_LIMIT_MORE_THAN_ZERO]
                                                    )
                                                );
                                            }
                                            return (
                                                Number(v) > 0 ||
                                                formatMessage(
                                                    messages[TranslationKey.WORKSPACE_MEMORY_LIMIT_MORE_THAN_ZERO]
                                                )
                                            );
                                        },
                                        moreThanIntegrationStep: (v) =>
                                            Number(v) <= Number(projectSettings.discretizationStep) ||
                                            formatMessage(
                                                messages[TranslationKey.WORKSPACE_MEMORY_LIMIT_INTEGRATION_STEP_ERROR]
                                            ),
                                    },
                                })}
                            />
                        </SProjectSettingsItem>
                    </ShowMore>
                    <ShowMore title={formatMessage(messages[TranslationKey.WORKSPACE_MEMORY_LIMIT_GRAPHS])}>
                        <SProjectSettingsItem>
                            <InputField
                                variant={
                                    !errors?.discretizationStep && variant.fields.includes('discretizationStep')
                                        ? variant.variant
                                        : InputVariant.DEFAULT
                                }
                                error={!!errors?.discretizationStep}
                                errorText={
                                    Object.keys(errors).includes('discretizationStep')
                                        ? errors?.discretizationStep?.message
                                        : ''
                                }
                                type={'text'}
                                value={projectSettings.discretizationStep}
                                label={
                                    <SLabelWrapper>
                                        {formatMessage(
                                            messages[TranslationKey.WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP]
                                        )}
                                        <Tooltip
                                            text={formatMessage(
                                                messages[
                                                    TranslationKey.WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP_TOOLTIP
                                                ]
                                            )}
                                            position={TooltipPosition.LEFT}
                                            variant={TooltipVariant.LIGHT}
                                            tooltipWidth={200}
                                        >
                                            <SCustomIcon name='help' fill={uiColors.darkGrey} />
                                        </Tooltip>
                                    </SLabelWrapper>
                                }
                                size={'small'}
                                {...register('discretizationStep', {
                                    disabled: isDemo,
                                    required: formatMessage(messages[TranslationKey.OBLIGATORY_FIELD_MSG]),
                                    onChange: handleOnChange,
                                    validate: {
                                        moreThanNull: (v) =>
                                            Number(v) > 0 ||
                                            formatMessage(
                                                messages[TranslationKey.WORKSPACE_MEMORY_LIMIT_MORE_THAN_ZERO]
                                            ),
                                        moreThanIntegrationStep: (v) =>
                                            Number(v) >= Number(projectSettings.integrationStep) ||
                                            formatMessage(
                                                messages[
                                                    TranslationKey.WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP_ERROR
                                                ]
                                            ),
                                        multiplicityIntegrationStep: (v) =>
                                            remainderOfDivision(Number(v), Number(projectSettings.integrationStep)) ===
                                                0 ||
                                            formatMessage(
                                                messages[
                                                    TranslationKey
                                                        .WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP_MULTIPLICITY_ERROR
                                                ]
                                            ),
                                    },
                                })}
                            />
                        </SProjectSettingsItem>
                        <SProjectSettingsItem>
                            <Toggle
                                disabled={isDemo}
                                isChecked={projectSettings.isCompression}
                                label={
                                    <>
                                        {formatMessage(messages[TranslationKey.WORKSPACE_MEMORY_LIMIT_COMPRESSION_USE])}
                                        <Tooltip
                                            text={formatMessage(
                                                messages[TranslationKey.WORKSPACE_MEMORY_LIMIT_COMPRESSION_USE_TOOLTIP]
                                            )}
                                            position={TooltipPosition.LEFT}
                                            variant={TooltipVariant.LIGHT}
                                            tooltipWidth={200}
                                        >
                                            <SCustomIcon name='help' fill={uiColors.darkGrey} />
                                        </Tooltip>
                                    </>
                                }
                                onClick={(event) => {
                                    setProjectSettings({ ...projectSettings, isCompression: event });
                                    handleToggleChange('isCompression', event);
                                }}
                            />
                        </SProjectSettingsItem>
                        <SProjectSettingsItem>
                            {projectSettings.isCompression && (
                                <>
                                    <Label>
                                        {formatMessage(
                                            messages[TranslationKey.WORKSPACE_MEMORY_LIMIT_COMPRESSION_TYPE]
                                        )}
                                    </Label>
                                    <Select
                                        disabled={isDemo}
                                        style={{ width: '100%' }}
                                        size={'small'}
                                        value={projectSettings.compressionType}
                                        name='compressionType'
                                        onChange={(event: SelectChangeEvent) =>
                                            debounce(handleSettingsChange, {
                                                [event.target.name]: event.target.value.trim(),
                                            })
                                        }
                                    >
                                        <MenuItem style={{ width: '100%' }} value={'RDP'}>
                                            RDP
                                        </MenuItem>
                                    </Select>
                                </>
                            )}
                        </SProjectSettingsItem>
                        <SProjectSettingsItem>
                            {projectSettings.isCompression && (
                                <InputField
                                    error={!!errors?.compressionAccuracy}
                                    errorText={
                                        variant.fields.includes('compressionAccuracy')
                                            ? errors?.compressionAccuracy?.message
                                            : ''
                                    }
                                    variant={
                                        !errors?.compressionAccuracy && variant.fields.includes('compressionAccuracy')
                                            ? variant.variant
                                            : InputVariant.DEFAULT
                                    }
                                    type={'text'}
                                    value={projectSettings.compressionAccuracy}
                                    label={formatMessage(
                                        messages[TranslationKey.WORKSPACE_MEMORY_LIMIT_COMPRESSION_ACCURACY]
                                    )}
                                    size={'small'}
                                    {...register('compressionAccuracy', {
                                        disabled: isDemo,
                                        required: formatMessage(messages[TranslationKey.OBLIGATORY_FIELD_MSG]),
                                        onChange: handleOnChange,
                                        validate: {
                                            moreThanNull: (v) =>
                                                parseFloat(v) > 0 ||
                                                formatMessage(
                                                    messages[TranslationKey.WORKSPACE_MEMORY_LIMIT_MORE_THAN_ZERO]
                                                ),
                                        },
                                    })}
                                />
                            )}
                        </SProjectSettingsItem>
                    </ShowMore>
                    <ShowMore title={formatMessage(messages[TranslationKey.WORKSPACE_MEMORY_LIMIT_MEMORY_CALCULATION])}>
                        <SProjectSettingsItem>
                            <InputField
                                type={'text'}
                                variant={status}
                                size={'small'}
                                disabled
                                value={calculation.toString()}
                                label={
                                    <SLabelWrapper>
                                        {formatMessage(messages[TranslationKey.WORKSPACE_MEMORY_LIMIT_CALCULATION])}
                                        <Tooltip
                                            text={
                                                <span>
                                                    {formatMessage(messages[TranslationKey.WORKSPACE_MEMORY_USAGE])},
                                                    <br />
                                                    {formatMessage(messages[TranslationKey.WORKSPACE_MEMORY_LIMIT])}
                                                </span>
                                            }
                                            position={TooltipPosition.LEFT}
                                            variant={TooltipVariant.LIGHT}
                                            tooltipWidth={200}
                                        >
                                            <SCustomIcon name='help' fill={uiColors.darkGrey} />
                                        </Tooltip>
                                    </SLabelWrapper>
                                }
                            />
                        </SProjectSettingsItem>
                    </ShowMore>
                    {isAdmin && (
                        <ShowMore
                            title={formatMessage(messages[TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_TITLE])}
                        >
                            <SProjectSettingsItem>
                                <InputField
                                    variant={
                                        !errors?.taskName && variant.fields.includes('taskName')
                                            ? variant.variant
                                            : InputVariant.DEFAULT
                                    }
                                    error={!!errors?.taskName}
                                    errorText={
                                        Object.keys(errors).includes('taskName') ? errors?.taskName?.message : ''
                                    }
                                    type={'text'}
                                    value={projectSettings.taskName}
                                    label={
                                        <SLabelWrapper>
                                            {formatMessage(
                                                messages[TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_TASKNAME]
                                            )}
                                        </SLabelWrapper>
                                    }
                                    size={'small'}
                                    {...register('taskName', {
                                        disabled: isDemo,
                                        maxLength: {
                                            value: 255,
                                            message: formatMessage(messages[TranslationKey.TOO_MANY_ERROR_MSG], {
                                                num: 255,
                                            }),
                                        },
                                        onChange: handleOnChange,
                                    })}
                                />
                            </SProjectSettingsItem>
                            <SProjectSettingsItem>
                                <InputField
                                    variant={
                                        !errors?.modelHost && variant.fields.includes('modelHost')
                                            ? variant.variant
                                            : InputVariant.DEFAULT
                                    }
                                    error={!!errors?.modelHost}
                                    errorText={
                                        Object.keys(errors).includes('modelHost') ? errors?.modelHost?.message : ''
                                    }
                                    type={'text'}
                                    value={projectSettings.modelHost}
                                    label={
                                        <SLabelWrapper>
                                            {formatMessage(
                                                messages[TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_HOST]
                                            )}
                                        </SLabelWrapper>
                                    }
                                    size={'small'}
                                    {...register('modelHost', {
                                        disabled: isDemo,
                                        onChange: handleOnChange,
                                        maxLength: {
                                            value: 155,
                                            message: formatMessage(messages[TranslationKey.TOO_MANY_ERROR_MSG], {
                                                num: 155,
                                            }),
                                        },
                                        pattern: {
                                            value: IPV4_OR_LOCALHOST,
                                            message: formatMessage(
                                                messages[
                                                    TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_HOST_NOT_VALID
                                                ]
                                            ),
                                        },
                                    })}
                                />
                            </SProjectSettingsItem>
                            <SProjectSettingsItem>
                                <InputField
                                    variant={
                                        !errors?.modelPort && variant.fields.includes('modelPort')
                                            ? variant.variant
                                            : InputVariant.DEFAULT
                                    }
                                    error={!!errors?.modelPort}
                                    errorText={
                                        Object.keys(errors).includes('modelPort') ? errors?.modelPort?.message : ''
                                    }
                                    type={'text'}
                                    value={projectSettings.modelPort}
                                    label={
                                        <SLabelWrapper>
                                            {formatMessage(
                                                messages[TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_PORT]
                                            )}
                                        </SLabelWrapper>
                                    }
                                    size={'small'}
                                    {...register('modelPort', {
                                        disabled: isDemo,
                                        onChange: handleOnChange,
                                        validate: {
                                            portValue: (v) => {
                                                if (v === undefined) {
                                                    return true;
                                                }

                                                return (
                                                    (Number.isInteger(Number(v)) &&
                                                        Number(v) >= MODEL_PORT_MIN_VALUE &&
                                                        Number(v) <= MODEL_PORT_MAX_VALUE) ||
                                                    formatMessage(
                                                        messages[
                                                            TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_PORT_VALUE
                                                        ],
                                                        {
                                                            min: MODEL_PORT_MIN_VALUE,
                                                            max: MODEL_PORT_MAX_VALUE,
                                                        }
                                                    )
                                                );
                                            },
                                        },
                                    })}
                                />
                            </SProjectSettingsItem>
                        </ShowMore>
                    )}
                </form>
            </SProjectSettingsWrapper>
        );
    }
    if (mode === ProjectSettingsItemTypes.COMPACT) {
        return (
            <SProjectSettingsCompactWrapper
                status={status}
                onClick={() => {
                    if (selectedElements) {
                        onClick && onClick();
                    }
                }}
            >
                <IconButton noHover name={'memory'} />
                <span>
                    {calculation}&nbsp;{formatMessage(messages[TranslationKey.WORKSPACE_MEMORY_LIMIT_MEMORY_POINT])}
                </span>
            </SProjectSettingsCompactWrapper>
        );
    }

    return null;
};
