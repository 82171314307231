import styled, { css, keyframes } from 'styled-components';

import { layoutSize, pxToRem, radius, shadow, uiRGBColors, zIndex } from '../../config/config';

const FileManagerItemDefaultStyles = css`
    display: flex;
    cursor: pointer;
    gap: 8px;
    align-items: center;
    padding: 0;
    font-size: ${pxToRem(14)};
    position: relative;
`;

const KPulsateAnimation = keyframes`
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.1);
        opacity: 0.5;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
`;

export const SFileManagerWrapper = styled.div`
    border: 1px solid var(--ui-table-hover);
    border-radius: ${radius.default};
    margin: 0;
    padding: 0;
    position: relative;
    overflow: hidden;

    i {
        max-width: ${pxToRem(24)};
    }
`;

export const SFileManagerHeader = styled.header`
    align-items: center;
    background-color: var(--ui-primary);
    color: white;
    display: grid;
    grid-template-columns: auto 50%;
    gap: ${pxToRem(16)};
    border-bottom: 1px solid var(--ui-table-hover);
    padding: ${pxToRem(8)} ${pxToRem(16)};
`;

export const SSFileManagerEmpty = styled.div`
    align-items: center;
    display: flex;
    gap: ${pxToRem(16)};
    justify-content: center;
    padding: ${pxToRem(16)};
    width: 100%;

    i {
        svg {
            fill: var(--ui-text);
        }
    }
`;

export const SFileManagerPanel = styled.div`
    align-items: center;
    display: flex;
    gap: ${pxToRem(12)};
    justify-content: flex-end;

    i {
        min-width: ${pxToRem(24)};

        svg {
            fill: white;
        }
    }

    menu {
        i {
            min-width: ${pxToRem(24)};

            svg {
                fill: var(--ui-label);
            }
        }
    }
`;

export const SFileManagerItemName = styled.span`
    display: inline-block;
    overflow-wrap: break-word;
    word-break: break-all;
    min-width: ${pxToRem(100)};
    min-height: ${pxToRem(16)};
    width: 100%;
`;

export const SFileManagerItemFavorite = styled.div`
    position: absolute;
    left: ${pxToRem(8)};
    top: ${pxToRem(8)};
    max-width: 20px;

    i {
        max-width: 20px;
        svg {
            fill: var(--ui-text) !important;
        }
    }
`;

export const SFileManagerItemHelp = styled.div`
    animation: ${KPulsateAnimation} 1.5s ease-out infinite;
    visibility: hidden;
    position: absolute;
    right: ${pxToRem(30)};

    i {
        max-width: 18px;

        svg {
            fill: var(--ui-text) !important;
        }
    }
`;

export const SFileManagerItem = styled.div`
    ${FileManagerItemDefaultStyles};

    > i {
        margin-bottom: ${pxToRem(8)};
    }

    &[data-file-manager-type='dir'] {
        color: var(--ui-text);

        i {
            svg {
                fill: var(--ui-text) !important;
            }
        }
    }

    &[data-file-manager-type='file'] {
        color: var(--ui-label);

        i {
            svg {
                fill: var(--ui-label) !important;
            }
        }
    }

    &:not(&:last-child) {
        border-bottom: 1px solid var(--ui-table-hover);
    }

    &[data-active='true'] {
        background-color: var(--ui-table);
    }

    &:hover {
        background-color: var(--ui-table-hover);
    }

    textarea {
        display: block;
        height: 100%;
        min-height: ${pxToRem(20)};
        line-height: 12px;
        padding: 8px;
        resize: none;
        width: 100%;
        outline: none;

        &::first-line {
            transform: translateY(-8px);
        }
    }

    &:hover {
        ${SFileManagerItemHelp} {
            visibility: visible;
        }
    }
`;

export const SFileManagerUpItem = styled.div`
    ${FileManagerItemDefaultStyles};
    border-bottom: 1px solid var(--ui-table-hover);
    padding: ${pxToRem(8)};
    max-height: ${pxToRem(30)};
    font-size: ${pxToRem(24)};
    width: 100%;

    i svg {
        fill: var(--ui-text);
    }
`;

export const SFileManagerDivider = styled.div`
    background-color: var(--ui-border);
    display: block;
    height: ${pxToRem(24)};
    margin-block-start: ${pxToRem(8)};
    margin-block-end: ${pxToRem(8)};
    width: 1px;
`;

export const SFileManagerItemWrapper = styled.div<{ currentFolderId: number }>`
    overflow-y: auto;
    height: 100%;
    max-height: calc(100vh - ${layoutSize.footer} - ${layoutSize.header} - 128px - 72px);
    min-height: calc(100vh - ${layoutSize.footer} - ${layoutSize.header} - 128px - 72px);
`;

export const SFileManagerPreviewHeader = styled.div`
    background-color: var(--ui-sidebar);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: ${pxToRem(156)};
    margin-bottom: ${pxToRem(16)};
    position: sticky;
    top: 0;
    width: 100%;

    i {
        max-height: ${pxToRem(48)};
        max-width: ${pxToRem(48)};

        svg {
            fill: var(--ui-text);
        }
    }

    img {
        display: block;
        max-width: 100%;
        max-height: calc(300px / 2);
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
`;

export const SFileManagerPreview = styled.div`
    border-left: 1px solid var(--ui-table-hover);
    padding: ${pxToRem(16)};
    height: 100%;
    overflow: hidden;
    max-height: calc(100vh - ${layoutSize.footer} - ${layoutSize.header} - 128px - 72px);
    min-height: calc(100vh - ${layoutSize.footer} - ${layoutSize.header} - 128px - 72px);
    width: 100%;

    h6 {
        line-height: ${pxToRem(16)};
        overflow: hidden;
        word-break: break-all;
    }
`;

export const SFileManagerPreviewList = styled.ul`
    overflow: auto;
    max-height: calc(100vh - ${layoutSize.footer} - ${layoutSize.header} - 128px - 72px - 188px);
    min-height: calc(100vh - ${layoutSize.footer} - ${layoutSize.header} - 128px - 72px - 188px);
    width: 100%;

    > li {
        align-items: center;
        display: flex;
        list-style-type: decimal;
        gap: ${pxToRem(8)};
        font-size: ${pxToRem(14)};
        overflow: hidden;
        word-break: break-all;

        i {
            max-height: ${pxToRem(20)};
            max-width: ${pxToRem(20)};

            svg {
                fill: var(--ui-text);
            }
        }
    }
`;

export const SFileManagerBody = styled.section`
    padding: 0;
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;

    &[data-preview-mode='true'] {
        grid-template-columns: 1fr 35%;
    }

    &[data-preview-mode='true'][data-view-mode='table'] {
        ${SFileManagerPreview} {
            padding: ${pxToRem(16)};
        }
    }

    &[data-view-mode='card'] {
        padding: 0;

        ${SFileManagerItemWrapper} {
            ${SFileManagerItem} {
                display: inline-flex;
                flex-direction: column;
                align-self: flex-start;
                border: none !important;
                padding: ${pxToRem(16)} ${pxToRem(8)};
                width: ${pxToRem(128)};
                margin: ${pxToRem(8)};
                vertical-align: top;

                ${SFileManagerItemHelp} {
                    right: ${pxToRem(12)};
                    top: ${pxToRem(12)};
                }

                &[data-file-manager-type='file'] {
                    span {
                        color: var(--ui-table-text);
                    }
                }

                > button {
                    display: grid;
                    width: 100%;

                    i {
                        margin-bottom: ${pxToRem(8)};
                        max-width: ${pxToRem(32)};

                        svg {
                            fill: var(--ui-icons);
                        }
                    }
                }

                span {
                    display: block;
                    text-align: center;
                    width: 100%;
                }

                input {
                    display: none;
                }
            }
        }
    }

    &[data-view-mode='table'] {
        ${SFileManagerItem} {
            padding: ${pxToRem(8)} ${pxToRem(48)} ${pxToRem(8)} ${pxToRem(8)} !important;

            > button i {
                min-width: 24px;
            }
        }

        ${SFileManagerItemFavorite} {
            left: calc(100% - 24px);
            width: 20px;
        }
    }
`;

export const SFileManagerFooter = styled.footer`
    display: flex;
    align-items: center;
    gap: ${pxToRem(8)};
    font-size: ${pxToRem(12)};
    background-color: var(--ui-table-hover);
    padding: ${pxToRem(4)} ${pxToRem(8)};
`;

export const SFileManagerConfirmDelete = styled.dialog`
    background-color: var(--ui-sidebar);
    border: 0;
    border-radius: ${radius.default};
    box-shadow: ${shadow.main};
    z-index: ${zIndex.top};
    position: absolute;
    padding: 0;
    top: ${pxToRem(32)};

    &::backdrop {
        background-color: rgba(${uiRGBColors.black}, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    header {
        border-bottom: 1px solid var(--ui-table-hover);

        h5 {
            margin: 0;
        }
    }

    header,
    footer {
        padding: ${pxToRem(16)};
    }

    section {
        overflow: auto;
        max-height: calc(100vh - 30%);
        padding: ${pxToRem(24)};

        p {
            margin-bottom: ${pxToRem(16)};
        }

        ul {
            padding-left: ${pxToRem(16)};
        }

        li {
            align-items: center;
            display: flex;
            gap: ${pxToRem(8)};
            font-size: ${pxToRem(14)};
            padding: 2px 0;
            list-style-type: decimal !important;

            i svg {
                fill: var(--ui-text);
            }
        }
    }

    footer {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid var(--ui-table-hover);
        padding-top: ${pxToRem(16)};
    }
`;

export const SFileManagerTitle = styled.div`
    display: flex;
    align-items: center;

    > span {
        display: block;
        margin-right: ${pxToRem(16)};
    }
`;

export const SFileManagerSearch = styled.div`
    display: flex;
    position: relative;
    width: 70%;

    &::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='64 64 896 896' focusable='false' data-icon='search'  fill='grey' aria-hidden='true'%3E%3Cpath d='M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z'%3E%3C/path%3E%3C/svg%3E");
        content: '';
        left: ${pxToRem(6)};
        top: 50%;
        position: absolute;
        transform: translateY(-50%);
        height: ${pxToRem(14)};
        width: ${pxToRem(14)};
        z-index: 2;
    }

    * {
        margin: 0;
    }

    input {
        padding: ${pxToRem(4)} ${pxToRem(24)};
        width: 100%;
    }

    > button {
        position: absolute;
        right: ${pxToRem(4)};
        top: ${pxToRem(4)};
    }
`;

export const SFileManagerUploadZoneDetails = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 65%;
`;

export const SFileManagerUploadZone = styled.div<{ isPreviewOpen: boolean }>`
    align-items: center;
    background-color: var(--ui-background);
    border: 2px dashed var(--ui-text);
    bottom: ${pxToRem(8)};
    display: flex;
    justify-content: center;
    height: calc(100% - ${pxToRem(16)});
    left: ${pxToRem(8)};
    position: absolute;
    width: ${({ isPreviewOpen }) => isPreviewOpen && `calc(100% - 35% - ${pxToRem(16)})`};
    width: ${({ isPreviewOpen }) => !isPreviewOpen && `calc(100% - ${pxToRem(16)})`};

    > div {
        align-items: center;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
    }

    p {
        font-size: ${pxToRem(14)};
    }

    ul {
        margin: ${pxToRem(16)} 0;
        li {
            color: var(--ui-table-text);
        }
    }
`;
