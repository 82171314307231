import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.PAGE_HELP_TITLE]: {
        id: TranslationKey.PAGE_HELP_TITLE,
        defaultMessage: ru[TranslationKey.PAGE_HELP_TITLE],
    },
    [TranslationKey.PAGE_HELP_SUBTITLE]: {
        id: TranslationKey.PAGE_HELP_SUBTITLE,
        defaultMessage: ru[TranslationKey.PAGE_HELP_SUBTITLE],
    },
    [TranslationKey.HELP_SECTION_COMMON]: {
        id: TranslationKey.HELP_SECTION_COMMON,
        defaultMessage: ru[TranslationKey.HELP_SECTION_COMMON],
    },
    [TranslationKey.HELP_SECTION_INTERFACE]: {
        id: TranslationKey.HELP_SECTION_INTERFACE,
        defaultMessage: ru[TranslationKey.HELP_SECTION_INTERFACE],
    },
    [TranslationKey.HELP_SECTION_TASK]: {
        id: TranslationKey.HELP_SECTION_TASK,
        defaultMessage: ru[TranslationKey.HELP_SECTION_TASK],
    },
    [TranslationKey.HELP_SECTION_MODELING]: {
        id: TranslationKey.HELP_SECTION_MODELING,
        defaultMessage: ru[TranslationKey.HELP_SECTION_MODELING],
    },
    [TranslationKey.HELP_SECTION_CHANGELOG]: {
        id: TranslationKey.HELP_SECTION_CHANGELOG,
        defaultMessage: ru[TranslationKey.HELP_SECTION_CHANGELOG],
    },
    [TranslationKey.HELP_SECTION_SUPPORT]: {
        id: TranslationKey.HELP_SECTION_SUPPORT,
        defaultMessage: ru[TranslationKey.HELP_SECTION_SUPPORT],
    },
    [TranslationKey.SECTION_IN_PROGRESS]: {
        id: TranslationKey.SECTION_IN_PROGRESS,
        defaultMessage: ru[TranslationKey.SECTION_IN_PROGRESS],
    },
    [TranslationKey.HELP_SECTION_HOTKEYS]: {
        id: TranslationKey.HELP_SECTION_HOTKEYS,
        defaultMessage: ru[TranslationKey.HELP_SECTION_HOTKEYS],
    },
    [TranslationKey.HELP_BLOCK_SUMMARY]: {
        id: TranslationKey.HELP_BLOCK_SUMMARY,
        defaultMessage: ru[TranslationKey.HELP_BLOCK_SUMMARY],
    },
    [TranslationKey.HELP_BLOCK_NEW]: {
        id: TranslationKey.HELP_BLOCK_NEW,
        defaultMessage: ru[TranslationKey.HELP_BLOCK_NEW],
    },
    [TranslationKey.HELP_BLOCK_IMPROVEMENTS]: {
        id: TranslationKey.HELP_BLOCK_IMPROVEMENTS,
        defaultMessage: ru[TranslationKey.HELP_BLOCK_IMPROVEMENTS],
    },
    [TranslationKey.HELP_BLOCK_FIXES]: {
        id: TranslationKey.HELP_BLOCK_FIXES,
        defaultMessage: ru[TranslationKey.HELP_BLOCK_FIXES],
    },
    [TranslationKey.HELP_BLOCK_ADDITIONAL]: {
        id: TranslationKey.HELP_BLOCK_ADDITIONAL,
        defaultMessage: ru[TranslationKey.HELP_BLOCK_ADDITIONAL],
    },
    [TranslationKey.MODAL_TECH_SUPPORT_CONTACT]: {
        id: TranslationKey.MODAL_TECH_SUPPORT_CONTACT,
        defaultMessage: ru[TranslationKey.MODAL_TECH_SUPPORT_CONTACT],
    },
    [TranslationKey.US]: {
        id: TranslationKey.US,
        defaultMessage: ru[TranslationKey.US],
    },
    [TranslationKey.HELP_SECTION_VERSIONS_TITLE]: {
        id: TranslationKey.HELP_SECTION_VERSIONS_TITLE,
        defaultMessage: ru[TranslationKey.HELP_SECTION_VERSIONS_TITLE],
    },
});
