import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.LIBRARY_NEW_BLOCK]: {
        id: TranslationKey.LIBRARY_NEW_BLOCK,
        defaultMessage: ru[TranslationKey.LIBRARY_NEW_BLOCK],
    },
});
