import { PortTypes, TLibraryItemPort, TPortTypeComplex } from 'libs/models/src/lib/libraryItem';

import { extractNumbers, findPortDetails, getHandleName, updateHandleIdWithHandleName } from '@repeat/constants';
import {
    ElemParams,
    ElemProps,
    ILibraryItem,
    PortConnectionTypes,
    TPortConnectionType,
    TSchemaConnection,
    TSchemaGroup,
    TSchemaHandle,
    TSchemaNode,
} from '@repeat/models';

import { ELEMENTS_WITH_CONDITIONAL_PORTS_TYPES } from '../../SchemaAdapterService';
import { applyPatch_FMI_properties } from '../2.2.0';

interface ISchemaItems {
    elements: TSchemaNode[];
    wires: TSchemaConnection[];
    groups: TSchemaGroup[];
}

const FMI_TYPE = 'fmi';
const ELECTRONIC_MOTOR_DRIVE_ELEMENT_TYPE = 'ElectronicMotorDrive';

const sortParameters = (params: ElemParams[]) => {
    return params.sort((a, b) => {
        const aIsIn = a.name.includes('in');
        const bIsIn = b.name.includes('in');
        if (aIsIn === bIsIn) {
            const aNum = parseInt(a.name.split('_')[1]);
            const bNum = parseInt(b.name.split('_')[1]);
            return aNum - bNum;
        } else if (aIsIn) {
            return -1;
        } else {
            return 1;
        }
    });
};

const updateSubtypeByPicId = (picId: number) => {
    switch (picId) {
        case 2163:
            return 'And';
        case 2164:
            return 'Or';
        case 2165:
            return 'Not';
        case 2167:
            return 'sinh';
        case 2168:
            return 'сosh';
        case 2169:
            return 'tanh';
        case 2170:
            return 'Sin';
        case 2171:
            return 'Cos';
        case 2172:
            return 'Tan';
        case 2173:
            return 'Cotan';
        default:
            return null;
    }
};

const findDefaultPort = (
    libraryPorts: TLibraryItemPort[],
    port: TSchemaHandle,
    libraryPortTypes: TPortTypeComplex[]
) => {
    const availablePortsInput = libraryPorts.filter((port) => port.type === PortTypes.INPUT);
    const availablePortsOutput = libraryPorts.filter((port) => port.type === PortTypes.OUTPUT);

    const typeConnection =
        port.type === PortTypes.INPUT
            ? availablePortsInput[availablePortsInput.length - 1]?.typeConnection
            : availablePortsOutput[availablePortsOutput.length - 1]?.typeConnection;

    const libraryPortDetails = libraryPortTypes.find((libPort) => libPort.type === typeConnection);

    if (!libraryPortDetails) {
        return port;
    }
    const { type, ...rest } = libraryPortDetails;

    return { ...port, typeConnection: type, ...rest };
};

const setIsConnected = (port: TLibraryItemPort, elementPorts: TSchemaHandle[]) => {
    return elementPorts.find((p) => p.name === port.name)?.isConnected || false;
};

const setPorts = (ports: TLibraryItemPort[], schemaPorts: TSchemaHandle[], portTypes: TPortTypeComplex[]) => {
    return ports.map((port) => {
        const { direction, compatibleTypes } = findPortDetails(portTypes, port.typeConnection, port.name);

        return {
            ...port,
            direction,
            compatibleTypes,
            isConnected: setIsConnected(port, schemaPorts),
        };
    });
};

const findPropertyValueByPorts = (elemProps: ElemProps[], ports: TLibraryItemPort[]) => {
    const visibilityConditionKey =
        ports[0] && ports[0].visibilityConditions ? Object.keys(ports[0].visibilityConditions[0])[0] : '';

    return elemProps.find((prop) => prop.name === visibilityConditionKey)?.value;
};

const findPropertyValue = (elementId: string, elements: TSchemaNode[], libraryItems: ILibraryItem[]) => {
    const element = elements.find((el) => el.id === elementId);
    const elemProps = element?.data.elemProps || [];
    const ports = libraryItems.find((item) => item.type === element?.data.type)?.availablePorts || [];

    const visibilityConditionKey =
        element?.data.type === ELECTRONIC_MOTOR_DRIVE_ELEMENT_TYPE
            ? 'generateTorq'
            : ports[0] && ports[0].visibilityConditions
            ? Object.keys(ports[0].visibilityConditions[0])[0]
            : '';

    return elemProps.find((prop) => prop.name === visibilityConditionKey)?.value;
};

const updateElementWithConditionalPorts = (
    libraryElement: ILibraryItem,
    schemaElement: TSchemaNode,
    portTypes: TPortTypeComplex[]
) => {
    const libraryAvailablePorts = libraryElement.availablePorts;
    const libraryElemParams = libraryElement.elemParams;
    const libraryAvailablePortsNames = libraryAvailablePorts.map((port) => port.name);
    const schemaAvailablePorts = schemaElement.data.availablePorts;
    let newPorts: TSchemaHandle[] = [];

    // порты и параметры новых блоков не обновляем
    if (
        [
            'PressureAndTemperatureSensorTH',
            'AnnularLeakage',
            'TemperatureSensor',
            'ThermalResistance',
            'VariableReductionDrive',
        ].includes(schemaElement.data.type)
    ) {
        return { availablePorts: schemaElement.data.availablePorts, elemParams: schemaElement.data.elemParams };
    }

    if (schemaElement.data.type === ELECTRONIC_MOTOR_DRIVE_ELEMENT_TYPE) {
        const propertyName = 'generateTorq';
        const propertyValue = schemaElement.data.elemProps.find((prop) => prop.name === propertyName)?.value;
        if (propertyValue === 'true') {
            newPorts = setPorts(
                libraryAvailablePorts.filter((port) => ['in_1', 'in_2', 'out_3', 'out_5'].includes(port.name)),
                schemaAvailablePorts,
                portTypes
            );
        }
        if (propertyValue === 'false') {
            newPorts = setPorts(
                libraryAvailablePorts.filter((port) => ['in_1', 'in_2', 'out_3', 'out_4'].includes(port.name)),
                schemaAvailablePorts,
                portTypes
            );
        }
    }
    const propertyValue = findPropertyValueByPorts(schemaElement.data.elemProps, libraryAvailablePorts);
    if (propertyValue === 'true') {
        newPorts = setPorts(
            libraryAvailablePorts.filter((port) => port.name === 'in_1' || port.name === 'out_3'),
            schemaAvailablePorts,
            portTypes
        );
    }
    if (propertyValue === 'false') {
        newPorts = setPorts(
            libraryAvailablePorts.filter((port) => port.name === 'in_2' || port.name === 'out_4'),
            schemaAvailablePorts,
            portTypes
        );
    }
    const newParams = libraryElemParams
        .filter((param) => newPorts.map((p) => p.name).includes(param.name))
        .map((param) => ({ ...param, isVisible: true }));
    const hiddenParams = libraryElemParams
        .filter((param) => !newPorts.map((p) => p.name).includes(param.name))
        .map((param) => ({ ...param, isVisible: false }));
    return { availablePorts: newPorts, elemParams: sortParameters([...hiddenParams, ...newParams]) };
};

const findElementTypeById = (elementId: string, elements: TSchemaNode[]) => {
    return elements.find((el) => el.id === elementId)?.data.type;
};

export const wiresCheckValidConnections = (wires: TSchemaConnection[], elements: TSchemaNode[]) => {
    return wires.map((wire) => {
        const sourceBlock = elements.find((item) => item.id === wire.source);
        const targetBlock = elements.find((item) => item.id === wire.target);

        const sourcePortHandlename = getHandleName(wire?.sourceHandle ?? '');
        const targetPortHandlename = getHandleName(wire?.targetHandle ?? '');

        const unsupportedSourcePort =
            sourceBlock &&
            sourceBlock.data.availablePorts.some(
                (item) =>
                    item.name === sourcePortHandlename &&
                    !targetBlock?.data.availablePorts.some((item1) =>
                        item1.compatibleTypes?.includes(item.typeConnection)
                    )
            );

        const unsupportedTargetPort =
            targetBlock &&
            targetBlock.data.availablePorts.some(
                (item) =>
                    item.name === targetPortHandlename &&
                    !sourceBlock?.data.availablePorts.some((item1) =>
                        item.compatibleTypes?.includes(item1.typeConnection)
                    )
            );

        const isValidConnection = !(unsupportedSourcePort && unsupportedTargetPort);

        return {
            ...wire,
            data: { ...wire.data, isValidConnection: isValidConnection },
            type: 'custom-edge',
        };
    });
};

const updateWiresWithVisibilityConditions = (
    wiresToUpdate: TSchemaConnection[],
    elements: TSchemaNode[],
    libraryItems: ILibraryItem[]
) => {
    const elementsIds = elements.map((el) => el.id);

    const wires = wiresToUpdate.map((wire, index) => {
        const newWire = { ...wire };
        const propertyValueSource = findPropertyValue(wire.source, elements, libraryItems);
        const propertyValueTarget = findPropertyValue(wire.target, elements, libraryItems);

        const isSourceElectronicMotorDrive =
            findElementTypeById(wire.source, elements) === ELECTRONIC_MOTOR_DRIVE_ELEMENT_TYPE;
        const isTargetElectronicMotorDrive =
            findElementTypeById(wire.target, elements) === ELECTRONIC_MOTOR_DRIVE_ELEMENT_TYPE;

        if (elementsIds.includes(wire.source) && elementsIds.includes(wire.target)) {
            if (propertyValueSource === 'true') {
                if (isSourceElectronicMotorDrive) {
                    if (wire.sourceHandle.includes('out_4')) {
                        newWire.sourceHandle = updateHandleIdWithHandleName(wire.sourceHandle, 'out_5');
                    }
                } else {
                    if (wire.sourceHandle.includes('out_2')) {
                        newWire.sourceHandle = updateHandleIdWithHandleName(wire.sourceHandle, 'out_3');
                    }
                }
            }
            if (propertyValueTarget === 'true') {
                if (isTargetElectronicMotorDrive) {
                    if (wire.targetHandle.includes('out_4')) {
                        newWire.targetHandle = updateHandleIdWithHandleName(wire.targetHandle, 'out_5');
                    }
                } else {
                    if (wire.targetHandle.includes('out_2')) {
                        newWire.targetHandle = updateHandleIdWithHandleName(wire.targetHandle, 'out_3');
                    }
                }
            }
            if (propertyValueSource === 'false' && !isSourceElectronicMotorDrive) {
                if (wire.sourceHandle.includes('in_1')) {
                    newWire.sourceHandle = updateHandleIdWithHandleName(wire.sourceHandle, 'in_2');
                }
                if (wire.sourceHandle.includes('out_2')) {
                    newWire.sourceHandle = updateHandleIdWithHandleName(wire.sourceHandle, 'out_4');
                }
            }
            if (propertyValueTarget === 'false' && !isTargetElectronicMotorDrive) {
                if (wire.targetHandle.includes('in_1')) {
                    newWire.targetHandle = updateHandleIdWithHandleName(wire.targetHandle, 'in_2');
                }
                if (wire.targetHandle.includes('out_2')) {
                    newWire.targetHandle = updateHandleIdWithHandleName(wire.targetHandle, 'out_4');
                }
            }
        }
        if (elementsIds.includes(wire.source) && !elementsIds.includes(wire.target)) {
            if (propertyValueSource === 'true') {
                if (isSourceElectronicMotorDrive) {
                    if (wire.sourceHandle.includes('out_4')) {
                        newWire.sourceHandle = updateHandleIdWithHandleName(wire.sourceHandle, 'out_5');
                    }
                } else {
                    if (wire.sourceHandle.includes('out_2')) {
                        newWire.sourceHandle = updateHandleIdWithHandleName(wire.sourceHandle, 'out_3');
                    }
                }
            }
            if (propertyValueSource === 'false' && !isSourceElectronicMotorDrive) {
                if (wire.sourceHandle.includes('in_1')) {
                    newWire.sourceHandle = updateHandleIdWithHandleName(wire.sourceHandle, 'in_2');
                }
                if (wire.sourceHandle.includes('out_2')) {
                    newWire.sourceHandle = updateHandleIdWithHandleName(wire.sourceHandle, 'out_4');
                }
            }
        }
        if (!elementsIds.includes(wire.source) && elementsIds.includes(wire.target)) {
            if (propertyValueTarget === 'true') {
                if (isTargetElectronicMotorDrive) {
                    if (wire.targetHandle.includes('out_4')) {
                        newWire.targetHandle = updateHandleIdWithHandleName(wire.targetHandle, 'out_5');
                    }
                } else {
                    if (wire.targetHandle.includes('out_2')) {
                        newWire.targetHandle = updateHandleIdWithHandleName(wire.targetHandle, 'out_3');
                    }
                }
            }
            if (propertyValueTarget === 'false' && !isTargetElectronicMotorDrive) {
                if (wire.targetHandle.includes('in_1')) {
                    newWire.targetHandle = updateHandleIdWithHandleName(wire.targetHandle, 'in_2');
                }
                if (wire.targetHandle.includes('out_2')) {
                    newWire.targetHandle = updateHandleIdWithHandleName(wire.targetHandle, 'out_4');
                }
            }
        }
        return newWire;
    });
    return wires;
};

export const updateSchema_patch_2_5_1 = (
    schemaItems: ISchemaItems,
    libraryItems: ILibraryItem[],
    libraryPortTypes: TPortTypeComplex[]
) => {
    const elementsWithSubtypes = schemaItems.elements.map((el) => {
        const picId = el.data.picId;
        const subtype = updateSubtypeByPicId(picId);
        if (!subtype) {
            return el;
        }
        return { ...el, data: { ...el.data, subtype } };
    });
    const elements = elementsWithSubtypes.map((el) => {
        const elementType = el.data.type;
        const elementAvailablePorts = el.data.availablePorts;

        const libraryItem = libraryItems.find((item) => {
            if (item.subtype && el.data.subtype) {
                return item.type === elementType && item.subtype === el.data.subtype;
            }
            return item.type === elementType;
        });
        if (!libraryItem) {
            return el;
        }
        if ([FMI_TYPE].includes(elementType)) {
            const updatedElementAvailablePorts = elementAvailablePorts.map((port) => {
                if (port.name.includes('in')) {
                    const { direction, compatibleTypes } = findPortDetails(
                        libraryPortTypes,
                        PortConnectionTypes.AUTO_IN,
                        port.name
                    );
                    return { ...port, typeConnection: PortConnectionTypes.AUTO_IN, direction, compatibleTypes };
                }
                if (port.name.includes('out')) {
                    const { direction, compatibleTypes } = findPortDetails(
                        libraryPortTypes,
                        PortConnectionTypes.AUTO_OUT,
                        port.name
                    );
                    return { ...port, typeConnection: PortConnectionTypes.AUTO_OUT, direction, compatibleTypes };
                }
                return port;
            });
            return { ...el, data: { ...el.data, availablePorts: updatedElementAvailablePorts } };
        }
        if (ELEMENTS_WITH_CONDITIONAL_PORTS_TYPES.includes(elementType)) {
            const { availablePorts, elemParams } = updateElementWithConditionalPorts(libraryItem, el, libraryPortTypes);

            return { ...el, data: { ...el.data, availablePorts, elemParams } };
        }

        const libraryItemAvailablePorts = libraryItem.availablePorts;

        const updatedElementAvailablePorts = elementAvailablePorts.map((port, index) => {
            const portName = port.name;
            const libraryPort = libraryItemAvailablePorts.find((p) => p.name === portName);

            if (!libraryPort) {
                return findDefaultPort(libraryItemAvailablePorts, port, libraryPortTypes);
            }
            const libraryPortTypeConnection = libraryPort.typeConnection as TPortConnectionType;

            const libraryPortDetails = libraryPortTypes.find((libPort) => libPort.type === libraryPortTypeConnection);

            if (!libraryPortDetails) {
                return port;
            }
            const { type, ...rest } = libraryPortDetails;
            const portUpdated = { ...port, typeConnection: type, ...rest };
            return portUpdated;
        });
        return {
            ...el,
            data: { ...el.data, availablePorts: updatedElementAvailablePorts },
        };
    });

    const elementsWithConditionalPortsTypes = elements.filter((el) =>
        ELEMENTS_WITH_CONDITIONAL_PORTS_TYPES.includes(el.data.type)
    );

    const libraryElementsWithConditionalPortsTypes = libraryItems.filter((item) =>
        ELEMENTS_WITH_CONDITIONAL_PORTS_TYPES.includes(item.type)
    );

    const elementsWithConditionalPortsTypesIds = elementsWithConditionalPortsTypes.map((el) => el.id);
    const wiresToUpdate = schemaItems.wires.filter(
        (wire) =>
            elementsWithConditionalPortsTypesIds.includes(wire.source) ||
            elementsWithConditionalPortsTypesIds.includes(wire.target)
    );
    const updatedWires = updateWiresWithVisibilityConditions(
        wiresToUpdate,
        elementsWithConditionalPortsTypes,
        libraryElementsWithConditionalPortsTypes
    );

    const wiresResult = [
        ...schemaItems.wires.filter((x) => !wiresToUpdate.some((y) => y.id === x.id)),
        ...updatedWires,
    ];
    const updatedWiresConnectedPortsIds = updatedWires.map((wire) => [wire.sourceHandle, wire.targetHandle]).flat();
    const elementsResult = elements.map((el) => {
        if (elementsWithConditionalPortsTypesIds.includes(el.id)) {
            const availablePorts = el.data.availablePorts.map((port) => {
                if (updatedWiresConnectedPortsIds.includes(`${el.id}-${extractNumbers(port.name)}-${port.name}`)) {
                    return { ...port, isConnected: true };
                }
                return port;
            });
            return { ...el, data: { ...el.data, availablePorts } };
        }
        return el;
    });

    return {
        elements: elementsResult,
        wires: wiresCheckValidConnections(wiresResult, elementsResult),
    };
};

export const applyPatch_2_5_1 = (
    schemaItems: ISchemaItems,
    libraryItems: ILibraryItem[],
    libraryPortTypes: TPortTypeComplex[]
): ISchemaItems => {
    const schemaItemsUpdated = applyPatch_FMI_properties(schemaItems, libraryItems);
    return {
        ...schemaItems,
        elements: updateSchema_patch_2_5_1(schemaItemsUpdated, libraryItems, libraryPortTypes).elements,
        wires: updateSchema_patch_2_5_1(schemaItemsUpdated, libraryItems, libraryPortTypes).wires,
    };
};
