import { FC } from 'react';

import { FormLabelTypeMap } from '@mui/material';

import { StyledFormLabel, StyledFormLabelLight } from './StyledFormLabel';

import type { IStyledFormLabelProps } from './StyledFormLabel';

type AppLabelType = IStyledFormLabelProps &
    Partial<FormLabelTypeMap> & {
        light?: boolean;
        children: React.ReactNode;
    };

export const Label: FC<AppLabelType> = ({ light, children, ...props }) => {
    return light ? <StyledFormLabelLight {...props}>{children}</StyledFormLabelLight> : <StyledFormLabel {...props}>{children}</StyledFormLabel>;
};
