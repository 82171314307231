import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { addProjectToDemo, getDemoProjects, removeProjectFromDemo } from '@repeat/store';

export const useProjectDemo = () => {
    const dispatch = useAppDispatch();
    const { currentUser } = useAppSelector((state) => state.appUser);

    const handleAddToDemo = useCallback((projectId: number) => {
        if (currentUser) {
            dispatch(addProjectToDemo(projectId));
        }
    }, []);

    const handleRemoveFromDemo = useCallback((exampleId: number) => {
        if (currentUser) {
            dispatch(removeProjectFromDemo(exampleId)).then(() => dispatch(getDemoProjects()));
        }
    }, []);

    return {
        handleAddToDemo,
        handleRemoveFromDemo,
    };
};
