import styled from 'styled-components';

import { pxToRem } from '@repeat/ui-kit';

export const SInputNotice = styled.div`
    width: 100%;
    justify-content: flex-start;
    font-size: ${pxToRem(14)};
    margin-top: ${pxToRem(-10)};
    margin-bottom: ${pxToRem(20)};
    padding: 0;
    color: var(--ui-tags);
`;
export const StyledButtons = styled.div`
    margin-top: ${pxToRem(24)};
    button:first-child {
        margin-right: ${pxToRem(16)};
    }
`;

export const SBlockPortEdit = styled.div`
    width: 100%;
    border: 1px solid var(--ui-border);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(34, 60, 80, 0.1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(34, 60, 80, 0.1);
    box-shadow: 0px 0px 10px 0px rgba(34, 60, 80, 0.1);
    border-radius: ${pxToRem(10)};
    padding: ${pxToRem(20)};
    margin: ${pxToRem(10)} 0;
    display: flex;
    flex-direction: column;
    gap: ${pxToRem(10)};
`;

export const SDisplaySection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: ${pxToRem(30)};
    gap: ${pxToRem(10)};
`;

export const SPropertyValuesForm = styled.div`
    width: 100%;
    margin-left: ${pxToRem(50)};
`;

export const SGroupHeader = styled.div`
    font-size: ${pxToRem(14)};
    position: sticky;
    top: -8px;
    padding: 4px 10px;
    color: var(--ui-text);
    background: var(--ui-input);
`;

export const SGroupItems = styled.ul`
    background-color: var(--ui-background);
    padding: 0;
    font-size: 12px;
`;
