import styled from 'styled-components';

import { pxToRem, radius, shadow } from '@repeat/ui-kit';

export const SCanvasWrapper = styled.div`
    border: none;
    background-color: initial;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    height: calc(100vh - 48px - 40px);
    position: relative;
    width: 100%;
    z-index: 0;

    .react-flow__node-toolbar {
        padding: 0 !important;

        &[data-list-params] {
            margin-top: -28px;
        }
    }

    .react-flow {
        background: var(--ui-canvas);
    }

    .react-flow__edge-path {
        stroke: var(--ui-text);
    }

    .react-flow__edge.selected .react-flow__edge-path {
        stroke: var(--ui-primary);
        stroke-width: 2px;
    }

    .react-flow__handle-bottom {
        bottom: -5px;
    }

    .react-flow__handle-top {
        top: -5px;
    }

    .react-flow__handle-left {
        left: -5px;
    }

    .react-flow__handle-right {
        right: -5px;
    }

    .validationflow .react-flow__node-customnode {
        background: var(--ui-sidebar);
        border: 1px solid #ddd;
    }

    .react-flow__node-custominput .react-flow__handle {
        background: var(--ui-text);
    }

    .validationflow .react-flow__node-custominput {
        background: var(--ui-input);
    }

    .validationflow .react-flow__handle-connecting {
        background: #ff6060;
    }

    .validationflow .react-flow__handle-valid {
        background: #55dd99;
    }

    .react-flow__node-toolbar {
        background: var(--ui-backround);
        padding: ${pxToRem(4)} ${pxToRem(8)};
        border-radius: ${radius.default};
        font-size: ${pxToRem(12)};
    }

    .react-flow__panel .react-flow__controls-button {
        background-color: var(--ui-sidebar);
        border: none;
        box-shadow: ${shadow.controls};

        svg {
            fill: var(--ui-icons);
        }
    }

    .react-flow__minimap {
        background-color: var(--ui-sidebar);
        box-shadow: ${shadow.controls};
    }

    .react-flow__controls,
    .react-flow__minimap {
        bottom: 0 !important;
    }
`;

export const SCanvasLoaderWrapper = styled.div`
    background: var(--ui-canvas);
    position: absolute;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(4px);
    z-index: 10;

    img {
        max-height: 72px;
        max-width: 72px;
    }
`;
