import React, { FC } from 'react';

import { SToolsList } from './STools';

interface IToolsList {
    children: React.ReactNode;
}

export const ToolsList: FC<IToolsList> = ({ children }) => {
    return <SToolsList>{children}</SToolsList>;
};
