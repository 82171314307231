import styled from 'styled-components';

import { pxToRem } from '@repeat/ui-kit';

export const STrendSetupWrapper = styled.div`
    min-height: 70px;
    width: 100%;
`;
export const STrendSetupMessage = styled.h5`
    font-size: ${pxToRem(12)};
    padding: 0 ${pxToRem(8)};
`;
