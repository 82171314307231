import { ApplicationActions } from '@repeat/common-slices';
import { createBlobFile, CSV_DELIMITER } from '@repeat/constants';
import { EChartItemType, NotificationTypes, TChartsData } from '@repeat/models';
import { AppDispatch, RootStateFn } from '@repeat/store';
import { Locales, TranslationKey } from '@repeat/translations';

export const downloadChartData = (index: number) => (dispatch: AppDispatch, getState: RootStateFn) => {
    const data = getState().workspace.graphs.data as TChartsData[];
    const currentChart = getState().workspace.graphs.charts[index];
    const locale = getState().appUser?.currentUser?.settings?.locale;

    if (currentChart && data !== null && data[index] !== null) {
        try {
            const { XParameters, YParameters } = currentChart;
            const dataHeader: string[] = [
                `${XParameters?.elementTitle ? XParameters?.elementTitle : XParameters?.parameterTitle}`,
                ...YParameters.map((parameter) => `${parameter?.elementTitle} / ${parameter?.parameterTitle}`),
            ];
            if (currentChart?.type === EChartItemType.YFromX && XParameters?.parameterTitle) {
                /*TODO
                    find a way to fix
                 *  */
                if (locale && locale === Locales.RUSSIAN) {
                    dataHeader.unshift('Время, с');
                } else {
                    dataHeader.unshift('Time, s');
                }
            }
            const dataTime: number[] = data[index][0];
            const dataParameters: number[][] = data[index].slice(1); // Используем все внутренние массивы, кроме первого

            let csvContent = dataHeader.join(CSV_DELIMITER) + '\n';

            dataTime.forEach((time, rowIndex) => {
                const row = [time, ...dataParameters.map((parameterSet) => parameterSet[rowIndex])];
                csvContent += row.join(CSV_DELIMITER) + '\n';
            });

            return createBlobFile<string>(csvContent, 'text/csv');
        } catch (error) {
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: TranslationKey.WORKSPACE_ERROR_CHART_CSV,
                    },
                })
            );
            console.error(error);
            return;
        }
    } else {
        ApplicationActions.showNotification({
            notification: {
                type: NotificationTypes.ERROR,
                message: TranslationKey.WORKSPACE_ERROR_CHART_CSV,
            },
        });
        return;
    }
};
