import React, { FC } from 'react';

import styled, { css } from 'styled-components';

import { layoutSize, pxToRem } from '../../config/config';

interface ILayout {
    withRightSidebar?: boolean;
    withFooter?: boolean;
    internal?: boolean;
    children: React.ReactNode;
    style?: keyof typeof css;
}

const SLayout = styled.section<ILayout>`
    background: var(--ui-light-grey);
    display: flex;
    height: 100vh;
    max-width: 100vw;
    overflow: hidden;
    padding: ${layoutSize.header} 0 ${layoutSize.footer};
    position: relative;
    width: 100%;

    ${({ internal }) =>
        internal &&
        `
        padding: 0;

        aside {
            height: 100%;
        }

        aside ~ main {
            margin-right: 0;
            min-height: 100vh;
            padding-top: ${pxToRem(48)};
        }

        aside[data-open="true"][data-position="LEFT"],
        aside[data-open="true"][data-position="LEFT"] + main,
        aside[data-open="true"][data-position="RIGHT"],
        aside[data-open="true"][data-position="RIGHT"] + main {
            padding-top: ${pxToRem(48)};
        }
    `}

    aside[data-open="false"][data-position="LEFT"] ~ main {
        margin-left: 0;
    }

    aside[data-open='false'][data-position='RIGHT'] ~ main {
        margin-right: 0;
    }
`;

export const Layout: FC<ILayout> = ({ style, children, internal, withRightSidebar = false, withFooter = false }) => {
    return (
        <SLayout style={style} withRightSidebar={withRightSidebar} withFooter={withFooter} internal={internal}>
            {children}
        </SLayout>
    );
};
