import { lte } from 'semver';

import { ReleaseModalCloseType } from 'libs/models/src/lib/modal';

import { ApplicationActions } from '@repeat/common-slices';
import { DEFAULT_LAST_SEEN_VERSION, LOCAL_STORAGE_KEYS, VERSION, getAppLocale } from '@repeat/constants';
import { ModalTypes, TReleaseInfoModal } from '@repeat/models';
import { HelpPageService } from '@repeat/services';

import { AppDispatch } from '../store';

export const showSkippedReleaseInfo = () => async (dispatch: AppDispatch) => {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.IS_DONT_SHOW_RELEASES)) {
        return;
    }
    const versionInLocalStorage =
        localStorage.getItem(LOCAL_STORAGE_KEYS.LAST_SEEN_VERSION) ?? DEFAULT_LAST_SEEN_VERSION;
    if (versionInLocalStorage === VERSION) {
        return;
    }
    try {
        const locale = getAppLocale();

        const { data } = await HelpPageService.getVersionHistoryContent(locale);

        const findItem = data.find((item) => item.code === versionInLocalStorage);
        const content = data.filter(({ id, code }) => id > findItem!.id && lte(code, VERSION));

        const { code: actualVersion } = content[content.length - 1];
        const modal: TReleaseInfoModal = {
            type: ModalTypes.RELEASE_INFO,
            data: {
                content: content.slice(-3),
                actual_release_version: actualVersion,
            },
        };

        dispatch(ApplicationActions.showModal({ modal }));
    } catch (error) {
        return;
    }
};

export const hideReleaseInfoModal =
    (actualVersion: string, type: ReleaseModalCloseType.CLOSE | ReleaseModalCloseType.DONT_SHOW_RELEASES) =>
    (dispatch: AppDispatch) => {
        if (type === ReleaseModalCloseType.DONT_SHOW_RELEASES) {
            localStorage.setItem(LOCAL_STORAGE_KEYS.IS_DONT_SHOW_RELEASES, 'true');
        } else {
            localStorage.setItem(LOCAL_STORAGE_KEYS.LAST_SEEN_VERSION, actualVersion);
        }
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.RELEASE_INFO }));
    };
