import { StrictMode } from 'react';
import { HotkeysProvider } from 'react-hotkeys-hook';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { createRoot } from 'react-dom/client';

import { store } from '@repeat/store';

import App from './app/app';
import { GlobalStyles } from './styles/global';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
    <StrictMode>
        <GlobalStyles />
        <Provider store={store}>
            <BrowserRouter>
                <HotkeysProvider initiallyActiveScopes={['global']}>
                    <App />
                </HotkeysProvider>
            </BrowserRouter>
        </Provider>
    </StrictMode>
);
