import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.SAVE]: {
        id: TranslationKey.SAVE,
        defaultMessage: ru[TranslationKey.SAVE],
    },
    [TranslationKey.SAVE_AS]: {
        id: TranslationKey.SAVE_AS,
        defaultMessage: ru[TranslationKey.SAVE_AS],
    },
    [TranslationKey.TOUR_PROJECTS_LIST]: {
        id: TranslationKey.TOUR_PROJECTS_LIST,
        defaultMessage: ru[TranslationKey.TOUR_PROJECTS_LIST],
    },
    [TranslationKey.CLOSE_PROJECT]: {
        id: TranslationKey.CLOSE_PROJECT,
        defaultMessage: ru[TranslationKey.CLOSE_PROJECT],
    },
    [TranslationKey.SCHEMA_ELEMENT_DELETED]: {
        id: TranslationKey.SCHEMA_ELEMENT_DELETED,
        defaultMessage: ru[TranslationKey.SCHEMA_ELEMENT_DELETED],
    },
    [TranslationKey.WORKSPACE_GENERATE_SOURCES]: {
        id: TranslationKey.WORKSPACE_GENERATE_SOURCES,
        defaultMessage: ru[TranslationKey.WORKSPACE_GENERATE_SOURCES],
    },
    [TranslationKey.WORKSPACE_GENERATE_SOURCES_NOT_AVAILABLE]: {
        id: TranslationKey.WORKSPACE_GENERATE_SOURCES_NOT_AVAILABLE,
        defaultMessage: ru[TranslationKey.WORKSPACE_GENERATE_SOURCES_NOT_AVAILABLE],
    },
    [TranslationKey.WORKSPACE_GENERATE_SOURCES_CANCEL]: {
        id: TranslationKey.WORKSPACE_GENERATE_SOURCES_CANCEL,
        defaultMessage: ru[TranslationKey.WORKSPACE_GENERATE_SOURCES_CANCEL],
    },
    [TranslationKey.WORKSPACE_GET_NONAME_FILE]: {
        id: TranslationKey.WORKSPACE_GET_NONAME_FILE,
        defaultMessage: ru[TranslationKey.WORKSPACE_GET_NONAME_FILE],
    },
    [TranslationKey.WORKSPACE_GET_NONAME_FILE_CANCEL]: {
        id: TranslationKey.WORKSPACE_GET_NONAME_FILE_CANCEL,
        defaultMessage: ru[TranslationKey.WORKSPACE_GET_NONAME_FILE_CANCEL],
    },
    [TranslationKey.BACK_TO_PROJECTS]: {
        id: TranslationKey.BACK_TO_PROJECTS,
        defaultMessage: ru[TranslationKey.BACK_TO_PROJECTS],
    },
    [TranslationKey.SCHEMA_SCREENSHOT]: {
        id: TranslationKey.SCHEMA_SCREENSHOT,
        defaultMessage: ru[TranslationKey.SCHEMA_SCREENSHOT],
    },
});
