import styled from 'styled-components';

import { pxToRem, uiColors } from '@repeat/ui-kit';

export const SNavigationDropDownFirstItem = styled.div`
    align-items: center;
    display: flex;
    padding: ${pxToRem(8)} ${pxToRem(16)};
`;

export const SLivePermissionStatus = styled.div<{ status: boolean; size?: string }>`
    background-color: ${({ status }) => (status ? uiColors.green : uiColors.red)};
    border-radius: 100%;
    height: 10px;
    width: 10px;
    margin: ${pxToRem(4)};

    ${({ size }) =>
        size === 'small' &&
        `
			height: 6px;
			margin: 0 0 0 ${pxToRem(4)};
			width: 6px;
  	`}
`;
