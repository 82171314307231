import { FC } from 'react';

import styled from 'styled-components';

import { verticalAlign } from '../../config/mixin';

const SLoader = styled.div`
    ${verticalAlign()};
    height: 100%;
    justify-content: center;
    position: relative;
    width: 100%;

    img {
        width: 200px;
        height: 200px;
    }
`;

export const Loader: FC = () => {
    return (
        <SLoader>
            <img src='assets/loader-default.gif' alt='' />
        </SLoader>
    );
};
