import { useState } from 'react';
import { useIntl } from 'react-intl';

import { ApplicationActions } from '@repeat/common-slices';
import { useAppDispatch } from '@repeat/hooks';
import { ModalTypes } from '@repeat/models';
import { TranslationKey } from '@repeat/translations';
import { Button, Modal } from '@repeat/ui-kit';

import { ImportBlockForm } from '../EngineerMode/ImportBlockForm/ImportBlockForm';
import { messages } from '../EngineerMode/translations';
import { projectMessages } from '../Project/translations';

export const LibraryItemAddModal = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const [isValidForm, setIsValidForm] = useState(false);

    const handleClose = () => {
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.LIBRARYITEM_IMPORT }));
    };

    const FooterButtons = () => (
        <Button variant='secondary' onClick={handleClose}>
            {intl.formatMessage(projectMessages[TranslationKey.CANCEL])}
        </Button>
    );
    const handleFormValidate = (isValid: boolean) => {
        setIsValidForm(isValid);
    };

    return (
        <Modal
            id={ModalTypes.LIBRARYITEM_IMPORT}
            title={intl.formatMessage(messages[TranslationKey.LIBRARYITEM_IMPORT_MODAL_TITLE])}
            isOpen={true}
            hasOverlay={true}
            onClose={handleClose}
            type='default'
            size='small'
            footer={<FooterButtons />}
        >
            <ImportBlockForm onValid={handleFormValidate} />
        </Modal>
    );
};
