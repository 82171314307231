import { LogAction, LogTypes } from '../actions/logTypes';

/* eslint-disable @typescript-eslint/no-explicit-any */

export const addLog = (arg: any): LogAction => ({
    type: LogTypes.ADD_LOG,
    payload: JSON.stringify(arg),
});

export const clearLog = (): LogAction => ({
    type: LogTypes.CLEAR_LOG,
});

export const throwLogError = (err: string): LogAction => ({
    type: LogTypes.THROW_LOG_ERROR,
    payload: err,
});
