import { useState } from 'react';
import { useIntl } from 'react-intl';

import { ApplicationActions } from '@repeat/common-slices';
import { useAppDispatch } from '@repeat/hooks';
import { ModalTypes } from '@repeat/models';
import { TranslationKey } from '@repeat/translations';
import { Button, Modal } from '@repeat/ui-kit';

import { ImportProjectForm } from '../Project/ImportProjectForm/ImportProjectForm';
import { projectMessages as messages } from '../Project/translations';

export const ProjectImportModal = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const [isValidForm, setIsValidForm] = useState(false);

    const handleClose = () => {
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.PROJECT_IMPORT }));
    };

    const FooterButtons = () => (
        <Button variant='secondary' onClick={handleClose}>
            {intl.formatMessage(messages[TranslationKey.CANCEL])}
        </Button>
    );
    const handleFormValidate = (isValid: boolean) => {
        setIsValidForm(isValid);
    };

    return (
        <Modal
            id={ModalTypes.PROJECT_IMPORT}
            title={intl.formatMessage(messages[TranslationKey.PROJECT_IMPORT_MODAL_TITLE])}
            isOpen={true}
            hasOverlay={true}
            onClose={handleClose}
            type='default'
            size='small'
            footer={<FooterButtons />}
        >
            <ImportProjectForm onValid={handleFormValidate} />
        </Modal>
    );
};
