import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';

import {
    SFooter,
    SFooterContentItem,
    SFooterContentItems,
    SFooterControl,
    SFooterControlWrapper,
    SFooterTab,
    SFooterTabBadge,
    SFooterTabs,
} from './SFooter';

import { IconButton } from '../../IconButton/IconButton';

interface IFooter {
    isActive: boolean;
    isFullscreen?: boolean;
    tabs: { name: string; text: string; content: React.ReactNode; badge?: number; disabled?: boolean }[];
    setIsActiveHandler: (state: boolean) => void;
    activeTab?: number;
    onOpen?: () => void;
    onClose?: () => void;
    onMount?: (state: boolean) => void;
    onTabChange?: (state: number) => void;
    onFullScreen?: (state: boolean) => void;
}

const onOpenEvent = new Event('onOpen');
const onCloseEvent = new Event('onClose');

export const Footer: FC<IFooter> = ({
    activeTab,
    tabs,
    isActive,
    isFullscreen,
    setIsActiveHandler,
    onFullScreen,
    onMount,
    onTabChange,
    onOpen,
    onClose,
}) => {
    const [isActiveTab, setIsActiveTab] = useState<number | null>(activeTab || null);
    const [isFull, setIsFull] = useState(isFullscreen || false);
    const footerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.addEventListener('onOpen', () => onOpen && onOpen());
        document.addEventListener('onClose', () => onClose && onClose());
        return () => {
            document.removeEventListener('onOpen', () => onOpen);
            document.removeEventListener('onClose', () => onClose);
        };
    }, []);

    useLayoutEffect(() => {
        if (onMount) {
            onMount(isActive);
        }
    }, []);

    useLayoutEffect(() => {
        if (isActive && onOpen) {
            if (footerRef.current) {
                document.dispatchEvent(onOpenEvent);
            }
        }
        if (!isActive && onClose) {
            if (footerRef.current) {
                document.dispatchEvent(onCloseEvent);
            }
        }
        if (isActive && isActiveTab === null) {
            setIsActiveTab(0);
        }
    }, [isActive]);

    return (
        <SFooter ref={footerRef} isActive={isActive} isWideActive={isFull} data-open={isActive}>
            <SFooterTabs data-tour='footer-tabs'>
                {tabs.map((item, index) => (
                    <SFooterTab key={`footer-tab-${index}`} isActive={isActiveTab === index}>
                        <IconButton
                            disabled={item.disabled}
                            noHover
                            {...item}
                            onClick={() => {
                                setIsActiveTab(index);
                                if (!isActive) {
                                    setIsActiveHandler(true);
                                }
                                if (onTabChange) {
                                    onTabChange(index);
                                }
                            }}
                        >
                            {item?.badge && item.badge !== 0 ? <SFooterTabBadge>{item.badge}</SFooterTabBadge> : null}
                        </IconButton>
                    </SFooterTab>
                ))}
                {isActive && (
                    <SFooterControlWrapper>
                        <SFooterControl>
                            <IconButton
                                style={{ transform: !isFull ? 'rotate(180deg)' : 'none' }}
                                noHover
                                name='chevron'
                                fill='white'
                                onClick={() => {
                                    setIsFull(!isFull);
                                    onFullScreen && onFullScreen(!isFull);
                                }}
                            />
                        </SFooterControl>
                        <SFooterControl>
                            <IconButton
                                noHover
                                name='close'
                                fill='white'
                                onClick={() => {
                                    setIsActiveHandler(false);
                                    setIsFull(false);
                                    setIsActiveTab(null);
                                }}
                            />
                        </SFooterControl>
                    </SFooterControlWrapper>
                )}
            </SFooterTabs>
            {isActive && (
                <SFooterContentItems isWideActive={isFull}>
                    {tabs.map((item, index) => (
                        <SFooterContentItem
                            key={`footer-tab-content-${index}`}
                            id={`footer-content-${index}`}
                            isActive={isActiveTab === index}
                        >
                            {item.content}
                        </SFooterContentItem>
                    ))}
                </SFooterContentItems>
            )}
        </SFooter>
    );
};
