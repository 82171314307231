import { Action } from 'redux';

/* eslint-disable @typescript-eslint/no-explicit-any */

export enum SolverTypeTypes {
    ADD_SOLVER_TYPE = 'ADD_SOLVER_TYPE',
}

export interface SolverTypeAction extends Action<SolverTypeTypes> {
    // TODO: check Type property !!!
    type: SolverTypeTypes;
    payload?: string | any;
}
