import { useCallback } from 'react';

import { useAppDispatch } from '@repeat/hooks';
import { addProjectToFavorites, addProjectsToFavorites, getFavProjects } from '@repeat/store';

export const useFavorites = (favProjects: number[]) => {
    const dispatch = useAppDispatch();

    const handleAddFavorites = useCallback(
        async (projectId: number) => {
            const status = !favProjects.includes(projectId);
            await dispatch(addProjectToFavorites(projectId, status));
            await dispatch(getFavProjects());
        },
        [favProjects]
    );

    const handleAddFavoritesList = useCallback(async (projectIds: number[], status: boolean) => {
        await dispatch(addProjectsToFavorites(projectIds, status));
        await dispatch(getFavProjects());
    }, []);

    return {
        handleAddFavorites,
        handleAddFavoritesList,
    };
};
