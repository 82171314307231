import { FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { AlignedData, Range } from 'uplot';
import UplotReact from 'uplot-react';

import { getTheme, unicodeReplaceSymbol } from '@repeat/constants';
import { EChartItemType, IChartItemParameter, IChartItemType, TChartData } from '@repeat/models';
import { TranslationKey } from '@repeat/translations';
import { uiColors } from '@repeat/ui-kit';

import 'uplot/dist/uPlot.min.css';
import { SUPlot } from './SCharts';

import { messages } from '../translation';

interface IChartPlotProps {
    uuid: string;
    type: IChartItemType | null;
    xParameters: IChartItemParameter;
    yParameters: IChartItemParameter[];
    data: TChartData;
}

const colors = [uiColors.mainBlue, 'red', 'green', 'brown', 'purple', 'orange', 'yellow'];
const xIndexOfYX = 0;
const xPaddingCoef = 1;

export const ChartPlot: FC<IChartPlotProps> = ({ uuid, type, data, xParameters, yParameters }) => {
    const [width, setWidth] = useState(400);
    const [height, setHeight] = useState(200);
    const theme = getTheme();

    const intl = useIntl();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef<HTMLDivElement>(null);

    const minXRef = useRef(0);
    const maxXRef = useRef(0);
    const lastXDataLengthRef = useRef<number | null>(null);

    useLayoutEffect(() => {
        const target = chartRef.current && (chartRef.current.parentNode as HTMLDivElement);
        if (target) {
            const observer = new ResizeObserver(() => {
                const newWidth = target.clientWidth;
                const newHeight = target.clientHeight;
                setWidth(newWidth);
                setHeight(newHeight);
            });
            observer.observe(target);
        }
    }, []);

    useEffect(() => {
        if (type === EChartItemType.YFromX && data) {
            const dataLength = data[xIndexOfYX].length;

            const lastXDataLength = lastXDataLengthRef.current;
            if (lastXDataLength === null) {
                if (dataLength > 0) {
                    const minX = data ? Math.min(...data[xIndexOfYX]) : 0;
                    const maxX = data ? Math.max(...data[xIndexOfYX]) : 0;

                    minXRef.current = minX;
                    maxXRef.current = maxX;
                }
            } else {
                const lastXData = data[xIndexOfYX].slice(lastXDataLength);
                if (lastXData.length > 0) {
                    const minX = Math.min(...lastXData, minXRef.current);
                    const maxX = Math.max(...lastXData, maxXRef.current);

                    minXRef.current = minX;
                    maxXRef.current = maxX;
                }
            }

            lastXDataLengthRef.current = dataLength;
        }
    }, [data]);

    const makeSeriesOptions = useCallback((xParameters: IChartItemParameter, yParameters: IChartItemParameter[]) => {
        return [
            {
                label: `${unicodeReplaceSymbol(xParameters?.title)}:`,
                value: (self: uPlot, rawValue: number) => rawValue,
            },
            ...yParameters.map((yParameter: IChartItemParameter, index) => ({
                spanGaps: false,
                label: `${
                    unicodeReplaceSymbol(yParameter.title) ||
                    intl.formatMessage(messages[TranslationKey.WORKSPACE_CHART_POINT_VALUE])
                }:`,
                stroke: colors[index],
                width: 1,
                value: (self: uPlot, rawValue: number) => rawValue,
            })),
        ];
    }, []);

    const makeOptions = useCallback(
        (type: IChartItemType | null, xParameters: IChartItemParameter, yParameters: IChartItemParameter[]) => {
            const xScale =
                type === EChartItemType.YFromX
                    ? {
                          time: false,
                          auto: false,
                          range: (self: uPlot, low: number, high: number, scaleKey: string) => {
                              let customLow = minXRef.current;
                              let customHigh = maxXRef.current;
                              if (low && high && low !== minXRef.current) {
                                  customLow = low > minXRef.current ? low : minXRef.current;
                                  customHigh = high < maxXRef.current ? high : maxXRef.current;
                              }

                              return [customLow * xPaddingCoef, customHigh * xPaddingCoef] as Range.MinMax;
                          },
                      }
                    : { time: false };
            const chartType = type || EChartItemType.YFromT;
            const chartKey = chartType === EChartItemType.YFromX ? `${chartType}-${uuid}` : chartType;

            return {
                width,
                height,
                pxAlign: false,
                cursor: {
                    sync: {
                        key: chartKey,
                    },
                },
                series: makeSeriesOptions(xParameters, yParameters),
                scales: {
                    x: xScale,
                },
                axes: [
                    {
                        space: 100,
                        values: (self: uPlot, ticks: number[]) => ticks.map((rawValue) => rawValue),
                    },
                    {
                        size: (self: uPlot, values: string[], axisIdx: number, cycleNum: number) => {
                            const axis = self.axes[axisIdx] as uPlot.Axis & { _size: number };

                            if (cycleNum > 1) {
                                return axis._size;
                            }
                            let axisSize = axis._size;

                            if (axis.ticks && axis.ticks.size && axis.gap && axis.font) {
                                axisSize = axis.ticks.size + axis.gap;
                                const longestVal = (values ?? []).reduce(
                                    (acc, val) => (val.toString().length > acc.toString().length ? val : acc),
                                    ''
                                );

                                if (longestVal !== '') {
                                    self.ctx.font = axis.font[0];
                                    axisSize += self.ctx.measureText(longestVal).width / devicePixelRatio;
                                }
                            }
                            return Math.ceil(axisSize);
                        },
                        values: (self: uPlot, ticks: number[]) => ticks.map((rawValue) => rawValue),
                    },
                ],
            };
        },
        []
    );

    const initialOptions = makeOptions(type, xParameters, yParameters);

    const [options, setOptions] = useState(initialOptions);

    useEffect(() => {
        const updatingSeries = makeSeriesOptions(xParameters, yParameters);
        setOptions({
            ...options,
            series: updatingSeries,
        });
    }, [yParameters]);

    return (
        <div ref={wrapperRef} style={{ width: '100%', height: '100%' }}>
            <SUPlot ref={chartRef} theme={theme}>
                <UplotReact
                    options={{ ...options, width, height: height - 100 }}
                    data={data as AlignedData}
                    target={chartRef.current || undefined}
                />
            </SUPlot>
        </div>
    );
};
