import { ChangeEvent, FC, MouseEvent, forwardRef, memo, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';

import * as ruPhone from 'react-phone-input-2/lang/ru.json';
import 'react-phone-input-2/lib/style.css';

import { SInputButton, SInputError, SInputField, SInputWrapper, SLabel } from './SInputField';

import { Icon } from '../Icon/Icon';

export { InputVariant } from './SInputField';

export type TInputVariant = 'default' | 'success' | 'warning' | 'danger';

export interface IInputTextField {
    locale?: string;
    type?: string;
    defaultValue?: string | number;
    placeholder?: string;
    maxLength?: number;
    minLength?: number;
    value?: string | number;
    name?: string;
    errorText?: string | boolean;
    error?: boolean;
    required?: boolean;
    disabled?: boolean;
    label?: React.ReactNode | string;
    id?: string;
    autoComplete?: string;
    size?: string;
    variant?: TInputVariant;
    minValue?: string;
    maxValue?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const PhoneField: FC<any> = memo(
    forwardRef((props, ref) => {
        const { name, required, value, defaultValue, onChange, locale, onBlur } = props;
        const [inputValue, setInputValue] = useState(value);

        useEffect(() => {
            setInputValue(defaultValue as string);
        }, []);

        return (
            <PhoneInput
                onBlur={onBlur}
                value={inputValue}
                country={'ru'}
                masks={{ in: '.. ....-....' }}
                onChange={(phone, data, event) => {
                    setInputValue(phone);
                    onChange && onChange(event);
                }}
                inputProps={{
                    ref,
                    name,
                    required,
                }}
                {...(locale === 'ru' && { localization: ruPhone })}
            />
        );
    })
);

export const DateField: FC<any> = memo(
    forwardRef((props, ref) => {
        const { value, onChange, defaultValue, locale, ...rest } = props;

        return <SInputField {...rest} defaultValue={defaultValue} ref={ref} type={'date'} />;
    })
);

export const InputField = forwardRef<HTMLInputElement, IInputTextField>((props, ref) => {
    const {
        variant,
        type = 'text',
        autoComplete = 'on',
        locale = 'ru',
        error,
        errorText,
        label,
        id,
        size,
        minValue,
        maxValue,
        ...rest
    } = props;
    const [inputType, setInputType] = useState<string>(type);

    const showPassHandler = (event: MouseEvent) => {
        event.preventDefault();
        if (inputType === 'text') {
            return setInputType('password');
        }
        return setInputType('text');
    };

    let InputComponent = (
        <SInputField
            {...rest}
            variant={variant}
            ref={ref}
            id={id}
            type={inputType}
            error={error}
            autoComplete={inputType !== 'password' ? autoComplete : 'false'}
            onBlur={(event) => {
                if (rest.onBlur) {
                    rest.onBlur(event);
                }
            }}
            min={minValue && minValue}
            max={maxValue && maxValue}
            onKeyDown={(event) => {
                if (minValue || maxValue) {
                    event.preventDefault();
                }
                if (rest.onKeyDown) {
                    rest.onKeyDown(event);
                }
            }}
        />
    );

    if (inputType === 'tel') {
        InputComponent = <PhoneField ref={ref} locale={locale} {...rest} />;
    }

    if (inputType === 'date') {
        InputComponent = <DateField ref={ref} locale={locale} {...rest} />;
    }

    return (
        <SInputWrapper
            data-variant={variant}
            data-type={inputType}
            dataType={inputType}
            error={Boolean(error)}
            size={size}
        >
            {label && (
                <SLabel error={Boolean(error)} htmlFor={id}>
                    {label}
                </SLabel>
            )}
            {InputComponent}
            {error && typeof errorText === 'string' && <SInputError>{errorText}</SInputError>}
            {error &&
                Array.isArray(errorText) &&
                errorText.map(
                    (item, index) => item && <SInputError key={`error-text--${id}-${index}`}>{item}</SInputError>
                )}
            {type === 'password' && (
                <SInputButton label={label} onClick={showPassHandler}>
                    <Icon name={inputType === 'password' ? 'eyeOpen' : 'eyeClose'} />
                </SInputButton>
            )}
        </SInputWrapper>
    );
});
