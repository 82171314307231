import styled, { css } from 'styled-components';

import { pxToRem, radius, uiRGBColors, zIndex } from '../../config/config';

export const STableLabel = styled.span`
    color: var(--ui-label);
    display: block;
    padding: 0 !important;

    &::after {
        content: ':';
        margin-right: ${pxToRem(4)};
    }
`;

const DefaultTableStyles = css`
    border: 0;
    border-collapse: collapse;
    border-spacing: ${pxToRem(8)};
    position: relative;
    empty-cells: hide;
    width: 100%;
    font-size: ${pxToRem(14)};
    z-index: ${zIndex.base};
    cursor: default;
    box-shadow: 0 0 2px 0 var(--ui-table-hover);

    thead {
        background-color: var(--ui-table);

        th {
            padding: ${pxToRem(16)} ${pxToRem(8)};
        }
    }

    tbody {
        tr {
            &:hover {
                background-color: var(--ui-table-hover);
            }
        }
    }

    th,
    td {
        padding: ${pxToRem(8)};
        vertical-align: middle;
    }

    tr {
        min-height: ${pxToRem(32)};
        border-bottom: 1px solid var(--ui-table-hover);

        [data-card-image] {
            display: none;
        }

        > td {
            color: var(--ui-text);
        }

        &:last-child {
            border-bottom: 0;
        }

        td:last-child {
            padding: ${pxToRem(8)} ${pxToRem(16)};
            > span {
                visibility: hidden;
            }
        }

        &:hover {
            td:last-child > span {
                visibility: visible;
            }
        }
    }

    tr > td:last-child span {
        justify-content: flex-end;

        i {
            min-height: 24px;
            min-width: 24px;
        }

        menu li {
            i {
                min-height: 20px;
                min-width: 20px;
            }
        }
    }

    ${STableLabel} {
        display: none;
    }

    &[data-view-mode='card'] {
        display: block;
        box-shadow: none;

        ${STableLabel} {
            display: block;
        }

        thead {
            display: none;
        }

        tbody {
            display: grid;
            grid-template-columns: repeat(4, calc(100% / 4 - 6px));
            gap: ${pxToRem(8)};

            @media screen and (max-width: 1280px) {
                grid-template-columns: repeat(3, calc(100% / 3 - 6px));
            }

            @media screen and (max-width: 1024px) {
                grid-template-columns: repeat(2, calc(100% / 2 - 6px));
            }

            @media screen and (max-width: 768px) {
                grid-template-columns: repeat(1, calc(100% / 1 - 6px));
            }

            tr {
                border: 1px solid var(--ui-table-hover);
                border-radius: ${radius.default};
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: 170px 32px repeat(2, 16px);
                position: relative;
                width: 100%;
                padding-bottom: ${pxToRem(8)};

                [data-card-image] {
                    display: grid;
                    justify-content: space-between;
                    grid-template-columns: 1fr;

                    i {
                        justify-self: center;
                    }
                }

                td {
                    font-size: ${pxToRem(12)};
                    padding: ${pxToRem(2)} ${pxToRem(16)};

                    &:nth-child(3) {
                        display: flex;
                        align-items: center;
                        padding-top: ${pxToRem(8)};

                        span {
                            padding: 0 0 ${pxToRem(4)} 0;
                        }
                    }

                    &:nth-child(2) {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-template-rows: 100px 1fr;
                        font-size: ${pxToRem(14)};
                        padding-top: ${pxToRem(12)};

                        span {
                            padding: ${pxToRem(8)} 0;
                            display: inline-block;
                            overflow: hidden;
                            width: 100%;
                        }
                    }
                }

                td:last-child {
                    > span {
                        background-color: var(--ui-sidebar);
                        padding: ${pxToRem(4)};
                        border-radius: ${radius.default};
                        position: absolute;
                        top: 8px;
                        right: 8px;
                    }
                }

                td:first-child {
                    display: none;
                }
            }
        }
    }

    &[data-projects-type='demo'] {
        tbody tr td:first-child {
            display: none;
        }
    }
`;

const StyledTable = styled.table`
    border: 1px solid rgba(${uiRGBColors.blackSecond}, 0.2);
    border-radius: ${radius.default};
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;

    th {
        text-align: left;
    }

    th,
    td {
        padding: ${pxToRem(4)} ${pxToRem(8)};
    }

    thead {
        border-bottom: 1px solid rgba(${uiRGBColors.blackSecond}, 0.2);

        th:first-child {
            width: calc(${pxToRem(32)} * 3);
        }

        th:last-child {
            width: calc(${pxToRem(32)} * 2);
        }
    }
`;

const StyledTbody = styled.tbody`
    tr {
        cursor: pointer;
        border-bottom: 1px solid rgba(${uiRGBColors.blackSecond}, 0.2);

        &:hover td {
            background-color: rgba(${uiRGBColors.whiteSecond}, 0.7);
        }
    }
`;

const StyledTableButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

export { DefaultTableStyles, StyledTable, StyledTableButtons, StyledTbody };
