import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { TProjectSaveNewModal } from 'libs/models/src/lib/modal';

import { ApplicationActions } from '@repeat/common-slices';
import { SAVE_NEW_PROJECT_FORM } from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { ModalTypes, Project, Statuses } from '@repeat/models';
import { getProjects } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { Button, Loader, Modal } from '@repeat/ui-kit';

import { SaveNewProjectForm } from '../Project/SaveNewProjectForm/SaveNewProjectForm';
import { projectMessages as messages } from '../Project/translations';

export const ProjectSaveNewModal: FC = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const modals = useAppSelector((state) => state.app.modals);
    const modal = modals.find((m) => m.type === ModalTypes.PROJECT_SAVE_NEW) as TProjectSaveNewModal;
    const projectsState = useAppSelector((state) => state.projects);
    const [isValidForm, setIsValidForm] = useState(false);
    const [project] = useState<Pick<Project, 'projectName' | 'projectId'>>(modal.data.project);

    const isProjectsInitialized = projectsState.status === Statuses.SUCCEEDED;
    useEffect(() => {
        if (isProjectsInitialized === false) {
            dispatch(getProjects());
        }
    }, [dispatch, isProjectsInitialized]);

    const handleClose = () => {
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.PROJECT_SAVE_NEW }));
    };

    const FooterButtons = () => (
        <>
            <Button variant='secondary' onClick={handleClose}>
                {intl.formatMessage(messages[TranslationKey.CANCEL])}
            </Button>
            {isProjectsInitialized && (
                <Button form={SAVE_NEW_PROJECT_FORM} type='submit' disabled={!isValidForm}>
                    {intl.formatMessage(messages[TranslationKey.SAVE])}
                </Button>
            )}
        </>
    );
    const handleFormValidate = (isValid: boolean) => {
        setIsValidForm(isValid);
    };

    return (
        <Modal
            id={ModalTypes.PROJECT_EDIT}
            title={intl.formatMessage(messages[TranslationKey.SAVE_AS])}
            isOpen={true}
            hasOverlay={true}
            onClose={handleClose}
            type='default'
            size='small'
            footer={<FooterButtons />}
        >
            {projectsState.status === Statuses.LOADING && <Loader />}
            {projectsState.status === Statuses.FAILED && (
                <>{intl.formatMessage(messages[TranslationKey.WORKSPACE_ERROR_GETTING_PROJECTS_LIST])}</>
            )}
            {project && isProjectsInitialized && <SaveNewProjectForm project={project} onValid={handleFormValidate} />}
        </Modal>
    );
};
