import styled from 'styled-components';

import { Icon, InputVariant, pxToRem, TInputVariant, uiColors } from '@repeat/ui-kit';

export const SProjectSettingsCompactWrapper = styled.div<{ status: TInputVariant }>`
    align-items: center;
    border-radius: ${pxToRem(2)};
    border: 0 solid ${uiColors.grey};
    color: ${uiColors.darkGrey};
    display: flex;
    font-size: ${pxToRem(14)};
    padding-right: ${pxToRem(8)};

    ${({ status }) => status === InputVariant.DANGER && `border: 1px solid ${uiColors.red} !important`};
    ${({ status }) => status === InputVariant.WARNING && `border: 1px solid ${uiColors.yellow} !important`};

    svg {
        ${({ status }) => status === InputVariant.DANGER && `fill: ${uiColors.red} !important`};
        ${({ status }) => status === InputVariant.WARNING && `fill: ${uiColors.yellow} !important`};
    }

    > span {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: ${pxToRem(60)};
    }
`;

export const SProjectSettingsItem = styled.div`
    margin-bottom: 8px;

    label {
        font-size: ${pxToRem(12)} !important;
        color: ${uiColors.darkGrey} !important;
        font-weight: normal;
        padding: ${pxToRem(4)} 0;
    }

    [aria-haspopup='listbox'] {
        padding: ${pxToRem(4)} ${pxToRem(8)};
        font-size: 14px;

        &:focus,
        &:active,
        &:focus-visible {
            outline: none;
        }
    }
`;

export const SProjectSettingsWrapper = styled.div`
    form {
        padding: 0;
        margin: 0;

        > div {
            padding: 0;
        }
    }

    label,
    [data-type='toggle'] span {
        font-size: ${pxToRem(12)};
        color: ${uiColors.darkGrey};
        max-width: calc(100% - 50px);
        width: 100%;
    }

    [data-tour='workspace-project-name'] {
        margin-bottom: ${pxToRem(16)};
    }
`;

export const SCustomIcon = styled(Icon)`
    margin-left: ${pxToRem(8)};
    max-height: ${pxToRem(14)};
    max-width: ${pxToRem(14)};
    height: ${pxToRem(14)};
    width: 100%;
`;

export const SLabelWrapper = styled.span`
    display: flex;
`;
