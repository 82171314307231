import { isHotkeyPressed, useHotkeys } from 'react-hotkeys-hook';

import { ApplicationActions } from '@repeat/common-slices';
import { useAppDispatch } from '@repeat/hooks';
import { THotkey } from '@repeat/models';

export const Hotkeys = () => {
    const dispatch = useAppDispatch();

    const hotkeyHandler = (key: THotkey) => {
        dispatch(ApplicationActions.setHotkeyPressed(key));
    };
    const hotkeyMetaHandler = (key: THotkey) => {
        if (!isHotkeyPressed('meta')) {
            dispatch(ApplicationActions.setHotkeyPressed({ ...key, ctrl: true, meta: false }));
        } else {
            dispatch(ApplicationActions.setHotkeyPressed(key));
        }
    };
    useHotkeys('delete, backspace', (_, handler) => hotkeyHandler(handler), {
        scopes: 'workspacePage',
        preventDefault: true,
    });
    useHotkeys('meta+a, ctrl+a, meta+s, ctrl+s, ctrl+g, meta+g', (_, handler) => hotkeyMetaHandler(handler), {
        scopes: 'workspacePage',
        preventDefault: true,
    });
    useHotkeys(
        'meta+c, ctrl+c, meta+v, ctrl+v, meta+z, ctrl+z, meta+shift+z, ctrl+shift+z',
        (_, handler) => hotkeyMetaHandler(handler),
        {
            scopes: 'workspacePage',
            preventDefault: false,
        }
    );
    useHotkeys('meta+h', (_, handler) => hotkeyHandler(handler), { scopes: 'global' });
    return null;
};
