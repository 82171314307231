import { getCookie } from '@repeat/constants';
import { environment } from '@repeat/environment';

const { WS_URL } = environment;

export type WebSocketCallback = (event: Event, socket: WebSocket) => void;
export type MessageCallback = (event: MessageEvent, socket: WebSocket) => void;
export type CloseCallback = (event: CloseEvent, socket: WebSocket) => void;

export interface WebSocketOptions {
    onOpen?: (socket: WebSocket) => void;
    onMessage?: MessageCallback;
    onClose?: CloseCallback;
    onError?: WebSocketCallback;
}

export const webSocketClient = (
    urlPath: string,
    onOpen: (socket: WebSocket) => void,
    onMessage: (e: MessageEvent, socket: WebSocket) => void,
    onClose: (e: CloseEvent, socket: WebSocket) => void,
    onError: (error: Event, socket: WebSocket) => void
) => {
    const url = `${WS_URL}${urlPath}`;
    const socket = new WebSocket(url);

    socket.onopen = () => {
        socket.send(`${getCookie('accessToken')}`);
        onOpen(socket);
    };

    socket.onmessage = (event) => {
        onMessage(event, socket);
    };

    socket.onclose = (event) => {
        !!socket?.send && socket.send('Client Closed!');
        onClose(event, socket);
    };

    socket.onerror = (event: Event) => {
        onError(event, socket);
    };

    return socket;
};

export class WebSocketWrapper {
    private urlPath: string;
    private options: WebSocketOptions;
    private socket: WebSocket | null;

    constructor(urlPath: string, options: WebSocketOptions = {}) {
        this.urlPath = urlPath;
        this.options = options;
        this.socket = null;
    }

    socketStatus() {
        return this.socket;
    }

    connect() {
        this.socket = webSocketClient(
            this.urlPath,
            //eslint-disable-next-line
            this.options.onOpen || ((socket) => {}),
            //eslint-disable-next-line
            this.options.onMessage || ((event, socket) => {}),
            //eslint-disable-next-line
            this.options.onClose || ((event) => {}),
            //eslint-disable-next-line
            this.options.onError || ((event) => {})
        );
        return this.socket;
    }
}
