import styled from 'styled-components';

import { pxToRem } from '@repeat/ui-kit';

export const SSelectedText = styled.div`
    font-size: ${pxToRem(12)};
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const SButtons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
`;
