import { FC, useContext } from 'react';
import { useIntl } from 'react-intl';

import { css } from 'styled-components';

import { monoLogoUrl, VERSION } from '@repeat/constants';
import { useAppSelector } from '@repeat/hooks';
import { appUserSelectors } from '@repeat/store';
import { Header, Layout, Main as MainBlock, MainContent, Sidebar, TourContext } from '@repeat/ui-kit';

import { ProjectsContent } from './ProjectsContent';

import { Navigation } from '../../features/Navigation/Navigation';
import { ProjectsToolbar } from '../../features/Project/ProjectsToolbar';

interface IProjectsPageSubLayout {
    title: string;
    children: React.ReactNode;
}

export const ProjectsPageSubLayout: FC<IProjectsPageSubLayout> = ({ children, title }) => {
    const { locale } = useIntl();
    const userIsAuth = useAppSelector(appUserSelectors.isAuth);

    const { setOpen, open } = useContext(TourContext);

    return (
        <Layout internal>
            <Header
                Navigation={userIsAuth && <Navigation setTourOpen={setOpen} />}
                version={VERSION}
                logoUrl={monoLogoUrl(locale)}
                indexUrl='/projects'
            />
            <Sidebar canHide={false}>
                <ProjectsToolbar />
            </Sidebar>
            <MainBlock
                style={{ paddingTop: 0, marginTop: '3rem', minHeight: 'auto', height: 'auto' } as keyof typeof css}
            >
                <ProjectsContent>
                    <h3>{title}</h3>
                    <MainContent>{children}</MainContent>
                </ProjectsContent>
            </MainBlock>
        </Layout>
    );
};
