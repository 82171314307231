import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { CREATE_PROJECT_FORM, validateEmptySpaces, validateExistingProjectName } from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { IProjectSubmit, LibraryTypes, SolverTypes, Statuses, TProjectCreateData } from '@repeat/models';
import { createProject, fetchAvailableLibraries, getProjects } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { InputField, StyledForm } from '@repeat/ui-kit';

import { projectMessages as messages } from '../translations';

interface ICreateProjectForm {
    onValid: (isValid: boolean) => void;
}

export const CreateProjectForm: FC<ICreateProjectForm> = ({ onValid }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const projectsState = useAppSelector((state) => state.projects);

    const { formatMessage } = useIntl();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm<Pick<IProjectSubmit, 'projectName'>>({ mode: 'onChange' });

    const isProjectsInitialized = projectsState.status === Statuses.SUCCEEDED;

    useEffect(() => {
        onValid(isValid);
    }, [isValid]);

    useEffect(() => {
        dispatch(fetchAvailableLibraries());
        if (isProjectsInitialized === false) {
            dispatch(getProjects());
        }
    }, [dispatch, isProjectsInitialized]);

    const projects = projectsState.items || [];

    const onSubmit = (data: Pick<IProjectSubmit, 'projectName'>) => {
        dispatch(
            createProject(
                { ...data, solverType: SolverTypes.MDCORE, libraryType: LibraryTypes.MDLIBRARY } as TProjectCreateData,
                navigate
            )
        ).finally(() => reset());
    };

    const inputProjectNameErrors =
        (errors.projectName?.type === 'required' && true) ||
        (errors.projectName?.type === 'uniqueName' && formatMessage(messages[TranslationKey.ERROR_NEW_PROJECT_NAME])) ||
        (errors.projectName?.type === 'noWhitespace' &&
            formatMessage(messages[TranslationKey.ERROR_PROJECT_NAME_IS_EMPTY]));

    return (
        <StyledForm onSubmit={handleSubmit(onSubmit)} id={CREATE_PROJECT_FORM}>
            <InputField
                label={formatMessage(messages[TranslationKey.PROJECT_NAME])}
                type='text'
                id='standard-required'
                {...register('projectName', {
                    required: true,
                    validate: {
                        noWhitespace: validateEmptySpaces,
                        uniqueName: (value: string) => validateExistingProjectName(value, projects),
                    },
                })}
                error={!!errors?.projectName}
                errorText={inputProjectNameErrors}
                maxLength={100}
            />
        </StyledForm>
    );
};
