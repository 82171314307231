import { $api } from './http';

interface IShortCircuitServiceData {
    data: Record<string, any>;
    header: Record<string, any>;
    [key: string]: any;
}

export class ShortCircuitService {
    static calculate = async (projectId: string, payload: IShortCircuitServiceData, signal?: AbortSignal) => {
        return await $api.post('/api/v1/modeling/calcShortCircuit', payload, {
            headers: { projectId: projectId },
        });
    };
}
