import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.VARIABLE]: {
        id: TranslationKey.VARIABLE,
        defaultMessage: ru[TranslationKey.VARIABLE],
    },
    [TranslationKey.WORKSPACE_PAGE_ERROR_VARIABLES]: {
        id: TranslationKey.WORKSPACE_PAGE_ERROR_VARIABLES,
        defaultMessage: ru[TranslationKey.WORKSPACE_PAGE_ERROR_VARIABLES],
    },
    [TranslationKey.ADD]: {
        id: TranslationKey.ADD,
        defaultMessage: ru[TranslationKey.ADD],
    },
    [TranslationKey.WORKSPACE_CHART_POINT_VALUE]: {
        id: TranslationKey.WORKSPACE_CHART_POINT_VALUE,
        defaultMessage: ru[TranslationKey.WORKSPACE_CHART_POINT_VALUE],
    },
    [TranslationKey.WORKSPACE_PAGE_ERROR_NAME_VARIABLES]: {
        id: TranslationKey.WORKSPACE_PAGE_ERROR_NAME_VARIABLES,
        defaultMessage: ru[TranslationKey.WORKSPACE_PAGE_ERROR_NAME_VARIABLES],
    },
    [TranslationKey.WORKSPACE_PAGE_ERROR_VALUE_VARIABLES]: {
        id: TranslationKey.WORKSPACE_PAGE_ERROR_VALUE_VARIABLES,
        defaultMessage: ru[TranslationKey.WORKSPACE_PAGE_ERROR_VALUE_VARIABLES],
    },
});
