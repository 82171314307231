import styled from 'styled-components';

import { border, layoutSize, pxToRem, shadow, zIndex } from '../../../config/config';
import { verticalAlign } from '../../../config/mixin';

export const SSidebarContent = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const SSidebarTitle = styled.div`
    ${verticalAlign()};
    background: var(--ui-sidebar);
    border-bottom: ${border.default};
    font-size: ${pxToRem(14)};
    height: ${layoutSize.sidebarTitle};
    padding: ${pxToRem(8)} ${pxToRem(16)};
    z-index: ${zIndex.top - 1};
    position: sticky;
    top: 0;

    i {
        margin-right: ${pxToRem(8)};

        svg {
            fill: var(--ui-text);
        }
    }
`;

export const SSidebarTrigger = styled.button<{ position: string; open: boolean }>`
    background: var(--ui-sidebar);
    border: none;
    cursor: pointer;
    position: absolute;
    height: ${pxToRem(26)};
    top: calc((100% / 2) - (${layoutSize.footer}));
    width: ${pxToRem(20)};
    z-index: ${zIndex.sidebar - 1};

    i {
        ${({ open, position }) => !open && position === 'LEFT' && `transform: rotate(-180deg)`};
        ${({ open, position }) => open && position === 'LEFT' && `transform: rotate(0)`};
        ${({ open, position }) => open && position === 'RIGHT' && `transform: rotate(-180deg)`};
    }

    i svg {
        fill: var(--ui-link-default) !important;
    }

    &:hover {
        i:hover svg {
            fill: var(--ui-link-active) !important;
        }
    }
`;

export const SSidebarAddon = styled.div<{ position: string }>`
    ${verticalAlign()};
    border-radius: 2px;
    justify-content: space-between;
    padding: 0;
    position: absolute;
    top: ${pxToRem(8)};
    transition: background 0.3s ease;
    width: auto;

    i svg {
        fill: var(--ui-icons);
    }

    > div {
        background-color: var(--ui-sidebar);
        box-shadow: ${shadow.controls};
        cursor: pointer;
        height: 100%;
    }

    > div:hover {
        i:hover svg {
            fill: var(--ui-icons-hover);
        }
    }

    ${({ position }) => position === 'LEFT' && `left: calc(100% + ${pxToRem(16)})`};
    ${({ position }) => position === 'RIGHT' && `right: calc(100% + ${pxToRem(16)})`};

    > * {
        position: relative;

        &:last-child {
            border-right: 0;
        }
    }

    button {
        padding: ${pxToRem(4)} ${pxToRem(5)};
        width: ${pxToRem(28)};
    }
`;

export const SSidebar = styled.aside<{ position: string; ['data-open']: boolean }>`
    background: var(--ui-sidebar);
    box-shadow: ${shadow.controls};
    height: calc(100vh - ${layoutSize.header} - ${layoutSize.footer});
    padding: 0;
    position: absolute;
    transition: all 0.3s ease;
    width: ${layoutSize.sidebar};
    will-change: left, right;
    z-index: ${zIndex.top};

    ${({ position }) =>
        position === 'LEFT'
            ? `
            left: -${layoutSize.sidebar};

            ${SSidebarTrigger} {
                right: -${pxToRem(20)};
            }
        `
            : `
            right: -${layoutSize.sidebar};

            ${SSidebarTrigger} {
                left: -${pxToRem(20)};
            }
        `};

    ${({ position }) => position === 'RIGHT' && 'grid-area: RightSidebar'};

    &[data-open='true'] {
        ${({ position }) => position === 'LEFT' && `left: 0`};
        ${({ position }) => position === 'RIGHT' && `right: 0`};
    }

    &::after {
        background: var(--ui-sidebar);
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        width: ${pxToRem(8)};
        visibility: hidden;
        z-index: ${zIndex.sidebar - 2};

        ${({ position }) => position === 'RIGHT' && `left: -${pxToRem(8)}`};
        ${({ position }) => position === 'LEFT' && `right: -${pxToRem(8)}`};
    }

    &[data-open='false'] {
        ${({ position }) =>
            position === 'LEFT' &&
            `
                &::after {
                    visibility: visible;
                }
            `}

        ${({ position }) =>
            position === 'RIGHT' &&
            `
        &::after {
            visibility: visible;
        }
    `}
    }
`;
