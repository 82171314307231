import { AxiosResponse } from 'axios';

import { $api } from './http';

interface IReportErrorPayload {
    userId: number;
    message: string;
    context: { projectId: number };
}
type ReportErrorPayload = IReportErrorPayload;
interface IReportError {
    (payload: ReportErrorPayload, signal?: AbortSignal): Promise<AxiosResponse<{ status: string }>> | Promise<void>;
}

export default class ErrorReportService {
    static reportError: IReportError = async (payload: IReportErrorPayload, signal?: AbortSignal) => {
        try {
            await $api.post('/api/v1/internal/createLog', { ...payload, level: 'error', service: 'frontend' }, { headers: { internalKey: '123' }, signal });
        } catch (error) {
            console.error(error);
        }
    };
}
