import styled from 'styled-components';

import { border, fontSize, pxToRem, radius, shadow, uiColors, zIndex } from '@repeat/ui-kit';

export const SElementDescription = styled.div`
    position: absolute;
    top: ${pxToRem(80)};
    right: 0;
    min-width: ${pxToRem(200)};
    width: ${pxToRem(300)};
    min-height: ${pxToRem(150)};
    height: ${pxToRem(300)};
    overflow-y: scroll;
    scrollbar-width: none;
    color: var(--ui-heading);
    background-color: var(--ui-background);
    font-size: ${pxToRem(12)};
    border: ${border.sidebar}};
    border-radius: ${radius.default};
    box-shadow: ${shadow.default};
    z-index: ${zIndex.super};

    h4 {
        background-color: var(--ui-background);
        position: sticky;
        top: 0;
        display: flex;
        justify-content: space-between;
        align-items: start;
        padding: ${pxToRem(8)};
        margin: 0;
        font-size: ${fontSize.default};
        line-height: 150%;
        font-weight: bold;
        z-index: ${zIndex.top};
        border-bottom: ${border.default};
    }

    button {
        top: 0;
        min-width: ${pxToRem(25)};
    }

    p {
        padding: ${pxToRem(8)};
        font-size: ${pxToRem(14)};
    }

    ul {
        padding: ${pxToRem(8)};
        font-size: ${pxToRem(14)};
    }

    img {
        width: ${pxToRem(300)};
    }

    blockquote {
        padding-left: ${pxToRem(10)};
        border-left: 5px solid ${uiColors.grey};
    }

    table {
        margin-bottom: ${pxToRem(20)};
    }

    table tr,
    table th,
    table td {
        border: ${border.default};
        padding: ${pxToRem(5)}
    }

    table th {
        text-align: start;
        background-color: ${uiColors.greyLight};
    }
`;

export const SElementDescriptionTitle = styled.h4`
    cursor: move;
`;
