import { SolverTypeAction, SolverTypeTypes } from '../actions/solverTypeTypes';

/* eslint-disable @typescript-eslint/no-explicit-any */

export const addSolverType = (type: any): SolverTypeAction => {
    return {
        type: SolverTypeTypes.ADD_SOLVER_TYPE,
        payload: type,
    };
};
