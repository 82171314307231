import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { FileManagerBody } from './FileManagerBody';
import { FileManagerConfirmDelete } from './FileManagerConfirmDelete';
import { FileManagerFooter } from './FileManagerFooter';
import { FileManagerHeader } from './FileManagerHeader';
import { DEFAULT_RULES, isNameUnique, setDefaultRules } from './FileManagerHelpers';
import { FileManagerSelectionArea } from './FileManagerSelectionArea';
import {
    EFileManagerItemType,
    EFIleManagerSort,
    FILE_MANAGER_SORT_KEY,
    FILE_MANAGER_PREVIEW_KEY,
    IFileManager,
    Statuses,
    TFileManagerElement,
    TFileManagerSortVariant,
    TOnMovePayload,
} from './FileManagerTypes';
import { SFileManagerWrapper } from './SFileManager';

import { useSelect } from '../../hooks/useSelect';
import { useViewMode } from '../../hooks/useViewMode';

export const FileManager: FC<IFileManager> = ({
    store,
    translations,
    imageLoader,
    nameListCompare = [],
    favoriteList = [],
    rules,
    onGetData,
    onCreateFolder,
    onEditFolder,
    onEditFile,
    onDelete,
    onMove,
    onOpenFile,
    onExport,
    onImport,
    onCopyId,
    onCreateFile,
    onAddToFavorites,
    onDuplicate,
    onManageDemo,
    onAddToRoot,
}) => {
    const [currentElements, setCurrentElements] = useState({
        files: store.elements,
    });
    const [currentFolderId, setCurrentFolderId] = useState(0);
    const [previousFolderIds, setPreviousFolderIds] = useState<number[]>([0]);
    const [sortType, setSortType] = useState<TFileManagerSortVariant>(
        (localStorage.getItem(FILE_MANAGER_SORT_KEY) as TFileManagerSortVariant) || EFIleManagerSort.DEFAULT
    );
    const [removeElements, setRemoveElements] = useState<TFileManagerElement[]>([]);
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [isImportOpen, setIsImportOpen] = useState(false);
    const [isItemDragging, setIsItemFDragging] = useState(false);
    const [isShowPreloader, setIsShowPreloader] = useState(false);
    const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(() => {
        const value = localStorage.getItem(FILE_MANAGER_PREVIEW_KEY);
        if (typeof value !== 'undefined' && value === 'false') return false;
        return true;
    });

    const { mode, handleChangeMode } = useViewMode('file-manager-view-mode');
    const { selectedBoxes, handleSelect, handleUnSelectAll, handleSelectAll, handleSelectGroup } = useSelect(
        currentElements.files
    );

    const updatedRules = useMemo(() => {
        if (rules) {
            return { ...setDefaultRules(rules) };
        }
        return DEFAULT_RULES;
    }, []);

    const getData = async (id: number) => {
        setIsShowPreloader(true);
        if (onGetData) {
            return await onGetData(id)
                .then((data) => {
                    if (data && data !== null) {
                        return data;
                    }
                    return [];
                })
                .finally(() => setIsShowPreloader(false));
        }
    };

    const currentFolderIdRef = useRef(currentFolderId);
    useEffect(() => {
        currentFolderIdRef.current = currentFolderId;
    }, [currentFolderId, favoriteList]);

    useEffect(() => {
        if (!isInitialized || store.status === Statuses.IDLE) {
            getData(currentFolderId)
                .then((data) => {
                    setCurrentElements({ files: [...data] });
                })
                .then(() => setIsInitialized(true));
        }
    }, []);

    useEffect(() => {
        if (previousFolderIds[previousFolderIds.length - 1] !== currentFolderId) {
            getData(currentFolderId).then((data) => {
                setCurrentElements({ files: [...data] });
            });
        }
    }, [currentFolderId, previousFolderIds]);

    useEffect(() => {
        if (isOpenDialog && selectedBoxes) {
            setRemoveElements(
                () =>
                    currentElements.files.filter((element) =>
                        selectedBoxes.includes(element.uuid)
                    ) as TFileManagerElement[]
            );
        } else {
            setRemoveElements([]);
        }
    }, [isOpenDialog, selectedBoxes]);

    const handleCreateFile = () => {
        onCreateFile && onCreateFile(currentFolderId);
    };

    const handleOpenImport = useCallback(
        (status?: boolean): void => {
            if (!isItemDragging) {
                setIsImportOpen(status && status !== isImportOpen ? status : !isImportOpen);
            }
        },
        [isImportOpen]
    );

    const handleSelectElement = useCallback(
        (uuid: string, event?: React.MouseEvent) => {
            if (selectedBoxes.length < 1) {
                return handleSelect(uuid);
            } else {
                if (event?.ctrlKey) {
                    return handleSelect(uuid);
                }
                handleUnSelectAll();
                return handleSelect(uuid);
            }
        },
        [selectedBoxes]
    );

    const handleCheckboxSelectElement = useCallback((uuid: string) => {
        handleSelect(uuid);
    }, []);

    const handleSearch = useCallback(
        (value: string) => {
            const newElements = store.elements.filter((element) =>
                element.name?.toLowerCase().includes(value.toLowerCase())
            );
            setCurrentElements({ files: [...newElements] });
        },
        [currentElements]
    );

    const onAddFolder = useCallback(() => {
        const newFolderNameCount = translations
            ? currentElements.files.map((file) => file.name).filter((name) => name.includes(translations?.newFolder))
            : [];
        const newFolder: TFileManagerElement = {
            name:
                translations && translations.newFolder
                    ? `${translations.newFolder} ${newFolderNameCount.length > 0 ? newFolderNameCount.length + 1 : ''}`
                    : '',
            type: EFileManagerItemType.DIR,
            isEdit: true,
            uuid: uuidv4(),
            isNew: true,
            isDisabled: false,
        };
        setCurrentElements({ files: [...currentElements.files, newFolder] });
    }, [currentElements.files]);

    const handleOpenFile = (id: number) => {
        onOpenFile && onOpenFile(id);
    };

    const onSaveFolderName = useCallback(
        async (name: string, uuid: string) => {
            const current = currentElements.files.find((element) => element.uuid === uuid) as TFileManagerElement;
            const isNameExist = isNameUnique(current, currentElements.files, nameListCompare, name);

            if (name && isNameExist) {
                if (current?.isNew) {
                    onCreateFolder && (await onCreateFolder(currentFolderId, name));
                    await getData(currentFolderId).then((data) => {
                        setCurrentElements({ files: [...data] });
                    });
                } else {
                    const currentId = current?.id;
                    if (current?.type === EFileManagerItemType.DIR) {
                        onEditFolder && (await onEditFolder(currentId as number, name));
                        await getData(currentFolderId).then((data) => {
                            setCurrentElements({ files: [...data] });
                        });
                    } else {
                        if (onEditFile) {
                            await onEditFile(currentId as number, name);
                            await getData(currentFolderId).then((data) => {
                                setCurrentElements({ files: [...data] });
                            });
                        }
                    }
                }
            }
        },
        [currentElements.files, currentFolderId]
    );

    const onEditFolderName = useCallback(
        (uuid: string) => {
            if (uuid) {
                const newElements = currentElements.files.map((element) => {
                    if (element.uuid === uuid) {
                        return { ...element, isEdit: true };
                    }
                    return element;
                });
                setCurrentElements({ files: [...newElements] });
            }
        },
        [currentElements.files]
    );

    const handleShowDialog = () => {
        setIsOpenDialog(!isOpenDialog);
    };

    const onRemove = async () => {
        const filteredElements = currentElements.files.filter((element) => !selectedBoxes.includes(element.uuid));
        if (onDelete && removeElements) {
            await onDelete(removeElements);
            setCurrentElements({ files: [...filteredElements] });
            handleUnSelectAll();
        }
    };

    const onDeclineRemoveFolder = useCallback(() => {
        setRemoveElements([]);
        handleUnSelectAll();
    }, []);

    const handleAddFavoriteList = useCallback(
        async (id: number, status: boolean) => {
            if (onAddToFavorites) {
                await onAddToFavorites(id, status);
                getData(currentFolderId).then((data) => {
                    setCurrentElements({ files: [...data] });
                });
            }
        },
        [favoriteList]
    );

    const defaultSortHandler = useCallback((a: TFileManagerElement, b: TFileManagerElement) => {
        if (typeof a.name === 'string') {
            if (a.type === b.type) {
                return a.name.localeCompare(b.name);
            }
            return a.type.localeCompare(b.type);
        }
        return 0;
    }, []);

    const ASCSortHandler = useCallback(
        (a: TFileManagerElement, b: TFileManagerElement) =>
            typeof a.name === 'string' ? a.name.localeCompare(b.name) : 0,
        []
    );

    const DESCSortHandler = useCallback(
        (a: TFileManagerElement, b: TFileManagerElement) =>
            typeof a.name === 'string' ? b.name.localeCompare(a.name) : 0,
        []
    );

    const dateSortHandler = useCallback(
        (a: TFileManagerElement, b: TFileManagerElement) => {
            const aDate = new Date(a.createdAt as string);
            const bDate = new Date(b.createdAt as string);
            return sortType === EFIleManagerSort.DATE_ASC
                ? aDate.getTime() - bDate.getTime()
                : bDate.getTime() - aDate.getTime();
        },
        [sortType]
    );

    const handleOpenFolder = async (newCurrentFolder: number) => {
        const newPreviousFolderIds = [...previousFolderIds];
        newPreviousFolderIds.push(newCurrentFolder);
        setCurrentFolderId(newCurrentFolder);
        setPreviousFolderIds(newPreviousFolderIds);
        setCurrentElements({ files: [] });
        getData(newCurrentFolder).then((data) => {
            setCurrentElements({ files: [...data] });
        });
    };

    const handleReturnFolder = () => {
        const newPreviousFolderIds = [...previousFolderIds].filter((el, i) => i !== previousFolderIds.length - 1);
        setPreviousFolderIds(newPreviousFolderIds);
        setCurrentFolderId(newPreviousFolderIds[newPreviousFolderIds.length - 1]);
        setCurrentElements({ files: [] });
        getData(newPreviousFolderIds[newPreviousFolderIds.length - 1]).then((data) => {
            setCurrentElements({ files: [...data] });
        });
    };

    const handleMove = async (payload: TOnMovePayload) => {
        onMove &&
            onMove(payload).then(() => {
                getData(currentFolderIdRef.current).then((data) => {
                    setCurrentElements({ files: [...data] });
                    handleUnSelectAll();
                });
            });
    };

    const handleDuplicate = useCallback((id: number) => {
        onDuplicate &&
            onDuplicate(id).then(() => {
                getData(currentFolderIdRef.current).then((data) => {
                    setCurrentElements({ files: [...data] });
                });
            });
    }, []);

    const handleImport = useCallback(
        (file: File) => {
            onImport && onImport(file).then(() => setIsImportOpen(false));
        },
        [isImportOpen]
    );

    const handleManageDemo = useCallback((id: number) => {
        onManageDemo && onManageDemo(id);
    }, []);

    const handleChangePreviewMode = () => {
        const value = !isPreviewOpen;
        localStorage.setItem(FILE_MANAGER_PREVIEW_KEY, `${value}`);
        setIsPreviewOpen(value);
    };

    const handleSortType = (sortType: TFileManagerSortVariant) => {
        localStorage.setItem(FILE_MANAGER_SORT_KEY, sortType);
        setSortType(sortType);
    };

    const handleSort = () => {
        switch (sortType) {
            case EFIleManagerSort.ASC:
                return ASCSortHandler;
            case EFIleManagerSort.DESC:
                return DESCSortHandler;
            case EFIleManagerSort.DATE_ASC:
                return dateSortHandler;
            case EFIleManagerSort.DATE_DESC:
                return dateSortHandler;
            default:
                return defaultSortHandler;
        }
    };

    return (
        <SFileManagerWrapper>
            <FileManagerHeader
                rules={updatedRules}
                elements={currentElements.files}
                translations={translations}
                sortType={sortType}
                viewMode={mode}
                selectedElements={selectedBoxes}
                onMultiSelect={handleSelectAll}
                isOpenImport={isImportOpen}
                onGetData={() => getData(currentFolderId)}
                onChangeMode={handleChangeMode}
                onAddFolder={onAddFolder}
                onRemove={handleShowDialog}
                onSelectSort={handleSortType}
                onSearch={handleSearch}
                onChangePreviewMode={handleChangePreviewMode}
                onOpenImport={handleOpenImport}
                onCreateFile={handleCreateFile}
            />
            <FileManagerSelectionArea
                isItemDragging={isItemDragging}
                onSelectGroup={handleSelectGroup}
                selectedElements={selectedBoxes}
            >
                <FileManagerBody
                    rules={updatedRules}
                    viewMode={mode}
                    files={currentElements.files}
                    translations={translations}
                    previousFolderIds={previousFolderIds}
                    currentFolderId={currentFolderId}
                    imageLoader={imageLoader}
                    selectedElements={selectedBoxes}
                    isPreviewOpen={isPreviewOpen}
                    isOpenImport={isImportOpen}
                    isItemDragging={isItemDragging}
                    isShowPreloader={isShowPreloader}
                    favoriteList={[...favoriteList]}
                    onSelect={handleSelectElement}
                    onCheckboxSelect={handleCheckboxSelectElement}
                    onRemove={handleShowDialog}
                    onOpenImport={handleOpenImport}
                    onUnSelectAll={handleUnSelectAll}
                    onSaveFolderName={onSaveFolderName}
                    onEditFolderName={onEditFolderName}
                    onOpenFolder={handleOpenFolder}
                    onReturnFolder={handleReturnFolder}
                    onOpenFile={handleOpenFile}
                    onSort={handleSort()}
                    onMove={handleMove}
                    onExport={onExport}
                    onImport={handleImport}
                    onItemDragging={setIsItemFDragging}
                    onCopyId={onCopyId}
                    onAddToFavorites={handleAddFavoriteList}
                    onDuplicate={handleDuplicate}
                    onManageDemo={onManageDemo && handleManageDemo}
                    onAddToRoot={onAddToRoot}
                />
            </FileManagerSelectionArea>
            <FileManagerFooter
                rules={updatedRules}
                translations={translations}
                elements={currentElements.files}
                selectedElements={selectedBoxes.length}
            />
            <FileManagerConfirmDelete
                rules={updatedRules}
                open={isOpenDialog}
                removeElements={removeElements}
                translations={translations}
                onSuccess={onRemove}
                onDecline={onDeclineRemoveFolder}
                onHandleShow={handleShowDialog}
            />
        </SFileManagerWrapper>
    );
};
