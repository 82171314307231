import styled from 'styled-components';

import { pxToRem, uiColors } from '../../config/config';

const AUTOSUGGEST_DEFAULT_WIDTH = 220;
const AUTOSUGGEST_DEFAULT_HEIGHT = 30;

export const SAutoSuggestWrapper = styled.div<{ isUpwards?: boolean; width?: number; height?: number }>`
    display: inline-block;

    .react-autosuggest__container {
        position: relative;
        float: left;
    }

    .react-autosuggest__input {
        width: ${(props) => (props.width ? `${props.width}px` : `${AUTOSUGGEST_DEFAULT_WIDTH}px`)};
        height: ${(props) => (props.height ? `${props.height}px` : `${AUTOSUGGEST_DEFAULT_HEIGHT}px`)};
        padding: 10px 20px;
        font-size: 14px;
        border: 1px solid #aaa;
        border-radius: 4px;
    }

    .react-autosuggest__input--focused {
        outline: none;
    }

    .react-autosuggest__input--open {
        ${(props) =>
            !props.isUpwards &&
            `
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            `}
        ${(props) =>
            props.isUpwards &&
            `
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            `}
    }

    .react-autosuggest__suggestions-container {
        display: none;
    }

    .react-autosuggest__suggestions-container--open {
        display: block;
        position: absolute;
        ${(props) =>
            !props.isUpwards &&
            `top:` + (props.height ? `${props.height + 1}px` : `${AUTOSUGGEST_DEFAULT_HEIGHT + 1}px`)};
        ${(props) =>
            props.isUpwards &&
            `bottom:` + (props.height ? `${props.height + 1}px` : `${AUTOSUGGEST_DEFAULT_HEIGHT + 1}px`)};
        width: ${(props) => (props.width ? `${props.width}px` : `${AUTOSUGGEST_DEFAULT_WIDTH}px`)};
        border: 1px solid #aaa;
        background-color: #fff;
        font-size: 14px;
        ${(props) =>
            !props.isUpwards &&
            `
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            `}
        ${(props) =>
            props.isUpwards &&
            `
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            `}
        z-index: 2;
        max-height: 200px;
        overflow: auto;
    }

    .react-autosuggest__suggestions-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .react-autosuggest__suggestion {
        cursor: pointer;
        padding: 10px 20px;
    }

    .react-autosuggest__suggestion--highlighted {
        background-color: #ddd;
    }
`;
export const SSuggestionItem = styled.div`
    display: grid;
    grid-template-columns: 32px 1fr;
    grid-gap: 0.5rem;

    img {
        width: 32px;
    }
`;
export const SSuggestionItemInfo = styled.div`
    display: grid;
    grid-template-rows: min-content min-content;
`;
export const SSuggestionItemTitle = styled.div`
    color: ${uiColors.black};
    font-size: ${pxToRem(12)};
`;
export const SSuggestionItemSubTitle = styled.div`
    color: ${uiColors.darkGrey};
    font-size: ${pxToRem(10)};
`;
