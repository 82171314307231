import { FC, useEffect, useRef, useState } from 'react';

import { useAppSelector } from '@repeat/hooks';
import { workspaceSelectors } from '@repeat/store';
import { SButton, SListItem, SMenuDropdown, SProjectName, SWorkspaceProjectName } from './SWorkspaceProjectMenu';

interface IBreadCrumbsProps {
    handleProjectNameClick: () => void;
    handleGroupNameClick: (id: string) => void;
    projectName: string;
    path: { id: string | null; name: string }[];
}

export const MenuBreadCrumbs: FC<IBreadCrumbsProps> = ({
    handleProjectNameClick,
    handleGroupNameClick,
    projectName,
    path,
}) => {
    const metaGroupId = useAppSelector(workspaceSelectors.workspaceMetaGroupId);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isButtonVisible, setIsButtonVisible] = useState(false);

    const [dropdownList, setDropdownList] = useState<{ id: string | null; name: string }[]>([]);
    const [mainList, setMainList] = useState<{ id: string | null; name: string }[]>([]);

    const [outsideNames, setOutsideNames] = useState<string[]>([]);
    const [outsideBlocks, setOutsideBlocks] = useState<HTMLSpanElement[]>([]);
    const blockRef = useRef<HTMLDivElement>(null);
    const namesRefs = useRef<(HTMLSpanElement | null)[]>([]);

    const namesRects = namesRefs.current && namesRefs.current.map((ref) => ref?.getBoundingClientRect());

    const blockRect = blockRef.current?.getBoundingClientRect();

    useEffect(() => {
        if (blockRect?.width) {
            if (!isButtonVisible) {
                setIsButtonVisible(blockRect?.width >= 300 || outsideNames.length !== 0);
            }
            if (blockRect.width === 300) {
                const pathItem = path.find((item) => item.id === metaGroupId);

                if (pathItem) {
                    setMainList((prev) => [...prev, pathItem]);
                    setDropdownList(path.filter((item) => item.id !== metaGroupId).reverse());
                }
            }
        }
    }, [blockRect?.width, path]);

    useEffect(() => {
        if (dropdownList.length === 0) {
            setIsDropdownVisible(false);
        }
    }, [dropdownList.length]);

    return (
        <>
            <SButton
                variant={'outlined'}
                onClick={() => {
                    setIsDropdownVisible(!isDropdownVisible);
                }}
                isVisible={isButtonVisible}
            >
                ...
            </SButton>
            <SWorkspaceProjectName isGroup ref={blockRef} data-tour='workspace-project-name'>
                {dropdownList.length === 0 && <SProjectName onClick={handleProjectNameClick}>{projectName}</SProjectName>}
                &nbsp;/&nbsp;          
                {path.map((el, index) =>
                    index === path.length - 1 ? (
                        <span ref={(ref) => (namesRefs.current[index] = ref)} key={el.id}>
                            {el.name}
                        </span>
                    ) : (
                        <SProjectName
                            ref={(ref) => (namesRefs.current[index] = ref)}
                            key={el.id}
                            onClick={() => {
                                el.id && handleGroupNameClick(el.id);
                            }}
                        >
                            {el.name}
                            &nbsp;/&nbsp;
                        </SProjectName>
                    )
                )}
            </SWorkspaceProjectName>
            {dropdownList.length !== 0 && (
                <SMenuDropdown isVisible={isDropdownVisible}>
                    {dropdownList.map((item) => (
                        <SListItem key={item.id}>
                            <SProjectName
                                onClick={() => {
                                    item.id && handleGroupNameClick(item.id);
                                }}
                            >
                                {item.name}
                            </SProjectName>
                        </SListItem>
                    ))}
                    <SListItem>
                        <SProjectName onClick={handleProjectNameClick}>{projectName}</SProjectName>
                    </SListItem>
                </SMenuDropdown>
            )}
        </>
    );
};
