import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { ApplicationActions } from '@repeat/common-slices';
import { validateEmptySpaces } from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { ILibraryItem, ModalTypes, TNewBlockInfoModal } from '@repeat/models';
import { TranslationKey } from '@repeat/translations';
import { Button, InputField, StyledForm } from '@repeat/ui-kit';

import { EngineerService } from '../EngineerService';
import { SInputNotice, SPropertyValuesForm, StyledButtons } from '../styled/SEditBlock';
import { messages } from '../translations';

interface IEditBlockEnProps {
    currentBlockProp: ILibraryItem;
    engineerService: EngineerService;
}

export const EditBlockEn: FC<IEditBlockEnProps> = (props) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [currentBlockEnValue, setCurrentBlockEnValue] = useState<ILibraryItem>();

    const engineerService = props.engineerService;
    const modals = useAppSelector((state) => state.app.modals);
    const modal = modals.find((m) => m.type === ModalTypes.NEWBLOCK_INFO) as TNewBlockInfoModal;
    const [block] = useState(modal.data.block);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm<Pick<ILibraryItem, 'name' | 'elemProps' | 'elemParams' | 'stateParameters'>>({
        mode: 'onChange',
    });

    useEffect(() => {
        const fetchData = async () => {
            const currentDBBlockEN = await props.engineerService.getBlock(block.type);
            if (currentDBBlockEN) {
                setCurrentBlockEnValue(currentDBBlockEN);
                reset({ ...currentBlockEnValue });
            }
        };

        fetchData();
    }, []);

    const handleSaveClick = useCallback(
        async (data: Pick<ILibraryItem, 'name' | 'elemProps' | 'elemParams' | 'stateParameters'>) => {
            const engineerBlockEn = structuredClone(block);
            engineerBlockEn.name = data.name;
            engineerBlockEn.shortName = data.name;
            engineerBlockEn.elemProps?.forEach((prop, i) => {
                prop.description = data.elemProps[i].description;
                prop.availableValues?.map((avaliableValue, index) => {
                    avaliableValue.title = data.elemProps[i]?.availableValues?.[index].title as string;
                });
            });
            engineerBlockEn.elemParams?.forEach((param, i) => (param.description = data.elemParams[i].description));
            engineerBlockEn.stateParameters?.forEach(
                (param, i) => (param.description = data.stateParameters[i].description)
            );

            const isLocaleExist = await engineerService.getBlock(engineerBlockEn.type);

            isLocaleExist
                ? await engineerService
                      .updateBlockByCursor(engineerBlockEn.type, engineerBlockEn)
                      .then(() => navigate(0))
                      .catch((err) => console.error(err))
                : await engineerService
                      .addBlock(engineerBlockEn)
                      .then(() => navigate(0))
                      .catch((err) => console.error(err));
        },
        [engineerService, block]
    );

    const preventRULettersInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const isCyrillic = /[а-яА-ЯЁё]/.test(e.key);
        if (isCyrillic) {
            e.preventDefault();
        }
    };

    const handleClose = () => {
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.NEWBLOCK_INFO }));
    };

    return (
        <StyledForm>
            <>
                <InputField
                    label='Название'
                    type='text'
                    id='standard-required'
                    defaultValue={currentBlockEnValue?.name ?? ''}
                    {...register('name', {
                        validate: {
                            noWhitespace: validateEmptySpaces,
                        },
                    })}
                    onKeyDown={preventRULettersInput}
                />
                <SInputNotice>{block?.name}</SInputNotice>
            </>
            <h5>Свойства блока (EN)</h5>
            {block?.elemProps?.map((elemProp, index1) => (
                <>
                    <InputField
                        key={elemProp.description}
                        label={elemProp.name}
                        defaultValue={currentBlockEnValue?.elemProps[index1].description ?? ''}
                        type='text'
                        {...register(`elemProps.${index1}.description`)}
                        onKeyDown={preventRULettersInput}
                    />
                    <SInputNotice>{elemProp.description}</SInputNotice>
                    {elemProp.availableValues &&
                        elemProp.availableValues?.map((value, index2) => {
                            return (
                                <SPropertyValuesForm>
                                    <h6>Значения свойства "{value.title}"</h6>
                                    <InputField
                                        key={value.value}
                                        defaultValue={
                                            currentBlockEnValue?.elemProps[index1].availableValues?.[index2].title ?? ''
                                        }
                                        type='text'
                                        {...register(`elemProps.${index1}.availableValues.${index2}.title`)}
                                        onKeyDown={preventRULettersInput}
                                    />
                                    <SInputNotice>{value.value}</SInputNotice>
                                </SPropertyValuesForm>
                            );
                        })}
                </>
            ))}

            <h5>Параметры блока (EN)</h5>
            {block?.elemParams?.map((elemParam, index) => (
                <>
                    <InputField
                        key={elemParam.description}
                        label={elemParam.name}
                        defaultValue={currentBlockEnValue?.elemParams[index].description ?? ''}
                        type='text'
                        {...register(`elemParams.${index}.description`)}
                        onKeyDown={preventRULettersInput}
                    />
                    <SInputNotice>{elemParam.description}</SInputNotice>
                </>
            ))}

            <h5>Параметры датчика (EN)</h5>
            {block?.stateParameters?.map((stateParam, index) => (
                <>
                    <InputField
                        key={stateParam.description}
                        label={stateParam.name}
                        pattern='[a-zA-Z]{3,30}'
                        defaultValue={currentBlockEnValue?.stateParameters[index].description ?? ''}
                        type='text'
                        {...register(`stateParameters.${index}.description`)}
                        onKeyDown={preventRULettersInput}
                    />
                    <SInputNotice>{stateParam.description}</SInputNotice>

                    <SPropertyValuesForm>
                        <InputField
                            key={stateParam.unit}
                            label={'Единица измерения'}
                            defaultValue={currentBlockEnValue?.stateParameters[index].unit ?? ''}
                            type='text'
                            {...register(`stateParameters.${index}.unit`)}
                            onKeyDown={preventRULettersInput}
                        />
                    </SPropertyValuesForm>
                </>
            ))}
            <StyledButtons>
                <Button variant='secondary' onClick={handleClose}>
                    Отмена
                </Button>
                <Button onClick={handleSubmit(handleSaveClick)}>
                    {intl.formatMessage(messages[TranslationKey.SAVE])}
                </Button>
            </StyledButtons>
        </StyledForm>
    );
};
