import styled from 'styled-components';

import { shadow } from '@repeat/ui-kit';

export const SContextMenuButton = styled.button`
    border: none;
    display: block;
    padding: 0.5em;
    text-align: left;
    width: 100%;
`;

export const SContextMenu = styled.div`
    .context-menu {
        background: var(--ui-background);
        border-style: solid;
        box-shadow: ${shadow.default};
        position: absolute;
        z-index: 10;
    }

    .context-menu button {
        border: none;
        display: block;
        padding: 0.5em;
        text-align: left;
        width: 100%;
    }

    .context-menu button:hover {
        background: var(--ui-background);
    }
`;
