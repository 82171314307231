import { AxiosResponse } from 'axios';

import { ElemParams, ElemProps, TLibraryType, TSolverType } from '@repeat/models';

import UploadService from './UploadService';
import { $api } from './http';

type ReturnInitializeFMIContract = {
    name: string;
    uuid: string;
    elemParams: ElemParams[];
    elemProps: ElemProps[];
};
export type TInitializeFMIPayload = {
    uuid: string;
    projectId: number;
    libraryType: TLibraryType;
    solverType: TSolverType;
};
interface IInitializeFMI {
    (payload: TInitializeFMIPayload, signal?: AbortSignal): Promise<AxiosResponse<ReturnInitializeFMIContract>>;
}

type TUploadFMUFilePayload = {
    elementId: number;
    projectId: number;
    file: File;
    onChunkUploaded: (uploadedBytes: number) => void;
    onUploaded: (file: TUploadedFmuFile) => void;
};

export type TUploadedFmuFile = {
    uuid: string;
    createdAt: string;
    fileType: string;
    contentType: string;
    originalName: string;
    size: number;
    projectId: number;
    elementId: number;
};

interface IUploadFMUFile {
    (payload: TUploadFMUFilePayload): void;
}

export default class FMIService {
    static initializeFMI: IInitializeFMI = async (payload: TInitializeFMIPayload, signal?: AbortSignal) => {
        const data = {
            uuid: payload.uuid,
            libraryType: payload.libraryType,
            solverType: payload.solverType,
        };
        return await $api.post('/api/v1/modeling/initFMI', { ...data }, { headers: { projectId: payload.projectId }, signal });
    };

    static uploadFMUFile: IUploadFMUFile = async (payload: TUploadFMUFilePayload) => {
        const headers = {
            projectId: payload.projectId,
            elementId: payload.elementId,
        };
        const chunkSize = 1048576; // 1Mb

        const uploadService = new UploadService('/api/v1/modeling/uploadProjectFile', payload.file, headers, chunkSize);
        await uploadService.uploadFile<TUploadedFmuFile>(payload.onChunkUploaded, payload.onUploaded);
    };
}
