import React, { FC, useEffect, useLayoutEffect, useState } from 'react';

import { SSidebar, SSidebarAddon, SSidebarTrigger } from './SSidebar';

import { Icon } from '../../Icon/Icon';

interface ISidebar {
    open?: boolean;
    canHide?: boolean;
    position?: string;
    addon?: React.ReactNode;
    children: React.ReactNode;
    onTriggerClick?: (state: boolean) => void;
    onMount?: (state: boolean) => void;
}

export const Sidebar: FC<ISidebar> = ({
    onMount,
    onTriggerClick,
    position = 'LEFT',
    children,
    open = true,
    addon,
    canHide = true,
}) => {
    const [visible, setVisible] = useState<boolean>(open);

    useLayoutEffect(() => {
        if (onMount) {
            onMount(visible);
        }
    }, []);

    useEffect(() => {
        setVisible(open);
    }, [open]);

    return (
        <SSidebar data-open={visible} position={position} data-position={position}>
            {addon && <SSidebarAddon position={position}>{addon}</SSidebarAddon>}
            {children}
            {canHide && (
                <SSidebarTrigger
                    data-trigger
                    position={position}
                    open={visible}
                    onClick={() => {
                        if (onTriggerClick) {
                            onTriggerClick(!visible);
                        }
                        setVisible((state) => !state);
                    }}
                >
                    <Icon name='collapse' />
                </SSidebarTrigger>
            )}
        </SSidebar>
    );
};
