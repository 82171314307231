import { FC, useContext } from 'react';
import { useIntl } from 'react-intl';

import { monoLogoUrl, VERSION } from '@repeat/constants';
import { useAppSelector } from '@repeat/hooks';
import { appUserSelectors } from '@repeat/store';
import { Header, Layout, Main as MainBlock, Sidebar, TourContext } from '@repeat/ui-kit';

import { EngineerContent } from './EngineerContent';

import { EngineerToolbar } from '../../features/EngineerMode/EngineerToolbar';
import { Navigation } from '../../features/Navigation/Navigation';

interface IEngineerPageSubLayout {
    title: string;
    children: React.ReactNode;
}

export const EngineerPageSubLayout: FC<IEngineerPageSubLayout> = ({ children, title }) => {
    const { locale } = useIntl();
    const userIsAuth = useAppSelector(appUserSelectors.isAuth);

    const { setOpen, open } = useContext(TourContext);

    return (
        <Layout internal>
            <Header
                Navigation={userIsAuth && <Navigation setTourOpen={setOpen} />}
                version={VERSION}
                logoUrl={monoLogoUrl(locale)}
            />
            <Sidebar canHide={false}>
                <EngineerToolbar />
            </Sidebar>
            <MainBlock>
                <EngineerContent>
                    <h3>{title}</h3>
                    <div>{children}</div>
                </EngineerContent>
            </MainBlock>
        </Layout>
    );
};
