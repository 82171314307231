import { FC } from 'react';

import { IFileManagerUpItem, EFileManagerItemType } from './FileManagerTypes';
import { SFileManagerUpItem } from './SFileManager';

import { Icon } from '../Icon/Icon';

export const FileManagerUpItem: FC<IFileManagerUpItem> = ({ onClick, id, onMove }) => {
    return (
        <SFileManagerUpItem
            className={'file-manager__item'}
            data-file-manager-id={id}
            data-type={EFileManagerItemType.DIR}
            data-file-manager-type={EFileManagerItemType.DIR}
            onClick={onClick}
            onDragOver={(event) => {
                event.preventDefault();
            }}
            onDrop={onMove}
        >
            <Icon name={'upFolder'} />
            <span>...</span>
        </SFileManagerUpItem>
    );
};
