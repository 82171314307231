import styled from 'styled-components';

import { pxToRem, uiColors, shadow, zIndex, breakPoints } from '../../config/config';

export const SGridViewListItemBody = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: ${pxToRem(8)};
    width: 100%;
`;

export const SGridViewListItemHeader = styled.header`
    align-items: center;
    border-bottom: 1px solid var(--ui-border);
    display: flex;
    height: ${pxToRem(28)};
    justify-content: space-between;
    font-size: ${pxToRem(12)};
    padding: 0 8px 0 0;
    width: 100%;

    > div {
        height: 100%;

        > span {
            padding: 0 ${pxToRem(8)};
            align-items: center;
            display: flex;
        }
    }

    svg {
        fill: var(--ui-text);
    }
`;

export const SGridViewListItem = styled.div<{ append: boolean }>`
    background: var(--ui-input);
    box-shadow: 1px 0 0 0 var(--ui-border), 0 1px 0 0 var(--ui-border), 1px 1px 0 0 var(--ui-border),
        1px 0 0 0 var(--ui-border) inset, 0 1px 0 0 var(--ui-border) inset;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100%;
    position: relative;
    width: 100%;
`;

export const SGridViewList = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100vw;

    > * {
        width: 100%;
    }
`;

export const SGridView = styled.div`
    margin: 0;
    position: relative;
    height: 100%;
    padding-bottom: ${pxToRem(62)};
    width: 100vw;
    top: 0;
    left: 0;

    .react-resizable {
        position: relative;
    }

    .react-resizable-handle {
        position: absolute;
        width: 20px;
        height: 20px;
        bottom: 0;
        right: 0;
        background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=');
        background-position: bottom right;
        background-repeat: no-repeat;
        background-origin: content-box;
        padding: 0 3px 3px 0;
        box-sizing: border-box;
        cursor: se-resize;
    }
`;

export const SGridViewAddItem = styled.button<{ isMaximum: boolean; isNotAdded: boolean }>`
    align-items: center;
    border: 0;
    background: ${uiColors.mainBlue};
    box-shadow: ${shadow.default};
    border-radius: ${pxToRem(24)};
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: fixed;
    padding: 0 ${pxToRem(16)};
    bottom: ${pxToRem(6)};
    right: ${pxToRem(16)};
    height: ${pxToRem(48)};
    min-width: ${pxToRem(48)};
    width: ${pxToRem(120)};
    z-index: ${zIndex.top};
    will-change: left, bottom;

    ${({ isNotAdded }) =>
        isNotAdded &&
        `
        left: calc(100% / 2 - 120px / 2);
        bottom: calc(100vh / 4 - 24px);
    `}

    span:empty {
        display: none;
    }

    span {
        margin-left: ${pxToRem(8)};
    }
`;
