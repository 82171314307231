import { PayloadAction } from '@reduxjs/toolkit';

import {
    ElemParams,
    ElemProps,
    IElement,
    IElementInitializationData,
    IElementPort,
    ISchemaState,
    ISetPosition,
    IWire,
    Statuses,
    TLibraryType,
    TSolverType,
} from '@repeat/models';

import { initialState } from './index';

const setPosition = (state: ISchemaState, payload: ISetPosition) => {
    const elements = state.schemaItems.elements.map((el: IElement) => {
        if (el.id === payload.id) {
            return {
                ...el,
                x: payload.x,
                y: payload.y,
                new: payload.new,
            };
        }
        return el;
    });
    return {
        ...state,
        schemaItems: {
            ...state.schemaItems,
            elements,
        },
    };
};

export const elementsReducers = {
    updateSchema: (state: ISchemaState, action: PayloadAction<any>) => ({
        ...state,
        schemaItems: {
            ...action.payload,
        },
    }),
    addElement: (state: ISchemaState, action: PayloadAction<IElement>) => ({
        ...state,
        schemaItems: {
            ...state.schemaItems,
            elements: [...state.schemaItems.elements, action.payload],
        },
    }),

    deleteItemsGroup: (state: ISchemaState) => {
        const selectedItems = state.selectedItems;
        const selectedElements = selectedItems.filter((item) => item.type !== 'wire');

        const selectedWires = selectedItems.filter((item) => item.type === 'wire');
        const newElements = state.schemaItems.elements.filter((x) => !selectedElements.some((y) => x.id === y.id));
        const newWires = state.schemaItems.wires.filter((x) => !selectedWires.some((y) => x.id === y.id));
        const wiresToDelete: IWire[] = [];

        newWires.map((wire: IWire) => {
            selectedElements.map((el) => {
                if (wire.firstElement === el?.id || wire.secondElement === el?.id) {
                    wiresToDelete.push(wire);
                }
            });
        });

        const resultWires = newWires.filter((x: { id: number }) => !wiresToDelete.some((y) => x.id === y.id));

        return {
            ...state,
            schemaItems: {
                elements: newElements,
                wires: resultWires,
            },
            selectedItems: initialState.selectedItems,
        };
    },

    deleteLastElement: (state: ISchemaState) => {
        const elements = [...state.schemaItems.elements];
        elements.pop();
        return {
            ...state,
            schemaItems: {
                ...state.schemaItems,
                elements,
            },
        };
    },

    setElementPosition: (state: ISchemaState, { payload }: PayloadAction<ISetPosition>) => {
        return setPosition(state, payload);
    },
    changeElementPosition: (state: ISchemaState, { payload }: PayloadAction<ISetPosition>) => {
        return setPosition(state, payload);
    },
    changeElementSize: (state: ISchemaState, { payload }: PayloadAction<{ id: number; size: number }>) => {
        const elements = state.schemaItems.elements.map((el: IElement) => {
            if (el.id === payload.id) {
                return {
                    ...el,
                    size: payload.size,
                };
            }
            return el;
        });
        return {
            ...state,
            schemaItems: {
                ...state.schemaItems,
                elements,
            },
        };
    },
    rotateElement: (state: ISchemaState, { payload }: PayloadAction<{ id: number }>) => {
        const elements = state.schemaItems.elements.map((el: IElement) => {
            if (el.id === payload.id) {
                return {
                    ...el,
                    angle: el.angle + 90 === 360 ? 0 : el.angle + 90,
                };
            }
            return el;
        });
        return {
            ...state,
            schemaItems: {
                ...state.schemaItems,
                elements,
            },
        };
    },
    changeElementState: (state: ISchemaState, { payload }: PayloadAction<{ id: number; elementState: { size: number } }>) => {
        const elements = state.schemaItems.elements.map((el: IElement) => {
            if (el.id === payload.id) {
                return {
                    ...el,
                    ...payload.elementState,
                };
            }
            return el;
        });
        return {
            ...state,
            schemaItems: {
                ...state.schemaItems,
                elements,
            },
        };
    },

    addWireToElement: (state: ISchemaState, { payload }: PayloadAction<{ id: number | undefined; wireId: number | undefined }>) => {
        // TODO check types
        const elements = state.schemaItems.elements.map((el: IElement) => {
            if (el.id === payload.id) {
                return { ...el, wireId: payload.wireId };
            }
            return el;
        });
        return {
            ...state,
            schemaItems: {
                ...state.schemaItems,
                elements: [...elements],
            },
        };
    },

    getProjectRequest: () => ({
        ...initialState,
        getProject: {
            ...initialState.getProject,
            status: Statuses.LOADING,
        },
    }),
    getProjectSuccess: (
        state: ISchemaState,
        { payload }: PayloadAction<{ data: { elements: IElement[]; wires: IWire[] }; libraryType: TLibraryType; solverType: TSolverType }>
    ) => ({
        ...state,
        libraryType: payload.libraryType,
        solverType: payload.solverType,
        schemaItems: {
            elements: payload.data.elements,
            wires: payload.data.wires,
        },
        getProject: {
            ...state.getProject,
            status: Statuses.SUCCEEDED,
        },
    }),
    getProjectFailed: (state: ISchemaState, { payload }: PayloadAction<{ error: string }>) => ({
        ...state,
        getProject: {
            ...state.getProject,
            status: Statuses.FAILED,
            error: payload.error,
        },
    }),

    deleteSchema: (state: ISchemaState) => ({
        ...state,
        schemaItems: initialState.schemaItems,
        selectedItems: initialState.selectedItems,
    }),
    addElementParameter: (state: ISchemaState, { payload }: PayloadAction<{ elementId: number; options: { [key: string]: string } }>) => {
        if (payload?.options) {
            let oldOutputPortNumber = 0;
            let newOutputPortNumber = 0;
            const numberOfInputs = Object.values(payload.options)[0]
                ?.split(';')
                .filter((value: string) => value !== '').length;
            const elements = state.schemaItems.elements.map((el: IElement) => {
                const newParameters = [];
                if (el.id === payload.elementId) {
                    let i = 1;
                    while (i <= numberOfInputs) {
                        newParameters.push({ description: `Вход${i}`, name: `in_${i}`, modelName: '', value: '', unit: '' });
                        i++;
                    }
                    newParameters.push({ description: `Выход`, name: `out_${numberOfInputs + 1}`, modelName: '', value: '', unit: '' });

                    oldOutputPortNumber = el.portsAmount;
                    newOutputPortNumber = numberOfInputs + 1;
                    return { ...el, portsAmount: numberOfInputs + 1, elemParams: newParameters };
                }
                return el;
            });
            const wires = state.schemaItems.wires
                .filter((wire: IWire) => {
                    if (oldOutputPortNumber <= newOutputPortNumber) {
                        return true;
                    }

                    if (
                        (wire.firstElement === payload.elementId && wire.firstPort > numberOfInputs && wire.firstPort !== oldOutputPortNumber) ||
                        (wire.secondElement === payload.elementId && wire.secondPort > numberOfInputs && wire.secondPort !== oldOutputPortNumber)
                    ) {
                        return false;
                    }

                    return true;
                })
                .map((wire: IWire) => {
                    if (wire.firstElement === payload.elementId && wire.firstPort === oldOutputPortNumber && newOutputPortNumber > 0) {
                        return {
                            ...wire,
                            firstPort: newOutputPortNumber,
                        };
                    }
                    if (wire.secondElement === payload.elementId && wire.secondPort === oldOutputPortNumber && newOutputPortNumber > 0) {
                        return {
                            ...wire,
                            secondPort: newOutputPortNumber,
                        };
                    }
                    return wire;
                });
            return {
                ...state,
                schemaItems: {
                    ...state.schemaItems,
                    elements,
                    wires,
                },
            };
        } else return state;
    },
    setElementParametersValues: (state: ISchemaState, { payload }: PayloadAction<{ [key: string]: string | number }>) => {
        const elements = state.schemaItems.elements.map((el) => {
            let allParametersModelNames: string[] = [];
            if (payload) {
                allParametersModelNames = Object.keys(payload);
            }
            const newParams = el?.elemParams?.map((param: ElemParams) => {
                let newValue: string | number = '';
                for (let i = 0; i <= allParametersModelNames.length; i++) {
                    if (param.modelName === allParametersModelNames[i]) {
                        newValue = payload[allParametersModelNames[i]] !== '-' ? Number(payload[allParametersModelNames[i]]) : '-';
                        return { ...param, value: newValue };
                    }
                }
                return param;
            });
            return { ...el, elemParams: newParams };
        });

        return {
            ...state,
            schemaItems: {
                ...state.schemaItems,
                elements: [...elements],
            },
        };
    },
    setElementPropertiesValues: (state: ISchemaState, { payload }: PayloadAction<{ id: number; elemProps: { [key: string]: string } }>) => {
        const elements = state.schemaItems.elements.map((el: IElement) => {
            if (el.id === payload.id) {
                const keys = Object.keys(payload.elemProps);
                const newProps = el?.elemProps?.map((option: ElemProps) => {
                    for (let i = 0; i <= keys.length; i++) {
                        if (option.name === keys[i]) {
                            return {
                                ...option,
                                value: payload.elemProps[keys[i]],
                            };
                        }
                    }
                    return option;
                });
                return {
                    ...el,
                    elemProps: newProps,
                };
            }
            return el;
        });
        return {
            ...state,
            schemaItems: {
                ...state.schemaItems,
                elements,
            },
        };
    },
    setPortsCoordinates: (state: ISchemaState, { payload }: PayloadAction<{ id: number; elementPort: Omit<IElementPort, 'num'>[] }>) => {
        const elements = state.schemaItems.elements.map((el: IElement) => {
            if (el.id === payload.id) {
                const resPorts = payload.elementPort.map((port: Omit<IElementPort, 'num'>, index: number) => {
                    return { ...port, num: index + 1 };
                });
                return { ...el, ports: resPorts };
            }
            return { ...el };
        });
        return {
            ...state,
            schemaItems: {
                ...state.schemaItems,
                elements,
            },
        };
    },
    markPortAsUsed: (state: ISchemaState, { payload }: PayloadAction<{ elementId: number; num: number }>) => {
        const elementIndex = state.schemaItems.elements.findIndex((e) => e.id === payload.elementId);
        if (elementIndex === -1) {
            return { ...state };
        }

        const elements = [
            ...state.schemaItems.elements.slice(0, elementIndex),
            {
                ...state.schemaItems.elements[elementIndex],
                ports: state.schemaItems.elements[elementIndex].ports.map((p) => {
                    if (p.num !== payload.num) {
                        return p;
                    }

                    return {
                        ...p,
                        isUsed: true,
                    };
                }),
            },
            ...state.schemaItems.elements.slice(elementIndex + 1),
        ];

        return {
            ...state,
            schemaItems: {
                ...state.schemaItems,
                elements,
            },
        };
    },
    markPortAsNotUsed: (state: ISchemaState, { payload }: PayloadAction<{ elementId: number; num: number }>) => {
        const elementIndex = state.schemaItems.elements.findIndex((e) => e.id === payload.elementId);
        if (elementIndex === -1) {
            return { ...state };
        }

        const elements = [
            ...state.schemaItems.elements.slice(0, elementIndex),
            {
                ...state.schemaItems.elements[elementIndex],
                ports: state.schemaItems.elements[elementIndex].ports.map((p) => {
                    if (p.num !== payload.num) {
                        return p;
                    }

                    return {
                        ...p,
                        isUsed: false,
                    };
                }),
            },
            ...state.schemaItems.elements.slice(elementIndex + 1),
        ];

        return {
            ...state,
            schemaItems: {
                ...state.schemaItems,
                elements,
            },
        };
    },
    initializeElementSuccess: (state: ISchemaState, { payload: { name, elemParams, elemProps, uuid } }: PayloadAction<IElementInitializationData>) => {
        const selectedElement = state.selectedItems[0];
        const elements = state.schemaItems.elements.map((el: IElement) => {
            if (selectedElement && el.id === selectedElement.id) {
                return { ...el, name, elemParams, uuid, portsAmount: elemParams.length, elemProps };
            }
            return { ...el };
        });
        return {
            ...state,
            schemaItems: { ...state.schemaItems, elements },
        };
    },
    setAdaptedSchema: (state: ISchemaState, { payload }: PayloadAction<ISchemaState>) => {
        return payload;
    },
};

// export const deleteUninitializedElement = () => async (dispatch: AppDispatch) => {
//     dispatch(actions.deleteElement());
//     dispatch(ApplicationActions.hideModal({ type: ModalTypes.ELEMENT_INITIALIZATION }));
// };

// export const initializeElement = () => async (dispatch: AppDispatch, getState: RootStateFn) => {
//     const data = getState().schema.elementInitialization.data;
//     if (!data) {
//         return;
//     }
//
//     dispatch(actions.initializeElementSuccess(data));
//     dispatch(ApplicationActions.hideModal({ type: ModalTypes.ELEMENT_INITIALIZATION }));
// };
