import * as AdminCreators from './adminAC';
import * as CounterCreators from './counterAC';
import * as ElemToolCreators from './elemToolAC';
import * as LibraryTypeCreators from './libraryTypeAC';
import * as LogCreators from './logAC';
import * as ModalCreators from './modalAC';
import * as PreloaderCreators from './preloaderAC';
import * as SolverTypeCreators from './solverTypeAC';
import * as TaskCreators from './taskAC';
import * as UserDataCreators from './userDataAC';
import * as ValidCreators from './validAC';

export default {
    ...AdminCreators,
    ...CounterCreators,
    ...ElemToolCreators,
    ...LogCreators,
    ...LibraryTypeCreators,
    ...ModalCreators,
    ...PreloaderCreators,
    ...SolverTypeCreators,
    ...TaskCreators,
    ...UserDataCreators,
    ...ValidCreators,
};
