import { IElement } from '@repeat/models';

import { DeletedElementsAction, DeletedElementsTypes } from '../actions/deletedElementsTypes';

export const deletedElementsReducer = (state = [] as IElement[], action: DeletedElementsAction) => {
    const { type, payload } = action;
    switch (type) {
        case DeletedElementsTypes.ADD_DELETED_ELEMENT:
            return [...state, payload];
        case DeletedElementsTypes.DELETE_DELETED_ELEMENT: {
            const arr = [...state];
            arr.pop();
            return arr;
        }

        default:
            return state;
    }
};

export default deletedElementsReducer;
