import {
    ElemParams,
    ElementNotificationTypes,
    PortTypes,
    TElement,
    TElementNotificationType,
    TLibraryType,
    TSchemaConnection,
    TSchemaHandle,
    TSchemaNode,
} from '@repeat/models';
import {
    PortDirections,
    PortPositions,
    TLibraryItemPort,
    TPortDirection,
    TPortTypeComplex,
} from 'libs/models/src/lib/libraryItem';
import { PortConnectionTypes, TPortConnectionType } from 'libs/models/src/lib/libraryPortConnection';
import { getHandleName } from '.';
import { LIBRARIES_DEFAULT_LIST } from '../schema';

export const calculatePortsLength = (portsCount: number, handleMargin: number) => portsCount * handleMargin * 2;

export const countMaxOfInputsAndOutputs = (ports: TSchemaHandle[] | TLibraryItemPort[]) => {
    const types = ports.map((port) => port.type);
    const inputCount = types.filter((type) => type === PortTypes.INPUT).length;
    const outputCount = types.filter((type) => type === PortTypes.OUTPUT).length;
    return Math.max(inputCount, outputCount);
};

export const getElementNotification = (element: TElement): TElementNotificationType | null => {
    if (element.isNeedToUpdate === true) {
        return ElementNotificationTypes.NEED_TO_UPDATE;
    }
    if (element.isDeprecated === true) {
        return ElementNotificationTypes.IS_DEPRECATED;
    }
    if (element.diff?.connectedProject === true) {
        return ElementNotificationTypes.PROJECT_IS_CHANGED;
    }
    if (element.diff?.isErrorGettingProject === true) {
        return ElementNotificationTypes.IS_ERROR_GETTING_PROJECT;
    }
    if (element.diff?.userBlockIsChanged === true) {
        return ElementNotificationTypes.USER_BLOCK_IS_CHANGED;
    }
    if (element.diff?.userBlockIsDeleted === true) {
        return ElementNotificationTypes.USER_BLOCK_IS_DELETED;
    }
    if (element.diff?.isErrorGettingUserBlock === true) {
        return ElementNotificationTypes.USER_BLOCK_IS_ERROR;
    }

    return null;
};

export const findElementByParentParameter = (elements: TSchemaNode[], portName: string) => {
    return elements.find((el) => {
        const parentParameter = el.data.elemProps.find((prop) => prop.name === 'parentParameter')?.value;
        return parentParameter === portName;
    });
};

export const setLibraries = (portsBlocks: TSchemaNode[], portName: string) => {
    const currentBlock = findElementByParentParameter(portsBlocks, portName);
    return currentBlock?.data.availablePorts[0].libraries || LIBRARIES_DEFAULT_LIST;
};

const findElementType = (id: string, elements: TSchemaNode[]) => {
    const element = elements.find((el) => el.id === id);
    if (!element) {
        return '';
    }
    return element.data.type;
};

export const setDefaultPort = (portName: string, portTypes: TPortTypeComplex[]) => {
    const defaultPortConnectionType = portName.includes('in')
        ? PortConnectionTypes.DEFAULT_INPUT
        : PortConnectionTypes.DEFAULT_OUTPUT;
    const defaultPortDetails = portTypes.find((type) => type.type === defaultPortConnectionType);
    const { direction, compatibleTypes } = defaultPortDetails ?? {
        direction: PortDirections.INPUT,
        compatibleTypes: [],
    };
    return { libraries: LIBRARIES_DEFAULT_LIST, typeConnection: defaultPortConnectionType, direction, compatibleTypes };
};

const setPortByConnection = (
    connectedElementId: string,
    connectedPortId: string,
    elements: TSchemaNode[],
    portName: string,
    portTypes: TPortTypeComplex[]
) => {
    const element = elements.find((el) => el.id === connectedElementId);
    if (!element) {
        return setDefaultPort(portName, portTypes);
    }
    const elementPort = element.data.availablePorts.find((port) => port.name === getHandleName(connectedPortId));

    if (!elementPort) {
        return setDefaultPort(portName, portTypes);
    }
    const { libraries, typeConnection, direction, compatibleTypes } = elementPort;
    return { libraries, typeConnection, direction, compatibleTypes };
};

export const setPort = (
    elements: TSchemaNode[],
    wires: TSchemaConnection[],
    portName: string,
    portTypes: TPortTypeComplex[]
): {
    libraries: TLibraryType[];
    typeConnection: TPortConnectionType;
    direction: TPortDirection;
    compatibleTypes: TPortConnectionType[];
} => {
    const portBlock = findElementByParentParameter(elements, portName);
    if (!portBlock) {
        return setDefaultPort(portName, portTypes);
    }
    const connectionWithPortAsSource = wires.find((wire) => wire.source === portBlock.id);
    const connectionWithPortAsTarget = wires.find((wire) => wire.target === portBlock.id);

    if (connectionWithPortAsSource && !connectionWithPortAsTarget) {
        return setPortByConnection(
            connectionWithPortAsSource.target,
            connectionWithPortAsSource.targetHandle,
            elements,
            portName,
            portTypes
        );
    }
    if (!connectionWithPortAsSource && connectionWithPortAsTarget) {
        return setPortByConnection(
            connectionWithPortAsTarget.source,
            connectionWithPortAsTarget.sourceHandle,
            elements,
            portName,
            portTypes
        );
    }
    return setDefaultPort(portName, portTypes);
};

export const setPortsByParameters = (
    parameters: ElemParams[],
    elements: TSchemaNode[],
    wires: TSchemaConnection[],
    libraryPortTypes: TPortTypeComplex[]
) => {
    return parameters.map((p) => {
        const position = p.name.includes('in') ? PortPositions.LEFT : PortPositions.RIGHT;
        const type = p.name.includes('in') ? PortTypes.INPUT : PortTypes.OUTPUT;

        const { libraries, typeConnection, direction, compatibleTypes } = setPort(
            elements,
            wires,
            p.name,
            libraryPortTypes
        );
        return {
            name: p.name,
            position,
            libraries,
            type,
            isConnected: false,
            typeConnection,
            direction,
            compatibleTypes,
        };
    });
};

export const findPortDetails = (
    portTypes: TPortTypeComplex[],
    connectionType: TPortConnectionType,
    portName: string
) => {
    const portDetails = portTypes.find((typeComplex) => typeComplex.type === connectionType);
    if (!portDetails) {
        const { direction, compatibleTypes } = setDefaultPort(portName, portTypes);
        return { direction, compatibleTypes };
    }
    return { direction: portDetails.direction, compatibleTypes: portDetails.compatibleTypes };
};
