import styled from 'styled-components';

import { hexToRGB } from '@repeat/constants';
import { Mixins, pxToRem, radius, uiColors } from '@repeat/ui-kit';

export const SDropZoneWrapper = styled.div`
    display: inline-flex;
    flex-direction: column;
    position: relative;
    width: 100%;
`;

export const SDropZone = styled.div<{ hasError: boolean }>`
    background: var(--ui-background);
    border: 2px dashed ${uiColors.grey};
    border-radius: ${radius.default};
    padding: ${pxToRem(48)};

    ${Mixins.transitionAnimation(['border-color,background'])}

    ${({ hasError }) =>
        hasError &&
        `
        border-color : ${uiColors.red};
        background: ${hexToRGB(uiColors.red, '0.3')};
    `}

    [data-error] {
        color: ${uiColors.red};
    }

    button {
        height: auto;
        margin: ${pxToRem(16)} 0 0;
        max-width: 200px;
        padding: ${pxToRem(8)} ${pxToRem(12)};
    }
`;

export const SDropZoneText = styled.p`
    color: var(--ui-text);
    font-size: ${pxToRem(14)};
`;

export const SDropZoneFileList = styled.ul`
    font-size: ${pxToRem(14)};
    color: ${uiColors.darkGrey};
    margin: ${pxToRem(16)} 0;
`;

export const SDropZoneLabel = styled.label<{ error: boolean }>`
    ${Mixins.transitionAnimation(['color'])}
    color: ${({ error }) => (!error ? `${uiColors.darkGrey}` : `${uiColors.red}`)};
    font-size: ${pxToRem(14)};
    overflow: hidden;
    padding: ${pxToRem(4)} 0;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
