import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { ApplicationActions } from '@repeat/common-slices';
import { useAppDispatch, useAppSelector, useProjectId } from '@repeat/hooks';
import { ModalTypes, Statuses } from '@repeat/models';
import { initializeElementByTextFile, uploadSourcesFile, workspaceActions } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { Button, Modal } from '@repeat/ui-kit';

import { blockOptionsMessages as messages } from './translation';

import { UploadForm } from '../Upload/UploadForm';

export const PulSeqInitializationModal = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();

    const element = useAppSelector((state) => state.workspace.schema.selectedItems.elements[0]);
    const { elementInitialization } = useAppSelector((state) => state.workspace.schema);
    const { projectId } = useProjectId() || { projectId: null };

    useEffect(() => {
        return () => {
            dispatch(workspaceActions.resetElementInitialization());
        };
    }, []);

    const handleClose = () => {
        dispatch(workspaceActions.deleteNode());
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.PULSEQ_INITIALIZATION }));
    };
    const handleAdd = () => {
        dispatch(initializeElementByTextFile());
    };

    const handleFileUpload = (file: File) => {
        if (!element?.id || !projectId) {
            return;
        }

        dispatch(uploadSourcesFile({ file, projectId, elementId: parseInt(element.id) }));
    };

    const FooterButtons = () => (
        <>
            <Button variant='secondary' onClick={handleClose}>
                {intl.formatMessage(messages[TranslationKey.CANCEL])}
            </Button>
            <Button
                onClick={handleAdd}
                disabled={elementInitialization.uploadElementSourcesFile.status !== Statuses.SUCCEEDED}
            >
                {intl.formatMessage(messages[TranslationKey.ADD])}
            </Button>
        </>
    );
    const formMessages = {
        title: formatMessage(messages[TranslationKey.ELEMENT_INITIALIZATION_NOTICE], {
            name: 'PULSEQ',
            extension: 'SEQ',
        }),
        dropZoneMessages: {
            uploadHelp: formatMessage(messages[TranslationKey.ELEMENT_INITIALIZATION_DROPZONE], {
                extension: '.seq',
            }),
            uploadButton: formatMessage(messages[TranslationKey.ELEMENT_INITIALIZATION_SELECT], {
                extension: '.seq',
            }),
        },
    };

    return (
        <Modal
            id={ModalTypes.PULSEQ_INITIALIZATION}
            title={intl.formatMessage(messages[TranslationKey.ELEMENT_INITIALIZATION], { name: 'PULSEQ' })}
            isOpen={true}
            hasOverlay={true}
            onClose={handleClose}
            type='default'
            size='medium'
            footer={<FooterButtons />}
        >
            <UploadForm
                acceptFileTypes={{ 'text/seq': ['.seq'] }}
                maxFileSize={52428800}
                onFileUpload={handleFileUpload}
                uploadStatusInfo={elementInitialization.uploadElementSourcesFile}
                initializeStatusInfo={elementInitialization.initializeElementByFile}
                messages={formMessages}
            />
        </Modal>
    );
};
