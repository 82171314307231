import styled from 'styled-components';

import { pxToRem, radius, uiColors } from '@repeat/ui-kit';

export const SAnnotationsEmpty = styled.div`
    font-size: ${pxToRem(12)};
    padding: ${pxToRem(16)} 0;
`;
export const SAnnotation = styled.div`
    display: grid;
    grid-template-columns: 1fr ${pxToRem(24)};
    gap: ${pxToRem(8)};
    padding: ${pxToRem(4)} ${pxToRem(8)};
    margin-top: ${pxToRem(8)};
    border-radius: ${radius.default};
    font-size: ${pxToRem(12)};
    color: var(--ui-text);
    background: var(--ui-managed-list-item);

    &:hover {
        background: var(--ui-managed-list-item-active);
    }

    i {
        visibility: hidden;

        &:hover {
            svg {
                fill: ${uiColors.black};
            }
        }
    }

    &:hover {
        i {
            visibility: visible;
        }
    }
`;
export const SAnnotationTitle = styled.div`
    align-content: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
